import React from 'react';
import { shape } from 'prop-types';

export const Close = ({ style }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" style={style}>
    <path d="M7.07025 6L11.9214 1.14911C12.0258 1.04456 12.0258 0.875166 11.9214 0.770621L11.2295 0.0783768C11.1793 0.0283168 11.1111 0 11.0402 0C10.9691 0 10.901 0.0283168 10.8508 0.0783768L5.99978 4.9294L1.14876 0.0783768C1.04826 -0.0221225 0.870394 -0.0219961 0.770147 0.0783768L0.0784084 0.770621C-0.0261362 0.875166 -0.0261362 1.04456 0.0784084 1.14911L4.92943 6L0.0784084 10.8509C-0.0261362 10.9554 -0.0261362 11.1248 0.0784084 11.2294L0.770274 11.9216C0.82046 11.9717 0.888597 12 0.959642 12C1.03069 12 1.0987 11.9717 1.14888 11.9216L5.99991 7.0706L10.8509 11.9216C10.9011 11.9717 10.9694 12 11.0403 12C11.1112 12 11.1795 11.9717 11.2297 11.9216L11.9215 11.2294C12.0259 11.1248 12.0259 10.9554 11.9215 10.8509L7.07025 6Z" />
  </svg>
);

Close.propTypes = {
  style: shape({}),
};

Close.defaultProps = {
  style: null,
};
