import { useEffect } from 'react';

import {
  Footer,
  PageContainer,
  QuestionsSectionSellerLA,
  AboutPlumBidLA,
  DescriptionOptimizer,
  SellerBenefits,
  LearnMoreLA,
} from 'components';

export const SellersPageLA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <SellerBenefits />
      <DescriptionOptimizer />
      <QuestionsSectionSellerLA />
      <AboutPlumBidLA />
      <LearnMoreLA />
      <Footer />
    </PageContainer>
  );
};
