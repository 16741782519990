import gql from 'graphql-tag';

export const SET_PLUMBID_DATES_MUTATION = gql`
  mutation SetPlumbidDates($input: SetPlumBidDatesInput!) {
    setPlumbidDates(input: $input) {
      success
      errors
    }
  }
`;
