import { Formik } from 'formik';
import { object, string } from 'yup';

import { useMemo, useState } from 'react';
import { LinkButton, Input, PBox } from 'legos';
import { formatToTitleCase, getParticipantField } from 'utils';
import { useResponsive } from 'utils/hooks';

const ValidationSchema = object({
  fullName: string().trim().nullable(),
  email: string().trim().email('Invalid email').required('Email is required').nullable(),
});

export const AddSellerForm = ({ seller, addSeller, resetChange, loading, setAddOrEditSeller }) => {
  const responsive = useResponsive();
  const [emailError, setEmailError] = useState(false);
  const initialValues = useMemo(
    () =>
      seller
        ? {
            id: seller.id,
            email: getParticipantField(seller, 'email'),
            fullName: getParticipantField(seller, 'fullName'),
          }
        : {
            id: null,
            email: '',
            fullName: '',
          },
    [seller]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        const res = await addSeller(values, initialValues);
        if (res === 'Participant already take a part in this plumBid.') {
          setEmailError('This participant has already been added.');
        } else {
          setAddOrEditSeller(null);
        }
      }}
    >
      {({ values, touched, errors, handleChange, setFieldValue, handleSubmit }) => (
        <PBox display="flex" alignItems="flex-start" justifyContent="space-between" width="100%">
          <PBox
            pr={1}
            width="100%"
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection={responsive.smallScreen ? 'column' : 'row'}
          >
            <Input
              fullWidth
              type="text"
              name="fullName"
              placeholder="Full name"
              value={values.fullName}
              onChange={({ target: { value } }) => setFieldValue('fullName', formatToTitleCase(value))}
              error={touched.fullName && errors.fullName}
            />

            <PBox pr={1} />

            <Input
              fullWidth
              name="email"
              type="email"
              placeholder="Email"
              value={values.email}
              onChange={(e) => {
                setEmailError(false);
                handleChange(e);
              }}
              error={(touched.email && errors.email) || emailError}
            />
          </PBox>
          <PBox display="flex" width={80} pt={1}>
            <LinkButton
              color="link"
              icon="Accept"
              iconSize={16}
              loading={loading}
              style={{
                padding: 8,
                borderRadius: '50%',
                background: '#0000000D',
              }}
              onClick={handleSubmit}
              disabled={emailError}
            />
            <PBox pr={1} />
            <LinkButton
              icon="Close"
              color="link"
              iconSize={10}
              disabled={loading}
              onClick={resetChange}
              style={{
                padding: 12,
                borderRadius: '50%',
                background: '#0000000D',
              }}
            />
          </PBox>
        </PBox>
      )}
    </Formik>
  );
};
