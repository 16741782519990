import { Fragment, useCallback, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';

import { PBox, Divider, LinkButton } from 'legos';
import { DIALOG, NEW_PARTICIPANT_STATUS, NEW_PARTY_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import { useDialog, useNotification, useResponsive } from 'utils/hooks';
import { getBuyerWarningMessage, getParticipantField } from 'utils';
import {
  CREATE_PARTICIPANT_MUTATION,
  UPDATE_PARTICIPANT_MUTATION,
  REMOVE_PARTICIPANT_INVITE_MUTATION,
} from 'service/graphql';
import { PreviewImageButton } from './PreviewImageButton';
import { ParticipantDetailsItem } from './ParticipantDetailsItem';

const draftParticipant = {
  id: null,
  edited: true,
  status: NEW_PARTICIPANT_STATUS.PENDING,
  onboardingStatus: '',
  link: '',
  email: '',
  emailOld: '',
  fullName: '',
  fullNameOld: '',
  role: ROLES.BUYER,
  roleOld: ROLES.BUYER,
};

const getParticipant = participant => ({
  status: participant.status,
  onboardingStatus: participant.onboardingStatus,
  id: participant.id,
  edited: false,
  role: participant.role,
  roleOld: participant.role,
  link: participant.invite?.link,
  email: getParticipantField(participant, 'email'),
  emailOld: getParticipantField(participant, 'email'),
  fullName: getParticipantField(participant, 'fullName'),
  fullNameOld: getParticipantField(participant, 'fullName'),
  exist: !!participant.user,
});

export const ParticipantDetails = ({
  plumBid,
  curParty,
  selectedParty,
  plumBidRefetch,
  plumBidLoading,
  isButtonDisabled,
  setIsOfferChanged,
  createInvitedMutation,
  idEditingParticipant,
  setIdEditingParticipant,
}) => {
  const responsive = useResponsive();
  const showNotification = useNotification();
  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: changePropsOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const [inputError, setInputError] = useState({});
  const [inputWarning, setInputWarning] = useState({});
  const [newParticipant, setNewParticipant] = useState([]);
  const participantRef = useRef();

  const [removeParticipant] = useMutation(REMOVE_PARTICIPANT_INVITE_MUTATION);
  const [createParticipantMutation, { loading: createParticipantLoading }] = useMutation(CREATE_PARTICIPANT_MUTATION);
  const [updateParticipantMutation, { loading: updateParticipantLoading }] = useMutation(UPDATE_PARTICIPANT_MUTATION);
  const buttonLoading = createParticipantLoading || updateParticipantLoading;

  const disableAddParticipant =
    idEditingParticipant ||
    isButtonDisabled ||
    (curParty.status === NEW_PARTY_STATUS.COMPLETED && plumBid?.service?.name === 'multipleoffers') ||
    (plumBid?.service?.name === 'fullservice' &&
      !(plumBid?.status === PLUMBID_STATUS.INCOMPLETE || plumBid?.status === PLUMBID_STATUS.UPCOMING));

  const getLink = async participant => {
    if (participant.link) {
      return participant.link;
    } else {
      try {
        const { data } = await createInvitedMutation({
          variables: {
            input: {
              ...(participant.id ? { participantId: participant.id } : null),
              email: participant.email,
              role: participant.role,
              fullName: participant.fullName,
              plumbidId: plumBid.id,
              plumbidPartyId: selectedParty,
              copied: true,
            },
          },
        });
        plumBidRefetch();
        return data.createInvite.invite.link;
      } catch (error) {
        showNotification({ error });
      }
    }
  };

  const saveParticipantChange = useCallback(
    async participant => {
      try {
        const { data } = participant.id
          ? await updateParticipantMutation({
              variables: {
                input: {
                  participantId: participant.id,
                  fullName: participant.fullName?.trim(),
                  role: participant.role,
                  email: participant.email?.trim(),
                },
              },
            })
          : await createParticipantMutation({
              variables: {
                input: {
                  offerSettingsId: curParty?.offersettings?.id,
                  plumbidPartyId: selectedParty,
                  email: participant?.email,
                  role: participant.role,
                  fullName: participant.fullName,
                },
              },
            });
        const error = participant.id ? data?.updateParticipant?.errors : data?.createParticipant?.errors;

        if (error) {
          if (error.includes('Participant already take a part in this plumBid.')) {
            showNotification({ error });
            return 'Participant already take a part in this plumBid.';
          } else {
            showNotification({ error });
          }
        }

        if (data?.createParticipant?.participant?.offerParticipant?.fullName === '') {
          showNotification({
            message: 'Please add participant Name.',
            variant: 'warning',
          });
        }

        if (data?.updateParticipant?.fullName && participant.email.trim() !== participant.emailOld) {
          showNotification({
            message: 'User name has been changed because the user is already registered',
            variant: 'warning',
          });
          return 'User name has been changed because the user is already registered';
        }
      } catch (error) {
        showNotification({
          error,
        });
      } finally {
        plumBidRefetch();
      }
    },
    [
      updateParticipantMutation,
      createParticipantMutation,
      curParty?.offersettings?.id,
      selectedParty,
      plumBidRefetch,
      showNotification,
    ]
  );

  const participantSet = curParty.participantSet.reduce(
    (acc, participant) => {
      if (participant.id && participant.offerParticipant.fullName === '' && participant.offerParticipant.email === '') {
        return saveParticipantChange({ ...participant, fullName: 'New participant' });
      }

      if (
        participant.id &&
        participant.offerParticipant.fullName === '' &&
        !participant.user?.fullName &&
        !inputWarning[`${participant.id}fullName`]
      ) {
        setInputWarning({
          [`${participant.id}fullName`]: 'Please add participant name',
        });
      }

      if (
        participant.id &&
        (participant.offerParticipant.fullName || participant.user?.fullName) &&
        inputWarning[`${participant.id}fullName`]
      ) {
        setInputWarning({
          [`${participant.id}fullName`]: null,
        });
      }

      if (participant.role === ROLES.BUYER && participant.isParsed) {
        acc.buyers?.push(getParticipant(participant));
      }
      if (participant.role === ROLES.BUYER && !participant.isParsed) {
        acc.buyersNotParsed?.push(getParticipant(participant));
      }
      if (participant.role === ROLES.BUYER_AGENT && participant.isParsed) {
        acc.buyerAgents?.push(getParticipant(participant));
      }
      if (participant.role === ROLES.BUYER_AGENT && !participant.isParsed) {
        acc.buyerAgentsNotParsed?.push(getParticipant(participant));
      }
      return acc;
    },
    { buyers: [], buyersNotParsed: [], buyerAgents: [], buyerAgentsNotParsed: [] }
  );

  const deleteParticipant = participantId => {
    if (participantId) {
      openOkCancelDialog({
        title: 'Attention',
        text: 'Are you sure you want to remove this participant?',
        okTitle: 'OK',
        onOk: async () => {
          changePropsOkCancelDialog({ loading: true });
          try {
            const { data } = await removeParticipant({
              variables: {
                input: {
                  participantId,
                },
              },
            });
            await plumBidRefetch();
            if (data?.removeParticipantInvite?.errors) {
              throw Error(data.removeParticipantInvite.errors);
            }
            closeOkCancelDialog();
          } catch (error) {
            closeOkCancelDialog();
            showNotification({ error });
          } finally {
            changePropsOkCancelDialog({ loading: false });
          }
        },
      });
    }
  };

  const handleAddParticipant = useCallback(() => {
    if (newParticipant?.length > 0) {
      setTimeout(() => participantRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    } else {
      setNewParticipant([...newParticipant, draftParticipant]);
      setTimeout(() => participantRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    }
  }, [newParticipant]);

  return (
    <>
      <PBox pt={5} display="flex" alignItems="center">
        <Typography variant="h2">Participants</Typography>
      </PBox>
      <PBox display="center" alignItems="center" my={2} ml={3}>
        <LinkButton
          title="Add New Participant"
          icon="CirclePlus"
          iconSize={13}
          color="link"
          style={{ fontWeight: 500 }}
          onClick={handleAddParticipant}
          disabled={disableAddParticipant}
        />
      </PBox>
      <PBox border="1px solid #EAE8E4">
        <Grid container>
          {participantSet?.buyers?.length > 0 && (
            <>
              <Grid item container xs={responsive.tabletScreen ? 12 : 7}>
                {participantSet.buyers.map((participant, index) => {
                  const participantStatusProps = getBuyerWarningMessage(participant);
                  return (
                    <Fragment key={`buyers${participant.id}`}>
                      <ParticipantDetailsItem
                        participant={participant}
                        index={index}
                        participantStatusProps={participantStatusProps}
                        isButtonDisabled={isButtonDisabled}
                        loading={buttonLoading}
                        plumBidLoading={plumBidLoading}
                        setIsOfferChanged={setIsOfferChanged}
                        deleteParticipant={deleteParticipant}
                        getLink={getLink}
                        idEditingParticipant={idEditingParticipant}
                        setIdEditingParticipant={setIdEditingParticipant}
                        inputError={inputError}
                        setInputError={setInputError}
                        inputWarning={inputWarning}
                        setInputWarning={setInputWarning}
                        saveParticipantChange={saveParticipantChange}
                        newParticipant={newParticipant}
                      />
                      {index < participantSet.buyers.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={responsive.tabletScreen ? 12 : 5}
                alignContent="center"
                style={{ display: 'grid', borderLeft: '1px solid #EAE8E4', borderBottom: '1px solid #EAE8E4' }}
              >
                {participantSet.buyers.length !== 0 && (
                  <PBox p={2}>
                    <PreviewImageButton imageurl={curParty?.offersettings?.buyerImage} />
                  </PBox>
                )}
              </Grid>
            </>
          )}
          {participantSet?.buyersNotParsed?.length > 0 && (
            <>
              <Grid item container xs={responsive.tabletScreen ? 12 : 7}>
                {participantSet.buyersNotParsed.map((participant, index) => {
                  const participantStatusProps = getBuyerWarningMessage(participant);
                  return (
                    <Fragment key={`buyersNotParsed${participant.id}`}>
                      <ParticipantDetailsItem
                        participant={participant}
                        index={index}
                        participantStatusProps={participantStatusProps}
                        isButtonDisabled={isButtonDisabled}
                        loading={buttonLoading}
                        plumBidLoading={plumBidLoading}
                        setIsOfferChanged={setIsOfferChanged}
                        deleteParticipant={deleteParticipant}
                        getLink={getLink}
                        idEditingParticipant={idEditingParticipant}
                        setIdEditingParticipant={setIdEditingParticipant}
                        inputError={inputError}
                        setInputError={setInputError}
                        inputWarning={inputWarning}
                        setInputWarning={setInputWarning}
                        saveParticipantChange={saveParticipantChange}
                        newParticipant={newParticipant}
                      />
                      {index < participantSet.buyersNotParsed.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid
                item
                xs={responsive.tabletScreen ? 12 : 5}
                style={{ display: 'grid', borderLeft: '1px solid #EAE8E4', borderBottom: '1px solid #EAE8E4' }}
              >
                {' '}
              </Grid>
            </>
          )}

          {participantSet?.buyerAgents?.length > 0 && (
            <>
              <Grid item container xs={responsive.tabletScreen ? 12 : 7}>
                {participantSet.buyerAgents.map((participant, index) => {
                  const participantStatusProps = getBuyerWarningMessage(participant);
                  return (
                    <Fragment key={`buyerAgents${participant.id}`}>
                      <ParticipantDetailsItem
                        participant={participant}
                        index={index}
                        participantStatusProps={participantStatusProps}
                        isButtonDisabled={isButtonDisabled}
                        loading={buttonLoading}
                        plumBidLoading={plumBidLoading}
                        setIsOfferChanged={setIsOfferChanged}
                        deleteParticipant={deleteParticipant}
                        getLink={getLink}
                        idEditingParticipant={idEditingParticipant}
                        setIdEditingParticipant={setIdEditingParticipant}
                        inputError={inputError}
                        setInputError={setInputError}
                        inputWarning={inputWarning}
                        setInputWarning={setInputWarning}
                        saveParticipantChange={saveParticipantChange}
                        newParticipant={newParticipant}
                      />
                      {index < participantSet.buyerAgents.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={responsive.tabletScreen ? 12 : 5}
                alignContent="center"
                style={{ display: 'grid', borderLeft: '1px solid #EAE8E4', borderBottom: '1px solid #EAE8E4' }}
              >
                {participantSet.buyerAgents.length !== 0 && (
                  <PBox p={2}>
                    <PreviewImageButton imageurl={curParty?.offersettings?.buyerAgentsImage} />
                  </PBox>
                )}
              </Grid>
            </>
          )}

          {participantSet?.buyerAgentsNotParsed?.length > 0 && (
            <>
              <Grid item container xs={responsive.tabletScreen ? 12 : 7}>
                {participantSet.buyerAgentsNotParsed.map((participant, index) => {
                  const participantStatusProps = getBuyerWarningMessage(participant);
                  return (
                    <Fragment key={`buyerAgentsNotParsed${participant.id}`}>
                      <ParticipantDetailsItem
                        participant={participant}
                        index={index}
                        participantStatusProps={participantStatusProps}
                        isButtonDisabled={isButtonDisabled}
                        loading={buttonLoading}
                        plumBidLoading={plumBidLoading}
                        setIsOfferChanged={setIsOfferChanged}
                        deleteParticipant={deleteParticipant}
                        getLink={getLink}
                        idEditingParticipant={idEditingParticipant}
                        setIdEditingParticipant={setIdEditingParticipant}
                        inputError={inputError}
                        setInputError={setInputError}
                        inputWarning={inputWarning}
                        setInputWarning={setInputWarning}
                        saveParticipantChange={saveParticipantChange}
                        newParticipant={newParticipant}
                      />
                      {index < participantSet.buyerAgentsNotParsed.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={responsive.tabletScreen ? 12 : 5}
                alignContent="center"
                style={{ display: 'grid', borderLeft: '1px solid #EAE8E4', borderBottom: '1px solid #EAE8E4' }}
              >
                {' '}
              </Grid>
            </>
          )}

          {newParticipant.length > 0 && (
            <>
              <Grid item container xs={responsive.tabletScreen ? 12 : 7} ref={participantRef}>
                {newParticipant.map((participant, index) => {
                  const participantStatusProps = getBuyerWarningMessage(participant);
                  return (
                    <Fragment key={`newParticipant${index}`}>
                      <ParticipantDetailsItem
                        participant={participant}
                        index={index}
                        participantStatusProps={participantStatusProps}
                        isButtonDisabled={isButtonDisabled}
                        loading={buttonLoading}
                        plumBidLoading={plumBidLoading}
                        setIsOfferChanged={setIsOfferChanged}
                        deleteParticipant={deleteParticipant}
                        getLink={getLink}
                        idEditingParticipant={idEditingParticipant}
                        setIdEditingParticipant={setIdEditingParticipant}
                        inputError={inputError}
                        setInputError={setInputError}
                        inputWarning={inputWarning}
                        setInputWarning={setInputWarning}
                        saveParticipantChange={saveParticipantChange}
                        newParticipant={newParticipant}
                      />
                      {index < newParticipant.length && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={responsive.tabletScreen ? 12 : 5}
                alignContent="center"
                style={{ display: 'grid', borderLeft: '1px solid #EAE8E4', borderBottom: '1px solid #EAE8E4' }}
              >
                {' '}
              </Grid>
            </>
          )}
        </Grid>
      </PBox>
    </>
  );
};
