import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import state from 'service/graphql/state';

export const useDialog = name => {
  const openDialog = useCallback(
    props => {
      const oldState = state.showDialogVar();

      state.showDialogVar({
        ...oldState,
        [name]: {
          isOpen: true,
          props,
        },
      });
    },
    [name]
  );

  const closeDialog = useCallback(() => {
    const oldState = state.showDialogVar();

    state.showDialogVar({
      ...oldState,
      [name]: {
        isOpen: false,
        props: null,
      },
    });
  }, [name]);

  const closeDialogByName = useCallback(name => {
    const oldState = state.showDialogVar();

    state.showDialogVar({
      ...oldState,
      [name]: {
        isOpen: false,
        props: null,
      },
    });
  }, []);

  const changeProps = useCallback(
    newProps => {
      const oldState = state.showDialogVar();
      if (oldState[name].isOpen) {
        const oldProps = oldState[name].props;
        state.showDialogVar({
          ...oldState,
          [name]: {
            isOpen: true,
            props: { ...newProps, ...oldProps },
          },
        });
      }
    },
    [name]
  );

  return {
    dialogs: useReactiveVar(state.showDialogVar),
    closeDialogByName,
    isOpenDialog: useReactiveVar(state.showDialogVar)[name]?.isOpen,
    props: useReactiveVar(state.showDialogVar)[name]?.props,
    openDialog,
    closeDialog,
    changeProps,
  };
};
