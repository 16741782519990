import gql from 'graphql-tag';

export const GET_OFFERS_MUTATION = gql`
  mutation GetOffersMutation($input: GetOffersInput!, $plumbidId: Int!) {
    getOffers(input: $input) {
      success
      errors
      offers(plumbidId: $plumbidId) {
        price
        amountDown
        percentageDown
        escrowLength
        vaLoan
        leaseBackIncentive
        inspectionContingency
        inspectionContingencyLength
        loanContingency
        loanContingencyLength
        appraisalContingency
        appraisalContingencyLength
        offerLeaseBack
        sellerContingency
        buyerWarranty
        buyerContingency
        downPaymentIncentive
        escrowIncentive
        inspectionContingencyIncentive
        inspectionContingencyLengthIncentive
        loanContingencyIncentive
        loanContingencyLengthIncentive
        appraisalContingencyIncentive
        appraisalContingencyLengthIncentive
        leaseBackIncentive
        sellerContingencyIncentive
        buyerWarrantyIncentive
        buyerContingencyIncentive
        totalIncentives
        plumBidAmount
      }
    }
  }
`;
