import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { BUYERS, ABOUT_US, BUYERS_LA } from 'routes';
import AboutPlumBidPng from 'assets/images/AboutPlumBid.png';

import { RouterLink } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AboutPlumBid = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const { pathname } = useLocation();
  const pathnameBuyer = pathname === BUYERS || pathname === BUYERS_LA;
  const pathnameAboutUs = pathname === ABOUT_US;

  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '30px 0' : '75px 0 80px 0',
        backgroundColor:
          pathnameBuyer || pathnameAboutUs
            ? theme.palette.background.benefits
            : theme.palette.background.card,
      }}
    >
      {!pathnameBuyer && (
        <Grid
          item
          xs={12}
          md={6}
          style={
            mdScreen
              ? { padding: 24 }
              : { paddingLeft: tabletScreen ? 0 : mdScreen ? 0 : 88 }
          }
        >
          <img style={{ width: '100%' }} src={AboutPlumBidPng} alt="" />
        </Grid>
      )}
      <Grid item container xs={12} md={6} justify="center" alignItems="center">
        <Box px={{ xs: 3, md: 10 }} pt={0}>
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            plumBid – invented by experts
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              padding: '26px 0',
            }}
          >
            While <b>plumBid</b> is an innovative concept, its founders leverage
            both an expertise in finance, as well as a 50+ year background in
            residential real estate. From Caltech educated economists, to real
            estate brokerage visionaries that have represented over ten thousand
            homes and billions of dollars in transactions.
          </Typography>

          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
            }}
          >
            In 2020, their firm was awarded “Most Innovative Brokerage” for
            their invention of plumBid by LeadingRE -- a global network of 565
            real estate companies representing 130,000 agents, across 70+
            countries.
            {pathnameAboutUs || (
              <RouterLink
                to={ABOUT_US}
                className="removeDecorators"
                style={{
                  fontSize: 18,
                  paddingLeft: 4,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                Learn more
              </RouterLink>
            )}
          </Typography>
        </Box>
      </Grid>
      {pathnameBuyer && (
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingRight: tabletScreen ? 40 : mdScreen ? 20 : 88,
            paddingLeft: mdScreen ? 20 : 0,
          }}
        >
          <img style={{ width: '100%' }} src={AboutPlumBidPng} alt="" />
        </Grid>
      )}
    </Grid>
  );
};
