import gql from 'graphql-tag';

export const CREATE_PARTICIPANT_MUTATION = gql`
  mutation CreateParticipant($input: CreateParticipantInput!) {
    createParticipant(input: $input) {
      success
      errors
      participant {
        id
        offerParticipant {
          fullName
        }
      }
    }
  }
`;
