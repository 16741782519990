import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';

import { Button, SplashDot } from 'legos';
import { NEW_PARTY_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import { UPDATE_PARTICIPANT_MUTATION } from 'service/graphql';
import { PLUMBID, BID_HISTORY, ADMIN_BID_HISTORY } from 'routes';
import { useAuthState } from 'service/store/authStore';
import state from 'service/graphql/state';

export const GoToOptimizer = ({ plumBid }) => {
  const [updateParticipantMutation] = useMutation(UPDATE_PARTICIPANT_MUTATION);
  const isMeLeader = plumBid?.lastBid?.owner?.user?.userId === plumBid?.myInfoInPlumBid?.meId;

  const [{ me }] = useAuthState();
  const iAmNotAdmin = !me.roles.includes(ROLES.ADMIN);
  const history = useHistory();
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);

  const isPlumBidJustStarted =
    plumBid?.myInfoInPlumBid?.myPopupStatus?.plumBidStarted && plumBid?.status === PLUMBID_STATUS.LIVE;

  const openPlumBidPage = useCallback(() => {
    if (me?.roles.includes(ROLES.ADMIN)) {
      history.push({
        pathname: ADMIN_BID_HISTORY,
        search: `?plumbid=${plumBid.id}`,
      });
    } else {
      if (iAmNotAdmin && isPlumBidJustStarted) {
        updateParticipantMutation({
          variables: {
            input: {
              participantId: plumBid.myInfoInPlumBid.participantId,
              plumBidStartedPopup: false,
            },
          },
        }).catch(() => { });
      }
      if (
        plumBid?.status === PLUMBID_STATUS.FINISHED ||
        (plumBid?.status === PLUMBID_STATUS.LIVE && !plumBidTimeLeft[plumBid?.id]) ||
        plumBid?.myInfoInPlumBid.role === ROLES.SELLER ||
        plumBid?.myInfoInPlumBid.role === ROLES.BUYER_AGENT
      ) {
        history.push({
          pathname: BID_HISTORY,
          search: `?plumbid=${plumBid.id}`,
        });
      } else {
        history.push({
          pathname: PLUMBID,
          search: `?plumbid=${plumBid.id}`,
        });
      }
    }
  }, [
    me?.roles,
    history,
    plumBid.id,
    plumBid?.status,
    plumBid?.myInfoInPlumBid.role,
    plumBid?.myInfoInPlumBid.participantId,
    iAmNotAdmin,
    isPlumBidJustStarted,
    plumBidTimeLeft,
    updateParticipantMutation,
  ]);

  const isFullService = plumBid?.service.name === 'fullservice';
  const isReservePriceMetInFullService = isFullService ? plumBid?.reservePriceMet : true

  const title =
    (plumBid?.status === PLUMBID_STATUS.FINISHED ||
      (plumBid?.status === PLUMBID_STATUS.LIVE && !plumBidTimeLeft[plumBid?.id])) &&
      plumBid.myInfoInPlumBid.role !== ROLES.BUYER && isReservePriceMetInFullService
      ? 'See Winner'
      : plumBid?.status === PLUMBID_STATUS.LIVE && plumBid.myInfoInPlumBid.role !== ROLES.BUYER
        ? 'Bid History'
        : (plumBid.myInfoInPlumBid.role === ROLES.BUYER &&
          (plumBid?.status === PLUMBID_STATUS.FINISHED ||
            (plumBid?.status === PLUMBID_STATUS.LIVE && !plumBidTimeLeft[plumBid?.id]))) ||
          plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT
          ? 'See Bid History'
          : plumBid.myInfoInPlumBid.role === ROLES.BUYER && isMeLeader
            ? 'See bids history'
            : plumBid.myInfoInPlumBid.role === ROLES.BUYER
              ? 'Start Bidding'
              : 'Bid History';

  const isDisabled =
    [PLUMBID_STATUS.UPCOMING, PLUMBID_STATUS.INCOMPLETE].some(status => plumBid?.status === status) ||
    ((plumBid.myInfoInPlumBid.role === ROLES.BUYER || plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT) &&
      plumBid.myInfoInPlumBid.myPartyStatus !== NEW_PARTY_STATUS.COMPLETED);

  const showSplashDot = isPlumBidJustStarted && iAmNotAdmin && !isDisabled;

  return (
    <Button
      fullWidth
      title={title}
      icon="RightArrow"
      onClick={openPlumBidPage}
      disabled={isDisabled}
      style={{ position: 'relative' }}
    >
      {showSplashDot && <SplashDot />}
    </Button>
  );
};
