import React, { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell as TableCellMaterial,
  TableHead,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import { ADMIN_PLUMBID } from 'routes';
import { GLOBAL } from 'constant';
import { PlumBidRowItem } from './PlumBidRowItem';
import { useUpdateQueryString } from 'utils/hooks';
import { theme } from 'utils/theme';

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))(TableCellMaterial);

export const PlumBidTable = ({ plumBids, fetchMore, pageInfo, loading }) => {
  const { pathname } = useLocation();

  const [{ pd }, updateQueryString] = useUpdateQueryString();

  const [showDetailsById, setShowDetailsById] = useState(pd);
  const changeShowDetailsById = useCallback(
    id => {
      setShowDetailsById(id);
      updateQueryString('pd', id);
    },
    [updateQueryString]
  );

  if (loading) {
    return (
      <Box pt={2} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box pt={2.5}>
        <InfiniteScroll
          pageStart={0}
          loadMore={async () => {
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
                first: GLOBAL?.ROWS_PER_PAGE,
              },
            });
          }}
          hasMore={pageInfo?.hasNextPage}
          loader={
            <Box pt={2} py={3} textAlign="center" key={pageInfo?.endCursor}>
              <CircularProgress />
            </Box>
          }
        >
          <TableContainer>
            <Table
              stickyHeader
              aria-label="Plumbids table"
              style={{
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  />
                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  >
                    <Typography variant="h4">Name</Typography>
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  >
                    <Typography variant="h4">Dates of plumBid</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  >
                    <Typography variant="h4">Status</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  >
                    <Typography variant="h4">Listing Agent</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 0,
                    }}
                  >
                    <Typography variant="h4">Amount</Typography>
                  </TableCell>
                  {pathname !== ADMIN_PLUMBID && (
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 0,
                      }}
                    >
                      <Typography variant="h4">Role</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {plumBids.map(({ plumBid, role }) => (
                  <PlumBidRowItem
                    role={role}
                    key={plumBid.id}
                    plumBid={plumBid}
                    plumBidId={plumBid.id}
                    pathname={pathname}
                    showDetailsById={showDetailsById}
                    setShowDetailsById={changeShowDetailsById}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </>
  );
};
