import { string, func, number } from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import { useLinkButtonStyle } from './style';
import { Icons } from 'legos';
import { CircularProgress } from '@material-ui/core';
import { theme } from 'utils/theme';

export const LinkButton = ({
  onClick,
  icon,
  iconSize,
  title,
  style,
  variant,
  color,
  children,
  disabled,
  border,
  loading,
  iconEnd,
  ...props
}) => {
  const linkButtonStyle = useLinkButtonStyle();
  const Icon = icon && Icons[icon] ? Icons[icon] : null;
  const _color =
    disabled || loading
      ? theme.palette.text.disabled
      : theme.palette.text[color] || color;

  return (
    <ButtonBase
      classes={linkButtonStyle}
      onClick={onClick}
      style={{
        textDecorationColor: _color,
        border: border,
        ...style,
      }}
      TouchRippleProps={{
        style: {
          color: theme.palette.purple.ripple,
        },
      }}
      disabled={disabled}
      {...props}
    >
      {loading && (
        <CircularProgress
          size={iconSize + 8}
          style={{ position: 'absolute' }}
        />
      )}
      {!iconEnd && Icon ? (
        <Icon
          style={{
            fill: _color,
            height: iconSize,
            width: iconSize,
            minHeight: iconSize,
            minWidth: iconSize,
          }}
        />
      ) : null}

      {title && (
        <Typography
          variant={variant}
          style={{
            color: _color,
            paddingLeft: icon ? 8 : 0,
            ...style,
          }}
        >
          {title}
        </Typography>
      )}
      {iconEnd && Icon ? (
        <Icon
          style={{
            fill: _color,
            height: iconSize,
            width: iconSize,
            minHeight: iconSize,
            minWidth: iconSize,
            marginLeft: 8,
          }}
        />
      ) : null}

      {children}
    </ButtonBase>
  );
};

LinkButton.propTypes = {
  onClick: func,
  icon: string,
  title: string,
  variant: string,
  color: string,
  iconSize: number,
};

LinkButton.defaultProps = {
  onClick: () => {},
  icon: null,
  title: null,
  variant: 'body1',
  color: 'primary',
  iconSize: 14,
};
