import { makeVar } from '@apollo/client';
import { LocalStorage } from 'utils/localStorage';

import { DIALOG, PROCESSING_STEP_COUNT } from 'constant';

const getDialogInit = () =>
  Object.keys(DIALOG).reduce((prev, cur) => {
    prev[DIALOG[cur]] = { isOpen: false, props: null };
    return prev;
  }, {});

export const state = {
  pageLoadingVar: makeVar({}),
  showDialogVar: makeVar(getDialogInit()),
  selectedChatVar: makeVar(0),
  tutorialOptimizerStepVar: makeVar(LocalStorage.getTutorialOptimizerStep()),
  tutorialToggleActiveVar: makeVar(LocalStorage.getTutorialToggle()),
  tutorialSkipRunVar: makeVar(false),
  tutorialPauseVar: makeVar(false),
  plumBidsTimerVar: makeVar({}),
  plumBidsTimerLeftVar: makeVar({}),
  globalPlumBidPopupVar: makeVar({}),
  isRunningAtomicOperationVar: makeVar(false),
  isWSConnectedVar: makeVar(true),
  signUpFormVar: makeVar({}),
  bidProcessingVar: makeVar({
    loading: false,
    currentStep: PROCESSING_STEP_COUNT,
    error: false,
    plumBidId: null,
  }),
};
