import moment from 'moment';
import MomentUtils from '@date-io/moment';
import React, { useMemo, useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  Box,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  IconButton,
  InputAdornment,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

import { Footer } from './Footer';
import { theme } from 'utils/theme';
import { TERM_OF_USE, USER_SANDBOX } from 'routes';
import state from 'service/graphql/state';
import { Button, Icons, Tooltip, RouterLink, Checkbox } from 'legos';
import { QuestionSubHeader } from './QuestionSubHeader';
import { formatUserFullName, formatUserNameChain } from 'utils/formatters';
import { ReviewOfferContent } from 'components/OfferModal/ReviewOfferContent';
import { useBuyersQuestions, useNotification, useDialog, useResponsive, useUpdateQueryString } from 'utils/hooks';
import { DIALOG, PLUMBID_STATUS, ROLES, NEW_PARTY_STATUS, NEW_PARTICIPANT_STATUS } from 'constant';
import { useSettingsState } from 'service/store/settingsStore';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { useAuthState } from 'service/store/authStore';
import { RedirectPage } from 'components/RedirectPage';

export const BuyerOnboardingForm = ({ buyerId, plumBidId, previewMode }) => {
  const history = useHistory();
  const [{ me }] = useAuthState();
  const [parsedSearch] = useUpdateQueryString();
  const showNotification = useNotification();
  const responsive = useResponsive();
  const scrollRef = useRef();
  const [, dispatchSettings] = useSettingsState();
  const readOnly = previewMode || !!parsedSearch.readonly;

  const [isShowedAutoFillDialog, setIsShowedAutoFillDialog] = useState(false);
  const [confirmAnswersLoading, setConfirmAnswersLoading] = useState(false);

  const { openDialog: openMakeRevisionOfferDialog } = useDialog(DIALOG.MAKE_REVISION_OFFER);
  const { openDialog: openOkCancelDialog, closeDialog: closeOkCancelDialog } = useDialog(DIALOG.OK_CANCEL);
  const { openDialog: openSurveyDialog, closeDialog: closeSurveyDialog } = useDialog(DIALOG.SURVEY);
  const {
    openDialog: openAutoFillQuestionnaireDialog,
    closeDialog: closeAutoFillQuestionnaireDialog,
    changeProps: changePropsAutoFillQuestionnaireDialog,
  } = useDialog(DIALOG.AUTO_FILL_QUESTIONNAIRE);

  const {
    refetch,
    mismatch,
    setAnswer,
    canSubmit,
    buyerParty,
    processing,
    plumBidData,
    plumBidError,
    buyersAnswers,
    plumBidLoading,
    plumBidRefetch,
    buyerQuestions,
    answeredBuyers,
    saveBuyerAnswers,
    anotherBuyersAnswer,
    confirmBuyerAnswers,
    isMeReachedToToFinish,
    confirmBuyerAnswersLoading,
  } = useBuyersQuestions(plumBidId, buyerId);

  const contingentNotQualified =
    plumBidData?.sellerParty.status === NEW_PARTY_STATUS.COMPLETED &&
    (plumBidData?.sellerParty.sellerPriorities?.offerContingent === false ||
      plumBidData?.sellerParty.sellerPriorities?.offerContingentSecondary === false);

  const myParty = useMemo(() => {
    if (plumBidData && buyerId) {
      return (
        plumBidData.buyerParty.find(party =>
          party.participantSet.some(participant => participant?.user?.userId === buyerId)
        ) || 'Party not found'
      );
    }
  }, [plumBidData, buyerId]);

  const disableEditAnswer =
    readOnly ||
    plumBidData?.status === PLUMBID_STATUS.FINISHED ||
    ((plumBidData?.status === PLUMBID_STATUS.LIVE || plumBidData?.status === PLUMBID_STATUS.UPCOMING) &&
      plumBidData.myInfoInPlumBid.role === ROLES.BUYER &&
      plumBidData.myInfoInPlumBid.myParty.status === NEW_PARTY_STATUS.COMPLETED);

  const isAnyoneCompleteOnboarding = plumBidData?.myInfoInPlumBid?.myParty?.participantSet?.some(
    item => item.confirmedAnswers
  );

  const offerIsBiggerCashDown =
    +plumBidData?.myInfoInPlumBid?.myParty?.offersettings?.offerPrice >
    +plumBidData?.myInfoInPlumBid?.myParty?.offersettings?.cashdownAmount;

  const isNeedChangeAnswer = buyersAnswers.some(answer => answer.answerId === 'Q2A4') && offerIsBiggerCashDown;

  const handleAutoFillQuestionnaireDialog = useCallback(() => {
    setIsShowedAutoFillDialog(true);

    openAutoFillQuestionnaireDialog({
      users: answeredBuyers,
      isSellerParty: false,
      onOk: async index => {
        try {
          changePropsAutoFillQuestionnaireDialog({ loading: true });
          const userId = answeredBuyers[index].userId;

          await saveBuyerAnswers({
            variables: {
              input: {
                plumbidId: plumBidId,
                answers: anotherBuyersAnswer[userId]
                  .filter(answer => answer.answerId)
                  .map(({ questionId, answerId, answerValue }) => ({
                    answerId,
                    answerValue: answerValue ? +answerValue : null,
                    questionId,
                  })),
              },
            },
          });
          await refetch();
        } catch (error) {
          showNotification({ error });
        } finally {
          closeAutoFillQuestionnaireDialog();
          changePropsAutoFillQuestionnaireDialog({ loading: false });
        }
      },
    });
  }, [
    refetch,
    plumBidId,
    answeredBuyers,
    showNotification,
    saveBuyerAnswers,
    anotherBuyersAnswer,
    openAutoFillQuestionnaireDialog,
    closeAutoFillQuestionnaireDialog,
    changePropsAutoFillQuestionnaireDialog,
  ]);

  useEffect(() => {
    if (
      !isShowedAutoFillDialog &&
      !processing &&
      answeredBuyers?.length &&
      buyerQuestions &&
      plumBidData?.myInfoInPlumBid.role === ROLES.BUYER &&
      isAnyoneCompleteOnboarding &&
      (!isMeReachedToToFinish || plumBidData?.myInfoInPlumBid.myStatus !== NEW_PARTICIPANT_STATUS.COMPLETED) &&
      !readOnly
    ) {
      handleAutoFillQuestionnaireDialog();
    }
  }, [
    readOnly,
    processing,
    buyersAnswers,
    buyerQuestions,
    answeredBuyers,
    isMeReachedToToFinish,
    isShowedAutoFillDialog,
    plumBidData?.myInfoInPlumBid.role,
    handleAutoFillQuestionnaireDialog,
    plumBidData?.myInfoInPlumBid.myPartyStatus,
    plumBidData?.myInfoInPlumBid.myOnboardingStatus,
    plumBidData?.myInfoInPlumBid.myPartyOnboardingStatus,
    plumBidData?.myInfoInPlumBid.myStatus,
    isAnyoneCompleteOnboarding,
  ]);

  useEffect(() => {
    plumBidRefetch();
  }, [plumBidId, plumBidRefetch]);

  useEffect(() => {
    if (!readOnly) {
      setTimeout(() => scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    }
  }, [setAnswer, readOnly]);

  const getDatePickerError = answer => {
    if (buyersAnswers.find(answer => answer.questionId === 'Q4')) {
      if ((answer.questionId === 'Q3.4' || answer.questionId === 'Q3.5') && answer.answerValue === '') {
        return true;
      }
    }
    return false;
  };

  const submitForm = () => {
    setIsShowedAutoFillDialog(true);
    setConfirmAnswersLoading(true);
    const confirmAnswers = () => {
      dispatchSettings({
        type: 'refreshScreen',
        payload: `PlumBidItem${plumBidId}`,
      });

      state.isRunningAtomicOperationVar(true);
      confirmBuyerAnswers({
        variables: {
          plumbidId: plumBidId,
        },
      })
        .then(res => {
          if (res?.data?.confirmBuyerAnswers?.errors) {
            showNotification({ error: res.data.confirmBuyerAnswers.errors });
            state.isRunningAtomicOperationVar(false);
          } else if (res?.data?.confirmBuyerAnswers?.success) {
            plumBidRefetch().then(response => {
              const refetchPlumbidData =
                response?.data?.plumbidById && normalizePlumBid(response.data.plumbidById, false, me);
              if (
                !contingentNotQualified &&
                refetchPlumbidData &&
                plumBidData.myInfoInPlumBid.myParty.status !== NEW_PARTY_STATUS.COMPLETED &&
                (refetchPlumbidData.myInfoInPlumBid.myOnboardingStatus.incorrectOffer ||
                  !refetchPlumbidData.myInfoInPlumBid.myOnboardingStatus.mismatch)
              ) {
                openOkCancelDialog({
                  okTitle: refetchPlumbidData?.status === PLUMBID_STATUS.LIVE ? 'Start Bidding' : 'OK',
                  hideCancel: true,
                  title: 'Attention',
                  text: refetchPlumbidData.myInfoInPlumBid.myOnboardingStatus.incorrectOffer
                    ? 'The listing agent will reach out to you to correct the details of your offer'
                    : refetchPlumbidData?.status === PLUMBID_STATUS.LIVE
                    ? 'You’re all set. Click to see the bidding progress and start bidding.'
                    : 'You’re all set. The plumBid will begin when the sellers and a second buyer have on-boarded.',
                  onOk: () => {
                    closeOkCancelDialog();
                    history.goBack();
                    state.isRunningAtomicOperationVar(false);
                  },
                  onClose: () => {
                    closeOkCancelDialog();
                    history.goBack();
                    state.isRunningAtomicOperationVar(false);
                  },
                });
              } else {
                history.goBack();
                state.isRunningAtomicOperationVar(false);
              }
            });
          } else {
            history.goBack();
            state.isRunningAtomicOperationVar(false);
          }
        })
        .catch(error => {
          showNotification({ error });
          state.isRunningAtomicOperationVar(false);
        });
    };

    if (mismatch) {
      openSurveyDialog({
        type: 'buyer',
        onOk: () => {
          confirmAnswers();
          closeSurveyDialog();
        },
        onClose: () => {
          closeSurveyDialog();
          setConfirmAnswersLoading(false);
        },
      });
    } else {
      confirmAnswers();
    }
  };

  const showContingencyDialog = () => {
    if (buyersAnswers.find(answer => answer.questionId === 'Q3' && answer.answerId === 'Q3A1')) {
      openOkCancelDialog({
        okTitle: 'OK',
        hideCancel: true,
        title: 'Attention',
        text:
          'Seller has decided not to qualify contingent offers for participating in plumBid. Contact your Buyer Agent to know your options, or remove contingency in the onboarding.',
        onOk: () => {
          closeOkCancelDialog();
          submitForm();
        },
        onClose: () => {
          closeOkCancelDialog();
          submitForm();
        },
      });
    } else {
      submitForm();
    }
  };

  const setRadioAnswer = (answerId, number, question, curAnswer) => {
    if (answerId === 'Q2.1A1') {
      openOkCancelDialog({
        okTitle: 'I agree',
        cancelTitle: 'I disagree',
        title: 'Share contact details',
        text:
          'By clicking "I agree" you consent to us sharing your contact details with a trusted loan broker so that they can contact you about suitable loan options',
        onOk: () => {
          setAnswer(number, answerId, question, curAnswer.answerValue);
          closeOkCancelDialog();
        },
        onClose: () => {
          setAnswer(number, 'Q2.1A2', question, curAnswer.answerValue);
          closeOkCancelDialog();
        },
      });
    } else {
      if (answerId === 'Q1A1') {
        openMakeRevisionOfferDialog();
      }
      setAnswer(number, answerId, question, curAnswer.answerValue);
    }
  };

  const progress = useMemo(() => {
    if (!buyerQuestions || !buyersAnswers) {
      return 0;
    }

    const totalProgress = buyersAnswers.reduce((acc, cur) => {
      if (cur.answerProgress) {
        acc += cur.answerProgress;
      }
      return acc;
    }, 0);

    return totalProgress;
  }, [buyerQuestions, buyersAnswers]);
  const userIsListingAgentAndBuyerNotStartAnswer =
    buyersAnswers.length === 1 &&
    !buyersAnswers[0].answerId &&
    plumBidData?.myInfoInPlumBid.role === ROLES.LISTING_AGENT;

  const showBuyerNotAnswered = !plumBidLoading && userIsListingAgentAndBuyerNotStartAnswer;
  const showBuyersAnswers = !plumBidLoading && buyerQuestions && !userIsListingAgentAndBuyerNotStartAnswer;

  const renderAnswersAnotherSeller = (partyAnswers, answerId) => {
    const anotherAnswer = partyAnswers.filter(item => item.answerId === answerId);
    if (anotherAnswer.length) {
      return (
        <Typography component="span" style={{ fontStyle: 'italic' }}>
          (This answer was selected by:{' '}
          {formatUserNameChain(
            anotherAnswer.map(
              item =>
                `${formatUserFullName(item.owner.user)}${
                  item.answerValue && !['Q16A1', 'Q16A2'].some(answerId => item.answerId === answerId)
                    ? ` [${item.answerValue} day${item.answerValue > 1 ? 's' : ''}]`
                    : ''
                }`
            )
          )}
          )
        </Typography>
      );
    }
  };

  const renderAnswerTooltip = answer => {
    return ['Q2A1', 'Q2A2', 'Q2A3'].some(item => item === answer.answerId) ? (
      <Tooltip text={answer.hint || 'Some text'} placement={responsive.mobileScreen ? 'top' : 'right-start'}>
        <Icons.QuestionMark />
      </Tooltip>
    ) : null;
  };

  const renderDateInput = (curAnswer, number, question) => {
    return question.answerList.map(answer => {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} key={answer.answerId}>
          <DatePicker
            orientation="portrait"
            variant="dialog"
            format="MM/DD/yyyy"
            minDate={moment().add(1, 'day')}
            id="date"
            value={curAnswer.answerValue ? moment(curAnswer.answerValue * 1000) : null}
            disabled={disableEditAnswer}
            onChange={date =>
              setAnswer(
                number,
                answer.answerId,
                question,
                moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix()
              )
            }
            inputVariant="filled"
            error={getDatePickerError(curAnswer)}
            style={{
              borderLeft: getDatePickerError(curAnswer) ? '2px solid red' : 'unset',
              borderRight: getDatePickerError(curAnswer) ? '2px solid red' : 'unset',
              borderTop: getDatePickerError(curAnswer) ? '2px solid red' : 'unset',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Icons.CalendarNew />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      );
    });
  };

  const renderCheckbox = (curAnswer, number, question) => {
    const answerId = curAnswer.answerId === 'Q5A1' ? 'Q5A2' : 'Q5A1';
    return (
      <Box mt={1.5} display="flex" alignItems="center">
        <Checkbox
          disabled={disableEditAnswer}
          checked={curAnswer.answerValue}
          onChange={e => {
            setAnswer(number, answerId, question, e.target.checked);
          }}
        />
        <Typography>Please acknowledge and accept</Typography>
      </Box>
    );
  };

  const renderRadioGroup = (curAnswer, number, question) => {
    return (
      <>
        {question.type === 'reviewOffer' && (
          <Box pb={1}>
            <ReviewOfferContent plumBid={plumBidData} offerDetails={{ payload: myParty.offersettings }} asOfferCard />
          </Box>
        )}
        <RadioGroup
          id={curAnswer.questionId}
          value={curAnswer.answerId}
          onChange={e => setRadioAnswer(e.target.value, number, question, curAnswer)}
          disabled={disableEditAnswer}
        >
          {question.answerList.map(answer => {
            if (answer.answerId === 'Q2A4' && offerIsBiggerCashDown) {
              return null;
            }
            return (
              <Fragment key={answer.answerId}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      key={answer.answerId}
                      value={answer.answerId}
                      control={
                        <Radio
                          disabled={
                            (answer.answerId === 'Q2A4' && offerIsBiggerCashDown) ||
                            processing ||
                            disableEditAnswer ||
                            (buyerParty.offersettings?.agreementContingent && curAnswer.questionId === 'Q3') ||
                            (buyerParty.offersettings?.cashdownAmount === 0 &&
                              curAnswer.questionId === 'Q2' &&
                              (answer.answerId === 'Q2A1' || answer.answerId === 'Q2A4'))
                          }
                          style={{
                            color: theme.palette.primary.main,
                          }}
                          size="small"
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center">
                          <Box mr={1}>{renderAnswerTooltip(answer)}</Box>
                          {answer.text}
                        </Box>
                      }
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '17px',
                        height: 30,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {renderAnswersAnotherSeller(question.partyAnswers, answer.answerId)}
                  </Grid>
                </Grid>
                {curAnswer.answerId === 'Q4A2' && answer.answerId === 'Q4A2' ? (
                  <Box pl={2} pt={responsive.mdScreen ? 3 : 1}>
                    <Typography variant="body2" style={{ color: theme.palette.text.error, fontWeight: 400 }}>
                      To complete the onboarding you have to agree to the plumBid Terms of Conditions
                    </Typography>
                  </Box>
                ) : null}
              </Fragment>
            );
          })}
        </RadioGroup>
      </>
    );
  };

  const renderAnswers = (curAnswer, number, question) => {
    return (
      <Box maxWidth={650}>
        {question.type === 'dateInput'
          ? renderDateInput(curAnswer, number, question)
          : question.type === 'checkbox'
          ? renderCheckbox(curAnswer, number, question)
          : renderRadioGroup(curAnswer, number, question)}
      </Box>
    );
  };

  const renderQuestionTitle = (curAnswer, number, question) => {
    let text1 = question.text1;

    if (curAnswer.questionId === 'Q4') {
      text1 = text1.replace('Terms & Conditions?', '');
    }

    if (question.type === 'checkbox') {
      return (
        <Box mr={{ xs: 1, md: 5 }}>
          <Box maxWidth={650} py={2}>
            <Typography
              variant="h4"
              component="span"
              align="justify"
              id={`question-id-${curAnswer.questionId}`}
              style={{
                lineHeight: '22px',
                verticalAlign: 'top',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {`${question.number}. `}
              {question.tooltip ? (
                <Tooltip style={{ marginTop: -2 }} text={question.tooltip} placement="top">
                  <Icons.QuestionMark />
                </Tooltip>
              ) : null}
              {text1}
            </Typography>

            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text2}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text3}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text4}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 32,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text5}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box mr={{ xs: 1, md: 5 }}>
        <Box maxWidth={650} py={2}>
          <Typography
            variant="h4"
            component="span"
            id={`question-id-${curAnswer.questionId}`}
            style={{
              lineHeight: '22px',
              verticalAlign: 'top',
              scrollPaddingTop: '-10rem',
              display: 'flex',
            }}
          >
            {`${question.number}. `}
            {question.tooltip ? (
              <Tooltip style={{ marginTop: -2 }} text={question.tooltip} placement="top">
                <Icons.QuestionMark />
              </Tooltip>
            ) : null}
            {text1}
            {curAnswer.questionId === 'Q4' ? (
              <Typography
                component="pre"
                variant="h4"
                style={{
                  lineHeight: '22px',
                }}
              >
                {' '}
                <RouterLink to={TERM_OF_USE} style={{ color: theme.palette.text.primary }}>
                  {'Terms & Conditions'}
                </RouterLink>
                {'?'}
              </Typography>
            ) : null}
          </Typography>

          {question.text2 && (
            <Typography variant="h5" style={{ paddingTop: 16 }}>
              {question.text2}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  if (myParty === 'Party not found') {
    return (
      <Typography variant="h4" component="span">
        Party not found
      </Typography>
    );
  }

  return plumBidData && myParty ? (
    <>
      {!previewMode && (
        <QuestionSubHeader
          plumbid={plumBidData}
          mismatch={mismatch}
          progress={progress}
          loading={processing || plumBidLoading}
        />
      )}
      <Box
        style={{
          boxSizing: 'border-box',
          maxWidth: 1440,
          margin: '0 auto',
        }}
      >
        <Box
          px={responsive.mobileScreen ? 2 : 5}
          pt={responsive.mobileScreen ? 2 : 6}
          pb={6}
          style={{
            maxWidth: '950px',
            marginBottom: 130,
          }}
        >
          {showBuyerNotAnswered && (
            <Box ml={{ xs: 1, md: 5 }}>
              <Typography style={{ fontSize: 26, fontWeight: 500 }}>
                Buyer has not yet started answering questions
              </Typography>
            </Box>
          )}
          {showBuyersAnswers &&
            buyersAnswers.map((curAnswer, number) => {
              const question = buyerQuestions[curAnswer.questionId];

              return (
                <Box
                  key={curAnswer.questionId}
                  ml={{ xs: 0, md: 5 }}
                  style={{
                    background:
                      isNeedChangeAnswer && question.number === '2' ? theme.palette.background.roseWhite : 'unset',
                  }}
                  id="tutorial__QuestionOnboarding"
                >
                  {renderQuestionTitle(curAnswer, number, question)}
                  {renderAnswers(curAnswer, number, question)}
                </Box>
              );
            })}
          {(processing || plumBidLoading) && (
            <Box mt={2} ml={{ xs: 0, md: 5 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>

        {!readOnly && (
          <Footer>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justify="flex-end"
              spacing={2}
              style={{ height: responsive.mdScreen ? 187 : 117, textAlign: responsive.mdScreen ? 'center' : 'end' }}
            >
              <Grid item xs={responsive.mdScreen ? 12 : 7} md={8}>
                <Typography variant="h4" component="div">
                  If you would like to see and get comfortable with how the plumBid bidding platform works - please
                  check out{' '}
                  <RouterLink to={USER_SANDBOX} rel="noreferrer" style={{ color: theme.palette.text.primary }}>
                    plumBid Sandbox
                  </RouterLink>
                </Typography>
              </Grid>
              <Grid item xs={responsive.mdScreen ? 12 : 'auto'}>
                <Button
                  title="Confirm"
                  icon="RightArrow"
                  disabled={!canSubmit || disableEditAnswer || isNeedChangeAnswer}
                  loading={processing || confirmBuyerAnswersLoading || confirmAnswersLoading}
                  onClick={contingentNotQualified ? showContingencyDialog : submitForm}
                  style={{ width: 183 }}
                />
              </Grid>
            </Grid>
          </Footer>
        )}
        <Box ref={scrollRef} height={1} width={1} />
      </Box>
    </>
  ) : plumBidError && !plumBidData ? (
    <RedirectPage />
  ) : null;
};
