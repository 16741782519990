export const OptimizerStep = middleScreen => {
  const steps = [
    {
      target: '#tutorial__YouPay',
      content: 'You Pay',
      title: 'Tutorial',
      placement: 'bottom-start',
      placementBeacon: 'left',
      disableOverlayClose: true,
      disableBeacon: true,
    },
    {
      target: '#tutorial__OptimizerParameters',
      content: 'Optimizer parameters ',
      title: 'Tutorial',
      placement: 'top',
      placementBeacon: 'left',
      disableOverlayClose: true,
    },
    {
      target: '#tutorial__BidHistory',
      content: 'Bid history',
      title: 'Tutorial',
      placement: 'right-start',
      placementBeacon: 'left',
      disableOverlayClose: true,
    },
  ];
  return steps;
};
