import { useCallback, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';

export const useLazyQueryPromise = (query, options) => {
  const [execute, result] = useLazyQuery(query, options);

  const resolveRef = useRef();
  const rejectRef = useRef();

  useEffect(() => {
    if (result.called) {
      if (result.data !== undefined && resolveRef.current) {
        resolveRef.current(result.data);
      } else if (result.error !== undefined && rejectRef.current) {
        rejectRef.current(result.error);
      } else {
        return;
      }
      resolveRef.current = undefined;
      rejectRef.current = undefined;
    }
  }, [result.data, result.error, result.called]);

  const queryLazily = useCallback(
    (variables, context) => {
      execute({ variables, context });
      return new Promise((resolve, reject) => {
        if (result.called) {
          if (result.data !== undefined) {
            resolve(result.data);
          } else if (result.error !== undefined) {
            reject(result.error);
          }
        } else {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        }
      });
    },
    [execute, result.called, result.data, result.error]
  );

  return [queryLazily, result];
};
