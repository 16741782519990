import moment from 'moment';
import { useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';

import { theme } from 'utils/theme';
import { useResponsive } from 'utils/hooks';
import { LinkButton, Icons, PBox } from 'legos';

export const Footer = () => {
  const { mdScreen } = useResponsive();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
    if (!checked) {
      setTimeout(
        () =>
          window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
          }),
        200
      );
    }
  };

  return (
    <PBox bgcolor={theme.palette.background.footer} maxWidth="1440px">
      <Grid container>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ minHeight: '128px', padding: '8px 0' }}
        >
          <Grid item xs={12} sm={mdScreen ? 12 : 6}>
            <Grid item xs={10}>
              <Typography
                variant="h3"
                style={{
                  color: theme.palette.common.white,
                  paddingLeft: 24,
                  fontWeight: 700,
                }}
              >
                To learn more, or if would like to sell your home with plumBid,
                contact us:
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={mdScreen ? 12 : 6}
            direction="row"
            spacing={4}
          >
            <Grid
              item
              container
              direction="row"
              xs={12}
              md={12}
              justify="flex-start"
              style={{ paddingLeft: 40 }}
            >
              <Grid item xs={12} lg={6}>
                <a href="tel:626.229.9700" style={{ textDecoration: 'none' }}>
                  <PBox display="flex" alignItems="center">
                    <PBox width="25px">
                      <Icons.RoundedPhone />
                    </PBox>
                    <Typography
                      variant="h3"
                      style={{
                        color: theme.palette.common.white,
                        paddingLeft: 16,
                        fontWeight: 700,
                      }}
                    >
                      626.229.9700
                    </Typography>
                  </PBox>
                </a>
              </Grid>
              <Grid item xs={12} lg={6}>
                <a
                  href="mailto:info@plumbid.com"
                  style={{ textDecoration: 'none' }}
                >
                  <PBox display="flex" alignItems="center">
                    <PBox width="25px">
                      <Icons.RoundedMail />
                    </PBox>
                    <Typography
                      variant="h3"
                      style={{
                        color: theme.palette.common.white,
                        paddingLeft: 16,
                        fontWeight: 700,
                      }}
                    >
                      info@plumbid.com
                    </Typography>
                  </PBox>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{
            padding: '5px 25px',
            background: theme.palette.background.footerBottom,
          }}
          alignItems="center"
          justify="space-between"
        >
          <PBox
            display="flex"
            alignItems="center"
            flexDirection="column"
            py={1.4}
            width="100%"
          >
            <PBox
              display="flex"
              justifyContent="space-between"
              flexDirection={mdScreen ? 'column' : 'row'}
              width="100%"
              maxWidth="1350px"
            >
              <PBox
                display="flex"
                alignItems="center"
                width="100%"
                maxWidth="750px"
                style={{
                  marginBottom: checked ? 30 : 0,
                  overflow: checked ? 'unset' : 'hidden',
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.common.white,
                    lineHeight: '22px',
                    marginRight: 4,
                  }}
                >
                  We are pledged to the letter and spirit of U.S. policy for the
                  achievement of equal housing opportunity throughout the
                  Nation.
                  {!checked && (
                    <LinkButton
                      onClick={handleChange}
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: theme.palette.common.white,
                        textDecorationColor: theme.palette.common.white,
                        marginLeft: 8,
                        marginRight: 16,
                        marginBottom: 2,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Show More
                    </LinkButton>
                  )}
                </Typography>
              </PBox>
              {!mdScreen && (
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontSize: '16px',
                    lineHeight: '26px',
                  }}
                >
                  © {moment().year()}, 2016 -{' '}
                  {moment().subtract(1, 'years').year()} plumBid, Inc. ™ Equal
                  Housing Opportunity.
                </Typography>
              )}
            </PBox>
            <Collapse in={checked} disableStrictModeCompat>
              <PBox display="flex" alignItems="center" justifyContent="center">
                <PBox
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  maxWidth="1350px"
                >
                  <Typography
                    style={{
                      color: theme.palette.common.white,
                      marginBottom: 30,
                      lineHeight: '22px',
                    }}
                  >
                    plumBid is not the seller or listing agent in any
                    transaction. plumBid is the transaction platform in which
                    all plumBids are conducted. All bid invitations are listed
                    by the owner and/or their designated licensed real estate
                    agents and their respective real estate brokerage firm, who
                    hold a valid real estate license to list and sell
                    real-estate in the State of California. Sellers, buyers and
                    agents must meet all plumBid terms and conditions.
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.common.white,
                      marginBottom: 30,
                      lineHeight: '22px',
                    }}
                  >
                    *Average median price per square foot of homes sold via
                    plumBid to date vs average median price per square foot, by
                    zip code of properties sold during the same period, compiled
                    from MLS data. This is no guarantee for future sales sold
                    via plumBid. For complete information, please see:{' '}
                    <a
                      className="link-in-footer"
                      href="/term-of-use"
                      style={{ color: theme.palette.common.white }}
                    >
                      plumBid Terms and Conditions
                    </a>
                  </Typography>
                  <LinkButton
                    onClick={handleChange}
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: theme.palette.common.white,
                      textDecorationColor: theme.palette.common.white,
                    }}
                  >
                    Show less
                  </LinkButton>
                </PBox>
              </PBox>
            </Collapse>
          </PBox>
          {mdScreen && (
            <PBox>
              <Typography
                variant="h4"
                style={{
                  color: theme.palette.common.white,
                  fontSize: '16px',
                  marginBottom: 32,
                }}
              >
                © {moment().year()}, 2016 -{' '}
                {moment().subtract(1, 'years').year()} plumBid, Inc. ™ Equal
                Housing Opportunity.
              </Typography>
            </PBox>
          )}
        </Grid>
      </Grid>
    </PBox>
  );
};
