import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { NavigationButton, Button } from 'legos';
import { formatUserFullName } from 'utils/formatters';
import { ROLES, LISTING_AGENT_VERIFY_STATUS } from 'constant';
import { theme } from 'utils/theme';

export const UserPlumBidsHeader = ({ userDetails, onClickApprove, onClickReject }) => {
  const history = useHistory();

  const renderRoles = user => (
    <Grid container spacing={1} style={{ overflow: 'hidden' }}>
      {user?.roles?.map(role => (
        <Grid item>
          <Typography
            key={role}
            variant="h5"
            noWrap
            style={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              background: theme.palette.background.table,
              color:
                role === ROLES.LISTING_AGENT && user.listingAgentStatus !== LISTING_AGENT_VERIFY_STATUS.VERIFIED
                  ? theme.palette.disabled
                  : theme.palette.text.primary,
              width: 'auto',
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            {role}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
  return (
    <Grid container alignItems="center" justify="space-between" style={{ padding: '24px 0' }}>
      <Grid container alignItems="center" justify="flex-start" item xs={8}>
        <Grid item xs={1}>
          <NavigationButton
            icon="LeftArrow"
            fullWidth={false}
            onClick={history.goBack}
            color={theme.palette.common.white}
            style={{
              backgroundColor: theme.palette.purple.light,
              marginRight: 38,
            }}
          />
        </Grid>
        <Grid container item xs={8} direction="column">
          <Typography
            variant="h1"
            style={{
              textTransform: 'uppercase',
              marginBottom: 16,
            }}
          >
            {formatUserFullName(userDetails)}
          </Typography>
          <Grid item container direction="row">
            {renderRoles(userDetails)}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={4} justify="flex-end" spacing={2}>
        {userDetails.roles?.includes(ROLES.LISTING_AGENT) &&
          (userDetails.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.NEW ||
            userDetails.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.UNVERIFIED) && (
            <>
              <Grid item>
                <Button variant="tertiary" title="Reject" icon="Close" onClick={onClickReject} />
              </Grid>
              <Grid item>
                <Button title="Approve" icon="Accept" onClick={onClickApprove} />
              </Grid>
            </>
          )}
      </Grid>
    </Grid>
  );
};
