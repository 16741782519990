import React from 'react';
import { Redirect } from 'react-router-dom';

import { DASHBOARD, WIZARD } from 'routes';
import { useUpdateQueryString } from 'utils/hooks';
import { useAuthState } from 'service/store/authStore';
import { ROLES } from 'constant';

export const WizardExternalPage = () => {
  const [{ me }] = useAuthState();
  const [parsedSearch] = useUpdateQueryString();

  if (!me?.roles.includes(ROLES.ADMIN) || !parsedSearch.plumBid || !parsedSearch.step) {
    return (
      <Redirect
        to={{
          pathname: DASHBOARD,
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: WIZARD,
        state: { step: parsedSearch.step, plumBidId: parsedSearch.plumBid },
      }}
    />
  );
};
