import React from 'react';
import { List } from '@material-ui/core';

import { SandboxPlumBidItem } from './SandboxPlumBidItem';
import { SandboxPropertyItem } from './SandboxPropertyItem';

export const DashboardSandbox = ({ plumBids, properties, refetch }) => (
  <List>
    {properties.map(property => (
      <SandboxPropertyItem key={property.id} property={property} refetch={refetch} />
    ))}
    {plumBids && plumBids.map(plumBid => <SandboxPlumBidItem key={plumBid.id} plumBid={plumBid} refetch={refetch} />)}
  </List>
);
