import React from 'react';
import { Box } from '@material-ui/core';

import { Text, Spinner } from 'legos';
import { formatNumberToUSD } from 'utils/formatters';
import { SellerPriorities } from 'legos/SellerPriorities';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const BaseCard = ({
  style,
  improve,
  children,
  disabled,
  sellerPrioritiesTitle,
  sellerPrioritiesValue,
  loading,
  priorityNotApplied,
}) => {
  const responsive = useResponsive();

  return (
    <Box
      px={2.5}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={priorityNotApplied ? 'flex-start' : 'space-between'}
      height={responsive.smallScreen ? 365 : 325}
      style={{
        backgroundColor: theme.palette.background.card,
        borderRadius: 15,
        ...style,
      }}
    >
      {children}
      <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-end">
        <Box
          width="100%"
          height="24px"
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-start"
          color={theme.palette.purple.light}
        >
          <Text
            disabled={disabled}
            variant="caption"
            style={{
              textTransform: 'capitalize',
              fontSize: responsive.padScreen ? 14 : 16,
            }}
          >
            {`Improves your bid by: `}
          </Text>
          <Box pl={0.5}>
            {loading ? (
              <Spinner />
            ) : (
              <Text
                disabled={disabled}
                variant="caption"
                style={{
                  textTransform: 'capitalize',
                  fontSize: responsive.padScreen ? 14 : 16,
                }}
              >
                {formatNumberToUSD(improve ?? '-')}
              </Text>
            )}
          </Box>
        </Box>

        {!priorityNotApplied && (
          <Box mb={4} mt={2} width="100%">
            <SellerPriorities process={sellerPrioritiesValue} title={sellerPrioritiesTitle} disabled={disabled} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
