import React from 'react';
import { shape } from 'prop-types';
import { Tooltip } from 'legos/Tooltip';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const MaxValueContingencyWarning = ({ tooltipPlacement, text, style }) => {
  const responsive = useResponsive();
  return (
    <Tooltip
      text={
        text
          ? text
          : 'Please review your contingency length values according to the maximum amounts marked on the boxes'
      }
      placement={tooltipPlacement ? tooltipPlacement : responsive.mobileScreen ? 'top' : 'right-start'}
    >
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
          d="M12.0892 3.30968C12.9384 1.83871 15.0616 1.83871 15.9108 3.30968L23.7805 16.9403C24.6298 18.4113 23.5682 20.25 21.8697 20.25H6.13034C4.43181 20.25 3.37024 18.4113 4.2195 16.9403L12.0892 3.30968Z"
          fill={style?.fill ? style.fill : theme.palette.background.paleOrange}
        />
        <path
          d="M14.0081 9.52946L14.0081 12.7059"
          stroke={style?.stroke ? style.stroke : theme.palette.text.warning}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0081 15.8823L14.0001 15.8823"
          stroke={style?.stroke ? style.stroke : theme.palette.text.warning}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

MaxValueContingencyWarning.propTypes = {
  style: shape({}),
};

MaxValueContingencyWarning.defaultProps = {
  style: null,
};
