import gql from 'graphql-tag';

import { ExtendedUserType } from '../types';

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
    $inviteToken: String
  ) {
    tokenAuth(email: $email, password: $password, inviteToken: $inviteToken) {
      token
      refreshExpiresIn
      refreshToken
      user {
        ${ExtendedUserType}
      }
    }
  }
`;
