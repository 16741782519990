import gql from 'graphql-tag';

import { SBPlumBidType } from '../types';

export const GET_SB_PLUMBIDS_AND_PROPERTIES_QUERY = gql`
  query SbPlumBidsAndProperties($sandboxKey: String) {
    sbGetProperties {
      id
      title
      previewImage
      listingPrice
      amountDown
      idealEscrowLength
      escrowPriority
      inspectionPriority
      loanContingencyPriority
      appraisalContingencyPriority
      leaseBackLength
      leaseBackImportance
      sellerContingencyPriority
      buyerWarrantyPriority
      buyerContingencyPriority
      sbplumbidSet {
        edges {
          node {
            id
            plumbidId
            active
          }
        }
      }
    }
    sbPlumbidByUser(sandboxKey: $sandboxKey) {
      ${SBPlumBidType}
    }
  }
`;
