import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import { DIALOG, ROLES } from 'constant';
import { theme } from 'utils/theme';
import { Button, Icons } from 'legos';
import { useDialog, useNotification } from 'utils/hooks';
import state from 'service/graphql/state';
import { CREATE_PLUMBIDPARTY_MUTATION } from 'service/graphql';
import { useAuthState } from 'service/store';

export const UploadZipFormsOffer = ({ onUploadFile, children, plumBid, loading, plumBidRefetch, onSelect }) => {
  const { openDialog: openOkCancelDialog, closeDialog: closeOkCancelDialog } = useDialog(DIALOG.OK_CANCEL);
  const time = useReactiveVar(state.plumBidsTimerVar);
  const showNotification = useNotification();
  const [{ me }] = useAuthState();

  const [createParty, { loading: createPartyLoading }] = useMutation(CREATE_PLUMBIDPARTY_MUTATION, {
    variables: {
      input: {
        plumbidId: plumBid.id,
      },
    },
    onCompleted: data => {
      if (data?.createPlumbidParty.errors) {
        showNotification({ error: data.createPlumbidParty.errors });
      } else if (plumBidRefetch) {
        plumBidRefetch().finally(() => onSelect(data?.createPlumbidParty.plumbidParty, 0));
      }
    },
  });

  const handleUpload = useCallback(
    (...params) => {
      const isTimeLeft = time[plumBid.id].timeLeft + time[plumBid.id].responseTime - Date.now();
      if (isTimeLeft < 3600000 && time[plumBid.id].timeLeft !== 0) {
        openOkCancelDialog({
          hideCancel: true,
          title: 'Unable to add offer',
          text: 'Offers cannot be submitted with less than one hour remaining in the plumBid.',
          onOk: closeOkCancelDialog,
        });
      } else {
        onUploadFile(...params);
      }
    },
    [closeOkCancelDialog, onUploadFile, openOkCancelDialog, plumBid.id, time]
  );

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    onDrop: handleUpload,
    accept: 'application/pdf',
    // maxSize: 20971521,
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      minHeight="calc(100% - 4px)"
      bgcolor={isDragAccept ? theme.palette.background.suvaGrey : theme.palette.background.table}
      style={{ border: `2px dashed ${theme.palette.border.gainsboro}`, borderRadius: 4 }}
      {...getRootProps()}
      onClick={null}
    >
      <input {...getInputProps()} />
      <Box p={2} alignSelf="flex-start" width="calc(100% - 32px)">
        {children}
      </Box>
      <Box pt={2} pb={4} display="flex" alignItems="center" flexDirection="column">
        <Icons.UploadDocument />
        {me?.roles?.includes(ROLES.ADMIN) ? (
          <Box mt={2}>
            <Button
              icon="Add"
              variant="primary"
              title="Add offer"
              loading={createPartyLoading}
              style={{ width: 200 }}
              onClick={createParty}
            />
            <Box mt={2}>
              <Typography align="center">or</Typography>
            </Box>
          </Box>
        ) : null}
        <Box my={2}>
          <Button
            icon="Upload"
            variant="primary"
            title="Upload Offer PDF"
            loading={loading}
            style={{ width: 200 }}
            onClick={open}
          />
        </Box>
        <Typography>or Drag Them Here</Typography>
        <Box mt={4}>
          <Typography>Please upload .pdf files up to 20Mb</Typography>
        </Box>
      </Box>
    </Box>
  );
};
