import { Box, Typography } from '@material-ui/core';
import { useReactiveVar } from '@apollo/client';

import { LinkButton } from 'legos';
import { LocalStorage } from 'utils/localStorage';
import state from 'service/graphql/state';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const TooltipTutorial = ({ index, size, step, closeProps, primaryProps, tooltipProps, skipProps }) => {
  const responsive = useResponsive();

  const getStyleCloseButton = placement => {
    switch (placement) {
      case 'bottom':
        return { maxWidth: '172px', position: 'absolute', top: -20, right: 0 };

      case 'bottom-start':
        return { maxWidth: '172px', position: 'absolute', top: -20, left: 0 };
      case 'bottom-end':
        return { maxWidth: '172px', position: 'absolute', top: -20, left: 0 };
      case 'left':
      case 'left-start':
      case 'left-end':
        return { maxWidth: '172px', position: 'absolute', top: -35, right: '17px' };
      case 'right':
      case 'right-end':
      case 'right-start':
        return { maxWidth: '172px', position: 'absolute', top: -35, left: '17px' };
      case 'top':
      case 'top-start':
      case 'top-end':
        return { maxWidth: '172px', position: 'absolute', top: -35, right: 0 };

      default:
        return {};
    }
  };
  const isActiveSkip = useReactiveVar(state.tutorialSkipRunVar);

  const skipTutorial = () => {
    LocalStorage.setTutorialToggle(false);
    state.tutorialToggleActiveVar(false);
    state.tutorialPauseVar(false);
    state.tutorialSkipRunVar(true);
  };

  const closeTutorial = e => {
    if (isActiveSkip) {
      if (index === 0 && size === 1) {
        state.tutorialToggleActiveVar(false);
        LocalStorage.setTutorialToggle(false);
        state.tutorialSkipRunVar(false);
        skipProps.onClick(e);
      } else if (index === 1 && size === 2) {
        state.tutorialToggleActiveVar(false);
        LocalStorage.setTutorialToggle(false);
        state.tutorialSkipRunVar(false);
        skipProps.onClick(e);
      } else {
        closeProps.onClick(e);
      }
    } else {
      closeProps.onClick(e);
    }
  };

  return (
    <Box
      {...tooltipProps}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2}
      style={{
        maxWidth: responsive.padScreen ? 300 : 350,
        minWidth: 300,
        height: 'auto',
        backgroundColor: theme.palette.background.vulcan,
        borderRadius: '6px',
      }}
    >
      <Box style={getStyleCloseButton(step.placement)}>
        <LinkButton
          {...closeProps}
          onClick={e => closeTutorial(e)}
          style={{
            background: theme.palette.background.darkGrayishNavy,
            padding: '4px 8px',
            fontSize: '12px',
            borderRadius: '5px',
          }}
          title="CLOSE TUTORIAL"
          icon="Close"
          iconSize={9}
          color={theme.palette.common.white}
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        {step.title && (
          <Typography variant="h4" style={{ color: theme.palette.common.white, fontSize: 15 }}>
            {step.title}
          </Typography>
        )}
        <Typography variant="h4" style={{ color: theme.palette.common.white, fontSize: 15 }}>
          {`${index + 1}/${size}`}
        </Typography>
      </Box>
      <Box py={4}>
        <Typography variant="body1" style={{ color: theme.palette.common.white, fontSize: 13, lineHeight: '18px' }}>
          {step.content}
        </Typography>
      </Box>
      <Box display="flex" justifyContent={isActiveSkip ? 'flex-end' : 'space-between'}>
        {!isActiveSkip && (
          <LinkButton
            onClick={() => skipTutorial()}
            style={{
              backgroundColor: `${theme.palette.background.vulcan} !important`,
              color: 'white',
              padding: 5,
              fontSize: 15,
              fontWeight: 700,
              width: 126,
            }}
            border={`2px solid ${theme.palette.common.white}`}
            title="Skip tutorial"
            color={theme.palette.common.white}
            onChange={() => skipProps.onClick()}
          />
        )}
        {size > 1 && (
          <LinkButton
            {...primaryProps}
            style={{
              backgroundColor: `${theme.palette.background.vulcan} !important`,
              color: 'white',
              padding: 5,
              fontSize: 15,
              fontWeight: 700,
              width: 125,
            }}
            border={`2px solid ${theme.palette.common.white}`}
            title={index + 1 === size ? 'Finish' : 'Next'}
            color={theme.palette.common.white}
          />
        )}
      </Box>
    </Box>
  );
};
