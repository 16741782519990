import ReactDOM from 'react-dom';
import { useEffect, useState, useRef } from 'react';
import { Typography, Tooltip, Zoom } from '@material-ui/core';

import { NavigationButton, PBox } from 'legos';
import { withStyles } from '@material-ui/styles';

const MIN_SIZE = 46;
const MIN_PAGE_SIZE = 400;
const MIN_FRAME_SIZE = 410;
const START_TOP_POS = 151;

const CustomToolTip = withStyles(() => ({
  tooltip: {
    padding: 6,
    paddingLeft: 75,
    paddingRight: 75,
    fontSize: 12,
  },
}))(Tooltip);

const dragElement = (elmnt, open, setOpenTooltip) => {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0,
    startX = 0,
    startY = 0,
    startX1 = 0,
    startY1 = 0,
    halfSizeX = 50;

  const header = document.getElementById(elmnt.id + '-header');
  header.onmousedown = dragMouseDown;
  header.ontouchstart = dragMouseDown;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    halfSizeX = header.offsetWidth / 2;

    const clientX = e.clientX || e.changedTouches?.[0]?.clientX;
    const clientY = e.clientY || e.changedTouches?.[0]?.clientY;

    pos3 = clientX;
    pos4 = clientY;
    startY = e.offsetY + (e.target?.id === 'title-header' ? 11 : 0);
    startY1 = header.offsetHeight - startY;
    startX = e.offsetX + (e.target?.id === 'title-header' ? 10 : 0);
    startX1 = header.offsetWidth - startX + 45;

    document.onmouseup = closeDragElement;
    document.ontouchend = closeDragElement;

    document.onmousemove = elementDrag;
    document.ontouchmove = elementDrag;
  }

  function elementDrag(e) {
    setOpenTooltip(false);
    e = e || window.event;
    e.preventDefault();

    const clientX = e.clientX || e.changedTouches?.[0]?.clientX;
    const clientY = e.clientY || e.changedTouches?.[0]?.clientY;

    if (open) {
      if (clientY > 70 + (startY || 46) && clientY < window.visualViewport.height - (startY1 || 46)) {
        pos2 = pos4 - clientY;
        pos4 = clientY;
      } else {
        pos2 = 0;
      }
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
    }

    if (clientX > (startX || halfSizeX) && clientX < window.visualViewport.width - (startX1 || halfSizeX)) {
      pos1 = pos3 - clientX;
      pos3 = clientX;
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
    }
  }

  function closeDragElement(e) {
    e = e || window.event;

    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
  }
};

export const MoveableFrame = ({ parentId, title, children }) => {
  const containerRef = useRef();

  const [oldPosition, setOldPosition] = useState({});
  const [openTooltip, setOpenTooltip] = useState(true);

  const [open, setOpen] = useState(true);

  const minimizeFrame = containerFrame => {
    setOpenTooltip(false);
    const { offsetTop, offsetHeight, offsetLeft, offsetWidth } = containerFrame;
    setOldPosition({ top: offsetTop, left: offsetLeft, height: offsetHeight, width: offsetWidth });
    containerFrame.style.minHeight = `${MIN_SIZE}px`;
    containerFrame.style.height = `${MIN_SIZE}px`;
    containerFrame.style.top = `71px`;
    containerFrame.style.left = `10px`;
    containerFrame.style.borderRadius = `0px 0px 4px 4px`;
    setOpen(false);
  };

  const maximizeFrame = container => {
    container.style.top = `${oldPosition.top}px`;
    container.style.left = `${oldPosition.left}px`;
    container.style.width = `${oldPosition.width}px`;
    container.style.height = `${oldPosition.height < MIN_FRAME_SIZE ? MIN_FRAME_SIZE : oldPosition.height}px`;
    container.style.minHeight = `${4 * MIN_SIZE}px`;
    container.style.borderRadius = `0px 0px 0px 0px`;
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => setOpenTooltip(false), 8000);
  }, []);

  useEffect(() => {
    const container = document.getElementById('move-able-frame');
    dragElement(container, open, setOpenTooltip);
    const resizeObserve = new ResizeObserver(() => {
      if (window.innerHeight < MIN_PAGE_SIZE) {
        const containerFrame = containerRef.current;
        minimizeFrame(containerFrame);
      }
    });
    resizeObserve.observe(container);
    return () => resizeObserve.unobserve(container);
  }, [open]);

  const element = document.body;

  return ReactDOM.createPortal(
    <PBox
      left="10px"
      zIndex={1002}
      bgcolor="white"
      minWidth="350px"
      maxWidth="max-content"
      maxHeight="75vh"
      overflow="hidden"
      ref={containerRef}
      position="fixed"
      id="move-able-frame"
      top={`${START_TOP_POS}px`}
      minHeight={`${4 * MIN_SIZE}px`}
      height="400%"
      resize={open ? 'both' : 'none'}
      boxShadow="0px 6px 24px rgba(0, 0, 0, 0.25)"
    >
      <CustomToolTip
        open={openTooltip}
        arrow
        placement="top"
        title="You can switch between offers here"
        TransitionComponent={Zoom}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <div style={{ width: '100%' }}>
          <PBox
            top="0"
            px={1.2}
            zIndex="100"
            height="46px"
            cursor="move"
            display="flex"
            bgcolor="black"
            position="sticky"
            alignItems="center"
            justifyContent="space-between"
          >
            <PBox
              width="calc(100% - 35px)"
              height="100%"
              id="move-able-frame-header"
              display="flex"
              alignItems="center"
            >
              <Typography
                id="title-header"
                style={{
                  color: 'white',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {title}
              </Typography>
            </PBox>

            <PBox display="flex" id="button-container" width="35px">
              <NavigationButton
                icon={!open ? 'ArrowDown' : 'ArrowRight'}
                size={35}
                color="white"
                onClick={() => {
                  const container = containerRef.current;
                  if (container) {
                    if (!open) {
                      maximizeFrame(container);
                    } else {
                      minimizeFrame(container);
                    }
                  }
                }}
              />
            </PBox>
          </PBox>
        </div>
      </CustomToolTip>
      <PBox height="calc(100% - 46px)" style={{ overflowY: 'scroll' }}>
        {children}
      </PBox>
    </PBox>,
    element
  );
};
