import { CircularProgress, IconButton } from '@material-ui/core';

import { Icons, PBox } from 'legos';
import { theme } from 'utils';

export const ActionButtons = ({ loading, handleAccept, handleClose, disabled }) => (
  <PBox display="flex" justifyContent="center" pt="8px">
    <IconButton disabled={disabled} style={{ height: 36, width: 36 }} onClick={handleAccept}>
      {loading ? (
        <CircularProgress
          style={{
            backgroundColor: 'transparent',
            color: 'inherit',
            opacity: 0.6,
            position: 'absolute',
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
          }}
          size={20}
        />
      ) : (
        <Icons.Accept
          style={{
            fill: disabled ? theme.palette.disabled : theme.palette.purple.dark,
            height: 36,
            width: 36,
          }}
        />
      )}
    </IconButton>
    <PBox ml={1}>
      <IconButton style={{ height: 36, width: 36 }} onClick={handleClose} disabled={disabled}>
        <Icons.Close style={{ fill: disabled ? theme.palette.disabled : theme.palette.purple.dark }} />
      </IconButton>
    </PBox>
  </PBox>
);
