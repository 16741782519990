import gql from 'graphql-tag';

export const BUYER_QUESTIONS_QUERY = gql`
  query BuyerQuestionsQuery($plumbidPartyId: Int) {
    onboardingBuyerQuestions(plumbidPartyId: $plumbidPartyId) {
      questionId
      text1
      text2
      text3
      text4
      text5
      answerList {
        answerId
        text
        hint
      }
      partyAnswers {
        answerId
        answerValue
        owner {
          user {
            userId
            fullName
          }
        }
      }
    }
  }
`;
