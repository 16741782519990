import gql from 'graphql-tag';

export const REJECT_LISTING_AGENT_MUTATION = gql`
  mutation RejectListingAgentMutation($input: RejectListingAgentInput!) {
    rejectListingAgent(input: $input) {
      success
      errors
    }
  }
`;
