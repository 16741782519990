import gql from 'graphql-tag';

import { ChatMessageType } from '../types';

export const CHATS_LIST_QUERY = gql`
  query ChatsList($searchName: String) {
    adminChats(searchName: $searchName) {
      id
      name
      status
      owner {
        id
        fullName
        callMeBackPhone
      }
      lastMessage {
        ${ChatMessageType}
      }
    }
  }
`;
