import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import { Box, DialogContent, Dialog, Typography, Grid, CircularProgress } from '@material-ui/core';

import { theme } from 'utils/theme';
import { formatAddressTwoLines } from 'utils';
import { useAuthState } from 'service/store/authStore';
import { Button, Icons, NavigationButton } from 'legos';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { PropertyThumbnail } from 'components/PropertyThumbnail';
import { useNotification, useResponsive } from 'utils/hooks';
import { GET_PLUMBID_BY_ID_QUERY, SEND_EMAIL_BACK_UP_MUTATION, UPDATE_PARTICIPANT_MUTATION } from 'service/graphql';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const GPBackupPositionDialog = ({ isOpen, onClose, plumBidId }) => {
  const classes = useStyles();
  const { mobileScreen } = useResponsive();
  const showNotification = useNotification();
  const [{ me }] = useAuthState();

  const [updateParticipantMutation] = useMutation(UPDATE_PARTICIPANT_MUTATION, {
    refetchQueries: [
      {
        query: GET_PLUMBID_BY_ID_QUERY,
        variables: {
          id: plumBidId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [sendEmailBackUpMutation, { loading: sendEmailBackUpLoading }] = useMutation(SEND_EMAIL_BACK_UP_MUTATION, {
    refetchQueries: [
      {
        query: GET_PLUMBID_BY_ID_QUERY,
        variables: {
          id: plumBidId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { data } = useQuery(GET_PLUMBID_BY_ID_QUERY, {
    variables: {
      id: plumBidId,
    },
    fetchPolicy: 'cache-only',
    skip: !isOpen,
  });

  const plumBid = useMemo(() => {
    if (data?.plumbidById) {
      return normalizePlumBid(data?.plumbidById, false, me);
    }
  }, [data?.plumbidById, me]);

  useEffect(() => {
    if (isOpen && plumBid?.myInfoInPlumBid?.participantId) {
      updateParticipantMutation({
        variables: {
          input: {
            participantId: plumBid.myInfoInPlumBid.participantId,
            backUpPositionPopup: false,
          },
        },
      });
    }
  }, [isOpen, plumBid?.myInfoInPlumBid?.participantId, updateParticipantMutation]);

  const sendEmailBackupPosition = useCallback(() => {
    sendEmailBackUpMutation({
      variables: {
        input: {
          participantId: plumBid.myInfoInPlumBid.participantId,
        },
      },
    })
      .then(res => {
        if (res?.data?.notifyParticipantBackup.success) {
          showNotification({
            message: 'Back up position request was sent',
            variant: 'success',
          });
        } else if (res?.data?.notifyParticipantBackup.errors) {
          showNotification({ error: res?.data?.notifyParticipantBackup.errors });
        }
      })
      .catch(error => showNotification({ error }))
      .finally(() => {
        onClose();
      });
  }, [sendEmailBackUpMutation, plumBid?.myInfoInPlumBid?.participantId, showNotification, onClose]);

  const renderAddress = () => {
    const address = formatAddressTwoLines(plumBid?.mls || {});

    return (
      <Box display="flex" alignItems="center" my={1}>
        <Icons.MapMarker />
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line1}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line2}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      open={isOpen && plumBid}
      className={classes.root}
      fullScreen={mobileScreen}
    >
      <Box position="absolute" top={0} right={0}>
        <NavigationButton icon="Close" size={35} onClick={onClose} />
      </Box>
      <DialogContent>
        <Box py={2} minHeight={450} display="flex" alignItems="center" justifyContent="center">
          {plumBid ? (
            <Grid container>
              <Grid item container xs={12} md={5} alignItems="center" direction="row">
                <Box p="30px" style={{ width: '100%', backgroundColor: theme.palette.background.solitude }}>
                  <PropertyThumbnail
                    aspectRatio={1 / 1}
                    src={plumBid.mls?.previewImage || plumBid.mls?.mlsinfophotoSet?.[0] || '/no_photo.png'}
                  />
                  <Box pt={2}>
                    <Box
                      px={2}
                      py={1}
                      display="flex"
                      borderRadius="3px"
                      width="fit-content"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor={theme.palette.text.resolutionBlue}
                    >
                      <Typography style={{ color: theme.palette.common.white }} variant="caption">
                        Finished
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                      plumBid Ended
                    </Typography>
                    {renderAddress()}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={7}>
                <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%" pl={2} pt={2}>
                  <Box mt={12}>
                    <Typography align="left" style={{ fontSize: 24, fontWeight: 600 }}>
                      Thank you for participating in the plumBid.
                    </Typography>
                    <Box py={2}>
                      <Typography align="left" style={{ fontSize: 20 }}>
                        Should you wish to be considered for a back-up position, please press “Yes”
                      </Typography>
                    </Box>
                  </Box>
                  <Box pb={2} display="flex">
                    <Box>
                      <Button
                        fullWidth
                        onClick={onClose}
                        variant="secondary"
                        title="No, Thank's"
                        disabled={sendEmailBackUpLoading}
                      />
                    </Box>
                    <Box width={8} />
                    <Box width={150}>
                      <Button
                        fullWidth
                        title="Yes please"
                        onClick={sendEmailBackupPosition}
                        loading={sendEmailBackUpLoading}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
