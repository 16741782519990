import gql from 'graphql-tag';
import { PlumBidType } from '../types';

export const ACCEPT_INVITE_MUTATION = gql`
  mutation AcceptInviteMutation($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      success
      errors
      userExists
      plumbid {
        ${PlumBidType}
      }
      invite {
        link
        fullName
        email
        participant {
          role
          user {
            id
            userId
          }
        }
      }
    }
  }
`;
