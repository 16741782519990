import React from 'react';

export const DocumentIconFilled = ({ style }) => (
  <svg width="19" height="23" viewBox="0 0 19 23" fill="none">
    <path
      d="M9.5 0V7.6C9.5 8.10391 9.70018 8.58718 10.0565 8.9435C10.4128 9.29982 10.8961 9.5 11.4 9.5H18.9991L19 9.5741V20.52C19 21.1247 18.7598 21.7046 18.3322 22.1322C17.9046 22.5598 17.3247 22.8 16.72 22.8H2.28C1.67531 22.8 1.09538 22.5598 0.667797 22.1322C0.240214 21.7046 0 21.1247 0 20.52V2.28C0 1.67531 0.240214 1.09538 0.667797 0.667797C1.09538 0.240214 1.67531 0 2.28 0H9.5Z"
      fill={style?.color || '#731E6B'}
    />
    <path
      d="M10.9248 0.451263V7.60001C10.9248 7.72599 10.9748 7.84681 11.0639 7.93589C11.153 8.02497 11.2738 8.07501 11.3998 8.07501H18.5267C18.4398 7.95084 18.3422 7.8345 18.2351 7.72731L11.1756 0.667864C11.0974 0.589678 11.0135 0.517287 10.9248 0.451263Z"
      fill={style?.color || '#731E6B'}
    />
  </svg>
);
