export const DIALOG = {
  SURVEY: 'SURVEY',
  OK_CANCEL: 'OK_CANCEL',
  TOP_OFFER: 'TOP_OFFER',
  ADD_DRE_IDD: 'ADD_DRE_IDD',
  CALL_ME_BACK: 'CALL_ME_BACK',
  REVIEW_OFFER: 'REVIEW_OFFER',
  PREVIEW_IMAGE: 'PREVIEW_IMAGE',
  REVIEW_AND_BID: 'REVIEW_AND_BID',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  GET_SANDBOX_KEY: 'GET_SANDBOX_KEY',
  OFFER_DUPLICATE: 'OFFER_DUPLICATE',
  GP_BACKUP_POSITION: 'GP_BACKUP_POSITION',
  MAKE_REVISION_OFFER: 'MAKE_REVISION_OFFER',
  ADD_PHANTOM_PLUM_BID: 'ADD_PHANTOM_PLUM_BID',
  AUTO_FILL_QUESTIONNAIRE: 'AUTO_FILL_QUESTIONNAIRE',
  GP_PLUMBID_JUST_STARTED: 'GP_PLUMBID_JUST_STARTED',
  GP_NEW_BID_RECEIVED_STARTED: 'GP_NEW_BID_RECEIVED_STARTED',
  GP_FINISH_DATA_HAS_CHANGED: 'GP_FINISH_DATA_HAS_CHANGED',
  GP_RESERVE_PRICE_MET_CHANGED: 'GP_RESERVE_PRICE_MET_CHANGED',
};
