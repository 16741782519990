import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import { ROLES } from 'constant';
import { NavigationButton, PBox } from 'legos';
import { getParticipantField } from 'utils';
import { useAuthState } from 'service/store';
import { BuyerPartyItem } from './BuyerPartyItem';
import { usePageLoading, usePlumBidById, useResponsive } from 'utils/hooks';
import { BuyerOnboardingForm } from 'components/BuyerOnboardingForm';
import { ContingencyTag } from 'components/ContingencyTag';
import { theme } from 'utils/theme';
import { RedirectPage } from 'components/RedirectPage';

export const ReviewBuyerOnboarding = () => {
  const history = useHistory();
  const location = useLocation();
  const responsive = useResponsive();
  const [{ me }] = useAuthState();
  const { plumbid: plumBidId, b: buyerId, p: partyId } = queryString.parse(location.search);

  const [selectedParticipant, setSelectedParticipant] = useState();

  const { plumBid, plumBidError, plumBidLoading, plumBidRefetch } = usePlumBidById({ id: plumBidId });

  const plumBidBuyerAgentParty = plumBid?.buyerParty?.find(({ participantSet }) =>
    participantSet.some(
      participant => participant.role === ROLES.BUYER_AGENT && participant.user?.userId === me?.userId
    )
  );

  useEffect(() => {
    if (buyerId && partyId && plumBid) {
      const party = plumBid.buyerParty.find(party => +party.id === +partyId);

      if (party) {
        const participant = party.participantSet.find(participant => +participant.id === +buyerId);
        if (participant) {
          setSelectedParticipant({
            partyId: party.id,
            offerContingent: party.offersettings.agreementContingent,
            buyerId: participant.user?.userId,
            participantId: participant.id,
            status: participant.status,
            onboardingStatus: participant.onboardingStatus,
            contingentRemoved: party.contingentRemoved,
            secondaryContingencyRemoved: party.secondaryContingencyRemoved,
            fullName: getParticipantField(participant, 'fullName'),
          });
        }
      }
    }
  }, [buyerId, partyId, plumBid]);

  useEffect(() => {
    plumBidRefetch({
      id: plumBidId,
    });
  }, [plumBidId, plumBidRefetch]);

  usePageLoading(plumBidLoading, 'ReviewBuyerOnboarding');

  const renderBuyersList = () => {
    return plumBid.buyerParty.map(party =>
      party.participantSet.length ? (
        <BuyerPartyItem
          party={party}
          key={party.id}
          plumBid={plumBid}
          showInBuyerOnboarding
          selectedParticipant={selectedParticipant}
          setSelectParticipant={setSelectedParticipant}
        />
      ) : null
    );
  };

  const renderBuyersListForBA = () => {
    return (
      plumBidBuyerAgentParty && (
        <BuyerPartyItem
          plumBid={plumBid}
          showInBuyerOnboarding
          party={plumBidBuyerAgentParty}
          key={plumBidBuyerAgentParty.id}
          selectedParticipant={selectedParticipant}
          setSelectParticipant={setSelectedParticipant}
        />
      )
    );
  };

  const renderEmptyBuyersList = () => (
    <PBox p={1} display="flex" justifyContent="center">
      <Typography>Buyers not found in plumBid</Typography>
    </PBox>
  );

  return plumBid && !plumBidLoading ? (
    <PBox px={responsive.mobileScreen ? 2 : 6}>
      <PBox py={1} display="flex" alignItems="center">
        <NavigationButton
          color="white"
          icon="LeftArrow"
          fullWidth={false}
          onClick={() => history.goBack()}
          style={{
            backgroundColor: theme.palette.purple.light,
            marginRight: 32,
          }}
        />
        <Typography variant={responsive.mobileScreen ? 'h2' : 'h1'} style={{ textTransform: 'uppercase' }}>
          Review Onboarding Details
        </Typography>
      </PBox>
      <Grid container spacing={1}>
        <Grid item xs={responsive.mobileScreen ? 12 : 4}>
          {plumBid.myStatusInPlumBid?.role === ROLES.BUYER_AGENT
            ? renderBuyersListForBA()
            : plumBid.buyerParty.length
            ? renderBuyersList()
            : renderEmptyBuyersList()}
        </Grid>
        <Grid item xs={responsive.mobileScreen ? 12 : 8}>
          {selectedParticipant && (
            <PBox>
              <PBox my={1} pl={4} display="flex" alignItems="center">
                <Typography variant="h2">{selectedParticipant.fullName}</Typography>
                {selectedParticipant.offerContingent && (
                  <PBox ml={2}>
                    <ContingencyTag contingencyRemoved={selectedParticipant.secondaryContingencyRemoved} />
                  </PBox>
                )}
              </PBox>
              {selectedParticipant.buyerId ? (
                <PBox ml={responsive.mobileScreen ? 0 : -6} mt={responsive.mobileScreen ? -2 : -6}>
                  <BuyerOnboardingForm previewMode plumBidId={plumBid.id} buyerId={selectedParticipant.buyerId} />
                </PBox>
              ) : (
                <PBox ml={responsive.mobileScreen ? 4 : 4} mb={1}>
                  <Typography variant="h3">Buyer has not yet started the onboarding</Typography>
                </PBox>
              )}
            </PBox>
          )}
        </Grid>
      </Grid>
    </PBox>
  ) : !plumBid && plumBidError ? (
    <RedirectPage />
  ) : null;
};
