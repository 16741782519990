import gql from 'graphql-tag';

export const GET_INVITES_QUERY = gql`
  query GetInvites($plumbidId: Int, $role: String) {
    invites(plumbidId: $plumbidId, role: $role) {
      id
      fullName
      createdAt
      acceptedAt
      token
      email
      active
      link
      status
      participant {
        role
        active
        plumbidParty {
          id
          isWinner
          offerdoc {
            offersettings {
              id
              propertyAddress
              offerPrice
              cashdownAmount
              escrowLength
              inspectionContingency
              loanContingency
              appraisalContingency
              isVaLoan
              offerLeaseBack
            }
          }
        }
      }
    }
  }
`;
