import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { ROLES } from 'constant';
import { useAuthState } from 'service/store';
import { UPDATE_PLUMBID_MUTATION } from 'service/graphql';

const SET_ADMINISTRATING_INTERVAL = 5000;
const GET_ADMINISTRATING_INTERVAL = 6500;

export const usePollingAdministrating = ({ plumBid, skip }) => {
  const [{ me }] = useAuthState();
  const [runUpdatePlumBid] = useMutation(UPDATE_PLUMBID_MUTATION);
  const lastValueIsAdministrating = useRef({
    administratingTime: undefined,
    currentTime: 0,
  });
  const [isAdminInTheWizard, setIsAdminInTheWizard] = useState(false);

  useEffect(() => {
    if (skip || !plumBid) {
      setIsAdminInTheWizard(false);
      return;
    }

    if (me?.roles?.includes(ROLES.ADMIN)) {
      if (Date.now() - lastValueIsAdministrating.current.currentTime > SET_ADMINISTRATING_INTERVAL) {
        lastValueIsAdministrating.current.currentTime = Date.now();
        runUpdatePlumBid({
          variables: {
            input: {
              plumBidId: plumBid.id,
              isAdministrating: Math.round(Date.now() / 1000),
            },
          },
        });
      }
      const interval = setInterval(() => {
        runUpdatePlumBid({
          variables: {
            input: {
              plumBidId: plumBid.id,
              isAdministrating: Math.round(Date.now() / 1000),
            },
          },
        });
      }, SET_ADMINISTRATING_INTERVAL);

      return () => clearInterval(interval);
    } else {
      const toggleIsAdminInTheWizard = () => {
        if (lastValueIsAdministrating.current.administratingTime === plumBid.isAdministrating) {
          if (
            Date.now() - lastValueIsAdministrating.current.currentTime > GET_ADMINISTRATING_INTERVAL ||
            plumBid.isAdministrating === 0 ||
            plumBid.isAdministrating === null
          ) {
            setIsAdminInTheWizard(false);
            lastValueIsAdministrating.current.currentTime = Date.now();
          }
        } else {
          lastValueIsAdministrating.current = {
            administratingTime: plumBid.isAdministrating,
            currentTime: Date.now(),
          };
          if (plumBid.isAdministrating) {
            setIsAdminInTheWizard(true);
          }
        }
      };

      toggleIsAdminInTheWizard();

      const interval = setInterval(toggleIsAdminInTheWizard, GET_ADMINISTRATING_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [me?.roles, plumBid, runUpdatePlumBid, skip]);

  return isAdminInTheWizard;
};
