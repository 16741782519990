import gql from 'graphql-tag';
import { ExtendedUserType } from '../types';

export const ON_NEW_USER_ME_EVENT_SUBSCRIPTION = gql`
  subscription onNewUserMe($room: String) {
    onNewUserMe(room: $room) {
      user {
        ${ExtendedUserType}
      }
    }
  }
`;
