import React from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { theme } from 'utils/theme';

export const WizardStepper = ({ steps, currentStep, responsive }) => {
  const isActive = index => index + 1 === currentStep;
  const isHighlighted = index => index < currentStep;
  const mobileGridWidth = useWindowSize();
  const minWidth = 940;
  const pagePadding = responsive.mobileScreen ? 48 : 96;
  const stepsShowCount = Math.floor((mobileGridWidth.width - pagePadding - 16) / (minWidth / 5));
  const isOffset = currentStep + stepsShowCount <= 6;
  const stepsOffset = stepsShowCount === 4 ? 2 : stepsShowCount === 3 ? 0 : -2;

  const renderStep = (title, index) => {
    return (
      <Grid
        item
        xs
        key={`${title}`}
        style={{
          width:
            mobileGridWidth.width < minWidth ? (mobileGridWidth.width - pagePadding - 16) / stepsShowCount + 16 : '',
          position: mobileGridWidth.width < minWidth ? 'absolute' : 'inherit',
          left: isOffset
            ? ((index + 1 - currentStep) * (mobileGridWidth.width - pagePadding)) / stepsShowCount
            : stepsShowCount
            ? ((index + 1 + stepsOffset - stepsShowCount) * (mobileGridWidth.width - pagePadding)) / stepsShowCount
            : 'unset',
        }}
      >
        <Box pt={4} pb={2}>
          <Typography
            variant={'caption'}
            style={{ textTransform: 'uppercase', fontSize: responsive.bigTabletScreen ? '10px' : '14px' }}
          >
            {index + 1}. {title}
          </Typography>
        </Box>
        <Box
          height={6}
          width={'100%'}
          bgcolor={
            isActive(index) || isHighlighted(index) ? theme.palette.primary.main : theme.palette.background.lightGray
          }
        />
      </Grid>
    );
  };

  return (
    <Box width={mobileGridWidth} height={90} overflow="hidden">
      <Grid
        container
        spacing={2}
        justify={mobileGridWidth.width < minWidth ? 'flex-start' : 'space-between'}
        style={{
          minWidth: minWidth + 16,
          position: mobileGridWidth.width < minWidth ? 'relative' : 'inherit',
        }}
      >
        {steps.map(renderStep)}
      </Grid>
    </Box>
  );
};
