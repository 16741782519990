import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, DialogContent, Dialog } from '@material-ui/core';

import { NavigationButton } from 'legos/Buttons/NavigationButton';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
  content: {
    paddingTop: 'calc(7%)',
    paddingBottom: 'calc(7%)',
  },
  image: {
    width: '100%',
  },
}));

export const PreviewImageDialog = ({ isOpen, onClose, url }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="1400px" scroll="body">
      <Box position="absolute" top={0} right={0}>
        <NavigationButton icon="Close" size={35} onClick={onClose} />
      </Box>
      <DialogContent className={classes.content}>
        <img className={classes.image} src={url} alt="Object preview" />
      </DialogContent>
    </Dialog>
  );
};
