import { Grid, Typography } from '@material-ui/core';

import { Icons, PBox, RouterLink, Tooltip } from 'legos';
import { useEffect, useRef } from 'react';
import { TERM_OF_USE } from 'routes';
import { theme } from 'utils';
import { QuestionTitleCard } from './QuestionTitleCard';

export const QuestionTitleItem = ({
  curAnswer,
  number,
  question,
  illegalAnswerCombination,
  getText,
  priorityParams,
  cardDisabled,
  setRadioAnswer,
  renderOfferButton,
  currentScrollQuestionId,
  setCurrentScrollQuestionId,
}) => {
  const scrollRef = useRef();
  const isQ18 = curAnswer.questionId === 'Q18';

  useEffect(() => {
    if (currentScrollQuestionId === curAnswer.questionId) {
      setTimeout(() => scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 500);
      setCurrentScrollQuestionId(null);
    }
  }, [curAnswer.questionId, currentScrollQuestionId, setCurrentScrollQuestionId]);

  return (
    <PBox mr={{ xs: 1 }} ref={scrollRef}>
      <PBox py={2}>
        <Typography
          variant="h4"
          component="span"
          id={`question-id-${curAnswer.questionId}`}
          style={{
            lineHeight: '22px',
            alignItems: 'center',
            scrollPaddingTop: '-10rem',
            display: 'flex',
          }}
        >
          {illegalAnswerCombination[curAnswer.questionId] ? (
            <Tooltip
              text="Given answers are contradictory - please select different options or contact your Agent for support"
              placement="top"
            >
              <Icons.QuestionMark style={{ stroke: theme.palette.error.main }} />
            </Tooltip>
          ) : null}
          {`${number + 1}. `}
          {question.tooltip ? (
            <Tooltip text={question.tooltip} placement="top">
              <Icons.QuestionMark />
            </Tooltip>
          ) : null}
          {getText(question, question.text1, 'text1', curAnswer)}

          {curAnswer.questionId === 'Q17' ? (
            <Typography
              component="pre"
              variant="h4"
              style={{
                lineHeight: '22px',
              }}
            >
              {' '}
              <RouterLink to={TERM_OF_USE} style={{ color: theme.palette.text.primary }}>
                {'Terms & Conditions'}
              </RouterLink>
              {'?'}
            </Typography>
          ) : null}
        </Typography>
        {isQ18 && (
          <>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text2 && getText(question, question.text2, 'text2', curAnswer)}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text3 && getText(question, question.text3, 'text3', curAnswer)}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 8,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text4 && getText(question, question.text4, 'text4', curAnswer)}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              style={{
                marginTop: 24,
                lineHeight: '22px',
                alignItems: 'center',
                scrollPaddingTop: '-10rem',
                display: 'flex',
              }}
            >
              {question.text5 && getText(question, question.text5, 'text5', curAnswer)}
            </Typography>
          </>
        )}
        {question.titleCard ? (
          <Grid container justify="center" spacing={2}>
            {question.titleCard.map((func, index) => {
              const data = func(priorityParams);
              return (
                <Grid key={data.answerId} item style={{ paddingTop: 16 }}>
                  <QuestionTitleCard
                    data={data}
                    disabled={cardDisabled}
                    index={index + 1}
                    onClick={() =>
                      data.answerId !== curAnswer.answerId &&
                      setRadioAnswer(data.answerId, number, question, curAnswer.answerValue)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : null}

        {question.text2 && !isQ18 && (
          <Typography variant="h5" style={{ paddingTop: 16 }}>{`${getText(
            question,
            question.text2,
            'text2'
          )}`}</Typography>
        )}
      </PBox>
      {question.showOffersCard && (
        <Grid container spacing={2} justify="flex-start">
          <Grid item>{renderOfferButton(0)}</Grid>
          <Grid item>{renderOfferButton(1)}</Grid>
        </Grid>
      )}
    </PBox>
  );
};
