import { Box } from '@material-ui/core';

import { PropertyDescriptionWithImage } from 'components';
import { theme } from 'utils/theme';

export const PropertyDescriptionCard = ({ property }) => (
  <Box width="100%" border={`1px solid ${theme.palette.divider}`} style={{ borderRadius: 15 }}>
    <PropertyDescriptionWithImage property={property} />
  </Box>
);
