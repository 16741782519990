import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { DIALOG } from 'constant';
import { useDialog, useNotification } from '.';
import { UPDATE_PARTICIPANT_MUTATION } from 'service/graphql';
import { formatAddress } from 'utils';

export const usePendingDialog = () => {
  const { openDialog: openPendingDialog, closeDialog: closePendingDialog, isOpenDialog } = useDialog(DIALOG.OK_CANCEL);
  const [updateParticipantMutation, { loading: updateParticipantLoading }] = useMutation(UPDATE_PARTICIPANT_MUTATION);
  const showNotification = useNotification();

  return useCallback(
    (plumBid, refetchPlumbid) => {
      if (!isOpenDialog) {
        openPendingDialog({
          title: 'important',
          text: `THE SELLER OF ${formatAddress(
            plumBid.mls
          ).toUpperCase()} HAS CHOSEN NOT TO CONSIDER OFFERS THAT HAVE OUR CONTINGENT`,
          loading: updateParticipantLoading,
          hideCancel: true,
          onOk: () => {
            updateParticipantMutation({
              variables: {
                input: {
                  participantId: plumBid.myInfoInPlumBid.participantId,
                  pendingPopup: false,
                },
              },
            })
              .then(res => {
                if (res?.data?.updateParticipant?.errors) {
                  showNotification({ error: res?.data?.updateParticipant?.errors });
                }
              })
              .catch(error => showNotification({ error }))
              .finally(() => {
                refetchPlumbid();
                closePendingDialog();
              });
          },
          onClose: () => {
            closePendingDialog();
          },
        });
      }
    },
    [
      closePendingDialog,
      isOpenDialog,
      openPendingDialog,
      showNotification,
      updateParticipantLoading,
      updateParticipantMutation,
    ]
  );
};
