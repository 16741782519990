import { Typography } from '@material-ui/core';

import { PLUMBID_STATUS, ROLES } from 'constant';
import { PBox } from 'legos';
import { getInitials } from 'utils/formatters';
import { theme } from 'utils/theme';

export const LastBidInfo = ({ plumBid, myInfo }) => {
  const isMeLeader = plumBid?.lastBid?.owner?.user?.userId === myInfo.meId;
  const isMyPartyLeader = plumBid?.lastBid?.partyId === +myInfo.myParty?.id;
  const isFinished = plumBid.status === PLUMBID_STATUS.FINISHED;
  const isShowBidInfo = (plumBid.status === PLUMBID_STATUS.LIVE || isFinished) && plumBid?.lastBid;
  const isShowFullName = isMyPartyLeader || myInfo.role === ROLES.LISTING_AGENT || myInfo.role === ROLES.SELLER;
  const fullName = plumBid?.lastBid?.owner?.user?.fullName ?? 'N/A';

  return isShowBidInfo && plumBid?.lastBid ? (
    <PBox
      py={1}
      my={1.75}
      width="100%"
      display="flex"
      borderRadius="3.25px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={
        isMyPartyLeader || (isShowFullName && isFinished)
          ? theme.palette.background.highBid
          : theme.palette.background.charcoal
      }
    >
      {isMyPartyLeader ? (
        <Typography
          variant="h6"
          style={{
            color: theme.palette.common.white,
            padding: '0 8px',
          }}
        >
          {isMeLeader && isFinished
            ? 'You are the winner'
            : isFinished
            ? `Winner ${fullName}`
            : isMeLeader
            ? 'You are the High Bidder'
            : `${fullName} is the
          High Bidder`}
        </Typography>
      ) : (
        <Typography
          variant="h6"
          style={{
            color: theme.palette.common.white,
            padding: '0 8px',
          }}
        >
          {isShowFullName
            ? `${isFinished ? `Winner ${fullName}` : `${fullName} is the High Bidder`}`
            : `${isFinished ? `Winner ${getInitials(fullName)}` : `${getInitials(fullName)} is the High Bidder`}`}
        </Typography>
      )}
    </PBox>
  ) : null;
};
