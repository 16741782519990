import gql from 'graphql-tag';

export const CHECK_RESET_PASSWORD_LINK_MUTATION = gql`
  mutation CheckResetPasswordLink($input: CheckResetPasswordLinkInput!) {
    checkResetPasswordLink(input: $input) {
      success
      errors
    }
  }
`;
