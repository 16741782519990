import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { theme } from 'utils/theme';

export const StyledTypography = styled(Typography)`
  font-size: ${props => (props.type === 'initial' ? '8px' : '13px')};
  color: ${props => (props.type === 'initial' ? theme.palette.background.paper : props.$color)};
  background: ${props => props.bgColor};
  font-weight: ${props => (props.type === 'initial' ? 500 : '')};
  text-transform: ${props => props.type === 'initial' && 'uppercase'};
  line-height: ${props => props.type === 'initial' && '12px'};
`;
