import { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { theme } from 'utils/theme';
import { useResponsive } from 'utils/hooks';
import { formatNumberToUSD } from 'utils/formatters';
import { CurrencyInput, Icons, PBox, Spinner, Text, Divider, Tooltip } from 'legos';

const limitText = `possible incentive amount.
Incentive amounts from individual
parameters might differ from the total incentive.`;

export const BidInputCard = ({
  limit,
  errors,
  loading,
  disabled,
  setErrors,
  bidsHistory,
  plumbidData,
  effectiveBid,
  improvePrice,
  incentivePrice,
  totalIncentives,
  isAutoCalculated,
  changeIncentiveHandle,
  toggleIsAutoCalculated,
}) => {
  const { smallScreen, tabletScreen, padScreen, mdScreen } = useResponsive();
  const [inputValue, setInputValue] = useState(null);
  const [inputError, setInputError] = useState(false);

  const currentBid = plumbidData?.lastBid?.plumBidAmount || bidsHistory[0]?.plumBidAmount || plumbidData.listPrice;

  const handleChange = value => {
    if (isAutoCalculated) {
      toggleIsAutoCalculated(false, true);
    }
    if (+value >= 1000000000) {
      setInputValue(value);
      setErrors('The price is too high');
      setInputError('The price is too high');
    } else if (value === '' || value === 0) {
      setInputValue(value);
      setErrors('Purchase price can’t be blank');
      setInputError('Purchase price can’t be blank');
    } else if (+value > +currentBid * 2.1) {
      setInputValue(value);
      setErrors('You can only increase your bid by 110% of the current amount');
      setInputError('You can only increase your bid by 110% of the current amount');
    } else {
      setErrors();
      setInputError(false);
      changeIncentiveHandle(
        +value?.[0] === 0 ? value?.replace(/^0+/, '') : value,
        'price',
        currentBid * 2.1,
        false,
        false
      );
      setInputValue(null);
    }
  };

  return (
    <Box
      px={1}
      py={3}
      style={{
        backgroundColor: theme.palette.background.pattensBlue,
        borderRadius: 15,
        width: '100%',
      }}
    >
      <Grid item container xs spacing={1} style={{ margin: 0 }}>
        <Grid item xs={5}>
          <Text
            align="right"
            disabled={disabled}
            variant={smallScreen ? 'h3' : 'h2'}
            style={{
              color: theme.palette.purple.light,
              fontWeight: 500,
            }}
          >
            {loading ? (
              <Spinner width={50} height={7} />
            ) : (
              formatNumberToUSD(effectiveBid, {
                hidePlusSign: true,
              })
            )}
          </Text>
        </Grid>
        <Grid item xs={7}>
          <Text
            variant={smallScreen ? 'h3' : 'h2'}
            disabled={disabled}
            style={{
              color: theme.palette.text.primary,
              fontSize: 22,
            }}
          >
            plumBid
          </Text>
        </Grid>
      </Grid>
      <Grid container xs spacing={1} justify="center" style={{ margin: 0 }}>
        <Grid item xs={5} style={{ alignSelf: 'center' }}>
          <PBox display="flex" alignItems="center" minHeight="44px" justifyContent="flex-end" maxWidth="316px">
            {!loading && (limit?.max || limit?.min) && (
              <Icons.MaxValueContingencyWarning
                tooltipPlacement="left"
                text={`You've reached ${limit?.max ? 'maximum' : 'minimum'} ${limitText}`}
                style={{ marginTop: '3px' }}
              />
            )}
            <Text
              disabled={disabled}
              variant={smallScreen ? 'h3' : 'h2'}
              style={{
                color: theme.palette.purple.light,
                fontWeight: 500,
                fontStyle: 'italic',
              }}
            >
              {loading ? (
                <Spinner width={50} height={7} />
              ) : (
                formatNumberToUSD(totalIncentives, {
                  hidePlusSign: true,
                })
              )}
            </Text>
          </PBox>
        </Grid>
        <Grid item xs={7}>
          <PBox display="flex" alignItems="center" minHeight="44px">
            <Text
              variant={smallScreen ? 'h3' : 'h2'}
              disabled={disabled}
              style={{
                color: theme.palette.text.primary,
                fontSize: 22,
              }}
            >
              plumBid advantage
            </Text>
            <Tooltip placement="top" text="Total of all incentives to be added to your purchase price">
              <Icons.InfoUnfilled />
            </Tooltip>
          </PBox>
        </Grid>
        <Grid item xs={11}>
          <Divider style={{ borderColor: '#000' }} />
        </Grid>
        <Grid item container xs={12} alignItems="center" spacing={1}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              alignItems="center"
              width={padScreen ? '100%' : tabletScreen ? '80%' : '75%'}
              style={{ float: 'right' }}
            >
              <CurrencyInput
                disabled={disabled}
                value={formatNumberToUSD(
                  isAutoCalculated
                    ? improvePrice
                    : inputValue || inputValue === '' || inputValue === 0
                    ? inputValue
                    : incentivePrice,
                  {
                    hidePlusSign: true,
                  }
                )}
                onChange={value => handleChange(value)}
                textAlign="end"
                fontSize={20}
                color={disabled ? theme.palette.text.secondary : theme.palette.text.primary}
                autoFocus
                backgroundColor="white"
                startAdornment="EditDocument"
                error={isAutoCalculated ? false : inputError || errors || ''}
              />
            </Box>
          </Grid>
          <Grid container item xs={12} md={7} alignItems="center" justify={mdScreen ? 'center' : 'flex-start'}>
            <Text disabled={disabled} variant="h2" style={{ color: theme.palette.text.primary, fontSize: 26 }}>
              Purchase Price
            </Text>
            <Tooltip placement="top" text="Total of the price you will have to pay for purchasing the property">
              <Icons.InfoUnfilled />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
