import React from 'react';
import { string, func, number, oneOfType } from 'prop-types';
import NumberFormat from 'react-number-format';

import { Input } from 'legos';

export const CurrencyInput = ({ value, onChange, ...props }) => {
  return (
    <NumberFormat
      thousandSeparator
      prefix="$"
      decimalScale={0}
      value={value}
      allowNegative={false}
      onChange={event => {
        const { value } = event?.target;
        onChange(value === '$' ? '$' : typeof value === 'string' ? value.replace(/[,\s$]+/g, '') : value);
      }}
      customInput={Input}
      {...props}
    />
  );
};

CurrencyInput.propTypes = {
  value: oneOfType([string, number]),
  onChange: func,
};

CurrencyInput.defaultProps = {
  value: '',
  onChange: () => {},
};
