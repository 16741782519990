import React from 'react';

export const ShieldCheck = ({ style }) => (
  <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M89.2158 24.9677C89.0998 22.4158 89.0998 19.98 89.0998 17.5441C89.0998 15.5722 87.5919 14.0643 85.62 14.0643C71.1208 14.0643 60.1013 9.88849 50.9378 0.956951C49.5459 -0.318984 47.458 -0.318984 46.066 0.956951C36.9025 9.88849 25.8831 14.0643 11.3838 14.0643C9.41193 14.0643 7.90401 15.5722 7.90401 17.5441C7.90401 19.98 7.90401 22.4158 7.78801 24.9677C7.32404 49.3265 6.62807 82.7327 47.342 96.768L48.5019 97L49.6619 96.768C90.2598 82.7327 89.6798 49.4425 89.2158 24.9677ZM45.7181 58.722C45.0221 59.3019 44.2101 59.6499 43.2822 59.6499H43.1662C42.2382 59.6499 41.3103 59.1859 40.7303 58.49L29.9429 46.5426L35.1626 41.9028L43.6302 51.2984L62.4212 33.4353L67.177 38.539L45.7181 58.722Z" fill="#77256F" />
  </svg>

);


