import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Input, Button, CurrencyInput, Select, LinkButton, PBox } from 'legos';
import { hasOfferSettingsError } from 'utils';

import { theme } from 'utils/theme';
import { PreviewImageButton } from './PreviewImageButton';

export const OfferDetailsItem = ({
  offerItem,
  imageUrl,
  offerSettings,
  index,
  idEditingSetting,
  setIdEditingSetting,
  saveOfferChange,
  offerSettingsWarnings,
  setOfferSettingsWarnings,
  readOnly,
  setEditMode,
  isButtonDisabled,
  responsive,
  loading,
  setLoading,
  isBuyerCompleted,
}) => {
  const type = offerItem.type;
  const nameSelect = offerItem?.nameSelect;
  const nameInput = offerItem.nameInput;
  const nameInputTwo = offerItem?.nameInputTwo;
  const titleSelect = offerItem?.titleSelect;
  const titleInput = offerItem.titleInput;
  const titleInputTwo = offerItem?.titleInputTwo;

  const [editedFields, setEditedFields] = useState({
    [nameSelect]: undefined,
    [nameInput]: undefined,
    [nameInputTwo]: undefined,
  });

  const [fieldHasChanged, setFieldHasChanged] = useState(false);
  const [fieldHasTouched, setFieldHasTouched] = useState(false);

  const offerSettingsErrors = hasOfferSettingsError(offerSettings, offerSettings);

  const inputDisabled = isBuyerCompleted || readOnly || (idEditingSetting !== null && idEditingSetting !== index);

  const showMarkFieldAsCorrect = () => {
    if (readOnly) {
      return false;
    }

    if (offerSettingsErrors[nameSelect] || offerSettingsErrors[nameInput] || offerSettingsErrors[nameInputTwo]) {
      return false;
    }

    if (!fieldHasChanged) {
      if (!offerSettingsWarnings[nameSelect] && offerSettings[nameSelect] === false) {
        return offerSettingsWarnings[nameSelect];
      } else {
        return offerSettingsWarnings[nameInput] || offerSettingsWarnings[nameInputTwo];
      }
    }

    return false;
  };

  useEffect(() => {
    if (!fieldHasChanged) {
      setFieldHasChanged(false);
    }
  }, [fieldHasChanged, setEditMode]);

  const renderInputTitle = (title, width) => (
    <Grid item xs={width}>
      <PBox pt={2} pb={1} px={1}>
        <Typography variant="h4" align="left" style={{ textTransform: 'capitalize' }}>
          {title}
        </Typography>
      </PBox>
    </Grid>
  );

  const getSelectValue = () => {
    return fieldHasChanged && (editedFields[nameSelect] || editedFields[nameSelect] === false)
      ? editedFields[nameSelect]
      : offerSettings[nameSelect];
  };

  const renderOfferItemTitle = () => (
    <>
      {titleSelect && renderInputTitle(titleSelect, titleInputTwo ? 3 : true)}
      {getSelectValue() !== false &&
        !hasOfferSettingsError(offerSettings, editedFields)?.[nameSelect] &&
        renderInputTitle(titleInput, titleInputTwo ? 3 : true)}
      {getSelectValue() !== false &&
        !hasOfferSettingsError(offerSettings, editedFields)?.[nameSelect] &&
        titleInputTwo &&
        renderInputTitle(titleInputTwo, 3)}
      <Grid item xs={3}>
        {' '}
      </Grid>
    </>
  );

  const handleSumbit = () => {
    saveOfferChange(editedFields).finally(() => {
      setTimeout(() => {
        setIdEditingSetting(null);
        setFieldHasChanged(false);
        setFieldHasTouched(false);
        setEditMode(false);
        setLoading(false);
      }, 400);
    });
  };

  return (
    <Grid container justify="space-between">
      <Grid
        container
        item
        justify="space-between"
        alignContent="flex-start"
        alignItems="flex-start"
        xs={responsive.tabletScreen ? 12 : 7}
        spacing={responsive.tabletScreen ? 0 : 1}
      >
        <PBox pl={2} width="100%">
          <Grid container item xs={12}>
            {renderOfferItemTitle()}
          </Grid>
          <Grid container justify="space-between">
            {type === 'input' || type === 'currencyInput' ? (
              <Grid item xs={12} md={9}>
                <PBox pr={1}>
                  {type === 'input' ? (
                    <Input
                      key={nameInput}
                      value={fieldHasChanged ? editedFields[nameInput] : offerSettings[nameInput]}
                      disabled={inputDisabled}
                      type="number"
                      onChange={e => {
                        setFieldHasChanged(true);
                        setFieldHasTouched(true);
                        setIdEditingSetting(index);
                        setEditMode(true);
                        setEditedFields(oldValue => ({
                          ...oldValue,
                          [nameInput]: e.target.value,
                        }));
                      }}
                      warning={!readOnly && offerSettingsWarnings[nameInput]}
                      error={
                        fieldHasTouched
                          ? hasOfferSettingsError(offerSettings, editedFields)?.[nameInput]
                          : offerSettingsErrors[nameInput]
                      }
                    />
                  ) : (
                    <CurrencyInput
                      key={nameInput}
                      value={fieldHasChanged ? editedFields[nameInput] : offerSettings[nameInput]}
                      disabled={inputDisabled}
                      onChange={value => {
                        setIdEditingSetting(index);
                        setFieldHasChanged(true);
                        setFieldHasTouched(true);
                        setEditMode(true);
                        setEditedFields(oldValue => ({
                          ...oldValue,
                          [nameInput]: value,
                        }));
                      }}
                      warning={!readOnly && offerSettingsWarnings[nameInput]}
                      allowNegative={false}
                      error={
                        fieldHasTouched
                          ? hasOfferSettingsError(offerSettings, editedFields)?.[nameInput]
                          : offerSettingsErrors[nameInput]
                      }
                    />
                  )}
                </PBox>
              </Grid>
            ) : type === 'selectAndInput' || type === 'selectAndTwoInput' ? (
              <Grid container item xs={12} md={9} spacing={1}>
                <Grid item xs={12} md={type === 'selectAndInput' ? 6 : 4}>
                  <PBox pt={0.25}>
                    <Select
                      key={nameSelect}
                      value={getSelectValue()}
                      fullWidth
                      style={{ height: 40, width: '100%' }}
                      items={[
                        { text: 'Applied', value: true },
                        { text: 'No', value: false },
                      ]}
                      disabled={inputDisabled}
                      warning={!readOnly && offerSettingsWarnings[nameSelect]}
                      onChange={e => {
                        setIdEditingSetting(index);
                        setFieldHasChanged(true);
                        setFieldHasTouched(true);
                        setEditMode(true);
                        setEditedFields(oldValue => ({
                          ...oldValue,
                          [nameSelect]: e.target.value,
                        }));
                      }}
                      error={fieldHasChanged ? false : hasOfferSettingsError(offerSettings, editedFields)?.[nameSelect]}
                    />
                    <PBox mt="2px">
                      <Typography variant="h6" style={{ color: theme.palette.error.main }}>
                        {fieldHasChanged ? false : hasOfferSettingsError(offerSettings, editedFields)?.[nameSelect]}
                      </Typography>
                    </PBox>
                  </PBox>
                </Grid>
                {getSelectValue() !== false && !hasOfferSettingsError(offerSettings, editedFields)?.[nameSelect] && (
                  <>
                    <Grid item xs={12} md={type === 'selectAndInput' ? 6 : 4}>
                      <Input
                        key={nameInput}
                        value={fieldHasChanged ? editedFields[nameInput] : offerSettings[nameInput]}
                        disabled={inputDisabled}
                        onChange={e => {
                          setIdEditingSetting(index);
                          setFieldHasChanged(true);
                          setFieldHasTouched(true);
                          setEditMode(true);
                          setEditedFields(oldValue => ({
                            ...oldValue,
                            [nameInput]: e.target.value.replace(/(^[0])/g, '').trim(),
                          }));
                        }}
                        warning={!readOnly && offerSettings[nameSelect] && offerSettingsWarnings[nameInput]}
                        error={
                          fieldHasTouched
                            ? hasOfferSettingsError(offerSettings, editedFields)?.[nameInput]
                            : offerSettingsErrors[nameInput]
                        }
                      />
                    </Grid>
                    {type === 'selectAndTwoInput' && (
                      <Grid item xs={12} md={4}>
                        <CurrencyInput
                          key={nameInputTwo}
                          value={fieldHasChanged ? editedFields[nameInputTwo] : offerSettings[nameInputTwo]}
                          disabled={inputDisabled}
                          onChange={value => {
                            setIdEditingSetting(index);
                            setFieldHasChanged(true);
                            setFieldHasTouched(true);
                            setEditMode(true);
                            setEditedFields(oldValue => ({
                              ...oldValue,
                              [nameInputTwo]:
                                nameInputTwo === 'offerLeaseBackPricePerDay' ? value : value.replace(/(^[0])/g, ''),
                            }));
                          }}
                          warning={!readOnly && offerSettingsWarnings[nameInputTwo]}
                          allowNegative={false}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            ) : null}
            {showMarkFieldAsCorrect() && (
              <Grid item xs={responsive?.mdScreen ? 12 : 3}>
                <PBox pr={responsive.tabletScreen ? 2 : 1}>
                  <Button
                    fullWidth
                    variant="primary"
                    title="Mark field as correct"
                    icon="ArrowAccept"
                    iconAtStart
                    marginLeft={0}
                    marginRight={0}
                    align="center"
                    fontSize={12}
                    loading={loading}
                    disabled={isButtonDisabled}
                    onClick={() => {
                      let data = {};
                      if (offerSettings[nameSelect] || offerSettings[nameSelect] === false) {
                        data[nameSelect] = offerSettings[nameSelect];
                      }
                      if (offerSettings[nameInput] || offerSettings[nameInput] === 0) {
                        data[nameInput] = offerSettings[nameInput];
                      }
                      if (offerSettings[nameInputTwo] || offerSettings[nameInputTwo] === 0) {
                        data[nameInputTwo] = offerSettings[nameInputTwo];
                      }
                      saveOfferChange(data).finally(() => {
                        setIdEditingSetting(null);
                        setFieldHasChanged(false);
                        setFieldHasTouched(false);
                        setEditMode(false);
                        setOfferSettingsWarnings(oldValue => ({
                          ...oldValue,
                          [nameSelect]: undefined,
                          [nameInput]: undefined,
                          [nameInputTwo]: undefined,
                        }));
                        setLoading(false);
                      });
                    }}
                    style={{ height: 44 }}
                  />
                </PBox>
              </Grid>
            )}

            {fieldHasChanged && (
              <Grid item xs={3}>
                <Box pt={0.75} pl={2} display="flex">
                  <LinkButton
                    icon="Accept"
                    iconSize={16}
                    color="link"
                    style={{
                      marginRight: 8,
                      padding: 8,
                      background: '#0000000D',
                      borderRadius: '50%',
                    }}
                    disabled={
                      getSelectValue() === true && hasOfferSettingsError(offerSettings, editedFields)?.[nameInput]
                    }
                    loading={loading}
                    onClick={handleSumbit}
                  />

                  <LinkButton
                    style={{
                      marginRight: 8,
                      padding: 12,
                      background: '#0000000D',
                      borderRadius: '50%',
                    }}
                    icon="Close"
                    iconSize={10}
                    color="link"
                    disabled={!fieldHasChanged || loading}
                    onClick={() => {
                      setIdEditingSetting(null);
                      setFieldHasChanged(false);
                      setEditMode(false);
                      setEditedFields({ [nameSelect]: undefined, [nameInput]: undefined, [nameInputTwo]: undefined });
                      setFieldHasTouched(false);
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </PBox>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        xs={responsive.tabletScreen ? 12 : 5}
        style={{ borderLeft: !responsive.tabletScreen ? '1px solid #EAE8E4' : 'unset' }}
      >
        <PBox py={2.5} px={2} width="100%">
          <PreviewImageButton imageurl={offerSettings[imageUrl]} />
        </PBox>
      </Grid>
    </Grid>
  );
};
