import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const HeaderLink = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    fontSize: '16px',
    fontWeight: 400,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      color: '#731E6B',
      transition: '0.3s',
    },
  },
}))(Typography);
