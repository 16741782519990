import React from 'react';
import { shape } from 'prop-types';

export const WizardWait = ({ style }) => (
  <svg width="92" height="80" viewBox="0 0 92 80" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M84.1923 18.5693H71.9462C71.1012 18.5693 70.4154 19.2536 70.4154 20.1001C70.4154 20.9466 71.1012 21.6309 71.9462 21.6309H84.1923C86.7242 21.6309 88.7846 23.6913 88.7846 26.2232V72.1463C88.7846 74.6782 86.7242 76.7386 84.1923 76.7386H7.65385C5.12195 76.7386 3.06154 74.6782 3.06154 72.1463V26.2232C3.06154 23.6913 5.12195 21.6309 7.65385 21.6309H19.9C20.745 21.6309 21.4308 20.9466 21.4308 20.1001C21.4308 19.2536 20.745 18.5693 19.9 18.5693H7.65385C3.43352 18.5693 0 22.0029 0 26.2232V72.1463C0 76.3666 3.43352 79.8001 7.65385 79.8001H84.1923C88.4126 79.8001 91.8462 76.3666 91.8462 72.1463V26.2232C91.8462 22.0029 88.4126 18.5693 84.1923 18.5693Z"
      fill="black"
    />
    <path
      d="M45.9229 40C56.8955 40 65.8229 31.0725 65.8229 20.1C65.8229 9.1274 56.8955 0.199951 45.9229 0.199951C34.9504 0.199951 26.0229 9.1274 26.0229 20.1C26.0229 31.0725 34.9504 40 45.9229 40ZM45.9229 3.26149C55.2071 3.26149 62.7614 10.8158 62.7614 20.1C62.7614 29.3841 55.2071 36.9384 45.9229 36.9384C36.6388 36.9384 29.0845 29.3841 29.0845 20.1C29.0845 10.8158 36.6388 3.26149 45.9229 3.26149Z"
      fill="black"
    />
    <path
      d="M83.9349 31.4965C83.465 30.7924 82.5159 30.6026 81.8117 31.0725L54.2671 49.4356C54.2655 49.4371 54.264 49.4371 54.2625 49.4387L45.9228 54.9984L10.034 31.0725C9.32827 30.6026 8.3792 30.7924 7.91078 31.4965C7.44237 32.2007 7.63218 33.1498 8.33481 33.6197L33.9783 50.7153L8.33481 67.811C7.63218 68.2809 7.44237 69.23 7.91078 69.9341C8.20622 70.3765 8.69147 70.6153 9.18591 70.6153C9.47676 70.6153 9.7722 70.5311 10.034 70.3582L36.7382 52.5553L45.0733 58.112C45.3304 58.2835 45.6274 58.3692 45.9228 58.3692C46.2183 58.3692 46.5152 58.2835 46.7724 58.112L55.1075 52.5553L81.8117 70.3582C82.0735 70.5311 82.3689 70.6153 82.6598 70.6153C83.1542 70.6153 83.6395 70.3765 83.9349 69.9341C84.4033 69.23 84.2135 68.2809 83.5109 67.811L57.8674 50.7153L83.5109 33.6197C84.2135 33.1498 84.4033 32.2007 83.9349 31.4965Z"
      fill="black"
    />
    <path
      d="M45.9224 21.6307H53.5762C54.4212 21.6307 55.107 20.9464 55.107 20.0999C55.107 19.2534 54.4212 18.5692 53.5762 18.5692H47.4531V6.32301C47.4531 5.47649 46.7674 4.79224 45.9224 4.79224C45.0774 4.79224 44.3916 5.47649 44.3916 6.32301V20.0999C44.3916 20.9464 45.0774 21.6307 45.9224 21.6307Z"
      fill="black"
    />
  </svg>
);

WizardWait.propTypes = {
  style: shape({}),
};

WizardWait.defaultProps = {
  style: null,
};
