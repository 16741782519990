/**
 * @typedef {{
 *   ADMIN: 'Admin',
 *   SELLER: 'Seller',
 *   LISTING_AGENT: 'Listing Agent',
 *   BUYER: 'Buyer',
 *   BUYER_AGENT: "Buyer's Agent",
 * }}
 */
export const ROLES = {
  ADMIN: 'Admin',
  SELLER: 'Seller',
  LISTING_AGENT: 'Listing Agent',
  BUYER: 'Buyer',
  BUYER_AGENT: "Buyer's Agent",
};

/**
 * @typedef {{
 *   NEW: 'New',
 *   VERIFIED: 'Verified',
 *   REJECTED: 'Rejected',
 *   UNVERIFIED: 'Unverified',
 * }}
 */
export const LISTING_AGENT_VERIFY_STATUS = {
  NEW: 'New',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
  UNVERIFIED: 'Unverified',
};

/**
 * @typedef {{
 *   APPROVE: 'Approve',
 *   REJECT: 'Reject',
 * }}
 */
export const LISTING_AGENT_STATUS_ACTION = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
};
