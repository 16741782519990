import { Grid, Box, Typography } from '@material-ui/core';
import { theme } from 'utils/theme';

export const ChatMessageHeader = ({ chat }) => {
  return (
    <Box
      px={2}
      bgcolor={theme.palette.background.chatMessageHeader}
      style={{
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid container alignItems="center" justify="space-between" style={{ height: 40 }}>
        <Grid item xs={4} style={{ paddingTop: 4, paddingBottom: 4 }}>
          <Typography variant="h3" style={{ fontWeight: 700 }}>
            {' '}
            {chat.owner.fullName}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: 4, paddingBottom: 4 }}>
          <Typography variant="h3"> Call me back: {chat.owner.callMeBackPhone}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
