import { Typography } from '@material-ui/core';

import { NEW_PARTY_STATUS } from 'constant';
import { Icons, PBox } from 'legos';
import { theme } from 'utils/theme';

export const ContingentNotQualified = ({ plumBid }) => {
  const contingentNotQualified =
    plumBid.sellerParty.status === NEW_PARTY_STATUS.COMPLETED &&
    (plumBid.sellerParty.sellerPriorities?.offerContingent === false ||
      plumBid.sellerParty.sellerPriorities?.offerContingentSecondary === false);

  return contingentNotQualified ? (
    <PBox
      display="flex"
      alignItems="center"
      bgcolor={theme.palette.background.whiteSmoke1}
      borderRadius="2px"
      p={1}
      mb={1.75}
    >
      <Typography variant="h5">Contingent offers are not eligible for this plumBid</Typography>
      <PBox ml={1} display="flex" alignItems="center">
        <Icons.AttentionContingent />
      </PBox>
    </PBox>
  ) : null;
};
