import moment from 'moment';
import { useReactiveVar } from '@apollo/client';

import { PLUMBID_STATUS } from 'constant';
import state from 'service/graphql/state';
import { Box, Typography } from '@material-ui/core';
import { theme } from 'utils/theme';

export const ActiveMarkDateTime = ({ plumBid, extraSmallScreen, smallScreen }) => {
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);
  const timeLeft = plumBidTimeLeft[plumBid.id];

  const status =
    plumBid?.status === PLUMBID_STATUS.UPCOMING
      ? { line1: 'plumBid starts ', line2: `at ${moment(plumBid?.startDateTime).format('h:mm a')}` }
      : plumBid?.status === PLUMBID_STATUS.LIVE && !!timeLeft
      ? { line1: 'plumBid will finish ', line2: `in ${timeLeft}` }
      : { line1: 'plumBid Ended', line2: '' };
  return (
    <Box
      pt={4}
      display="flex"
      alignItems="center"
      px={extraSmallScreen ? 0 : 2}
      flexDirection={smallScreen ? 'row' : 'column'}
      justifyContent={smallScreen ? 'space-between' : null}
    >
      {plumBid?.status === PLUMBID_STATUS.LIVE && !!timeLeft ? (
        <Box
          py={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          order={smallScreen && 4}
          pl={extraSmallScreen ? 1 : 0}
        >
          <Typography style={{ color: theme.palette.background.coralRed, fontSize: '30px' }} variant="caption">
            •
          </Typography>
          <Typography style={{ color: theme.palette.background.coralRed }} variant="caption">
            Live
          </Typography>
        </Box>
      ) : null}
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection={smallScreen ? 'row' : 'column'}>
        <Box
          display="flex"
          flexDirection="column"
          pr={smallScreen ? 2 : 0}
          style={smallScreen ? { borderRight: '1px solid #E6E6E6' } : null}
        >
          <Typography variant="body2" align="center" style={{ fontSize: '15px', textTransform: 'uppercase' }}>
            {moment(plumBid?.startDateTime).format('ddd')}
          </Typography>
          <Typography variant="body2" style={{ fontSize: '56px' }}>
            {moment(plumBid?.startDateTime).format('D')}
          </Typography>
        </Box>
        <Box pl={smallScreen ? 2 : 0}>
          <Typography align="center" variant="subtitle2">
            {status.line1}
          </Typography>
          <Typography align="center" variant="subtitle2">
            {status.line2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
