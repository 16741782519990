import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useReactiveVar } from '@apollo/client';

import { Icons, PBox } from 'legos';
import { PLUMBID_STATUS } from 'constant';
import state from 'service/graphql/state';
import { theme } from 'utils/theme';

export const TimeLeft = ({ plumBid, isSandbox, isWinner }) => {
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);
  const timeLeft = plumBidTimeLeft[plumBid?.id];
  const isPlumBidActive = plumBid?.status === PLUMBID_STATUS.LIVE && timeLeft;

  return (
    <PBox px={2} py={1} bgcolor={theme.palette.primary.main}>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <Icons.TimerLeft style={{ fill: 'white' }} />
        </Grid>
        <Grid container item justify="space-evenly" xs={10}>
          <PBox px={1}>
            <Typography
              variant="caption"
              align="center"
              style={{
                textTransform: 'uppercase',
                fontSize: '20px',
                color: theme.palette.common.white,
              }}
            >
              {plumBid?.status === PLUMBID_STATUS.UPCOMING
                ? 'NOT STARTED'
                : isPlumBidActive
                ? ' TIME LEFT '
                : isSandbox && isWinner
                ? 'Congratulations'
                : plumBid?.status === PLUMBID_STATUS.FINISHED
                ? 'FINISHED'
                : 'PROCESSING'}
            </Typography>
          </PBox>
          {!!timeLeft && (
            <Typography
              align="center"
              variant="caption"
              style={{
                textTransform: 'uppercase',
                fontSize: '20px',
                color: theme.palette.common.white,
              }}
            >
              {timeLeft}
            </Typography>
          )}
        </Grid>
      </Grid>
    </PBox>
  );
};
