import { Typography } from '@material-ui/core';

import { Icons, PBox } from 'legos';
import { theme } from 'utils/theme';

export const WarningBlock = ({ text, variant = 'warning', style }) => {
  const colors =
    variant === 'success'
      ? { text: theme.palette.background.paper, bg: theme.palette.background.highBid }
      : variant === 'error'
      ? { text: theme.palette.error.main, bg: theme.palette.background.tutu }
      : variant === 'info'
      ? { text: theme.palette.text.summerSky, bg: theme.palette.text.aliceBlue }
      : variant === 'not_defined'
      ? { text: theme.palette.background.paper, bg: theme.palette.background.maroon }
      : { text: theme.palette.text.warning, bg: theme.palette.background.cardUserPartiallyReady };
  return (
    <PBox
      px={1}
      py={0.5}
      height="25px"
      style={style}
      display="flex"
      borderRadius="2px"
      bgcolor={colors.bg}
      alignItems="center"
      justifyContent="flex-start"
    >
      {variant !== 'success' && (
        <PBox pt={0.9} pr={1}>
          <Icons.Warning
            style={{
              fill: colors.text,
              stroke: colors.bg,
            }}
          />
        </PBox>
      )}
      <Typography
        variant="h6"
        align="left"
        style={{
          color: colors.text,
        }}
      >
        {text}
      </Typography>
    </PBox>
  );
};
