import { useMutation } from '@apollo/client';
import { RECALCULATE_PMBID_OFFERS_BIDS_MUTATION } from 'service/graphql';

import { Button } from 'legos';
import { useDialog, useNotification } from 'utils/hooks';
import { DIALOG } from 'constant';

export const ForceBid = ({ plumbidId }) => {
  const showNotification = useNotification();
  const { openDialog: openForceBidDialog, closeDialog: closeForceBidDialog } = useDialog(DIALOG.OK_CANCEL);
  const [forceAutoBid] = useMutation(RECALCULATE_PMBID_OFFERS_BIDS_MUTATION);

  const handlerForceBid = () => {
    openForceBidDialog({
      title: 'Force Bid',
      text: 'You really wanna to do force bid?',
      okTitle: 'Accept',
      onOk: () => {
        forceAutoBid({
          variables: {
            input: { plumbidId },
          },
        })
          .catch(error =>
            showNotification({
              message: error,
              variant: 'error',
            })
          )
          .then(({ data }) => {
            if (data.recalculatePmbidOffersBids.success) {
              showNotification({
                message: 'All success',
                variant: 'success',
              });
            }
          });
        closeForceBidDialog();
      },
    });
  };

  return <Button fullWidth title="Force Bid" onClick={() => handlerForceBid()} />;
};
