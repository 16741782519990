import React from 'react';

export const SquareBid = ({ style }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect width="24" height="24" rx="4" fill="current-color" />
    <path
      d="M19.5847 19.6694C19.7967 19.4551 19.9155 19.1658 19.9155 18.8644C19.9155 18.563 19.7967 18.2738 19.5847 18.0595L11.8092 10.2891L10.2251 11.8938L18.0058 19.6719C18.1093 19.7761 18.2324 19.8587 18.368 19.915C18.5036 19.9712 18.649 20.0001 18.7958 19.9999C18.9426 19.9996 19.0879 19.9703 19.2233 19.9136C19.3588 19.8568 19.4816 19.7738 19.5847 19.6694Z"
      fill="white"
    />
    <path d="M9.96763 5.5166L5.50488 10.031L8.51278 13.0751L12.9755 8.55809L9.96763 5.5166Z" fill="white" />
    <path
      d="M5.2564 10.3304C5.20263 10.2752 5.13831 10.2315 5.06728 10.2017C4.99624 10.172 4.91995 10.1569 4.84294 10.1572C4.76602 10.1574 4.68989 10.1728 4.61894 10.2026C4.54799 10.2323 4.4836 10.2757 4.42948 10.3304L4.17107 10.5888C4.06135 10.7021 4 10.8536 4 11.0113C4 11.169 4.06135 11.3205 4.17107 11.4338L7.13504 14.4184C7.24621 14.5252 7.39437 14.5848 7.5485 14.5848C7.70263 14.5848 7.85078 14.5252 7.96195 14.4184L8.22036 14.16C8.33115 14.0478 8.39326 13.8965 8.39326 13.7388C8.39326 13.5811 8.33115 13.4298 8.22036 13.3176L5.2564 10.3304Z"
      fill="white"
    />
    <path
      d="M13.2228 8.25811C13.3334 8.36607 13.4817 8.42651 13.6363 8.42651C13.7908 8.42651 13.9392 8.36607 14.0497 8.25811L14.3081 7.9997C14.4189 7.88749 14.481 7.73616 14.481 7.57849C14.481 7.42081 14.4189 7.26948 14.3081 7.15728L11.3442 4.17264C11.2901 4.11795 11.2257 4.07453 11.1547 4.04489C11.0838 4.01526 11.0076 4 10.9307 4C10.8538 4 10.7776 4.01526 10.7067 4.04489C10.6357 4.07453 10.5713 4.11795 10.5172 4.17264L10.2588 4.43105C10.1481 4.54325 10.0859 4.69458 10.0859 4.85226C10.0859 5.00993 10.1481 5.16126 10.2588 5.27347L13.2228 8.25811Z"
      fill="white"
    />
  </svg>
);
