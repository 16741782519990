import { useMemo, useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

import {
  SELLER_QUESTIONS_QUERY,
  CHANGE_SELLERS_ANSWERS_MUTATION,
  GET_OFFERS_MUTATION,
  CONFIRM_SELLER_ANSWERS_MUTATION,
} from 'service/graphql';
import { getQuestionsTree } from './questionTree';
import { useNotification } from '../useNotification';
import { useAuthState } from 'service/store';
import { usePlumBidById } from 'utils/hooks';
import { formatGraphqlError } from 'utils/formatters';

export const useSellerQuestions = (variables, selectedSeller) => {
  const [{ me }] = useAuthState();
  const showNotification = useNotification();
  const [priorityParams, setPriorityParams] = useState({
    listingPrice: 1,
    downPaymentImportance: 0,
    idealEscrowLength: 0,
    escrowPriority: 0,
    leaseBackLength: null,
    leaseBackImportance: null,
    leaseback: null,
    inspectionContingency: null,
    inspectionPriority: null,
    loanContingencyPriority: null,
    appraisalContingencyPriority: null,
    sellerContingencyPriority: null,
    buyerContingencyPriority: null,
    templateText: {
      'valueQ2-2': null,
      'valueQ3.1-2': null,
      'valueQ3.2-2': null,
    },
    illegalAnswerCombination: { cashDown: {}, inspectionContingency: {} },
    maxProgress: 22,
  });

  const [mismatch, setMismatch] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [sellersAnswers, setSellerAnswers] = useState([
    { questionId: 'Q1', answerId: null, answerValue: '', progress: 1 },
  ]);

  const { plumBid, plumBidError, plumBidLoading, plumBidRefetch } = usePlumBidById({
    id: variables.plumbidId,
  });
  const { data: sellersQuestionsData, loading: sellerQuestionsLoading, refetch } = useQuery(SELLER_QUESTIONS_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (sellersQuestionsData) {
      setPriorityParams(oldData => ({ ...oldData, mcai: sellersQuestionsData?.onboardingSellerQuestions?.mcai }));
    } else {
      setSellerAnswers([{ questionId: 'Q1', answerId: null, answerValue: null, progress: 1, branch: 1 }]);
    }
  }, [sellersQuestionsData]);

  const [topOffersFetch, { data: topOffers, loading: topOffersLoading }] = useMutation(GET_OFFERS_MUTATION, {
    variables: {
      input: {
        plumbidId: variables.plumbidId,
        ...(selectedSeller?.id ? { participantId: selectedSeller?.id } : null),
      },
      plumbidId: variables.plumbidId,
    },
    onError: error => showNotification({ error }),
  });

  const [confirmSellerAnswers, { loading: confirmSellerAnswersLoading }] = useMutation(
    CONFIRM_SELLER_ANSWERS_MUTATION,
    {
      variables: {
        input: {
          plumbidId: variables.plumbidId,
        },
      },
      onError: error => showNotification({ error }),
    }
  );

  const [saveSellerAnswers] = useMutation(CHANGE_SELLERS_ANSWERS_MUTATION);

  useEffect(() => plumBid && setPriorityParams(oldValue => ({ ...oldValue, listingPrice: plumBid.listPrice })), [
    plumBid,
  ]);

  const checkMismatch = (answers, questionTree) => {
    const isValueEqual = (val1, val2) => {
      if (!val1 && !val1) {
        return true;
      }
      return +val1 === +val2;
    };

    const mismatch = answers.some(({ questionId, answerId, answerValue }) => {
      if (!questionTree[questionId].partyAnswers.length || !answerId) {
        return false;
      }

      return questionTree[questionId].partyAnswers.find(
        item => item.answerId !== answerId || !isValueEqual(item.answerValue, answerValue)
      );
    });

    setMismatch(mismatch);
  };

  const addNewQuestion = useCallback((nextQuestionId, answers, answerIndex, questionTree) => {
    if (
      nextQuestionId &&
      nextQuestionId !== 'Finish' &&
      (answerIndex === answers.length - 1 || answers[answerIndex + 1].questionId !== nextQuestionId)
    ) {
      answers.splice(answerIndex + 1, 0, {
        questionId: nextQuestionId,
        answerId: nextQuestionId === 'Q6' ? 'Q6A1' : null,
        answerValue: '',
        progress: questionTree[nextQuestionId].progress,
        branch: questionTree[nextQuestionId].branch,
      });
    }

    return answers;
  }, []);

  const addOrRemoveOfferQuestion = useCallback((answers, curAnswer) => {
    if (curAnswer && curAnswer.questionId === 'Q16') {
      return answers;
    }
    //Remove offers question at least one answer is empty
    if (
      !answers.every(({ questionId, answerId, answerValue }) =>
        questionId === 'Q16' ? true : answerId === 'Q4A6' || answerId === 'Q6A1' ? !!answerValue : !!answerId
      )
    ) {
      return answers.filter(item => item.questionId !== 'Q16');
    }

    //Add offer question Q16 if all answers exist
    const offerQ16AnswerIndex = answers.findIndex(({ questionId }) => questionId === 'Q16');
    if (offerQ16AnswerIndex === -1) {
      const questionTree = getQuestionsTree(1, false);
      answers.push({
        questionId: 'Q16',
        answerId: null,
        answerValue: null,
        progress: questionTree.Q16.progress,
        branch: questionTree.Q16.progress,
      });
    } else {
      answers[offerQ16AnswerIndex].answerId = null;
      answers[offerQ16AnswerIndex].answerValue = '';
    }

    return answers;
  }, []);

  const getNextQuestionId = useCallback((answer, answers) => {
    if (typeof answer === 'function') {
      const result = answer(answers);
      return result;
    }
    return answer;
  }, []);

  const isQuestionReachedTo = useCallback(
    (answers, questionTree, questionReachedToList) => {
      let questionReachedTo = false;
      questionReachedToList = questionReachedToList || ['Finish'];

      answers.reduce((prev, { questionId, answerId, answerValue }) => {
        if (questionId === prev) {
          if (answerId === 'Q4A6' && !answerValue) {
            prev = null;
          } else if (answerId === 'Q6A1' && !answerValue) {
            prev = null;
          } else if (answerId === 'Q17A2') {
            prev = null;
          } else {
            const nextQuestionId = getNextQuestionId(questionTree[questionId][answerId], answers);
            if (questionReachedToList.indexOf(nextQuestionId) > -1) {
              questionReachedTo = true;
            }
            return nextQuestionId || prev;
          }
        }
        return prev;
      }, 'Q1');

      return questionReachedTo;
    },
    [getNextQuestionId]
  );

  const removeOfferQuestions = useCallback(answers => {
    const index = answers.findIndex(item => item.questionId === 'Q16');
    if (index > -1) {
      answers.splice(index, 1);
      removeOfferQuestions(answers);
    }
  }, []);

  const checkOffersAnswerExist = useCallback(
    (offers, answers) => {
      const answerWithOffer = answers.find(
        answer =>
          answer.questionId === 'Q16' &&
          (answer.answerId === 'Q16A1' || answer.answerId === 'Q16A2') &&
          answer.answerValue
      );

      if (answerWithOffer) {
        const index = offers.findIndex(offer => offer.offerId === answerWithOffer.answerValue);
        if (index > -1) {
          if (index === 0 && answerWithOffer.answerId === 'Q16A2') {
            const tempOffer = offers[0];
            offers[0] = offers[1];
            offers[1] = tempOffer;
          }
        } else {
          removeOfferQuestions(answers);
        }
      }
    },
    [removeOfferQuestions]
  );

  const sellerQuestionsInfo = useMemo(() => {
    setProcessing(true);
    const { onboardingSellerQuestions: questions, mcai, isNeedQ15 } =
      sellersQuestionsData?.onboardingSellerQuestions || {};

    if (questions?.length) {
      let myAnswers = [];
      const autoCalculating = [];
      const questionsTree = getQuestionsTree(mcai, isNeedQ15);
      let answeredSellers = [];
      const anotherSellersAnswer = {};
      Object.keys(questionsTree).forEach(questionId => {
        const question = questions.find(item => item.questionId === questionId);
        if (question) {
          questionsTree[questionId].text1 = question.text1;
          questionsTree[questionId].text2 = question.text2;
          questionsTree[questionId].text3 = question.text3;
          questionsTree[questionId].text4 = question.text4;
          questionsTree[questionId].text5 = question.text5;

          questionsTree[questionId].answerList = question.answerList;
          questionsTree[questionId].partyAnswers = question.partyAnswers.filter(
            ({ owner }) => owner.user.userId !== me.userId
          );
          questionsTree[questionId].partyAnswers.forEach(party => {
            if (!anotherSellersAnswer[party.owner.user.userId]) {
              anotherSellersAnswer[party.owner.user.userId] = [];
            }
            anotherSellersAnswer[party.owner.user.userId].push({
              answerId: party.answerId,
              answerValue: party.answerValue,
              questionId,
            });

            if (!answeredSellers.some(user => user.userId === party.owner.user.userId)) {
              answeredSellers.push(party.owner.user);
            }
          });

          //find my answer and extract it to the sellersAnswers array
          const myAnswer = question.partyAnswers.find(
            ({ owner }) => owner.user.userId === me.userId || owner.user.userId === selectedSeller?.user?.userId
          );

          if (myAnswer) {
            myAnswers.push({
              questionId,
              answerId: myAnswer.answerId,
              answerValue: myAnswer.answerValue || '',
              progress: questionsTree[questionId].progress,
              branch: questionsTree[questionId].branch,
            });
            if (questionsTree[questionId].calculatingAfterAnswer) {
              autoCalculating.push({
                answerId: myAnswer.answerId,
                answerValue: myAnswer.answerValue || '',
                hook: questionsTree[questionId].calculatingAfterAnswer,
              });
            }
          }
        }
      });

      if (answeredSellers.length) {
        answeredSellers = answeredSellers.filter(seller =>
          isQuestionReachedTo(anotherSellersAnswer[seller.userId], questionsTree, ['Finish'])
        );
      }

      if (myAnswers.length) {
        for (let index = 0; index < myAnswers.length; index++) {
          const lengthBeforeAddQuestion = myAnswers.length;

          const questionLeaf = questionsTree[myAnswers[index].questionId];

          const nextQuestionId = getNextQuestionId(questionLeaf[myAnswers[index].answerId], myAnswers);
          myAnswers = addNewQuestion(nextQuestionId, myAnswers, index, questionsTree);

          if (myAnswers.length !== lengthBeforeAddQuestion) {
            index = 0;
          }
        }

        checkMismatch(myAnswers, questionsTree);

        if (isQuestionReachedTo(myAnswers, questionsTree, ['Finish', 'Q16'])) {
          topOffersFetch()
            .then(res => {
              if (res?.data?.getOffers?.errors) {
                removeOfferQuestions(myAnswers);
                setCanSubmit(false);
                showNotification({ error: res.data.getOffers.errors });
              } else if (!(res?.data?.getOffers?.offers?.length > 1)) {
                setCanSubmit(isQuestionReachedTo(myAnswers, questionsTree, ['Finish']));
                removeOfferQuestions(myAnswers);
              } else {
                checkOffersAnswerExist(res?.data?.getOffers?.offers, myAnswers);
                setCanSubmit(isQuestionReachedTo(myAnswers, questionsTree, ['Finish']));
              }
            })
            .catch(error => {
              removeOfferQuestions(myAnswers);
              setCanSubmit(false);
              showNotification({ error });
            })
            .finally(() => {
              if (autoCalculating.length) {
                setPriorityParams(oldValue => ({
                  ...autoCalculating.reduce((prev, cur) => cur.hook(cur.answerId, cur.answerValue)(prev), oldValue),
                  maxProgress: questionsTree[myAnswers[myAnswers.length - 1].questionId].progress,
                }));
              }
              setSellerAnswers(myAnswers);
              setProcessing(false);
            });
        } else {
          if (autoCalculating.length) {
            setPriorityParams(oldValue =>
              autoCalculating.reduce((prev, cur) => cur.hook(cur.answerId, cur.answerValue)(prev), oldValue)
            );
          }
          removeOfferQuestions(myAnswers);
          setSellerAnswers(myAnswers);
          setProcessing(false);
        }
      } else {
        setProcessing(false);
      }

      return {
        mcai,
        isMeReachedToToFinish: isQuestionReachedTo(myAnswers, questionsTree, ['Finish']),
        sellerQuestions: questionsTree,
        answeredSellers,
        anotherSellersAnswer,
      };
    } else {
      return { sellerQuestions: null, maxProgress: 0, isMeReachedToToFinish: false };
    }
  }, [
    sellersQuestionsData?.onboardingSellerQuestions,
    isQuestionReachedTo,
    me.userId,
    selectedSeller?.user?.userId,
    addNewQuestion,
    topOffersFetch,
    removeOfferQuestions,
    showNotification,
    checkOffersAnswerExist,
    getNextQuestionId,
  ]);

  const processAnswers = useCallback(
    (answers, oldAnswers, questionsTree, curAnswer, question, answerId, answerValue, withoutSave) => {
      checkMismatch(answers, questionsTree);
      answers = addOrRemoveOfferQuestion(answers, curAnswer);

      const tempAnswers = [...answers];

      if (!withoutSave) {
        saveSellerAnswers({
          variables: {
            input: {
              plumbidId: variables.plumbidId,
              answers: answers
                .filter(answer => answer.answerId)
                .map(({ questionId, answerId, answerValue }) => ({
                  answerId,
                  answerValue: answerValue ? +answerValue : null,
                  questionId,
                })),
            },
          },
        })
          .then(res => {
            if (res?.data?.changeSellerAnswers?.errors) {
              throw Error(formatGraphqlError(res?.data?.changeSellerAnswers?.errors));
            }
            if (
              res?.data?.changeSellerAnswers?.success &&
              curAnswer.questionId !== 'Q16' &&
              isQuestionReachedTo(tempAnswers, questionsTree, ['Q16'])
            ) {
              topOffersFetch()
                .then(res => {
                  // res.data.getOffers.offers = [];
                  if (res?.data?.getOffers?.errors) {
                    removeOfferQuestions(tempAnswers);
                    setCanSubmit(true);
                    showNotification({ error: res.data.getOffers.errors });
                  } else if (!(res?.data?.getOffers?.offers?.length > 1)) {
                    const reachedToFinish = isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']);
                    setCanSubmit(reachedToFinish);
                    removeOfferQuestions(tempAnswers);
                  } else {
                    const reachedToFinish = isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']);
                    setCanSubmit(reachedToFinish);
                  }
                })
                .catch(error => {
                  removeOfferQuestions(tempAnswers);
                  setCanSubmit(true);
                  showNotification({ error });
                })
                .finally(() => {
                  if (question.calculatingAfterAnswer) {
                    setPriorityParams(oldParams => ({
                      ...question.calculatingAfterAnswer(answerId, answerValue)(oldParams),
                      maxProgress: questionsTree[tempAnswers[tempAnswers.length - 1].questionId].progress,
                    }));
                  }
                  setSellerAnswers(tempAnswers);
                  setProcessing(false);
                });
            } else {
              if (question.calculatingAfterAnswer) {
                setPriorityParams(question.calculatingAfterAnswer(answerId, answerValue));
              }
              plumBidRefetch();
              setCanSubmit(isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']));
              setSellerAnswers(tempAnswers);
              setProcessing(false);
            }
          })
          .catch(error => {
            setCanSubmit(isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']));
            setProcessing(false);
            setSellerAnswers(oldAnswers);
            showNotification({ error });
          });
      } else {
        if (question.calculatingAfterAnswer) {
          setPriorityParams(question.calculatingAfterAnswer(answerId, answerValue));
        }
        setCanSubmit(isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']));
        setSellerAnswers(tempAnswers);
        setProcessing(false);
      }
    },
    [
      addOrRemoveOfferQuestion,
      saveSellerAnswers,
      variables.plumbidId,
      isQuestionReachedTo,
      topOffersFetch,
      removeOfferQuestions,
      showNotification,
      plumBidRefetch,
    ]
  );

  const setAnswer = useCallback(
    (answerIndex, answerId, question, answerValue, withoutSave, touched) => {
      setProcessing(true);
      let newSellerAnswers = cloneDeep(sellersAnswers);
      newSellerAnswers[answerIndex].answerId = answerId;
      newSellerAnswers[answerIndex].answerValue = answerValue;
      newSellerAnswers[answerIndex].touched = touched;

      if (newSellerAnswers[answerIndex].questionId === 'Q4' && answerId !== 'Q4A6') {
        newSellerAnswers[answerIndex].answerValue = '';
      }

      //remove next questions with branch === current branch
      const curBranch = newSellerAnswers[answerIndex].branch;
      const countWithCurBranch = newSellerAnswers.reduce((prev, cur, index) => {
        if (index > answerIndex && cur.branch === curBranch) {
          prev += 1;
        }
        return prev;
      }, 0);

      if (answerIndex < newSellerAnswers.length - 1 && countWithCurBranch > 0) {
        newSellerAnswers.splice(answerIndex + 1, countWithCurBranch);
      }

      //add next question with empty answer (if not exist)
      const nextQuestionId = getNextQuestionId(question[answerId], newSellerAnswers);

      newSellerAnswers = addNewQuestion(
        nextQuestionId,
        newSellerAnswers,
        answerIndex,
        sellerQuestionsInfo.sellerQuestions
      );
      processAnswers(
        newSellerAnswers,
        sellersAnswers,
        sellerQuestionsInfo.sellerQuestions,
        {
          ...newSellerAnswers[answerIndex],
        },
        question,
        answerId,
        answerValue,
        withoutSave
      );
    },
    [sellerQuestionsInfo.sellerQuestions, processAnswers, addNewQuestion, sellersAnswers, getNextQuestionId]
  );

  return {
    ...sellerQuestionsInfo,
    mismatch,
    canSubmit,
    sellersAnswers,
    setAnswer,
    topOffers: topOffers?.getOffers?.offers || [],
    topOffersLoading,
    processing,
    priorityParams,
    plumBidLoading: plumBidLoading || sellerQuestionsLoading,
    plumBid,
    plumBidError,
    plumBidRefetch,
    confirmSellerAnswers,
    confirmSellerAnswersLoading,
    saveSellerAnswers,
    refetch,
  };
};
