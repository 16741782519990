import React from 'react';
import { shape } from 'prop-types';

export const Dashboard = ({ style }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.6111 1H1V7.6111H7.6111V1Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9997 1H11.3886V7.6111H17.9997V1Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9997 11.3889H11.3886V18H17.9997V11.3889Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.6111 11.3889H1V18H7.6111V11.3889Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Dashboard.propTypes = {
  style: shape({}),
};

Dashboard.defaultProps = {
  style: null,
};
