import gql from 'graphql-tag';

export const SANDBOX_USER_UPDATE_MUTATION = gql`
  mutation SandboxUserUpdate($input: SandboxUserUpdateInput!) {
    sandboxUserUpdate(input: $input) {
      success
      errors
    }
  }
`;
