import React from 'react';
import { shape } from 'prop-types';

export const TimeInvite = ({ style }) => (
  <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ style }}>
    <path
      d="M64 128C28.7095 128 0 99.2905 0 64C0 28.7095 28.7095 0 64 0C99.2905 0 128 28.7095 128 64C128 80.3818 120.585 97.0461 107.657 109.714C106.785 110.566 105.388 110.552 104.536 109.681C103.684 108.811 103.698 107.414 104.569 106.56C116.654 94.7178 123.586 79.2055 123.586 64C123.586 31.1437 96.8563 4.41379 64 4.41379C31.1437 4.41379 4.41379 31.1437 4.41379 64C4.41379 96.8563 31.1437 123.586 64 123.586C65.2182 123.586 66.2069 124.575 66.2069 125.793C66.2069 127.011 65.2182 128 64 128Z"
      fill="black"
    />
    <path
      d="M102.11 114.345C101.017 114.345 100.068 113.533 99.9249 112.42L97.1045 90.5325C96.9478 89.3231 97.8018 88.2174 99.0112 88.0607C100.214 87.8886 101.326 88.7559 101.483 89.9675L104.021 109.666L123.72 107.128C124.922 106.956 126.035 107.823 126.191 109.035C126.348 110.244 125.494 111.35 124.285 111.507L102.397 114.327C102.3 114.34 102.205 114.345 102.11 114.345Z"
      fill="black"
    />
    <path
      d="M64.0428 66.2069C62.8246 66.2069 61.8359 65.2182 61.8359 64V28.6897C61.8359 27.4714 62.8246 26.4828 64.0428 26.4828C65.261 26.4828 66.2497 27.4714 66.2497 28.6897V64C66.2497 65.2182 65.261 66.2069 64.0428 66.2069Z"
      fill="black"
    />
    <path
      d="M86.1118 66.2069H64.0428C62.8246 66.2069 61.8359 65.2182 61.8359 64C61.8359 62.7818 62.8246 61.7931 64.0428 61.7931H86.1118C87.33 61.7931 88.3187 62.7818 88.3187 64C88.3187 65.2182 87.33 66.2069 86.1118 66.2069Z"
      fill="black"
    />
    <path
      d="M63.9999 17.6552C62.7817 17.6552 61.793 16.6665 61.793 15.4483V11.0345C61.793 9.81627 62.7817 8.82758 63.9999 8.82758C65.2181 8.82758 66.2068 9.81627 66.2068 11.0345V15.4483C66.2068 16.6665 65.2181 17.6552 63.9999 17.6552Z"
      fill="black"
    />
    <path
      d="M63.9999 119.172C62.7817 119.172 61.793 118.184 61.793 116.966V112.552C61.793 111.334 62.7817 110.345 63.9999 110.345C65.2181 110.345 66.2068 111.334 66.2068 112.552V116.966C66.2068 118.184 65.2181 119.172 63.9999 119.172Z"
      fill="black"
    />
    <path
      d="M15.4488 66.2069H11.035C9.81681 66.2069 8.82812 65.2182 8.82812 64C8.82812 62.7818 9.81681 61.7931 11.035 61.7931H15.4488C16.667 61.7931 17.6557 62.7818 17.6557 64C17.6557 65.2182 16.667 66.2069 15.4488 66.2069Z"
      fill="black"
    />
    <path
      d="M116.966 66.2069H112.553C111.334 66.2069 110.346 65.2182 110.346 64C110.346 62.7818 111.334 61.7931 112.553 61.7931H116.966C118.185 61.7931 119.173 62.7818 119.173 64C119.173 65.2182 118.185 66.2069 116.966 66.2069Z"
      fill="black"
    />
    <path
      d="M29.6701 31.8764C29.1051 31.8764 28.5401 31.6601 28.1098 31.2298L24.987 28.107C24.1241 27.2441 24.1241 25.8494 24.987 24.9865C25.8499 24.1236 27.2447 24.1236 28.1076 24.9865L31.2303 28.1092C32.0932 28.9721 32.0932 30.3669 31.2303 31.2298C30.8 31.6601 30.235 31.8764 29.6701 31.8764Z"
      fill="black"
    />
    <path
      d="M26.5473 103.66C25.9823 103.66 25.4174 103.444 24.987 103.013C24.1241 102.151 24.1241 100.756 24.987 99.8929L28.1098 96.7702C28.9727 95.9073 30.3674 95.9073 31.2303 96.7702C32.0932 97.6331 32.0932 99.0278 31.2303 99.8907L28.1076 103.013C27.6772 103.444 27.1123 103.66 26.5473 103.66Z"
      fill="black"
    />
    <path
      d="M98.3324 31.8764C97.7675 31.8764 97.2025 31.6601 96.7722 31.2298C95.9093 30.3669 95.9093 28.9721 96.7722 28.1092L99.8949 24.9865C100.758 24.1236 102.153 24.1236 103.015 24.9865C103.878 25.8494 103.878 27.2441 103.015 28.107L99.8927 31.2298C99.4624 31.6601 98.8974 31.8764 98.3324 31.8764Z"
      fill="black"
    />
  </svg>
);

TimeInvite.propTypes = {
  style: shape({}),
};

TimeInvite.defaultProps = {
  style: null,
};
