import gql from 'graphql-tag';

export const RESET_PASSWORD_CONFIRM_MUTATION = gql`
  mutation ResetPasswordConfirm($input: ResetPasswordConfirmInput!) {
    resetPasswordConfirm(input: $input) {
      success
      errors
    }
  }
`;
