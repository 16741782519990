import React from 'react';
import { shape } from 'prop-types';

export const Delete = ({ style }) => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" style={style}>
    <path
      d="M1 4.20001H2.6H15.4"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

Delete.propTypes = {
  style: shape({}),
};

Delete.defaultProps = {
  style: {},
};
