import React from 'react';
import { shape } from 'prop-types';

export const BidAvatar = ({ style }) => (
  <svg width="24" height="24" viewBox="0 0 17 16" fill="none" style={style}>
    <path d="M16.4319 15.6695C16.6438 15.4552 16.7627 15.166 16.7627 14.8646C16.7627 14.5632 16.6438 14.2739 16.4319 14.0596L8.65632 6.28921L7.07227 7.89393L14.853 15.6721C14.9565 15.7762 15.0796 15.8588 15.2152 15.9151C15.3508 15.9714 15.4962 16.0002 15.643 16C15.7898 15.9998 15.9351 15.9704 16.0705 15.9137C16.2059 15.857 16.3287 15.774 16.4319 15.6695Z" />
    <path d="M6.81529 1.51637L2.35254 6.0308L5.36044 9.07487L9.82318 4.55786L6.81529 1.51637Z" />
    <path d="M2.10405 6.33055C2.05029 6.27542 1.98597 6.23168 1.91493 6.20193C1.8439 6.17219 1.76761 6.15705 1.6906 6.15742C1.61367 6.15763 1.53755 6.17303 1.4666 6.20274C1.39564 6.23245 1.33126 6.27589 1.27714 6.33055L1.01873 6.58896C0.909007 6.70224 0.847656 6.85376 0.847656 7.01146C0.847656 7.16917 0.909007 7.32069 1.01873 7.43397L3.9827 10.4186C4.09387 10.5254 4.24202 10.585 4.39615 10.585C4.55028 10.585 4.69844 10.5254 4.80961 10.4186L5.06802 10.1602C5.1788 10.048 5.24092 9.89666 5.24092 9.73899C5.24092 9.58131 5.1788 9.42998 5.06802 9.31778L2.10405 6.33055Z" />
    <path d="M10.0705 4.25811C10.181 4.36607 10.3294 4.42651 10.4839 4.42651C10.6384 4.42651 10.7868 4.36607 10.8974 4.25811L11.1558 3.9997C11.2666 3.88749 11.3287 3.73616 11.3287 3.57849C11.3287 3.42081 11.2666 3.26948 11.1558 3.15728L8.19182 0.172637C8.13774 0.117946 8.07336 0.0745259 8.00239 0.0448924C7.93142 0.0152588 7.85527 0 7.77836 0C7.70145 0 7.62531 0.0152588 7.55434 0.0448924C7.48336 0.0745259 7.41898 0.117946 7.3649 0.172637L7.10649 0.431047C6.99571 0.54325 6.93359 0.694579 6.93359 0.852256C6.93359 1.00993 6.99571 1.16126 7.10649 1.27347L10.0705 4.25811Z" />
  </svg>
);

BidAvatar.propTypes = {
  style: shape({}),
};

BidAvatar.defaultProps = {
  style: null,
};
