
export const RoundedArrow = ({ style }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g clipPath="url(#clip0_249_111)">
      <path d="M6.99382e-07 16C3.13124e-07 7.16344 7.16344 -3.13124e-07 16 -6.99382e-07C24.8366 -1.08564e-06 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 1.08564e-06 24.8366 6.99382e-07 16ZM16.7071 19.7071L21.2071 15.2071C21.4024 15.0118 21.5001 14.7559 21.5001 14.5C21.5001 13.6171 20.4247 13.1612 19.793 13.7929L16.0001 17.5858L12.2071 13.7929C11.8166 13.4024 11.1834 13.4024 10.7929 13.7929C10.4024 14.1834 10.4024 14.8166 10.7929 15.2071L15.2929 19.7071C15.6835 20.0976 16.3166 20.0976 16.7071 19.7071Z" fill="#77256F" />
    </g>
    <defs>
      <clipPath id="clip0_249_111">
        <rect width="32" height="32" fill="white" transform="translate(0 32) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>

);

