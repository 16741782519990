import React, { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Typography, CircularProgress } from '@material-ui/core';

import { Button, Checkbox, PBox, SplashDot } from 'legos';
import { formatPDFFileName } from 'utils';
import { useNotification, useDialog } from 'utils/hooks';
import { ONBOARDING_STATUS, ROLES, DIALOG, NEW_PARTY_STATUS } from 'constant';
import { CONFIRM_OFFER_MUTATION, EDIT_OFFER_SETTINGS, REMOVE_CONTINGENCY_MUTATION } from 'service/graphql';
import { ContingencyTag } from 'components/ContingencyTag';
import { hasOfferSettingsWarning } from 'utils';

export const OfferHeader = ({
  party,
  plumBid,
  responsive,
  isButtonDisabled,
  markAllAsCorrect,
  displayOnlyOneStep,
  loading,
  plumBidRefetch,
}) => {
  const showNotification = useNotification();
  const [confirmOffer, { loading: confirmOfferLoading }] = useMutation(CONFIRM_OFFER_MUTATION);
  const [editOfferSettings, { loading: editOfferSettingsLoading }] = useMutation(EDIT_OFFER_SETTINGS);
  const [removeContingency, { loading: removeContingencyLoading }] = useMutation(REMOVE_CONTINGENCY_MUTATION);
  const isOfferContingent = party?.offersettings?.agreementContingent;

  const isLA = plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT;
  const showContingencyRemoveButton =
    displayOnlyOneStep &&
    party?.onboardingStatus[ONBOARDING_STATUS.PENDING] &&
    plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT;
  const isIncorrectOffer =
    plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT && party.onboardingStatus[ONBOARDING_STATUS.INCORRECT];

  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: changePropsOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const confirmWithBuyer = () => {
    confirmOffer({
      variables: {
        input: {
          plumbidPartyId: party.id,
        },
      },
    })
      .then(res => {
        if (res?.data?.confirmOffer?.errors) {
          showNotification({ error: res?.data?.confirmOffer.errors });
        }
      })
      .catch(error => showNotification({ error }));
  };

  const showPDFPreview = () => {
    if (party?.offerdoc?.offerFileUrl) {
      window.open(party.offerdoc.offerFileUrl, '_blank');
    }
  };

  const handleEditOfferContingency = useCallback(async () => {
    await editOfferSettings({
      variables: {
        input: {
          offerDocId: party?.offersettings?.offerDoc?.id,
          agreementContingent: !isOfferContingent,
        },
      },
    })
      .then(plumBidRefetch)
      .catch(error => showNotification({ error }));
    return;
  }, [editOfferSettings, isOfferContingent, party?.offersettings?.offerDoc?.id, plumBidRefetch, showNotification]);

  const disableCheckbox = party?.status !== NEW_PARTY_STATUS.WAITING_FOR_PARTICIPANTS;

  const setOfferContingency = () => {
    if (!isOfferContingent) {
      openOkCancelDialog({
        okTitle: 'OK',
        cancelTitle: 'Cancel',
        title: 'Attention',
        text: `Depending on the seller's preferences the offer might not be qualified for the plumBid.
      Are you sure you want to make this Buyer offer contingent?`,
        onOk: () => {
          handleEditOfferContingency().finally(closeOkCancelDialog);
        },
        onClose: () => {
          closeOkCancelDialog();
        },
      });
    } else {
      handleEditOfferContingency();
    }
  };

  const removeContingencyStatus = () =>
    openOkCancelDialog({
      okTitle: 'OK',
      cancelTitle: 'Cancel',
      title: 'Remove Contingency',
      text:
        "By accepting this dialogue you confirm that the selected buyer's purchase agreement was renegotiated and all contingencies are removed, so we can let the buyer participate in plumBid",
      onOk: () => {
        removeContingency({
          variables: {
            input: {
              plumbidPartyId: party.id,
            },
          },
        })
          .catch(error => showNotification({ error }))
          .finally(closeOkCancelDialog);
      },
      onClose: () => {
        closeOkCancelDialog();
      },
    });

  useEffect(() => changePropsOkCancelDialog({ loading: removeContingencyLoading || editOfferSettingsLoading }), [
    changePropsOkCancelDialog,
    editOfferSettingsLoading,
    removeContingencyLoading,
  ]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" flexDirection={responsive?.padScreen ? 'column' : 'row'}>
        <Box display="flex" flexDirection="column" mb={responsive?.padScreen ? 2 : 0} style={{ overflow: 'hidden' }}>
          <Typography variant="h2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {party?.partyName}
          </Typography>
          <Typography variant="h5">{formatPDFFileName(party?.offerdoc?.filename)}</Typography>
        </Box>
        <Box display="flex" flexDirection={responsive?.mobileScreen ? 'column' : 'row'} flexWrap="wrap">
          {party?.offersettings?.agreementContingent && (
            <ContingencyTag contingencyRemoved={party?.secondaryContingencyRemoved} statusBlock />
          )}
          {showContingencyRemoveButton && (
            <Box m={0.5}>
              <Button
                fullWidth
                marginLeft={0}
                align="center"
                marginRight={0}
                uploadMultiple
                variant="primary"
                title="Remove contingency"
                onClick={() => removeContingencyStatus()}
                style={{ width: 170 }}
              />
            </Box>
          )}
          {isIncorrectOffer && (
            <Box m={0.5}>
              <Button
                variant="primary"
                marginLeft={0}
                marginRight={0}
                align="center"
                onClick={confirmWithBuyer}
                title="Incorrect Offer Fixed"
                loading={confirmOfferLoading}
                disabled={isButtonDisabled}
                style={{ width: 170, position: 'relative' }}
              >
                <SplashDot />
              </Button>
            </Box>
          )}
          {isLA && hasOfferSettingsWarning(party.offersettings) && (
            <Box m={0.5}>
              <Button
                fullWidth
                variant="primary"
                title={'Mark All as Correct'}
                icon={'ArrowAccept'}
                iconAtStart
                marginLeft={0}
                marginRight={0}
                align="center"
                fontSize={12}
                loading={loading}
                disabled={isButtonDisabled || !hasOfferSettingsWarning(party.offersettings)}
                onClick={markAllAsCorrect}
                style={{ width: 170 }}
              />
            </Box>
          )}
          <Box m={0.5}>
            <Button variant="tertiary" icon="Download" onClick={showPDFPreview} />
          </Box>
        </Box>
      </Box>
      <PBox pb={3} display="flex" alignItems="center">
        <PBox>
          <Typography variant="caption" style={{ paddingRight: 20 }}>
            Offer is Contingent
          </Typography>
        </PBox>
        <PBox display="flex" alignItems="center">
          <Checkbox
            disabled={disableCheckbox}
            component="span"
            checked={isOfferContingent}
            onChange={setOfferContingency}
          />
          {editOfferSettingsLoading && <CircularProgress size={20} />}
        </PBox>
      </PBox>
    </>
  );
};
