import React from 'react';
import { shape } from 'prop-types';

export const Search = ({ style }) => (
  <svg
    width="16"
    style={style}
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.22226 12.9445C10.6587 12.9445 13.4445 10.1587 13.4445 6.72226C13.4445 3.2858 10.6587 0.5 7.22226 0.5C3.7858 0.5 1 3.2858 1 6.72226C1 10.1587 3.7858 12.9445 7.22226 12.9445Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0001 14.5001L11.6167 11.1168"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Search.propTypes = {
  style: shape({}),
};

Search.defaultProps = {
  style: null,
};
