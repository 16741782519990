import { useState, useMemo } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';

import { Icons, Divider, TextButton, PBox } from 'legos';
import { useAuthState } from 'service/store';
import { HistoryTable } from './HistoryTable';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const HistoryBids = ({
  bids,
  bidsHistoryLoading,
  isMeBuyer,
  myPartyId,
  isSandbox,
  sandboxKey,
  myRole,
  isWinner,
  style = {},
  isFullService,
}) => {
  const responsive = useResponsive();
  const [{ me }] = useAuthState();
  const [isFilterMyBids, setIsFilterMyBids] = useState(false);

  const filteredBids = useMemo(() => {
    if (isFilterMyBids) {
      return isSandbox
        ? bids?.filter(bid => bid.owner?.user?.key === sandboxKey) || []
        : bids?.filter(bid => +bid.owner?.user?.id === +me.userId) || [];
    } else {
      return bids || [];
    }
  }, [bids, isFilterMyBids, me?.userId, sandboxKey, isSandbox]);

  return (
    <Grid
      item
      container
      direction="column"
      style={{
        ...style,
        padding: 26,
        borderRadius: 15,
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
      }}
    >
      {isWinner ? (
        <Box
          position="absolute"
          px={2}
          py={1}
          bgcolor={theme.palette.background.highBid}
          style={responsive.smallScreen ? { left: 32, top: 12 } : { right: 32, top: 32 }}
        >
          <Typography align="center" variant="h4" style={{ color: theme.palette.common.white, fontSize: 13 }}>
            Congratulations
          </Typography>
        </Box>
      ) : null}
      <Grid item container direction="row" spacing={2} style={{ marginTop: responsive.smallScreen ? 10 : 0 }}>
        <Grid item>
          <TextButton
            onClick={() => setIsFilterMyBids(false)}
            style={{
              color: isFilterMyBids ? theme.palette.text.primary : theme.palette.purple.light,
            }}
          >
            History
          </TextButton>
        </Grid>
        {isMeBuyer && (
          <Grid item>
            <TextButton
              onClick={() => setIsFilterMyBids(true)}
              style={{
                color: !isFilterMyBids ? theme.palette.text.primary : theme.palette.purple.light,
              }}
              startIcon={
                <Icons.Bid
                  style={{
                    fill: !isFilterMyBids ? theme.palette.text.primary : theme.palette.purple.light,
                  }}
                />
              }
            >
              My BID
            </TextButton>
          </Grid>
        )}
      </Grid>
      <Divider
        style={{
          marginTop: 16,
          marginBottom: 16,
          position: 'relative',
        }}
      />
      {bidsHistoryLoading ? (
        <PBox mt={2} mb={4} display="flex" justifyContent="center">
          <CircularProgress />
        </PBox>
      ) : (
        <HistoryTable
          bids={filteredBids}
          myRole={myRole}
          myPartyId={myPartyId}
          sandboxKey={sandboxKey}
          isSandbox={isSandbox}
          isFullService={isFullService}
        />
      )}
    </Grid>
  );
};
