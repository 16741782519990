import React from 'react';
import { shape } from 'prop-types';

export const Telephone = ({ style }) => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M6.23507 9.47562C7.15452 10.506 8.26704 11.3014 9.49447 11.8062L11.155 10.3465C11.2042 10.3091 11.2626 10.289 11.3224 10.289C11.3822 10.289 11.4405 10.3091 11.4898 10.3465L14.573 12.5409C14.69 12.6186 14.7891 12.7254 14.8624 12.8527C14.9357 12.98 14.9812 13.1243 14.9953 13.2744C15.0094 13.4245 14.9917 13.5761 14.9436 13.7173C14.8956 13.8585 14.8184 13.9855 14.7183 14.0881L13.2736 15.6645C13.0667 15.8903 12.8124 16.0561 12.5335 16.1469C12.2545 16.2377 11.9595 16.2509 11.6748 16.1851C8.83476 15.5372 6.21707 14.0197 4.12085 11.8062C2.07116 9.57062 0.652789 6.72606 0.0377852 3.61758C-0.0229692 3.30796 -0.0102889 2.98625 0.0745972 2.68364C0.159483 2.38102 0.313697 2.10776 0.522292 1.89033L2.01986 0.294454C2.11253 0.189256 2.22555 0.10858 2.35044 0.0584667C2.47534 0.00835362 2.60888 -0.00989807 2.74107 0.00507831C2.87326 0.0200547 3.00067 0.0678711 3.11376 0.144947C3.22685 0.222023 3.32268 0.326361 3.3941 0.450149L5.44664 3.8122C5.48204 3.86499 5.50115 3.92895 5.50115 3.99466C5.50115 4.06036 5.48204 4.12432 5.44664 4.17711L4.09443 5.97247C4.56377 7.30121 5.295 8.49787 6.23507 9.47562Z"
      fill="white"
    />
  </svg>
);

Telephone.propTypes = {
  style: shape({}),
};

Telephone.defaultProps = {
  style: null,
};
