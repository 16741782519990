import gql from 'graphql-tag';
import { PlumBidForOptimizer } from '../types';

export const GET_PLUMBID_BY_ID_FOR_OPTIMIZER = gql`
  query PlumbidByIdForOptimizer($id: Int) {
    plumbidById(id: $id) {
      ${PlumBidForOptimizer}
    }    
  }
`;
