import React from 'react';

import { useMutation } from '@apollo/client';

import { Box, Grid, Typography } from '@material-ui/core';

import { DIALOG } from 'constant';
import { Input, Button } from 'legos';
import { CREATE_PLUMBID_MUTATION } from 'service/graphql';
import { formatApplicationGraphqlError } from 'utils/formatters';
import { useNotification, useDialog, usePageLoading } from 'utils/hooks';
import { WizardPropertyScreen } from './WizardPropertyScreen';
import { WizardPropertyPlaceholder } from './WizardPropertyPlaceholder';

export const Step1 = ({ mlsId, plumbid, setMlsId, plumbidId, responsive, setPlumbidId, setIsNextDisabled }) => {
  const [createPlumBid, { loading }] = useMutation(CREATE_PLUMBID_MUTATION);
  const showNotification = useNotification();
  const { openDialog: openOkDialog, closeDialog: closeOkDialog } = useDialog(DIALOG.OK_CANCEL);
  usePageLoading(loading, 'Step1');

  const clickAddPlumBid = async e => {
    try {
      const { data } = await createPlumBid({
        variables: {
          input: {
            mlsId,
          },
        },
      });
      if (data.createPlumbid.success) {
        setPlumbidId(data.createPlumbid.plumbid.id);
        if (!data.createPlumbid.plumbid.mls) {
          openOkDialog({
            title: 'Attention',
            text:
              'The MLS ID that you entered was not found. Please re-enter it, or, if the problem persists, contact plumBid support.',
            okTitle: 'OK',
            hideCancel: true,
            onOk: closeOkDialog,
          });
        }
      } else if (data.createPlumbid.errors) {
        showNotification({
          message: formatApplicationGraphqlError(data.createPlumbid.errors),
          variant: 'error',
        });
      }
    } catch (err) {}
  };

  setIsNextDisabled(!plumbidId);

  return (
    <Grid container spacing={2} justify="space-between" alignItems="flex-start">
      <Grid item xs={12} md={responsive.tabletScreen ? 8 : 5}>
        <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
          Add your listing
        </Typography>

        <Box
          mt={4}
          style={{
            maxWidth: 450,
          }}
        >
          <Box display="flex" alignItems="center" pb={1}>
            <Typography variant={'h4'}>Please add your property's MLS ID number so we can get started</Typography>
          </Box>
          <Input autoFocus value={mlsId} onChange={event => setMlsId(event.target.value)} />
        </Box>
        <Box mt={2} mb={3}>
          <Button
            title="Confirm MLS ID"
            align="center"
            disabled={!mlsId}
            style={{ width: 150 }}
            loading={loading}
            onClick={clickAddPlumBid}
          />
        </Box>
      </Grid>
      <Grid item container xs={responsive.tabletScreen ? 12 : 7} justify="center">
        {plumbid?.mls ? <WizardPropertyScreen plumBid={plumbid} /> : <WizardPropertyPlaceholder />}
      </Grid>
    </Grid>
  );
};
