import gql from 'graphql-tag';

export const SEND_EMAIL_BACK_UP_MUTATION = gql`
mutation NotifyParticipantBackup($input: NotifyParticipantBackupInput!) {
  notifyParticipantBackup(input: $input) {
      success
      errors
    }
  }
`;
