import {
  APPRAISAL_MAX,
  APPRAISAL_MAX_DIFF,
  APPRAISAL_MIN,
  ESCROW_MAX,
  ESCROW_MIN,
  INSPECTION_MAX,
  INSPECTION_MAX_DIFF,
  INSPECTION_MIN,
  LOAN_MAX,
  LOAN_MAX_DIFF,
  LOAN_MIN,
} from 'constant';
import { isEmpty } from 'lodash-es';

export const hasOfferSettingsError = (offerSettings, data = {}) => {
  let setErrors = {};
  if (+data['escrowLength'] < ESCROW_MIN) {
    setErrors['escrowLength'] = `Should be bigger then ${ESCROW_MIN}`;
  }
  if (+data['escrowLength'] > ESCROW_MAX) {
    setErrors['escrowLength'] = `Should be less then ${ESCROW_MAX}`;
  }
  if (offerSettings.inspectionContingency !== false) {
    if (+data['inspectionContingencyLength'] > +offerSettings.escrowLength - INSPECTION_MAX_DIFF) {
      setErrors['inspectionContingencyLength'] = `Should be less than Escrow Period - ${INSPECTION_MAX_DIFF}`;
    }
    if (+data['inspectionContingencyLength'] < INSPECTION_MIN) {
      setErrors['inspectionContingencyLength'] = `Should be bigger then ${INSPECTION_MIN}`;
    }
    if (+data['inspectionContingencyLength'] > INSPECTION_MAX) {
      setErrors['inspectionContingencyLength'] = `Should be less then ${INSPECTION_MAX}`;
    }
  }
  if (offerSettings.appraisalContingency !== false) {
    if (
      +data['appraisalContingencyLength'] > +offerSettings.escrowLength - APPRAISAL_MAX_DIFF &&
      +offerSettings.escrowLength !== 5
    ) {
      setErrors['appraisalContingencyLength'] = `Should be less than Escrow Period - ${APPRAISAL_MAX_DIFF}`;
    }
    if (+data['appraisalContingencyLength'] > +offerSettings.escrowLength - 2 && +offerSettings.escrowLength === 5) {
      setErrors['appraisalContingencyLength'] = `Should be less than Escrow Period - 2`;
    }

    if (+data['appraisalContingencyLength'] < APPRAISAL_MIN) {
      setErrors['appraisalContingencyLength'] = `Should be bigger then ${APPRAISAL_MIN}`;
    }
    if (+data['appraisalContingencyLength'] > APPRAISAL_MAX) {
      setErrors['appraisalContingencyLength'] = `Should be less then ${APPRAISAL_MAX}`;
    }
    if (+offerSettings.offerPrice === +offerSettings.cashdownAmount) {
      setErrors['appraisalContingency'] = `Should be "No" because OfferPrice = Down Payment`;
      setErrors['appraisalContingencyLength'] = false;
    }
  }
  if (offerSettings.loanContingency !== false) {
    if (
      +data['loanContingencyLength'] > +offerSettings.escrowLength - LOAN_MAX_DIFF &&
      +offerSettings.escrowLength !== 5
    ) {
      setErrors['loanContingencyLength'] = `Should be less than Escrow Period - ${LOAN_MAX_DIFF}`;
    }
    if (+data['loanContingencyLength'] > +offerSettings.escrowLength - 2 && +offerSettings.escrowLength === 5) {
      setErrors['loanContingencyLength'] = `Should be less than Escrow Period - 2`;
    }
    if (+data['loanContingencyLength'] < LOAN_MIN) {
      setErrors['loanContingencyLength'] = `Should be bigger then ${LOAN_MIN}`;
    }
    if (
      offerSettings.appraisalContingency !== false &&
      +data['loanContingencyLength'] < +offerSettings.appraisalContingencyLength
    ) {
      setErrors['loanContingencyLength'] = `Should be bigger then Appraisal Duration`;
    }
    if (+data['loanContingencyLength'] > LOAN_MAX) {
      setErrors['loanContingencyLength'] = `Should be less then ${LOAN_MAX}`;
    }
    if (+offerSettings.offerPrice === +offerSettings.cashdownAmount) {
      setErrors['loanContingency'] = `Should be "No" because OfferPrice = Down Payment`;
      setErrors['loanContingencyLength'] = false;
    }
  }

  if (offerSettings.offerLeaseBack !== false && +data['offerLeaseBackLength'] === 0) {
    setErrors['offerLeaseBackLength'] = `Should be bigger than zero`;
  }

  if (+data['cashdownAmount'] > +offerSettings.offerPrice) {
    setErrors['cashdownAmount'] = `Should be less than Offer Price`;
  }

  if (!data['cashdownAmount'] && +data['cashdownAmount'] !== 0) {
    setErrors['cashdownAmount'] = `Downpayment has to be not less than 0`;
  }

  if (+data['offerPrice'] === 0) {
    setErrors['offerPrice'] = `Should be bigger than zero`;
  }

  if (!isEmpty(setErrors)) {
    return setErrors;
  }
  return false;
};
