import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { theme } from 'utils';
import { Icons } from 'legos/Icons';

const StyledAccordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.primary.main,
  },
})(AccordionSummary);

const StyledAccordionDetails = withStyles({
  root: {
    padding: '0px !important',
  },
})(AccordionDetails);

export const AccordionList = ({ title, content, icon, titleStyle, iconStyle }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const Icon = Icons[icon] || null;

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={icon ? <Icon style={iconStyle} /> : <ExpandMoreIcon />}>
        <Typography
          variant='body1'
          style={{
            fontSize: '16px',
            fontWeight: 500,
            color: theme.palette.primary.main,
            ...titleStyle
          }}>
          {title}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box pl={4}>{content}</Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

