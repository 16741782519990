import React from 'react';

export const Marker = ({ style, ...props }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" style={style} {...props}>
    <path
      d="M9.5 -4.15258e-07C12.0196 -5.25392e-07 14.4359 1.00089 16.2175 2.78249C17.9991 4.56408 19 6.98044 19 9.5C19 12.0196 17.9991 14.4359 16.2175 16.2175C14.4359 17.9991 12.0196 19 9.5 19C6.98044 19 4.56408 17.9991 2.78249 16.2175C1.00089 14.4359 -3.05125e-07 12.0196 -4.15258e-07 9.5C-5.25392e-07 6.98044 1.00089 4.56408 2.78249 2.78249C4.56408 1.00089 6.98044 -3.05125e-07 9.5 -4.15258e-07ZM5.34375 8.90625C5.18628 8.90625 5.03525 8.96881 4.9239 9.08015C4.81256 9.1915 4.75 9.34253 4.75 9.5C4.75 9.65747 4.81256 9.80849 4.9239 9.91984C5.03525 10.0312 5.18628 10.0937 5.34375 10.0937L12.2229 10.0937L9.67337 12.6421C9.56188 12.7536 9.49925 12.9048 9.49925 13.0625C9.49925 13.2202 9.56188 13.3714 9.67337 13.4829C9.78487 13.5944 9.93608 13.657 10.0937 13.657C10.2514 13.657 10.4026 13.5944 10.5141 13.4829L14.0766 9.92037C14.1319 9.86522 14.1758 9.7997 14.2057 9.72756C14.2357 9.65543 14.2511 9.5781 14.2511 9.5C14.2511 9.4219 14.2357 9.34457 14.2057 9.27243C14.1758 9.2003 14.1319 9.13478 14.0766 9.07962L10.5141 5.51712C10.4026 5.40563 10.2514 5.343 10.0937 5.343C9.93608 5.343 9.78486 5.40563 9.67337 5.51712C9.56188 5.62861 9.49925 5.77983 9.49925 5.9375C9.49925 6.09517 9.56188 6.24638 9.67337 6.35787L12.2229 8.90625L5.34375 8.90625Z"
      fill="#2CA084"
    />
  </svg>
);
