import { useReactiveVar } from '@apollo/client';

import { Button } from 'legos';
import state from 'service/graphql/state';
import { BidProcessing } from './BidProcessing';
import { PROCESSING_STEP_COUNT } from 'constant';

export const BidButtonContainer = ({ plumbidData, disabled, loading, variant, onClick, title, textIcon, ...props }) => {
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);
  const bidProcessing = useReactiveVar(state.bidProcessingVar);

  if (!plumBidTimeLeft[plumbidData?.id]) {
    return null;
  }

  if (bidProcessing.loading || bidProcessing.currentStep < PROCESSING_STEP_COUNT) {
    return <BidProcessing />;
  }

  return (
    <Button
      title={title}
      align="center"
      disabled={disabled}
      variant={variant}
      style={{ width: '70%' }}
      loading={loading}
      onClick={onClick}
      textIcon={textIcon}
      {...props}
    />
  );
};
