import gql from 'graphql-tag';

export const CONFIRM_OFFER_MUTATION = gql`
  mutation ConfirmOffer($input: ConfirmOfferInput!) {
    confirmOffer(input: $input) {
      success
      errors
    }
  }
`;
