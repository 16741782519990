import gql from 'graphql-tag';

export const UPDATE_PARTICIPANT_INVITE_MUTATION = gql`
  mutation UpdateParticipantInvite($input: UpdateParticipantInviteInput!) {
    updateParticipantInvite(input: $input) {
      success
      errors
      # fullName
      invite {
        token
      }
    }
  }
`;
