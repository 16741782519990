import React from 'react';
import { shape } from 'prop-types';

export const Instagram = ({ style }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M19.5556 0H2.44444C1.1 0 0 1.1 0 2.44444V19.5556C0 20.9012 1.1 22 2.44444 22H19.5556C20.9 22 22 20.9012 22 19.5556V2.44444C22 1.1 20.9 0 19.5556 0ZM10.9804 17.7503C12.7757 17.75 14.4974 17.0366 15.7667 15.767C17.036 14.4975 17.7491 12.7757 17.7491 10.9804C17.7491 10.5673 17.6966 10.1689 17.6232 9.77778H19.5556V18.5973C19.5556 18.7084 19.5336 18.8184 19.491 18.9209C19.4484 19.0235 19.386 19.1166 19.3073 19.195C19.2286 19.2733 19.1352 19.3354 19.0324 19.3775C18.9297 19.4197 18.8196 19.4411 18.7086 19.4407H3.29144C3.18039 19.4411 3.07033 19.4197 2.96759 19.3775C2.86485 19.3354 2.77145 19.2733 2.69275 19.195C2.61405 19.1166 2.5516 19.0235 2.50899 18.9209C2.46638 18.8184 2.44444 18.7084 2.44444 18.5973V9.77778H4.33767C4.26311 10.1689 4.21056 10.5673 4.21056 10.9804C4.21088 12.7758 4.92424 14.4976 6.19377 15.7671C7.4633 17.0367 9.18506 17.75 10.9804 17.7503ZM6.75033 10.9804C6.75033 10.4249 6.85975 9.87487 7.07233 9.36165C7.28491 8.84843 7.5965 8.38211 7.9893 7.9893C8.38211 7.5965 8.84843 7.28491 9.36165 7.07233C9.87487 6.85975 10.4249 6.75033 10.9804 6.75033C11.536 6.75033 12.086 6.85975 12.5992 7.07233C13.1125 7.28491 13.5788 7.5965 13.9716 7.9893C14.3644 8.38211 14.676 8.84843 14.8886 9.36165C15.1011 9.87487 15.2106 10.4249 15.2106 10.9804C15.2106 12.1023 14.7649 13.1783 13.9716 13.9716C13.1783 14.7649 12.1023 15.2106 10.9804 15.2106C9.85855 15.2106 8.7826 14.7649 7.9893 13.9716C7.196 13.1783 6.75033 12.1023 6.75033 10.9804ZM18.7086 6.11111H16.7347C16.5106 6.11047 16.2958 6.02115 16.1373 5.86268C15.9788 5.7042 15.8895 5.48945 15.8889 5.26533V3.289C15.8889 2.82211 16.2678 2.44444 16.7334 2.44444H18.7073C19.1767 2.44444 19.5556 2.82211 19.5556 3.289V5.26411C19.5556 5.72978 19.1767 6.11111 18.7086 6.11111Z"
      fill="white"
    />
  </svg>
);

Instagram.propTypes = {
  style: shape({}),
};

Instagram.defaultProps = {
  style: null,
};
