import { memo } from 'react';

import { LinkButton, PBox } from 'legos';
import { CopyToClipboard } from 'components';

export const ActionButtonsBlock = memo(({ seller, removeSeller, disabledActionButton }) => {
  return (
    <>
      {seller.invite?.link && (
        <PBox pl={1}>
          <CopyToClipboard
            label="Copy link"
            text={seller.invite?.link}
            disabled={disabledActionButton}
            style={{
              padding: 8,
              background: '#0000000D',
              borderRadius: '5px',
            }}
          />
        </PBox>
      )}
      <PBox pl={1}>
        <LinkButton
          icon="Delete"
          color="link"
          iconSize={18}
          onClick={removeSeller}
          disabled={disabledActionButton}
          style={{
            padding: 8,
            background: '#0000000D',
            borderRadius: '50%',
          }}
        />
      </PBox>
    </>
  );
});
