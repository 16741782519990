import { string, object, array, ref } from 'yup';

export const MockScheme = object({
  firstName: string().required('Entering first name is required'),

  lastName: string().required('Entering last name is required'),
});

export const Step3Scheme = object({
  sellers: array(
    object({
      email: string()
        .test('Is email  empty?', 'Email is required', function (value) {
          return !(!value && this.resolve(ref('fullName')));
        })
        .email('Email is not valid'),
    })
  ),
});
