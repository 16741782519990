import React from 'react';
import { shape } from 'prop-types';

export const AttentionContingent = ({ style }) => (
  <svg width="20" height="20" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25929 1.6561C6.57489 0.463672 8.26732 0.463672 8.58292 1.6561C8.80633 2.50027 9.82698 2.83189 10.5039 2.28027C11.4601 1.50108 12.8293 2.49587 12.3838 3.64606C12.0683 4.46033 12.6991 5.32854 13.571 5.28016C14.8026 5.21183 15.3256 6.82143 14.289 7.49006C13.5552 7.9634 13.5552 9.03657 14.289 9.50991C15.3256 10.1785 14.8026 11.7881 13.571 11.7198C12.6991 11.6714 12.0683 12.5396 12.3838 13.3539C12.8293 14.5041 11.4601 15.4989 10.5039 14.7197C9.82698 14.1681 8.80633 14.4997 8.58292 15.3439C8.26732 16.5363 6.57489 16.5363 6.25929 15.3439C6.03588 14.4997 5.01523 14.1681 4.3383 14.7197C3.38208 15.4989 2.01287 14.5041 2.45845 13.3539C2.77388 12.5396 2.14309 11.6714 1.27121 11.7198C0.0396122 11.7881 -0.483379 10.1785 0.553168 9.50991C1.28697 9.03657 1.28697 7.9634 0.553168 7.49006C-0.483379 6.82143 0.0396126 5.21183 1.27121 5.28016C2.14309 5.32854 2.77388 4.46033 2.45845 3.64606C2.01287 2.49587 3.38208 1.50108 4.3383 2.28027C5.01523 2.83189 6.03588 2.50027 6.25929 1.6561ZM9.7115 6.20959C9.90676 6.40486 9.90676 6.72144 9.7115 6.9167L8.12821 8.49999L9.7115 10.0833C9.90676 10.2785 9.90676 10.5951 9.7115 10.7904C9.51623 10.9856 9.19965 10.9856 9.00439 10.7904L7.42111 9.20709L5.83782 10.7904C5.64256 10.9856 5.32598 10.9856 5.13071 10.7904C4.93545 10.5951 4.93545 10.2785 5.13071 10.0833L6.714 8.49999L5.13071 6.9167C4.93545 6.72144 4.93545 6.40486 5.13071 6.20959C5.32598 6.01433 5.64256 6.01433 5.83782 6.20959L7.42111 7.79288L9.00439 6.20959C9.19965 6.01433 9.51623 6.01433 9.7115 6.20959Z"
      fill="#989898"
    />
  </svg>
);

AttentionContingent.propTypes = {
  style: shape({}),
};

AttentionContingent.defaultProps = {
  style: null,
};
