import { useEffect } from 'react';

import { useAuthState } from 'service/store/authStore';
import { apolloClient, ON_NEW_PLUMBID_FOR_OPTIMIZER_EVENT_SUBSCRIPTION } from 'service/graphql';
import { normalizePlumBid } from 'utils/normalizePlumBid';

export const usePlumBidSubscription = () => {
  const [{ me }] = useAuthState();

  useEffect(() => {
    if (me?.userId) {
      const observer = apolloClient.subscribe({
        query: ON_NEW_PLUMBID_FOR_OPTIMIZER_EVENT_SUBSCRIPTION,
        variables: { room: 'plumbid_events' },
      });

      const subscription = observer.subscribe(({ data }) => {
        const plumBid = data?.onNewPlumBidEvent?.plumbid;
        if (plumBid) {
          normalizePlumBid(plumBid, false, me);
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [me?.userId, me]);
};
