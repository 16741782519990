import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { Button, Input } from 'legos';
import { useNotification } from 'utils/hooks';
import { SEND_AGENT_EMAIL_MUTATION } from 'service/graphql';
import { useSettingsState } from 'service/store/settingsStore';
import ReCAPTCHA from 'react-google-recaptcha';
import { getEnv } from 'utils';

const validationSchema = object().shape({
  email: string().trim().email('Invalid email').required('Email is required').nullable(),
});

export const SendAgentEmailScreen = () => {
  const showNotification = useNotification();
  const [sendAgentEmailMutation, { loading }] = useMutation(SEND_AGENT_EMAIL_MUTATION);
  const [{ isOpenDrawer }, dispatchSettings] = useSettingsState();
  const [isVerified, setIsVerified] = useState(false);
  const googleReCaptchaApiKey = getEnv('GOOGLE_RECAPTCHA_API_KEY');

  const handleRecaptchaChange = value => {
    setIsVerified(value);
  };

  const formik = useFormik({
    initialValues: {
      email: null,
    },
    validationSchema,
    onSubmit: values => {
      sendAgentEmailMutation({ variables: { input: { email: values.email } } })
        .then(response => {
          if (response.data.sendAgentEmail.success) {
            showNotification({
              message: 'Email sent',
              variant: 'success',
            });
            dispatchSettings({
              type: 'toggleAuthDrawer',
              payload: {
                isOpenDrawer: !isOpenDrawer,
              },
            });
          } else if (response.data.sendAgentEmail.errors) {
            showNotification({
              error: response.data.sendAgentEmail.errors,
            });
          }
        })
        .catch(error => {
          showNotification({ error });
        });
    },
  });

  return (
    <Box mx={3}>
      <Box my={3}>
        <Typography variant="h4">Please let us know your agent's email so we can send him an invite.</Typography>
      </Box>
      <Box my={3}>
        <Input
          autoFocus
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email}
        />
      </Box>
      <Box mt={2} mb={2} display="flex" justifyContent="center">
        <ReCAPTCHA sitekey={googleReCaptchaApiKey} onChange={handleRecaptchaChange} />
      </Box>
      <Box py={5} display="flex" justifyContent="flex-end">
        <Button
          title="Send"
          style={{ width: '30%' }}
          onClick={formik.handleSubmit}
          align="center"
          loading={loading}
          disabled={!isVerified || !formik.values.email || formik.errors.email}
        />
      </Box>
    </Box>
  );
};
