import { Box, Typography } from '@material-ui/core';
import { PlumBidItem as DashboardPlumBidItem } from 'components/Dashboard/PlumBidItem';
import { usePlumBidById } from 'utils/hooks';
import { theme } from 'utils/theme';

export const PlumBidDetails = ({ plumBidId }) => {
  const { plumBid, plumBidLoading, plumBidError, plumBidRefetch } = usePlumBidById({ id: plumBidId }, 'network-only');

  return plumBidError || plumBidLoading ? (
    <Box display="flex" justifyContent="center" py={2}>
      {plumBidError && <Typography style={{ color: theme.palette.text.error }}>plumBid not found</Typography>}
    </Box>
  ) : (
    <DashboardPlumBidItem plumBid={plumBid} refetch={plumBidRefetch} />
  );
};
