import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import state from 'service/graphql/state';

export const useSelectChat = () => {
  const setSelectedChat = useCallback(id => {
    state.selectedChatVar(id);
  }, []);

  return {
    selectedChat: useReactiveVar(state.selectedChatVar),
    setSelectedChat,
  };
};
