import React from 'react';

export const ArrowGo = ({ style }) => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M5.17205 7L0.222046 2.05L1.63605 0.636002L8.00005 7L1.63605 13.364L0.222046 11.95L5.17205 7Z"
      fill="currentColor"
    />
  </svg>
);
