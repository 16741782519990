import React from 'react';
import { shape } from 'prop-types';

export const HouseArrow = ({ style }) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.5 2L1 7.95V17.3C1 17.7509 1.17559 18.1833 1.48816 18.5021C1.80072 18.8209 2.22464 19 2.66667 19H14.3333C14.7754 19 15.1993 18.8209 15.5118 18.5021C15.8244 18.1833 16 17.7509 16 17.3V11.95"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 19V12H11V19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 6V1H13"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8L18 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

HouseArrow.propTypes = {
  style: shape({}),
};

HouseArrow.defaultProps = {
  style: null,
};
