import gql from 'graphql-tag';

export const ADD_LISTING_AGENT_MUTATION = gql`
  mutation AddListingAgentRole($input: AddListingAgentRoleInput!) {
    addListingAgentRole(input: $input) {
      success
      errors
    }
  }
`;
