const FONT_FAMILY_HN = '"Helvetica Neue", "Roboto", "Aria", sans-serif';
const FONT_FAMILY_H = '"Helvetica", "Roboto", "Aria", sans-serif';

export const typography =   {
  h1: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 800,
    fontSize: 34,
    lineHeight: 1.18, // 40
    letterSpacing: 'normal',
  },
  h2: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 800,
    fontSize: 24,
    lineHeight: 1.17, // 28
    letterSpacing: 'normal',
  },
  h3: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1.2, // 24
    letterSpacing: 'normal',
  },
  h4: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.14, // 16
    letterSpacing: 'normal',
  },
  h5: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.2, // 17
    letterSpacing: 'normal',
  },
  h6: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.17, // 14
    letterSpacing: 'normal',
  },

  body1: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.14, // 16
    letterSpacing: 'normal',
  },
  body2: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 300,
    fontSize: 13,
    lineHeight: 1.23, // 16
    letterSpacing: 'normal',
  },
  subtitle1: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 2, // 28
    letterSpacing: 'normal',
  },
  subtitle2: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.42, // 17
    letterSpacing: 'normal',
  },
  caption: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.2, // 17
    letterSpacing: 'normal',
  },
  overline: {
    fontFamily: FONT_FAMILY_HN,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.2, // 17
    letterSpacing: 'normal',
  },

  button: {
    fontFamily: FONT_FAMILY_H,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.29, // 18
    letterSpacing: 'normal',
  },

}