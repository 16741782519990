import gql from 'graphql-tag';

export const CREATE_PLUMBID_MUTATION = gql`
  mutation CreatePlumbid($input: CreatePlumBidInput!) {
    createPlumbid(input: $input) {
      success
      errors
      plumbid {
        id
        mls {
          mlsId
        }
      }
    }
  }
`;
