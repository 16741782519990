import React, { useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCellMaterial from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { formatUserFullName } from 'utils/formatters';
import { useDialog, useListingAgentStatusAction } from 'utils/hooks';
import { Icons, LinkButton, TextWithLabel } from 'legos';
import { DIALOG, LISTING_AGENT_STATUS_ACTION } from 'constant';
import { theme } from 'utils/theme';

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))(TableCellMaterial);

export const ListingAgentTable = ({ joinedListingAgents }) => {
  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: setLoadingInOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const {
    approveListingAgent,
    approveListingAgentLoading,
    rejectListingAgent,
    rejectListingAgentLoading,
  } = useListingAgentStatusAction();

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: approveListingAgentLoading,
      }),
    [approveListingAgentLoading, setLoadingInOkCancelDialog]
  );

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: rejectListingAgentLoading,
      }),
    [rejectListingAgentLoading, setLoadingInOkCancelDialog]
  );

  const openDialogHandle = useCallback(
    (email, approveOrReject) => {
      openOkCancelDialog({
        onOk: () => {
          if (approveOrReject === LISTING_AGENT_STATUS_ACTION.APPROVE) {
            approveListingAgent(email, closeOkCancelDialog);
          } else {
            rejectListingAgent(email, closeOkCancelDialog);
          }
        },
        okTitle: approveOrReject,
        title: `${approveOrReject} Listing Agent`,
        text:
          approveOrReject === LISTING_AGENT_STATUS_ACTION.APPROVE
            ? `${approveOrReject} listing agent?`
            : 'The account will not be created and Listing Agent will not be able to log in',
      });
    },
    [rejectListingAgent, approveListingAgent, closeOkCancelDialog, openOkCancelDialog]
  );

  return (
    <Box display="flex" flexDirection="column" p={3.5} bgcolor={theme.palette.background.card}>
      <Box display="flex" mb={4} alignItems="center">
        <Icons.JoinedAgent
          style={{
            width: 24,
            height: 24,
          }}
        />
        <Typography
          variant="h5"
          style={{
            paddingLeft: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          New Listing Agent applications
        </Typography>
      </Box>
      <TableContainer
        style={{
          background: theme.palette.background.paper,
        }}
      >
        <Table
          style={{
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <TableHead>
            <TableRow style={{ height: 50 }}>
              <TableCell width="40%" style={{ backgroundColor: theme.palette.background.paper }}>
                <Typography variant="h4">Full name</Typography>
              </TableCell>
              <TableCell colSpan={2} style={{ backgroundColor: theme.palette.background.paper }}>
                <Typography variant="h4">Contacts</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {joinedListingAgents.map(item => (
              <TableRow
                key={item.id}
                hover
                style={{
                  height: 50,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell width="40%" component="th" scope="row" style={{ padding: '0 0 0 20px' }}>
                  <Box display="flex" alignItems="center">
                    <Icons.UserInverse
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 4,
                      }}
                    />
                    <TextWithLabel label={formatUserFullName(item)} text={`DRE id: ${item.dreId}`} />
                  </Box>
                </TableCell>

                <TableCell width="35%" style={{ padding: 0 }}>
                  <TextWithLabel label={item.phone} text={item.email} />
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: '0 20px 0 0',

                    whiteSpace: 'nowrap',
                    textAlign: 'end',
                  }}
                >
                  <LinkButton
                    icon="Accept"
                    iconSize={14}
                    color="link"
                    onClick={() => {
                      openDialogHandle(item.email, LISTING_AGENT_STATUS_ACTION.APPROVE);
                    }}
                    style={{ marginRight: 24 }}
                  />

                  <LinkButton
                    icon="Close"
                    iconSize={14}
                    color="link"
                    onClick={() => {
                      openDialogHandle(item.email, LISTING_AGENT_STATUS_ACTION.REJECT);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!joinedListingAgents.length && (
              <TableRow>
                <TableCell colSpan={3} width="40%" component="th" scope="row">
                  <Typography align="center">There are no pending Listing Agents to approve</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
