import { TableCell as TableCellMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const TableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  },
}))(TableCellMaterial);
