import { useEffect } from 'react';

import { Footer, PageContainer, LearnMore, BuyerBenefits, DescriptionOptimizer, AboutPlumBid } from 'components';

export const BuyersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <BuyerBenefits />
      <DescriptionOptimizer />
      <AboutPlumBid />
      <LearnMore />
      <Footer />
    </PageContainer>
  );
};
