import React, { useEffect, useRef } from 'react';
import { Box, List, Divider, CircularProgress } from '@material-ui/core';

import { Button } from 'legos';
import { MessageItem } from './MessageItem';
import { useAdminChatHistory, useSendAdminChatMessage, useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const ChatWithAdmin = ({ chatName, isAdminView }) => {
  const responsive = useResponsive();

  const messageRef = useRef();
  const scrollRef = useRef();
  const { adminChatHistory, adminChatHistoryLoading } = useAdminChatHistory({
    chatName,
  });
  const { sendAdminChatMessage, sendAdminChatMessageLoading } = useSendAdminChatMessage(chatName);

  const sendMessage = () => {
    if (messageRef?.current?.value) {
      sendAdminChatMessage({
        variables: {
          input: {
            chatName,
            message: messageRef?.current.value,
          },
        },
      }).then(() => {
        if (messageRef?.current) {
          messageRef.current.value = '';
          setTimeout(() => scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
        }
      });
    }
  };

  useEffect(() => {
    setTimeout(() => scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
  }, [adminChatHistory, isAdminView]);

  useEffect(() => {
    const sendMessageOnEnter = event => {
      if (!event.shiftKey && event.key === 'Enter') {
        sendMessage();
      }
    };
    const localMessageRef = messageRef?.current;

    messageRef?.current?.addEventListener('keypress', sendMessageOnEnter);
    return () => {
      localMessageRef?.removeEventListener('keypress', sendMessageOnEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatName]);

  return (
    <Box
      height={isAdminView ? 'calc(100vh - 240px)' : '100%'}
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
    >
      <List
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: '100%',
        }}
      >
        {adminChatHistoryLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          adminChatHistory.map((message, index) => (
            <React.Fragment key={message.id}>
              <Box pr={3}>
                <MessageItem message={message} />
              </Box>
              {adminChatHistory.length - 1 !== index && (
                <Box my={1}>
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          ))
        )}
        <Box ref={scrollRef} height={'0.1px'} width={1} />
      </List>
      <Box pb={1} width="100%" bgcolor="white" zIndex={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={responsive.mobileScreen ? 'column' : 'row'}
          pb={2}
          pt={responsive.mobileScreen ? 0.5 : 2}
          mx={isAdminView ? 0 : 2}
          style={{
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <textarea
            type="submit"
            ref={messageRef}
            placeholder="Click here to reply"
            style={{
              fontFamily: '"Helvetica Neue", Roboto, Aria, sans-serif',
              fontSize: 14,
              width: responsive.mobileScreen ? '98%' : '100%',
              height: responsive.mobileScreen ? 86 : 56,
              resize: 'none',
              textAria: true,
              border: 'none',
              outline: 'none',
              paddingLeft: 24,
              marginRight: 10,
            }}
            id="message-text"
          />
          <Button
            title="Send message"
            align="center"
            style={{
              width: responsive.mobileScreen ? '90%' : '40%',
              marginRight: responsive.mobileScreen ? 0 : 30,
              marginTop: responsive.mobileScreen ? 24 : 0,
            }}
            onClick={sendMessage}
            loading={sendAdminChatMessageLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};
