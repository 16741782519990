import React from 'react';
import { shape } from 'prop-types';

export const TimerLeft = ({ style }) => (
  <svg style={style} width="37" height="33" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.7567 12.4818H25.4739C25.2971 12.4818 25.1525 12.6264 25.1525 12.8032V14.7312C25.1525 14.908 25.2971 15.0526 25.4739 15.0526H35.7567C35.9335 15.0526 36.0781 14.908 36.0781 14.7312V12.8032C36.0781 12.6264 35.9335 12.4818 35.7567 12.4818ZM30.3743 17.9446H25.4739C25.2971 17.9446 25.1525 18.0892 25.1525 18.266V20.194C25.1525 20.3707 25.2971 20.5153 25.4739 20.5153H30.3743C30.551 20.5153 30.6956 20.3707 30.6956 20.194V18.266C30.6956 18.0892 30.551 17.9446 30.3743 17.9446ZM16.9704 8.88684H15.2311C14.9821 8.88684 14.7812 9.08768 14.7812 9.33672V19.2983C14.7812 19.4429 14.8495 19.5754 14.966 19.6598L20.947 24.022C21.1478 24.1666 21.429 24.1264 21.5736 23.9256L22.6059 22.5157V22.5117C22.7505 22.3108 22.7063 22.0297 22.5055 21.8851L17.4162 18.2057V9.33672C17.4203 9.08768 17.2154 8.88684 16.9704 8.88684Z"
      fill={style?.fill || '#E86D29'}
    />
    <path
      d="M30.1252 23.0017H27.8036C27.5786 23.0017 27.3657 23.1182 27.2452 23.311C26.7351 24.1184 26.1406 24.8655 25.4578 25.5484C24.2809 26.7253 22.9111 27.6491 21.3888 28.2918C19.8102 28.9586 18.1352 29.296 16.408 29.296C14.6768 29.296 13.0018 28.9586 11.4272 28.2918C9.90489 27.6491 8.53518 26.7253 7.35827 25.5484C6.18136 24.3715 5.25751 23.0017 4.61483 21.4794C3.94805 19.9048 3.61064 18.2298 3.61064 16.4986C3.61064 14.7674 3.94805 13.0964 4.61483 11.5178C5.25751 9.99549 6.18136 8.62578 7.35827 7.44887C8.53518 6.27196 9.90489 5.34811 11.4272 4.70543C13.0018 4.03865 14.6808 3.70124 16.408 3.70124C18.1392 3.70124 19.8142 4.03865 21.3888 4.70543C22.9111 5.34811 24.2809 6.27196 25.4578 7.44887C26.1406 8.13172 26.7351 8.87884 27.2452 9.6862C27.3657 9.87901 27.5786 9.99549 27.8036 9.99549H30.1252C30.4024 9.99549 30.5791 9.70629 30.4546 9.46127C27.8357 4.25153 22.5255 0.865409 16.5968 0.797124C7.9166 0.688671 0.706524 7.79431 0.690457 16.4665C0.67439 25.1547 7.71576 32.2041 16.404 32.2041C22.4091 32.2041 27.8076 28.806 30.4546 23.536C30.5791 23.291 30.3984 23.0017 30.1252 23.0017Z"
      fill={style?.fill || '#E86D29'}
    />
  </svg>
);

TimerLeft.propTypes = {
  style: shape({}),
};

TimerLeft.defaultProps = {
  style: null,
};
