import React from 'react';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const SurveyDialog = ({ isOpen, onClose, onOk, type, loading }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="Attention" onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4}>
          <Typography variant="h5" style={{ paddingBottom: 16, lineHeight: '22px' }}>
            {`This questionnaire helps determine your ${
              type === 'buyer' ? 'Buyer' : 'Seller'
            }  party’s priorities. There are several people in your ${
              type === 'buyer' ? 'Buyer' : 'Seller'
            } party and some of your responses do not match${
              type === 'buyer' ? '' : ' A plumBid can only be published if all people have a common vision.'
            }`}
          </Typography>
          <Typography variant="h5" style={{ lineHeight: '22px' }}>
            Please consult with the other Buyers in your party to match your answers.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" mt={1} mb={2}>
          <Button
            title="I understand, submit form"
            style={{ width: '100%' }}
            onClick={onOk}
            loading={loading}
            fullWidth
            icon="Add"
          />

          <Box height={8} />
          <Button
            title="Edit answers"
            variant="secondary"
            style={{ width: '100%' }}
            onClick={onClose}
            fullWidth
            icon="EditPen"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
