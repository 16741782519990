import { bool, shape, func } from 'prop-types';
import { Box } from '@material-ui/core/';

import { theme } from 'utils/theme';

export const Toggle = ({ value, onChange, id, disabled }) => {
  id = id ? id : Math.random();
  return (
    <Box className="pb--toggle-switch">
      <input
        disabled={disabled}
        type="checkbox"
        value={!value}
        checked={!value}
        onChange={event => {
          const value = event.target.checked;
          onChange(!value);
        }}
        className="pb--toggle-switch-checkbox"
        name={id}
        id={id}
      />
      <label className="pb--toggle-switch-label" htmlFor={id}>
        <span className={`pb--toggle-switch-inner ${disabled ? 'pb--toggle-switch-disabled' : ''}`} />
        <span
          className="pb--toggle-switch-switch"
          style={{
            backgroundColor: disabled
              ? theme.palette.text.grey
              : !value
              ? theme.palette.purple.light
              : theme.palette.common.white,
          }}
        />
      </label>
    </Box>
  );
};

Toggle.propTypes = {
  theme: shape({}),
  toggle: bool,
  onChange: func,
};

Toggle.defaultProps = {
  toggle: false,
};
