import gql from 'graphql-tag';

export const CALCULATE_BID_MUTATION = gql`
  mutation CalculateBid($input: CalculateBidInput!) {
    calculateBid(input: $input) {
      errors
      success
      bid {
        mcai
        price
        amountDown
        percentageDown
        escrowLength
        vaLoan
        inspectionContingency
        inspectionContingencyLength
        loanContingency
        loanContingencyLength
        appraisalContingency
        appraisalContingencyLength
        offerLeaseBack
        sellerContingency
        buyerWarranty
        buyerContingency
        downPaymentIncentive
        escrowIncentive
        inspectionContingencyIncentive
        inspectionContingencyLengthIncentive
        loanContingencyIncentive
        loanContingencyLengthIncentive
        appraisalContingencyIncentive
        appraisalContingencyLengthIncentive
        leaseBackIncentive
        leaseBackIncentiveDisplay
        sellerContingencyIncentive
        sellerContingencyIncentiveDisplay
        buyerWarrantyIncentive
        buyerContingencyIncentive
        totalIncentives
        plumBidAmount
      }
    }
  }
`;
