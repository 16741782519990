import { Box, Grid, Typography } from '@material-ui/core';

import { ABOUT_US, AGENTS_LA } from 'routes';
import AboutPlumBidPng from 'assets/images/AboutPlumBid.png';

import { RouterLink } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const AboutPlumBidLA = () => {
  const { mdScreen, tabletScreen } = useResponsive();
  const { pathname } = useLocation();
  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '30px 0' : '75px 0 80px 0',
        backgroundColor: theme.palette.background.card,
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        alignItems="center"
        style={
          mdScreen
            ? { padding: 24 }
            : {
                paddingLeft: tabletScreen
                  ? 0
                  : mdScreen || pathname === AGENTS_LA
                  ? 0
                  : 88,
                paddingRight: tabletScreen
                  ? 0
                  : mdScreen || pathname !== AGENTS_LA
                  ? 0
                  : 88,
                order: pathname !== AGENTS_LA ? 1 : 2,
              }
        }
      >
        <img style={{ width: '100%' }} src={AboutPlumBidPng} alt="" />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={6}
        justify="center"
        alignItems="center"
        style={{ order: pathname !== AGENTS_LA ? 2 : 1 }}
      >
        <Box px={{ xs: 3, md: 10 }}>
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            <Typography
              variant="h1"
              component="span"
              style={{ color: theme.palette.primary.main, fontWeight: 700 }}
            >
              plumBid{' '}
            </Typography>
            – invented by experts
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              padding: '26px 0',
            }}
          >
            While <b>plumBid</b> is an innovative concept, its founders leverage
            both an expertise in finance, as well as a 50+ year background in
            residential real estate. From Caltech educated economists, to real
            estate brokerage visionaries that have represented over ten thousand
            homes and billions of dollars in transactions.
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
            }}
          >
            In 2020, their firm was awarded “Most Innovative Brokerage” for
            their invention of plumBid by LeadingRE -- a global network of 565
            real estate companies representing 130,000 agents, across 70+
            countries.
            <RouterLink
              to={ABOUT_US}
              className="removeDecorators"
              style={{
                fontSize: 18,
                paddingLeft: 4,
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              Learn more
            </RouterLink>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
