import React from 'react';
import { shape } from 'prop-types';

export const UsersAdmin = ({ style }) => (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M16.1111 18V16.1111C16.1111 15.1092 15.7131 14.1483 15.0046 13.4398C14.2961 12.7313 13.3352 12.3333 12.3333 12.3333H4.77777C3.77585 12.3333 2.81495 12.7313 2.10648 13.4398C1.39801 14.1483 1 15.1092 1 16.1111V18"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.55567 8.55555C10.6421 8.55555 12.3334 6.86418 12.3334 4.77777C12.3334 2.69137 10.6421 1 8.55567 1C6.46926 1 4.77789 2.69137 4.77789 4.77777C4.77789 6.86418 6.46926 8.55555 8.55567 8.55555Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7775 18V16.1111C21.7769 15.2741 21.4983 14.461 20.9855 13.7994C20.4727 13.1379 19.7547 12.6654 18.9442 12.4561"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1663 1.1228C15.9789 1.33086 16.6992 1.80346 17.2135 2.46609C17.7279 3.12872 18.0071 3.94369 18.0071 4.78252C18.0071 5.62135 17.7279 6.43632 17.2135 7.09895C16.6992 7.76158 15.9789 8.23418 15.1663 8.44224"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

UsersAdmin.propTypes = {
  style: shape({}),
};

UsersAdmin.defaultProps = {
  style: null,
};
