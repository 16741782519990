import React from 'react';
import { shape } from 'prop-types';

export const LeftArrow = ({ style }) => (
  <svg
    style={style}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="3.00122" width="12" height="0.999999" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146894 3.85465C0.100331 3.8082 0.0633882 3.75303 0.0381817 3.69228C0.0129753 3.63154 4.50428e-07 3.56642 4.59052e-07 3.50065C4.67677e-07 3.43488 0.0129753 3.36976 0.0381818 3.30902C0.0633882 3.24827 0.100331 3.1931 0.146894 3.14665L3.14689 0.14665C3.24078 0.0527634 3.36812 1.90383e-05 3.50089 1.90557e-05C3.63367 1.90731e-05 3.76101 0.0527635 3.85489 0.14665C3.94878 0.240537 4.00153 0.367875 4.00153 0.50065C4.00153 0.633426 3.94878 0.760764 3.85489 0.854651L1.20789 3.50065L3.85489 6.14665C3.94878 6.24054 4.00153 6.36787 4.00153 6.50065C4.00153 6.63343 3.94878 6.76076 3.85489 6.85465C3.76101 6.94854 3.63367 7.00128 3.50089 7.00128C3.36812 7.00128 3.24078 6.94854 3.14689 6.85465L0.146894 3.85465Z"
    />
  </svg>
);

LeftArrow.propTypes = {
  style: shape({}),
};

LeftArrow.defaultProps = {
  style: null,
};
