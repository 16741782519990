import React from 'react';
import { string, func, number, oneOfType } from 'prop-types';
import NumberFormat from 'react-number-format';

import { Input } from 'legos';

export const PhoneInput = ({ value, onChange, ...props }) => {
  return (
    <NumberFormat
      format="### ### ####"
      value={value}
      onChange={event => {
        const { value } = event?.target;
        onChange(typeof value === 'string' ? value.replace(/[,\s$]+/g, '') : value);
      }}
      customInput={Input}
      {...props}
    />
  );
};

PhoneInput.propTypes = {
  value: oneOfType([string, number]),
  onChange: func,
};

PhoneInput.defaultProps = {
  value: '',
  onChange: () => {},
};
