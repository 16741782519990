import { shape } from 'prop-types';

export const EditDocument = style => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M11.5 4H4.5C3.96957 4 3.46086 4.21071 3.08579 4.58579C2.71071 4.96086 2.5 5.46957 2.5 6V20C2.5 20.5304 2.71071 21.0391 3.08579 21.4142C3.46086 21.7893 3.96957 22 4.5 22H18.5C19.0304 22 19.5391 21.7893 19.9142 21.4142C20.2893 21.0391 20.5 20.5304 20.5 20V13"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 2.50023C19.3978 2.1024 19.9374 1.87891 20.5 1.87891C21.0626 1.87891 21.6022 2.1024 22 2.50023C22.3978 2.89805 22.6213 3.43762 22.6213 4.00023C22.6213 4.56284 22.3978 5.1024 22 5.50023L12.5 15.0002L8.5 16.0002L9.5 12.0002L19 2.50023Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

EditDocument.propTypes = {
  style: shape({}),
};

EditDocument.defaultProps = {
  style: null,
};
