import { useState } from 'react';

import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils';
import { LinkButton } from 'legos';

export const PhotoAndShowMore = ({
  isOrderReverse,
  photo,
  title,
  text,
  textCollapse,
  bgColor,
  isBorder,
}) => {
  const { tabletScreen, mdScreen } = useResponsive();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Grid
      container
      alignItems={checked ? 'unset' : 'center'}
      style={{
        padding: mdScreen ? '30px 0' : '75px 15px 80px 15px',
        backgroundColor: bgColor || theme.palette.background.paper,
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={
          mdScreen
            ? { padding: 24, order: isOrderReverse ? 1 : 0 }
            : {
                paddingLeft: tabletScreen ? 0 : mdScreen ? 0 : 88,
                paddingRight: tabletScreen ? 0 : mdScreen ? 0 : 88,
                order: isOrderReverse ? 1 : 0,
              }
        }
      >
        <img
          style={{
            width: '100%',
            borderRadius: isBorder ? 15 : 'unset',
            border: isBorder ? '3px solid #b1b1b1' : 'unset',
          }}
          src={photo}
          alt=""
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        justify="center"
        alignItems={textCollapse ? 'unset' : 'center'}
      >
        <Box px={{ xs: 3, md: 10 }} pt={0}>
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
          {text}

          <Collapse in={checked} disableStrictModeCompat>
            {textCollapse}
          </Collapse>

          {textCollapse && (
            <LinkButton
              onClick={handleChange}
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: theme.palette.primary.main,
                textDecorationColor: theme.palette.primary.main,
                marginTop: '20px',
              }}
            >
              {checked ? 'Show less' : 'Show more'}
            </LinkButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
