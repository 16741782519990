import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useDialog, useNotification, useResponsive } from 'utils/hooks';
import { OfferDetailsItem } from './OfferDetailsItem';
import { useMutation } from '@apollo/client';
import { EDIT_OFFER_SETTINGS } from 'service/graphql';
import { OfferHeader } from './OfferHeader';
import {
  DIALOG,
  APPRAISAL_MIN,
  ESCROW_MIN,
  INSPECTION_MIN,
  LOAN_MIN,
  MINIMUM_PERCENT_NO_VA_LOAN,
  NEW_PARTY_STATUS,
  NEW_PARTICIPANT_STATUS,
  ROLES,
} from 'constant';
import { Divider, PBox } from 'legos';

const offerItems = [
  {
    type: 'currencyInput',
    nameInput: 'offerPrice',
    titleInput: 'Offer Price',
    imageUrl: 'offerPriceImage',
  },
  {
    type: 'currencyInput',
    nameInput: 'cashdownAmount',
    titleInput: 'Down Payment Amount',
    imageUrl: 'cashdownAmountImage',
  },
  {
    type: 'input',
    nameInput: 'escrowLength',
    titleInput: 'Escrow Period',
    imageUrl: 'escrowPeriodImage',
  },
  {
    type: 'selectAndInput',
    nameSelect: 'inspectionContingency',
    nameInput: 'inspectionContingencyLength',
    titleSelect: 'Inspection Contingency',
    titleInput: 'Inspection Duration',
    imageUrl: 'inspectionContingencyImage',
  },
  {
    type: 'selectAndInput',
    nameSelect: 'appraisalContingency',
    nameInput: 'appraisalContingencyLength',
    titleSelect: 'Appraisal Contingency',
    titleInput: 'Appraisal Duration',
    imageUrl: 'appraisalContingencyImage',
  },
  {
    type: 'selectAndInput',
    nameSelect: 'loanContingency',
    nameInput: 'loanContingencyLength',
    titleSelect: 'Loan Contingency',
    titleInput: 'Loan Duration',
    imageUrl: 'loanContingencyImage',
  },
  {
    type: 'selectAndTwoInput',
    nameSelect: 'offerLeaseBack',
    nameInput: 'offerLeaseBackLength',
    nameInputTwo: 'offerLeaseBackPricePerDay',
    titleSelect: 'Offer Lease Back',
    titleInput: 'Lease Back Duration',
    titleInputTwo: 'Lease Back Amount',
    imageUrl: 'offerLeaseBackImage',
  },
];

const offerSettingsFields = [
  'offerPrice',
  'cashdownAmount',
  'escrowLength',
  'appraisalContingencyLength',
  'appraisalContingency',
  'inspectionContingencyLength',
  'inspectionContingency',
  'loanContingencyLength',
  'loanContingency',
  'offerLeaseBack',
  'offerLeaseBackLength',
  'offerLeaseBackPricePerDay',
];

const initOfferSettingsWarnings = party => ({
  offerPrice: !party?.offersettings?.offerPriceIsPrecise,
  cashdownAmount: !party?.offersettings?.cashdownAmountIsPrecise,
  appraisalContingencyLength: !party?.offersettings?.appraisalContingencyLengthIsPrecise,
  appraisalContingency: !party?.offersettings?.appraisalContingencyIsPrecise,
  inspectionContingencyLength: !party?.offersettings?.inspectionContingencyLengthIsPrecise,
  inspectionContingency: !party?.offersettings?.inspectionContingencyIsPrecise,
  escrowLength: !party?.offersettings?.escrowLengthIsPrecise,
  loanContingencyLength: !party?.offersettings?.loanContingencyLengthIsPrecise,
  loanContingency: !party?.offersettings?.loanContingencyIsPrecise,
  offerLeaseBack: !party?.offersettings?.offerLeaseBackIsPrecise,
  offerLeaseBackLength: !party?.offersettings?.offerLeaseBackLengthIsPrecise,
  offerLeaseBackPricePerDay: !party?.offersettings?.offerLeaseBackPricePerDayIsPrecise,
});

export const OfferDetails = ({
  plumBid,
  party,
  plumBidRefetch,
  readOnly,
  fileUploading,
  displayOnlyOneStep,
  isButtonDisabled,
  setEditMode,
}) => {
  const responsive = useResponsive();
  const offerSettings = useMemo(
    () =>
      party.offersettings ?? {
        offerPrice: 1000,
        cashdownAmount: (MINIMUM_PERCENT_NO_VA_LOAN / 100) * 1000,
        escrowLength: ESCROW_MIN,
        inspectionContingency: true,
        inspectionContingencyLength: INSPECTION_MIN,
        loanContingency: true,
        loanContingencyLength: LOAN_MIN,
        appraisalContingency: true,
        appraisalContingencyLength: APPRAISAL_MIN,
        offerLeaseBack: false,
        offerLeaseBackLength: 0,
        offerLeaseBackPricePerDay: 0,
      },
    [party.offersettings]
  );

  const [offerSettingsWarnings, setOfferSettingsWarnings] = useState(initOfferSettingsWarnings(party));
  const [idEditingSetting, setIdEditingSetting] = useState(null);
  const [loading, setLoading] = useState(false);

  const [saveOfferSettings] = useMutation(EDIT_OFFER_SETTINGS);
  const { openDialog: openOkDialog, closeDialog: closeOkDialog, changeProps: changePropsOkDialog } = useDialog(
    DIALOG.OK_CANCEL
  );
  const showNotification = useNotification();

  const isBuyerCompleted = party?.participantSet.some(
    item => item.status === NEW_PARTICIPANT_STATUS.COMPLETED && item.role === ROLES.BUYER
  );

  useEffect(() => {
    setOfferSettingsWarnings(initOfferSettingsWarnings(party));
  }, [party]);

  const markAllAsCorrect = () => {
    openOkDialog({
      title: 'Attention',
      text: 'By clicking this you confirm that all the displayed offer details are correct',
      okTitle: 'OK',
      onOk: () => {
        let data = {};
        offerSettingsFields.map(item => (data[item] = offerSettings[item]));
        changePropsOkDialog({ loading: true });
        saveOfferChange(data).finally(() => {
          setLoading(false);
          setOfferSettingsWarnings(false);
          changePropsOkDialog({ loading: false });
          closeOkDialog();
        });
      },
    });
  };

  const saveOfferChange = useCallback(
    async data => {
      setLoading(true);
      if (
        Number(data['offerPrice']) === offerSettings.cashdownAmount ||
        Number(data['cashdownAmount']) === offerSettings.offerPrice
      ) {
        data['appraisalContingency'] = false;
        data['loanContingency'] = false;
      }
      if (data['offerLeaseBack'] === false) {
        data['offerLeaseBackLength'] = null;
        data['offerLeaseBackPricePerDay'] = null;
      }

      if (data['offerLeaseBackLength'] === '') {
        data['offerLeaseBackLength'] = null;
      }

      if (data['offerLeaseBackPricePerDay'] === '') {
        data['offerLeaseBackPricePerDay'] = null;
      }

      return saveOfferSettings({
        variables: {
          input: {
            offerDocId: party.offerdoc.id,
            ...data,
          },
        },
      })
        .then(plumBidRefetch)
        .catch(error => showNotification({ error }))
        .finally(() => {
          setOfferSettingsWarnings(initOfferSettingsWarnings(party));
        });
    },
    [offerSettings.cashdownAmount, offerSettings.offerPrice, saveOfferSettings, party, plumBidRefetch, showNotification]
  );

  return (
    <>
      <OfferHeader
        party={party}
        plumBid={plumBid}
        responsive={responsive}
        plumBidRefetch={plumBidRefetch}
        markAllAsCorrect={markAllAsCorrect}
        offerSettingsWarnings={offerSettingsWarnings}
        fileUploading={fileUploading}
        isButtonDisabled={isButtonDisabled}
        displayOnlyOneStep={displayOnlyOneStep}
      />
      <PBox border="1px solid #EAE8E4">
        {offerItems.map((offerItem, index) => {
          return (
            <Fragment key={offerItem.titleInput}>
              <OfferDetailsItem
                key={offerItem.nameInput}
                imageUrl={offerItem.imageUrl}
                offerItem={offerItem}
                responsive={responsive}
                offerSettings={offerSettings}
                offerSettingsWarnings={offerSettingsWarnings}
                setOfferSettingsWarnings={setOfferSettingsWarnings}
                index={index}
                readOnly={readOnly || party.status === NEW_PARTY_STATUS.COMPLETED}
                idEditingSetting={idEditingSetting}
                setIdEditingSetting={setIdEditingSetting}
                saveOfferChange={saveOfferChange}
                setEditMode={setEditMode}
                isButtonDisabled={isButtonDisabled}
                loading={loading}
                setLoading={setLoading}
                isBuyerCompleted={isBuyerCompleted}
              />
              {index !== offerItems.length - 1 && <Divider />}
            </Fragment>
          );
        })}
      </PBox>
    </>
  );
};
