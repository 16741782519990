import React from 'react';
import DrawerMaterial from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import {
  SignInScreen,
  SignUpScreen,
  ForgotPasswordScreen,
  ResetPasswordScreen,
  AddAdminUserScreen,
  UserChatScreen,
  SendAgentEmailScreen,
} from 'screens';
import { DialogHeader } from './Header/DialogHeader';
import { useSettingsState } from 'service/store';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: 483,
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
}));

const getScreen = drawerScreen => {
  switch (drawerScreen) {
    case 'SignInScreen':
      return {
        title: 'MY ACCOUNT',
        component: <SignInScreen />,
      };
    case 'SignInFromInvitationScreen':
      return {
        title: 'PLEASE SIGN IN',
        component: <SignInScreen withInvite />,
      };
    case 'SignUpScreen':
      return {
        title: 'MY ACCOUNT',
        component: <SignUpScreen />,
      };
    case 'SignUpFromInvitationScreen':
      return {
        title: 'PLEASE SIGN UP',
        component: <SignUpScreen withInvite />,
      };
    case 'ForgotPasswordScreen':
      return {
        title: 'MY ACCOUNT',
        component: <ForgotPasswordScreen />,
      };
    case 'ResetPasswordScreen':
      return {
        title: 'MY ACCOUNT',
        component: <ResetPasswordScreen />,
      };
    case 'AddAdminUserScreen':
      return {
        title: 'Add admin user',
        component: <AddAdminUserScreen />,
      };
    case 'UserChatScreen':
      return {
        title: 'Chat with admin',
        component: <UserChatScreen />,
      };
    case 'SendAgentEmailScreen':
      return {
        title: 'INVITE AGENT',
        component: <SendAgentEmailScreen />,
      };
    default:
      return {};
  }
};

export const Drawer = () => {
  const classes = useStyles();
  const [{ isOpenDrawer, drawerScreen }, dispatchSettings] = useSettingsState();

  const closeDrawer = () => {
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: false,
      },
    });
  };
  const screen = getScreen(drawerScreen);
  return (
    <DrawerMaterial
      variant="temporary"
      disableBackdropClick={drawerScreen !== 'UserChatScreen'}
      disableEscapeKeyDown
      anchor="right"
      open={isOpenDrawer}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <DialogHeader title={screen.title} onClose={closeDrawer} />
      <Box
        height="100%"
        style={{ overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}
        borderTop="1px solid #EAE8E4"
      >
        {screen.component}
      </Box>
    </DrawerMaterial>
  );
};
