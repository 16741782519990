import { createContext, useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';

const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const bigTabletScreen = useMediaQuery('(max-width: 1360px) ', {
    noSsr: true,
  });
  const tabletScreen = useMediaQuery('(max-width: 1250px) ', {
    noSsr: true,
  });
  const padScreen = useMediaQuery('(max-width: 970px) ', {
    noSsr: true,
  });
  const mdScreen = useMediaQuery('(max-width: 767px) ', {
    noSsr: true,
  });
  const mobileScreen = useMediaQuery('(max-width: 614px) ', {
    noSsr: true,
  });
  const smallScreen = useMediaQuery('(max-width: 400px) ', {
    noSsr: true,
  });

  return (
    <MediaContext.Provider value={{ bigTabletScreen, tabletScreen, padScreen, mdScreen, mobileScreen, smallScreen }}>
      {children}
    </MediaContext.Provider>
  );
};

export const useResponsive = () => useContext(MediaContext);
