import React from 'react';
import { shape } from 'prop-types';

export const Edit = ({ style }) => (
  <svg
    style={style}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 12.3661H0.875V13.375H15V12.3661Z" />
    <path d="M12.6796 3.79019C13.0831 3.38662 13.0831 2.78126 12.6796 2.37769L10.8635 0.56162C10.4599 0.158049 9.85457 0.158049 9.451 0.56162L1.88403 8.12858V11.3572H5.1126L12.6796 3.79019ZM10.1572 1.26787L11.9733 3.08394L10.4599 4.59733L8.64385 2.78126L10.1572 1.26787ZM2.89296 10.3482V8.53216L7.9376 3.48751L9.75368 5.30358L4.70903 10.3482H2.89296Z" />
  </svg>
);

Edit.propTypes = {
  style: shape({}),
};

Edit.defaultProps = {
  style: null,
};
