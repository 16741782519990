import React from 'react';

export const SandClock = ({ style }) => (
  <svg width="37" height="56" viewBox="0 0 37 56" fill="none" style={style}>
    <path
      d="M33.7519 14.7429V10.78H34.3419C35.7934 10.7839 36.9779 9.62981 37 8.19131V2.59263C36.9784 1.15325 35.7943 -0.00174801 34.3419 1.98601e-06L2.65761 1.98601e-06C1.20524 -0.00174801 0.0211832 1.15325 0 2.59263L0 8.19131C0.0220658 9.62981 1.20612 10.7839 2.65761 10.78H3.24809L3.24809 14.7429C3.24589 16.6198 4.10866 18.3947 5.5906 19.5624L16.2149 27.9829L5.79934 35.9923C4.27459 37.1569 3.38313 38.9598 3.38931 40.8686L3.38931 45.22H2.65761C1.20612 45.2161 0.0220658 46.3702 0 47.8087L0 53.4074C0.0211832 54.8468 1.20524 56.0018 2.65761 56H34.3419C35.7943 56.0018 36.9784 54.8468 37 53.4074V47.8087C36.9779 46.3702 35.7934 45.2161 34.3419 45.22H33.8931V40.8962C33.8843 38.9686 32.9734 37.1543 31.4275 35.9848L20.8001 27.972L31.4112 19.5619C32.8927 18.3943 33.755 16.6189 33.7519 14.7429ZM2.82443 2.8L34.1756 2.8V7.98L2.82443 7.98L2.82443 2.8ZM34.1756 53.2H2.82443L2.82443 48.02H4.55792C4.63912 48.02 4.72121 48.0428 4.80638 48.0428C4.89111 48.0428 4.97408 48.02 5.05528 48.02H32.2139C32.2947 48.02 32.3776 48.0428 32.4624 48.0428C32.5476 48.0428 32.6301 48.02 32.7108 48.02H34.1756V53.2ZM31.0687 40.8962V45.22H6.21374V40.8686C6.21153 39.8256 6.69831 38.8408 7.5324 38.2047L18.5071 29.7653L29.7169 38.213C30.5625 38.8518 31.0621 39.8427 31.0687 40.8962ZM29.6446 17.3753L18.5026 26.2076L7.35675 17.3753C6.54561 16.7383 6.07252 15.7688 6.07252 14.7429V10.78L30.9275 10.78V14.7429C30.9275 15.7688 30.4553 16.7379 29.6446 17.3753Z"
      fill="black"
    />
  </svg>
);
