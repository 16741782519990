import React from 'react';
import { Box, DialogContent, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string, ref } from 'yup';

import { Button, Input } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';
import { useNotification, useUserProfile } from 'utils/hooks';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
});

const validationSchema = object().shape({
  password: string().trim().required('Password is required').nullable(),
  repeatPassword: string()
    .trim()
    .required('Should not be empty')
    .oneOf([ref('password'), null], "Password confirmation doesn't match password"),
});

export const ChangePasswordDialog = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const showNotification = useNotification();
  const { changePassword, changePasswordLoading } = useUserProfile();

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      changePassword({ variables: { input: values } }).then(response => {
        if (response.data.changeUserPassword.success) {
          showNotification({
            message: 'Password changed',
            variant: 'success',
          });
          onClose();
        }
      });
    },
  });

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="CHANGE PASSWORD" onClose={onClose} />
      <DialogContent>
        <Box my={3}>
          <Input
            autoFocus
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && formik.errors.password}
          />
        </Box>
        <Input
          name="repeatPassword"
          label="Confirm new password"
          type="password"
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          error={formik.touched.repeatPassword && formik.errors.repeatPassword}
        />

        <Box py={5} display="flex">
          <Button style={{ width: '40%' }} variant="secondary" title="Cancel" align="center" onClick={onClose} />
          <Box component="span" pr={3} />
          <Button
            title="Save"
            style={{ width: '60%' }}
            onClick={formik.handleSubmit}
            align="center"
            loading={changePasswordLoading}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
