import gql from 'graphql-tag';

export const SCHEMA = gql`
  query PlumbidById($id: Int) {
    plumbidById(id: $id) {
      id
      status
      totalBidders
      readyBidders
      timeLeft {
        timeLeft
      }
      lastBid {
        id
        partyId
        owner {
          user {
            userId
            fullName
          }
        }
      }
      plumbidpartySet {
        id
        party
        status
        onboardingStatus
        sellerPriorities {
          id
          offerContingent
          offerContingentSecondary
        }
        offerdoc {
          filename
          offerFileUrl
          id
        }
        contingencyRemoved
        secondaryContingencyRemoved
        offersettings {
          id
          agreementContingent
          agreementContingentImage
          buyerWarrantyImage
          offerPrice
          offerPriceIsPrecise
          offerPriceImage
          propertyAddress
          propertyAddressIsPrecise
          propertyAddressImage
          cashdownAmount
          cashdownAmountIsPrecise
          cashdownAmountImage
          escrowLength
          escrowLengthIsPrecise
          escrowPeriodImage
          isVaLoan
          isFhaLoan
          inspectionContingency
          inspectionContingencyIsPrecise
          inspectionContingencyLength
          inspectionContingencyLengthIsPrecise
          inspectionContingencyImage
          loanContingency
          loanContingencyIsPrecise
          loanContingencyLength
          loanContingencyLengthIsPrecise
          loanContingencyImage
          appraisalContingency
          appraisalContingencyIsPrecise
          appraisalContingencyLength
          appraisalContingencyLengthIsPrecise
          appraisalContingencyImage
          offerLeaseBack
          offerLeaseBackIsPrecise
          offerLeaseBackLength
          offerLeaseBackLengthIsPrecise
          offerLeaseBackPricePerDay
          offerLeaseBackPricePerDayIsPrecise
          offerLeaseBackImage
          offerDateImage
          offerFromImage
        }
        participants {
          edges {
            node {
              id
              role
              onboardingStatus {
                mismatch
                review
                incorrectOffer
                pending
              }
              user {
                userId
                email
                fullName
              }
              offerParticipant {
                id
                fullName
                email
              }
              status
            }
          }
        }
      }
    }
  }
`;
