import React from 'react';

import { PageContainer, MyBuyerOnboarding } from 'components';

export const BuyerOnboardingPage = () => {
  return (
    <PageContainer>
      <MyBuyerOnboarding />
    </PageContainer>
  );
};
