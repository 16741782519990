import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Icons } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const WizardAlert = ({ isOpen, onClose, onOk, loading }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader onClose={onClose} height={52} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4} display="flex" alignItems="center" flexDirection="column">
          <Icons.WizardWait />
          <Typography variant="h1" style={{ paddingTop: 24, lineHeight: '28px', fontSize: 26 }}>
            Processing taking too long?
          </Typography>
          <Typography variant="h5" style={{ lineHeight: '28px' }}>
            We will email you when uploading is complete.
          </Typography>
          <Button
            title="Email me please"
            style={{ width: 130, marginTop: 16 }}
            onClick={onOk}
            loading={loading}
            align="center"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
