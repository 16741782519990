import React from 'react';
import { shape } from 'prop-types';

export const EditPen = ({ style }) => (
  <svg style={style} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.1875L9.375 2.8125L12.1875 5.625L2.8125 15H0V12.1875ZM10.3125 1.875L12.1875 0L15 2.8125L13.1241 4.68844L10.3125 1.875Z"
      fill="#731E6B"
    />
  </svg>
);

EditPen.propTypes = {
  style: shape({}),
};

EditPen.defaultProps = {
  style: null,
};
