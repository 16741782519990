import React from 'react';
import { shape } from 'prop-types';

export const Upload = ({ style }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" style={style}>
    <path d="M4.47949 7.98657L5.18449 8.69157L6.47949 7.40157V13.4866H7.47949V7.40157L8.77449 8.69157L9.47949 7.98657L6.97949 5.48657L4.47949 7.98657Z" />
    <path d="M10.7294 9.9866H10.4794V8.9866H10.7294C11.3262 9.01047 11.9079 8.79631 12.3468 8.39123C12.7856 7.98615 13.0456 7.42334 13.0694 6.8266C13.0933 6.22987 12.8791 5.64809 12.4741 5.20925C12.069 4.77042 11.5062 4.51047 10.9094 4.4866H10.4794L10.4294 4.0766C10.3185 3.23483 9.90536 2.46207 9.26697 1.90229C8.62858 1.34251 7.80848 1.03388 6.95942 1.03388C6.11037 1.03388 5.29026 1.34251 4.65188 1.90229C4.01349 2.46207 3.60035 3.23483 3.48942 4.0766L3.47942 4.4866H3.04942C2.45268 4.51047 1.88987 4.77042 1.48479 5.20925C1.07971 5.64809 0.865552 6.22987 0.889422 6.8266C0.913291 7.42334 1.17324 7.98615 1.61207 8.39123C2.05091 8.79631 2.63268 9.01047 3.22942 8.9866H3.47942V9.9866H3.22942C2.42762 9.98152 1.656 9.68023 1.06291 9.14065C0.469824 8.60107 0.0971299 7.86129 0.0164867 7.06354C-0.0641565 6.26579 0.152944 5.46639 0.626048 4.81903C1.09915 4.17167 1.79486 3.72204 2.57942 3.5566C2.79528 2.54986 3.34984 1.64759 4.15058 1.00034C4.95132 0.353084 5.9498 0 6.97942 0C8.00904 0 9.00753 0.353084 9.80827 1.00034C10.609 1.64759 11.1636 2.54986 11.3794 3.5566C12.164 3.72204 12.8597 4.17167 13.3328 4.81903C13.8059 5.46639 14.023 6.26579 13.9424 7.06354C13.8617 7.86129 13.489 8.60107 12.8959 9.14065C12.3028 9.68023 11.5312 9.98152 10.7294 9.9866Z" />
  </svg>
);

Upload.propTypes = {
  style: shape({}),
};

Upload.defaultProps = {
  style: {
    fill: 'white',
  },
};
