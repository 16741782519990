import { theme } from 'utils/theme';

export const Footer = ({ children }) => (
  <div
    style={{
      left: 0,
      bottom: 0,
      zIndex: 1001,
      width: '100%',
      display: 'flex',
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.card,
    }}
  >
    <div style={{ maxWidth: 1440, width: '100%', padding: '0 48px', boxSizing: 'border-box', textAlign: 'right' }}>
      {children}
    </div>
  </div>
);
