import React from 'react';
import { shape } from 'prop-types';

export const Hamburger = ({ style }) => (
  <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect y="0.946411" width="39" height="3.62143" rx="0.5" fill="black" />
    <rect y="14.875" width="39" height="3.62143" rx="0.5" fill="black" />
    <rect y="28.8035" width="39" height="3.62143" rx="0.5" fill="black" />
  </svg>
);

Hamburger.propTypes = {
  style: shape({}),
};

Hamburger.defaultProps = {
  style: null,
};
