import gql from 'graphql-tag';

export const ADD_OFFER_DOC_MUTATION = gql`
  mutation AddOfferDocMutation($input: AddOfferDocInput!) {
    addOfferDoc(input: $input) {
      success
      errors
      offerDoc {
        id
        offerFile
        filename
        plumbidParty {
          id
          party
          isWinner
        }
        offersettings {
          propertyAddress
          offerPrice
          cashdownAmount
          isVaLoan
          escrowLength
          inspectionContingency
          loanContingency
          appraisalContingency
          offerLeaseBack
          offerDoc {
            plumbidParty {
              id
              party
              isWinner
            }
          }
        }
      }
    }
  }
`;
