import { Typography, Divider, Tabs, Tab } from '@material-ui/core';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { PBox } from 'legos';
import { theme } from 'utils/theme';
import { PlumBidItem } from './PlumBidItem';
import { useUpdateQueryString, useResponsive } from 'utils/hooks';
import { PlumBidSkeleton } from './PlumBidSkeleton';
import { useSettingsState } from 'service/store/settingsStore';
import { withStyles } from '@material-ui/core/styles';

const StyledTab = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '33.3%',
    },
  },
}))(props => <Tab {...props} />);

const tabs = [
  {
    title: 'All',
    tabIndex: 1,
    emptyListText: 'You do not have any plumBids',
  },
  {
    title: 'Active',
    tabIndex: 2,
    emptyListText: 'You do not have any active plumBids',
  },
  {
    title: 'Inactive',
    tabIndex: 3,
    emptyListText: 'You do not have any inactive plumBids',
  },
];

export const DashboardTabs = ({ plumBidsLoading, plumBids, plumBidsRefetch }) => {
  const { mobileScreen } = useResponsive();
  const refPlumBidToScroll = useRef();
  const [, dispatchSettings] = useSettingsState();
  const [parsedSearch, updateQueryString] = useUpdateQueryString();

  const [activeTab, setActiveTab] = useState(() => {
    const tab = Number(parsedSearch.t) ?? 0;
    return parsedSearch.plumbid ? 0 : tab >= 0 && tab <= 2 ? tab : 1;
  });

  const changeTab = useCallback(
    (_, tabIndex) => {
      updateQueryString('t', `${tabIndex}`);
      setActiveTab(tabIndex);
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!plumBidsLoading && plumBids?.length === 0 && activeTab === 1 && !parsedSearch.t) {
      setActiveTab(2);
      changeTab(null, 2);
    }

    if (!plumBidsLoading && plumBids?.length > 1 && parsedSearch.plumbid && refPlumBidToScroll.current) {
      updateQueryString('t', '0');
      refPlumBidToScroll.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
      updateQueryString('plumbid');
    }
  }, [
    parsedSearch.plumbid,
    plumBids?.length,
    updateQueryString,
    plumBidsLoading,
    activeTab,
    changeTab,
    parsedSearch.t,
  ]);

  useEffect(() => {
    if (parsedSearch.chat) {
      setTimeout(() => {
        dispatchSettings({
          type: 'toggleAuthDrawer',
          payload: {
            isOpenDrawer: true,
            drawerScreen: 'UserChatScreen',
          },
        });
        updateQueryString('chat');
      }, 1000);
    }
  }, [dispatchSettings, parsedSearch.chat, updateQueryString]);

  const plumBidsFiltered = useMemo(() => {
    if (plumBids?.length) {
      return plumBids.filter(item => item.userIsParticipantOfPlumBid);
    }
    return [];
  }, [plumBids]);

  return (
    <PBox px={mobileScreen ? 1 : 6}>
      <Divider />

      <PBox pb={3}>
        <Tabs value={activeTab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          {tabs.map(tab => (
            <StyledTab label={tab.title} key={tab.title} style={{ fontWeight: '600' }} />
          ))}
        </Tabs>
        <Divider />
      </PBox>

      {plumBidsLoading ? (
        <PlumBidSkeleton />
      ) : plumBidsFiltered?.length ? (
        plumBidsFiltered.map(item => (
          <PlumBidItem
            key={item.id}
            plumBid={item}
            loading={plumBidsLoading}
            refetch={plumBidsRefetch}
            innerRef={activeTab === 0 && +parsedSearch.plumbid === +item.id ? refPlumBidToScroll : null}
          />
        ))
      ) : (
        <PBox borderRadius="2px" bgcolor={theme.palette.background.aliceBlue1} p={2} my={2}>
          <Typography variant="h2">{tabs[activeTab].emptyListText}</Typography>
        </PBox>
      )}
    </PBox>
  );
};
