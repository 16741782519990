import { Box, Grid, Typography } from '@material-ui/core';

import AgentBenefitsPng from 'assets/images/AgentBenefits.jpg';

import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AgentBenefitsLA = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  return (
    <Grid
      container
      style={{
        position: 'relative',
        padding: '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        xs={12}
        md={6}
        style={
          mdScreen ? { padding: 14 } : { paddingLeft: tabletScreen ? 40 : 88 }
        }
      >
        <img
          style={{ width: '100%', borderRadius: '38px' }}
          src={AgentBenefitsPng}
          alt=""
        />
      </Grid>
      <Grid item container xs={12} md={6} justify="center" alignItems="center">
        <Box px={{ xs: 3, md: 12 }} pt={0}>
          <Typography
            variant="h1"
            style={{ fontWeight: 700, paddingBottom: 32 }}
          >
            Agent benefits
          </Typography>
          <ul style={{ margin: 0, marginLeft: 16, padding: 0 }}>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Definitive time on market
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Global and targeted exposure to reach a larger audience of
                potential buyers
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Best possible price and terms at time of sale
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Listing agents maintain their full commission
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              fontWeight: 400,
              paddingTop: '32px',
            }}
          >
            Contact us about a <b>plumBid</b> Luxury Auction for one of your
            listings.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
