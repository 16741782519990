import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';

import { useResponsive } from 'utils/hooks';
import { Button, RouterLink } from 'legos';
import backgroundHeroSection from 'assets/images/home_new.jpg';

import { PLUMBID_LUXURY_AUCTIONS, PLUMBID_MULTIPLE } from 'routes';
import { theme } from 'utils';
import { useAuthState } from 'service/store';
import { LandingVideo } from 'components/Home/HowPlumBidWorksVideo';


export const HeroSection = () => {
  const { mobileScreen, padScreen, smallScreen } = useResponsive();
  const [{ me }] = useAuthState();

  const toTabletScreen = useMediaQuery('(max-width: 1110px) ', {
    noSsr: true,
  });

  return (
    <Box pb={6} mt={me && !toTabletScreen ? '50px' : 'unset'}>
      <Box
        height="450px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage: `url(${backgroundHeroSection})`,
          backgroundSize: 'cover',
          backgroundPosition: smallScreen ? 'center' : 'left',
          backgroundPositionY: '43%',
        }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={smallScreen ? 12 : 8}
            md={7}
            justify="flex-end"
          >
            <Box
              maxWidth="675px"
              ml={2}
              mr={smallScreen ? 2 : 'unset'}
              px={mobileScreen ? 3 : 4}
              py={mobileScreen ? 1.5 : 2.5}
              bgcolor={smallScreen ? '#ffffff99' : '#ffffff'}
              borderRadius="60px"
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: mobileScreen ? 24 : padScreen ? 28 : 34,
                  fontWeight: 700,
                }}
              >
                Real estate empowered
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container style={{ paddingTop: 80, paddingBottom: 30 }}>
        <Grid item xs={12} md={6}>
          <LandingVideo />
        </Grid>
        <Grid item container xs={12} md={6}>
          <Box
            mt={{ xs: 4, md: 0 }}
            mr={{ xs: 0, lg: 5 }}
            pr={{ xs: 2, md: 8 }}
            pl={{ xs: 2, md: 1 }}
          >
            <Typography
              variant="h3"
              style={{ fontSize: 18, lineHeight: '27px', paddingBottom: 40 }}
            >
              <b>plumBid</b> provides an easy, transparent and better process to
              buy and sell real estate – which results in the best price and
              terms for you. The revolutionary and patent-pending technology
              removes the guesswork and puts seller, buyer and agent in control
              of the entire process, maximizing the outcome for everyone.
            </Typography>
            <Typography
              variant="h3"
              style={{ fontSize: 18, lineHeight: '27px', marginBottom: 32 }}
            >
              <b>plumBid Luxury Auctions</b> provides sellers and their agent -
              a full-service marketing, registration and innovative bidding
              solution for luxury properties.
            </Typography>

            <RouterLink
              to={PLUMBID_LUXURY_AUCTIONS}
              style={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
              }}
            >
              <Button
                variant="primary"
                title="plumBid Luxury Auctions"
                style={{ borderRadius: '15px', padding: '12px' }}
                titleStyle={{ fontSize: '18px', fontWeight: 700 }}
              />
            </RouterLink>

            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                marginTop: 32,
                marginBottom: 32,
              }}
            >
              <b>plumBid Multiples</b> for properties already listed by an agent
              and that have generated multiple offers from buyers. more:
            </Typography>

            <RouterLink
              to={PLUMBID_MULTIPLE}
              style={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
              }}
            >
              <Button
                variant="primary"
                title="plumBid Multiples Overview"
                style={{ borderRadius: '15px' }}
                titleStyle={{
                  fontSize: '18px',
                  fontWeight: 700,
                  padding: '12px',
                }}
              />
            </RouterLink>
            <Typography
              variant="h3"
              style={{ fontSize: 18, lineHeight: '27px', marginTop: 60 }}
            >
              For a personal conversation contact us at:{' '}
              <a
                href="tel:626.229.9700"
                style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                }}
              >
                <b>626.229.9700</b>
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
