import { getEnv } from 'utils/getEnv';

export const refreshTokenRequest = async refreshToken => {
  return (
    await fetch(`${getEnv('PLUMBID_API_ENDPOINT')}/graphql/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operationName: null,
        variables: {
          refreshToken: refreshToken,
        },
        query: `
          mutation RefreshToken($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
              token
              refreshToken
            }
          }
        `,
      }),
    })
  ).json();
};
