import gql from 'graphql-tag';

export const SEND_EMAIL_FINISH_MUTATION = gql`
mutation OffersProcessingFinished($input: OffersProcessingFinishedInput!) {
    offersProcessingFinished(input: $input) {
      success
      errors
    }
  }
`;
