import React from 'react';
import { shape } from 'prop-types';

export const More = ({ style, stroke }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 15L15 11L11 7" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 10.9999H15" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

More.propTypes = {
  style: shape({}),
};

More.defaultProps = {
  style: null,
};
