import { useMemo, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { Box, CircularProgress, Typography, IconButton } from '@material-ui/core';

import { Icons, Tooltip, WarningBlock } from 'legos';
import { formatPDFFileName } from 'utils/formatters';
import { getBuyerPartyWarningMessage } from 'utils/warningMessages';
import { hasOfferSettingsError, hasOfferSettingsWarning } from 'utils';

import { theme } from 'utils/theme';

export const PartyItem = ({
  isSelectedParty,
  onSelect,
  party,
  deleteParty,
  displayOnlyOneStep,
  isButtonDisabled,
  plumBidStatus,
  processingInfo,
  selectedParty,
  setCountUploadOffer,
}) => {
  const color = isSelectedParty ? theme.palette.primary.main : theme.palette.text.primary;
  const bgcolor = !isSelectedParty ? theme.palette.background.whiteSmoke : theme.palette.background.paper;
  const [fakeProcessing, setFakeProcessing] = useState(() => (!isNil(party?.offerdoc?.parseEndedAt) ? 0 : 1));
  const partyWarningMessage = useMemo(() => getBuyerPartyWarningMessage(party, plumBidStatus), [party, plumBidStatus]);
  const perCents = sessionStorage.getItem(party?.id);
  const markOfferWithMismatch =
    hasOfferSettingsWarning(party.offersettings) || hasOfferSettingsError(party.offersettings, party.offersettings);

  const timeToNextPerCent =
    party?.offerdoc?.progress > 65 ||
    party?.offerdoc?.progress > fakeProcessing ||
    (!isNil(party?.offerdoc?.parseEndedAt) && 99 > fakeProcessing && fakeProcessing > 0)
      ? 100
      : +(fakeProcessing * (Math.random() * 2000 + 3000)).toFixed(0);

  useEffect(() => {
    if (sessionStorage.getItem(party?.id)) {
      setFakeProcessing(+perCents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [party?.id]);

  useEffect(() => {
    if (99 > fakeProcessing && fakeProcessing > 0) {
      const timer = setTimeout(() => {
        if (
          (party?.offerdoc?.progress < 1 && fakeProcessing < 20) ||
          (!isNil(party?.offerdoc?.parseEndedAt) && 99 > fakeProcessing && fakeProcessing > 0)
        ) {
          setFakeProcessing(fakeProcessing + 1);
          sessionStorage.setItem(party?.id, fakeProcessing + 1);
        } else if (
          (party?.offerdoc?.progress && fakeProcessing < 99) ||
          (!isNil(party?.offerdoc?.parseEndedAt) && 99 > fakeProcessing && fakeProcessing > 0)
        ) {
          setFakeProcessing(fakeProcessing + 1);
          sessionStorage.setItem(party?.id, fakeProcessing + 1);
        }
      }, timeToNextPerCent);

      return () => clearTimeout(timer);
    } else {
      setFakeProcessing(0);
      sessionStorage.setItem(party?.id, 0);
    }
  }, [fakeProcessing, party?.id, party?.offerdoc?.parseEndedAt, party?.offerdoc?.progress, timeToNextPerCent]);

  useEffect(() => {
    if (!selectedParty && !isNil(party?.offerdoc?.parseEndedAt) && fakeProcessing === 0) {
      onSelect(party, fakeProcessing);
    }
  }, [onSelect, party, selectedParty, processingInfo.isRunProcessing, fakeProcessing]);

  useEffect(() => {
    if (fakeProcessing > 99 || (!isNil(party?.offerdoc?.parseEndedAt) && fakeProcessing === 0)) {
      setCountUploadOffer(prev => (prev > 0 ? prev - 1 : 0));
    }
  }, [fakeProcessing, party?.offerdoc?.parseEndedAt, setCountUploadOffer]);

  return (
    <Box
      pl={2}
      height={44}
      display="flex"
      borderRadius={4}
      bgcolor={bgcolor}
      alignItems="center"
      justifyContent="space-between"
      style={{ cursor: isButtonDisabled ? 'default' : 'pointer', minWidth: '270px' }}
      onClick={e => {
        e.stopPropagation();
        onSelect(party, fakeProcessing);
      }}
    >
      <Box>
        {(99 > fakeProcessing && fakeProcessing > 0) || !isNil(party?.offerdoc?.progress) ? (
          <CircularProgress style={{ color: theme.palette.text.rain }} size={17} />
        ) : (
          <Icons.DocumentIconFilled style={{ color }} />
        )}
      </Box>
      <Box px={1} flex={1} display="flex" style={{ textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>
        <Typography
          variant="h5"
          noWrap
          style={{
            color:
              !isNil(party?.offerdoc?.progress) || (99 > fakeProcessing && fakeProcessing > 0)
                ? theme.palette.text.rain
                : color,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {party.partyName || formatPDFFileName(party?.offerdoc?.filename)}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        {!isNil(party?.offerdoc?.progress) || (99 > fakeProcessing && fakeProcessing > 0) ? (
          <Box px={1} flex={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden', position: 'relative' }}>
            <Typography
              variant="h5"
              style={{
                color: color,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isNil(party?.offerdoc?.parseEndedAt) &&
              +fakeProcessing.toFixed(0) === 0 &&
              party?.offerdoc?.progress >= 66
                ? '99%'
                : `${+fakeProcessing.toFixed(0)}%`}
            </Typography>
          </Box>
        ) : (
          <>
            {markOfferWithMismatch && (
              <Tooltip text="Please Review the Highlighted Fields" placement="right-start">
                <Icons.WarningCircle />
              </Tooltip>
            )}
            {displayOnlyOneStep && (
              <WarningBlock variant={partyWarningMessage.variant} text={partyWarningMessage.text} />
            )}
          </>
        )}
        <IconButton
          onClick={() => deleteParty(party)}
          disabled={isButtonDisabled}
          style={{
            height: 40,
            width: 40,
          }}
        >
          <Icons.Delete style={{ fill: isButtonDisabled ? theme.palette.text.rain : theme.palette.purple.dark }} />
        </IconButton>
      </Box>
    </Box>
  );
};
