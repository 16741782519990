import gql from 'graphql-tag';
import { BidType } from '../types';

export const GET_BIDS_REST = gql`
  query Bids_REST($input: ID) {
    bids(input: $input) @rest(type: "BidType", path: "plum-bid/{args.input.id}/bids/", method: "GET") {
      bids {
        ${BidType}
      }
    }
  }
`;
