export const palette = {
  avatarBackground: '#D2D2D2',
  myBidHistoryBackground: 'rgba(115, 30, 107, 0.06)',
  buttonBackground: 'rgba(0, 0, 0, 0.6)',
  disabled: '#C6C6C6',
  cardSliderTrack: '#BD45B2',
  purple: {
    dark: '#6B1B63',
    light: '#731E6B',
    ripple: '#731E6B6F',
  },

  text: {
    primary: '#000000',
    secondary: '#616161',
    lastBid: '#546E7A',
    link: '#731E6B',
    error: '#DA1E28',
    grey: '#7D7D7D',
    grey1: '#666666',
    dark1: '#262626',
    dark2: '#161616',
    disabled: '#979797',
    transparent: '#464545',
    warning: '#E86D29',
    rain: '#969696',
    stone: '#b1b1b1',
    whisper: '#E6E6E6',
    whisper1: '#E5E5E5',
    nobel: '#949494',
    gainsboro: '#E0E0E0',
    scarlet: '#FF1C1C',
    resolutionBlue: '#212f7e',
    fireBrick: '#c42520',
    cornflowerBlue: '#4c97ed',
    summerSky: '#4CBDED',
    deepGreen: '#20842A',
    cobalt: '#0043CE',
    suvaGrey: '#8D8D8D',
    aliceBlue: '#f2fbff',
  },
  common: {
    white: '#FFFFFF',
  },
  background: {
    tabIndicator: '#000000',
    paper: '#FFFFFF',
    card: '#F6F5F3',
    lastBid: '#EF8733',
    highBid: '#2CA084',
    cardUserReady: '#EBFAF3',
    cardUserPartiallyReady: '#FFEEDF',
    wrapper: '#FFFDEB',
    table: '#F8F8F8',
    footer: '#292D33',
    footerBottom: '#44474D',
    benefits: '#EDEDED',
    chatMessageHeader: '#FAFAFA',
    sundown: '#ffb3b3',
    tutu: '#FFE3E3',
    roseWhite: '#FFF3EE',
    suvaGrey: '#8C8C8C',
    pattensBlue: '#DADDDF',
    whiteSmoke: '#F4F4F4',
    whiteSmoke1: '#F2F2F2',
    whiteSmoke2: '#F3F3F3',
    selago: '#f7f2f6',
    aliceBlue: '#EBF1F5',
    aliceBlue1: '#F4F7FB',
    solitude: '#E2EAF4',
    charcoal: '#434343',
    vulcan: '#36383F',
    coralRed: '#F44242',
    darkGrayishNavy: '#212328',
    lightGray: '#C4C4C4',
    fireEngineRed: '#BA1B23',
    burgundy: '#750E13',
    eclipse: '#393939',
    eclipse1: '#3C3C3C',
    dimGray: '#6F6F6F',
    maroon: '#740000',
    paleOrange: '#FFDFC4',
  },
  border: {
    wrapper: '#FFE790',
    gainsboro: '#D8D8D8',
    dodgerBlue: '#2596FF',
  },
  primary: {
    main: '#731E6B',
  },
  secondary: {
    main: '#4C4C4C',
  },
  divider: '#EAE8E4',
  error: {
    main: '#DA1E28',
    secondary: '#DA4E48',
  },
  warning: {
    main: '#ECA927',
  },
  transparentWhite: {
    white99: '#ffffff99',
    white87: 'rgba(255,255,255,0.87)',
    white67: 'rgba(255,255,255,0.67)',
  },
  transparentBlack: {
    black8: 'rgba(0,0,0,0.8)',
    black69: 'rgba(0,0,0,0.69)',
    black67: 'rgba(0,0,0,0.67)',
    black47: 'rgba(0,0,0,0.47)',
  },
};
