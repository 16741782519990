import gql from 'graphql-tag';

export const EDIT_OFFER_SETTINGS = gql`
  mutation EditOfferSettings($input: EditOfferSettingsInput!) {
    editOfferSettings(input: $input) {
      success
      errors
    }
  }
`;
