import gql from 'graphql-tag';

export const GET_USERS_QUERY = gql`
  query GetUsers($after: String, $first: Int, $searchName: String, $role: String) {
    users(after: $after, first: $first, searchName: $searchName, role: $role) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          userId
          id
          fullName
          phone
          email
          dreId
          roles
          listingAgentStatus
          isActive
        }
      }
    }
  }
`;
