import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogContent, Dialog, Typography, Grid, CircularProgress } from '@material-ui/core';

import { ROLES } from 'constant';
import { theme } from 'utils/theme';
import { formatAddressTwoLines } from 'utils';
import { BID_HISTORY, PLUMBID } from 'routes';
import { useAuthState } from 'service/store/authStore';
import { Button, Icons, NavigationButton } from 'legos';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { PropertyThumbnail } from 'components/PropertyThumbnail';
import { useResponsive, useUpdateQueryString, useNotification } from 'utils/hooks';
import { GET_PLUMBID_BY_ID_OPTIMIZED_QUERY, UPDATE_PARTICIPANT_MUTATION } from 'service/graphql';
import state from 'service/graphql/state';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const GPNewBidReceivedDialog = ({ isOpen, onClose, plumBidId, isMyPartyBidWasHighest }) => {
  const classes = useStyles();
  const [parsedSearch, , pathname] = useUpdateQueryString();
  const { mobileScreen } = useResponsive();
  const [isOpenLocal, setIsOpenLocal] = useState(false);

  const [{ me }] = useAuthState();
  const isAdmin = me?.roles?.includes(ROLES.ADMIN);
  const history = useHistory();
  const showNotification = useNotification();

  const [updateParticipantMutation] = useMutation(UPDATE_PARTICIPANT_MUTATION);

  const [fetchPlumBid, { data }] = useLazyQuery(GET_PLUMBID_BY_ID_OPTIMIZED_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      id: plumBidId,
    },
  });

  useEffect(() => {
    if (!isOpen) {
      setIsOpenLocal(false);
    } else if (plumBidId) {
      fetchPlumBid({
        variables: {
          id: plumBidId,
        },
      });
    }
  }, [fetchPlumBid, isOpen, plumBidId]);

  const plumBid = useMemo(() => {
    if (data) {
      if (data.plumbidById) {
        const plumBidNormalized = normalizePlumBid(data?.plumbidById, false, me);
        setIsOpenLocal(true);
        return plumBidNormalized;
      } else {
        setIsOpenLocal(false);
      }
    }
  }, [data, me]);

  const pathToRedirect = useMemo(() => {
    if (plumBid) {
      if (plumBid.myInfoInPlumBid.role === ROLES.SELLER || plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT) {
        if (!pathname.includes(BID_HISTORY) || plumBid.id !== parsedSearch.plumbid) {
          return BID_HISTORY;
        }
      } else if (isAdmin) {
        if (!pathname.includes(BID_HISTORY) || plumBid.id !== parsedSearch.plumbid) {
          return `/pbadmin${BID_HISTORY}`;
        }
      } else {
        if (!pathname.includes(PLUMBID) || plumBid.id !== parsedSearch.plumbid) {
          return PLUMBID;
        }
      }
      return '';
    }
  }, [isAdmin, parsedSearch.plumbid, pathname, plumBid]);

  const closeHandle = useCallback(async () => {
    if (!isAdmin) {
      try {
        const { data } = await updateParticipantMutation({
          variables: {
            input: {
              participantId: plumBid?.myInfoInPlumBid?.participantId,
              newBidReceivedPopup: false,
            },
          },
        });
        if (data.updateParticipant.success) {
          const globalPlumBid = state.globalPlumBidPopupVar()?.[plumBidId];
          state.globalPlumBidPopupVar({
            ...state.globalPlumBidPopupVar(),
            [plumBidId]: {
              ...globalPlumBid,
              popupNewBidReceivedShowed: false,
            },
          });
          onClose();
        }
      } catch (error) {
        showNotification({
          error,
        });
      }
    } else {
      onClose();
    }
  }, [
    isAdmin,
    updateParticipantMutation,
    plumBid?.myInfoInPlumBid?.participantId,
    plumBidId,
    onClose,
    showNotification,
  ]);

  const closeAndRedirect = useCallback(() => {
    if (plumBid) {
      if (pathToRedirect) {
        history.push({
          pathname: pathToRedirect,
          search: `?plumbid=${plumBid.id}`,
        });
      }
      closeHandle();
    }
  }, [plumBid, pathToRedirect, closeHandle, history]);

  const buttonTitle = pathToRedirect
    ? plumBid?.myInfoInPlumBid.role === ROLES.BUYER
      ? 'Start Bidding'
      : 'See Bid History'
    : 'OK';

  const renderAddress = () => {
    const address = formatAddressTwoLines(plumBid?.mls || {});

    return (
      <Box display="flex" alignItems="center" my={1}>
        <Icons.MapMarker />
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line1}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line2}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      onClose={closeHandle}
      open={isOpenLocal}
      className={classes.root}
      fullScreen={mobileScreen}
    >
      <Box position="absolute" top={0} right={0}>
        <NavigationButton icon="Close" size={35} onClick={closeHandle} />
      </Box>
      <DialogContent>
        <Box py={2} minHeight={450} display="flex" alignItems="center" justifyContent="center">
          {plumBid ? (
            <Grid container>
              <Grid item container xs={12} md={5} alignItems="center" direction="row">
                <Box p="30px" style={{ width: '100%', backgroundColor: theme.palette.background.solitude }}>
                  <PropertyThumbnail
                    aspectRatio={1 / 1}
                    src={plumBid.mls?.previewImage || plumBid.mls?.mlsinfophotoSet?.[0] || '/no_photo.png'}
                  />
                  <Box pt={2}>
                    <Box
                      px={2}
                      py={1}
                      display="flex"
                      borderRadius="3px"
                      width="fit-content"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor={theme.palette.background.highBid}
                    >
                      <Typography style={{ color: theme.palette.common.white }} variant="caption">
                        Active
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                      plumBid will finish:
                    </Typography>
                    <Typography align="left" style={{ fontSize: 18, fontWeight: 700 }}>
                      {moment(plumBid.finishDateTime).format('ddd, MMM DD [at] h:mm a ')}
                    </Typography>
                    {renderAddress()}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={7}>
                <Box display="flex" justifyContent="center" flexDirection="column" height="100%" pl={2} pt={2}>
                  <Box pb={2}>
                    <Typography align="left" style={{ fontSize: 24, fontWeight: 600 }}>
                      {isMyPartyBidWasHighest ? 'You’ve been outbid by another bidder' : 'A new bid has been received'}
                    </Typography>
                  </Box>
                  <Box maxWidth={250}>
                    <Button
                      fullWidth
                      title={buttonTitle}
                      onClick={closeAndRedirect}
                      icon={buttonTitle === 'OK' ? '' : 'ArrowGo'}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
