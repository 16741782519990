import { useEffect } from 'react';

import {
  AddedProtection,
  Footer,
  LearnMoreLA,
  PageContainer,
  PhotoAndShowMore,
} from 'components';
import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';
import bgLuxuryAuctions1 from 'assets/images/luxury_header.jpg';
import { theme } from 'utils';
import GroupLA144 from 'assets/images/Group1441.png';
import Group145 from 'assets/images/Group145.png';
import Group148 from 'assets/images/Group1481.png';
import Group152 from 'assets/images/Group1521.png';
import Group150 from 'assets/images/Group1501.png';
import GroupPBm_2 from 'assets/images/Group1561.png';
import LatrobeStreet from 'assets/images/LatrobeStreet.jpg';
import OrangeGrove from 'assets/images/OrangeGrove.jpeg';
import MountVernon from 'assets/images/MountVernon.jpeg';
import { RouterLink } from 'legos';
import { USER_SANDBOX } from 'routes';

export const PlumBidLuxuryAuctionsPage = () => {
  const { mobileScreen, padScreen, smallScreen, mdScreen } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <Box display="flex" flexDirection="column">
        <Box
          height="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundImage: `url(${bgLuxuryAuctions1})`,
            backgroundSize: 'cover',
            backgroundPosition: smallScreen ? 'center' : 'left',
            backgroundPositionY: '63%',
          }}
        >
          <Grid container>
            <Grid
              container
              item
              xs={smallScreen ? 12 : 8}
              md={6}
              justify="flex-end"
            >
              <Box
                maxWidth="675px"
                mr={smallScreen ? 2 : 'unset'}
                px={mobileScreen ? 3 : 4}
                py={mobileScreen ? 1.5 : 3}
                bgcolor={smallScreen ? '#ffffff99' : '#ffffff'}
                borderRadius="60px"
              >
                <Typography
                  variant="h1"
                  style={{
                    fontSize: mobileScreen ? 24 : padScreen ? 28 : 34,
                    fontWeight: 700,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    color="primary"
                    style={{
                      fontSize: mobileScreen ? 24 : padScreen ? 28 : 34,
                      fontWeight: 700,
                      paddingRight: 8,
                    }}
                  >
                    plumBid
                  </Typography>
                  Luxury Auctions
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <PhotoAndShowMore
          title="Combining your listing agent and brokerage with a revolutionary auction solution"
          photo={GroupLA144}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                The <b>plumBid</b> online platform helps you intelligently
                market and sell your home within an accelerated and defined time
                period. Simply, plumBid negotiates terms that match your needs
                and garners the best possible sales price — often considerably
                better than through traditional methods.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                plumBid Luxury Auctions, in conjunction with the seller’s
                listing agent and brokerage — provides exceptional marketing and
                access to a global buyer audience. From commencement to
                successful conclusion - including property presentation, buyer
                registration, negotiation, escrow and title facilitation.
              </Typography>
            </>
          }
        />

        <PhotoAndShowMore
          title="plumBid, taking auctions to the next level"
          photo={Group145}
          bgColor={theme.palette.background.card}
          isOrderReverse={!mdScreen}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                  fontWeight: 400,
                }}
              >
                In consultation with the listing agent, the seller determines
                what aspects are most important.  Based-on on these parameters
                and preferences, <b>plumBid</b> prioritizes numerous key
                factors:
              </Typography>
              <ul style={{ margin: 0 }}>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '29px',
                      padding: '4px 0',
                    }}
                  >
                    Ultimate sales price
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '29px',
                      padding: '4px 0',
                    }}
                  >
                    Loan amount and down payment
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '29px',
                      padding: '4px 0',
                    }}
                  >
                    Length of escrow
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '29px',
                      padding: '4px 0',
                    }}
                  >
                    Inspection contingency options
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '29px',
                    }}
                  >
                    Appraisal contingency and additional options
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                plumBid then dynamically combines this information with current
                market data to generate incentive amounts for each item.
              </Typography>
            </>
          }
        />

        <PhotoAndShowMore
          title="Global exposure, reaching ideal buyers"
          photo={Group148}
          isBorder
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px ',
                  fontWeight: 400,
                }}
              >
                <b>plumBid</b> utilizes a robust and targeted marketing strategy
                for every property to reach millions of potential buyers,
                including:
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  fontWeight: 400,
                }}
              >
                Online, search, email, print, social media, public relations,
                events, exclusive showcasing and dedicated partnerships.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                For a complete perspective, please connect with one of our
                marketing specialists.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  paddingBottom: '26px',
                  fontWeight: 400,
                }}
              >
                Along with your agent and brokerages advertising - this creates
                a more comprehensive communications platform to build awareness,
                excitement and interest for each property.
              </Typography>
              <RouterLink
                to={USER_SANDBOX}
                className="removeDecorators"
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                Experience plumBid
              </RouterLink>
            </>
          }
        />

        <PhotoAndShowMore
          title={
            <>
              Preparing your property for{' '}
              <Typography
                component="span"
                variant="h1"
                color="primary"
                style={{ fontWeight: 700, paddingRight: 8 }}
              >
                plumBid
              </Typography>
            </>
          }
          photo={Group150}
          isOrderReverse={!mdScreen}
          bgColor={theme.palette.background.card}
          text={
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                paddingTop: '26px',
                fontWeight: 400,
              }}
            >
              <b>plumBid</b> will coordinate the scheduling of applicable
              third-party, independent inspections by experienced property
              inspectors. All disclosures, photographs, property information —
              as well as sample purchase contracts and all seller desired terms
              are posted online for every buyer to review. Should property
              staging be necessary, plumBid will help facilitate the process of
              securing the appropriate resource.
            </Typography>
          }
          textCollapse={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                In the days leading up to the launch of the plumBid, your
                listing agent will provide the comprehensive marketing program
                for your property — including a targeted campaign that leverages
                web, social media, email, and print advertising to generate
                awareness and excitement, while reaching the audience that’s
                right for your property.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                Your listing agent will conduct showings and open houses,
                building enthusiasm and securing buyers who are interested in
                registering.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                Included in the buyer registration process is a review and
                acknowledgement of all documentation and disclosures, as well as
                a financial prequalification to demonstrate buyer purchase
                capability.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                To ensure maximum initial buyer interest, plumBid determines the
                “Starting plumBid Price.” plumBid, the listing agent and the
                seller will collectively determine a “minimum unpublished
                reserve price” that must be reached in order for the property to
                sell.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                The marketing period depends on current market conditions, but
                usually lasts for 14-21 days.
              </Typography>
            </>
          }
        />

        <PhotoAndShowMore
          title="On plumBid day"
          photo={Group152}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                For 90-minutes, registered buyers adjust and optimize their bids
                online to create the strongest individual offer, based on the
                seller’s priorities. The prospective buyer who has submitted the
                highest effective price at the end will secure the property. If
                an offer is placed within the last five minutes of the{' '}
                <b>plumBid</b>, the clock will reset to five minutes -
                maximizing the ultimate sales price and providing all
                participants another opportunity to bid and win.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                Once a winner is determined, all other registered bidders are
                invited to submit a back-up offer. Your listing agent will
                coordinate the opening of escrow and shepherd the necessary
                documentation and financing requirements to complete the
                transaction.
              </Typography>
            </>
          }
        />

        <Box
          minHeight="200px"
          py={8}
          style={{ background: theme.palette.background.card }}
        >
          <Typography variant="h1" align="center">
            Success Snapshots
          </Typography>
          <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
            <Box
              maxWidth="333px"
              bgcolor={theme.palette.primary.main}
              p={2}
              borderRadius="30px"
              mt={4}
              mx={1}
            >
              <Box
                width="333px"
                height="333px"
                style={{
                  backgroundImage: `url(${OrangeGrove})`,
                  backgroundSize: 'cover',
                }}
              >
                <Box display="flex">
                  <Box
                    bgcolor={theme.palette.primary.main}
                    width="auto"
                    m={2}
                    borderRadius="30px"
                    py={1}
                    px={2}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        color: theme.palette.common.white,
                        fontSize: '16px',
                      }}
                    >
                      409 W Orange Grove, Sierra Madre
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <ul style={{ color: 'white' }}>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      color: theme.palette.common.white,
                    }}
                  >
                    List Price: $1,895,000
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    Sales Price: $2,188,000
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    No Contingencies
                  </Typography>
                </li>
              </ul>
            </Box>

            <Box
              maxWidth="333px"
              bgcolor={theme.palette.primary.main}
              p={2}
              borderRadius="30px"
              mt={4}
              mx={1}
            >
              <Box
                width="333px"
                height="333px"
                style={{
                  backgroundImage: `url(${MountVernon})`,
                  backgroundSize: 'cover',
                }}
              >
                <Box display="flex">
                  <Box
                    bgcolor={theme.palette.primary.main}
                    width="auto"
                    m={2}
                    borderRadius="30px"
                    py={1}
                    px={2}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        color: theme.palette.common.white,
                        fontSize: '16px',
                      }}
                    >
                      4476 Mount Vernon, View Park
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <ul style={{ color: 'white' }}>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      color: theme.palette.common.white,
                    }}
                  >
                    List Price: $1,099,000
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    Sales Price: $1,758,700
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    All Cash, 23 Bids in Under Two Hours
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box
              maxWidth="333px"
              bgcolor={theme.palette.primary.main}
              p={2}
              borderRadius="30px"
              mt={4}
              mx={1}
            >
              <Box
                width="333px"
                height="333px"
                style={{
                  backgroundImage: `url(${LatrobeStreet})`,
                  backgroundSize: 'cover',
                }}
              >
                <Box display="flex">
                  <Box
                    bgcolor={theme.palette.primary.main}
                    width="auto"
                    m={2}
                    borderRadius="30px"
                    py={1}
                    px={2}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        color: theme.palette.common.white,
                        fontSize: '16px',
                      }}
                    >
                      3841 Latrobe Street, Los Angeles
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <ul style={{ color: 'white' }}>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      color: theme.palette.common.white,
                    }}
                  >
                    List Price: $895,000
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    Sales Price: $1,363,500
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: 18,
                      lineHeight: '27px',
                      paddingTop: '10px',
                      color: theme.palette.common.white,
                    }}
                  >
                    28 Bids In Under Two Hours
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>

        <PhotoAndShowMore
          title="Pricing"
          photo={GroupPBm_2}
          isOrderReverse={!mdScreen}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                <b>plumBid</b> charges a buyers premium of 1.95%. This is
                significantly lower than other options, with some auction
                platforms charging a buyers premium of up to 18% — above the
                final sales price of the property.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                plumBid charges sellers (do we want charge anything for
                marketing)
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  paddingTop: '26px',
                  fontWeight: 400,
                }}
              >
                The listing agent/brokerage commission is not impacted and is
                agreed upon separately.
              </Typography>
            </>
          }
        />
        <LearnMoreLA bgColor={theme.palette.background.card} />
        <AddedProtection />
        <Footer />
      </Box>
    </PageContainer>
  );
};
