import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { formatUserFullName } from 'utils/formatters';

import { TextWithLabel } from 'legos';
import { theme } from 'utils/theme';

export const UserInformation = ({ userDetails }) => {
  return (
    <>
      <Box px={5} py={3} bgcolor={theme.palette.background.card}>
        <Grid container spacing={8}>
          <Grid item>
            <TextWithLabel text={formatUserFullName(userDetails)} label="Full name" />
          </Grid>
          <Grid item>
            <TextWithLabel text={userDetails.phone} label="Phone" />
          </Grid>
          <Grid item>
            <TextWithLabel text={userDetails.email} label="Email" />
          </Grid>
          <Grid item>
            <TextWithLabel text={userDetails.dreId} label="DRE ID" />
          </Grid>
        </Grid>
      </Box>
      <Box pt={6}>
        <Typography
          variant="h4"
          style={{
            textTransform: 'uppercase',
            marginBottom: 24,
          }}
        >
          {userDetails.fullName + '`s'} plumBids
        </Typography>
      </Box>
    </>
  );
};
