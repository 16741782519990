import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog } from '@material-ui/core';
import ReactPlayer from 'react-player';

import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const VideoDialog = ({ isOpen, onClose, title, video }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} maxWidth="lg" fullWidth scroll="body">
      <DialogHeader title={title} onClose={onClose} />
      <Box style={{ overflowY: 'hidden' }}>
        <ReactPlayer url={video} playing controls width="100%" style={{ minHeight: '57.5vh', maxHeight: '100%' }} />
      </Box>
    </Dialog>
  );
};
