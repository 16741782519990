import React from 'react';

export const Phone = ({ style }) => (
  <svg width="73" height="73" viewBox="0 0 73 73" fill="none" style={style}>
    <g filter="url(#filter0_d)">
      <circle cx="36.5" cy="36.5" r="15.5" fill="#ECA927" />
    </g>
    <path
      d="M33.1383 36.0109C34.1303 37.9604 35.7285 39.5587 37.6781 40.5507L39.1936 39.0351C39.3865 38.8422 39.6552 38.7871 39.8963 38.8629C40.6679 39.1178 41.4945 39.2556 42.3556 39.2556C42.5383 39.2556 42.7136 39.3281 42.8428 39.4573C42.972 39.5865 43.0445 39.7617 43.0445 39.9444V42.3555C43.0445 42.5383 42.972 42.7135 42.8428 42.8427C42.7136 42.9719 42.5383 43.0444 42.3556 43.0444C39.2497 43.0444 36.2709 41.8106 34.0746 39.6143C31.8784 37.4181 30.6445 34.4393 30.6445 31.3333C30.6445 31.1506 30.7171 30.9754 30.8463 30.8462C30.9755 30.717 31.1507 30.6444 31.3334 30.6444H33.7445C33.9272 30.6444 34.1025 30.717 34.2316 30.8462C34.3608 30.9754 34.4334 31.1506 34.4334 31.3333C34.4334 32.1944 34.5712 33.0211 34.8261 33.7927C34.9019 34.0338 34.8468 34.3024 34.6539 34.4953L33.1383 36.0109Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="73"
        height="73"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="10.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.819167 0 0 0 0 0.354167 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
