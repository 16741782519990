import { useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';

import { Divider } from 'legos';
import { PropertyAttributes, CarouselContainer } from 'components';
import { formatAddress } from 'utils';
import Image from 'material-ui-image';

export const PropertyDescriptionWithImage = ({ property, style }) => {
  const src =
    property?.mlsinfophotoSet?.length > 0
      ? property.mlsinfophotoSet[0].picture
      : property.previewImage ?? '/no_photo.png';

  const sortImages = useMemo(() => {
    if (property?.mlsinfophotoSet?.length > 0) {
      const previewIndex = property.mlsinfophotoSet.findIndex(photo => photo.picture === property.previewImage);
      if (previewIndex >= 0) {
        const previewPhoto = [...property.mlsinfophotoSet].splice(previewIndex, 1);
        return [...previewPhoto, ...property.mlsinfophotoSet];
      }
      return [{ picture: property.previewImage }, ...property.mlsinfophotoSet];
    }
    return [];
  }, [property]);

  return (
    <Box alignItems="stretch" pt={{ xs: 0, md: 2, lg: 5 }} px={{ xs: 0, md: 2, lg: 5 }} style={style}>
      <Typography variant="h1" align="center" style={{ textTransform: 'uppercase' }} paragraph>
        {formatAddress(property || {})}
      </Typography>
      <Divider />
      <Box px={{ xs: 1, sm: 3, md: 6, lg: 12 }} py={2}>
        <PropertyAttributes property={property} item align="center" />
      </Box>
      <Divider />
      <Box px={{ xs: 1, sm: 3, md: 6, lg: 12 }} py={2}>
        <Box>
          {sortImages.length > 1 ? (
            <CarouselContainer images={sortImages} />
          ) : (
            <Image
              cover
              aspectRatio={9 / 6}
              src={src}
              alt="Object preview"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />
          )}
        </Box>
      </Box>
      <Box px={{ xs: 1, sm: 3, md: 6, lg: 12 }} pb={4}>
        <Typography variant="subtitle1" paragraph align="justify">
          {property?.fullDescription || ''}
        </Typography>
      </Box>
    </Box>
  );
};
