const DOT_SIZE = 3;
const DELAY = 1800;

export const Dot3 = () => {
  return (
    <>
      <style>
        {`
          @keyframes dot-3-animation {
            from { opacity: 0.1}
            50% { opacity: 1}
              to { opacity: 0.1}
          }
        `}
      </style>
      <div
        style={{
          backgroundColor: 'white',
          height: DOT_SIZE,
          width: DOT_SIZE,
          borderRadius: '50%',
          animation: `dot-3-animation ${DELAY}ms linear infinite`,
        }}
      />
      <div
        style={{
          backgroundColor: 'white',
          height: DOT_SIZE,
          width: DOT_SIZE,
          marginLeft: 4,
          marginRight: 4,
          borderRadius: '50%',
          animation: `dot-3-animation ${DELAY}ms ${(DELAY / 3) * 0.9}ms linear infinite`,
        }}
      />
      <div
        style={{
          backgroundColor: 'white',
          height: DOT_SIZE,
          width: DOT_SIZE,
          borderRadius: '50%',
          animation: `dot-3-animation ${DELAY}ms ${(DELAY / 3) * 1.8}ms linear infinite`,
        }}
      />
    </>
  );
};
