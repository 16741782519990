import React from 'react';

export const MapMarker = ({ style, ...props }) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" style={style} {...props}>
    <path
      d="M8.64286 0.416748C10.8688 0.416748 13.0035 1.30099 14.5775 2.87496C16.1515 4.44893 17.0357 6.58368 17.0357 8.80961C17.0357 12.2842 14.3873 15.9249 9.20238 19.8136C9.04096 19.9346 8.84463 20.0001 8.64286 20.0001C8.44108 20.0001 8.24475 19.9346 8.08333 19.8136C2.89841 15.9249 0.25 12.2842 0.25 8.80961C0.25 6.58368 1.13424 4.44893 2.70821 2.87496C4.28218 1.30099 6.41693 0.416748 8.64286 0.416748ZM8.64286 6.01199C7.90088 6.01199 7.1893 6.30673 6.66464 6.83139C6.13999 7.35605 5.84524 8.06763 5.84524 8.80961C5.84524 9.55158 6.13999 10.2632 6.66464 10.7878C7.1893 11.3125 7.90088 11.6072 8.64286 11.6072C9.38483 11.6072 10.0964 11.3125 10.6211 10.7878C11.1457 10.2632 11.4405 9.55158 11.4405 8.80961C11.4405 8.06763 11.1457 7.35605 10.6211 6.83139C10.0964 6.30673 9.38483 6.01199 8.64286 6.01199Z"
      fill="black"
    />
  </svg>
);
