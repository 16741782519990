import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSettingsState } from 'service/store';

import { SELLERS, CONTACTS } from 'routes';
import QuestionsSectionJpg from '../../assets/images/Group162.png';

import { RouterLink } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const QuestionsSectionSellerLA = () => {
  const { pathname } = useLocation();
  const [{ isOpenDrawer }, dispatchSettings] = useSettingsState();
  const { mdScreen, tabletScreen } = useResponsive();

  const handleSendAgentEmail = () => {
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: !isOpenDrawer,
        drawerScreen: 'SendAgentEmailScreen',
      },
    });
  };

  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '15px 0' : '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        alignItems={pathname === SELLERS ? 'center' : 'flex-start'}
        justify="center"
      >
        <Box px={{ xs: 4, md: 10 }} pt={2}>
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            Already on the market?
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              padding: '26px 0 0 0',
            }}
          >
            If you are already actively selling your property, please let your
            agent know about the benefits and ability of <b>plumBid</b> to
            generate the best price and terms during a multiple offer situation.
            <br />
          </Typography>
          <RouterLink
            className="removeDecorators"
            onClick={handleSendAgentEmail}
            style={{
              fontSize: 18,
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
          >
            Let your agent know about plumBid.
          </RouterLink>
          <Typography
            variant="h1"
            style={{ fontSize: 18, fontWeight: 700, paddingTop: 26 }}
          >
            Thinking of selling?
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              padding: '26px 0',
            }}
          >
            plumBid has direct access to unparalleled real estate resources,
            including referral sales agents around the country that provide
            exceptional service and results. Should you desire skilled
            representation to sell your property,
            <RouterLink
              className="removeDecorators"
              to={CONTACTS}
              style={{
                fontSize: 18,
                paddingLeft: 4,
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              please contact us.
            </RouterLink>
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={
          mdScreen
            ? { padding: 24 }
            : {
                paddingLeft: tabletScreen ? 0 : mdScreen ? 0 : 88,
                paddingRight: tabletScreen ? 0 : mdScreen ? 0 : 88,
              }
        }
      >
        <img style={{ width: '100%' }} src={QuestionsSectionJpg} alt="" />
      </Grid>
    </Grid>
  );
};
