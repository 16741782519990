import { useEffect } from 'react';

import {
  Footer,
  PageContainer,
  LearnMore,
  AgentBenefits,
  DescriptionOptimizer,
  QuestionsSection,
  AboutPlumBid,
  StepsPlumBid,
} from 'components';

export const AgentsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <AgentBenefits />
      <StepsPlumBid />
      <DescriptionOptimizer />
      <QuestionsSection />
      <AboutPlumBid />
      <LearnMore />
      <Footer />
    </PageContainer>
  );
};
