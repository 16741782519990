import gql from 'graphql-tag';

export const UPDATE_PLUMBID_MUTATION = gql`
  mutation UpdatePlumbid($input: UpdatePlumBidInput!) {
    updatePlumbid(input: $input) {
      success
      errors
    }
  }
`;
