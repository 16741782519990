import { useHistory } from 'react-router';
import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';

import { Icons } from 'legos';
import { WIZARD } from 'routes';
import { ROLES } from 'constant';
import { useAuthState } from 'service/store';
import { Button, PBox } from 'legos';

export const PlumBidItemIncomplete = ({ plumBidId }) => {
  const [{ me }] = useAuthState();
  const history = useHistory();

  const goToWizard = useCallback(() => {
    history.push({
      pathname: WIZARD,
      state: {
        plumBidId: plumBidId,
        step: 1,
      },
    });
  }, [history, plumBidId]);

  return (
    <PBox textAlign="center">
      <Icons.SandClock />
      <PBox py={2} display="flex" justifyContent="center">
        <Typography variant="caption">Your plumBid is being set up</Typography>
      </PBox>
      {me.roles.includes(ROLES.ADMIN) && (
        <Button
          fullWidth
          variant="secondary"
          title="Help Listing Agent to set up plumBid"
          align="center"
          onClick={goToWizard}
        />
      )}
      {me.roles.includes(ROLES.LISTING_AGENT) && (
        <Button fullWidth variant="secondary" title="Continue adding plumBid" align="center" onClick={goToWizard} />
      )}
    </PBox>
  );
};
