import React from 'react';
import { number } from 'prop-types';

export const Logo = ({ size }) => (
  <img height={size} alt="Logo" src="/Logo.png" />
);

Logo.propTypes = {
  size: number,
};

Logo.defaultProps = {
  size: 40,
};
