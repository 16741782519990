import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';

import background from 'assets/images/bgContacts.png';
import { theme } from 'utils/theme';

export const Contacts = () => {
  const responsive = useResponsive();

  return (
    <Box>
      <Box
        height="569px"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          backgroundPositionY: responsive.padScreen ? '10%' : '30%',
        }}
      >
        <Box
          mt={10}
          px={6}
          py={3.5}
          bgcolor={theme.palette.transparentWhite.white99}
          style={{ position: 'absolute', borderRadius: '0 60px 60px 0' }}
        >
          <Typography variant="h1" style={{ fontSize: responsive.padScreen ? 40 : 54, fontWeight: 700 }}>
            We’re here for you.
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item container xs={12} alignItems="center" justify="flex-start">
          <Box pl={8} py={8}>
            <Typography variant="h2" style={{ fontWeight: 500, fontSize: 34 }}>
              Reach out to us.
            </Typography>
            <Box py={4}>
              <a href="mailto:info@plumBid.com" style={{ textDecoration: 'none' }}>
                <Typography
                  variant="h5"
                  style={{ fontSize: 26, lineHeight: '27px', paddingBottom: 8, color: theme.palette.text.transparent }}
                >
                  info@plumBid.com
                </Typography>
              </a>
              <a href="tel:626.229.9700" style={{ textDecoration: 'none' }}>
                <Typography
                  variant="h3"
                  style={{ fontSize: 26, lineHeight: '27px', color: theme.palette.text.transparent }}
                >
                  626.229.9700
                </Typography>
              </a>
            </Box>
            <Box>
              <Typography
                variant="h3"
                style={{ fontSize: 26, lineHeight: '27px', paddingBottom: 8, color: theme.palette.text.transparent }}
              >
                For press inquiries:
              </Typography>
              <a href="mailto:press@plumBid.com" style={{ textDecoration: 'none' }}>
                <Typography
                  variant="h5"
                  style={{ fontSize: 26, lineHeight: '27px', color: theme.palette.text.transparent }}
                >
                  press@plumBid.com
                </Typography>
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
