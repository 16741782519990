import React from 'react';
import { PageContainer, ChatsContainer } from 'components';

export const AdminChatsPage = () => {
  return (
    <PageContainer>
      <ChatsContainer />
    </PageContainer>
  );
};
