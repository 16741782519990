import React from 'react';
import { Link } from 'react-router-dom';
import { string, bool, shape, func } from 'prop-types';

export const RouterLink = ({
  to,
  replace,
  state,
  title,
  onClick,
  component,
  children,
  ...props
}) => {
  return (
    <Link
      replace={replace}
      to={{ ...(to ? { pathname: to } : null), state }}
      onClick={onClick}
      component={component}
      {...props}
    >
      {children || title}
    </Link>
  );
};

RouterLink.propTypes = {
  to: string.isRequired,
  replace: bool,
  title: string,
  state: shape({}),
  onClick: func,
};

RouterLink.defaultProps = {
  title: '',
  replace: false,
  state: null,
  onClick: null,
};
