import { Avatar, Typography } from '@material-ui/core';
import { getInitials } from 'utils';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AvatarUserInitials = ({ fullName }) => {
  const responsive = useResponsive();

  return (
    <Avatar
      style={{
        marginLeft: responsive.smallScreen ? 8 : 24,
        height: 52,
        width: 52,
        backgroundColor: theme.palette.background.selago,
      }}
    >
      <Typography
        align="center"
        variant="overline"
        style={{
          fontSize: 18,
          fontWeight: 700,
          marginTop: 1,
          marginLeft: 2,
          textTransform: 'uppercase',
          color: theme.palette.purple.light,
        }}
      >
        {getInitials(fullName)}
      </Typography>
    </Avatar>
  );
};
