import React from 'react';

export const Download = ({ style }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="current-color" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3571 18H0.642857C0.472361 18 0.308848 18.0527 0.188289 18.1464C0.0677295 18.2402 0 18.3674 0 18.5C0 18.6326 0.0677295 18.7598 0.188289 18.8536C0.308848 18.9473 0.472361 19 0.642857 19H17.3571C17.5276 19 17.6912 18.9473 17.8117 18.8536C17.9323 18.7598 18 18.6326 18 18.5C18 18.3674 17.9323 18.2402 17.8117 18.1464C17.6912 18.0527 17.5276 18 17.3571 18Z"
      fill="current-color"
    />
    <path
      d="M3.14809 7.53532L8.40295 2.40502V15.3721C8.40295 15.5386 8.47054 15.6983 8.59087 15.8161C8.7112 15.9338 8.8744 16 9.04456 16C9.21473 16 9.37793 15.9338 9.49826 15.8161C9.61858 15.6983 9.68618 15.5386 9.68618 15.3721V2.40502L14.941 7.53532C15.0638 7.6382 15.2217 7.69195 15.3831 7.68585C15.5446 7.67974 15.6978 7.61423 15.8121 7.50239C15.9263 7.39056 15.9933 7.24065 15.9995 7.08261C16.0058 6.92457 15.9508 6.77005 15.8457 6.64992L9.04456 0L2.237 6.66248C2.16684 6.71828 2.10952 6.78795 2.06879 6.86694C2.02807 6.94593 2.00486 7.03245 2.00068 7.12083C1.99651 7.20921 2.01147 7.29746 2.04458 7.37979C2.07768 7.46211 2.12819 7.53667 2.19279 7.59856C2.2574 7.66045 2.33463 7.70828 2.41944 7.73891C2.50425 7.76955 2.59472 7.7823 2.68492 7.77633C2.77511 7.77036 2.863 7.7458 2.94282 7.70427C3.02264 7.66274 3.0926 7.60516 3.14809 7.53532Z"
      fill="current-color"
    />
  </svg>
);
