import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_ADMIN_USER_MUTATION } from 'service/graphql';
import { useNotification } from './useNotification';

export const useCreateAdminUser = () => {
  const [createAdminUser, { data, loading }] = useMutation(
    CREATE_ADMIN_USER_MUTATION,
    {
      onError: error => showNotification({ error }),
    }
  );

  const showNotification = useNotification();

  useEffect(() => {
    if (data?.createAdminUser?.errors) {
      showNotification({
        error: data?.createAdminUser?.errors,
      });
    }
  }, [data?.createAdminUser?.errors, showNotification]);

  return {
    createAdminUser,
    createAdminUserLoading: loading,
  };
};
