export const POLLING_INTERVAL = 5000;
export const MAX_PAY_AMOUNT = 999999999;
export const ESCROW_MIN = 5;
export const ESCROW_MAX = 90;

export const INSPECTION_MIN = 3;
export const INSPECTION_MAX = 30;
export const INSPECTION_MAX_DIFF = 1;

export const LOAN_MIN = 3;
export const LOAN_MAX = 30;
export const LOAN_MAX_DIFF = 3;

export const APPRAISAL_MIN = 3;
export const APPRAISAL_MAX = 30;
export const APPRAISAL_MAX_DIFF = 3;

export const MINIMUM_PERCENT_NO_VA_LOAN = 5;

/**
 * @typedef {{
 *   NOT_SELECTED: 'Not selected'
 *   SELECTED: 'Selected'
 *   MISMATCH: 'Mismatch'
 *   NEW_OFFERS: 'New offers'
 * }}
 */
export const TOP_OFFER_STATUS = {
  NOT_SELECTED: 'Not selected',
  SELECTED: 'Selected',
  MISMATCH: 'Mismatch',
  NEW_OFFERS: 'New offers',
};

/**
 * @typedef {{
 *   INCOMPLETE: 'Incomplete'
 *   UPCOMING: 'Upcoming'
 *   LIVE: 'Live'
 *   FINISHED: 'Finished'
 *   CANCELED: 'Canceled'
 * }}
 */
export const PLUMBID_STATUS = {
  INCOMPLETE: 'Incomplete',
  UPCOMING: 'Upcoming',
  LIVE: 'Live',
  FINISHED: 'Finished',
  CANCELED: 'Canceled',
};

/**
 * @typedef {{
 *   INCOMPLETE: -1
 *   UPCOMING: 0
 *   LIVE: 1
 *   FINISHED: 2
 *   ESCROW: 3
 *   CANCELED: 4
 * }}
 */
export const PLUMBID_STATUS_ID = {
  INCOMPLETE: -1,
  UPCOMING: 0,
  LIVE: 1,
  FINISHED: 2,
  ESCROW: 3,
  CANCELED: 4,
};

/**
 * @typedef {{
 *    A_0: 'Upcoming'
 *    A_1: 'Live'
 *    A_2: 'Finished'
 *    A_3: 'Escrow'
 *    A_4: 'Canceled'
 *    _1: 'Incomplete'
 * }}
 */
export const PLUMBID_STATUS_NAME = {
  A_0: 'Upcoming',
  A_1: 'Live',
  A_2: 'Finished',
  A_3: 'Escrow',
  A_4: 'Canceled',
  _1: 'Incomplete',
};

/**
 * @typedef {{
 *   SELLER: 'A_0'
 *   BUYER: 'A_1'
 * }}
 */
export const PARTY_KIND = {
  SELLER: 'A_0',
  BUYER: 'A_1',
};

export const OFFER_PRECISE_FIELDS = [
  'offerPriceIsPrecise',
  'cashdownAmountIsPrecise',
  'appraisalContingencyLengthIsPrecise',
  'appraisalContingencyIsPrecise',
  'inspectionContingencyLengthIsPrecise',
  'inspectionContingencyIsPrecise',
  'escrowLengthIsPrecise',
  'loanContingencyLengthIsPrecise',
  'loanContingencyIsPrecise',
  'offerLeaseBackIsPrecise',
  'offerLeaseBackLengthIsPrecise',
  'offerLeaseBackPricePerDayIsPrecise',
];

/**
 * @typedef {{
 *   PENDING: '_1'
 *   INVITED: 'A_0'
 *   WAITING_FOR_ONBOARDING: 'A_1'
 *   COMPLETED: 'A_2'
 * }}
 */
export const NEW_PARTICIPANT_STATUS = {
  PENDING: '_1',
  INVITED: 'A_0',
  WAITING_FOR_ONBOARDING: 'A_1',
  COMPLETED: 'A_2',
};

/**
 * @typedef {{
 *    WAITING_FOR_PARTICIPANTS: 0
 *    WAITING_FOR_ONBOARDING: 1
 *    COMPLETED: 2
 * }}
 */
export const NEW_PARTY_STATUS = {
  WAITING_FOR_PARTICIPANTS: 0,
  WAITING_FOR_ONBOARDING: 1,
  COMPLETED: 2,
};

/**
 * @typedef {{
 *    REVIEW: 'review'
 *   INCORRECT: 'incorrectOffer'
 *   PENDING: 'pending'
 *   MISMATCH: 'mismatch'
 * }}
 */
export const ONBOARDING_STATUS = {
  REVIEW: 'review',
  INCORRECT: 'incorrectOffer',
  PENDING: 'pending',
  MISMATCH: 'mismatch',
};

/**
 * @typedef {{
 *   PERCENTAGE_DOWN: 'percentageDown'
 *   AMOUNT_DOWN: 'amountDown'
 * }}
 */
export const CASH_DOWN_IMPROVE_MODE = {
  PERCENTAGE_DOWN: 'percentageDown',
  AMOUNT_DOWN: 'amountDown',
};

export const PROCESSING_STEP = [
  { title: '', delay: 0 },
  { title: 'Processing your bid', delay: 1000 },
  { title: 'Looking for competing bids', delay: 1000 },
  { title: 'Synchronizing bids history ', delay: 1000 },
];

export const PROCESSING_STEP_COUNT = PROCESSING_STEP.length;
