import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { arrayOf, string, shape } from 'prop-types';

import { Select as SelectMaterial, MenuItem, FormControl, InputLabel } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      display: 'none',
    },
    '& .MuiFormLabel-root ': {
      marginTop: '-4px',
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.primary,
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      overflow: 'hidden',
      border: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& .PrivateNotchedOutline-root-26': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
  },
  small: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      display: 'none',
    },
    '& .MuiFormLabel-root ': {
      marginTop: '-4px',
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.primary,
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      height: 29,
      overflow: 'hidden',
      border: `1px solid ${theme.palette.divider}`,
      fontSize: 16,
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& .PrivateNotchedOutline-root-26': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    width: props => (props.fullWidth ? '100%' : 'auto'),
    minWidth: 80,
    marginLeft: 0,
    outline: props =>
      `2px solid ${
        props.warning ? theme.palette.warning.main : props.error ? theme.palette.error.main : 'transparent'
      }`,
    background: theme.palette.background.table,
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const Select = ({ label, items, small, fullWidth, warning, error, ...props }) => {
  const classes = useStyles({ fullWidth, warning, error });

  return (
    <FormControl variant="outlined" className={`${small ? classes.small : classes.root} ${classes.formControl}`}>
      <InputLabel>{label}</InputLabel>
      <SelectMaterial {...props}>
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text || item.value}
          </MenuItem>
        ))}
      </SelectMaterial>
    </FormControl>
  );
};

Select.propTypes = {
  label: string,
  items: arrayOf(shape({})),
};

Select.defaultProps = {
  label: 'Select',
  items: [],
};
