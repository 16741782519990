import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { useNotification } from 'utils/hooks';
import { useAuthState } from 'service/store/authStore';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { GET_MY_PLUMBIDS } from './schema';

export const usePlumBids = filterByStatuses => {
  const showNotification = useNotification();
  const [{ me }] = useAuthState();

  const { data, loading, refetch } = useQuery(GET_MY_PLUMBIDS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { plumbidStatus: filterByStatuses },
    onError: error => showNotification({ error }),
  });

  const plumBids = useMemo(() => data?.plumbidByUser?.map(plumBid => normalizePlumBid(plumBid, false, me)) || [], [
    data?.plumbidByUser,
    me,
  ]);

  return {
    plumBids,
    plumBidsLoading: loading,
    plumBidsRefetch: refetch,
  };
};
