import gql from 'graphql-tag';

export const REMOVE_CONTINGENCY_MUTATION = gql`
  mutation RemoveContingency($input: RemoveContingencyInput!) {
    removeContingency(input: $input) {
      success
      errors
    }
  }
`;
