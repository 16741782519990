import { useCallback } from 'react';
import { get } from 'lodash';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Grid, Typography } from '@material-ui/core';

import { formatToTitleCase, theme } from 'utils';
import { Input, PBox, Select } from 'legos';
import { ROLES } from 'constant';
import { ParticipantActions } from './ParticipantActions';

const ValidationSchema = object({
  newParticipant: object({
    email: string().trim().email('Must be a valid email').nullable(),
  }),
});

export const ParticipantForm = ({
  index,
  participant,
  inputError,
  loading,
  plumBidLoading,
  setInputError,
  disableEditing,
  isButtonDisabled,
  draftParticipant,
  setIsOfferChanged,
  disableEditingField,
  saveParticipantChange,
  participantStatusProps,
  actionsButtonsPosition,
  setIdEditingParticipant,
  setActionsButtonsPosition,
  inputWarning,
  setInputWarning,
}) => {
  const handleAccept = useCallback(
    (newParticipant, submitForm, setFieldValue) => {
      if (newParticipant.id && newParticipant.fullName.trim() === '' && !actionsButtonsPosition) {
        setInputWarning({
          ...inputWarning,
          [`${newParticipant.id}fullName`]: 'Please add participant Name',
        });
        setFieldValue(`newParticipant.fullName`, '');
      } else {
        submitForm().then((res) => {
          if (res) {
            if (!participant.id) {
              draftParticipant.splice(index, 1);
            }
            setIdEditingParticipant(null);
            setActionsButtonsPosition(false);
          } else if (newParticipant.email) {
            setInputError({
              ...inputError,
              [`email`]: 'Participant already take a part in this plumBid.',
            });
            setIdEditingParticipant(participant.id);
          }
        });
      }
    },
    [
      actionsButtonsPosition,
      setInputWarning,
      inputWarning,
      participant.id,
      setIdEditingParticipant,
      setActionsButtonsPosition,
      draftParticipant,
      index,
      setInputError,
      inputError,
    ]
  );

  const handleClose = useCallback(
    (newParticipant) => {
      if (participant.id && (newParticipant.fullName || newParticipant.fullNameOld)) {
        setInputWarning({
          ...inputWarning,
          [`${participant.id}fullName`]: null,
        });
      }

      if (!participant.id) {
        draftParticipant.splice(index, 1);
      }
      setIdEditingParticipant(null);
      setActionsButtonsPosition(false);
      setInputError({});
    },
    [
      draftParticipant,
      index,
      inputWarning,
      participant.id,
      setActionsButtonsPosition,
      setIdEditingParticipant,
      setInputError,
      setInputWarning,
    ]
  );

  const handleChange = (value, field, setFieldValue) => {
    if (field === 'fullName') {
      setActionsButtonsPosition(false);
      setInputWarning({
        ...inputWarning,
        [`${participant.id}fullName`]: !value ? 'Please add participant Name.' : null,
      });
    } else {
      setActionsButtonsPosition(true);
    }
    setIsOfferChanged(true);
    setIdEditingParticipant(participant.id);
    setFieldValue(`newParticipant.${field}`, field === 'fullName' ? formatToTitleCase(value) : value);
    setInputError({});
  };

  const handleFocus = (fieldName) => {
    if (fieldName === 'fullName') {
      setActionsButtonsPosition(false);
    } else {
      setActionsButtonsPosition(true);
    }
  };

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={{
        newParticipant: { ...participant, edited: true },
      }}
      onSubmit={(values) => {
        return new Promise(async (resolve) => {
          const result = await saveParticipantChange(values.newParticipant);
          if (result !== 'Participant already take a part in this plumBid.') {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }}
    >
      {({ values, errors, touched, submitForm, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <PBox display="flex">
            <PBox width="75%">
              <Grid container>
                <Grid item xs={4} style={{ paddingRight: 8 }}>
                  <PBox py={0.55}>
                    <Typography variant="h4" style={{ color: disableEditingField ? theme.palette.disabled : 'black' }}>
                      Status
                    </Typography>
                  </PBox>
                  <PBox height="24px" width="calc(100% - 8px)" pt={1.8}>
                    <Typography
                      variant="h4"
                      style={{
                        fontSize: 12,
                        lineHeight: 1.2,
                        fontWeight: 400,
                        color: participantStatusProps.color,
                      }}
                    >
                      {values.newParticipant.id ? participantStatusProps.text : 'New'}
                    </Typography>
                  </PBox>
                </Grid>
                <Grid item xs={8} style={{ height: '90px' }}>
                  <Input
                    fullWidth
                    label="Full Name/Entity Name"
                    placeholder="Full name/Entity Name"
                    disabled={disableEditing}
                    value={values.newParticipant.fullName}
                    onChange={(event) => handleChange(event.target.value, 'fullName', setFieldValue)}
                    autoFocus={inputWarning[`${values.newParticipant.id}fullName`] ? true : false}
                    onFocus={() => handleFocus('fullName')}
                    error={get(errors, `newParticipant.fullName`)}
                    warning={
                      inputWarning[`${values.newParticipant.id}fullName`] &&
                      values.newParticipant.fullName === '' &&
                      draftParticipant.length === 0
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <PBox pt={0.25} pb={0.75}>
                    <Typography variant="h4" style={{ color: disableEditingField ? theme.palette.disabled : 'black' }}>
                      Role
                    </Typography>
                  </PBox>
                  <PBox pb={1}>
                    <Select
                      value={values.newParticipant.role}
                      fullWidth
                      style={{ height: 40, width: '100%' }}
                      items={[
                        { text: 'Buyer', value: ROLES.BUYER },
                        { text: `Buyer's Agent`, value: ROLES.BUYER_AGENT },
                      ]}
                      disabled={disableEditingField}
                      onChange={(event) => handleChange(event.target.value, 'role', setFieldValue)}
                    />
                  </PBox>
                </Grid>
                <Grid item xs={8} style={{ height: '90px' }}>
                  <Input
                    label="Email"
                    placeholder={
                      values.newParticipant.role === ROLES.BUYER ? 'Enter Buyer’s Email' : "Enter Buyer's Agent Email"
                    }
                    disabled={disableEditingField && !get(errors, `newParticipant.email`)}
                    value={values.newParticipant.email}
                    error={(touched?.newParticipant?.email && errors?.newParticipant?.email) || inputError['email']}
                    onChange={(event) => handleChange(event.target.value, 'email', setFieldValue)}
                    autoFocus={
                      values.newParticipant.fullName !== '' && values.newParticipant.email === '' ? true : false
                    }
                    onFocus={handleFocus}
                  />
                </Grid>
              </Grid>
            </PBox>

            <PBox
              display="flex"
              alignItems={actionsButtonsPosition ? 'flex-end' : 'flex-start'}
              minWidth="142px"
              pt={3.5}
              pb={2.5}
              pl={1}
            >
              <ParticipantActions
                participant={values.newParticipant}
                loading={loading || plumBidLoading}
                handleAccept={() => handleAccept(values.newParticipant, submitForm, setFieldValue)}
                disableEditingField={disableEditingField}
                handleClose={() => handleClose(values.newParticipant)}
                isButtonDisabled={isButtonDisabled}
                isAcceptDisabled={
                  (values?.newParticipant?.fullName.trim() === '' && values?.newParticipant?.email.trim() === '') ||
                  (values?.newParticipant?.fullName.trim() === '' &&
                    touched?.newParticipant?.fullName &&
                    errors?.newParticipant?.fullName) ||
                  (touched?.newParticipant?.email && errors?.newParticipant?.email) ||
                  inputError[`email`] ||
                  inputError[`fullName`]
                }
                isCloseDisabled={!values?.newParticipant?.edited && !values?.newParticipant?.id}
              />
            </PBox>
          </PBox>
        </Form>
      )}
    </Formik>
  );
};
