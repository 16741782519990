import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';

import { Icons, Checkbox } from 'legos';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    lineHeight: '21px',
  },
}))(Tooltip);

export const AutoCalculating = ({ toggleIsAutoCalculated, isAutoCalculated }) => (
  <Grid item container alignItems="center" justify="center">
    <LightTooltip
      placement="top"
      title="
                      Based on the parameters you input, Auto-calculate will compute the minimum 'you-pay' amount to
                      enable your bid to beat the current highest bid."
    >
      <span style={{ cursor: 'pointer' }}>
        <Icons.Info style={{ verticalAlign: 'middle' }} />
      </span>
    </LightTooltip>

    <Checkbox
      checked={isAutoCalculated}
      onChange={e => toggleIsAutoCalculated(e.target.checked)}
      style={{ color: 'black' }}
      labelPlacement="start"
      label={
        <Typography variant="h2" style={{ paddingRight: 8, paddingLeft: 4, fontWeight: 400 }}>
          Auto Calculate
        </Typography>
      }
    />
  </Grid>
);
