import React from 'react';
import { shape } from 'prop-types';

export const Accept = ({ style }) => (
  <svg width="9" height="7" viewBox="0 0 9 7" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M2.93902 5.21994L0.882891 3.1472L0 4.03723L2.93902 7L9 0.890028L8.11711 0L2.93902 5.21994Z" />
  </svg>
);

Accept.propTypes = {
  style: shape({}),
};

Accept.defaultProps = {
  style: null,
};
