import { Typography } from '@material-ui/core';

import { ROLES } from 'constant';
import { BuyersStatistic } from './BuyersStatistic';
import { PBox } from 'legos';

export const BuyerSectionHeader = ({ plumBid }) => {
  return (
    <PBox display="flex" justifyContent="space-between" flexDirection="column">
      <Typography variant="h3" style={{ fontWeight: 700, verticalAlign: 'top' }}>
        Buyers
      </Typography>

      {plumBid.myInfoInPlumBid.role === ROLES.LISTING_AGENT && <BuyersStatistic plumBid={plumBid} />}
    </PBox>
  );
};
