import { useMemo } from 'react';
import { shape, bool } from 'prop-types';
import { Grid, Box } from '@material-ui/core';

import {
  CarouselContainer,
  PropertyAttributes,
  PropertyDescription,
  PropertyThumbnailNameAddress,
  Tabs,
  GoogleMap,
} from 'components';
import { Divider } from 'legos';
import { useResponsive } from 'utils/hooks';

export const WizardPropertyScreen = ({ plumBid }) => {
  const responsive = useResponsive();

  const sortImages = useMemo(() => {
    if (plumBid?.mls?.mlsinfophotoSet?.length > 0) {
      const previewIndex = plumBid.mls.mlsinfophotoSet.findIndex(photo => photo.picture === plumBid.mls.previewImage);
      if (previewIndex >= 0) {
        const previewPhoto = [...plumBid.mls.mlsinfophotoSet].splice(previewIndex, 1);
        return [...previewPhoto, ...plumBid.mls.mlsinfophotoSet];
      }
      return [{ picture: plumBid.mls.previewImage }, ...plumBid.mls.mlsinfophotoSet];
    }
    return [];
  }, [plumBid?.mls]);

  const getTabs = () => [
    {
      tabTitle: 'Description',
      tabComponent: (
        <>
          <Box
            style={{
              paddingLeft: responsive.mdScreen ? 16 : responsive.padScreen ? 32 : 16,
              paddingRight: responsive.mdScreen ? 16 : responsive.padScreen ? 32 : 16,
              maxWidth: '100%',
            }}
          >
            <PropertyDescription property={plumBid.mls} />
          </Box>
          <Box
            style={{
              paddingLeft: responsive.mdScreen ? 16 : responsive.padScreen ? 0 : 16,
              paddingRight: responsive.mdScreen ? 16 : responsive.padScreen ? 0 : 16,
              maxWidth: '100%',
            }}
          >
            <GoogleMap property={plumBid.mls} />
          </Box>
        </>
      ),
    },
  ];

  const renderPropertyHeader = () => {
    return (
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs="auto">
          <PropertyThumbnailNameAddress property={plumBid.mls} />
        </Grid>
        <Grid item xs>
          {responsive.mobileScreen || (
            <PropertyAttributes property={plumBid.mls} style={{ minWidth: 450, paddingTop: 12 }} />
          )}
        </Grid>
      </Grid>
    );
  };

  return plumBid ? (
    <Box style={{ overflowX: 'hidden', position: 'relative', paddingBottom: 24 }}>
      {renderPropertyHeader()}
      <Divider style={{ marginTop: 8 }} />
      <CarouselContainer images={sortImages} />
      {responsive.mobileScreen && (
        <Box px={4} pt={4}>
          <PropertyAttributes property={plumBid.mls} item md={12} lg={7} />
        </Box>
      )}
      <Tabs
        tabStyle={
          responsive.mdScreen ? { padding: '0 16px' } : responsive.padScreen ? { paddingLeft: 32 } : { paddingLeft: 16 }
        }
        tabs={getTabs()}
        divider={<Divider />}
      />
    </Box>
  ) : null;
};

WizardPropertyScreen.propTypes = {
  plumBid: shape({}),
  isLoadingProceed: bool,
};

WizardPropertyScreen.defaultProps = {
  onChangeStartDataTime: () => {},
  isLoadingProceed: false,
};
