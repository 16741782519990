import gql from 'graphql-tag';

export const SEND_EMAIL_MUTATION = gql`
  mutation SendEmailMutation($input: SendEmailInput!) {
    sendEmail(input: $input) {
      success
      errors
    }
  }
`;
