import React from 'react';
import { Box, Typography } from '@material-ui/core/';

export const TextWithLabel = ({ label, text, text2, style }) => {
  return (
    <Box style={style}>
      <Typography variant="h4" align="left" style={style ? style : { textTransform: 'uppercase' }}>
        {label}
      </Typography>
      <Typography variant="subtitle2" align="left">
        {text}
      </Typography>
      {text2 && (
        <Typography variant="subtitle2" align="left">
          {text2}
        </Typography>
      )}
    </Box>
  );
};
