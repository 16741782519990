import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Icons } from 'legos';
import { theme } from 'utils/theme';

export const MonthlyStatistic = ({ statisticCount }) => {
  return (
    <Box p={4} bgcolor={theme.palette.background.card}>
      <Box display="flex" alignItems="center" mb={4}>
        <Icons.Wave
          style={{
            width: 24,
            height: 24,
          }}
        />
        <Typography variant="h4" style={{ marginLeft: 8, textTransform: 'uppercase' }}>
          Monthly statistic
        </Typography>
      </Box>
      <Grid container justify="space-around">
        <Grid item>
          <Typography align="center" variant="body2" style={{ fontSize: 17 }}>
            New plumBids
          </Typography>
          <Typography align="center" variant="h1" style={{ fontSize: 84 }}>
            {statisticCount.newPlumbidsCount}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="body2" style={{ fontSize: 17 }}>
            Complete plumBids
          </Typography>
          <Typography align="center" variant="h1" style={{ fontSize: 84 }}>
            {statisticCount.completePlumbidsCount}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="body2" style={{ fontSize: 17 }}>
            Users joined
          </Typography>
          <Typography align="center" variant="h1" style={{ fontSize: 84 }}>
            {statisticCount.usersJoined}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
