import { Button } from '@material-ui/core';
import { theme } from 'utils/theme';

export const TopOfferButton = ({ children, offerIndex, ...props }) => {
  return (
    <Button
      style={{
        height: 89,
        width: 126,
        border:
          offerIndex !== null
            ? `2px solid ${offerIndex === 0 ? theme.palette.background.highBid : theme.palette.text.error}`
            : `2px solid ${theme.palette.border.grainsboro}`,
        borderRadius: 3,
        padding: 20,
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
