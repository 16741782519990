import { useEffect } from 'react';

import { Footer, PageContainer, Contacts } from 'components';

export const ContactsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <Contacts />
      <Footer />
    </PageContainer>
  );
};
