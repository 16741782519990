import React from 'react';
import { bool, string, shape } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { Icons } from 'legos';

export const TextButton = ({ icon, loading, style, children, ...props }) => {
  const Icon = Icons[icon] || null;
  return (
    <Button
      disableElevation
      disableRipple
      style={{
        fontSize: 14,
        lineHeight: 1.2,
        fontWeight: 700,
        borderRadius: 0,
        height: 40,
        ...style,
      }}
      startIcon={Icon}
      {...props}
    >
      {children}
      {loading && (
        <CircularProgress
          size={30}
          style={{
            position: 'absolute',
            left: 'calc(50% - 15px)',
          }}
        />
      )}
    </Button>
  );
};

TextButton.propTypes = {
  icon: string,
  loading: bool,
  style: shape({}),
};

TextButton.defaultProps = {
  icon: null,
  loading: false,
  style: null,
};
