import React from 'react';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { string, object } from 'yup';
import { Formik, Form } from 'formik';

import { Button, Input } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

const ValidationSchema = object().shape({
  fullName: string().required('Full name is required'),
});

export const GetSandboxKeyDialog = ({ isOpen, onClose, onOk, loading }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="User info" onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={3}>
          <Typography variant="h5" style={{ lineHeight: 1.5, marginBottom: 24 }}>
            Please enter you Initials
          </Typography>

          <Formik
            initialValues={{
              fullName: '',
            }}
            validationSchema={ValidationSchema}
            onSubmit={values => {
              onOk(values);
            }}
            render={({ values, touched, errors, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box>
                  <Input
                    label="Initials"
                    name="fullName"
                    onChange={handleChange}
                    error={errors.fullName && touched.fullName ? errors.fullName : ''}
                  />
                </Box>
                <Box py={3}>
                  <Typography variant="body2">
                    You are using a test version of the plumBid optimizer - bids, properties and $ amounts are of no
                    real value
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
                  <Button title="Cancel" variant="secondary" style={{ width: '30%' }} onClick={onClose} />
                  <Box width={8} />
                  <Button type="submit" title="Bid" style={{ width: '35%' }} loading={loading} />
                </Box>
              </Form>
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
