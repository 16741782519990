import gql from 'graphql-tag';

import { SBPlumBidType } from '../types';

export const ON_NEW_SANDBOX_PLUMBID_EVENT_SUBSCRIPTION = gql`
  subscription OnNewSbPlumBidEvent($room: String) {
    onNewSbPlumBidEvent(room: $room) {
      plumbid {
        ${SBPlumBidType}
      }
    }
  }
`;
