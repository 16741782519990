import React from 'react';

export const WarningCircle = ({ style }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#ECA927"
    />
    <path d="M9.91 15.0045H9.92" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7 7.00448C7.2351 6.33614 7.69915 5.77258 8.30995 5.41361C8.92076 5.05463 9.6389 4.92341 10.3372 5.04319C11.0355 5.16296 11.6688 5.526 12.1251 6.068C12.5813 6.61001 12.8311 7.296 12.83 8.00448C12.83 10.0045 9.83 11.0045 9.83 11.0045"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
