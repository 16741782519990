import React from 'react';
import { shape } from 'prop-types';

export const CubeView = ({ style }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" style={style}>
    <path d="M8 10.125C8.62132 10.125 9.125 9.62132 9.125 9C9.125 8.37868 8.62132 7.875 8 7.875C7.37868 7.875 6.875 8.37868 6.875 9C6.875 9.62132 7.37868 10.125 8 10.125Z" />
    <path d="M12.3745 8.70666C12.0269 7.82128 11.4271 7.05736 10.6496 6.50958C9.87196 5.96179 8.95072 5.65423 8 5.625C7.04928 5.65423 6.12804 5.96179 5.35045 6.50958C4.57285 7.05736 3.97309 7.82128 3.62549 8.70666L3.5 9L3.62549 9.29334C3.97309 10.1787 4.57285 10.9426 5.35045 11.4904C6.12804 12.0382 7.04928 12.3458 8 12.375C8.95072 12.3458 9.87196 12.0382 10.6496 11.4904C11.4271 10.9426 12.0269 10.1787 12.3745 9.29334L12.5 9L12.3745 8.70666ZM8 11.25C7.55499 11.25 7.11998 11.118 6.74997 10.8708C6.37996 10.6236 6.09157 10.2722 5.92127 9.86104C5.75097 9.4499 5.70642 8.9975 5.79323 8.56105C5.88005 8.12459 6.09434 7.72368 6.40901 7.40901C6.72368 7.09434 7.12459 6.88005 7.56105 6.79323C7.9975 6.70642 8.4499 6.75097 8.86104 6.92127C9.27217 7.09157 9.62357 7.37996 9.87081 7.74997C10.118 8.11998 10.25 8.55499 10.25 9C10.2493 9.59653 10.0121 10.1684 9.59025 10.5903C9.16844 11.0121 8.59653 11.2493 8 11.25Z" />
    <path d="M15.0334 4.5766L8.28344 0.639103C8.19741 0.588915 8.0996 0.562469 8 0.562469C7.9004 0.562469 7.80259 0.588915 7.71656 0.639103L0.966556 4.5766C0.881637 4.62614 0.811185 4.69707 0.762222 4.78233C0.71326 4.86758 0.687496 4.96418 0.6875 5.06249V12.9375C0.687496 13.0358 0.71326 13.1324 0.762222 13.2177C0.811185 13.3029 0.881637 13.3738 0.966556 13.4234L7.71656 17.3609C7.80259 17.4111 7.9004 17.4375 8 17.4375C8.0996 17.4375 8.19741 17.4111 8.28344 17.3609L15.0334 13.4234C15.1184 13.3738 15.1888 13.3029 15.2378 13.2177C15.2867 13.1324 15.3125 13.0358 15.3125 12.9375V5.06249C15.3125 4.96418 15.2867 4.86758 15.2378 4.78233C15.1888 4.69707 15.1184 4.62614 15.0334 4.5766ZM14.1875 12.6145L8 16.2238L1.8125 12.6145V5.38548L8 1.7762L14.1875 5.38548V12.6145Z" />
  </svg>
);

CubeView.propTypes = {
  style: shape({}),
};

CubeView.defaultProps = {
  style: null,
};
