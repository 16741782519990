import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';

import { Input } from 'legos';

export const PercentInput = ({ value, onChange, ...props }) => {
  const handleChange = useCallback(e => onChange(e.target.value.replace(/[,\s%]+/g, '')), [onChange]);

  return (
    <NumberFormat
      thousandSeparator
      suffix="%"
      decimalScale={0}
      value={value}
      allowNegative={false}
      onChange={handleChange}
      customInput={Input}
      {...props}
    />
  );
};
