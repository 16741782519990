import React from 'react';

export const Completed = ({ style }) => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.399994C4.925 0.399994 0 5.32499 0 11.4C0 17.475 4.925 22.4 11 22.4C17.075 22.4 22 17.475 22 11.4C22 5.32499 17.075 0.399994 11 0.399994ZM15.768 9.53999C15.8558 9.43964 15.9226 9.32273 15.9646 9.19616C16.0065 9.06959 16.0227 8.93591 16.0123 8.80298C16.0018 8.67005 15.9648 8.54056 15.9036 8.42212C15.8423 8.30369 15.758 8.1987 15.6555 8.11334C15.5531 8.02797 15.4346 7.96396 15.3071 7.92506C15.1796 7.88616 15.0455 7.87316 14.9129 7.88682C14.7802 7.90049 14.6517 7.94054 14.5347 8.00463C14.4178 8.06871 14.3149 8.15554 14.232 8.25999L9.932 13.419L7.707 11.193C7.5184 11.0108 7.2658 10.91 7.0036 10.9123C6.7414 10.9146 6.49059 11.0198 6.30518 11.2052C6.11977 11.3906 6.0146 11.6414 6.01233 11.9036C6.01005 12.1658 6.11084 12.4184 6.293 12.607L9.293 15.607C9.39126 15.7052 9.50889 15.7818 9.63842 15.8321C9.76794 15.8823 9.9065 15.9049 10.0453 15.8986C10.184 15.8923 10.32 15.8572 10.4444 15.7954C10.5688 15.7337 10.6791 15.6467 10.768 15.54L15.768 9.53999Z"
      fill={style?.fill || '#2CA084'}
    />
  </svg>
);
