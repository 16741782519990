import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'utils/theme';

const colors = {
  primary: {
    enabled: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.primary.main,
      iconColor: theme.palette.common.white,
    },
    hover: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      iconColor: theme.palette.common.white,
    },
    focus: {
      backgroundColor: theme.palette.primary.main,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    active: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    disabled: {
      border: theme.palette.text.whisper1,
      backgroundColor: theme.palette.text.whisper1,
      color: theme.palette.text.suvaGrey,
      iconColor: theme.palette.text.suvaGrey,
    },
  },
  secondary: {
    enabled: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: theme.palette.primary.main,
      iconColor: theme.palette.primary.main,
    },
    hover: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      border: theme.palette.secondary.main,
      iconColor: theme.palette.common.white,
    },
    focus: {
      backgroundColor: theme.palette.background.eclipse,
      color: theme.palette.common.white,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    active: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.background.dimGray,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    disabled: {
      border: theme.palette.text.whisper1,
      backgroundColor: theme.palette.text.whisper1,
      color: theme.palette.text.suvaGrey,
      iconColor: theme.palette.text.suvaGrey,
    },
  },
  tertiary: {
    enabled: {
      color: theme.palette.purple.dark,
      backgroundColor: theme.palette.common.white,
      border: theme.palette.purple.dark,
      iconColor: theme.palette.purple.dark,
    },
    hover: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      iconColor: theme.palette.common.white,
    },
    focus: {
      color: 'white',
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    active: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    disabled: {
      border: theme.palette.disabled,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.disabled,
      iconColor: theme.palette.text.primary,
    },
  },
  bid: {
    enabled: {
      color: theme.palette.purple.dark,
      backgroundColor: theme.palette.common.white,
      border: theme.palette.purple.dark,
      iconColor: theme.palette.purple.dark,
    },
    hover: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      iconColor: theme.palette.common.white,
    },
    focus: {
      color: 'white',
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    active: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.purple.dark,
      border: theme.palette.purple.dark,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
    disabled: {
      border: theme.palette.background.highBid,
      backgroundColor: theme.palette.background.highBid,
      color: theme.palette.common.white,
      iconColor: theme.palette.common.white,
    },
  },
  ghost: {
    enabled: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: theme.palette.common.white,
      iconColor: theme.palette.primary.main,
    },
    hover: {
      border: theme.palette.text.whisper1,
      backgroundColor: theme.palette.text.whisper1,
      color: theme.palette.text.cobalt,
      iconColor: theme.palette.text.cobalt,
    },
    focus: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.primary.main,
    },
    active: {
      backgroundColor: theme.palette.disabled,
      color: theme.palette.text.cobalt,
      border: theme.palette.disabled,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.text.cobalt,
    },
    disabled: {
      border: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.disabled,
      iconColor: theme.palette.disabled,
    },
  },
  danger: {
    enabled: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      border: theme.palette.error.main,
      iconColor: theme.palette.text.primary,
    },
    hover: {
      border: theme.palette.background.fireEngineRed,
      backgroundColor: theme.palette.background.fireEngineRed,
      color: theme.palette.common.white,
      iconColor: theme.palette.text.primary,
    },
    focus: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      border: theme.palette.primary.main,
      boxShadow: theme.palette.common.white,
      iconColor: theme.palette.text.primary,
    },
    active: {
      backgroundColor: theme.palette.background.burgundy,
      color: theme.palette.common.white,
      border: theme.palette.background.burgundy,
      iconColor: theme.palette.text.primary,
    },
    disabled: {
      border: theme.palette.disabled,
      backgroundColor: theme.palette.disabled,
      color: theme.palette.text.suvaGrey,
      iconColor: theme.palette.text.primary,
    },
  },
};

export const useButtonStyle = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    transition:
      'background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9)',

    color: props => colors[props.variant].enabled.color,
    backgroundColor: props => colors[props.variant].enabled.backgroundColor,
    border: props => `2px solid ${colors[props.variant].enabled.border}`,
    fill: props => colors[props.variant].enabled.iconColor,
    '&:hover': {
      color: props => colors[props.variant].hover.color,
      backgroundColor: props => colors[props.variant].hover.backgroundColor,
      border: props => `2px solid ${colors[props.variant].hover.border}`,
      fill: props => colors[props.variant].hover.iconColor,
    },
    '&:focus': {
      color: props => colors[props.variant].focus.color,
      backgroundColor: props => colors[props.variant].focus.backgroundColor,
      border: props => `2px solid ${colors[props.variant].focus.border}`,
      boxShadow: props => `inset 0 0 0 1px ${colors[props.variant].focus.boxShadow}`,
      fill: props => colors[props.variant].focus.iconColor,
    },
    '&:active': {
      color: props => colors[props.variant].active.color,
      backgroundColor: props => colors[props.variant].active.backgroundColor,
      border: props => `2px solid ${colors[props.variant].active.border}`,
      boxShadow: props => `inset 0 0 0 1px ${colors[props.variant].active.boxShadow}`,
      fill: props => colors[props.variant].active.iconColor,
    },
    '&:disabled': {
      color: props => colors[props.variant].disabled.color,
      fill: props => colors[props.variant].disabled.iconColor,
      backgroundColor: props => colors[props.variant].disabled.backgroundColor,
      border: props => `2px solid ${colors[props.variant].disabled.border}`,
    },
  },
}));

export const useTooltipStyle = makeStyles(theme => ({
  arrow: {
    color: theme.palette.background.eclipse1,
  },
  tooltip: {
    backgroundColor: theme.palette.background.eclipse1,
    color: theme.palette.common.white,
    fontSize: '14',
    fontFamily: '"Helvetica", "Roboto", "Arial", sans-serif',
    textAlign: 'center',
  },
}));

export const useLinkButtonStyle = makeStyles(theme => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    alignSelf: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const useCallMeBackButtonStyle = makeStyles(theme => ({
  root: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    height: 47,
  },
}));
