import gql from 'graphql-tag';
import { SBPlumBidType } from '../types';

export const GET_SANDBOX_PLUMBID_BY_ID_AND_MINIMAL_BID = gql`
  query sbPlumbidById($id: Int) {
    sbPlumbidById(id: $id) {
      ${SBPlumBidType}
    }
    sbMinimalBid(plumbidId: $id)
  }
`;
