import React from 'react';

export const NewOffer = ({ style }) => (
  <svg width="35" height="20" viewBox="0 0 35 20" fill="none" style={style}>
    <rect x="1" y="1" width="33" height="18" fill="white" stroke="#2DC294" strokeWidth="2" />
    <path
      d="M8.9 10.7L8.23 9.3H8.2V14H6.77V7.02H8.43L10.34 10.32L11.01 11.72H11.04V7.02H12.47V14H10.81L8.9 10.7ZM14.1663 14V7.02H18.9163V8.37H15.6863V9.8H18.4563V11.14H15.6863V12.65H18.9163V14H14.1663ZM21.5005 14L19.9205 7.02H21.4205L22.0805 10.37L22.4505 12.31H22.4805L22.9205 10.37L23.6905 7.02H25.3505L26.1305 10.37L26.5605 12.31H26.5905L26.9605 10.37L27.6305 7.02H29.0705L27.4505 14H25.7205L24.8405 10.22L24.5005 8.69H24.4705L24.1205 10.22L23.2405 14H21.5005Z"
      fill="#2DC294"
    />
  </svg>
);
