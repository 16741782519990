import gql from 'graphql-tag';
import { ChatMessageType } from '../types';

export const ON_NEW_ADMIN_CHAT_MESSAGE_SUBSCRIPTION = gql`
  subscription OnNewAdminChatMessage($chatName: String) {
    onNewAdminChatMessage(chatName: $chatName) {
      message {
        ${ChatMessageType}
      }
      owner {
        id
        userId
        fullName
        email
      }
    }
  }
`;
