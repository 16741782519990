import { Typography, Grid } from '@material-ui/core';
import { PBox } from 'legos';
import { useResponsive } from 'utils/hooks';

const TextBlock = ({ title, text }) => (
  <PBox py={0.3} pr={1}>
    <Typography variant="h5" component="span" style={{ fontWeight: 700 }}>
      {title}
      <Typography variant="h5" component="span">
        {' '}
        - {text}
      </Typography>
    </Typography>
  </PBox>
);

export const BuyersStatistic = ({ plumBid }) => {
  const { tabletScreen } = useResponsive();

  return (
    <PBox pt={2} display="flex" flexDirection={tabletScreen ? 'column' : 'auto'}>
      <Grid container>
        <Grid item xs={6}>
          <TextBlock title="Total buyer parties" text={plumBid.buyerPartiesCount} />
          <TextBlock title="Pending buyer parties" text={plumBid.buyerPartiesCount - plumBid.buyerPartiesReady} />
        </Grid>
        <Grid item xs={6}>
          <TextBlock title="Total buyers" text={plumBid.totalBidders} />
          <TextBlock title="Pending buyers" text={plumBid.totalBidders - plumBid.readyBidders} />
        </Grid>
      </Grid>
    </PBox>
  );
};
