import React, { useMemo } from 'react';
import { isNil } from 'lodash';

import { PBox } from 'legos/PBox';
import { OfferDetails } from './OfferDetails';
import { ParticipantDetails } from './ParticipantDetails';
import { OfferSkeleton } from './OfferSkeleton';

export const PartyDetails = ({
  plumBid,
  plumBidRefetch,
  plumBidLoading,
  createInvitedMutation,
  selectedParty,
  fileUploading,
  displayOnlyOneStep,
  isButtonDisabled,
  setIsOfferChanged,
  setEditMode,
  readOnly,
  idEditingParticipant,
  setIdEditingParticipant,
}) => {
  const curParty = useMemo(() => plumBid?.buyerParty?.find(buyerParty => selectedParty === buyerParty.id), [
    plumBid?.buyerParty,
    selectedParty,
  ]);

  if (!curParty) {
    return null;
  }

  return (
    <PBox>
      {!isNil(curParty?.offerdoc?.parseEndedAt) ? (
        <>
          <OfferDetails
            plumBid={plumBid}
            party={curParty}
            plumBidRefetch={plumBidRefetch}
            fileUploading={fileUploading}
            displayOnlyOneStep={displayOnlyOneStep}
            isButtonDisabled={isButtonDisabled}
            setIsOfferChanged={setIsOfferChanged}
            setEditMode={setEditMode}
            readOnly={readOnly}
          />
          <ParticipantDetails
            plumBid={plumBid}
            plumBidRefetch={plumBidRefetch}
            plumBidLoading={plumBidLoading}
            curParty={curParty}
            selectedParty={selectedParty}
            createInvitedMutation={createInvitedMutation}
            isButtonDisabled={isButtonDisabled}
            setIsOfferChanged={setIsOfferChanged}
            idEditingParticipant={idEditingParticipant}
            setIdEditingParticipant={setIdEditingParticipant}
          />
        </>
      ) : (
        <>
          <PBox
            pt={3}
            width="100%"
            height="100%"
            mb={3}
          >{`Please wait until we process ${curParty.partyName} document`}</PBox>
          <OfferSkeleton />
        </>
      )}
    </PBox>
  );
};
