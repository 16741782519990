import React from 'react';

import { PageContainer, WizardContainer } from 'components';

export const WizardPage = () => {
  return (
    <PageContainer disableLoading>
      <WizardContainer />
    </PageContainer>
  );
};
