import gql from 'graphql-tag';

export const PARSE_OFFER_DOC_MUTATION = gql`
  mutation ParseOfferDocMutation($input: ParseOfferDocInput!) {
    parseOfferDoc(input: $input) {
      success
      errors
      offerDocSettings {
        id
        propertyAddress
        offerPrice
        cashdownAmount
        isVaLoan
        escrowLength
        inspectionContingency
        loanContingency
        appraisalContingency
        offerLeaseBack
        offerDoc {
          plumbidParty {
            id
            party
          }
        }
        offerparticipantSet {
          id
          fullName
          role
          dre
          email
        }
      }
    }
  }
`;
