import React from 'react';

export const ViewEye = ({ style }) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M1.4165 10.9998C1.4165 10.9998 5.08317 3.6665 11.4998 3.6665C17.9165 3.6665 21.5832 10.9998 21.5832 10.9998C21.5832 10.9998 17.9165 18.3332 11.4998 18.3332C5.08317 18.3332 1.4165 10.9998 1.4165 10.9998Z"
        stroke={style?.stroke ?? style?.fill ?? '#731E6B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 13.75C13.0188 13.75 14.25 12.5188 14.25 11C14.25 9.48122 13.0188 8.25 11.5 8.25C9.98122 8.25 8.75 9.48122 8.75 11C8.75 12.5188 9.98122 13.75 11.5 13.75Z"
        stroke={style?.stroke ?? style?.fill ?? '#731E6B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="22" height="22" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
