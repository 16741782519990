import gql from 'graphql-tag';

export const CREATE_ADMIN_USER_MUTATION = gql`
  mutation CreateAdminUserMutation($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      success
      errors
    }
  }
`;
