import React from 'react';

export const Link = ({ style }) => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" style={style}>
    <circle cx="17" cy="17" r="17" stroke="none" fill="black" fillOpacity="0.05" />
    <path
      d="M15.3972 17.8053C15.7431 18.2677 16.1843 18.6503 16.6911 18.9271C17.1978 19.204 17.7581 19.3686 18.334 19.4099C18.91 19.4511 19.4881 19.368 20.0291 19.1662C20.5701 18.9644 21.0613 18.6486 21.4696 18.2402L23.8856 15.8242C24.6191 15.0647 25.025 14.0476 25.0158 12.9918C25.0066 11.936 24.5831 10.926 23.8366 10.1794C23.09 9.43282 22.08 9.00933 21.0242 9.00015C19.9684 8.99098 18.9512 9.39685 18.1918 10.1304L16.8066 11.5075"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6187 16.1946C18.2729 15.7323 17.8316 15.3497 17.3249 15.0728C16.8182 14.796 16.2579 14.6314 15.6819 14.5901C15.106 14.5489 14.5279 14.632 13.9869 14.8338C13.4459 15.0356 12.9546 15.3514 12.5464 15.7598L10.1304 18.1758C9.39685 18.9353 8.99098 19.9524 9.00015 21.0082C9.00933 22.064 9.43282 23.074 10.1794 23.8206C10.926 24.5672 11.936 24.9907 12.9918 24.9998C14.0476 25.009 15.0647 24.6031 15.8242 23.8696L17.2013 22.4925"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
