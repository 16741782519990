import React, { useEffect } from 'react';
import { Box, DialogContent, Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'legos';
import { DialogHeader } from '../../Header/DialogHeader';
import { OfferDetails } from 'components/Wizard/Step5/OfferDetails';
import { usePlumBidByIdLazy } from 'utils/hooks';
import { SCHEMA } from './schema';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const ReviewOfferDialog = ({ isOpen, onClose, plumBid }) => {
  const classes = useStyles();
  const { plumBid: plumBidData, plumbidByIdFetch } = usePlumBidByIdLazy(SCHEMA);

  useEffect(() => {
    if (isOpen) {
      plumbidByIdFetch({ variables: { id: plumBid?.id } });
    }
  }, [isOpen, plumBid?.id, plumbidByIdFetch]);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="lg" scroll="body">
      <DialogHeader title="Review Offer" onClose={onClose} />
      <DialogContent>
        {plumBidData ? (
          <OfferDetails plumBid={plumBidData} party={plumBidData.myInfoInPlumBid.myParty} readOnly />
        ) : (
          <Box py={10} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        <Box display="flex" justifyContent="center" mt={1} mb={2}>
          <Button title="OK" style={{ width: 160 }} align="center" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
