import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useAuthState } from 'service/store';
import { BuyerOnboardingForm } from './BuyerOnboardingForm';

export const MyBuyerOnboarding = () => {
  const [{ me }] = useAuthState();

  const location = useLocation();
  const { plumbid } = queryString.parse(location.search);

  return <BuyerOnboardingForm buyerId={me.userId} plumBidId={plumbid} />;
};
