import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Box } from '@material-ui/core';

import { PlumBidTable } from './PlumBidTable';
import { formatDateToGraphQLDate } from 'utils/formatters';
import { GLOBAL, ROLES, PLUMBID_STATUS } from 'constant';
import { useUsers, usePlumBidAdmin, usePageLoading, useUpdateQueryString } from 'utils/hooks';
import { formatUserFullName } from 'utils';
import { Autocomplete, SearchInput } from 'legos';
const DATE_FORMAT = 'MM-DD-YYYY';

export const PlumBidTableContainer = () => {
  const [search, updateQueryString] = useUpdateQueryString();

  const [address, setAddress] = useState(search.address || '');
  const [listingAgentId, setListingAgentId] = useState(search.la);

  const [status, setStatus] = useState(() => {
    if (search.status) {
      if (Object.values(PLUMBID_STATUS).includes(search.status)) {
        return search.status;
      } else {
        updateQueryString('status');
      }
    }
    return null;
  });

  const [focusedInput, setFocusedInput] = useState(false);
  const [dateFrom, setDateFrom] = useState(() => {
    const date = moment(search.dateFrom);
    if (date.isValid() && search.dateFrom) {
      return date;
    }
    return null;
  });

  const [dateTo, setDateTo] = useState(() => {
    const date = moment(search.dateTo);
    if (date.isValid() && search.dateTo) {
      return date;
    }
    return null;
  });

  const searchAddress = useCallback(
    value => {
      setAddress(value);
      updateQueryString('address', value);
    },
    [updateQueryString]
  );

  const changeStatus = useCallback(
    (_, value) => {
      setStatus(value);
      updateQueryString('status', value);
    },
    [updateQueryString]
  );

  const changeListingAgentId = useCallback(
    (_, value) => {
      setListingAgentId(value?.value);
      updateQueryString('la', value?.value);
    },
    [updateQueryString]
  );

  const changeDateFromTo = useCallback(
    (value, field) => {
      const date = moment(value);
      updateQueryString(field, date.isValid() ? date.format(DATE_FORMAT) : null);
      if (field === 'dateFrom') {
        setDateFrom(value);
      } else {
        setDateTo(value);
      }
    },
    [updateQueryString]
  );

  const onDatesChange = ({ startDate, endDate }) => {
    changeDateFromTo(startDate, 'dateFrom');
    changeDateFromTo(endDate, 'dateTo');
  };

  const { plumBidsAdmin, plumBidsAdminLoading, fetchMore, refetchPlumBids, pageInfo } = usePlumBidAdmin({
    address: address || undefined,
    status: status === 'Initializing' ? PLUMBID_STATUS.INCOMPLETE : status || undefined,
    listingAgentId,
    first: GLOBAL.ROWS_PER_PAGE,
    orderBy: ['finish_date_time'],
  });

  const { users, usersLoading } = useUsers({
    role: ROLES.LISTING_AGENT,
  });

  const selectedListingAgent = useMemo(() => {
    if (users) {
      const user = users.find(user => user.userId === +listingAgentId);

      if (user) {
        return {
          text: formatUserFullName(user),
          value: user.userId,
        };
      }
    }
    return null;
  }, [listingAgentId, users]);

  usePageLoading(plumBidsAdminLoading, 'PlumBidTableContainer');

  useEffect(() => {
    refetchPlumBids({
      first: GLOBAL.ROWS_PER_PAGE,
      dateFrom: dateFrom ? formatDateToGraphQLDate(dateFrom._d) : undefined,
      dateTo: dateTo ? formatDateToGraphQLDate(dateTo._d) : undefined,
      listingAgentId,
      status: status === 'Initializing' ? PLUMBID_STATUS.INCOMPLETE : status || undefined,
      orderBy: ['finish_date_time'],
      address: address || undefined,
    });
  }, [listingAgentId, status, refetchPlumBids, dateFrom, dateTo, address]);

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box mr={1} width={200}>
          <SearchInput value={address} onChange={searchAddress} placeholder="Search" />
        </Box>
        <Box mr={1}>
          <DateRangePicker
            startDate={dateFrom}
            startDateId="start_date_id"
            endDate={dateTo}
            endDateId="end_date_id"
            onDatesChange={onDatesChange}
            focusedInput={focusedInput ? focusedInput : null}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            showClearDates={true}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={() => false}
            startDatePlaceholderText="Date from"
            endDatePlaceholderText="Date to"
            customArrowIcon="—"
          />
        </Box>
        <Box minWidth={170} mr={1}>
          <Autocomplete
            noOptionsText="No status"
            options={Object.values(PLUMBID_STATUS).map(status =>
              status === PLUMBID_STATUS.INCOMPLETE ? 'Initializing' : status
            )}
            value={status}
            onChange={changeStatus}
            placeholder="Status"
          />
        </Box>
        <Box minWidth={270} mr={1}>
          <Autocomplete
            noOptionsText="No Listing Agent"
            loading={usersLoading}
            loadingText="Loading..."
            options={users?.map(user => ({
              text: formatUserFullName(user),
              value: user.userId,
            }))}
            value={selectedListingAgent}
            getOptionLabel={item => item.text}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={changeListingAgentId}
            placeholder="Listing Agent"
          />
        </Box>
      </Box>
      <PlumBidTable plumBids={plumBidsAdmin} fetchMore={fetchMore} pageInfo={pageInfo} loading={plumBidsAdminLoading} />
    </>
  );
};
