import gql from 'graphql-tag';

import { PlumBidType, PlumBidOptimized, AdminPlumbidType } from '../types';

export const ON_NEW_PLUMBID_FULL_EVENT_SUBSCRIPTION = gql`
  subscription OnNewPlumBidEvent($room: String) {
    onNewPlumBidEvent(room: $room) {
      plumbid {
        ${PlumBidType}
      }
    }
  }
`;

export const ON_NEW_PLUMBID_FOR_OPTIMIZER_EVENT_SUBSCRIPTION = gql`
  subscription OnNewPlumBidForOptimizerEvent($room: String) {
    onNewPlumBidEvent(room: $room) {
      plumbid {
        ${PlumBidOptimized}
      }
    }
  }
`;

export const ON_NEW_PLUMBID_ADMIN_EVENT_SUBSCRIPTION = gql`
  subscription OnNewAdminPlumbidEvent($room: String) {
    onNewAdminPlumbidEvent(room: $room) {
      plumbid {
        ${AdminPlumbidType}
      }
    }
  }
`;
