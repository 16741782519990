import { theme } from 'utils/theme';
import './style.scss';

export const Spinner = ({ width = 30, height = 5, style }) => {
  const circles = [...Array(4)].map((_, index) => (
    <div key={index} style={{ background: theme.palette.purple.light }} />
  ));

  return (
    <div className="spinner-dot" style={{ ...style, width, height }}>
      {circles}
    </div>
  );
};
