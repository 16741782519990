import React, { memo } from 'react';

import { CardSlider, Text } from 'legos';
import { BaseCard } from './BaseCard';
import { ESCROW_MAX, ESCROW_MIN } from 'constant';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';
import { PBox } from 'legos/PBox';

const daysMarks = [
  ...new Array(Math.floor((ESCROW_MAX - ESCROW_MIN) / 2)).fill(0),
  { value: ESCROW_MIN, label: `${ESCROW_MAX}` },
  { value: ESCROW_MAX, label: `${ESCROW_MIN}` },
].map((mark, index) => {
  if (mark) {
    return mark;
  }
  const newMark = { value: index * 2 + ESCROW_MIN + 1 };
  if (
    newMark.value === ESCROW_MAX + ESCROW_MIN - 15 ||
    newMark.value === ESCROW_MAX + ESCROW_MIN - 35 ||
    newMark.value === ESCROW_MAX + ESCROW_MIN - 55 ||
    newMark.value === ESCROW_MAX + ESCROW_MIN - 75
  ) {
    newMark.label = `${ESCROW_MAX + ESCROW_MIN - newMark.value}`;
  }
  return newMark;
});

export const EscrowCard = memo(
  ({
    style,
    loading,
    disabled,
    escrowLength,
    improveEscrow,
    disableSlider,
    changeIncentive,
    idealEscrowLength,
    sellerPrioritiesValue,
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const responsive = useResponsive();

    return (
      <BaseCard
        style={style}
        loading={loading}
        disabled={disabled}
        improve={improveEscrow}
        sellerPrioritiesTitle={
          idealEscrowLength === 0
            ? 'Escrow Priority: As short as possible'
            : idealEscrowLength
            ? `Escrow Priority: ${idealEscrowLength} ${idealEscrowLength > 1 ? 'days' : 'day'} Preferred`
            : null
        }
        sellerPrioritiesValue={sellerPrioritiesValue}
      >
        <PBox mb={3} mt={4} height="70px">
          <Text
            disabled={disabled}
            variant="h2"
            display="inline"
            style={{ fontSize: responsive.mobileScreen ? 20 : 24 }}
          >
            Escrow:{' '}
          </Text>
          <Text
            variant="h2"
            display="inline"
            disabled={disabled}
            style={{
              color: theme.palette.purple.light,
              fontSize: responsive.mobileScreen ? 20 : 24,
            }}
          >{`${escrowLength} days`}</Text>
        </PBox>
        <CardSlider
          min={ESCROW_MIN}
          max={ESCROW_MAX}
          step={1}
          marks={daysMarks}
          disabled={disabled || disableSlider}
          track="inverted"
          value={ESCROW_MAX + ESCROW_MIN - escrowLength}
          onChange={(_, newValue) => changeIncentive(ESCROW_MAX + ESCROW_MIN - newValue, 'escrowLength', null, true)}
          onChangeCommitted={(_, newValue, valueHasChanged) =>
            changeIncentive(ESCROW_MAX + ESCROW_MIN - newValue, 'escrowLength', null, !valueHasChanged)
          }
        />
      </BaseCard>
    );
  }
);
