import { memo, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { getSellerWarningMessage } from 'utils';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const SellerStatus = memo(({ participant }) => {
  const responsive = useResponsive();

  const warningMessage = useMemo(() => getSellerWarningMessage(participant), [participant]);

  return (
    <Typography
      align={responsive.smallScreen ? 'left' : 'right'}
      style={{
        fontSize: 11,
        lineHeight: 1.2,
        fontWeight: 400,
        color: warningMessage?.color ?? theme.palette.text.warning,
      }}
    >
      {warningMessage?.text}
    </Typography>
  );
});
