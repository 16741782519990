import React from 'react';
import { shape } from 'prop-types';

export const Address = ({ style }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.0009 0H2.16682C1.59214 0 1.041 0.170166 0.634646 0.473063C0.228289 0.775959 0 1.18678 0 1.61514V15.3438C0 15.558 0.114145 15.7634 0.317323 15.9148C0.520502 16.0663 0.796071 16.1514 1.08341 16.1514H14.0843C14.3717 16.1514 14.6472 16.0663 14.8504 15.9148C15.0536 15.7634 15.1677 15.558 15.1677 15.3438V1.61514C15.1677 1.18678 14.9394 0.775959 14.5331 0.473063C14.1267 0.170166 13.5756 0 13.0009 0ZM6.50045 11.306H3.25023V9.69082H6.50045V11.306ZM6.50045 8.07568H3.25023V6.46055H6.50045V8.07568ZM6.50045 4.84541H3.25023V3.23027H6.50045V4.84541ZM11.9175 11.306H8.66727V9.69082H11.9175V11.306ZM11.9175 8.07568H8.66727V6.46055H11.9175V8.07568ZM11.9175 4.84541H8.66727V3.23027H11.9175V4.84541Z"
      fill="white"
    />
  </svg>
);

Address.propTypes = {
  style: shape({}),
};

Address.defaultProps = {
  style: null,
};
