import React from 'react';
import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { object, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { Input, Button, NavigationButton, Checkbox, PhoneInput, PBox } from 'legos';
import { useAuthState } from 'service/store';
import { useNotification, useUserProfile, useDialog } from 'utils/hooks';
import { DIALOG, ROLES, LISTING_AGENT_VERIFY_STATUS } from 'constant';
import { theme } from 'utils/theme';
import { formatToTitleCase } from 'utils';

const fields = [
  {
    label: 'Full name',
    fieldName: 'fullName',
    init: '',
  },

  {
    label: 'Email',
    fieldName: 'email',
    type: 'email',
    init: '',
    disable: true,
  },
  {
    label: 'DRE',
    fieldName: 'dreId',
    type: 'text',
    init: '',
  },
  {
    label: 'Phone',
    fieldName: 'phone',
    type: 'text',
    init: '',
  },
  {
    component: 'checkbox',
    fieldName: 'acceptSms',
    type: 'checkbox',
    init: true,
  },
];

const getValidationSchema = isMeAgent =>
  object().shape({
    fullName: string().trim().required('Full name is required'),
    ...(isMeAgent
      ? {
          dreId: string()
            .trim()
            .max(12, 'The number of characters must not exceed 12')
            .matches(/^\d+$/, 'DRE ID can only contain numbers')
            .required('DRE is required'),
        }
      : null),
  });
string().min(1);

export const UserSettingsForm = () => {
  const [{ me }] = useAuthState();

  const { changeUserProfile, changeUserProfileLoading } = useUserProfile();
  const { openDialog: openAddDREIdDialog } = useDialog(DIALOG.ADD_DRE_IDD);
  const { openDialog: openChangePasswordDialog } = useDialog(DIALOG.CHANGE_PASSWORD);

  const showNotification = useNotification();
  const history = useHistory();

  const isMeAgent = me.roles.some(role => role === ROLES.LISTING_AGENT || role === ROLES.BUYER_AGENT);
  const formik = useFormik({
    initialValues: {
      fullName: formatToTitleCase(me.fullName),
      email: me.email,
      dreId: me.dreId || '',
      phone: me.phone?.slice(2) || '',
      acceptSms: me.acceptSms,
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(isMeAgent),
    onSubmit: values => {
      changeUserProfile({
        variables: {
          input: {
            ...omit(values, ['email']),
            phone: `+1${values.phone}`,
          },
        },
      }).then(response => {
        if (response?.data?.changeUserProfile?.success) {
          showNotification({
            message: 'Profile changed',
            variant: 'success',
          });
        }
      });
    },
  });

  const showAddDREIdDialog = () => {
    openAddDREIdDialog({
      dreId: me.dreId,
    });
  };

  return (
    <PBox px={6}>
      <PBox pt={5} mb={7} display="flex" alignItems="center">
        <NavigationButton
          fullWidth={false}
          onClick={() => history.goBack()}
          icon="LeftArrow"
          size={34}
          iconSize={14}
          style={{
            background: theme.palette.primary.main,
            color: 'red',
            marginRight: 32,
          }}
          color={theme.palette.common.white}
        />
        <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
          Profile
        </Typography>
      </PBox>

      <form
        onSubmit={e => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <PBox maxWidth="650px">
          {fields.map(({ fieldName, label, type, component, disable }) => {
            if (fieldName === 'dreId' && !isMeAgent) {
              return null;
            }
            if (component === 'checkbox') {
              return (
                <PBox key={fieldName} mt={1.5} display="flex" alignItems="center">
                  <Checkbox
                    checked={formik.values[fieldName]}
                    onChange={event => formik.setFieldValue(fieldName, event.target.checked)}
                  />
                  <Typography>Send SMS Notifications</Typography>
                </PBox>
              );
            }

            return (
              <PBox key={fieldName} pb={2}>
                {fieldName === 'phone' ? (
                  <PhoneInput
                    label={label}
                    value={formik.values[fieldName]}
                    type="tel"
                    onChange={value => formik.setFieldValue(fieldName, value)}
                    error={formik.touched[fieldName] && formik.errors[fieldName]}
                  />
                ) : (
                  <Input
                    name={fieldName}
                    label={label}
                    type={type}
                    value={formik.values[fieldName]}
                    disabled={
                      disable ||
                      (fieldName === 'dreId' && me?.listingAgentStatus !== LISTING_AGENT_VERIFY_STATUS.REJECTED)
                    }
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue(fieldName, fieldName === 'fullName' ? formatToTitleCase(value) : value)
                    }
                    error={formik.touched[fieldName] && formik.errors[fieldName]}
                  />
                )}
                <PBox pt={2}>
                  {fieldName === 'dreId' && me?.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.REJECTED ? (
                    <Typography variant="h4" style={{ color: theme.palette.text.error }}>
                      Your Listing Agent application was rejected. Please change your DRE
                    </Typography>
                  ) : null}
                  {fieldName === 'dreId' &&
                  (me?.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.NEW ||
                    me?.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.UNVERIFIED) ? (
                    <Typography variant="h4" style={{ color: theme.palette.text.warning }}>
                      Admin will have to check your DRE License number, please expect an email.
                    </Typography>
                  ) : null}
                </PBox>
              </PBox>
            );
          })}
          <PBox mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
            <PBox mt={2}>
              <PBox>
                <Button
                  title="Save"
                  align="center"
                  type="submit"
                  loading={changeUserProfileLoading}
                  style={{ width: 189 }}
                />
              </PBox>
              <PBox mt={2}>
                <Button
                  title="Change password"
                  variant="secondary"
                  type="button"
                  align="center"
                  style={{ width: 189 }}
                  onClick={openChangePasswordDialog}
                />
              </PBox>
            </PBox>
            {!me?.roles.includes(ROLES.LISTING_AGENT) && !me?.roles.includes(ROLES.ADMIN) && (
              <PBox mt={2}>
                <Button
                  title="Apply as Listing Agent"
                  variant="secondary"
                  type="button"
                  align="center"
                  style={{ width: 189 }}
                  onClick={showAddDREIdDialog}
                />
              </PBox>
            )}
          </PBox>
        </PBox>
      </form>
    </PBox>
  );
};
