import React from 'react';
import { shape } from 'prop-types';

export const Wave = ({ style }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M21 10H17L14 19L8 1L5 10H1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Wave.propTypes = {
  style: shape({}),
};

Wave.defaultProps = {
  style: null,
};
