import React, { useCallback } from 'react';
import { string, func, bool } from 'prop-types';
import { Box, DialogContent, Dialog, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button, TextWithLabel, WarningBlock, RouterLink } from 'legos';
import { TERM_OF_USE } from 'routes';
import { DialogHeader } from '../Header/DialogHeader';
import { formatNumberToUSD } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const ReviewAndBidDialog = ({
  isOpen,
  onClose,
  onOk,
  title,
  okTitle,
  cancelTitle,
  data,
  loading,
  isDontAllowBid,
}) => {
  const classes = useStyles();
  const responsive = useResponsive();

  const handlerClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={handlerClose} fullWidth maxWidth="md" scroll="body">
      <DialogHeader title={title} onClose={handlerClose} />
      <DialogContent>
        <Box mt={1.5}>
          <Grid container alignItems="baseline" style={{ border: 'solid 1px #EAE8E4' }}>
            <Box my={1}>
              {isDontAllowBid && (
                <WarningBlock
                  style={{ height: 'auto', padding: 8 }}
                  text="Seller has refused contingent offers from participating in plumBid. If you want to remove contingency please contact your Listing Agent."
                />
              )}
            </Box>
            <Grid item xs={12} md={5}>
              <Box p={4} pb={responsive.mdScreen && 0}>
                <Box pb={2}>
                  <TextWithLabel
                    label="Offer price"
                    text={formatNumberToUSD(data.price, {
                      hidePlusSign: true,
                    })}
                  />
                </Box>
                <Box pb={2}>
                  <TextWithLabel
                    label="Cash Down"
                    text={`${formatNumberToUSD(data?.amountDown, {
                      hidePlusSign: true,
                    })}. ${Math.round(+data?.percentageDown)}%`}
                  />
                </Box>
                <Box pb={2}>
                  <TextWithLabel
                    label="Escrow period"
                    text={
                      data?.escrowLength < 2
                        ? (data?.escrowLength || '0') + ' day'
                        : (data?.escrowLength || '0') + ' days'
                    }
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box p={4} pt={responsive.mdScreen && 0}>
                <Box pb={2}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                    Inspection contingency
                  </Typography>
                  <Box>
                    <Box display="flex" alignItems="baseline">
                      <Typography variant="subtitle2">{data?.inspectionContingency ? 'Yes' : 'No'}</Typography>
                      {data?.inspectionContingency && (
                        <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                          {data?.inspectionContingencyLength < 2
                            ? (data?.inspectionContingencyLength || '') +
                              (data?.inspectionContingencyLength ? ' day' : '')
                            : (data?.inspectionContingencyLength || '') +
                              (data?.inspectionContingencyLength ? ' days' : '')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box pb={2}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                    Appraisal contingency
                  </Typography>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="subtitle2">{data?.appraisalContingency ? 'Yes' : 'No'}</Typography>
                    {data?.appraisalContingency && (
                      <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                        {data?.appraisalContingencyLength < 2
                          ? (data?.appraisalContingencyLength || '') + (data?.appraisalContingencyLength ? ' day' : '')
                          : (data?.appraisalContingencyLength || '') +
                            (data?.appraisalContingencyLength ? ' days' : '')}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box pb={2}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                    Loan Contingency
                  </Typography>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="subtitle2">{data?.loanContingency ? 'Yes' : 'No'}</Typography>
                    {data?.loanContingency && (
                      <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                        {data?.loanContingencyLength < 2
                          ? (data?.loanContingencyLength || '') + (data?.loanContingencyLength ? ' day' : '')
                          : (data?.loanContingencyLength || '') + (data?.loanContingencyLength ? ' days' : '')}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                    Offer Lease Back
                  </Typography>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="subtitle2">{data?.offerLeaseBack ? 'Yes' : 'No'}</Typography>
                    {!data?.offerLeaseBack && (
                      <>
                        <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                          {data?.offerLeaseBackLength < 2
                            ? (data?.offerLeaseBackLength || '') + (data?.offerLeaseBackLength ? ' day' : '')
                            : (data?.offerLeaseBackLength || '') + (data?.offerLeaseBackLength ? ' days' : '')}
                        </Typography>
                        <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                          {data?.offerLeaseBackPricePerDay > 0
                            ? formatNumberToUSD(data?.offerLeaseBackPricePerDay, {
                                hidePlusSign: true,
                              }) + ' /Per Day'
                            : ''}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box py={3}>
          <Typography variant="h5">
            IMPORTANT: Once accepted your bid considered final and cannot be cancelled.{' '}
            <RouterLink to={TERM_OF_USE} onClick={handlerClose} style={{ color: theme.palette.text.primary }}>
              {'Terms & Conditions'}
            </RouterLink>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
          <Button
            title={cancelTitle}
            variant="secondary"
            style={{ width: 120 }}
            disabled={loading}
            onClick={handlerClose}
          />
          <Box width={8} />
          <Button title={okTitle} style={{ width: 120 }} onClick={onOk} loading={loading} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ReviewAndBidDialog.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onOk: func,
  text: string,
  okTitle: string,
  cancelTitle: string,
};

ReviewAndBidDialog.defaultProps = {
  onOk: () => {},
  title: '',
  okTitle: 'OK',
  cancelTitle: 'Cancel',
};
