import { TableRow, TableCell as TableCellMaterial, TableHead, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const TableCell = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.chatMessageHeader,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 8,
    paddingBottom: 8,
  },
}))(TableCellMaterial);

const columns = [
  { width: '12%', title: 'Name' },
  { width: '20%', title: 'Last Bid Amount' },
  { width: '12%', title: 'You Pay Amount' },
  { width: '10%', title: 'Party' },
  { width: '8%', title: 'Cash Down' },
  { width: '7%', title: 'Escrow' },
  { width: '7%', title: 'Inspection', titleSecondLine: 'Contingency', twoLines: true },
  { width: '7%', title: 'Loan', titleSecondLine: 'Contingency', twoLines: true },
  { width: '9%', title: 'Appraisal', titleSecondLine: 'Contingency', twoLines: true },
  { width: '8%', title: 'Leaseback' },
];

const HistoryTableHead = () => (
  <TableHead>
    <TableRow>
      {columns.map(col => (
        <TableCell key={col.title} width={col.width}>
          <Typography variant="body2" style={{ fontWeight: 400 }}>
            {col.title}
          </Typography>
          {col.twoLines && <Typography variant="body2">{col.titleSecondLine}</Typography>}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
export default HistoryTableHead;
