import { useEffect } from 'react';

import {
  Footer,
  PageContainer,
  LearnMore,
  SellerBenefits,
  DescriptionOptimizer,
  QuestionsSection,
  AboutPlumBid,
} from 'components';

export const SellersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <SellerBenefits />
      <DescriptionOptimizer />
      <QuestionsSection />
      <AboutPlumBid />
      <LearnMore />
      <Footer />
    </PageContainer>
  );
};
