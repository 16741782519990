import React, { useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Header } from 'components';
import { useAuthState } from 'service/store';
import { useGetMe, useUpdateQueryString } from 'utils/hooks';
import { removeSession } from 'service/auth';
import { DASHBOARD, HOME, ADMIN_DASHBOARD, ADMIN, PLUMBID } from 'routes';
import { ROLES } from 'constant';

export const PrivateByRoleLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [parsedSearch, updateQueryString, pathname] = useUpdateQueryString();

  const [{ me, loggedIn }, dispatchAuth] = useAuthState();
  useGetMe();

  const storeRedirectPath = useCallback(() => {
    dispatchAuth({
      type: 'redirectPath',
      payload: { path: `${pathname}?${updateQueryString('email')}`, email: parsedSearch.email?.replace(' ', '+') },
    });
  }, [dispatchAuth, parsedSearch.email, pathname, updateQueryString]);

  const renderContent = () => {
    if (loggedIn && !me) {
      return null;
    }

    if ((!loggedIn || !me) && pathname !== HOME) {
      storeRedirectPath();
      return <Redirect to={HOME} />;
    }

    if (parsedSearch.email) {
      const email = parsedSearch.email.toLowerCase().replace(' ', '+');
      if (me && email.toLowerCase() !== me.email.toLowerCase()) {
        dispatchAuth({
          type: 'logout',
        });
        removeSession();
        storeRedirectPath();
        return <Redirect to={HOME} />;
      } else {
        updateQueryString('email');
      }
    }

    if (me?.roles?.includes(ROLES.ADMIN)) {
      if (pathname === DASHBOARD || pathname === PLUMBID) {
        return <Redirect to={ADMIN_DASHBOARD} />;
      }
    } else {
      if (pathname.startsWith(ADMIN)) {
        return <Redirect to={DASHBOARD} />;
      }
    }

    return children;
  };

  return (
    <>
      <Header />
      {renderContent()}
    </>
  );
};
