import gql from 'graphql-tag';

export const UPDATE_PARTY_MUTATION = gql`
  mutation UpdatePlumbidParty($input: UpdatePlumBidPartyInput!) {
    updatePlumbidParty(input: $input) {
      success
      errors
    }
  }
`;
