import gql from 'graphql-tag';

import { UserType } from '../types';

export const CHANGE_USER_PROFILE_MUTATION = gql`
  mutation ChangeUserProfileMutation($input: ChangeUserProfileInput!) {
    changeUserProfile(input: $input) {
      success
      errors
      user {
        ${UserType}
      }
    }
  }
`;
