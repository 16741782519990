import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete as AutocompleteM, createFilterOptions } from '@material-ui/lab';

import { FONT_FAMILY_HN } from 'utils/theme';

const filterOptions = createFilterOptions({
  ignoreCase: true,
});

const CustomAutocomplete = withStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 0,
      minHeight: 48,
      minWidth: 133,
      paddingTop: 2,
      paddingBottom: 2,
      outline: 'none !important',
      border: 'none',
    },

    '& input': {
      '&::placeholder': {
        color: theme.palette.text.transparent,
      },
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },

    fontFamily: 'Helvetica Neue',
    '& .MuiChip-label': {
      fontFamily: FONT_FAMILY_HN,
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.purple.light,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #EAE8E4',
    },
  },

  option: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.purple.light,
      color: 'white',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.purple.dark,
      color: 'white',
    },
  },
  paper: {
    fontFamily: FONT_FAMILY_HN,
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
  },
}))(AutocompleteM);

export const Autocomplete = ({ options, onChange, placeholder, noOptionsText, ...props }) => (
  <CustomAutocomplete
    autoHighlight
    noOptionsText={noOptionsText}
    defaultChecked
    filterOptions={filterOptions}
    options={options}
    onChange={onChange}
    size="small"
    renderInput={params => <TextField {...params} variant="outlined" placeholder={placeholder} />}
    {...props}
  />
);
