import { useEffect } from 'react';

import { Footer, LearnMore, PageContainer, PhotoAndShowMore } from 'components';
import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';
import backgroundMultiplesSection from 'assets/images/backgroundMultiplesSection.png';
import { theme } from 'utils';
import Page1Png from 'assets/images/GroupPBm_1.png';
import Page2Png from 'assets/images/GroupPBm_2.png';
import { HowPlumBidWorksVideo } from 'components/Home/HowPlumBidWorksVideo';

export const PlumBidMultiplePage = () => {
  const { mobileScreen, padScreen, smallScreen, mdScreen } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <Box display="flex" flexDirection="column">
        <Box
          height="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundImage: `url(${backgroundMultiplesSection})`,
            backgroundSize: 'cover',
            backgroundPosition: smallScreen ? 'center' : 'left',
            backgroundPositionY: '48%',
          }}
        >
          <Grid container>
            <Grid
              container
              item
              xs={smallScreen ? 12 : 8}
              md={6}
              justify="flex-end"
            >
              <Box
                maxWidth="675px"
                mr={smallScreen ? 2 : 'unset'}
                px={mobileScreen ? 3 : 4}
                py={mobileScreen ? 1.5 : 3}
                bgcolor={smallScreen ? '#ffffff99' : '#ffffff'}
                borderRadius="60px"
              >
                <Typography
                  variant="h1"
                  style={{
                    fontSize: mobileScreen ? 24 : padScreen ? 28 : 34,
                    fontWeight: 700,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    color="primary"
                    style={{
                      fontSize: mobileScreen ? 24 : padScreen ? 28 : 34,
                      fontWeight: 700,
                      paddingRight: 8,
                    }}
                  >
                    plumBid
                  </Typography>
                  Multiple Offers
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <PhotoAndShowMore
          title="plumBid Multiple Offers
          overview"
          photo={Page1Png}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                  marginBottom: '26px',
                  fontWeight: 400,
                }}
              >
                If your property is already listed for sale and you are
                anticipating a multiple offer situation, use <b>plumBid</b>{' '}
                Multiple Offers and its innovative bidding platform to achieve
                the optimal price and best terms for all parties involved.
              </Typography>
              <HowPlumBidWorksVideo />
            </>
          }
        />

        <PhotoAndShowMore
          title="Pricing"
          bgColor={theme.palette.background.card}
          photo={Page2Png}
          isOrderReverse={!mdScreen}
          text={
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                  paddingBottom: 0,
                  fontWeight: 400,
                }}
              >
                <b>plumBid</b> Multiple Offer charges the seller a flat fee of
                $495, that is only payable when escrow closes. The Multiple
                Offers fee is added to the seller’s final closing costs.
              </Typography>{' '}
              <a
                className="removeDecorators"
                href="mailto:info@plumbid.com,jgassel@plumbid.com"
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                Please contact us for more information.
              </a>
            </>
          }
        />

        <LearnMore />
        <Footer />
      </Box>
    </PageContainer>
  );
};
