import moment from 'moment';

export const formatNumberToUSD = (number, options = {}) => {
  options.nanLabel = options.nanLabel || '-';

  if (typeof number === 'string') {
    number = Number(number);
  }

  if (Number.isNaN(number)) {
    return options.nanLabel;
  }

  if (typeof number === 'number') {
    const roundedNumber = Math.round(number);

    return `${roundedNumber < 0 ? '-' : roundedNumber > 0 && !options.hidePlusSign ? '+' : ''}$${Math.abs(roundedNumber)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  return number;
};

export const formatNumberToUSNumeric = (number, options = {}) => {
  options.nanLabel = options.nanLabel || '-';
  if (Number.isNaN(number)) {
    return options.nanLabel;
  }

  if (typeof number === 'number') {
    const roundedNumber = number;

    return `${roundedNumber < 0 ? '-' : ''}${Math.abs(roundedNumber).toLocaleString('us', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}`;
  }
  return number;
};

export const formatGraphqlError = (error) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Graphql error:', error);
  }

  if (error && typeof error === 'string') {
    return error;
  }

  if (error && Array.isArray(error)) {
    return `${error.join('\n ')}`;
  }

  return `${
    error && error.graphQLErrors && error.graphQLErrors.length
      ? error.graphQLErrors.map((e) => e.message).join('\n ')
      : error.message
  }`;
};

export const formatApplicationGraphqlError = (errors) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Graphql error:', errors);
  }
  return `${errors.map((e) => e).join('\n ')}`;
};

export const formatAddress = (addr) => {
  return addr
    ? [addr.address || '', addr.city || '', addr.state || '', addr.zipCode || ''].filter((item) => item).join(', ')
    : '';
};

export const formatAddressTwoLines = (addr) =>
  addr
    ? {
        line1: addr.address || '',
        line2: `${addr.city + ',' || ''} ${addr.state || ''} ${addr.zipCode || ''}`,
      }
    : {};

export const formatUserFullName = (user) => {
  return `${user?.fullName ? `${user.fullName} ` : ''}`;
};

export const formatDateToGraphQLDate = (date) => moment(date).format('YYYY-MM-DD');

export const formatTemplate = (template, values) => {
  if (!values || !template) {
    return template;
  }
  let text = template;
  Object.keys(values).forEach((key) => (text = text.replace(new RegExp(`{${key}}`, 'gm'), values[key])));

  return text;
};

export const formatPartyName = (fullNameList) =>
  Array.from(
    new Set(
      fullNameList.map((fullName) => {
        const name = fullName.trim().split(' ');
        if (name[0].includes(',')) {
          return name[0].slice(0, -1);
        }
        return name[name.length - 1];
      })
    )
  ).join('/');

export const formatUserNameChain = (names) => {
  const innerNames = Array.isArray(names) ? [...names] : [];
  if (innerNames.length === 0) {
    return '';
  }
  if (innerNames.length === 1) {
    return innerNames[0];
  }

  const lastElement = innerNames.splice(innerNames.length - 1, 1);

  return `${innerNames.join(', ')} and ${lastElement[0]}`;
};

export const formatPDFFileName = (fileName) => {
  if (typeof fileName !== 'string') {
    return fileName;
  }

  if (fileName.startsWith('pdf/')) {
    return fileName.replace('pdf/', '');
  }

  return fileName;
};

export const getInitials = (fullName) => {
  let initials = fullName
    ?.match(/\b(\w)/g)
    ?.join('')
    ?.slice(0, 2);
  if (!initials) {
    return 'N/A';
  }
  if (initials.length === 1) {
    return fullName.length === 1 ? fullName + fullName : fullName.slice(0, 2);
  }
  return initials;
};

export const formatToTitleCase = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const timeLeftFormat = (ms) => {
  if (ms < 1000) {
    return '';
  }
  let s = Math.floor(ms / 1000);
  let m = Math.floor(s / 60);
  let h = Math.floor(m / 60);
  const d = Math.floor(h / 24);

  h -= d * 24;
  m -= (d * 24 + h) * 60;
  s -= ((d * 24 + h) * 60 + m) * 60;

  let timeString = '';
  if (d === 1) {
    timeString = '1 day';
  } else if (d > 1) {
    timeString = d + ' days';
  }
  try {
    return `${timeString} ${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`;
  } catch (err) {
    return `${Math.floor(ms / 1000)} sec`;
  }
};
