import gql from 'graphql-tag';
import { PlumBidType, PlumBidOptimized } from '../types';

export const GET_PLUMBID_BY_ID_QUERY = gql`
  query PlumbidById($id: Int) {
    plumbidById(id: $id) {
      ${PlumBidType}
    }
  }
`;

export const GET_PLUMBID_BY_ID_OPTIMIZED_QUERY = gql`
  query PlumBidByIdOptimized($id: Int) {
    plumbidById(id: $id) {
      ${PlumBidOptimized}
    }
  }
`;
