import React from 'react';
import { Box } from '@material-ui/core';
import { HeaderHome } from 'components';
import { useGetMe } from 'utils/hooks';

export const PublicLayout = ({ children }) => {
  useGetMe();

  return (
    <>
      <HeaderHome />
      <Box mt={'70px'}>{children}</Box>
    </>
  );
};
