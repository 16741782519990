import gql from 'graphql-tag';

export const SELLER_QUESTIONS_QUERY = gql`
  query SellerQuestionsQuery($plumbidId: Int) {
    onboardingSellerQuestions(plumbidId: $plumbidId) {
      mcai
      isNeedQ15
      onboardingSellerQuestions {
        questionId
        text1
        text2
        text3
        text4
        text5
        answerList {
          answerId
          text
        }
        partyAnswers {
          answerId
          answerValue
          owner {
            user {
              userId
              fullName
            }
          }
        }
      }
    }
  }
`;
