import { Box, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import React, { useCallback, useState, useEffect, useMemo, Fragment } from 'react';

import { ROLES, NEW_PARTICIPANT_STATUS } from 'constant';
import { SellerPriorities, PriorityScale } from 'legos';
import { SellerItem } from './SellerItem';
import { SellerOnboardingForm } from './SellerOnboardingForm';
import { useResponsive, useSellerQuestions, useURLQuery } from 'utils/hooks';
import { QuestionSubHeader } from './QuestionSubHeader';
import { RedirectPage } from 'components/RedirectPage';

const listPriorities = [
  { percentField: 'cash', field: 'amountDown', title: 'Seller: Cash Down Priority' },
  { percentField: 'escrow', field: 'escrowPriority', title: 'Seller: Escrow Priority' },
  { percentField: 'inspection', field: 'inspectionPriority', title: 'Seller: Inspection Priority' },
  { percentField: 'loan', field: 'loanContingencyPriority', title: 'Seller: Loan Contingency Priority' },
  { percentField: 'appraisal', field: 'appraisalContingencyPriority', title: 'Seller: Appraisal Contingency Priority' },
  { percentField: 'leaseback', field: 'leaseBackImportance', title: 'Seller: Lease Back Importance' },
  { percentField: 'cop', field: 'sellerContingencyPriority', title: 'Seller: Contingency on Purchase (COP) Priority' },
];

export const SellerOnboardingContainer = () => {
  const location = useLocation();
  const { plumbid } = useURLQuery();
  const responsive = useResponsive();
  const [selectedSeller, setSelectedSeller] = useState(location?.state?.seller);
  const {
    topOffers,
    topOffersLoading,
    setAnswer,
    mismatch,
    canSubmit,
    plumBid,
    plumBidError,
    plumBidLoading,
    sellersAnswers,
    priorityParams,
    sellerQuestions,
    confirmSellerAnswers,
    confirmSellerAnswersLoading,
    answeredSellers,
    anotherSellersAnswer,
    saveSellerAnswers,
    refetch,
    processing,
    plumBidRefetch,
  } = useSellerQuestions(
    {
      plumbidId: plumbid,
    },
    selectedSeller
  );

  useEffect(() => {
    plumBidRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myInfo = plumBid?.myInfoInPlumBid;
  const isAgent = myInfo?.role === ROLES.LISTING_AGENT;

  useEffect(() => {
    if (isAgent && !selectedSeller && plumBid?.sellerParty?.participantSet[0]) {
      setSelectedSeller(plumBid?.sellerParty?.participantSet[0]);
    }
    if (plumBid && selectedSeller) {
      const seller = plumBid?.sellerParty?.participantSet.find(participant => participant.id === selectedSeller.id);
      if (seller) {
        setSelectedSeller(seller);
      }
    }
  }, [isAgent, plumBid, selectedSeller]);

  const onSelectSeller = useCallback(
    seller => {
      if (!plumBidLoading) {
        setSelectedSeller(seller);
        refetch();
      }
    },
    [setSelectedSeller, plumBidLoading, refetch]
  );
  const selectedSellerPreferences = selectedSeller?.preferences;
  const isSellerAnswered = plumBid?.sellerParty?.participantSet.find(
    seller => seller.id === selectedSeller?.id && seller?.selleranswerSet.length > 1
  );
  const isShowOnboarding =
    (plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT && selectedSeller && isSellerAnswered) ||
    myInfo?.role === ROLES.SELLER;

  const isSellerCompleted = useMemo(
    () =>
      plumBid?.sellerParty?.participantSet.some(
        seller =>
          (seller.user?.userId === myInfo?.meId || seller.user?.userId === selectedSeller?.user?.userId) &&
          seller.status === NEW_PARTICIPANT_STATUS.COMPLETED
      ),
    [myInfo?.meId, plumBid?.sellerParty?.participantSet, selectedSeller?.user?.userId]
  );

  const getProgress = useCallback(() => {
    if (sellersAnswers?.length) {
      const curProgress = sellersAnswers.reduce((prev, cur) => {
        if (!cur.answerId) {
          prev -= 1 - cur.progress / 30;
        }
        if (
          (cur.questionId === 'Q6' && (isNil(cur.answerValue) || cur.answerValue === '')) ||
          (cur.questionId === 'Q4' && cur.answerId === 'Q4A6' && (isNil(cur.answerValue) || cur.answerValue === ''))
        ) {
          prev -= 1 - cur.progress / 50;
        }
        return prev;
      }, sellersAnswers[sellersAnswers.length - 1].progress);

      return (curProgress / priorityParams.maxProgress) * 100;
    }

    return 0;
  }, [priorityParams.maxProgress, sellersAnswers]);

  const renderPriorities = () => (
    <>
      <PriorityScale />
      {listPriorities.map(priority =>
        isSellerCompleted &&
        (selectedSellerPreferences?.percentage?.[priority.percentField] === 0 ||
          (!isAgent && myInfo?.myPreferences?.percentage?.[priority.percentField] === 0)) ? null : (
          <Fragment key={priority.field}>
            <SellerPriorities
              process={
                isAgent
                  ? selectedSellerPreferences?.percentage?.[priority.percentField]
                  : myInfo?.myPreferences?.percentage?.[priority.percentField]
              }
              title={priority.title}
            />
          </Fragment>
        )
      )}
    </>
  );

  return plumBid ? (
    <>
      <Box px={{ xs: 2, md: 6 }} style={{ paddingBottom: 110 }}>
        <QuestionSubHeader
          mismatch={mismatch}
          loading={processing}
          progress={getProgress()}
          selectedSeller={selectedSeller}
          style={{ position: 'absolute' }}
          plumbid={plumBid}
          isShowOnboarding={isShowOnboarding}
          refetchPlumbid={refetch}
        />
        {responsive.mdScreen && <Box mt={2}>{renderPriorities()}</Box>}
        <Grid container>
          <Grid item xs={12} md={7} style={{ position: 'relative' }}>
            <SellerOnboardingForm
              topOffers={topOffers}
              topOffersLoading={topOffersLoading}
              setAnswer={setAnswer}
              mismatch={mismatch}
              canSubmit={canSubmit}
              plumBid={plumBid}
              plumBidLoading={plumBidLoading}
              sellersAnswers={sellersAnswers}
              priorityParams={priorityParams}
              sellerQuestions={sellerQuestions}
              confirmSellerAnswers={confirmSellerAnswers}
              confirmSellerAnswersLoading={confirmSellerAnswersLoading}
              answeredSellers={answeredSellers}
              anotherSellersAnswer={anotherSellersAnswer}
              saveSellerAnswers={saveSellerAnswers}
              refetch={refetch}
              processing={processing}
              myRoleInPlumBid={myInfo?.role}
              selectedSeller={selectedSeller}
              isSellerAnswered={isSellerAnswered}
              reviewMode={!!location?.state?.seller}
              plumBidRefetch={plumBidRefetch}
            />
          </Grid>
          {!responsive.mdScreen && (
            <Grid item xs={12} md={5}>
              <Box style={{ position: 'sticky', top: 230, paddingTop: 8 }}>
                {!plumBidLoading &&
                  myInfo?.role === ROLES.LISTING_AGENT &&
                  plumBid?.sellerParty?.participantSet.map(seller => (
                    <SellerItem
                      key={seller.id}
                      seller={seller}
                      onSelectSeller={onSelectSeller}
                      isSelectedSeller={selectedSeller?.id === seller.id}
                    />
                  ))}
                <Box mt={2}>{renderPriorities()}</Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  ) : !plumBid && plumBidError ? (
    <RedirectPage />
  ) : null;
};
