import { DIALOG } from 'constant';
import { useDialog } from 'utils/hooks';
import { VideoDialog } from './VideoDialog';
import { SurveyDialog } from './SurveyDialog';
import { OkCancelDialog } from './OkCancelDialog';
import { TopOfferDialog } from './TopOfferDialog';
import { CallMeBackDialog } from './CallMeBackDialog';
import { ReviewOfferDialog } from './ReviewOfferDialog';
import { ReviewAndBidDialog } from './ReviewAndBidDialog';
import { PreviewImageDialog } from './PreviewImageDialog';
import { GetSandboxKeyDialog } from './getSandboxKeyDialog';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { OfferDuplicateDialog } from './OfferDuplicateDialog';
import { GPNewBidReceivedDialog } from './GPNewBidReceivedDialog';
import { GPBackupPositionDialog } from './GPBackupPositionDialog';
import { AddPhantomPlumBidDialog } from './AddPhantomPlumBidDialog';
import { MakeRevisionOfferDialog } from './MakeRevisionOfferDialog';
import { GPPlumBidJustStartedDialog } from './GPPlumBidJustStartedDialog';
import { AutoFillQuestionnaireDialog } from './AutoFillQuestionnaireDialog';
import { GPFinishDataHasChangedDialog } from './GPFinishDataHasChangedDialog';
import { AddDreIdDialog } from './AddDreIdDialog';
import { GPReservePriceMetChangedDialog } from './GPReservePriceMetChangedDialog';

export const DialogContainer = () => {
  const { dialogs, closeDialogByName } = useDialog();

  const renderDialog = (ComponentDialog, dialogName) => {
    const state = dialogs[dialogName];
    return state?.isOpen ? (
      <ComponentDialog
        {...state.props}
        isOpen={state.isOpen}
        onClose={state.props?.onClose || (() => closeDialogByName(dialogName))}
      />
    ) : null;
  };

  return (
    <>
      {renderDialog(VideoDialog, DIALOG.VIDEO)}
      {renderDialog(SurveyDialog, DIALOG.SURVEY)}
      {renderDialog(OkCancelDialog, DIALOG.OK_CANCEL)}
      {renderDialog(TopOfferDialog, DIALOG.TOP_OFFER)}
      {renderDialog(AddDreIdDialog, DIALOG.ADD_DRE_IDD)}
      {renderDialog(CallMeBackDialog, DIALOG.CALL_ME_BACK)}
      {renderDialog(ReviewOfferDialog, DIALOG.REVIEW_OFFER)}
      {renderDialog(PreviewImageDialog, DIALOG.PREVIEW_IMAGE)}
      {renderDialog(ReviewAndBidDialog, DIALOG.REVIEW_AND_BID)}
      {renderDialog(GetSandboxKeyDialog, DIALOG.GET_SANDBOX_KEY)}
      {renderDialog(ChangePasswordDialog, DIALOG.CHANGE_PASSWORD)}
      {renderDialog(OfferDuplicateDialog, DIALOG.OFFER_DUPLICATE)}
      {renderDialog(GPBackupPositionDialog, DIALOG.GP_BACKUP_POSITION)}
      {renderDialog(MakeRevisionOfferDialog, DIALOG.MAKE_REVISION_OFFER)}
      {renderDialog(AddPhantomPlumBidDialog, DIALOG.ADD_PHANTOM_PLUM_BID)}
      {renderDialog(AutoFillQuestionnaireDialog, DIALOG.AUTO_FILL_QUESTIONNAIRE)}
      {renderDialog(GPPlumBidJustStartedDialog, DIALOG.GP_PLUMBID_JUST_STARTED)}
      {renderDialog(GPNewBidReceivedDialog, DIALOG.GP_NEW_BID_RECEIVED_STARTED)}
      {renderDialog(GPFinishDataHasChangedDialog, DIALOG.GP_FINISH_DATA_HAS_CHANGED)}
      {renderDialog(GPReservePriceMetChangedDialog, DIALOG.GP_RESERVE_PRICE_MET_CHANGED)}
    </>
  );
};
