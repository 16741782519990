import React from 'react';
import { shape } from 'prop-types';

export const EditPencilNoBG = ({ style }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5909 10.5849C20.7763 10.3995 20.9964 10.2524 21.2387 10.152C21.481 10.0517 21.7407 10 22.003 10C22.2652 10 22.5249 10.0517 22.7672 10.152C23.0095 10.2524 23.2296 10.3995 23.4151 10.5849C23.6005 10.7704 23.7476 10.9905 23.848 11.2328C23.9483 11.4751 24 11.7348 24 11.997C24 12.2593 23.9483 12.519 23.848 12.7613C23.7476 13.0036 23.6005 13.2237 23.4151 13.4091L13.8833 22.9409L10 24L11.0591 20.1167L20.5909 10.5849Z"
      stroke={style?.fill || style?.stroke || '#731E6B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

EditPencilNoBG.propTypes = {
  style: shape({}),
};

EditPencilNoBG.defaultProps = {
  style: null,
};
