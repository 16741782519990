import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { formatAddressTwoLines, formatNumberToUSD } from 'utils/formatters';

import { PropertyThumbnail } from 'components';
import { theme } from 'utils/theme';

const styles = {
  root: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
};

export const PropertyThumbnailNameAddress = withStyles(styles)(({ property, isSandbox, withPrice }) => {
  const address = formatAddressTwoLines(property || {});
  const src =
    (property?.previewImage ? property?.previewImage : property?.mlsinfophotoSet?.[0]?.picture) || '/no_photo.png';

  return (
    <Grid container alignItems="center">
      <Grid item xs="auto">
        <Box mr={1} width={90}>
          <PropertyThumbnail aspectRatio={9 / 6} src={src} alt="Object preview" />
        </Box>
      </Grid>

      {isSandbox ? (
        <Grid item xs zeroMinWidth>
          <Typography variant="h4" noWrap style={{ textTransform: 'uppercase' }}>
            {property.title}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs>
          <Typography variant="h4">{address.line1}</Typography>
          <Typography variant="h4">{address.line2}</Typography>
          {withPrice && (
            <Typography variant="overline">
              {formatNumberToUSD(property?.listingPrice || 0, {
                hidePlusSign: true,
              })}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
});
