import { formatTemplate, formatNumberToUSD } from 'utils/formatters';

export const getQuestionsTree = (mcai, isNeedQ15) => {
  const Q15Group = {
    Q15: {
      Q15A1: 'Q15.1',
      Q15A2: 'Q12',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 17,
      branch: 'Q15-branch',
      skipTo: 'Q17',
    },
    'Q15.1': {
      'Q15.1A1': 'Q15.1.1',
      'Q15.1A2': 'Q15.1.2',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 18,
      branch: 'Q15-branch',
      skipTo: 'Q17',
    },
    'Q15.1.1': {
      'Q15.1.1A1': 'Q12',
      'Q15.1.1A2': 'Q12',
      'Q15.1.1A3': 'Q12',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q15.1.1A1') params.buyerContingencyPriority = 0.5;
        else if (answerId === 'Q15.1.1A2') params.buyerContingencyPriority = 1.5;
        else if (answerId === 'Q15.1.1A3') params.buyerContingencyPriority = 1;
        params.maxProgress = 23;

        return params;
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice * 1.025,
          text: 'With a “contingent on sale of another house”',
          answerId: 'Q15.1.1A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: '',
          answerId: 'Q15.1.1A2',
        }),
      ],
      format: {
        'Q15.1.1A1': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ15.1.1A1-1': formatNumberToUSD(+priorityParams.listingPrice * 1.025, {
              hidePlusSign: true,
            }),
          }),
        'Q15.1.1A2': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ15.1.1A2-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
          }),
      },
      progress: 19,
      branch: 'Q15-branch',
      skipTo: 'Q17',
    },
    'Q15.1.2': {
      'Q15.1.2A1': 'Q12',
      'Q15.1.2A2': 'Q12',
      'Q15.1.2A3': 'Q12',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q15.1.2A1') params.buyerContingencyPriority = 2.5;
        else if (answerId === 'Q15.1.2A2') params.buyerContingencyPriority = 3.5;
        else if (answerId === 'Q15.1.2A3') params.buyerContingencyPriority = 3;
        params.maxProgress = 23;

        return params;
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice * 1.075,
          text: 'With a “contingent on sale of another house”',
          answerId: 'Q15.1.2A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: '',
          answerId: 'Q15.1.2A2',
        }),
      ],
      format: {
        'Q15.1.2A1': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ15.1.2A1-1': formatNumberToUSD(+priorityParams.listingPrice * 1.075, {
              hidePlusSign: true,
            }),
          }),
        'Q15.1.2A2': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ15.1.2A2-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
          }),
      },
      progress: 19,
      branch: 'Q15-branch',
      skipTo: 'Q17',
    },
  };

  return {
    Q1: {
      Q1A1: 'Q2',
      Q1A2: 'Q2',
      Q1A3: 'Q2',
      Q1A4: 'Q2',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q1A1') params.downPaymentImportance = 1;
        else if (answerId === 'Q1A2') params.downPaymentImportance = 2;
        else if (answerId === 'Q1A3') params.downPaymentImportance = 3;
        else if (answerId === 'Q1A4') params.downPaymentImportance = 4;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          cashDown: {
            ...params.illegalAnswerCombination.cashDown,
            Q1: answerId === 'Q1A1',
          },
        };
        const price = +params.listingPrice;

        params.templateText['valueQ2-2'] =
          price -
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(0.2 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            (params.downPaymentImportance + 1) +
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(1 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            (params.downPaymentImportance + 1);

        params.templateText['valueQ3.1-2'] =
          price -
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(0.2 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            (params.downPaymentImportance + 2) +
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(1 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            (params.downPaymentImportance + 2);
        params.templateText['valueQ3.2-2'] =
          price -
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(0.2 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            params.downPaymentImportance +
          0.1 *
            Math.exp(-mcai / 100) *
            Math.log10(1 / 0.1) *
            price *
            Math.pow(price / (1.15 * price), 2) *
            params.downPaymentImportance;
        params.maxProgress = 23;

        return params;
      },

      progress: 1,
      branch: 1,
    },
    Q2: {
      Q2A1: 'Q3.1',
      Q2A2: 'Q3.2',
      Q2A3: 'Q4',
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'All Cash offer',
          text1: '',
          answerId: 'Q2A1',
        }),
        priorityParams => ({
          amount: priorityParams.templateText['valueQ2-2'],
          text: 'Offer with a 20% down payment',
          answerId: 'Q2A2',
        }),
      ],
      format: {
        text1: (text, priorityParams) => {
          return formatTemplate(text, {
            'valueQ2-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ2-2': formatNumberToUSD(priorityParams.templateText['valueQ2-2'], {
              hidePlusSign: true,
            }),
          });
        },
      },
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          cashDown: {
            ...params.illegalAnswerCombination.cashDown,
            'Q3.2': false,
          },
        };
        return params;
      },
      progress: 2,
      branch: 2,
    },
    'Q3.1': {
      'Q3.1A1': 'Q4',
      'Q3.1A2': 'Q4',
      'Q3.1A3': 'Q4',
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'All Cash offer',
          text1: '',
          answerId: 'Q3.1A1',
        }),
        priorityParams => ({
          amount: priorityParams.templateText['valueQ3.1-2'],
          text: 'Offer with a 20% down payment',
          answerId: 'Q3.1A3',
        }),
      ],
      format: {
        text1: (text, priorityParams) => {
          return formatTemplate(text, {
            'valueQ3.1-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ3.1-2': formatNumberToUSD(priorityParams.templateText['valueQ3.1-2'], {
              hidePlusSign: true,
            }),
          });
        },
      },
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        // if (answerId === 'Q3.1A1') params.downPaymentImportance = params.downPaymentImportance + 1.5;
        // else if (answerId === 'Q3.1A2') params.downPaymentImportance = params.downPaymentImportance + 0.5;
        // else if (answerId === 'Q3.1A3') params.downPaymentImportance = params.downPaymentImportance + 1;
        params.maxProgress = 23;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          cashDown: {
            ...params.illegalAnswerCombination.cashDown,
            'Q3.2': false,
          },
        };

        return params;
      },
      progress: 3,
      branch: 2,
    },
    'Q3.2': {
      'Q3.2A1': 'Q4',
      'Q3.2A2': 'Q4',
      'Q3.2A3': 'Q4',
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'All Cash offer',
          // text1: 'contingent on purchase of another house',

          answerId: 'Q3.2A1',
        }),
        priorityParams => ({
          amount: priorityParams.templateText['valueQ3.2-2'],
          text: 'Offer with a 20% down payment',
          answerId: 'Q3.2A3',
        }),
      ],
      format: {
        text1: (text, priorityParams) => {
          return formatTemplate(text, {
            'valueQ3.2-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ3.2-2': formatNumberToUSD(priorityParams.templateText['valueQ3.2-2'], {
              hidePlusSign: true,
            }),
          });
        },
      },
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        // if (answerId === 'Q3.2A1') params.downPaymentImportance = params.downPaymentImportance - 0.5;
        // else if (answerId === 'Q3.2A2') params.downPaymentImportance = params.downPaymentImportance - 1.5;
        // else if (answerId === 'Q3.2A3') params.downPaymentImportance = params.downPaymentImportance - 1;
        params.maxProgress = 23;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          cashDown: {
            ...params.illegalAnswerCombination.cashDown,
            'Q3.2': answerId === 'Q3.2A3',
          },
        };

        return params;
      },
      progress: 3,
      branch: 2,
    },
    Q4: {
      Q4A1: 'Q4.1',
      Q4A2: 'Q4.1',
      Q4A3: 'Q4.1',
      Q4A4: 'Q4.1',
      Q4A5: 'Q4.1',
      Q4A6: 'Q4.1',
      restrict: {
        Q4A6: {
          minValue: 1,
          maxValue: 150,
        },
      },
      calculatingAfterAnswer: (answerId, answerValue) => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q4A1') params.idealEscrowLength = 60;
        else if (answerId === 'Q4A2') params.idealEscrowLength = 45;
        else if (answerId === 'Q4A3') params.idealEscrowLength = 30;
        else if (answerId === 'Q4A4') params.idealEscrowLength = 15;
        else if (answerId === 'Q4A5') params.idealEscrowLength = 0;
        else if (answerId === 'Q4A6') params.idealEscrowLength = answerValue;
        params.maxProgress = 23;

        return params;
      },
      progress: 4,
      branch: 3,
    },
    'Q4.1': {
      'Q4.1A1': 'Q14',
      'Q4.1A2': 'Q14',
      'Q4.1A3': 'Q14',
      'Q4.1A4': 'Q14',
      'Q4.1A5': 'Q14',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q4.1A1') params.escrowPriority = 1;
        else if (answerId === 'Q4.1A2') params.escrowPriority = 2;
        else if (answerId === 'Q4.1A3') params.escrowPriority = 3;
        else if (answerId === 'Q4.1A4') params.escrowPriority = 4;
        else if (answerId === 'Q4.1A5') params.escrowPriority = 5;
        params.maxProgress = 23;

        return params;
      },
      progress: 5,
      branch: 4,
    },
    Q14: {
      Q14A1: 'Q14.1',
      Q14A2: 'Q5',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 6,
      branch: 11,
    },
    'Q14.1': {
      'Q14.1A1': 'Q14.1.1',
      'Q14.1A2': 'Q14.1.2',
      'Q14.1A3': 'Q14.1.3',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 7,
      branch: 11,
    },
    'Q14.1.1': {
      'Q14.1.1A1': 'Q5',
      'Q14.1.1A2': 'Q5',
      'Q14.1.1A3': 'Q5',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q14.1.1A1') params.sellerContingencyPriority = 1.5;
        else if (answerId === 'Q14.1.1A2') params.sellerContingencyPriority = 0.5;
        else if (answerId === 'Q14.1.1A3') params.sellerContingencyPriority = 1;
        params.maxProgress = 23;

        return params;
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: '',
          text1: 'With a “contingent on purchase of another house“',
          answerId: 'Q14.1.1A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * 1.025,
          text: ' ',
          text1: 'Without a contingency on purchase of another house',
          answerId: 'Q14.1.1A2',
        }),
      ],
      format: {
        'Q14.1.1A1': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.1A1-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
          }),
        'Q14.1.1A3': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.1A3-1': formatNumberToUSD(+priorityParams.listingPrice * 1.025, {
              hidePlusSign: true,
            }),
          }),
      },
      progress: 8,
      branch: 11,
    },
    'Q14.1.2': {
      'Q14.1.2A1': 'Q5',
      'Q14.1.2A2': 'Q5',
      'Q14.1.2A3': 'Q5',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q14.1.2A1') params.sellerContingencyPriority = 2.5;
        else if (answerId === 'Q14.1.2A2') params.sellerContingencyPriority = 1.5;
        else if (answerId === 'Q14.1.2A3') params.sellerContingencyPriority = 2;
        params.maxProgress = 23;

        return params;
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'With a “contingent on purchase of another house“',
          answerId: 'Q14.1.2A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * 1.05,
          text: 'Without a contingency on purchase of another house',
          answerId: 'Q14.1.2A2',
        }),
      ],
      format: {
        'Q14.1.2A1': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.2A1-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
          }),
        'Q14.1.2A3': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.2A3-1': formatNumberToUSD(+priorityParams.listingPrice * 1.05, {
              hidePlusSign: true,
            }),
          }),
      },
      progress: 8,
      branch: 11,
    },
    'Q14.1.3': {
      'Q14.1.3A1': 'Q5',
      'Q14.1.3A2': 'Q5',
      'Q14.1.3A3': 'Q5',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q14.1.3A1') params.sellerContingencyPriority = 3.5;
        else if (answerId === 'Q14.1.3A2') params.sellerContingencyPriority = 2.5;
        else if (answerId === 'Q14.1.3A3') params.sellerContingencyPriority = 3;
        params.maxProgress = 23;

        return params;
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'With a “contingent on purchase of another house“',
          answerId: 'Q14.1.3A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * 1.075,
          text: 'Without a contingency on purchase of another house',
          answerId: 'Q14.1.3A2',
        }),
      ],
      format: {
        'Q14.1.3A1': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.3A1-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
          }),
        'Q14.1.3A3': (text, priorityParams) =>
          formatTemplate(text, {
            'valueQ14.1.3A3-1': formatNumberToUSD(+priorityParams.listingPrice * 1.075, {
              hidePlusSign: true,
            }),
          }),
      },
      progress: 8,
      branch: 11,
    },
    Q5: {
      Q5A1: 'Q6',
      Q5A2: 'Q8',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q5A1') params.leaseback = 1;
        else if (answerId === 'Q5A2') params.leaseback = 0;
        params.maxProgress = 23;

        return params;
      },
      progress: 9,
      branch: 5,
    },
    Q6: {
      Q6A1: 'Q7',
      restrict: {
        Q6A1: {
          minValue: 1,
          maxValue: 29,
        },
      },
      calculatingAfterAnswer: (answerId, answerValue) => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q6A1') params.leaseBackLength = +answerValue;
        params.maxProgress = 23;

        return params;
      },
      progress: 10,
      branch: 5,
      type: 'input',
    },
    Q7: {
      Q7A1: 'Q8',
      Q7A2: 'Q8',
      Q7A3: 'Q8',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q7A1') params.leaseBackImportance = 1;
        else if (answerId === 'Q7A2') params.leaseBackImportance = 2;
        else if (answerId === 'Q7A3') params.leaseBackImportance = 3;
        params.maxProgress = 23;

        return params;
      },
      format: {
        text1: (text, priorityParams) => {
          const amount = (priorityParams.leaseBackLength * +priorityParams.listingPrice * 3) / 20000;

          return formatTemplate(text, {
            'valueQ7-1': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
        Q7A1: (text, priorityParams) => {
          const amount = ((priorityParams.leaseBackLength * +priorityParams.listingPrice * 3) / 20000) * 0.5;

          return formatTemplate(text, {
            'valueQ7A1-1': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
        Q7A3: (text, priorityParams) => {
          const amount = ((priorityParams.leaseBackLength * +priorityParams.listingPrice * 3) / 20000) * 1.5;

          return formatTemplate(text, {
            'valueQ7A3-1': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
      },
      progress: 11,
      branch: 5,
    },
    Q8: {
      Q8A1: 'Q9',
      Q8A2: 'Q9',
      Q8A3: 'Q9',
      Q8A4: 'Q9',
      Q8A5: 'Q9',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q8A1') params.inspectionContingency = 1;
        else if (answerId === 'Q8A2') params.inspectionContingency = 2;
        else if (answerId === 'Q8A3') params.inspectionContingency = 3;
        else if (answerId === 'Q8A4') params.inspectionContingency = 4;
        else if (answerId === 'Q8A5') params.inspectionContingency = 5;
        params.maxProgress = 23;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          inspectionContingency: {
            ...params.illegalAnswerCombination.inspectionContingency,
            Q8: answerId === 'Q8A1',
          },
        };

        return params;
      },
      progress: 12,
      branch: 7,
    },
    Q9: {
      Q9A1: 'Q10.1',
      Q9A2: 'Q11',
      Q9A3: answers => {
        const q8a1index = answers.findIndex(answer => answer.answerId === 'Q8A1');
        if (q8a1index >= 0) {
          return 'Q11';
        }

        return 'Q10.2';
      },
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'No inspection contingency',
          answerId: 'Q9A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * (1 + 0.01 * priorityParams.inspectionContingency),
          text: 'With an inspection contingency',
          answerId: 'Q9A3',
        }),
      ],
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q9A2') {
          params.inspectionPriority = params.inspectionContingency;
        }
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          inspectionContingency: {
            ...params.illegalAnswerCombination.inspectionContingency,
            'Q10.2': false,
          },
        };
        params.maxProgress = 23;

        return params;
      },
      format: {
        text1: (text, priorityParams) => {
          const amount = +priorityParams.listingPrice * (1 + 0.01 * priorityParams.inspectionContingency);

          return formatTemplate(text, {
            'valueQ9-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ9-2': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
      },
      progress: 13,
      branch: 7,
    },
    'Q10.1': {
      'Q10.1A1': 'Q11',
      'Q10.1A2': 'Q11',
      'Q10.1A3': 'Q11',
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'No inspection contingency',
          answerId: 'Q10.1A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * (1 + 0.01 * (priorityParams.inspectionContingency + 1)),
          text: 'With an inspection contingency',
          answerId: 'Q10.1A3',
        }),
      ],
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q10.1A1') params.inspectionPriority = params.inspectionContingency + 1.5;
        else if (answerId === 'Q10.1A2') params.inspectionPriority = params.inspectionContingency + 0.5;
        else if (answerId === 'Q10.1A3') params.inspectionPriority = params.inspectionContingency + 1;

        params.maxProgress = 23;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          inspectionContingency: {
            ...params.illegalAnswerCombination.inspectionContingency,
            'Q10.2': false,
          },
        };
        return params;
      },
      format: {
        text1: (text, priorityParams) => {
          const amount = +priorityParams.listingPrice * (1 + 0.01 * (priorityParams.inspectionContingency + 1));

          return formatTemplate(text, {
            'valueQ10.1-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ10.1-2': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
      },
      progress: 14,
      branch: 7,
    },
    'Q10.2': {
      'Q10.2A1': 'Q11',
      'Q10.2A2': 'Q11',
      'Q10.2A3': 'Q11',
      titleCard: [
        priorityParams => ({
          amount: +priorityParams.listingPrice,
          text: 'No inspection contingency',
          answerId: 'Q10.2A1',
        }),
        priorityParams => ({
          amount: +priorityParams.listingPrice * (1 + 0.01 * (priorityParams.inspectionContingency - 1)),
          text: 'With an inspection contingency',
          answerId: 'Q10.2A3',
        }),
      ],
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q10.2A1') params.inspectionPriority = params.inspectionContingency - 0.5;
        else if (answerId === 'Q10.2A2') params.inspectionPriority = params.inspectionContingency - 1.5;
        else if (answerId === 'Q10.2A3') params.inspectionPriority = params.inspectionContingency - 1;
        params.illegalAnswerCombination = {
          ...params.illegalAnswerCombination,
          inspectionContingency: {
            ...params.illegalAnswerCombination.inspectionContingency,
            'Q10.2': answerId === 'Q10.2A3',
          },
        };
        params.maxProgress = 23;

        return params;
      },
      format: {
        text1: (text, priorityParams) => {
          const amount = +priorityParams.listingPrice * (1 + 0.01 * (priorityParams.inspectionContingency - 1));

          return formatTemplate(text, {
            'valueQ10.2-1': formatNumberToUSD(+priorityParams.listingPrice, {
              hidePlusSign: true,
            }),
            'valueQ10.2-2': formatNumberToUSD(amount, {
              hidePlusSign: true,
            }),
          });
        },
      },
      progress: 14,
      branch: 7,
    },
    Q11: {
      Q11A1: 'Q13',
      Q11A2: 'Q13',
      Q11A3: 'Q13',
      Q11A4: 'Q13',
      Q11A5: 'Q13',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q11A1') params.loanContingencyPriority = 1;
        else if (answerId === 'Q11A2') params.loanContingencyPriority = 2;
        else if (answerId === 'Q11A3') params.loanContingencyPriority = 3;
        else if (answerId === 'Q11A4') params.loanContingencyPriority = 4;
        else if (answerId === 'Q11A5') params.loanContingencyPriority = 5;
        params.maxProgress = 23;

        return params;
      },
      progress: 15,
      branch: 9,
    },
    Q13: {
      Q13A1: isNeedQ15 ? 'Q15' : 'Q12',
      Q13A2: isNeedQ15 ? 'Q15' : 'Q12',
      Q13A3: isNeedQ15 ? 'Q15' : 'Q12',
      Q13A4: isNeedQ15 ? 'Q15' : 'Q12',
      Q13A5: isNeedQ15 ? 'Q15' : 'Q12',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q13A1') params.appraisalContingencyPriority = 1;
        else if (answerId === 'Q13A2') params.appraisalContingencyPriority = 2;
        else if (answerId === 'Q13A3') params.appraisalContingencyPriority = 3;
        else if (answerId === 'Q13A4') params.appraisalContingencyPriority = 4;
        else if (answerId === 'Q13A5') params.appraisalContingencyPriority = 5;
        params.maxProgress = 23;

        return params;
      },
      progress: 16,
      branch: 10,
    },
    ...(isNeedQ15 ? Q15Group : undefined),
    Q12: {
      Q12A1: 'Q17',
      Q12A2: 'Q12.1',
      tooltip:
        'A buyer may need to sell their property first, before having the ability to purchase your home. If this is the case, you may not be in control of the timing to close your escrow.',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 20,
      branch: 'Q12',
    },
    'Q12.1': {
      'Q12.1A1': 'Q17',
      'Q12.1A2': 'Q17',
      tooltip:
        'A buyer may need to sell their property first, before having the ability to purchase your home. If this is the case, you may not be in control of the timing to close your escrow.',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        params.maxProgress = 23;

        return params;
      },
      progress: 21,
      branch: 'Q12',
    },
    Q17: {
      Q17A1: 'Q18',
      Q17A2: 'Q18',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q17A1') {
          params.maxProgress = 23;
        } else if (answerId === 'Q17A2') {
          params.maxProgress = 23;
        }

        return params;
      },
      progress: 22,
      branch: 13,
    },
    Q18: {
      Q18A1: 'Q16',
      Q18A2: 'Q16',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q18A1') {
          params.maxProgress = 23;
        } else if (answerId === 'Q18A2') {
          params.maxProgress = 23;
        }
        return params;
      },
      progress: 23,
      branch: 15,
      type: 'checkbox',
    },
    Q16: {
      Q16A1: 'Finish',
      Q16A2: 'Finish',
      calculatingAfterAnswer: answerId => oldPriorityParams => {
        const params = { ...oldPriorityParams };
        if (answerId === 'Q16A1') {
          params.maxProgress = 23;
        } else if (answerId === 'Q16A2') {
          params.maxProgress = 23;
        }

        return params;
      },
      showOffersCard: true,
      progress: 23,
      branch: 14,
    },
  };
};
