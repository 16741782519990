import { useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useNotification } from './useNotification';
import { useAuthState } from 'service/store/authStore';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { GET_PLUMBID_BY_ID_QUERY } from 'service/graphql';

export const usePlumBidByIdLazy = (schema, variables) => {
  const [{ me }] = useAuthState();
  const showNotification = useNotification();

  const [plumbidByIdFetch, { data, loading, called, refetch }] = useLazyQuery(
    schema ? schema : GET_PLUMBID_BY_ID_QUERY,
    {
      ...(variables ? { variables } : null),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onError: error => {
        showNotification({ error });
      },
    }
  );

  const plumBid = useMemo(() => {
    return data?.plumbidById ? normalizePlumBid(data?.plumbidById, false, me) : undefined;
  }, [data?.plumbidById, me]);

  return {
    called,
    plumBid,
    plumbidByIdFetch,
    plumBidLoading: loading,
    plumBidRefetch: refetch,
  };
};
