import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Select, MenuItem, Box, Typography } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      lineHeight: '0.2em  ',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '16px',
    },
    '& .MuiSelect-selectMenu': {
      minHeight: 'auto',
    },
    '& .MuiInput-underline:before': {
      border: '0 !important',
    },
    '& .MuiInput-underline:after': {
      border: '0 !important',
      backgroundColor: 'red !important',
    },
    '& .MuiSelect-selectMenu:focus': {
      backgroundColor: `${theme.palette.background.white} !important`,
    },
    '& .MuiSelect-icon': {
      top: 0,
    },
  },
}));

export const SelectForYear = ({ date, localeUtils, onChange }) => {
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 5, 0);
  const toMonth = new Date(currentYear + 5, 0);
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    onChange(new Date(e.target.value, date.getMonth()));
  };

  return (
    <Box className={`DayPicker-Caption ${classes.root}`}>
      <Select style={{ float: 'left' }} name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="h4" align="center" style={{ width: '60%', float: 'left' }}>
        {months[date.getMonth()]}
      </Typography>
    </Box>
  );
};
