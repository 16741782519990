import gql from 'graphql-tag';

export const SAVE_WIZARD_MUTATION = gql`
  mutation SaveWizardStep($input: SaveWizardStepInput!) {
    saveWizardStep(input: $input) {
      success
      errors
    }
  }
`;
