import { Box, Typography } from '@material-ui/core';

import { LinkButton } from 'legos';
import { theme } from 'utils/theme';

export const ShowHistory = ({ setShowHistory }) => {
  return (
    <Box my={2} p={4} borderRadius="15px" bgcolor={theme.palette.background.card}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <LinkButton
          color="link"
          onClick={() => setShowHistory(true)}
          style={{
            marginRight: 8,
          }}
        >
          <Typography variant="h2" style={{ color: theme.palette.primary.main }}>
            Show Bid History
          </Typography>
        </LinkButton>
      </Box>
    </Box>
  );
};
