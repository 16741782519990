import gql from 'graphql-tag';

export const DEACTIVATE_USER_MUTATION = gql`
  mutation DeactivateUser($input: DeactivateUserInput!) {
    adminDeactivateUser(input: $input) {
      success
      errors
    }
  }
`;
