import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import React, { useState, useCallback, useMemo } from 'react';

import { theme } from 'utils/theme';
import { SellerItem } from './SellerItem';
import { SELLER_ONBOARDING } from 'routes';
import { LinkButton, WarningBlock, PBox } from 'legos';
import { AddSellerForm } from './AddSellerForm';
import { PLUMBID_STATUS, DIALOG, ROLES, NEW_PARTY_STATUS } from 'constant';
import { getParticipantField, getSellerPartyWarningMessage, formatToTitleCase } from 'utils';
import { useNotification, useDialog, useLazyQueryPromise, useResponsive } from 'utils/hooks';
import {
  SEND_EMAIL_MUTATION,
  CREATE_INVITE_MUTATION,
  GET_INVITE_EMAIL_TEXT_QUERY,
  REMOVE_PARTICIPANT_INVITE_MUTATION,
} from 'service/graphql';

export const SellersParty = ({ plumBid, loading }) => {
  const [addOrEditSeller, setAddOrEditSeller] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  const history = useHistory();
  const isMeListingAgent = plumBid.myInfoInPlumBid.role === ROLES.LISTING_AGENT;
  const [getInviteEmailText] = useLazyQueryPromise(GET_INVITE_EMAIL_TEXT_QUERY);

  const responsive = useResponsive();
  const showNotification = useNotification();

  const [sendEmailMutation] = useMutation(SEND_EMAIL_MUTATION);
  const [createInvitedMutation] = useMutation(CREATE_INVITE_MUTATION);
  const [removeParticipantInviteMutation] = useMutation(REMOVE_PARTICIPANT_INVITE_MUTATION);

  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: changePropsOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const openReviewPriorities = useCallback(
    participant => {
      if (isMeListingAgent) {
        history.push({
          pathname: SELLER_ONBOARDING,
          search: `plumbid=${plumBid.id}`,
          state: {
            seller: participant,
          },
        });
      } else {
        history.push({
          pathname: SELLER_ONBOARDING,
          search: `plumbid=${plumBid.id}`,
        });
      }
    },
    [history, isMeListingAgent, plumBid?.id]
  );

  const sendEmailAction = useCallback(
    async (token, email, fullName) => {
      let inviteEmailText = '';
      try {
        const data = await getInviteEmailText({
          plumbidId: plumBid?.id,
        });

        inviteEmailText = data.inviteEmailText[1];
      } catch (err) {}

      const { data } = await sendEmailMutation({
        variables: {
          input: {
            token,
            email,
            text:
              fullName === ''
                ? `Hello and welcome to plumBid! \n\n ${inviteEmailText}`
                : `Hello ${formatToTitleCase(fullName.trim() || '')}, \n\n ${inviteEmailText}`,
          },
        },
      });

      if (data.sendEmail.error) {
        showNotification({
          error: data.sendEmail.errors,
        });
      }
    },
    [getInviteEmailText, plumBid?.id, sendEmailMutation, showNotification]
  );

  const removeSeller = useCallback(
    participantId => {
      openOkCancelDialog({
        title: 'Remove seller',
        text: 'Are you sure you want to remove seller?',
        onOk: () => {
          changePropsOkCancelDialog({ loading: true });
          removeParticipantInviteMutation({
            variables: {
              input: {
                participantId,
              },
            },
          })
            .then(res => {
              if (res?.data?.removeParticipantInvite?.errors) {
                showNotification({ error: res.data.removeParticipantInvite.errors });
              }
            })
            .catch(error => showNotification({ error }))
            .finally(() => {
              changePropsOkCancelDialog({ loading: false });
              closeOkCancelDialog();
            });
        },
      });
    },
    [
      showNotification,
      openOkCancelDialog,
      closeOkCancelDialog,
      changePropsOkCancelDialog,
      removeParticipantInviteMutation,
    ]
  );

  const sendInvitation = useCallback(
    async values => {
      const {
        data: { createInvite },
      } = await createInvitedMutation({
        variables: {
          input: {
            email: values.email,
            role: ROLES.SELLER,
            fullName: values.fullName,
            plumbidId: plumBid.id,
            plumbidPartyId: plumBid.sellerParty.id,
          },
        },
      });

      if (createInvite.success) {
        if (values.email) {
          await sendEmailAction(
            createInvite.invite.token,
            values.email,
            values.fullName === '' ? createInvite.invite.fullName : values.fullName
          );
        }
      } else if (createInvite.errors) {
        if (
          createInvite.errors.includes(
            'Participant with such email has been invited for specified plumBid already. Please check if email is correct.'
          ) ||
          createInvite.errors.includes(`Listing Agent can't invite himself.`)
        ) {
          return 'Participant already take a part in this plumBid.';
        } else {
          showNotification({ error: createInvite.errors });
        }
      }
      return createInvite;
    },
    [createInvitedMutation, plumBid?.id, plumBid?.sellerParty?.id, sendEmailAction, showNotification]
  );

  const handleUpdateSeller = useCallback(
    async newSeller => {
      const sellers =
        plumBid?.sellerParty?.participantSet?.map(seller => ({
          id: seller.id,
          email: getParticipantField(seller, 'email'),
        })) || [];
      if (newSeller.id) {
        const index = sellers.findIndex(({ id }) => id === newSeller.id);
        if (index > -1) {
          sellers.splice(index, 1);
        }
      }
      sellers.push(newSeller);

      setActionLoading(true);
      const updateAction = sendInvitation(newSeller);

      return updateAction
        .catch(error =>
          showNotification({
            error,
          })
        )
        .finally(setActionLoading(false));
    },
    [sendInvitation, showNotification, plumBid?.sellerParty?.participantSet]
  );

  const resetChange = useCallback(() => setAddOrEditSeller(null), []);

  const handleClickAddSeller = useCallback(() => {
    setAddOrEditSeller(-1);
  }, []);

  const canAddSeller =
    isMeListingAgent && (plumBid.status === PLUMBID_STATUS.UPCOMING || plumBid.status === PLUMBID_STATUS.INCOMPLETE);

  const partyStatusesProps = useMemo(() => getSellerPartyWarningMessage(plumBid.sellerParty), [plumBid.sellerParty]);

  const disabledActionButton =
    loading ||
    actionLoading ||
    addOrEditSeller !== null ||
    plumBid.status === PLUMBID_STATUS.FINISHED ||
    plumBid.status === PLUMBID_STATUS.CANCELED;

  const showActionButtons = isMeListingAgent && plumBid.status === PLUMBID_STATUS.UPCOMING;
  const showPartyStatuses =
    plumBid.myInfoInPlumBid.role !== ROLES.BUYER &&
    plumBid.myInfoInPlumBid.role !== ROLES.BUYER_AGENT &&
    plumBid.sellerParty.status !== NEW_PARTY_STATUS.COMPLETED;

  return (
    <PBox width="100%">
      <PBox
        width="100%"
        display="flex"
        justifyContent="space-between"
        height={responsive.tabletScreen ? '60px' : '30px'}
        flexDirection={responsive.tabletScreen ? 'column' : 'row'}
        alignItems={responsive.tabletScreen ? 'flex-start' : 'center'}
      >
        <Typography variant="h3" style={{ fontWeight: 700, verticalAlign: 'top' }}>
          Sellers
        </Typography>
        <PBox display="center" alignItems="center" ml={1}>
          {canAddSeller && (
            <LinkButton
              title="Add Seller"
              icon="CirclePlus"
              iconSize={13}
              color="link"
              disabled={disabledActionButton}
              style={{ fontWeight: 500, marginRight: 8 }}
              onClick={handleClickAddSeller}
            />
          )}
          {isMeListingAgent && (
            <LinkButton
              color="link"
              iconSize={13}
              icon="ViewEye"
              title="Review Onboarding"
              style={{ fontWeight: 500 }}
              onClick={() => openReviewPriorities(plumBid.sellerParty.participantSet[0])}
            />
          )}
        </PBox>
      </PBox>
      <PBox mt={4} mb={1} height="30px" width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" style={{ color: theme.palette.text.grey }}>
          Party
        </Typography>

        <PBox width="auto">
          {plumBid.sellerParty.status === NEW_PARTY_STATUS.COMPLETED ? (
            <WarningBlock variant="success" text="Onboarding complete" />
          ) : plumBid.myInfoInPlumBid.role === ROLES.BUYER || plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT ? (
            <WarningBlock variant="warning" text="Waiting for seller preferences" />
          ) : null}
        </PBox>
      </PBox>
      {showPartyStatuses && (
        <PBox>
          {partyStatusesProps.map(prop => (
            <PBox key={prop.text} mt={0.7}>
              <WarningBlock variant={prop.variant} text={prop.text} />
            </PBox>
          ))}
        </PBox>
      )}
      <PBox mt={3}>
        {plumBid?.sellerParty?.participantSet?.map(seller => {
          return (
            <PBox
              display="flex"
              key={seller.id}
              alignItems="center"
              justifyContent="space-between"
              mt={addOrEditSeller === seller.id ? 1 : 2}
            >
              {addOrEditSeller === seller.id ? (
                <AddSellerForm
                  seller={seller}
                  loading={actionLoading}
                  resetChange={resetChange}
                  addSeller={handleUpdateSeller}
                  setAddOrEditSeller={setAddOrEditSeller}
                />
              ) : (
                <SellerItem
                  seller={seller}
                  plumBid={plumBid}
                  removeSeller={removeSeller}
                  showActionButtons={showActionButtons}
                  disabledActionButton={disabledActionButton}
                />
              )}
            </PBox>
          );
        })}
        <PBox display="flex" mt={1} alignItems="center" justifyContent="space-between">
          {addOrEditSeller === -1 ? (
            <AddSellerForm
              addSeller={handleUpdateSeller}
              resetChange={resetChange}
              loading={actionLoading}
              setAddOrEditSeller={setAddOrEditSeller}
            />
          ) : null}
        </PBox>
      </PBox>
    </PBox>
  );
};
