import React from 'react';

export const ArrowRight = ({ style }) => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none">
    <path
      d="M1.5 8.5L7.5 1.5L13.5 8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
