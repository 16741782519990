import { useState, useCallback } from 'react';
import { Tooltip, ButtonBase, Typography, Zoom, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useNotification } from 'utils/hooks';
import { Icons, PBox } from 'legos';
import { theme } from 'utils/theme';

const useStyles = makeStyles(theme => ({
  tooltip: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 12,
  },
}));

export const CopyToClipboard = ({
  style,
  text,
  label,
  title = 'Copy link',
  icon = 'Copy',
  color = 'link',
  disabled,
  getText,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const showNotification = useNotification();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = useCallback(() => {
    if (!disabled) {
      setOpen(true);
    }
  }, [disabled]);

  const handleClick = useCallback(async () => {
    const _text = getText ? await getText() : text;

    handleTooltipOpen();
    try {
      await window.navigator.clipboard.writeText(_text);
    } catch (error) {
      showNotification(error);
    } finally {
      setTimeout(handleTooltipClose, 1000);
    }
  }, [getText, handleTooltipOpen, showNotification, text]);

  const _color = disabled || loading ? theme.palette.text.disabled : theme.palette.text[color] || color;

  const Icon = Icons[icon];

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        open={open}
        placement="top"
        classes={classes}
        title="Link copied"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Zoom}
        onClose={handleTooltipClose}
        TransitionProps={{ timeout: 300 }}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <span>
          <ButtonBase
            TouchRippleProps={{
              style: {
                color: theme.palette.purple.ripple,
              },
            }}
            icon={icon}
            style={{ padding: '6px', ...style }}
            color={_color}
            title={title}
            disabled={disabled}
            onClick={handleClick}
          >
            {loading && (
              <CircularProgress
                style={{
                  backgroundColor: 'transparent',
                  color: 'inherit',
                  opacity: 0.3,
                  position: 'absolute',
                  top: 'calc(50% - 10px)',
                  left: 'calc(50% - 10px)',
                }}
                size={20}
              />
            )}
            <Icon style={{ stroke: _color }} />
            {label && (
              <PBox pl={0.5}>
                <Typography component="span" noWrap style={{ color: _color }}>
                  {label}
                </Typography>
              </PBox>
            )}
          </ButtonBase>
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};
