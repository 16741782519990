import { useState } from 'react';

import { Box, Grid, Typography, Collapse } from '@material-ui/core';

import AgentBenefitsPng from 'assets/images/AgentBenefits.jpg';
import { useSettingsState, useAuthState } from 'service/store';

import { LinkButton } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AgentBenefits = () => {
  const { mdScreen, tabletScreen } = useResponsive();
  const [{ me }] = useAuthState();

  const [checked, setChecked] = useState(false);
  const [{ isOpenDrawer }, dispatchSettings] = useSettingsState();

  const handleOpenDrawer = () => {
    if (!me) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: {
          isOpenDrawer: !isOpenDrawer,
          drawerScreen: 'SignInScreen',
        },
      });
    }
  };
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <Grid
      container
      style={{
        position: 'relative',
        padding: '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={
          mdScreen ? { padding: 14 } : { paddingLeft: tabletScreen ? 40 : 88 }
        }
      >
        <img
          style={{ width: '100%', borderRadius: '38px' }}
          src={AgentBenefitsPng}
          alt=""
        />
      </Grid>
      <Grid item container xs={12} md={6} justify="center">
        <Box px={{ xs: 3, md: 12 }} pt={tabletScreen ? 0 : 8}>
          <Typography
            variant="h1"
            style={{ fontWeight: 700, paddingBottom: 32 }}
          >
            Agent benefits
          </Typography>

          <ul style={{ margin: 0, marginLeft: 16, padding: 0 }}>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Proven higher sales price for your seller
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                Better terms
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                No back and forth, comparing count less offers, or generating
                counters
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                }}
              >
                All online in a matter of hours - not days
              </Typography>
            </li>
          </ul>

          <Collapse in={checked} disableStrictModeCompat>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
              }}
            >
              Tired of drawn-out, back and forth negotiations, comparing
              competing offers and days of generating countless counter-offers?
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
              }}
            >
              Don’t worry, <b>plumBid</b> does it automatically online through a
              powerful, proprietary patent-pending software technology bidding
              platform.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
              }}
            >
              Sellers articulate the terms that are most important to them, such
              as: size of cash down payment, length of escrow, contingencies,
              etc. Buyers then see and compete against each other dynamically:
              by creating and submitting more competitive bids, based on those
              preferences. The buyer that most closely matches the sellers
              parameters - and with the ultimate highest price, secures the
              house and you open escrow.
            </Typography>
            {me ? (
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                }}
              >
                Ready to register your multiple offer with plumBid?
              </Typography>
            ) : (
              <LinkButton
                onClick={handleOpenDrawer}
                title="Ready to register your multiple offer with plumBid?"
                style={{
                  fontSize: 18,
                  paddingLeft: 0,
                  paddingTop: 0,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  textDecorationColor: theme.palette.primary.main,
                }}
              />
            )}
          </Collapse>
          <LinkButton
            onClick={handleChange}
            style={{
              fontSize: 18,
              fontWeight: 700,
              color: theme.palette.primary.main,
              textDecorationColor: theme.palette.primary.main,
              marginTop: '30px',
            }}
          >
            {checked ? 'Show less' : 'Show more'}
          </LinkButton>
        </Box>
      </Grid>
    </Grid>
  );
};
