import gql from 'graphql-tag';

import { AdminPlumbidType } from '../types';

export const ON_NEW_ADMIN_PLUMBID_EVENT_SUBSCRIPTION = gql`
  subscription OnNewAdminPlumBidEvent($room: String) {
    onNewAdminPlumbidEvent(room: $room) {
      plumbid {
        ${AdminPlumbidType}
      }
    }
  }
`;
