import { TERM_OF_USE } from 'routes';

export const getQuestionsTree = () => {
  return {
    Q1: {
      number: '1',
      Q1A1: 'Q2',
      Q1A2: 'Q2',
      progressQ1A1: 25,
      progressQ1A2: 25,
      progress: 1,
      branch: 1,
      type: 'reviewOffer',
    },
    Q2: {
      number: '2',
      Q2A1: 'Q2.1',
      Q2A2: 'Q2.1',
      Q2A3: 'Q2.1',
      Q2A4: 'Q3',
      progressQ2A1: 13,
      progressQ2A2: 13,
      progressQ2A3: 13,
      progressQ2A4: 25,
      progress: 2,
      branch: 2,
    },
    'Q2.1': {
      number: '2.1',
      'Q2.1A1': 'Q3',
      'Q2.1A2': 'Q3',
      'progressQ2.1A1': 12,
      'progressQ2.1A2': 12,
      progress: 3,
      branch: 2,
    },
    Q3: {
      number: '3',
      Q3A1: 'Q3.1',
      Q3A2: 'Q4',
      progressQ3A1: 5,
      progressQ3A2: 25,
      progress: 4,
      branch: 4,
    },
    'Q3.1': {
      number: '3.1',
      'Q3.1A1': 'Q3.2',
      'Q3.1A2': 'Q4',
      'progressQ3.1A1': 4,
      'progressQ3.1A2': 20,
      progress: 5,
      branch: 4,
    },
    'Q3.2': {
      number: '3.2',
      'Q3.2A1': 'Q3.3',
      'Q3.2A2': 'Q4',
      'progressQ3.2A1': 4,
      'progressQ3.2A2': 16,
      progress: 6,
      branch: 4,
    },
    'Q3.3': {
      number: '3.3',
      'Q3.3A1': 'Q3.5',
      'Q3.3A2': 'Q3.4',
      'progressQ3.3A1': 8,
      'progressQ3.3A2': 4,
      progress: 5,
      branch: 4,
    },
    'Q3.4': {
      number: '3.4',
      'Q3.4A1': 'Q3.5',
      'progressQ3.4A1': 4,
      progress: 6,
      branch: 4,
      type: 'dateInput',
    },
    'Q3.5': {
      number: '3.5',
      'Q3.5A1': 'Q4',
      'progressQ3.5A1': 4,
      progress: 7,
      branch: 4,
      type: 'dateInput',
    },

    Q4: {
      number: '4',
      Q4A1: 'Q5',
      Q4A2: 'Q5',
      progressQ4A1: 15,
      progressQ4A2: 0,
      linkTermsAndConditions: TERM_OF_USE,
      progress: 8,
      branch: 5,
    },
    Q5: {
      number: '5',
      Q5A1: 'Finish',
      Q5A2: null,
      progressQ5A1: 10,
      progressQ5A2: 0,
      progress: 10,
      branch: 6,
      type: 'checkbox',
    },
  };
};
