import {
  ROLES,
  LOAN_MAX,
  LOAN_MIN,
  ESCROW_MAX,
  ESCROW_MIN,
  APPRAISAL_MAX,
  APPRAISAL_MIN,
  INSPECTION_MAX,
  INSPECTION_MIN,
  CASH_DOWN_IMPROVE_MODE,
} from 'constant';
import { pick } from 'lodash';

const convertOfferSettingsFieldsToIncentive = offerSettings => {
  const price = +offerSettings.offerPrice || 1000;
  const amountDown =
    +offerSettings.cashdownAmount === 0
      ? offerSettings.isVaLoan || offerSettings.isFhaLoan
        ? 0
        : 1000
      : +offerSettings.cashdownAmount;

  const percentageDown = (amountDown * 100) / price;

  return {
    price,
    amountDown,
    percentageDown: Number.isNaN(percentageDown) || !Number.isFinite(percentageDown) ? 0 : percentageDown,
    escrowLength: offerSettings.escrowLength,
    inspectionContingency: offerSettings.inspectionContingency,
    inspectionContingencyLength: offerSettings.inspectionContingencyLength,
    loanContingency: offerSettings.loanContingency,
    loanContingencyLength: offerSettings.loanContingencyLength,
    appraisalContingency: offerSettings.appraisalContingency,
    appraisalContingencyLength: offerSettings.appraisalContingencyLength,
    offerLeaseBack: offerSettings.offerLeaseBack,
  };
};

const keyFieldsToStore = [
  'price',
  CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN,
  CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
  'cashDownImproveMode',
  'escrowLength',
  'inspectionContingency',
  'inspectionContingencyLength',
  'loanContingency',
  'loanContingencyLength',
  'appraisalContingency',
  'appraisalContingencyLength',
  'offerLeaseBack',
  'isAutoCalculated',
];

export const refreshIncentiveInStorage = (newIncentive, isSandbox) => {
  const getFieldsToStore = pick(newIncentive, keyFieldsToStore);
  const arr = Object.keys(getFieldsToStore).map(key => getFieldsToStore[key]);
  const key = `${isSandbox ? 'sbpb' : 'pb'}${newIncentive.plumbidId}`;

  localStorage.setItem(key, JSON.stringify(arr));
};

const refreshNewIncentiveFromStorage = (newIncentive, plumBidId, isSandbox) => {
  const key = `${isSandbox ? 'sbpb' : 'pb'}${plumBidId}`;
  const data = localStorage.getItem(key);
  if (data) {
    try {
      const parsedData = JSON.parse(data).reduce(
        (accumulator, value, index) => Object.assign(accumulator, { [keyFieldsToStore[index]]: value }),
        {}
      );
      // isAutoCalculated
      if (parsedData.isAutoCalculated !== undefined) {
        newIncentive.isAutoCalculated = !!parsedData.isAutoCalculated;
      }

      // price
      if (parsedData.price && Number(parsedData.price)) {
        newIncentive.price = Number(parsedData.price);
      }

      // cashDownImproveMode
      if (
        parsedData.cashDownImproveMode &&
        (parsedData.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN ||
          parsedData.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN)
      ) {
        newIncentive.cashDownImproveMode = parsedData.cashDownImproveMode;
      }

      // amountCashDown
      if (parsedData[CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN] && Number(parsedData[CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN])) {
        newIncentive[CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN] = Number(parsedData[CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN]);
      }

      // percentCashDown
      if (
        parsedData[CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN] &&
        Number(parsedData[CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN])
      ) {
        newIncentive[CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN] = Number(
          parsedData[CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN]
        );
      }

      // escrowLength
      if (parsedData.escrowLength && Number(parsedData.escrowLength)) {
        const escrowLength = Number(parsedData.escrowLength);
        if (escrowLength >= ESCROW_MIN && escrowLength <= ESCROW_MAX) {
          newIncentive.escrowLength = escrowLength;
        }
      }

      // inspectionContingency
      if (parsedData.inspectionContingency !== undefined) {
        newIncentive.inspectionContingency = !!parsedData.inspectionContingency;
      }
      if (parsedData.inspectionContingencyLength && Number(parsedData.inspectionContingencyLength)) {
        const inspectionContingencyLength = Number(parsedData.inspectionContingencyLength);
        if (inspectionContingencyLength >= INSPECTION_MIN && inspectionContingencyLength <= INSPECTION_MAX) {
          newIncentive.inspectionContingencyLength = inspectionContingencyLength;
        }
      }
      // loanContingency
      if (parsedData.loanContingency !== undefined) {
        newIncentive.loanContingency = !!parsedData.loanContingency;
      }
      if (parsedData.loanContingencyLength && Number(parsedData.loanContingencyLength)) {
        const loanContingencyLength = Number(parsedData.loanContingencyLength);
        if (loanContingencyLength >= LOAN_MIN && loanContingencyLength <= LOAN_MAX) {
          newIncentive.loanContingencyLength = loanContingencyLength;
        }
      }

      // appraisalContingency
      if (parsedData.appraisalContingency !== undefined) {
        newIncentive.appraisalContingency = !!parsedData.appraisalContingency;
      }
      if (parsedData.appraisalContingencyLength && Number(parsedData.appraisalContingencyLength)) {
        const appraisalContingencyLength = Number(parsedData.appraisalContingencyLength);
        if (appraisalContingencyLength >= APPRAISAL_MIN && appraisalContingencyLength <= APPRAISAL_MAX) {
          newIncentive.appraisalContingencyLength = appraisalContingencyLength;
        }
      }

      // offerLeaseBack
      if (parsedData.offerLeaseBack !== undefined) {
        newIncentive.offerLeaseBack = !!parsedData.offerLeaseBack;
      }
    } catch (err) {
      localStorage.removeItem(key);
    }
  }

  return newIncentive;
};

export const getNewIncentive = (plumBid, isSandbox, sandboxKey, me, incentive) => {
  let newIncentive = {};

  const myLastBidAmount = isSandbox
    ? plumBid?.sbbidSet?.length
      ? plumBid?.sbbidSet.reduce(
          (prev, bid) => (bid.owner?.user?.key === sandboxKey && prev.plumBidAmount < bid.plumBidAmount ? bid : prev),
          { plumBidAmount: -1 }
        )
      : null
    : plumBid.myInfoInPlumBid.myLastBid
    ? plumBid.myInfoInPlumBid.myLastBid
    : null;

  if (myLastBidAmount && myLastBidAmount.plumBidAmount !== -1) {
    newIncentive = {
      ...incentive,
      ...pick(myLastBidAmount, [
        'price',
        CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN,
        CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
        'escrowLength',
        'inspectionContingency',
        'inspectionContingencyLength',
        'loanContingency',
        'loanContingencyLength',
        'appraisalContingency',
        'appraisalContingencyLength',
        'offerLeaseBack',
        'sellerContingency',
        'buyerWarranty',
        'buyerContingency',
      ]),
    };
  } else if (
    !isSandbox &&
    plumBid.myInfoInPlumBid?.role === ROLES.BUYER &&
    plumBid.myInfoInPlumBid?.myParty?.offersettings
  ) {
    newIncentive = {
      ...incentive,
      ...convertOfferSettingsFieldsToIncentive(plumBid.myInfoInPlumBid.myParty.offersettings),
    };
  } else {
    newIncentive = {
      ...incentive,
      price: plumBid?.listPrice || 0,
      amountDown: plumBid?.listPrice || 0,
      percentageDown: 20.0,
      escrowLength: ESCROW_MIN + 20,
      inspectionContingency: false,
      inspectionContingencyLength: INSPECTION_MIN,
      loanContingency: false,
      loanContingencyLength: LOAN_MIN,
      appraisalContingency: false,
      appraisalContingencyLength: APPRAISAL_MIN,
      offerLeaseBack: false,
      sellerContingency: false,
      buyerWarranty: false,
      buyerContingency: false,
    };
  }

  newIncentive.plumbidId = plumBid.id;

  newIncentive = refreshNewIncentiveFromStorage(newIncentive, plumBid?.id, isSandbox);
  newIncentive.vaLoan =
    plumBid.myInfoInPlumBid?.myParty?.offersettings?.isVaLoan ||
    plumBid.myInfoInPlumBid?.myParty?.offersettings?.isFhaLoan ||
    false;

  return newIncentive;
};

export const parsingErrors = errors => {
  const parsedErrors = {};
  if (Array.isArray(errors)) {
    const err = errors.reduce(
      (prev, err) => {
        if (err.includes('Wrong amount down.')) {
          prev.cashDownErrors.push(err);
        } else {
          prev.globalErrors.push(err);
        }
        return prev;
      },
      { cashDownErrors: [], globalErrors: [] }
    );

    if (err.globalErrors.length) {
      parsedErrors.globalErrors = err.globalErrors.join(', ');
    }

    if (err.cashDownErrors.length) {
      parsedErrors.cashDownErrors = err.cashDownErrors.join(', ');
    }
  }

  return parsedErrors;
};
