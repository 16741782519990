import gql from 'graphql-tag';

import { ChatMessageType } from '../types';

export const ADMIN_CHAT_HISTORY_QUERY = gql`
  query AdminChatHistory($chatName: String) {
    adminChatHistory(chatName: $chatName) {
      edges {
        node {
          ${ChatMessageType}
        }
      }
    }
  }
`;
