import React from 'react';

export const Magnifier = ({ style }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" style={style}>
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C9.77498 15.9996 11.4988 15.4054 12.897 14.312L17.293 18.708L18.707 17.294L14.311 12.898C15.405 11.4997 15.9996 9.77544 16 8C16 3.589 12.411 0 8 0ZM12 9H9V12H7V9H4V7H7V4H9V7H12V9Z"
      fill={style?.fill ?? '#731E6B'}
    />
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C9.77498 15.9996 11.4988 15.4054 12.897 14.312L17.293 18.708L18.707 17.294L14.311 12.898C15.405 11.4997 15.9996 9.77544 16 8C16 3.589 12.411 0 8 0ZM12 9H9V12H7V9H4V7H7V4H9V7H12V9Z"
      fill={style?.fill ?? '#731E6B'}
    />
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C9.77498 15.9996 11.4988 15.4054 12.897 14.312L17.293 18.708L18.707 17.294L14.311 12.898C15.405 11.4997 15.9996 9.77544 16 8C16 3.589 12.411 0 8 0ZM12 9H9V12H7V9H4V7H7V4H9V7H12V9Z"
      fill={style?.fill ?? '#731E6B'}
    />
  </svg>
);
