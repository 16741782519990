import { throttle } from 'lodash';

import { apolloClient, subscriptionClient } from 'service/graphql/apolloClient';
import { refreshTokenRequest } from './refreshTokenRequest';
import { LocalStorage } from 'utils/localStorage';

export const setSession = ({ token, refreshToken }) => {
  LocalStorage.setFrontToken(token);
  LocalStorage.setRefreshToken(refreshToken);
};

export const refreshToken = async refreshToken => refreshTokenRequest(refreshToken);

const refreshTokenTrottled = throttle(refreshToken, 1000, { leading: true });

export const renewToken = async () => {
  try {
    const refresh = LocalStorage.getRefreshToken();
    if (!refresh) {
      throw new Error('Error refresh token');
    }

    const result = await refreshTokenTrottled(refresh);

    if (!result?.data?.refreshToken?.token || !result?.data?.refreshToken?.refreshToken) {
      throw new Error('Error refresh token');
    }

    const { token, refreshToken } = result.data.refreshToken;

    LocalStorage.setFrontToken(token);
    LocalStorage.setRefreshToken(refreshToken);

    return token;
  } catch (err) {
    removeSession();
    window.location = window.location.origin;
  } finally {
    subscriptionClient.close(false, false);
    apolloClient.refetchQueries({
      include: 'active',
    });
  }
};

export const removeSession = async () => {
  apolloClient.cache.reset();
  localStorage.clear();
  subscriptionClient?.unsubscribeAll();
  subscriptionClient?.close();
};
