import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { usePageLoading, useChats, useSelectChat, useDebounce } from 'utils/hooks';
import { SearchInput, PBox } from 'legos';
import { ChatMessageHeader } from './ChatMessageHeader';
import { ChatWithAdmin } from './ChatWithAdmin';
import { ChatItem } from './ChatItem';
import { CHAT_STATUS } from 'constant';
import { useAuthState } from 'service/store/authStore';
import { theme } from 'utils/theme';

export const ChatsContainer = () => {
  const [{ me }] = useAuthState();

  const { selectedChat } = useSelectChat();
  const [searchText, setSearchText] = useState('');
  const searchDebounce = useDebounce(searchText, 500);

  const { chats, refetchChats, chatsLoading } = useChats(
    { searchName: searchDebounce },
    {
      withSubscription: true,
    }
  );

  useEffect(() => {
    refetchChats();
  }, [searchDebounce, refetchChats]);
  usePageLoading(chatsLoading, 'ChatsContainer');

  const chatToShow = selectedChat ? chats.find(chat => chat.id === selectedChat) : chats ? chats[0] : null;

  const countUnreadChats = chats?.length
    ? chats.reduce(
        (prev, cur) => (cur.status === CHAT_STATUS.UNREAD && cur.lastMessage.owner.id !== me.id ? ++prev : prev),
        0
      )
    : 0;

  return (
    <PBox px={6} height="100%">
      <PBox
        py={5}
        style={{
          top: 60,
          right: 0,
          zIndex: 10,
          left: 'auto',
          backgroundColor: 'white',
        }}
      >
        <Typography align="left" variant="h1" style={{ textTransform: 'uppercase' }}>
          Support chat {countUnreadChats ? `(${countUnreadChats})` : ''}
        </Typography>
      </PBox>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PBox border={`1px solid ${theme.palette.divider}`} borderRadius="2px">
            <PBox p={2}>
              <SearchInput value={searchText} onChange={value => setSearchText(value)} />
            </PBox>
            <PBox
              height="calc(100vh - 283px)"
              style={{
                overflowY: 'scroll',
              }}
            >
              {chats.map((chat, index) => (
                <ChatItem key={chat.id} chat={chat} selectLight={!selectedChat && index === 0} />
              ))}
            </PBox>
          </PBox>
        </Grid>
        <Grid item xs={8}>
          {chatToShow && (
            <PBox border={`1px solid ${theme.palette.divider}`} borderRadius="2px">
              <ChatMessageHeader chat={chatToShow} />
              <PBox px={2}>
                <ChatWithAdmin chatName={chatToShow.name} chatsLoading={chatsLoading} isAdminView />
              </PBox>
            </PBox>
          )}
        </Grid>
      </Grid>
    </PBox>
  );
};
