import { Typography } from '@material-ui/core';
import { PBox } from './PBox';

export const PriorityScale = () => (
  <>
    <PBox mb={0.5} display="flex" alignItems="center" justifyContent="space-between">
      <Typography style={{ fontSize: 9, color: '#27B05E' }}>Less important</Typography>
      <Typography style={{ fontSize: 9, color: '#27B05E' }}>More important</Typography>
    </PBox>
    <PBox display="flex" alignItems="center" width="100%">
      <PBox height="12px" width="1px" style={{ background: '#27B05E' }} />
      <PBox height="1px" width="100%" style={{ background: '#27B05E' }} />
      <PBox height="12px" width="1px" style={{ background: '#27B05E' }} />
    </PBox>
  </>
);
