import gql from 'graphql-tag';

import { SellerPrioritiesType } from '../types';

export const SELLERS_PRIORITIES_QUERY = gql`
  query SellerPrioritiesQuery($plumbidId: Int) {
    sellerPriorities(plumbidId: $plumbidId) {
      ${SellerPrioritiesType}
    }
  }
`;
