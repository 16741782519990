import React, { memo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { CardSlider, CurrencyInput, PercentInput, PBox, Text } from 'legos';
import { CASH_DOWN_IMPROVE_MODE } from 'constant';
import { formatNumberToUSD } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';
import { BaseCard } from './BaseCard';
import { theme } from 'utils/theme';

const percentMarksVaLoan = [
  ...new Array(50).fill(0),
  { value: 25, label: '25%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
].map((mark, index) => {
  if (mark) {
    return mark;
  }
  const newMark = { value: index * 2 };
  if (newMark.value === 0 || newMark.value === 50) {
    newMark.label = `${newMark.value}%`;
  }
  return newMark;
});

const percentMarks = [
  ...new Array(46).fill(0),
  { value: 5, label: '5%' },
  { value: 25, label: '25%' },
  { value: 75, label: '75%' },
].map((mark, index) => {
  if (mark) {
    return mark;
  }
  const newMark = { value: index * 2 + 10 };
  if (newMark.value === 50 || newMark.value === 100) {
    newMark.label = `${newMark.value}%`;
  }
  return newMark;
});

export const CashDownCard = memo(
  ({
    style,
    vaLoan,
    loading,
    disabled,
    maxValue,
    cashDownErrors,
    loadingCashDown,
    improveAmountDown,
    hasCacheDownError,
    showInnerCashDown,
    downPaymentImprove,
    toggleCashDownMode,
    incentiveAmountDown,
    changeCashIncentive,
    improvePercentageDown,
    sellerPrioritiesValue,
    minimumPercentNoVALoan,
    incentivePercentageDown,
    incentiveCashDownImproveMode,
  }) => {
    const { mobileScreen } = useResponsive();
    const disableSlider = loadingCashDown || (disabled !== '' && disabled);
    const [currencyInputActive, setCurrencyInputActive] = useState(false);
    const [percentInputActive, setPercentInputActive] = useState(false);

    const amountDown = formatNumberToUSD(
      incentiveCashDownImproveMode === CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN || showInnerCashDown
        ? incentiveAmountDown
        : improveAmountDown,
      {
        hidePlusSign: true,
      }
    );

    const colorCurrencyInput = currencyInputActive ? theme.palette.text.secondary : theme.palette.purple.light;

    const backgroundColorCurrencyInput = currencyInputActive
      ? theme.palette.background.paper
      : theme.palette.background.card;

    const colorPercentInput = percentInputActive ? theme.palette.text.secondary : theme.palette.purple.light;

    const backgroundColorPercentInput = percentInputActive
      ? theme.palette.background.paper
      : theme.palette.background.card;

    const percentInputValue =
      incentiveCashDownImproveMode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN
        ? +incentivePercentageDown
        : +improvePercentageDown;

    return (
      <BaseCard
        loading={loading}
        disabled={disabled}
        improve={downPaymentImprove}
        style={{ ...style, position: 'relative' }}
        sellerPrioritiesTitle="Down Payment Priority"
        sellerPrioritiesValue={sellerPrioritiesValue}
      >
        <PBox mb={3} mt={4} height="80px">
          <Grid container alignItems="center">
            <Grid item>
              <Text
                disabled={disabled}
                variant="h2"
                style={{
                  flex: 1,
                  fontSize: mobileScreen ? 20 : 24,
                }}
              >
                Cash Down:
              </Text>
            </Grid>
            <Grid item style={{ width: amountDown?.length > 8 ? 157 : 127, marginRight: 4 }}>
              <CurrencyInput
                fontSize={24}
                fontWeight={700}
                disabled={showInnerCashDown || (disabled !== '' && disabled)}
                value={amountDown}
                color={colorCurrencyInput}
                backgroundColor={backgroundColorCurrencyInput}
                onFocus={() => {
                  setCurrencyInputActive(true);
                  if (incentiveCashDownImproveMode !== CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN) {
                    toggleCashDownMode(CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN);
                  }
                }}
                onBlur={() => setCurrencyInputActive(false)}
                onChange={newValue => {
                  if (+newValue === +amountDown.replace(/[,\s$]+/g, '')) {
                    return null;
                  } else {
                    changeCashIncentive({
                      mode: CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN,
                      newValue: !newValue || +newValue < 0 ? 0 : +newValue,
                    });
                  }
                  setPercentInputActive(false);
                }}
                error={!loading && !showInnerCashDown && hasCacheDownError}
              />
            </Grid>
            <Grid item style={{ width: 81 }}>
              <PercentInput
                fontSize={24}
                fontWeight={700}
                value={percentInputValue}
                disabled={showInnerCashDown || (disabled !== '' && disabled)}
                color={colorPercentInput}
                backgroundColor={backgroundColorPercentInput}
                onFocus={() => {
                  setPercentInputActive(true);
                  if (incentiveCashDownImproveMode !== CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN) {
                    toggleCashDownMode(CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN);
                  }
                }}
                onBlur={() => setPercentInputActive(false)}
                onChange={newValue => {
                  if (+newValue === +percentInputValue) {
                    return null;
                  } else {
                    changeCashIncentive({
                      mode: CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
                      newValue: !newValue || +newValue < 0 ? 0 : +newValue,
                    });
                    setPercentInputActive(false);
                  }
                }}
                error={!loading && !showInnerCashDown && hasCacheDownError}
              />
            </Grid>
            {cashDownErrors && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ color: theme.palette.text.error }}>
                  {cashDownErrors}
                </Typography>
              </Grid>
            )}
          </Grid>
        </PBox>
        <CardSlider
          maxValue={maxValue}
          max={100}
          disabled={disableSlider}
          min={vaLoan ? 0 : minimumPercentNoVALoan}
          style={{ marginTop: -8 }}
          marks={vaLoan ? percentMarksVaLoan : percentMarks}
          onChange={(_, newValue) => {
            changeCashIncentive({
              mode: CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
              newValue,
              lazyChange: true,
            });
          }}
          onChangeCommitted={(_, newValue, valueHasChanged) => {
            changeCashIncentive({
              mode: CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
              newValue,
              lazyChange: !valueHasChanged,
              isValueChange: valueHasChanged,
            });
          }}
          inactive={currencyInputActive}
          step={incentiveCashDownImproveMode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN ? 1 : 0.000001}
          value={percentInputValue}
          isPercent
        />
      </BaseCard>
    );
  }
);
