import { useEffect } from 'react';

import { Footer, PageContainer, AboutUs, AboutPlumBid } from 'components';

export const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <AboutPlumBid />
      <AboutUs />
      <Footer />
    </PageContainer>
  );
};
