export const hasOfferSettingsWarning = offersettings => {
  if (!offersettings) {
    return true;
  }
  return !(
    offersettings.offerPriceIsPrecise &&
    offersettings.cashdownAmountIsPrecise &&
    offersettings.appraisalContingencyIsPrecise &&
    (offersettings.appraisalContingency ? offersettings.appraisalContingencyLengthIsPrecise : true) &&
    offersettings.inspectionContingencyIsPrecise &&
    (offersettings.inspectionContingency ? offersettings.inspectionContingencyLengthIsPrecise : true) &&
    offersettings.escrowLengthIsPrecise &&
    offersettings.loanContingencyIsPrecise &&
    (offersettings.loanContingency ? offersettings.loanContingencyLengthIsPrecise : true) &&
    offersettings.offerLeaseBackIsPrecise &&
    (offersettings.offerLeaseBack
      ? offersettings.offerLeaseBackLengthIsPrecise && offersettings.offerLeaseBackPricePerDayIsPrecise
      : true)
  );
};
