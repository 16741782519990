import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import { PBox, Button } from 'legos';
import { DASHBOARD, HOME } from 'routes';
import { useResponsive } from 'utils/hooks';

export const RedirectPage = () => {
  const history = useHistory();
  const { mdScreen } = useResponsive();
  return (
    <PBox
      px={2}
      py={5}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={mdScreen ? 'column' : 'row'}
    >
      <PBox px={2}>
        <Typography variant="h2" align="center">
          You don't have access to this page, please:
        </Typography>
      </PBox>
      <PBox px={2} py={2}>
        <Button
          variant="secondary"
          marginLeft={3}
          marginRight={3}
          title="Go to Dashboard"
          align="center"
          onClick={() => history.push(DASHBOARD)}
          style={{
            borderRadius: '4px',
          }}
        />
      </PBox>
      <PBox px={2} py={2}>
        <Button
          variant="secondary"
          marginLeft={3}
          marginRight={3}
          title="Go to Homepage"
          align="center"
          onClick={() => history.push(HOME)}
          style={{
            borderRadius: '4px',
          }}
        />
      </PBox>
    </PBox>
  );
};
