import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { BUYERS_LA, HOME, SELLER_LA, AGENTS_LA, PLUMBID_LUXURY_AUCTIONS, PLUMBID_MULTIPLE } from 'routes';
import { Icons, RouterLink } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const LearnMoreLA = ({ bgColor }) => {
  const { pathname } = useLocation();
  const responsive = useResponsive();

  return (
    <Grid
      container
      style={{ padding: responsive.tabletScreen ? '25px 15px' : '70px 0 ', backgroundColor: bgColor || 'unset' }}
      justify="space-evenly"
      alignItems="stretch"
    >
      <Grid
        item
        xs={12}
        lg={3}
        style={{
          backgroundColor: pathname === BUYERS_LA ? theme.palette.common.white : theme.palette.primary.main,
          borderRadius: 15,
          border: pathname === BUYERS_LA ? `1px solid ${theme.palette.primary.main}` : null,
          marginBottom: responsive.padScreen && 20,
        }}
      >
        <RouterLink
          to={BUYERS_LA}
          style={{
            color: theme.palette.common.white,
            textDecoration: 'none',
            cursor: pathname === BUYERS_LA ? 'default' : 'pointer',
          }}
        >
          <Box
            p={2}
            className={pathname === BUYERS_LA ? '' : 'home-button'}
            minHeight={responsive.padScreen ? 290 : 350}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            style={{ position: 'relative' }}
          >
            <Box>
              <Typography
                variant="h1"
                style={{
                  fontSize: 45,
                  fontWeight: 700,
                  color: pathname === BUYERS_LA ? theme.palette.primary.main : theme.palette.common.white,
                  paddingBottom: 36,
                }}
              >
                Buyers
              </Typography>
              <Typography
                variant="h3"
                style={{
                  color: pathname === BUYERS_LA ? theme.palette.text.primary : theme.palette.common.white,
                  lineHeight: 1.5,
                }}
              >
                No more guessing and fully transparent, so you only pay the price that’s right for you.
              </Typography>
            </Box>
            <Box style={{ position: 'absolute', bottom: 100 }}>
              {pathname !== BUYERS_LA ? (
                <Box display="flex">
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 700,
                      marginRight: 8,
                    }}
                  >
                    Learn more
                  </Typography>
                  <Icons.More
                    style={{
                      stroke: theme.palette.common.white,
                    }}
                  />
                </Box>
              ) : (
                <div style={{ height: 24 }} />
              )}
            </Box>
          </Box>
        </RouterLink>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        style={{
          padding: responsive.padScreen ? '16px 0' : null,
          backgroundColor: pathname === SELLER_LA ? theme.palette.common.white : theme.palette.primary.main,
          borderRadius: 15,
          border: pathname === SELLER_LA ? `1px solid ${theme.palette.primary.main}` : null,
          marginBottom: responsive.padScreen && 20,
        }}
      >
        <RouterLink
          to={SELLER_LA}
          style={{
            textDecoration: 'none',
            color: theme.palette.common.white,
            cursor: pathname === SELLER_LA ? 'default' : 'pointer',
          }}
        >
          <Box
            p={2}
            className={pathname === SELLER_LA ? '' : 'home-button'}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight={responsive.padScreen ? 290 : 350}
            height="100%"
            style={{ position: 'relative' }}
          >
            <Box>
              <Typography
                variant="h1"
                style={{
                  fontSize: 45,
                  fontWeight: 700,
                  color: pathname === SELLER_LA ? theme.palette.primary.main : theme.palette.common.white,
                  paddingBottom: 36,
                }}
              >
                Sellers
              </Typography>
              <Typography
                variant="h3"
                style={{
                  color: pathname === SELLER_LA ? theme.palette.text.primary : theme.palette.common.white,
                  lineHeight: 1.5,
                }}
              >
                Proven to get you the best price and terms, based-on your personal preferences.
              </Typography>
            </Box>
            <Box style={{ position: 'absolute', bottom: 100 }}>
              {pathname !== SELLER_LA ? (
                <Box display="flex">
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 700,
                      marginRight: 8,
                    }}
                  >
                    Learn more
                  </Typography>
                  <Icons.More
                    style={{
                      stroke: theme.palette.common.white,
                    }}
                  />
                </Box>
              ) : (
                <div style={{ height: 24 }} />
              )}
            </Box>
          </Box>
        </RouterLink>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        style={{
          backgroundColor: pathname === AGENTS_LA ? theme.palette.common.white : theme.palette.primary.main,
          borderRadius: 15,
          border: pathname === AGENTS_LA ? `1px solid ${theme.palette.primary.main}` : null,
        }}
      >
        <RouterLink
          to={AGENTS_LA}
          style={{
            color: theme.palette.common.white,
            textDecoration: 'none',
            cursor: pathname === AGENTS_LA ? 'default' : 'pointer',
            position: 'relative',
          }}
        >
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            minHeight={responsive.padScreen ? 290 : 350}
            className={pathname === AGENTS_LA ? '' : 'home-button'}
            style={{ position: 'relative' }}
          >
            <Box>
              <Typography
                variant="h1"
                style={{
                  fontSize: 45,
                  fontWeight: 700,
                  color: pathname === AGENTS_LA ? theme.palette.primary.main : theme.palette.common.white,
                  paddingBottom: 36,
                }}
              >
                Agents
              </Typography>
              <Typography
                variant="h3"
                style={{
                  color: pathname === AGENTS_LA ? theme.palette.text.primary : theme.palette.common.white,
                  lineHeight: 1.5,
                }}
              >
                <b>plumBid</b> Luxury Auctions works with both you and your clients – providing a full-service platform to transparently generate the best outcome.
              </Typography>
            </Box>

            <Box style={{ position: 'absolute', bottom: 100 }}>
              {pathname !== AGENTS_LA ? (
                <Box display="flex">
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 700,
                      marginRight: 8,
                    }}
                  >
                    Learn more
                  </Typography>
                  <Icons.More
                    style={{
                      stroke: theme.palette.common.white,
                    }}
                  />
                </Box>
              ) : (
                <div style={{ height: 24 }} />
              )}
            </Box>
          </Box>
        </RouterLink>
      </Grid>
      {pathname !== HOME && pathname !== PLUMBID_LUXURY_AUCTIONS && pathname !== PLUMBID_MULTIPLE ? (
        <Grid item container justify="center" xs={10}>
          <Typography
            variant="h4"
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              paddingTop: 70,
            }}
          >
            Best price and terms. Fair and transparent. Net result; seller, buyer and agent -- all ecstatic.
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
