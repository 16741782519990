import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useCallback, useMemo } from 'react';

export const useUpdateQueryString = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);

  const updateQueryString = useCallback(
    (name, value) => {
      const innerParsedSearch = queryString.parse(window.location.search);

      if (value) {
        innerParsedSearch[name] = value;
      } else {
        delete innerParsedSearch[name];
      }
      history.replace({
        search: queryString.stringify(innerParsedSearch),
      });
      return queryString.stringify(innerParsedSearch);
    },
    [history]
  );

  return [parsedSearch, updateQueryString, pathname, search];
};
