import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';

import { Button } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const OfferDuplicateDialog = ({ isOpen, onClose, onOk, offer }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="Duplicate offer" onClose={onOk} />
      <DialogContent>
        <Box display="flex" justifyContent="center" mt={5} mb={6} flexDirection="column">
          <Typography variant="h3" style={{ lineHeight: 1.5, fontSize: 20 }}>
            One of the offers was duplicated, we removed it from the list
          </Typography>
          <Typography variant="h3" style={{ lineHeight: 2.5, fontSize: 20 }}>
            {offer.offerdoc.filename}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={1} mb={2}>
          <Button align="center" title="OK" style={{ width: 160 }} onClick={onOk} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
