import { PARTY_KIND, PLUMBID_STATUS, ROLES } from 'constant';
import { formatPartyName, formatPDFFileName, getParticipantField } from 'utils';
import state from 'service/graphql/state';
import { setGlobalPopup } from './setGlobalPopup';

/**
 *@typedef {{
 * backUpPosition: Boolean
 * pending: Boolean
 * plumBidStarted: Boolean
 * timeLeftIncrease: Boolean
 * newBidReceived: Boolean
 * }} PopupStatus
 */

/**
 * @typedef {{
 *    role: ROLES
 *    isBuyerParty: boolean
 *    isSellerParty: boolean
 *    myStatus: NEW_PARTICIPANT_STATUS
 *    myPartyStatus: keyof NEW_PARTY_STATUS
 *    myPopupStatus: PopupStatus
 *    myPartyOnboardingStatus: boolean
 *    myOnboardingStatus: boolean
 *    isWinner: boolean
 *    buyerPriorities: *
 *    contingencyRemoved: boolean
 *    meId: number|string
 *    myParty: *
 *    participantId:number|string
 *    myPreferences: *
 *    sellerPriorities: *
 * }} MyInfoInPlumBid
 */

/**
 *
 * @param {MyInfoInPlumBid} myInfoInPlumBid
 * @param {*} participant
 * @param {*} party
 * @param {*} me
 * @param {*} normalizedParty
 * @param {*} isBuyerParty
 */

const fillMyInfoInPlumBid = (myInfoInPlumBid, participant, party, me, normalizedParty, isBuyerParty) => {
  myInfoInPlumBid.role = participant.role;
  myInfoInPlumBid.isBuyerParty = isBuyerParty;

  myInfoInPlumBid.myStatus = participant.status;
  myInfoInPlumBid.myPartyStatus = party.status;
  myInfoInPlumBid.myPopupStatus = participant.popupStatus;
  myInfoInPlumBid.myPartyOnboardingStatus = party.onboardingStatus;
  myInfoInPlumBid.myOnboardingStatus = participant.onboardingStatus;

  myInfoInPlumBid.isWinner = !!participant.isWinner;
  myInfoInPlumBid.buyerPriorities = participant.buyerPriorities;
  myInfoInPlumBid.contingencyRemoved = party.contingencyRemoved;
  myInfoInPlumBid.myParty = normalizedParty;

  myInfoInPlumBid.meId = me.userId;
  myInfoInPlumBid.participantId = participant.id;
  myInfoInPlumBid.myLastBid = participant.lastBid || null;

  //seller
  if (participant.role === ROLES.SELLER) {
    myInfoInPlumBid.isSellerParty = true;
    myInfoInPlumBid.myPreferences = participant.preferences;
    myInfoInPlumBid.sellerPriorities = party.sellerPriorities;
  }
};

const setTimer = plumBid => {
  if (plumBid.timeLeft) {
    const timeInfo = state.plumBidsTimerVar()[plumBid.id];
    const newTimeLeft = +plumBid.timeLeft.timeLeft * 1000;

    if (!timeInfo || +timeInfo.timeLeft !== newTimeLeft) {
      state.plumBidsTimerVar({
        ...state.plumBidsTimerVar(),
        [plumBid.id]: { responseTime: Date.now(), timeLeft: newTimeLeft },
      });
    }
  }
};
/**
 *
 * @param {*} plumBid
 * @param {*} isSandbox
 * @param {*} me
 * @returns {{
 *    myInfoInPlumBid: MyInfoInPlumBid
 * }}
 */
export const normalizePlumBid = (plumBid, isSandbox, me) => {
  let userIsParticipantOfPlumBid = me?.roles.includes(ROLES.ADMIN) || isSandbox ? true : false;

  /**
   * @type MyInfoInPlumBid
   */
  const myInfoInPlumBid = {
    role: me?.roles.includes(ROLES.ADMIN) ? ROLES.LISTING_AGENT : undefined,
    myPopupStatus: {},
  };

  const partiesByType =
    plumBid[isSandbox ? 'sbplumbidpartySet' : 'plumbidpartySet']?.length > 0 &&
    plumBid[isSandbox ? 'sbplumbidpartySet' : 'plumbidpartySet'].reduce(
      (prev, party) => {
        const participantList = [...(party[isSandbox ? 'sbparticipantSet' : 'participants']?.edges || [])].sort(
          (a, b) => a.node.id - b.node.id
        );

        if (party.party === PARTY_KIND.BUYER) {
          const buyersFullNameList = [];
          const normalizedParty = {
            ...party,
          };

          normalizedParty.participantSet =
            participantList.map(({ node }) => {
              if (node.role === ROLES.BUYER && getParticipantField(node, 'fullName')) {
                buyersFullNameList.push(getParticipantField(node, 'fullName'));
              }

              if (node.backUpPosition) {
                normalizedParty.backUpPosition = true;
              }

              if (me?.userId && +node.user?.userId === +me.userId) {
                userIsParticipantOfPlumBid = true;
                fillMyInfoInPlumBid(myInfoInPlumBid, node, party, me, normalizedParty, true);
              }

              return node;
            }) || [];

          normalizedParty.partyName =
            formatPartyName(buyersFullNameList) || formatPDFFileName(party?.offerdoc?.filename) || `Offer #${party.id}`;

          prev.buyerParty.push(normalizedParty);
        } else if (party.party === PARTY_KIND.SELLER) {
          const participantSet = [];

          participantList.forEach(({ node }) => {
            if (me?.userId && +node.user?.userId === +me.userId) {
              userIsParticipantOfPlumBid = true;
              fillMyInfoInPlumBid(myInfoInPlumBid, node, party, me, party, false);
            }
            if (node.role !== ROLES.LISTING_AGENT) {
              participantSet.push(node);
            }
          });

          participantSet.sort((a, b) => a.id - b.id);

          prev.sellerParty = {
            ...party,
            participantSet,
          };
        }

        return prev;
      },
      {
        buyerParty: [],
        sellerParty: isSandbox ? { sellerPriorities: plumBid.sbsellerprioritiesSet?.[0] } : null,
      }
    );

  if (plumBid?.id) {
    setGlobalPopup(plumBid, myInfoInPlumBid, isSandbox);
    setTimer(plumBid);
  }

  if (plumBid.status === PLUMBID_STATUS.FINISHED) {
    const key = `${isSandbox ? 'sbpb' : 'pb'}${plumBid.id}`;
    localStorage.removeItem(key);
  }

  partiesByType?.buyerParty?.sort((a, b) => {
    if (plumBid.status === PLUMBID_STATUS.FINISHED) {
      if (a.isWinner === b.isWinner) {
        return a.backUpPosition ? -1 : 1;
      } else {
        return a.isWinner ? -1 : 1;
      }
    } else {
      return a.id - b.id;
    }
  });

  return {
    ...plumBid,
    mls: plumBid.mls
      ? {
          ...plumBid.mls,
          previewImage: plumBid.mls.previewImage ?? plumBid.mls.mlsinfophotoSet?.[0]?.picture,
        }
      : null,
    myInfoInPlumBid /** @type {myInfoInPlumBid}*/,
    ...partiesByType,
    userIsParticipantOfPlumBid,
  };
};
