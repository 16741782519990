import { useEffect } from 'react';

import { Footer, PageContainer, } from 'components';
import { CollapseBox } from 'components/CollapseBox';
import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';
const FAQList = [
  {
    title: 'What is the process to conduct a plumBid Luxury Auction?',
    text: 'The Luxury Auction process begins with an initial consultation with our experienced team. Your property will be showcased through our website. On the auction day, bidders will compete in real-time, and the highest bidder at or above the reserve price wins.',
  },
  {
    title: 'What do I have to do to participate?',
    text: "To participate in a Luxury Auction, you'll need to complete our registration process, which typically involves providing your contact information, agreeing to our terms and conditions."
  },
  {
    title: 'What happens after I register?',
    text: "After registration, you'll gain access to our auction platform, allowing you to view property details, due diligence documents, and participate in the auction. You'll also receive important updates and notifications related to the auction.",
  },
  {
    title: 'What happens after the auction if I win?',
    text: "If you're the winning bidder, you'll typically sign the Residential Purchase Agreement, submit the required earnest money deposit, and proceed with the closing process as outlined in the terms of the auction.",
  },
  {
    title: 'What happens after the auction if I don’t win?',
    text: "If you don't win the auction, you can continue your search for other available properties or participate in future auctions. Your registration and bidder deposit remain intact for future auctions.",
  },
  {
    title: 'How does plumBid protect my personal information?',
    text: "We take your privacy seriously. Your personal information is securely stored and only used for auction-related communications and transactions. We do not share your data with third parties without your consent.",
  },
  {
    title: 'What role will my agent play in the transaction?',
    text: 'Your agent can provide valuable assistance throughout the auction process, including property research, due diligence, and helping you navigate the bidding and closing process.',
  },
  {
    title: 'What fees are charged in this process?',
    text: "The fees associated with Luxury Auctions may include a buyer's premium, a seller's commission, and other transaction-related costs. These fees can vary, so it's essential to review the specific terms for each auction.",
  },
  {
    title: 'Who is responsible for the fees charged by Luxury Auctions?',
    text: "The responsibility for paying fees is typically outlined in the auction's terms and conditions. Buyers may be responsible for the buyer's premium, while sellers usually cover the seller's commission.",
  },
  {
    title: 'When are the fees due?',
    text: "The timing for fee payment is determined by the terms of the auction. It's crucial to review the auction terms to understand when fees are due.",
  },
  {
    title: 'How are fees paid?',
    text: "Fees are typically paid through secure and convenient online payment methods. Specific instructions for fee payment will be provided in the auction's terms and conditions.",
  },
  {
    title: 'Who can I contact with further questions?',
    text: "For any additional questions or clarification, please contact our customer support team at [Contact Email/Phone Number]. Our friendly and knowledgeable staff is here to assist you.",
  },
  {
    title: 'Can I speak with someone in person regarding my interests and questions?',
    text: "Absolutely! We understand the importance of personal interactions. You can schedule an appointment with one of our representatives to discuss your interests and questions in detail.",
  },
  {
    title: 'Who drafts the final Residential Purchase Agreement?',
    text: "The final Residential Purchase Agreement is typically drafted by legal professionals or licensed real estate agents involved in the transaction. Our team can guide you through this process and connect you with the necessary experts if needed.",
  },


]

export const FAQPage = () => {
  const { mdScreen } = useResponsive()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <Grid container justify="center" style={{ paddingBottom: 160 }}>
        <Grid item xs={12} >
          <Typography variant="h1" style={{ fontWeight: 500, fontSize: 34, paddingLeft: mdScreen ? 15 : 96, paddingTop: 90, paddingBottom: 40 }}>
            Frequently Asked Questions
          </Typography>
        </Grid>

        <Grid container item xs={12} spacing={2}  >
          {FAQList.map(({ text, title }) =>
            <Grid container item xs={12} >
              <Box style={{ paddingLeft: mdScreen ? 15 : 90, paddingRight: mdScreen ? 15 : 90 }}>
                <CollapseBox text={text} title={title} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
    </PageContainer>
  );
};
