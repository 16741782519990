import React from 'react';
import { ButtonBase, Typography } from '@material-ui/core';

import { useCallMeBackButtonStyle } from './style';
import { Icons, DotPuls } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const CallMeBackButton = ({ disabled, plumbidId, onClick, hasNewMessage, ...props }) => {
  const callMeBackButton = useCallMeBackButtonStyle();

  const responsive = useResponsive();

  return (
    <ButtonBase
      disableRipple
      disableTouchRipple
      classes={callMeBackButton}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {hasNewMessage && <DotPuls />}
      <Icons.Phone
        style={{
          marginRight: responsive.smallScreen ? '20px' : '-10px',
        }}
      />
      {!responsive.smallScreen && (
        <Typography
          variant="body2"
          style={{
            color: theme.palette.text.primary,
            marginRight: responsive.padScreen ? '20px' : 'unset',
          }}
        >
          {responsive.padScreen ? 'Call me back' : 'Have questions? Click here'}
        </Typography>
      )}
    </ButtonBase>
  );
};
