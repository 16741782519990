import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';

import { formatUserFullName } from 'utils/formatters';
import { AvatarUserInitials } from 'legos';
import { useResponsive } from 'utils/hooks';

export const MessageItem = ({ message }) => {
  const createdAt = moment(message.createdAt);
  const responsive = useResponsive();

  return (
    <Grid container alignItems="flex-start" justify="space-between" spacing={1}>
      <Grid item xs={1}>
        <AvatarUserInitials fullName={message.owner.fullName} />
      </Grid>
      <Grid item xs={9} spacing={1} container style={{ paddingLeft: responsive.smallScreen ? 24 : 56 }}>
        <Grid item xs={12}>
          <Typography variant="h4">{formatUserFullName(message.owner)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ wordWrap: 'break-word' }}>
            {message.message}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" component="p" align="right">
          {createdAt.isSame(moment(), 'day') ? createdAt.format('h:mm') : createdAt.format('MMM DD [at] h:mm')}
        </Typography>
      </Grid>
    </Grid>
  );
};
