import { useMemo, useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { BUYER_QUESTIONS_QUERY, CHANGE_BUYER_ANSWERS_MUTATION, CONFIRM_BUYER_ANSWERS_MUTATION } from 'service/graphql';
import { getQuestionsTree } from './questionTree';
import { usePlumBidById, useNotification } from 'utils/hooks';

const initialBuyersAnswer = [{ questionId: 'Q1', answerId: null, answerValue: '', progress: 1 }];

export const useBuyersQuestions = (plumbidId, buyerId) => {
  const showNotification = useNotification();

  const [mismatch, setMismatch] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [buyersAnswers, setBuyerAnswers] = useState(initialBuyersAnswer);

  const { plumBid, plumBidError, plumBidLoading, plumBidRefetch } = usePlumBidById({ id: plumbidId });

  const buyerParty = useMemo(() => {
    if (plumBid) {
      return plumBid.buyerParty.find(party =>
        party.participantSet.some(participant => participant?.user?.userId === buyerId)
      );
    }
  }, [plumBid, buyerId]);

  const [fetchBuyerQuestion, { data: buyersQuestionsData, loading: buyersQuestionsLoading, refetch }] = useLazyQuery(
    BUYER_QUESTIONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (buyerParty?.id) {
      fetchBuyerQuestion({
        variables: { plumbidPartyId: buyerParty.id },
        fetchPolicy: 'network-only',
        onError: error => showNotification({ error }),
      });
    }
  }, [buyerParty?.id, fetchBuyerQuestion, showNotification]);

  useEffect(() => {
    if (!buyersQuestionsData) {
      setBuyerAnswers([{ questionId: 'Q1', answerId: null, answerValue: null, progress: 1, branch: 1 }]);
    }
  }, [buyersQuestionsData]);

  const [confirmBuyerAnswers, { loading: confirmBuyerAnswersLoading }] = useMutation(CONFIRM_BUYER_ANSWERS_MUTATION, {
    variables: {
      input: {
        plumbidId,
      },
    },
  });

  const [saveBuyerAnswers] = useMutation(CHANGE_BUYER_ANSWERS_MUTATION);

  const checkMismatch = (answers, questionTree) => {
    const isValueEqual = (val1, val2) => {
      if (!val1 && !val1) {
        return true;
      }
      return moment(val1 * 1000).isSame(moment(val2 * 1000), 'day');
    };

    const mismatch = answers.some(({ questionId, answerId, answerValue }) => {
      if (!questionTree[questionId].partyAnswers.length || !answerId) {
        return false;
      }

      return questionTree[questionId].partyAnswers.find(
        item => item.answerId !== answerId || !isValueEqual(item.answerValue, answerValue)
      );
    });

    setMismatch(mismatch);
  };

  const addNewQuestion = useCallback(
    (nextQuestionId, answers, answerIndex, questionTree) => {
      if (
        nextQuestionId &&
        nextQuestionId !== 'Finish' &&
        (answerIndex === answers.length - 1 || answers[answerIndex + 1].questionId !== nextQuestionId)
      ) {
        let answerId = null;
        let addAfterNextQuestion = null;
        if (nextQuestionId === 'Q3.4') {
          answerId = 'Q3.4A1';
        } else if (nextQuestionId === 'Q3.5') {
          answerId = 'Q3.5A1';
        } else if (nextQuestionId === 'Q3') {
          if (buyerParty?.offersettings?.agreementContingent) {
            answerId = 'Q3A1';
            addAfterNextQuestion = {
              questionId: 'Q3',
              answerId: 'Q3A1',
            };
          }
        }

        answers.splice(answerIndex + 1, 0, {
          questionId: nextQuestionId,
          answerId,
          answerValue: '',
          progress: questionTree[nextQuestionId].progress,
          answerProgress: questionTree[`${nextQuestionId}`][`progress${answerId}`],
          branch: questionTree[nextQuestionId].branch,
        });

        if (addAfterNextQuestion) {
          const afterNextQuestionId = questionTree[addAfterNextQuestion.questionId][addAfterNextQuestion.answerId];

          answers.splice(answerIndex + 2, 0, {
            questionId: afterNextQuestionId,
            answerId: null,
            answerValue: '',
            progress: questionTree[afterNextQuestionId].progress,
            answerProgress: questionTree[`${afterNextQuestionId}`][`progress${answerId}`],
            branch: questionTree[afterNextQuestionId].branch,
          });
        }
      }

      return answers;
    },
    [buyerParty?.offersettings?.agreementContingent]
  );

  const isQuestionReachedTo = useCallback((answers, questionTree, questionReachedToList) => {
    const lastValidAnswer = answers.reduce((prev, { questionId, answerId, answerValue }) => {
      if (questionId === prev) {
        if (answerId === 'Q3.4A1' && !answerValue) {
          prev = null;
        } else if (answerId === 'Q3.5A1' && !answerValue) {
          prev = null;
        } else {
          const nextQuestionId = questionTree[questionId][answerId];
          return nextQuestionId || prev;
        }
      }
      return prev;
    }, 'Q1');

    questionReachedToList = questionReachedToList || ['Finish'];
    return questionReachedToList.indexOf(lastValidAnswer) > -1;
  }, []);

  const checkAgreeTermsAndConditions = useCallback(myAnswers => {
    const Q4 = myAnswers.find(({ questionId }) => questionId === 'Q4');

    return Q4 && Q4.answerId === 'Q4A1';
  }, []);

  const buyerQuestionsInfo = useMemo(() => {
    const questions = buyersQuestionsData?.onboardingBuyerQuestions || [];

    if (questions?.length) {
      let myAnswers = [];
      const questionsTree = getQuestionsTree();
      let answeredBuyers = [];
      const anotherBuyersAnswer = {};
      questions.forEach(({ questionId, text1, text2, text3, text4, text5, answerList, partyAnswers }) => {
        if (questionsTree[questionId]) {
          questionsTree[questionId].text1 = text1;
          questionsTree[questionId].text2 = text2;
          questionsTree[questionId].text3 = text3;
          questionsTree[questionId].text4 = text4;
          questionsTree[questionId].text5 = text5;

          questionsTree[questionId].answerList = answerList;

          questionsTree[questionId].partyAnswers = partyAnswers.filter(({ owner }) => owner.user.userId !== buyerId);
          questionsTree[questionId].partyAnswers.forEach(answer => {
            if (!anotherBuyersAnswer[answer.owner.user.userId]) {
              anotherBuyersAnswer[answer.owner.user.userId] = [];
            }
            anotherBuyersAnswer[answer.owner.user.userId].push({
              answerId: answer.answerId,
              answerValue: answer.answerValue,
              questionId,
            });

            if (!answeredBuyers.some(user => user.userId === answer.owner.user.userId)) {
              answeredBuyers.push(answer.owner.user);
            }
          });

          //find my answer and extract it to the sellersAnswers array
          const myAnswer = partyAnswers.find(({ owner }) => owner.user.userId === buyerId);

          if (myAnswer) {
            myAnswers.push({
              questionId,
              answerId: myAnswer.answerId,
              answerValue: myAnswer.answerValue || '',
              answerProgress: questionsTree[`${questionId}`][`progress${myAnswer.answerId}`],
              progress: questionsTree[questionId].progress,
              branch: questionsTree[questionId].branch,
            });
          }
        }
      });

      if (answeredBuyers.length) {
        answeredBuyers = answeredBuyers.filter(buyer =>
          isQuestionReachedTo(anotherBuyersAnswer[buyer.userId], questionsTree, ['Q4', 'Finish'])
        );
      }

      if (myAnswers.length) {
        for (let index = 0; index < myAnswers.length; index++) {
          const lengthBeforeAddQuestion = myAnswers.length;

          const questionLeaf = questionsTree[myAnswers[index].questionId];

          const nextQuestionId = questionLeaf[myAnswers[index].answerId];
          myAnswers = addNewQuestion(nextQuestionId, myAnswers, index, questionsTree);

          if (myAnswers.length !== lengthBeforeAddQuestion) {
            index = 0;
          }
        }

        checkMismatch(myAnswers, questionsTree);
        setCanSubmit(
          isQuestionReachedTo(myAnswers, questionsTree, ['Finish']) && checkAgreeTermsAndConditions(myAnswers)
        );
      } else {
        myAnswers = initialBuyersAnswer;
      }

      setBuyerAnswers(myAnswers);

      return {
        isMeReachedToToFinish: isQuestionReachedTo(myAnswers, questionsTree, ['Finish']),
        buyerQuestions: questionsTree,
        answeredBuyers: answeredBuyers,
        anotherBuyersAnswer: anotherBuyersAnswer,
      };
    } else {
      return { buyerQuestions: null, isMeReachedToToFinish: false };
    }
  }, [
    buyerId,
    addNewQuestion,
    isQuestionReachedTo,
    checkAgreeTermsAndConditions,
    buyersQuestionsData?.onboardingBuyerQuestions,
  ]);

  const processAnswers = useCallback(
    (answers, oldAnswers, questionsTree, curAnswer, question, answerId, answerValue, withoutSave) => {
      checkMismatch(answers, questionsTree);

      const tempAnswers = [...answers];

      if (!withoutSave) {
        saveBuyerAnswers({
          variables: {
            input: {
              plumbidId,
              answers: answers
                .filter(answer => answer.answerId)
                .map(({ questionId, answerId, answerValue }) => ({
                  answerId,
                  answerValue: answerValue || null,
                  questionId,
                })),
            },
          },
        })
          .then(res => {
            if (res?.data?.changeBuyerAnswers?.errors) {
              showNotification({ error: res?.data?.changeBuyerAnswers?.errors });
              setBuyerAnswers(oldAnswers);
            } else {
              setBuyerAnswers(tempAnswers);
            }
          })
          .catch(error => {
            showNotification({ error });
            setBuyerAnswers(oldAnswers);
          })
          .finally(() => {
            setProcessing(false);
            setCanSubmit(
              isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']) && checkAgreeTermsAndConditions(tempAnswers)
            );
          });
      } else {
        setCanSubmit(
          isQuestionReachedTo(tempAnswers, questionsTree, ['Finish']) && checkAgreeTermsAndConditions(tempAnswers)
        );
        setBuyerAnswers(tempAnswers);
        setProcessing(false);
      }
    },
    [plumbidId, showNotification, saveBuyerAnswers, isQuestionReachedTo, checkAgreeTermsAndConditions]
  );

  const setAnswer = useCallback(
    (answerIndex, answerId, question, answerValue, withoutSave, touched) => {
      setProcessing(true);

      let newBuyerAnswers = cloneDeep(buyersAnswers);

      newBuyerAnswers[answerIndex].answerId = answerId;
      newBuyerAnswers[answerIndex].answerProgress = question[`progress${answerId}`];
      newBuyerAnswers[answerIndex].answerValue = answerValue;
      newBuyerAnswers[answerIndex].touched = touched;

      //remove next questions with branch === current branch
      const curBranch = newBuyerAnswers[answerIndex].branch;
      const countWithCurBranch = newBuyerAnswers.reduce((prev, cur, index) => {
        if (index > answerIndex && cur.branch === curBranch) {
          prev += 1;
        }
        return prev;
      }, 0);

      if (answerIndex < newBuyerAnswers.length - 1 && countWithCurBranch > 0) {
        newBuyerAnswers.splice(answerIndex + 1, countWithCurBranch);
      }

      //add next question with empty answer (if not exist)
      const nextQuestionId = question[answerId];
      newBuyerAnswers = addNewQuestion(nextQuestionId, newBuyerAnswers, answerIndex, buyerQuestionsInfo.buyerQuestions);

      processAnswers(
        newBuyerAnswers,
        buyersAnswers,
        buyerQuestionsInfo.buyerQuestions,
        {
          ...newBuyerAnswers[answerIndex],
        },
        question,
        answerId,
        answerValue,
        withoutSave
      );
    },
    [buyerQuestionsInfo.buyerQuestions, processAnswers, addNewQuestion, buyersAnswers]
  );

  const setAnswerValue = useCallback(
    (answerIndex, answerId, question, answerValue) => {
      setProcessing(true);
      let newSellerAnswers = cloneDeep(buyersAnswers);
      newSellerAnswers[answerIndex].answerId = answerId;
      newSellerAnswers[answerIndex].answerValue = answerValue;
      newSellerAnswers[answerIndex].touched = true;

      processAnswers(
        newSellerAnswers,
        buyersAnswers,
        buyerQuestionsInfo.sellerQuestions,
        {
          ...newSellerAnswers[answerIndex],
        },
        question,
        answerId,
        answerValue
      );
    },
    [buyerQuestionsInfo, processAnswers, buyersAnswers]
  );

  return {
    ...buyerQuestionsInfo,
    refetch,
    mismatch,
    canSubmit,
    setAnswer,
    buyerParty,
    processing,
    plumBidError,
    buyersAnswers,
    setAnswerValue,
    plumBidRefetch,
    saveBuyerAnswers,
    confirmBuyerAnswers,
    plumBidData: plumBid,
    confirmBuyerAnswersLoading,
    plumBidLoading: plumBidLoading || buyersQuestionsLoading,
  };
};
