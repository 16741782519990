import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import { Toggle, CardSlider, Text } from 'legos';
import { BaseCard } from './BaseCard';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';
import { PBox } from 'legos/PBox';

const daysMarks = (maxDays, minDays) => {
  const markerStep1 = Math.floor((maxDays - minDays) / 4);
  const markerStep2 = Math.floor(((maxDays - minDays) / 4) * 2);
  const markerStep3 = Math.floor(((maxDays - minDays) / 4) * 3);
  return new Array(maxDays - minDays + 1).fill(0).map((_, index) => {
    const mark = { value: index + minDays };
    switch (index) {
      case 0:
        mark.label = `${maxDays}`;
        break;

      case markerStep1:
        mark.label = maxDays - minDays > 10 ? `${maxDays - markerStep1}` : '';
        break;
      case markerStep2:
        mark.label = maxDays - minDays > 5 ? `${maxDays - markerStep2}` : '';
        break;
      case markerStep3:
        mark.label = maxDays - minDays > 10 ? `${maxDays - markerStep3}` : '';
        break;

      case maxDays - minDays:
        mark.label = `${minDays}`;
        break;
      default:
    }
    return mark;
  });
};

export const ContingencyCard = memo(
  ({
    name,
    field,
    style,
    value,
    improve,
    maxDays,
    minDays,
    loading,
    disabled,
    maxValue,
    valueDays,
    disableSlider,
    improveByLength,
    changeIncentive,
    sellerPrioritiesTitle,
    sellerPrioritiesValue,
  }) => {
    const responsive = useResponsive();

    const fieldLength = `${field}Length`;

    return (
      <BaseCard
        disabled={disabled}
        improve={!value ? improve : improveByLength}
        sellerPrioritiesValue={sellerPrioritiesValue}
        sellerPrioritiesTitle={sellerPrioritiesTitle}
        style={style}
        loading={loading}
      >
        <PBox mb={3} mt={4} height="73px">
          <Text
            disabled={disabled}
            variant="h2"
            display="inline"
            style={{ fontSize: responsive.mobileScreen ? 20 : 24 }}
          >
            {name}
          </Text>
          {!!value && (
            <>
              <Text
                disabled={disabled}
                variant={'h2'}
                display="inline"
                style={{ fontSize: responsive.mobileScreen ? 20 : 24 }}
              >
                {': '}
              </Text>
              <Text
                variant="h2"
                display="inline"
                disabled={disabled}
                style={{
                  fontSize: responsive.mobileScreen ? 20 : 24,
                  color: theme.palette.purple.light,
                }}
              >{`${valueDays} days`}</Text>
            </>
          )}
        </PBox>
        <Grid container alignItems="flex-start" style={{ height: responsive.padScreen ? 180 : 51 }}>
          <Grid item xs={12} md={4}>
            <Toggle disabled={disabled} value={value} onChange={newValue => changeIncentive(newValue, field)} />
          </Grid>
          {value && (
            <Grid item xs={12} md={8}>
              <CardSlider
                maxValue={maxValue}
                disabled={disabled || disableSlider}
                min={minDays}
                max={maxDays}
                step={1}
                track="inverted"
                value={maxDays + minDays - valueDays}
                marks={daysMarks(maxDays, minDays)}
                onChange={(_, newValue) => {
                  changeIncentive(maxDays + minDays - newValue, fieldLength, maxValue, true);
                }}
                onChangeCommitted={(_, newValue, valueHasChanged) => {
                  changeIncentive(maxDays + minDays - newValue, fieldLength, maxValue, false, valueHasChanged);
                }}
              />
            </Grid>
          )}
        </Grid>
      </BaseCard>
    );
  }
);
