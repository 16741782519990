import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, Box } from '@material-ui/core';

import { ROLES } from 'constant';
import { useChats, useResponsive } from 'utils/hooks';
import { CHAT_STATUS } from 'constant';
import { RouterLink, Icons } from 'legos';
import state from 'service/graphql/state';
import { useAuthState } from 'service/store';
import { LocalStorage } from 'utils/localStorage';
import { ADMIN_DASHBOARD, ADMIN_PLUMBID, ADMIN_USERS, ADMIN_CHATS, ADMIN_SANDBOX } from 'routes';
import { theme } from 'utils/theme';

const content = [
  { title: 'Dashboard', link: () => ADMIN_DASHBOARD, icon: Icons.Dashboard },
  { title: 'plumBids', link: () => ADMIN_PLUMBID, icon: Icons.House },
  { title: 'plumbid Sandbox', link: () => ADMIN_SANDBOX, icon: Icons.Sandbox },
  { title: 'Users', link: () => ADMIN_USERS, icon: Icons.UsersAdmin },
  { title: 'Support chat', link: () => ADMIN_CHATS, icon: Icons.Email },
];

export const AdminContent = () => {
  const responsive = useResponsive();

  const [{ me }] = useAuthState();

  const { pathname } = useLocation();
  const { chats } = useChats(undefined, { withSubscription: true });

  const getFontWeight = path => (path === pathname ? 700 : 400);

  useEffect(() => {
    if (me?.roles?.includes(ROLES.ADMIN)) {
      state.tutorialToggleActiveVar(false);
      LocalStorage.setTutorialToggle(false);
    }
  }, [me]);

  return content.map(item => (
    <Grid
      md
      item
      container
      key={item.title}
      xs={12}
      style={
        responsive.padScreen
          ? { borderBottom: `1px solid ${theme.palette.text.whisper}`, padding: '16px 0 16px 40px' }
          : null
      }
    >
      <RouterLink to={item.link()} style={{ textDecoration: 'none' }}>
        <Box pl={1} display="flex" alignItems="center" justifyContent="flex-start" style={{ position: 'relative' }}>
          <item.icon
            style={{
              width: 19,
              height: 19,
              minWidth: 19,
              minHeight: 19,
              marginRight: 8,
            }}
          />
          {item.link() === ADMIN_CHATS &&
          chats.some(chat => chat.status === CHAT_STATUS.UNREAD && chat.lastMessage.owner.id !== me?.id) ? (
            <div
              style={{
                top: -5,
                left: 27,
                width: 7,
                height: 7,
                borderRadius: '50%',
                position: 'absolute',
                backgroundColor: theme.palette.background.coralRed,
              }}
            />
          ) : null}
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              fontWeight: getFontWeight(item.link()),
            }}
          >
            {item.title}
          </Typography>
        </Box>
      </RouterLink>
    </Grid>
  ));
};
