export const SplashDot = () => {
  return (
    <>
      <style>
        {`
          @keyframes dot-splash-animation-1 {
            from { transform: scale(1); box-shadow: inset 0px 0px 2px 1px rgb(47, 245, 228), 0px 0px 2px 1px rgb(47, 245, 228); opacity: 0.2;}
            10% { transform: scale(1.15); box-shadow: inset 0px 0px 5px 2px rgb(27, 205, 198), 0px 0px 5px 2px rgb(27, 205, 198); opacity: 1;}
            50% { transform: scale(2.5); opacity: box-shadow: inset 0px 0px 7px 2px rgb(7, 185, 178), 0px 0px 7px 2px rgb(7, 185, 178); opacity: 0.5;}
              to { transform: scale(3.5); opacity: box-shadow: 0px 0px 13px 2px rgb(7, 185, 178); opacity: 0;}
          }
        `}
      </style>
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 15px)',
          left: 'calc(50% - 15px)',
          backgroundColor: 'rgba(7, 185, 178, 0.8)',
          height: 30,
          width: 30,
          borderRadius: '50%',
        }}
      />
      <div
        style={{
          zIndex: 100,
          boxSizing: 'border-box',
          position: 'absolute',
          top: 'calc(50% - 15px)',
          left: 'calc(50% - 15px)',
          height: 30,
          width: 30,
          borderRadius: '50%',
          animation: 'dot-splash-animation-1 2500ms linear infinite',
        }}
      />
      <div
        style={{
          zIndex: 100,
          boxSizing: 'border-box',
          position: 'absolute',
          top: 'calc(50% - 15px)',
          left: 'calc(50% - 15px)',

          height: 30,
          width: 30,
          borderRadius: '50%',
          animation: 'dot-splash-animation-1 2500ms 800ms linear infinite',
        }}
      />
    </>
  );
};
