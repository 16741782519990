import { state } from './state';

/**
 * @typedef {{
 *  loading: boolean,
 *  currentStep: number,
 *  error: boolean,
 *  plumBidId:(string|number),
 *  bidId: (string|number)
 * }} TypeNewState
 */

/** @param {TypeNewState} newState */
export const setBidProcessing = (newState = {}) => {
  state.bidProcessingVar({
    ...state.bidProcessingVar(),
    ...newState,
  });
};
