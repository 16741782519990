import React from 'react';
import { Container, Toolbar } from '@material-ui/core';

export const PageContainer = ({ children }) => {
  return (
    <>
      <Toolbar disableGutters>
        <Container disableGutters maxWidth="xl">
          {children}
        </Container>
      </Toolbar>
    </>
  );
};
