import gql from 'graphql-tag';

export const CALCULATE_BID_REST = gql`
  mutation CalculateBid_REST($input: CalculateBidInput!) {
    calculateBid(input: $input) @rest(path: "plum-bid/{args.input.plumbidId}/calculate-bid/", method: "POST") {
      errors
      success
      bid {
        cashDownPercentage
        amountDown
        appraisalContingency
        appraisalContingencyIncentive
        appraisalContingencyLength
        appraisalContingencyLengthIncentive
        buyerContingency
        buyerContingencyIncentive
        buyerWarranty
        buyerWarrantyIncentive
        downPaymentIncentive
        escrowIncentive
        escrowLength
        inspectionContingency
        inspectionContingencyIncentive
        inspectionContingencyLength
        inspectionContingencyLengthIncentive
        leaseBackIncentive
        leaseBackIncentiveDisplay
        loanContingency
        loanContingencyIncentive
        loanContingencyLength
        loanContingencyLengthIncentive
        mcai
        offerLeaseBack
        percentageDown
        plumBidAmount
        price
        limit
        sellerContingency
        sellerContingencyIncentive
        sellerContingencyIncentiveDisplay
        totalIncentives
      }
    }
  }
`;
