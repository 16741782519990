import gql from 'graphql-tag';

export const DELETE_PLUMBID_MUTATION = gql`
  mutation DeletePlumbid($input: DeletePlumBidInput!) {
    deletePlumbid(input: $input) {
      success
      errors
    }
  }
`;
