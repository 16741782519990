import React from 'react';
import { shape } from 'prop-types';

export const Facebook = ({ style }) => (
  <svg
    width="11"
    height="22"
    viewBox="0 0 11 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.99096 12.3743L10.5456 8.393H7.07883V5.80937C7.07883 4.72038 7.56293 3.65819 9.1157 3.65819H10.6915V0.268813C10.6915 0.268813 9.26168 0 7.8942 0C5.03946 0 3.17353 1.90713 3.17353 5.35906V8.39369H0V12.375H3.17353V22H7.07883V12.375L9.99096 12.3743Z"
      fill="white"
    />
  </svg>
);

Facebook.propTypes = {
  style: shape({}),
};

Facebook.defaultProps = {
  style: null,
};
