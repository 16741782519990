import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import { Box, DialogContent, Dialog, Typography, Grid, CircularProgress } from '@material-ui/core';

import { theme } from 'utils/theme';
import { formatAddressTwoLines } from 'utils';
import { useAuthState } from 'service/store/authStore';
import { Icons, NavigationButton } from 'legos';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { PropertyThumbnail } from 'components/PropertyThumbnail';
import { useNotification, useResponsive } from 'utils/hooks';
import { GET_PLUMBID_BY_ID_QUERY, UPDATE_PARTICIPANT_MUTATION } from 'service/graphql';
import state from 'service/graphql/state';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const GPReservePriceMetChangedDialog = ({ isOpen, onClose, plumBidId }) => {
  const classes = useStyles();
  const { mobileScreen } = useResponsive();
  const [{ me }] = useAuthState();
  const showNotification = useNotification();
  const [isOpenLocal, setIsOpenLocal] = useState(false);

  const [updateParticipantMutation] = useMutation(UPDATE_PARTICIPANT_MUTATION);

  const { data, refetch } = useQuery(GET_PLUMBID_BY_ID_QUERY, {
    variables: {
      id: plumBidId,
    },
    fetchPolicy: 'cache-only',
    skip: !isOpen,
  });

  useEffect(() => {
    if (!isOpen) {
      setIsOpenLocal(false);
    } else if (plumBidId) {
      refetch({
        variables: {
          id: plumBidId,
        },
      });
    }
  }, [isOpen, plumBidId, refetch]);

  const plumBid = useMemo(() => {
    if (data) {
      if (data.plumbidById) {
        const plumBidNormalized = normalizePlumBid(data?.plumbidById, false, me);
        setIsOpenLocal(true);
        return plumBidNormalized;
      } else {
        setIsOpenLocal(false);
      }
    }
  }, [data, me]);

  const onOK = useCallback(async () => {
    try {
      const { data } = await updateParticipantMutation({
        variables: {
          input: {
            participantId: plumBid?.myInfoInPlumBid?.participantId,
            reservePriceMetChangedPopup: false,
          },
        },
      });
      if (data.updateParticipant.success) {
        refetch().then(() => {
          const globalPlumBid = state.globalPlumBidPopupVar()?.[plumBidId];
          state.globalPlumBidPopupVar({
            ...state.globalPlumBidPopupVar(),
            [plumBidId]: {
              ...globalPlumBid,
              popupReservePriceMetChangedNeedToShow: false,
              popupReservePriceMetChangedShowed: false,
            },
          });
          setIsOpenLocal(false);
          onClose();
        });
      }
    } catch (error) {
      showNotification({
        error,
      });
    }
  }, [
    plumBidId,
    updateParticipantMutation,
    plumBid?.myInfoInPlumBid?.participantId,
    refetch,
    onClose,
    showNotification,
  ]);

  useEffect(() => {
    if (plumBid?.myInfoInPlumBid?.participantId) {
      setTimeout(onOK, 5000);
    }
  }, [onOK, plumBid?.myInfoInPlumBid?.participantId]);

  const renderAddress = () => {
    const address = formatAddressTwoLines(plumBid?.mls || {});

    return (
      <Box display="flex" alignItems="center" my={1}>
        <Icons.MapMarker />
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line1}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              fontWeight: 800,
              lineHeight: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {address.line2}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      onClose={onOK}
      open={isOpenLocal}
      className={classes.root}
      fullScreen={mobileScreen}
    >
      <Box position="absolute" top={0} right={0}>
        <NavigationButton icon="Close" size={35} onClick={onOK} />
      </Box>
      <DialogContent>
        <Box py={2} minHeight={450} display="flex" alignItems="center" justifyContent="center">
          {plumBid ? (
            <Grid container>
              <Grid item container xs={12} md={5} alignItems="center" direction="row">
                <Box p="30px" style={{ width: '100%', backgroundColor: theme.palette.background.solitude }}>
                  <PropertyThumbnail
                    aspectRatio={1 / 1}
                    src={plumBid.mls?.previewImage || plumBid.mls?.mlsinfophotoSet?.[0] || '/no_photo.png'}
                  />
                  <Box pt={2}>
                    <Box
                      px={2}
                      py={1}
                      display="flex"
                      borderRadius="3px"
                      width="fit-content"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor={theme.palette.background.highBid}
                    >
                      <Typography style={{ color: theme.palette.common.white }} variant="caption">
                        Active
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                      plumBid will finish:
                    </Typography>
                    <Typography align="left" style={{ fontSize: 18, fontWeight: 700 }}>
                      {moment(plumBid.finishDateTime).format('ddd, MMM DD [at] h:mm a ')}
                    </Typography>
                    {renderAddress()}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={7}>
                <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%" pl={2} pt={2}>
                  <Box mt={12}>
                    <Typography align="left" style={{ fontSize: 24, fontWeight: 600 }}>
                      {plumBid.reservePriceMet ? 'Reserve price is now met' : 'Reserve price is now not met'}
                    </Typography>
                  </Box>
                  <Box pb={2} display="flex" margin="auto">
                    <CircularProgress />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
