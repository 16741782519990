import { Typography } from '@material-ui/core';

import { NEW_PARTY_STATUS, PLUMBID_STATUS, PLUMBID_STATUS_NAME } from 'constant';
import { PBox } from 'legos';
import { theme } from 'utils/theme';

export const PlumBidStatusCard = ({ plumBid, invert, timeLeft }) => {
  const normalizedPlumBidStatus = invert ? PLUMBID_STATUS_NAME[plumBid.status] : plumBid.status;
  const status =
    normalizedPlumBidStatus === PLUMBID_STATUS.LIVE && timeLeft
      ? { text: 'Active', bgcolor: theme.palette.background.highBid }
      : normalizedPlumBidStatus === PLUMBID_STATUS.FINISHED
      ? { text: 'Finished', bgcolor: theme.palette.text.resolutionBlue }
      : normalizedPlumBidStatus === PLUMBID_STATUS.LIVE && !timeLeft
      ? { text: 'Processing', bgcolor: theme.palette.text.resolutionBlue }
      : normalizedPlumBidStatus === PLUMBID_STATUS.CANCELED
      ? { text: 'Canceled', bgcolor: theme.palette.text.fireBrick }
      : plumBid.sellerParty.status === NEW_PARTY_STATUS.COMPLETED && plumBid.buyerPartiesReady > 1
      ? { text: 'Ready', bgcolor: theme.palette.background.highBid }
      : normalizedPlumBidStatus === PLUMBID_STATUS.INCOMPLETE
      ? { text: 'Initializing', bgcolor: theme.palette.text.cornflowerBlue }
      : { text: 'Upcoming', bgcolor: theme.palette.text.summerSky };

  return (
    <PBox
      mr={0.5}
      mb={0.5}
      px={2}
      py={1}
      display="flex"
      borderRadius="3px"
      width="fit-content"
      alignItems="center"
      justifyContent="center"
      bgcolor={status.bgcolor}
    >
      <Typography style={{ color: theme.palette.common.white }} variant="caption">
        {status.text}
      </Typography>
    </PBox>
  );
};
