import gql from 'graphql-tag';

export const CREATE_PLUMBIDPARTY_MUTATION = gql`
  mutation CreatePlumbidPartyMutation($input: CreatePlumBidPartyInput!) {
    createPlumbidParty(input: $input) {
      success
      errors
      plumbidParty {
        id
        offerdoc {
          id
          parseEndedAt
        }
      }
    }
  }
`;
