import React from 'react';
import { string, func, bool } from 'prop-types';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const OkCancelDialog = ({
  isOpen,
  onClose,
  onOk,
  onCancel,
  title,
  text,
  text2,
  okTitle,
  cancelTitle,
  loading,
  hideCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title={title} onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4}>
          <Typography variant="h5" style={{ lineHeight: 1.5 }}>
            {text}
          </Typography>
          {text2 && (
            <Typography variant="h5" style={{ lineHeight: 1.5 }}>
              {text2}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent={hideCancel ? 'center' : 'flex-end'} mt={1} mb={2}>
          {!hideCancel && (
            <>
              <Button
                title={cancelTitle}
                variant="secondary"
                style={{ width: '30%', minWidth: 'fit-content' }}
                disabled={loading}
                onClick={onCancel ? onCancel : onClose}
              />
              <Box width={8} />
            </>
          )}
          <Button
            align={hideCancel && 'center'}
            title={okTitle}
            style={{ width: '35%', minWidth: 'fit-content' }}
            onClick={onOk}
            loading={loading}
            marginRight={3}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

OkCancelDialog.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onOk: func,
  title: string,
  text: string,
  okTitle: string,
  cancelTitle: string,
};

OkCancelDialog.defaultProps = {
  onOk: () => {},
  title: '',
  text: '',
  okTitle: 'OK',
  cancelTitle: 'Cancel',
};
