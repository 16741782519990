import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, LinearProgress, Box } from '@material-ui/core';

import { ONBOARDING_STATUS } from 'constant';
import { PropertyThumbnailNameAddress } from 'components';
import { NavigationButton, Divider, Button, Icons } from 'legos';
import { REVIEW_SELLER_ANSWER } from 'service/graphql';
import { useNotification, useResponsive } from 'utils/hooks';
import { Tooltip } from 'legos/Tooltip';
import { theme } from 'utils/theme';

export const QuestionSubHeader = ({
  plumbid,
  mismatch,
  progress,
  loading,
  isShowOnboarding,
  selectedSeller,
  refetchPlumbid,
}) => {
  const history = useHistory();
  const showNotification = useNotification();
  const { mobileScreen } = useResponsive();

  const [reviewedOnboardingMutation, { loading: reviewedOnboardingLoading }] = useMutation(REVIEW_SELLER_ANSWER);

  const setReviewedOnboarding = () => {
    reviewedOnboardingMutation({
      variables: {
        input: {
          plumbidId: plumbid.id,
          participantId: selectedSeller.id,
        },
      },
    })
      .then(res => {
        if (res?.data?.reviewSellerAnswers?.errors) {
          showNotification({
            error: res.data.reviewSellerAnswers.errors,
          });
        }
      })
      .catch(error => {
        showNotification({
          error,
        });
      })
      .finally(refetchPlumbid);
  };

  return (
    <Box
      id="question-sub-header"
      style={{
        margin: '0 auto',
        width: '100%',
        top: 64,
        right: 0,
        left: 'auto',
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 100,
      }}
    >
      <Grid container alignItems="center" justify="flex-start" spacing={1}>
        <Grid item container alignItems="center" xs={12} md={12} lg={5}>
          <NavigationButton
            fullWidth={false}
            onClick={() => history.goBack()}
            icon="LeftArrow"
            color={theme.palette.common.white}
            style={{
              backgroundColor: theme.palette.purple.light,
              marginRight: 18,
            }}
          />
          <Box pr={1} pt={0.5}>
            <PropertyThumbnailNameAddress property={plumbid?.mls} />
          </Box>
        </Grid>

        <Grid container alignItems="center" justify="space-between" item xs>
          <Grid
            item
            xs={12}
            md={selectedSeller && selectedSeller?.onboardingStatus?.[ONBOARDING_STATUS.REVIEW] ? 5 : 6}
          >
            <Box display="flex" alignItems="center">
              {mobileScreen && mismatch ? (
                <Tooltip
                  text={`A plumBid can only be launched if all sellers are in agreement on their priorities as selected in this
                  form. Some of your answers do not match; please confer with your co-seller in order to resolve any
                  discrepancies. Thank you!`}
                  placement="bottom"
                >
                  <Icons.QuestionMark />
                </Tooltip>
              ) : null}
              <Typography
                variant="h3"
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                }}
              >
                Seller Priority Questionnaire
              </Typography>
            </Box>

            {!mobileScreen && mismatch ? (
              <Typography
                variant="overline"
                style={{ color: theme.palette.text.warning, textTransform: 'none', height: 16 }}
              >
                A plumBid can only be launched if all sellers are in agreement on their priorities as selected in this
                form. Some of your answers do not match; please confer with your co-seller in order to resolve any
                discrepancies. Thank you!
              </Typography>
            ) : null}
          </Grid>
          {selectedSeller && selectedSeller?.onboardingStatus?.[ONBOARDING_STATUS.REVIEW] && (
            <Grid item xs={12} md={3}>
              <Button
                variant="secondary"
                title="Onboarding reviewed"
                onClick={setReviewedOnboarding}
                loading={reviewedOnboardingLoading}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3} id="tutorial__ProgressOnboarding">
            <Typography variant="subtitle1">{`Progress: ${
              loading ? ' . . .' : `${isShowOnboarding ? Math.ceil(progress) : 0}%`
            }`}</Typography>
            <LinearProgress
              variant="determinate"
              value={isShowOnboarding ? Math.ceil(progress) : 0}
              style={{
                height: 8,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ paddingTop: 4 }} />
    </Box>
  );
};
