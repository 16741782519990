import React from 'react';
import { shape } from 'prop-types';

export const CirclePlus = ({ style }) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5002 20.1668C16.5628 20.1668 20.6668 16.0628 20.6668 11.0002C20.6668 5.93755 16.5628 1.8335 11.5002 1.8335C6.43755 1.8335 2.3335 5.93755 2.3335 11.0002C2.3335 16.0628 6.43755 20.1668 11.5002 20.1668Z"
      stroke={style?.fill || style?.stroke || '#731E6B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 7.3335V14.6668"
      stroke={style?.fill || style?.stroke || '#731E6B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8335 11H15.1668"
      stroke={style?.fill || style?.stroke || '#731E6B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CirclePlus.propTypes = {
  style: shape({}),
};

CirclePlus.defaultProps = {
  style: null,
};
