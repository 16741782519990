import { useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';

import {
  GET_BIDS_REST,
  GET_SANDBOX_MINIMAL_BID_QUERY,
  GET_PLUMBID_BY_ID_FOR_OPTIMIZER,
  GET_SANDBOX_PLUMBID_BY_ID_AND_MINIMAL_BID,
} from 'service/graphql';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { useNotification } from './useNotification';
import { useAuthState } from 'service/store/authStore';
import { normalizeBids } from 'utils/normalizeBids';
import state from 'service/graphql/state';
import { POLLING_INTERVAL } from 'constant';
import { GET_MINIMAL_BID_REST } from 'service/graphql/query/getMinimalBid_REST';

export const usePlumbid = (isSandbox, { variables, onCompleted, fetchPolicy, nextFetchPolicy }) => {
  const [{ me }] = useAuthState();
  const isWSConnected = useReactiveVar(state.isWSConnectedVar);

  const showNotification = useNotification();
  const { data, loading, error: plumBidError, refetch: refetchPlumbidData, startPolling, stopPolling } = useQuery(
    isSandbox ? GET_SANDBOX_PLUMBID_BY_ID_AND_MINIMAL_BID : GET_PLUMBID_BY_ID_FOR_OPTIMIZER,
    {
      variables,
      ...(fetchPolicy ? { fetchPolicy } : null),
      ...(nextFetchPolicy ? { nextFetchPolicy } : null),
      onError: error => showNotification({ error }),
      context: {
        debounceKey: `${variables.id}`,
      },
    }
  );

  useEffect(() => {
    if (isSandbox || !isWSConnected) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }
  }, [isSandbox, isWSConnected, startPolling, stopPolling]);

  const [minimalBidAndBidsFetch, { data: minimalBidAndBidsData }] = useLazyQuery(GET_SANDBOX_MINIMAL_BID_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    onError: error => showNotification({ error }),
  });

  const [minimalBidFetch, { data: minimalBidData }] = useLazyQuery(GET_MINIMAL_BID_REST, {
    variables: { input: variables },
    fetchPolicy: 'network-only',
    onError: error => showNotification({ error }),
  });

  const [bidsFetch, { data: bidsData, loading: bidsHistoryLoading }] = useLazyQuery(GET_BIDS_REST, {
    variables: { input: variables },
    fetchPolicy: 'network-only',
    onError: error => showNotification({ error }),
  });

  const plumbidData = useMemo(
    () =>
      isSandbox
        ? data?.sbPlumbidById
          ? normalizePlumBid(data?.sbPlumbidById, isSandbox, me)
          : null
        : data?.plumbidById
        ? normalizePlumBid(data?.plumbidById, false, me)
        : null,
    [data?.plumbidById, isSandbox, data?.sbPlumbidById, me]
  );

  useEffect(() => {
    if (isSandbox) {
      minimalBidAndBidsFetch();
    } else {
      minimalBidFetch();
      bidsFetch();
    }
  }, [bidsFetch, data?.plumbidById?.lastBid?.id, isSandbox, minimalBidAndBidsFetch, minimalBidFetch]);

  const bidsHistory = useMemo(() => {
    return [
      ...(isSandbox
        ? normalizeBids(data?.sbPlumbidById?.sbbidSet ?? [], plumbidData?.buyerParty)
        : normalizeBids(bidsData?.bids?.bids ?? [], plumbidData?.buyerParty)),
    ].sort((a, b) => b.plumBidAmount - a.plumBidAmount);
  }, [isSandbox, data?.sbPlumbidById?.sbbidSet, plumbidData?.buyerParty, bidsData?.bids?.bids]);

  const minimalBid = useMemo(
    () =>
      isSandbox
        ? minimalBidAndBidsData?.sbMinimalBid || data?.sbMinimalBid || 0
        : minimalBidData?.minimalBid?.minimalBid || 0,
    [isSandbox, minimalBidAndBidsData?.sbMinimalBid, data?.sbMinimalBid, minimalBidData?.minimalBid?.minimalBid]
  );

  return {
    plumBidError,
    plumbidData,
    bidsHistory,
    bidsHistoryLoading,
    minimalBid,
    plumbidLoading: loading,
    refetchPlumbidData,
  };
};
