import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import { Input, Button, NavigationButton } from 'legos';
import { FORGOT_PASSWORD_MUTATION } from 'service/graphql';
import { useSettingsState } from 'service/store';
import { useNotification } from 'utils/hooks';

const ForgotPasswordValidationSchema = object().shape({
  email: string().trim().required('Email is required').email('Invalid email').nullable(),
});

export const ForgotPasswordScreen = () => {
  const [{ drawerScreenGoBack }, dispatchSettings] = useSettingsState();
  const showNotification = useNotification();
  const inviteToken = queryString.parse(sessionStorage.getItem('inv'));

  const goBack = () =>
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: drawerScreenGoBack,
        drawerScreenGoBack: drawerScreenGoBack,
      },
    });
  const [isInputOrSuccess, setIsInputOrSuccess] = useState(true);
  const [forgotPasswordMutation, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION);

  const onClick = () => {
    if (isInputOrSuccess) {
      formik.submitForm();
    } else {
      goBack();
      setTimeout(() => setIsInputOrSuccess(false), 100);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordValidationSchema,
    onSubmit: async values => {
      try {
        const { data } = await forgotPasswordMutation({
          variables: { input: { email: values.email, inviteToken: inviteToken['/invite?token'] } },
        });
        if (data?.resetPassword.success) {
          setIsInputOrSuccess(false);
        } else {
          setIsInputOrSuccess(data.resetPassword.errors[0]);
        }
      } catch (error) {
        showNotification({ error });
      }
    },
  });
  return (
    <Box display="flex" flexDirection="column" p={3.75}>
      <Box display="flex" alignItems="center">
        <NavigationButton icon="LeftArrow" onClick={goBack} />
        <Typography variant="h3" style={{ fontWeight: 800, marginLeft: 8 }}>
          FORGOT PASSWORD
        </Typography>
      </Box>
      <Box my={4}>
        {isInputOrSuccess ? (
          <>
            <Typography variant="subtitle1">{isInputOrSuccess}</Typography>
            <Input
              autoFocus
              label="Email"
              type="email"
              value={formik.values.email}
              onChange={event => formik.setFieldValue('email', event.target.value)}
              error={formik.touched.email && formik.errors.email}
            />
          </>
        ) : (
          <Typography variant="subtitle1">
            {'✓    Password reset instructions has been successfully sent to your email'}
          </Typography>
        )}
      </Box>
      <Button
        title={isInputOrSuccess ? 'Remind the password' : 'Go to login page'}
        onClick={onClick}
        icon="RightArrow"
        loading={loading}
      />
    </Box>
  );
};
