/* eslint-disable no-nested-ternary */
import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { string, number, func, bool, elementType, oneOfType } from 'prop-types';

import { Icons } from './Icons';
import { theme } from 'utils/theme';

const useTextFieldStyle = makeStyles({
  root: {
    marginTop: 2,
    marginBottom: 2,
    '& .MuiInputBase-inputMultiline': {
      paddingTop: 8,
    },
    '& .MuiTextField-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-root': {
      fontFamily: '"Helvetica", "Roboto", "Arial", sans-serif',
      fontWeight: 700,
      color: props => (props.disabled ? theme.palette.disabled : theme.palette.text.primary),
      fontSize: 14,
      transform: 'none',
      lineHeight: 1.2,
    },
    '& .MuiFormHelperText-root': {
      fontSize: 12,
      fontWeight: 400,
    },
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      position: 'relative',
      height: 23,
      color: props => props.color || 'inherit',
      fontSize: props => props.fontSize,
      paddingLeft: props => (props.variant === 'rounded' ? 32 : 13),
      paddingTop: props => (props.variant === 'rounded' ? 11 : 8),
      paddingBottom: props => (props.variant === 'rounded' ? 12 : 8),
      textAlign: props => props.textAlign,
      marginTop: props => (props.label ? 6 : 0),
      paddingRight: props => (props.error ? 25 : 4),
      backgroundColor: props =>
        props.backgroundColor
          ? props.backgroundColor
          : props.variant === 'rounded'
          ? theme.palette.background.paper
          : theme.palette.background.whiteSmoke,
      outline: props =>
        `2px solid ${
          props.error ? theme.palette.error.main : props.warning ? theme.palette.warning.main : 'transparent'
        }`,
      borderBottom: props =>
        `1px solid ${
          props.error
            ? 'transparent'
            : props.variant === 'rounded'
            ? theme.palette.divider
            : theme.palette.text.suvaGrey
        }`,
      border: props =>
        `1px solid ${props.error ? 'transparent' : props.variant === 'rounded' ? theme.palette.divider : null}`,
      borderRadius: props => (props.variant === 'rounded' ? 2 : 0),
    },
    '& .Mui-disabled': {
      color: theme.palette.disabled,
    },
    '& .MuiInput-input:focus': {
      outline: props =>
        `2px solid ${
          props.error ? theme.palette.error.main : props.variant === 'rounded' ? 'none' : theme.palette.purple.dark
        }`,
      borderBottom: props => (props.variant === 'rounded' ? null : '1px solid transparent'),
    },
  },
});

export const Input = ({
  label,
  error,
  warning,
  placeholder,
  value,
  onChange,
  endAdornment,
  startAdornment,
  disabled,
  textAlign,
  fontSize,
  fullWidth,
  textArea,
  rows,
  rowsMax,
  rowsMin,
  variant,
  color,
  backgroundColor,
  onClear,
  clear,
  ...props
}) => {
  const classes = useTextFieldStyle({
    color,
    value,
    error,
    warning,
    disabled,
    label,
    textAlign,
    fontSize,
    variant,
    backgroundColor,
  });

  const EndAdornment = endAdornment ? Icons[endAdornment] : null;
  const StartAdornment = startAdornment ? Icons[startAdornment] : null;
  const id = (Math.random() * 100000).toString();
  return (
    <TextField
      id={id}
      fullWidth={fullWidth}
      disabled={disabled}
      placeholder={placeholder}
      className={classes.root}
      error={!!error}
      helperText={typeof error === 'string' ? error : ''}
      label={label}
      rows={rows}
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        id,
        ...(textArea
          ? {
              inputComponent: 'textarea',
              rows,
              rowsMin,
              multiline: true,
            }
          : null),

        disableUnderline: true,
        startAdornment: startAdornment && (
          <label
            htmlFor={id}
            style={{
              position: 'absolute',
              zIndex: 1,
              height: 16,
              width: 16,
              left: 8,
              bottom: 16,
            }}
          >
            <StartAdornment />
          </label>
        ),
        endAdornment: error ? (
          <Icons.Error
            style={{
              position: 'absolute',
              height: 16,
              width: 16,
              right: 8,
              bottom: 12,
            }}
          />
        ) : warning ? (
          <Icons.WarningCircle
            style={{
              position: 'absolute',
              height: 16,
              width: 16,
              right: 8,
              bottom: 12,
            }}
          />
        ) : endAdornment ? (
          <label
            htmlFor={id}
            style={{
              bottom: '50%',
              transform: 'translate(0, 60%)',
              position: 'absolute',
              right: 8,
            }}
          >
            <EndAdornment />
          </label>
        ) : clear ? (
          <label
            htmlFor={id}
            style={{
              bottom: '50%',
              transform: 'translate(0, 60%)',
              position: 'absolute',
              right: 4,
              top: -22,
            }}
          >
            <IconButton onClick={() => onClear('')}>
              <Icons.Close style={{ width: 12, height: 12, fill: theme.palette.purple.light }} />
            </IconButton>
          </label>
        ) : null,
      }}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

Input.propTypes = {
  label: string,
  error: string,
  placeholder: string,
  value: string,
  onChange: func,
  disabled: bool,
  textAlign: string,
  fontSize: oneOfType([string, number]),
  endAdornment: elementType,
  startAdornment: elementType,
  fullWidth: bool,
  textArea: bool,
  rows: oneOfType([string, number]),
  rowsMax: oneOfType([string, number]),
  rowsMin: oneOfType([string, number]),
  variant: string,
};

Input.defaultProps = {
  label: null,
  error: null,
  placeholder: null,
  value: null,
  disabled: false,
  onChange: () => {},
  fontSize: 14,
  textAlign: 'start',
  endAdornment: null,
  startAdornment: null,
  fullWidth: true,
  textArea: false,
  rows: 1,
  rowsMax: null,
  rowsMin: 1,
  variant: 'rectangle',
};
