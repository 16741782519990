import React from 'react';
import { shape } from 'prop-types';

export const Moon = ({ style }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, fill: '#fff' }}
    fill="#fff"
  >
    <path
      d="M12 6.9955C11.9037 8.03794 11.5124 9.0314 10.8721 9.85961C10.2317 10.6878 9.36877 11.3165 8.38415 11.6722C7.39952 12.0278 6.33397 12.0957 5.31217 11.8679C4.29038 11.6401 3.3546 11.1259 2.61434 10.3857C1.87408 9.6454 1.35995 8.70962 1.13211 7.68783C0.904275 6.66603 0.972155 5.60048 1.32781 4.61585C1.68346 3.63123 2.31218 2.76825 3.14039 2.12791C3.9686 1.48756 4.96206 1.09634 6.0045 1C5.39418 1.82569 5.10049 2.84302 5.17685 3.86695C5.25321 4.89087 5.69454 5.85339 6.42058 6.57942C7.14661 7.30546 8.10913 7.74679 9.13305 7.82315C10.157 7.89951 11.1743 7.60582 12 6.9955V6.9955Z"
      stroke={style?.fill || '#731E6B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Moon.propTypes = {
  style: shape({}),
};

Moon.defaultProps = {
  style: null,
};
