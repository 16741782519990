import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSettingsState } from 'service/store';

import { SELLERS, CONTACTS } from 'routes';
import QuestionsSectionJpg from '../../assets/images/QuestionsSection.jpg';

import { RouterLink, LinkButton } from 'legos';
import { useAuthState } from 'service/store/authStore';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const QuestionsSection = () => {
  const { pathname } = useLocation();
  const [{ isOpenDrawer }, dispatchSettings] = useSettingsState();
  const [{ me }] = useAuthState();
  const { mdScreen, tabletScreen } = useResponsive();

  const handleOpenDrawer = (event) => {
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: !isOpenDrawer,
        drawerScreen: 'SignInScreen',
      },
    });
  };

  const handleSendAgentEmail = () => {
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: !isOpenDrawer,
        drawerScreen: 'SendAgentEmailScreen',
      },
    });
  };

  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '15px 0' : '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid item container xs={12} md={6} alignItems="center" justify="center">
        <Box px={{ xs: 4, md: 10 }} pt={2}>
          {pathname === SELLERS ? (
            <>
              <Typography variant="h1" style={{ fontWeight: 700 }}>
                Already on the market?
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                }}
              >
                If you are already actively selling your property, please let
                your agent know about the benefits and ability of <b>plumBid</b>{' '}
                to generate the best price and terms in any multiple offer
                situation.
                {!me && (
                  <RouterLink
                    className="removeDecorators"
                    onClick={handleSendAgentEmail}
                    style={{
                      fontSize: 18,
                      paddingLeft: 4,
                      fontWeight: 700,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Let your agent know about plumBid.
                  </RouterLink>
                )}
              </Typography>
              <Typography
                variant="h1"
                style={{ fontSize: 18, fontWeight: 700 }}
              >
                Thinking of selling?
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                }}
              >
                Through our relationship with Leading Real Estate Companies of
                the World, (LeadingRE) plumBid has direct access to unparalleled
                real estate resources, including sales agents that provide
                exceptional service and results. Should you desire skilled
                representation to sell your property,
                <RouterLink
                  className="removeDecorators"
                  to={CONTACTS}
                  style={{
                    fontSize: 18,
                    paddingLeft: 4,
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                  }}
                >
                  please contact us.
                </RouterLink>
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h1"
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  paddingTop: mdScreen ? 0 : 54,
                }}
              >
                On the market, but not yet in multiples?
              </Typography>
              <Typography
                variant="h3"
                style={{ fontSize: 18, lineHeight: '27px', padding: '26px 0' }}
              >
                Let us know if you have any current or upcoming listings that
                you anticipate a multiple offer situation - and we’ll get
                everything ready to generate the best price and terms. Expecting
                multiple offers?
                <br />
                <LinkButton
                  onClick={handleOpenDrawer}
                  title="Click here."
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                    textDecorationColor: theme.palette.primary.main,
                  }}
                />
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        xs={12}
        md={6}
        style={
          mdScreen ? { padding: 24 } : { paddingRight: tabletScreen ? 40 : 88 }
        }
      >
        <img
          style={{ width: '100%', borderRadius: '38px' }}
          src={QuestionsSectionJpg}
          alt=""
        />
      </Grid>
    </Grid>
  );
};
