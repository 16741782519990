import { LinkButton } from 'legos';

export const AnswerActions = ({
  number,
  answer,
  question,
  isDisabledAccept,
  handleAccept,
  answerFieldValue,
  handleClose,
}) => {
  return (
    <>
      <LinkButton
        color="link"
        icon="Accept"
        iconSize={16}
        style={{
          padding: 8,
          marginLeft: 8,
          marginTop: 8,
          borderRadius: '50%',
          background: '#0000000D',
        }}
        disabled={isDisabledAccept}
        onClick={() => {
          handleAccept(answerFieldValue, number, answer, question);
        }}
      />
      <LinkButton
        icon="Close"
        color="link"
        iconSize={10}
        style={{
          marginTop: 8,
          marginLeft: 8,
          padding: 12,
          borderRadius: '50%',
          background: '#0000000D',
        }}
        onClick={handleClose}
      />
    </>
  );
};
