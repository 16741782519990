import React from 'react';

import { PageContainer, SellerOnboardingContainer } from 'components';

export const SellerOnboardingPage = () => {
  return (
    <PageContainer>
      <SellerOnboardingContainer />
    </PageContainer>
  );
};
