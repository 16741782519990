import gql from 'graphql-tag';

import { BidType } from '../types';

export const CREATE_BID_MUTATION = gql`
  mutation CreateBidMutation($input: CreateBidInput!) {
    createBid(input: $input) {
      success
      errors
      bid {
        id
        ${BidType}
      }
    }
  }
`;
