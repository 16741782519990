import React from 'react';
import { Typography, Grid, Box, CircularProgress } from '@material-ui/core';

import { PageContainer, LivePlumBidsList, NewPlumBidsList, ListingAgentTable, MonthlyStatistic } from 'components';
import { useDashboardAdmin, usePageLoading } from 'utils/hooks';

export const AdminDashboardPage = () => {
  const {
    livePlumBids,
    newPlumbids,
    statisticCount,
    joinedListingAgents,
    dashboardAdminLoading,
    refetchDashboardAdminData,
  } = useDashboardAdmin();

  usePageLoading(dashboardAdminLoading, 'AdminDashboardPage');

  return (
    <PageContainer>
      {dashboardAdminLoading ? (
        <Box display="flex" justifyContent="center" mt={40}>
          <CircularProgress size={100} />
        </Box>
      ) : (
        <Box px={6}>
          <Box py={5}>
            <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
              Admin Dashboard
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid container spacing={2} item xs={6} direction="column">
              <Grid item>
                <ListingAgentTable
                  joinedListingAgents={joinedListingAgents}
                  refetchDashboardAdminData={refetchDashboardAdminData}
                />
              </Grid>
              <Grid item>
                <MonthlyStatistic statisticCount={statisticCount} />
              </Grid>
              <Grid item>
                <NewPlumBidsList newPlumbids={newPlumbids} />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <LivePlumBidsList livePlumBids={livePlumBids} />
            </Grid>
          </Grid>
        </Box>
      )}
    </PageContainer>
  );
};
