import React, { useCallback, useEffect } from 'react';

import { HomePageContainer, PageContainer } from 'components';
import { useSettingsState } from 'service/store/settingsStore';
import { useAuthState } from 'service/store/authStore';

export const HomePage = () => {
  const [{ me, loggedIn, redirectPath }] = useAuthState();
  const [, dispatchSettings] = useSettingsState();

  const handleOpenDrawer = useCallback(() => {
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: 'SignInScreen',
      },
    });
  }, [dispatchSettings]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (redirectPath && (!loggedIn || !me)) {
      handleOpenDrawer();
    }
  }, [handleOpenDrawer, loggedIn, me, redirectPath]);

  return (
    <PageContainer>
      <HomePageContainer />
    </PageContainer>
  );
};
