import { useQuery } from '@apollo/client';

import { GET_INVITE_EMAIL_TEXT_QUERY } from 'service/graphql';
import { useNotification } from './useNotification';

export const useInviteEmailText = (plumBid) => {
  const showNotification = useNotification();
  const { data, loading, refetch } = useQuery(GET_INVITE_EMAIL_TEXT_QUERY, {
    variables: {
      plumbidId: plumBid?.id,
    },
    skip: !plumBid?.id || !plumBid?.mls,
    onError: error => showNotification({ error }),
  });
  return {
    inviteEmailText: data?.inviteEmailText || [],
    inviteEmailTextLoading: loading,
    inviteEmailTextRefetch: refetch
  };
};
