import React from 'react';
import { shape } from 'prop-types';

export const MyBid = ({ style }) => (
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <circle cx="13.5" cy="14" r="13.5" fill="#731E6B" />
    <path
      d="M20.4319 21.1694C20.6438 20.9551 20.7627 20.6658 20.7627 20.3644C20.7627 20.063 20.6438 19.7738 20.4319 19.5595L12.6563 11.7891L11.0723 13.3938L18.853 21.1719C18.9565 21.2761 19.0796 21.3587 19.2152 21.415C19.3508 21.4712 19.4962 21.5001 19.643 21.4999C19.7898 21.4996 19.9351 21.4703 20.0705 21.4136C20.2059 21.3568 20.3287 21.2738 20.4319 21.1694Z"
      fill="white"
    />
    <path d="M10.8153 7.0166L6.35254 11.531L9.36044 14.5751L13.8232 10.0581L10.8153 7.0166Z" fill="white" />
    <path
      d="M6.10405 11.8304C6.05029 11.7752 5.98597 11.7315 5.91493 11.7017C5.8439 11.672 5.76761 11.6569 5.6906 11.6572C5.61367 11.6574 5.53755 11.6728 5.4666 11.7026C5.39564 11.7323 5.33126 11.7757 5.27714 11.8304L5.01873 12.0888C4.90901 12.2021 4.84766 12.3536 4.84766 12.5113C4.84766 12.669 4.90901 12.8205 5.01873 12.9338L7.9827 15.9184C8.09387 16.0252 8.24202 16.0848 8.39615 16.0848C8.55028 16.0848 8.69844 16.0252 8.80961 15.9184L9.06802 15.66C9.1788 15.5478 9.24092 15.3965 9.24092 15.2388C9.24092 15.0811 9.1788 14.9298 9.06802 14.8176L6.10405 11.8304Z"
      fill="white"
    />
    <path
      d="M14.0705 9.75811C14.181 9.86607 14.3294 9.92651 14.4839 9.92651C14.6384 9.92651 14.7868 9.86607 14.8974 9.75811L15.1558 9.4997C15.2666 9.38749 15.3287 9.23616 15.3287 9.07849C15.3287 8.92081 15.2666 8.76948 15.1558 8.65728L12.1918 5.67264C12.1377 5.61795 12.0734 5.57453 12.0024 5.54489C11.9314 5.51526 11.8553 5.5 11.7784 5.5C11.7015 5.5 11.6253 5.51526 11.5543 5.54489C11.4834 5.57453 11.419 5.61795 11.3649 5.67264L11.1065 5.93105C10.9957 6.04325 10.9336 6.19458 10.9336 6.35226C10.9336 6.50993 10.9957 6.66126 11.1065 6.77347L14.0705 9.75811Z"
      fill="white"
    />
  </svg>
);

MyBid.propTypes = {
  style: shape({}),
};

MyBid.defaultProps = {
  style: {
    fill: 'black',
  },
};
