import React from 'react';
import { shape } from 'prop-types';

export const JoinedAgent = ({ style }) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M15.2222 17V15.2222C15.2222 14.2792 14.8476 13.3748 14.1808 12.7081C13.514 12.0413 12.6096 11.6667 11.6667 11.6667H4.55555C3.61256 11.6667 2.70819 12.0413 2.0414 12.7081C1.3746 13.3748 1 14.2792 1 15.2222V17"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.11097 8.1111C10.0746 8.1111 11.6665 6.51923 11.6665 4.55555C11.6665 2.59187 10.0746 1 8.11097 1C6.14729 1 4.55542 2.59187 4.55542 4.55555C4.55542 6.51923 6.14729 8.1111 8.11097 8.1111Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5553 17V15.2222C20.5547 14.4344 20.2925 13.6691 19.8099 13.0465C19.3272 12.4239 18.6515 11.9792 17.8887 11.7822"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3333 1.11554C15.0981 1.31136 15.7759 1.75616 16.26 2.37981C16.7441 3.00347 17.0069 3.7705 17.0069 4.55998C17.0069 5.34946 16.7441 6.11649 16.26 6.74014C15.7759 7.3638 15.0981 7.8086 14.3333 8.00442"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

JoinedAgent.propTypes = {
  style: shape({}),
};

JoinedAgent.defaultProps = {
  style: null,
};
