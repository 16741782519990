import React, { useEffect } from 'react';

import { PageContainer, UserPlumBidsContainer } from 'components';

export const UserPlumBidsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <UserPlumBidsContainer />
    </PageContainer>
  );
};
