import { useEffect, useCallback } from 'react';

import { PLUMBID_STATUS } from 'constant';
import state from 'service/graphql/state';
import { useReactiveVar } from '@apollo/client';
import { timeLeftFormat } from 'utils/formatters';

export const useTimeLeft = (plumbid, resultBool) => {
  const time = useReactiveVar(state.plumBidsTimerVar);

  const setTime = useCallback(() => {
    if (!plumbid) {
      return;
    }

    if (plumbid?.status === PLUMBID_STATUS.LIVE && time[plumbid.id]) {
      const timeLeftDashboard = time[plumbid.id].timeLeft + time[plumbid.id].responseTime - Date.now();
      const timeLeftString = timeLeftFormat(timeLeftDashboard);
      state.plumBidsTimerLeftVar({
        ...state.plumBidsTimerLeftVar(),
        [plumbid.id]: timeLeftString,
        [`${plumbid.id}dashboard`]: timeLeftDashboard,
      });
    } else {
      state.plumBidsTimerLeftVar({
        ...state.plumBidsTimerLeftVar(),
        [plumbid.id]: '',
      });
    }
  }, [plumbid, time]);

  useEffect(() => {
    setTime();
    let timer;
    if (plumbid?.status === PLUMBID_STATUS.LIVE) {
      timer = setInterval(setTime, 1000);

      return () => {
        if (timer) {
          clearInterval(timer);
        }
        if (plumbid?.id) {
          state.plumBidsTimerLeftVar({
            ...state.plumBidsTimerLeftVar(),
            [plumbid.id]: '',
          });
        }
      };
    }
  }, [plumbid?.status, resultBool, plumbid, time, setTime]);
};
