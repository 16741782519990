import { Box, Typography, ButtonBase } from '@material-ui/core';

import { formatNumberToUSD } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const QuestionTitleCard = ({ data, index, onClick, disabled }) => {
  const responsive = useResponsive();
  return (
    <Box>
      <ButtonBase
        TouchRippleProps={{
          style: {
            color: theme.palette.purple.ripple,
          },
        }}
        style={{
          padding: '22px',
          height: 128,
          width: responsive.mobileScreen ? 250 : 300,
          border: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <Box pb={1}>
          <Typography align="center" style={{ fontSize: 18, fontWeight: 700 }}>
            {formatNumberToUSD(data.amount, { hidePlusSign: true })}
          </Typography>
        </Box>
        <Typography align="center" style={{ fontSize: 18, fontWeight: 400 }}>
          {data.text}
        </Typography>
        {data.text1 ? (
          <Typography align="center" style={{ fontSize: 18, fontWeight: 400 }}>
            {data.text1}
          </Typography>
        ) : null}
      </ButtonBase>
      <Typography
        style={{
          paddingTop: 8,
          color: theme.palette.text.disabled,
          fontStyle: 'italic',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        #{index}
      </Typography>
    </Box>
  );
};
