import React, { useState } from 'react';
import Box from '@material-ui/core/Box';

import { func, object } from 'prop-types';

import DayPicker, { DateUtils } from 'react-day-picker';

import { SelectForYear } from 'legos';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { theme } from 'utils/theme';

export const Calendar = ({ markedDays, month, onChangeDateRange, onChangeMonth }) => {
  const [range, setRange] = useState({
    from: null,
    to: null,
  });

  const handleDayClick = day => {
    if (DateUtils.isDayInRange(day, range)) {
      setRange({
        from: null,
        to: null,
      });

      onChangeDateRange({
        fromDate: null,
        toDate: null,
      });
    } else if (markedDays.some(markedDate => moment(day).isSame(markedDate, 'date'))) {
      setRange({
        from: day,
        to: day,
      });

      onChangeDateRange({
        fromDate: moment(day),
        toDate: moment(day),
      });
    } else {
      setRange({
        from: null,
        to: null,
      });

      onChangeDateRange({
        fromDate: null,
        toDate: null,
      });
    }
  };

  const renderDay = (day, { selected }) => {
    const momentDate = moment(day);

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '30px', width: '30px', position: 'relative' }}
      >
        {markedDays.some(day => momentDate.isSame(moment(day), 'day')) && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 12,
              backgroundColor: 'red',
              height: 6,
              width: 6,
              borderRadius: 3,
            }}
          />
        )}
        <Box>{day.getDate()}</Box>
      </Box>
    );
  };

  const { from, to } = range;
  const modifiers = { start: from, end: to };

  return (
    <DayPicker
      captionElement={({ date, localeUtils }) => (
        <SelectForYear date={date} localeUtils={localeUtils} onChange={onChangeMonth} />
      )}
      renderDay={renderDay}
      enableOutsideDaysClick={false}
      month={month}
      modifiersStyles={{
        selected: {
          backgroundColor: theme.palette.purple.light,
          color: 'white',
        },
      }}
      modifiers={modifiers}
      selectedDays={[from, { from, to }]}
      onDayClick={handleDayClick}
      onMonthChange={date => {
        setRange({
          from: null,
          to: null,
        });
        onChangeMonth(date);
      }}
    />
  );
};

Calendar.propTypes = {
  month: object,
  onChangeDateRange: func,
  onChangeMonth: func,
};

Calendar.defaultProps = {
  month: new Date(),
  onChangeDateRange: () => {},
  onChangeMonth: () => {},
};
