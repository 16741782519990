import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Tooltip, Zoom } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { PLUMBID_STATUS } from 'constant';
import { withStyles } from '@material-ui/styles';
import { useNotification } from 'utils/hooks';
import {
  GET_PLUMBID_BY_ID_OPTIMIZED_QUERY,
  GET_SANDBOX_PLUMBID_BY_ID_AND_MINIMAL_BID,
  SANDBOX_USER_UPDATE_MUTATION,
  UPDATE_PARTICIPANT_MUTATION,
} from 'service/graphql';
import { LocalStorage } from 'utils/localStorage';
import { TimeLeft } from './TimeLeft';

const CustomToolTip = withStyles(() => ({
  tooltip: {
    padding: 6,
    fontSize: 16,
  },
}))(Tooltip);

export const ResetTimeContainer = memo(({ plumBid, isSandbox, isWinner, openTooltip, setOpenTooltip }) => {
  const isPlumBidActive = plumBid?.status === PLUMBID_STATUS.LIVE;
  const [updateParticipantMutation, { loading }] = useMutation(UPDATE_PARTICIPANT_MUTATION, {
    refetchQueries: [
      {
        query: GET_PLUMBID_BY_ID_OPTIMIZED_QUERY,
        variables: {
          id: plumBid.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [updateSBUserMutation, { loading: sbLoading }] = useMutation(SANDBOX_USER_UPDATE_MUTATION, {
    refetchQueries: [
      {
        query: GET_SANDBOX_PLUMBID_BY_ID_AND_MINIMAL_BID,
        variables: {
          id: plumBid.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const sandboxKey = LocalStorage.getSandboxKey();
  const showNotification = useNotification();

  const timeLeftIncrease = useMemo(
    () =>
      isSandbox
        ? plumBid?.sbplumbidpartySet?.some(party =>
            party.sbparticipantSet?.edges?.some(
              ({ node }) => node.user?.key === sandboxKey && node.user?.timeLeftIncrease
            )
          )
        : plumBid.myInfoInPlumBid?.myPopupStatus?.timeLeftIncrease,
    [isSandbox, plumBid.myInfoInPlumBid?.myPopupStatus?.timeLeftIncrease, plumBid?.sbplumbidpartySet, sandboxKey]
  );

  const handleTooltipOpen = useCallback(() => {
    setOpenTooltip(true);
  }, [setOpenTooltip]);
  const handleTooltipClose = useCallback(() => {
    setOpenTooltip(false);
  }, [setOpenTooltip]);

  useEffect(() => {
    if (
      (plumBid.myInfoInPlumBid?.participantId || isSandbox) &&
      !openTooltip &&
      (!loading || !sbLoading) &&
      timeLeftIncrease
    ) {
      handleTooltipOpen();
      if (isSandbox) {
        updateSBUserMutation({
          variables: {
            input: {
              sandboxKey: sandboxKey,
              timeLeftIncrease: false,
            },
          },
        });
      } else {
        updateParticipantMutation({
          variables: {
            input: {
              participantId: plumBid.myInfoInPlumBid.participantId,
              timeLeftIncreasePopup: false,
            },
          },
        });
      }
      setTimeout(() => handleTooltipClose(), 5000);
    }
  }, [
    handleTooltipClose,
    handleTooltipOpen,
    isSandbox,
    loading,
    openTooltip,
    plumBid.myInfoInPlumBid.participantId,
    sandboxKey,
    sbLoading,
    showNotification,
    timeLeftIncrease,
    updateParticipantMutation,
    updateSBUserMutation,
  ]);

  return plumBid.status ? (
    <CustomToolTip
      open={isPlumBidActive ? openTooltip : false}
      arrow
      placement="bottom"
      title={`The clock has been reset to 5 minutes to allow everyone the opportunity to bid`}
      TransitionComponent={Zoom}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      PopperProps={{
        disablePortal: true,
      }}
    >
      <div style={{ width: '100%' }}>
        <TimeLeft plumBid={plumBid} isSandbox={isSandbox} isWinner={isWinner} />
      </div>
    </CustomToolTip>
  ) : null;
});
