import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { PLUMBID_STATUS, ROLES } from 'constant';
import { ControlSection } from './ControlSection/ControlSection';
import { BuyerSection } from './BuyerSection/BuyerSection';
import { SellerSection } from './SellerSection/SellerSection';
import { PlumBidItemIncomplete } from './PlumBidItemIncomplete';
import { usePendingDialog, useTimeLeft, useResponsive } from 'utils/hooks';
import { useLazyQuery } from '@apollo/client';
import { GET_PLUMBID_BY_ID_QUERY } from 'service/graphql';
import { theme } from 'utils/theme';
import { PBox } from 'legos/';
import { useSettingsState } from 'service/store/settingsStore';
import { hasOfferSettingsError, hasOfferSettingsWarning } from 'utils';

export const PlumBidItem = ({ refetch, plumBid, loading, innerRef }) => {
  const responsive = useResponsive();
  const [{ refreshScreen }, dispatchSettings] = useSettingsState();

  useTimeLeft(plumBid);

  const showPopUp = usePendingDialog();
  const [refetchPlumbid] = useLazyQuery(GET_PLUMBID_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: plumBid.id,
    },
  });

  const offerSettingsErrors = useMemo(
    () =>
      hasOfferSettingsWarning(plumBid?.myInfoInPlumBid?.myParty?.offersettings) ||
      hasOfferSettingsError(
        plumBid?.myInfoInPlumBid?.myParty?.offersettings,
        plumBid?.myInfoInPlumBid?.myParty?.offersettings
      ),
    [plumBid?.myInfoInPlumBid?.myParty?.offersettings]
  );

  const isMeBuyerOrBA =
    plumBid.myInfoInPlumBid?.role === ROLES.BUYER || plumBid.myInfoInPlumBid?.role === ROLES.BUYER_AGENT;

  useEffect(() => {
    if (typeof refreshScreen === 'string' && refreshScreen.startsWith('PlumBidItem')) {
      const plumBidId = refreshScreen.replace('PlumBidItem', '');
      if (plumBidId === plumBid.id) {
        refetchPlumbid();
        dispatchSettings({
          type: 'refreshScreen',
          payload: null,
        });
      }
    }
  }, [dispatchSettings, plumBid.id, refetchPlumbid, refreshScreen]);

  useEffect(() => {
    if (
      plumBid?.isSellerPartyReady &&
      plumBid?.status === PLUMBID_STATUS.UPCOMING &&
      plumBid?.myInfoInPlumBid?.role === ROLES.BUYER &&
      plumBid?.myInfoInPlumBid?.myPopupStatus?.pending
    ) {
      showPopUp(plumBid, refetchPlumbid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plumBid]);

  return (
    <PBox
      id={plumBid.id}
      ref={innerRef}
      px={responsive.smallScreen ? 0 : responsive.mobileScreen ? 1 : 2}
      py={responsive.smallScreen ? 0 : responsive.mobileScreen ? 1 : 2}
      mb={3}
      position="relative"
      bgcolor={theme.palette.background.aliceBlue1}
      border={
        plumBid.status === PLUMBID_STATUS.LIVE
          ? `${theme.palette.border.dodgerBlue} solid 1px`
          : `${theme.palette.disabled} solid 2px`
      }
    >
      <Grid container spacing={responsive.tabletScreen ? 1 : 3}>
        <Grid item container xs={12} lg={3}>
          <ControlSection refetch={refetch} plumBid={plumBid} disabled={isMeBuyerOrBA && offerSettingsErrors} />
        </Grid>
        {plumBid.status === PLUMBID_STATUS.INCOMPLETE || (isMeBuyerOrBA && offerSettingsErrors) ? (
          <Grid
            item
            container
            xs
            alignContent="center"
            justify="center"
            style={{ height: responsive.padScreen ? 300 : 'auto' }}
          >
            <PlumBidItemIncomplete plumBidId={plumBid.id} />
          </Grid>
        ) : (
          <>
            <Grid item container xs={12} lg>
              <SellerSection plumBid={plumBid} loading={loading} />
            </Grid>
            <Grid item container xs={12} lg>
              <BuyerSection plumBid={plumBid} />
            </Grid>
          </>
        )}
      </Grid>
    </PBox>
  );
};
