import { Box, Grid, IconButton } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Icons, Input } from 'legos';
import { formatToTitleCase } from 'utils';

import { theme } from 'utils/theme';
import { ActionButtons } from './ActionButtons';

export const SellerItem = ({
  responsive,
  index,
  seller,
  addNewSellerRow,
  isDisabled,
  loading,
  selectedSeller,
  setSelectedSeller,
  arrayHelpers,
  handleRemoveSeller,
  sellerCreateInvite,
}) => {
  const { values, errors, touched, setFieldValue, setFieldError } = useFormikContext();

  const handleAccept = async () => {
    const result = await sellerCreateInvite(seller);
    if (result !== 'Participant already take a part in this plumBid.') {
      setSelectedSeller(null);
      setFieldValue(`sellers[${index}].edited`, false);
      setFieldValue(`sellers[${index}].emailOld`, seller.email);
      setFieldValue(`sellers[${index}].fullNameOld`, seller.fullName);
    } else {
      setFieldError(`sellers[${index}].email`, 'This participant has already been added.');
    }
  };

  const handleClose = () => {
    if (!seller.fullNameOld && !seller.emailOld) {
      arrayHelpers?.remove(index);
    }
    setSelectedSeller(null);
    setFieldValue(`sellers[${index}].fullName`, seller.fullNameOld);
    setFieldValue(`sellers[${index}].email`, seller.emailOld);
    setFieldValue(`sellers[${index}].edited`, false);
  };

  const handleChange = (value, field) => {
    setSelectedSeller(index);
    setFieldValue(`sellers[${index}].${field}`, value);
    setFieldValue(`sellers[${index}].edited`, true);
  };

  const handleFocus = () => {
    setSelectedSeller(index);
    index === values.sellers.length - 1 && addNewSellerRow();
  };

  const handleBlur = () =>
    values.sellers?.[index]?.fullName === '' && values.sellers?.[index]?.email === '' && arrayHelpers.remove(index);

  return (
    <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
      <Grid item xs={responsive.mobileScreen ? 12 : 5}>
        <Box minHeight={70}>
          <Input
            name={`sellers.${index}.fullName`}
            placeholder="Full name"
            type="text"
            value={values.sellers?.[index]?.fullName}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={(event) => handleChange(formatToTitleCase(event.target.value), 'fullName')}
            error={touched.sellers?.[index]?.fullName || errors.sellers?.[index]?.fullName}
            disabled={isDisabled || seller.exist}
          />
        </Box>
      </Grid>
      <Grid item xs={responsive.mobileScreen ? 9 : 5}>
        <Box minHeight={70}>
          <Input
            name={`sellers.${index}.email`}
            placeholder="Email"
            type="email"
            value={values.sellers?.[index]?.email}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={(event) => handleChange(event.target.value, 'email')}
            error={touched.sellers?.[index]?.email || errors.sellers?.[index]?.email}
            disabled={isDisabled}
          />
        </Box>
      </Grid>
      <Grid item xs={responsive.mobileScreen ? 3 : 2} style={{ textAlign: 'center' }}>
        {values.sellers.length > 1 &&
          index !== values.sellers.length - 1 &&
          (values.sellers[index].edited ? (
            <ActionButtons
              handleAccept={handleAccept}
              handleClose={handleClose}
              loading={loading}
              disabled={isDisabled || errors.sellers?.[index]?.fullName || errors.sellers?.[index]?.email}
            />
          ) : (
            <IconButton disabled={isDisabled} onClick={() => handleRemoveSeller(index, seller.id, arrayHelpers.remove)}>
              <Icons.Delete style={{ fill: isDisabled ? theme.palette.disabled : theme.palette.purple.dark }} />
            </IconButton>
          ))}
      </Grid>
    </Grid>
  );
};
