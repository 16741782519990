import gql from 'graphql-tag';

import { PlumBidType } from '../types';

export const GET_MY_PLUMBIDS = gql`
  query PlumbidByUser($role: String, $fromDatetime: String, $toDatetime: String) {
    plumbidByUser(role: $role, fromDatetime: $fromDatetime, toDatetime: $toDatetime) {
      ${PlumBidType}
    }
  }
`;
