import moment from 'moment';

export const normalizeBids = (bidSet, buyerParty) => {
  return bidSet?.map(bid => ({
    ...bid,
    plumBidAmount: Number(bid.plumBidAmount),
    formatDate: moment(bid.createdAt).format('MMM D [at] h:mm A'),
    partyName: buyerParty?.find(({ id }) => +id === +bid.partyId)?.partyName || bid.owner?.user?.fullName || 'N/A',
  }));
};
