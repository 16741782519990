import React, { useState, useEffect } from 'react';
import { Grid, Menu, MenuItem, Box, useMediaQuery } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { LinkButton } from 'legos';
import { removeSession } from 'service/auth';
import { useAuthState, useSettingsState } from 'service/store';
import { HOME, SETTINGS } from 'routes';
import state from 'service/graphql/state';
import { useResponsive } from 'utils/hooks';

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    minWidth: 130,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorReference="anchorEl"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));

export const AuthNav = ({ isHome, isDrawer }) => {
  const responsive = useResponsive();
  const [{ me }, dispatchAuth] = useAuthState();
  const { pathname } = useLocation();
  const [checked, setChecked] = React.useState(false);
  const history = useHistory();

  const [{ isOpenDrawer, invite }, dispatchSettings] = useSettingsState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const toTabletScreen = useMediaQuery('(max-width: 1010px) ', {
    noSsr: true,
  });

  const isSwitchScreen = (me && responsive.tabletScreen) || toTabletScreen;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (!me) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: {
          isOpenDrawer: !isOpenDrawer,
          drawerScreen: invite?.userExists
            ? 'SignInFromInvitationScreen'
            : pathname === '/invite' && !!invite
            ? 'SignUpFromInvitationScreen'
            : 'SignInScreen',
          drawerScreenGoBack:
            pathname === '/invite' && !!invite
              ? 'SignInFromInvitationScreen'
              : 'SignInScreen',
        },
      });
    } else {
      setChecked(!checked);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLogout = () => {
    handleMenuClose();
    removeSession();
    dispatchAuth({ type: 'logout' });
    history.replace(HOME);
  };

  const handleSettings = () => {
    handleMenuClose();
    history.push(SETTINGS);
  };

  useEffect(() => {
    if (open) {
      state.tutorialSkipRunVar(false);
    }
  }, [open]);

  return (
    <Grid
      item
      xs={8}
      lg={isDrawer ? 12 : isHome && !isSwitchScreen ? 'auto' : 2}
      style={{
        maxWidth: isSwitchScreen ? 120 : 200,
      }}
    >
      <Box style={{ textAlign: 'end', minWidth: !me ? '60px' : 'auto' }}>
        <LinkButton
          className={'login-button'}
          icon="UserLogin"
          iconSize={24}
          variant={'h5'}
          title={me ? me.fullName : 'Login'}
          onClick={handleClick}
          style={{
            fontSize: 16,
            textDecoration: 'none',
            fontWeight: 500,
            whiteSpace: me ? 'nowrap' : 'unset',
            textOverflow: me ? 'ellipsis' : 'unset',
            overflow: me ? 'hidden' : 'unset',
            maxWidth: '100%',
            paddingLeft: '1px',
          }}
        />
      </Box>
      <StyledMenu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSettings} style={{ height: 50 }}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} style={{ height: 50 }}>
          Logout
        </MenuItem>
      </StyledMenu>
    </Grid>
  );
};
