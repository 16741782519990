import { ROLES } from 'constant';
import { WarningBlock } from 'legos/WarningBlock';
import { useMemo } from 'react';
import { getBuyerPartyWarningMessage } from 'utils';

export const BuyerPartyStatus = ({ plumBid, party }) => {
  const message = useMemo(
    () =>
      plumBid.myInfoInPlumBid.role === ROLES.BUYER || plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT
        ? getBuyerPartyWarningMessage(plumBid.myInfoInPlumBid.myParty)
        : plumBid.buyerPartiesReady > 1
        ? { text: 'Buyers ready', variant: 'success' }
        : { text: 'Waiting for buyers', variant: 'warning' },
    [plumBid.buyerPartiesReady, plumBid.myInfoInPlumBid.myParty, plumBid.myInfoInPlumBid.role]
  );

  return <WarningBlock variant={message.variant} text={message.text} />;
};
