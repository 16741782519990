import gql from 'graphql-tag';

export const CREATE_SANDBOX_KEY_MUTATION = gql`
  mutation SandboxGetKey($input: SandboxGetKeyInput!) {
    sandboxGetKey(input: $input) {
      success
      errors
      key
    }
  }
`;
