import React from 'react';
import { shape } from 'prop-types';

export const Email = ({ style }) => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3 1H19C20.1 1 21 1.95625 21 3.125V15.875C21 17.0438 20.1 18 19 18H3C1.9 18 1 17.0438 1 15.875V3.125C1 1.95625 1.9 1 3 1Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 3L11 10L1 3"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Email.propTypes = {
  style: shape({}),
};

Email.defaultProps = {
  style: null,
};
