import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { CHANGE_USER_PROFILE_MUTATION, CHANGE_PASSWORD_MUTATION, ME_QUERY } from 'service/graphql';
import { useNotification } from './useNotification';

export const useUserProfile = () => {
  const [changeUserProfile, { data: userProfileData, loading: changeUserProfileLoading }] = useMutation(
    CHANGE_USER_PROFILE_MUTATION,
    {
      refetchQueries: [
        {
          query: ME_QUERY,
        },
      ],
      onError: error => showNotification({ error }),
    }
  );

  const [changePassword, { data: changePasswordData, loading: changePasswordLoading }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
    {
      onError: error => showNotification({ error }),
    }
  );

  const showNotification = useNotification();

  useEffect(() => {
    if (userProfileData?.changeUserProfile?.errors) {
      showNotification({ error: userProfileData.changeUserProfile.errors });
    }
  }, [userProfileData?.changeUserProfile?.errors, showNotification]);

  useEffect(() => {
    if (changePasswordData?.changeUserPassword?.errors) {
      showNotification({
        error: changePasswordData?.changeUserPassword?.errors,
      });
    }
  }, [changePasswordData?.changeUserPassword?.errors, showNotification]);

  return {
    changeUserProfile,
    changePassword,
    changeUserProfileLoading,
    changePasswordLoading,
  };
};
