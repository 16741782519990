import gql from 'graphql-tag';

export const SANDBOX_EDIT_PROPERTY_MUTATION = gql`
  mutation SandboxEditProperty($input: SandboxEditPropertyInput!) {
    sandboxEditProperty(input: $input) {
      success
      errors
    }
  }
`;
