import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { omit } from 'lodash';

import { useNotification } from './useNotification';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { GET_SB_PLUMBIDS_AND_PROPERTIES_QUERY, ON_NEW_SANDBOX_PLUMBID_EVENT_SUBSCRIPTION } from 'service/graphql';

export const useSBPlumBids = (variables, onlyPropertyWithoutPlumBid) => {
  const showNotification = useNotification();

  const { data, loading, refetch, subscribeToMore } = useQuery(GET_SB_PLUMBIDS_AND_PROPERTIES_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (subscribeToMore) {
      return subscribeToMore({
        document: ON_NEW_SANDBOX_PLUMBID_EVENT_SUBSCRIPTION,
        variables: { room: 'sandbox_plumbid_events' },
      });
    }
  }, [subscribeToMore]);

  const sbPlumBids = useMemo(() => data?.sbPlumbidByUser?.map(plumBid => normalizePlumBid(plumBid, true)) || [], [
    data?.sbPlumbidByUser,
  ]);

  const sbProperties = useMemo(() => {
    const property = onlyPropertyWithoutPlumBid
      ? (data?.sbGetProperties || []).filter(
          property =>
            !property.sbplumbidSet.edges.find(({ node }) =>
              data.sbPlumbidByUser?.find(({ id }) => +node.plumbidId === +id)
            )
        )
      : data?.sbGetProperties || [];
    const result = property.map(property => {
      const prop = omit(property, ['sbplumbidSet']);
      prop.sbplumbidSet = property.sbplumbidSet.edges.map(({ node }) => node);

      return prop;
    });
    result.sort((a, b) => a.id - b.id);

    return result;
  }, [data, onlyPropertyWithoutPlumBid]);

  return {
    sbPlumBids,
    sbProperties,
    loading,
    refetch,
  };
};
