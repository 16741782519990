import {useEffect} from 'react';
import {useMutation} from '@apollo/client';

import {ADD_LISTING_AGENT_MUTATION} from 'service/graphql';
import {useNotification} from './useNotification';
import {useGetMe} from './useGetMe';

export const useAgentListingRole = () => {
  const {fetchMe} = useGetMe();
  const [addAgentListingRole, {data, loading}] = useMutation(ADD_LISTING_AGENT_MUTATION, {
    onCompleted: () => {
      fetchMe();
    },
  });

  const showNotification = useNotification();

  useEffect(() => {
    if (data?.addListingAgentRole?.errors) {
      showNotification({
        error: data?.addListingAgentRole?.errors,
      });
    }
  }, [data?.addListingAgentRole?.errors, showNotification]);

  return {
    addAgentListingRole,
    addAgentListingRoleLoading: loading,
  };
};
