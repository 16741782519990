import { Box } from '@material-ui/core';

import { Header } from 'components';
import { ReactComponent as Page404 } from 'assets/images/Page404.svg';
import { theme } from 'utils/theme';
import { RouterLink } from 'legos';

export const NotFoundPage = () => (
  <>
    <Header withoutAuth />
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: 'calc(100vh - 59px)' }}
    >
      <Page404 />
      <RouterLink to={'/'} style={{ color: theme.palette.primary.main, paddingTop: 24 }}>
        Visit home page
      </RouterLink>
    </Box>
  </>
);
