import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  HomePage,
  NotFoundPage,
  PlumbidPage,
  DashboardPage,
  InvitePage,
  AdminUsersPage,
  AdminPlumBidPage,
  AdminDashboardPage,
  AdminChatsPage,
  UserSettingsPage,
  BuyersPage,
  AgentsPage,
  SellersPage,
  SellerOnboardingPage,
  BuyerOnboardingPage,
  UserPlumBidsPage,
  ResetPasswordPage,
  ContactsPage,
  AboutUsPage,
  AdminSandboxPage,
  UserSandboxPage,
  WizardPage,
  ReviewBuyersOnboardingPage,
  BidHistoryPage,
  SandboxPlumbidPage,
  WizardExternalPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  PlumBidMultiplePage,
  PlumBidLuxuryAuctionsPage,
  AgentsLAPage,
  SellersPageLA,
  FAQPage,
} from 'pages';
import { PrivateByRoleLayout, DevLayout, PublicLayout } from 'layouts';
import * as paths from './paths';
import { BuyersLAPage } from 'pages/BuyersLAPage';

const LazyComponent = (Component, path) => {
  const key = Array.isArray(path) ? path.join() : path;
  return (
    <Suspense key={key} fallback={<div />}>
      <Component />
    </Suspense>
  );
};

const routes = [
  {
    component: HomePage,
    path: paths.HOME,
    exact: true,
    title: 'Home',
    layout: 'public',
  },

  {
    component: PlumBidMultiplePage,
    path: paths.PLUMBID_MULTIPLE,
    exact: true,
    title: 'Multiple',
    layout: 'public',
  },
  {
    component: PlumBidLuxuryAuctionsPage,
    path: paths.PLUMBID_LUXURY_AUCTIONS,
    exact: true,
    title: 'Luxury Auctions',
    layout: 'public',
  },
  {
    component: ResetPasswordPage,
    path: paths.RESET_PASSWORD,
    exact: true,
    title: 'Reset password',
    layout: 'public',
  },
  {
    component: ResetPasswordPage,
    path: paths.CREATE_PASSWORD,
    exact: true,
    title: 'Reset password',
    layout: 'public',
  },
  {
    component: BuyersPage,
    path: paths.BUYERS,
    exact: true,
    title: 'Buyers',
    layout: 'public',
  },
  {
    component: BuyersLAPage,
    path: paths.BUYERS_LA,
    exact: true,
    title: 'Buyers',
    layout: 'public',
  },
  {
    component: AgentsPage,
    path: paths.AGENTS,
    exact: true,
    title: 'Agents',
    layout: 'public',
  }, {
    component: AgentsLAPage,
    path: paths.AGENTS_LA,
    exact: true,
    title: 'Sellers',
    layout: 'public',
  },
  {
    component: SellersPage,
    path: paths.SELLERS,
    exact: true,
    title: 'Sellers',
    layout: 'public',
  },
  {
    component: SellersPageLA,
    path: paths.SELLER_LA,
    exact: true,
    title: 'Sellers',
    layout: 'public',
  },
  {
    component: ContactsPage,
    path: paths.CONTACTS,
    exact: true,
    title: 'Contacts',
    layout: 'public',
  },
  {
    component: FAQPage,
    path: paths.FAQ,
    exact: true,
    title: 'FAQ',
    layout: 'public',
  },
  {
    component: AboutUsPage,
    path: paths.ABOUT_US,
    exact: true,
    title: 'About us',
    layout: 'public',
  },
  {
    component: PrivacyPolicyPage,
    path: paths.PRIVACY_POLICY,
    exact: true,
    title: 'Privacy Policy',
    layout: 'public',
  },
  {
    component: TermsOfUsePage,
    path: paths.TERM_OF_USE,
    exact: true,
    title: 'Term of Use',
    layout: 'public',
  },
  {
    component: UserSandboxPage,
    path: paths.USER_SANDBOX,
    exact: true,
    title: 'Sandbox',
  },

  {
    component: WizardPage,
    path: paths.WIZARD,
    exact: true,
    title: 'Wizard',
    layout: 'privateByRole',
  },
  {
    component: WizardExternalPage,
    path: paths.ADMIN_WIZARD,
    exact: true,
    title: 'Wizard',
    layout: 'privateByRole',
  },

  {
    component: InvitePage,
    path: paths.INVITE,
    exact: true,
    title: 'Invite',
    layout: 'public',
  },
  {
    component: PlumbidPage,
    path: paths.PLUMBID,
    exact: true,
    title: 'plumBid',
    layout: 'privateByRole',
  },
  {
    component: BidHistoryPage,
    path: paths.BID_HISTORY,
    exact: true,
    title: 'Bid History',
    layout: 'privateByRole',
  },
  {
    component: BidHistoryPage,
    path: paths.ADMIN_BID_HISTORY,
    exact: true,
    title: 'Bid History',
    layout: 'privateByRole',
  },
  {
    component: DashboardPage,
    path: paths.DASHBOARD,
    exact: true,
    title: 'Dashboard',
    layout: 'privateByRole',
  },
  {
    component: AdminUsersPage,
    path: paths.ADMIN_USERS,
    exact: true,
    title: 'Users',
    layout: 'privateByRole',
  },
  {
    component: AdminPlumBidPage,
    path: paths.ADMIN_PLUMBID,
    exact: true,
    title: 'plumBids',
    layout: 'privateByRole',
  },
  {
    component: AdminDashboardPage,
    path: paths.ADMIN_DASHBOARD,
    exact: true,
    title: 'Dashboard',
    layout: 'privateByRole',
  },
  {
    component: AdminSandboxPage,
    path: paths.ADMIN_SANDBOX,
    exact: true,
    title: 'Sandbox',
    layout: 'privateByRole',
  },
  {
    component: AdminChatsPage,
    path: paths.ADMIN_CHATS,
    exact: true,
    title: 'Dashboard',
    layout: 'privateByRole',
  },
  {
    component: UserPlumBidsPage,
    path: paths.ADMIN_USER_PLUMBIDS + '/:id',
    exact: true,
    title: 'User plumBids',
    layout: 'privateByRole',
  },
  {
    component: SandboxPlumbidPage,
    path: paths.SANDBOX_PLUMBID,
    exact: true,
    title: 'Sandbox plumBid',
    layout: 'public',
  },
  {
    component: SellerOnboardingPage,
    path: paths.SELLER_ONBOARDING,
    exact: true,
    title: 'Seller survey',
    layout: 'privateByRole',
  },
  {
    component: BuyerOnboardingPage,
    path: paths.BUYER_ONBOARDING,
    exact: true,
    title: 'Buyer survey',
    layout: 'privateByRole',
  },
  {
    component: UserSettingsPage,
    path: paths.SETTINGS,
    exact: true,
    title: 'Profile',
    layout: 'privateByRole',
  },
  {
    component: ReviewBuyersOnboardingPage,
    path: paths.REVIEW_BUYERS_ONBOARDING,
    exact: true,
    title: 'Review buyers onboarding',
    layout: 'privateByRole',
  },

  {
    component: NotFoundPage,
    title: 'Page not found',
  },
];

const renderRouteChildren = route => {
  if (route.layout === 'privateByRole') {
    return <PrivateByRoleLayout>{LazyComponent(route.component, route.path)}</PrivateByRoleLayout>;
  }

  if (route.layout === 'dev') {
    return <DevLayout>{LazyComponent(route.component, route.path)}</DevLayout>;
  }

  if (route.layout === 'public') {
    return <PublicLayout>{LazyComponent(route.component, route.path)}</PublicLayout>;
  }

  return () => LazyComponent(route.component, route.path);
};

export const Routes = () => (
  <Switch>
    {routes.map(route => (
      <Route path={route.path} exact={route.exact} key={route.path || route.title}>
        {renderRouteChildren(route)}
      </Route>
    ))}
  </Switch>
);
