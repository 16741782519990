import React, { useMemo } from 'react';
import moment from 'moment';
import { Grid, Typography, Box, ListItem } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Button, Divider } from 'legos';
import { PropertyThumbnail, PlumBidWarningMessage } from 'components';
import { formatNumberToUSD } from 'utils/formatters';
import { useTimeLeft } from 'utils/hooks';
import { LocalStorage } from 'utils/localStorage';
import { PLUMBID_STATUS } from 'constant';
import { useDialog, useResponsive } from 'utils/hooks';
import { DIALOG } from 'constant';
import { SANDBOX_PLUMBID, ADMIN_SANDBOX } from 'routes';
import { ActiveMarkDateTime } from './ActiveMarkDateTiime';
import { theme } from 'utils/theme';

export const SandboxPlumBidItem = ({ plumBid, refetch }) => {
  const { openDialog, closeDialog } = useDialog(DIALOG.ADD_PHANTOM_PLUM_BID);

  const responsive = useResponsive();

  const history = useHistory();
  const { pathname } = useLocation();
  useTimeLeft(plumBid);

  const sandboxKey = LocalStorage.getSandboxKey();
  const isWinner = useMemo(() => {
    if (plumBid?.status === PLUMBID_STATUS.FINISHED) {
      if (
        plumBid.sbbidSet.reduce(
          (prev, cur) => {
            if (cur.plumBidAmount > prev.plumBidAmount) {
              return cur;
            }
            return prev;
          },
          { plumBidAmount: -100000 }
        )?.owner?.user?.key === sandboxKey
      ) {
        return true;
      }
    }
    return false;
  }, [plumBid, sandboxKey]);

  const isMyBidHighest = plumBid.sbbidSet[plumBid?.sbbidSet.length - 1]?.owner?.user?.key === sandboxKey;

  const iBided = plumBid.sbbidSet.find(item => item?.owner?.user?.key === sandboxKey);

  const openPlumBidPage = () => {
    history.push(`${SANDBOX_PLUMBID}?plumbid=${plumBid.plumbidId || plumBid.id}`);
  };

  const handleOpenDialog = () => {
    openDialog({
      title: 'Edit Sample properties',
      okTitle: 'Save',
      plumBid,
      onOk: () => {
        closeDialog();
        refetch();
      },
    });
  };

  const renderLastBidInfo = () => {
    const lastBid = Array.isArray(plumBid?.sbbidSet) ? plumBid.sbbidSet[plumBid.sbbidSet.length - 1] : null;
    return plumBid?.status === PLUMBID_STATUS.LIVE ? (
      <Box
        borderRadius={3.25}
        py={1}
        mt={responsive.padScreen ? 1 : 0}
        display="flex"
        style={{ backgroundColor: theme.palette.background.lastBid }}
        justifyContent="center"
        alignItems="center"
      >
        <Icons.Bid style={{ fill: theme.palette.text.lastBid }} />
        <Typography
          variant="overline"
          style={{
            color: theme.palette.text.lastBid,
            marginLeft: 8,
            textTransform: 'capitalize',
          }}
        >
          Last bid: {lastBid ? moment(lastBid.createdAt).format('hh:mmA') : ''} -{' '}
          {lastBid ? formatNumberToUSD(lastBid.plumBidAmount, { hidePlusSign: true }) : ''}
        </Typography>
      </Box>
    ) : null;
  };

  return (
    <ListItem
      style={{
        paddingTop: responsive.padScreen ? 0 : 16,
        paddingBottom: 16,
        marginBottom: 24,
        position: 'relative',
        background: theme.palette.background.chatMessageHeader,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={responsive.padScreen ? 12 : 2}
          container
          justify="center"
          direction="column"
          style={{
            borderRight: responsive.padScreen ? null : `1px solid ${theme.palette.divider}`,
            paddingRight: responsive.padScreen ? null : 12,
          }}
        >
          {isWinner ? (
            <Box
              position="absolute"
              px={2}
              py={1}
              bgcolor={theme.palette.background.highBid}
              style={{ top: 0, left: 0 }}
            >
              <Typography align="center" variant="h4" style={{ color: theme.palette.common.white, fontSize: 13 }}>
                Congratulations
              </Typography>
            </Box>
          ) : null}
          <ActiveMarkDateTime
            plumBid={plumBid}
            smallScreen={responsive.padScreen}
            extraSmallScreen={responsive.mobileScreen}
          />
        </Grid>
        <Grid item container xs={responsive.padScreen ? 12 : 10} style={{ paddingLeft: responsive.padScreen || 40 }}>
          {responsive.mdScreen || (
            <Grid container item alignItems="stretch" direction="column">
              {renderLastBidInfo()}
            </Grid>
          )}
          {!isWinner && plumBid?.status === PLUMBID_STATUS.FINISHED ? (
            <Grid container item alignItems="stretch" direction="column">
              <PlumBidWarningMessage defaultText="Your bid did not win the plumBid. Try bidding again" />
            </Grid>
          ) : null}
          {!isMyBidHighest && iBided && plumBid.status === PLUMBID_STATUS.LIVE ? (
            <Grid container item alignItems="stretch" direction="column">
              <PlumBidWarningMessage defaultText="Insufficient Bid" />
            </Grid>
          ) : null}
          <Grid item container xs={12} style={{ marginTop: 12 }}>
            <Grid item xs={responsive.mdScreen ? 12 : 2}>
              <PropertyThumbnail aspectRatio={1 / 1} src={plumBid?.mls.previewImage} />
            </Grid>
            {responsive.mdScreen && (
              <Grid item alignItems="stretch" container direction="column" style={{ marginTop: 12, marginBottom: 12 }}>
                {renderLastBidInfo()}
              </Grid>
            )}

            <Grid item xs={responsive.mdScreen ? 12 : 10} style={{ paddingLeft: responsive.mdScreen ? 0 : 16 }}>
              <Box py={2}>
                <Typography variant="h2" style={{ textTransform: 'uppercase' }}>
                  {plumBid.mls.title}
                </Typography>
              </Box>
              <Divider />
              <Box pt={2} pb={3}>
                <Grid item container justify="space-between" direction="row">
                  <Grid
                    item
                    xs={responsive.mobileScreen ? 12 : 5}
                    style={{ paddingBottom: responsive.mobileScreen ? 16 : 0 }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 4,
                      }}
                    >
                      Listing Price
                    </Typography>
                    <Typography variant="h6">
                      {formatNumberToUSD(plumBid?.listPrice, {
                        hidePlusSign: true,
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={responsive.mobileScreen ? 12 : 7}
                    spacing={1}
                    justify={responsive.mobileScreen ? 'flex-start' : 'flex-end'}
                  ></Grid>
                </Grid>
              </Box>
              <Box pb={2} display="flex" flexDirection={responsive.mobileScreen ? 'column' : 'row'}>
                <Box mr={responsive.mobileScreen ? 0 : 1}>
                  <Button
                    title="Try optimizer"
                    icon="Optimizer"
                    onClick={() => openPlumBidPage()}
                    style={{ minWidth: 190 }}
                  />
                </Box>
                {pathname === ADMIN_SANDBOX && (
                  <Box my={responsive.mobileScreen ? 2 : 0} style={{ width: '100%' }}>
                    <Button
                      variant="tertiary"
                      title="Edit Property"
                      icon="Edit"
                      onClick={() => handleOpenDialog(plumBid)}
                      style={{ minWidth: 190 }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

SandboxPlumBidItem.propTypes = {};
