import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { useNotification } from './useNotification';
import { useAuthState } from 'service/store/authStore';
import { normalizePlumBid } from 'utils/normalizePlumBid';
import { GET_PLUMBID_BY_ID_QUERY } from 'service/graphql';

export const usePlumBidById = (variables, fetchPolicy) => {
  const [{ me }] = useAuthState();
  const showNotification = useNotification();

  const { loading, data, refetch, error } = useQuery(GET_PLUMBID_BY_ID_QUERY, {
    variables,
    ...(fetchPolicy ? { fetchPolicy } : null),
    onError: error => showNotification({ error }),
  });

  const plumBid = useMemo(() => (data?.plumbidById ? normalizePlumBid(data?.plumbidById, false, me) : undefined), [
    data?.plumbidById,
    me,
  ]);

  return {
    plumBidError: error,
    plumBid,
    plumBidLoading: loading,
    plumBidRefetch: refetch,
  };
};
