import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { ADMIN_CHAT_HISTORY_QUERY, ON_NEW_ADMIN_CHAT_MESSAGE_SUBSCRIPTION } from 'service/graphql';
import { useAuthState } from 'service/store';

import { useNotification } from './useNotification';

export const useAdminChatHistory = variables => {
  const [{ me }] = useAuthState();
  const showNotification = useNotification();
  const { data, loading, subscribeToMore } = useQuery(ADMIN_CHAT_HISTORY_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (subscribeToMore && variables?.chatName) {
      return subscribeToMore({
        document: ON_NEW_ADMIN_CHAT_MESSAGE_SUBSCRIPTION,
        variables: {
          chatName: variables.chatName,
        },
        updateQuery: (prev, { subscriptionData }) => {
          const newMessage = subscriptionData?.data?.onNewAdminChatMessage?.message;

          if (!newMessage) {
            return prev;
          }

          const adminChatHistory = {
            ...prev.adminChatHistory,
            edges: [
              ...prev.adminChatHistory.edges,
              {
                node: newMessage,
              },
            ],
          };

          return { ...prev, adminChatHistory };
        },
      });
    }
  }, [variables?.chatName, me.email, subscribeToMore, showNotification]);

  const adminChatHistory = data?.adminChatHistory.edges.map(edge => edge.node) || [];

  return {
    adminChatHistory,
    adminChatHistoryLoading: loading,
  };
};
