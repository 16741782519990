import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { PageContainer, DashboardSandbox } from 'components';
import { Button } from 'legos';
import { useDialog, usePageLoading, useSBPlumBids } from 'utils/hooks';
import { DIALOG } from 'constant';

export const AdminSandboxPage = ({ theme }) => {
  const { openDialog, closeDialog } = useDialog(DIALOG.ADD_PHANTOM_PLUM_BID);
  const { sbProperties, loading, refetch } = useSBPlumBids();

  usePageLoading(loading, 'AdminSandboxPage');

  const handleOpenDialog = () => {
    openDialog({
      title: 'Add Sample PROPERTY',
      okTitle: 'Add property',
      onOk: () => {
        closeDialog();
        refetch();
      },
    });
  };

  return (
    <PageContainer>
      <Box p={6} pt={0}>
        <Box mt={'62px'} mb={'55px'} display="flex" justifyContent="space-between">
          <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
            Sample properties
          </Typography>

          <Button title="Add sample property" icon="Add" style={{ minWidth: 220 }} onClick={handleOpenDialog} />
        </Box>

        <DashboardSandbox properties={sbProperties} refetch={refetch} />
      </Box>
    </PageContainer>
  );
};
