import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsive } from 'utils/hooks';

const steps = [
  {
    step: 1,
    text: 'Once you’ve received multiple offers on your property listing, log-in and enter the property MLS number.',
  },

  {
    step: 2,
    text: 'Upload the offers to the plumBid platform.',
    textSecondary: 'plumBid automatically transposes the purchase details of each contract into the initial bids.',
  },
  {
    step: 3,
    text:
      'An email or link will be sent to your seller - asking them to complete a short survey that will allow them to set their offer priorities.',
  },
  {
    step: 4,
    text:
      'Verify that all offers have been transferred successfully and utilize the calendar to schedule your plumBid - based on activity and your preference.',
  },
  {
    step: 5,
    text: 'Invitations will be sent to all Buyer participants inviting them to the plumBid.',
  },
  {
    step: 6,
    text: 'Watch plumBid deliver the best results, and then open escrow with a smile on your face.',
  },
];

export const StepsPlumBid = () => {
  const { tabletScreen, mdScreen } = useResponsive();

  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '30px 8px' : tabletScreen ? '80px 8px' : '80px  80px',
      }}
      justify="space-evenly"
    >
      <Grid item xs={12}>
        <Box p={2} pb={mdScreen ? 2 : 10}>
          <Typography variant="h1">Agents, six easy steps to a plumBid</Typography>
        </Box>
      </Grid>
      {steps.map(item => (
        <Grid key={item.step} item xs={12} md={6} lg={4} style={{ padding: 16 }}>
          <Box height="300px" p={{ xs: 3, sm: 3, md: 5 }} style={{ border: '1px solid #96A73C', borderRadius: 15 }}>
            <Typography
              style={{
                fontSize: 104,
                lineHeight: '120px',
                fontWeight: 400,
                color: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              {item.step}
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                lineHeight: '27px',
                fontWeight: 400,
              }}
            >
              {item.text}
            </Typography>
            {item.textSecondary && (
              <Typography
                style={{
                  fontSize: 16,
                  lineHeight: '27px',
                  fontWeight: 400,
                  paddingTop: 20,
                }}
              >
                {item.textSecondary}
              </Typography>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
