import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { SEND_ADMIN_CHAT_MESSAGE_MUTATION, ADMIN_CHAT_HISTORY_QUERY } from 'service/graphql';
import { useNotification } from './useNotification';

export const useSendAdminChatMessage = chatName => {
  const [sendAdminChatMessage, { data, loading }] = useMutation(SEND_ADMIN_CHAT_MESSAGE_MUTATION, {
    ...(chatName
      ? {
          refetchQueries: [
            {
              query: ADMIN_CHAT_HISTORY_QUERY,
              variables: { chatName },
            },
          ],
        }
      : null),
    onError: error => showNotification({ error }),
  });

  const showNotification = useNotification();

  useEffect(() => {
    if (data?.sendAdminChatMessage?.errors) {
      showNotification({ error: data.sendAdminChatMessage.errors });
    }
  }, [data?.sendAdminChatMessage?.errors, showNotification]);

  return {
    sendAdminChatMessage,
    sendAdminChatMessageLoading: loading,
  };
};
