import { Grid, Typography } from "@material-ui/core";
import { Icons } from "legos";

export const AddedProtection = () => {

  return (
    <Grid item container xs={12} justify="center" style={{ padding: '60px 28px' }}>
      <Grid item container xs={12} md={11}>
        <Grid item container xs={10}>
          <Typography variant="h1">
            Added protection
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: 20,
              paddingTop: 24,
            }}>
            <b>plumBid</b> provides the buyer of your property with an enhanced California home protection warranty plan from Fidelity National Home Warranty.
          </Typography>
        </Grid>
        <Grid item container xs={2} justify='center' alignItems='center'><Icons.ShieldCheck /></Grid>
      </Grid>
    </Grid>
  );
}