import { Box } from '@material-ui/core';
import { ReactComponent as PlaceholderHouse } from 'assets/images/PlaceholderHouse.svg';
import { ReactComponent as PlaceholderMapPin } from 'assets/images/PlaceholderMapPin.svg';
import { theme } from 'utils/theme';

export const WizardPropertyThumbnailPlaceholder = () => {
  return (
    <>
      <Box width="100%" display="flex" alignItems="center" border={1}>
        <Box
          width={77}
          height={55}
          m={1}
          bgcolor={theme.palette.text.gainsboro}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PlaceholderHouse />
        </Box>
        <Box width="60%" ml={1}>
          <Box height={18} width="100%" display="flex" alignItems="center">
            <PlaceholderMapPin />
            <Box width="70%" height={6} ml={1} bgcolor={theme.palette.text.gainsboro} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
