import { useEffect, useMemo, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { LinearProgress, Typography } from '@material-ui/core';

import { PBox } from 'legos';
import { Dot3 } from './Dots';
import state, { setBidProcessing } from 'service/graphql/state';
import { PROCESSING_STEP, PROCESSING_STEP_COUNT } from 'constant';

const STEP_MIN_DELAY = 1000;
const STEP_MAX_DELAY = 1400;

// Display progress of processing bid
// (min time = ~1500 msec * PROCESSING_STEP_COUNT )
// aborted if error = true

export const BidProcessing = () => {
  const stepTimerRef = useRef();

  const bidProcessing = useReactiveVar(state.bidProcessingVar);

  useEffect(() => {
    for (const s of PROCESSING_STEP) {
      s.delay = s.delay > 0 ? Math.round(Math.random() * (STEP_MAX_DELAY - STEP_MIN_DELAY + 1) + STEP_MIN_DELAY) : 0;
    }

    return () => {
      clearTimeout(stepTimerRef.current);
    };
  }, []);

  useEffect(() => {
    if (bidProcessing.error) {
      setBidProcessing({
        error: false,
        loading: false,
        currentStep: PROCESSING_STEP_COUNT,
      });
    } else if (state.bidProcessingVar().currentStep < PROCESSING_STEP_COUNT) {
      stepTimerRef.current = setTimeout(() => {
        setBidProcessing({
          currentStep: bidProcessing.currentStep + 1,
        });
      }, PROCESSING_STEP[bidProcessing.currentStep].delay);

      return () => {
        clearTimeout(stepTimerRef.current);
      };
    }
  }, [bidProcessing.currentStep, bidProcessing.error]);

  const innerStep =
    bidProcessing.currentStep < PROCESSING_STEP_COUNT ? bidProcessing.currentStep : PROCESSING_STEP_COUNT - 1;

  const progress = useMemo(() => {
    const delays = PROCESSING_STEP.reduce(
      (acc, curStep, index) => {
        acc.total += curStep.delay;
        if (index <= innerStep) {
          acc.current += curStep.delay;
        }
        return acc;
      },
      { total: 0, current: 0 }
    );

    return (delays.current / delays.total) * 100 - 1;
  }, [innerStep]);

  return (
    <PBox height="48px" width="70%" position="relative">
      <PBox
        width="auto"
        position="absolute"
        zIndex={20}
        display="flex"
        alignItems="baseline"
        justifyContent="center"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="h4" style={{ color: 'white', paddingRight: 4 }} noWrap>
          {PROCESSING_STEP[innerStep].title}
        </Typography>
        {bidProcessing.currentStep > 0 && <Dot3 />}
      </PBox>

      <LinearProgress variant="determinate" value={progress} style={{ height: '100%' }} />
    </PBox>
  );
};
