import React from 'react';
import { shape } from 'prop-types';

export const EmailFooter = ({ style }) => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M20.8941 0H0.773857C0.345817 0 0 0.257774 0 0.576838V12.1136C0 12.4327 0.345817 12.6904 0.773857 12.6904H20.8941C21.3222 12.6904 21.668 12.4327 21.668 12.1136V0.576838C21.668 0.257774 21.3222 0 20.8941 0ZM18.9402 1.96305L11.3104 6.38848C11.1218 6.49844 10.8582 6.49844 10.6696 6.38848L3.03739 1.96305C3.00862 1.9465 2.98752 1.92365 2.97704 1.89771C2.96656 1.87178 2.96724 1.84404 2.97897 1.8184C2.9907 1.79276 3.0129 1.7705 3.04246 1.75474C3.07202 1.73898 3.10745 1.73051 3.14379 1.73051H18.8337C18.8701 1.73051 18.9055 1.73898 18.9351 1.75474C18.9646 1.7705 18.9868 1.79276 18.9986 1.8184C19.0103 1.84404 19.011 1.87178 19.0005 1.89771C18.99 1.92365 18.9689 1.9465 18.9402 1.96305Z"
      fill="white"
    />
  </svg>
);

EmailFooter.propTypes = {
  style: shape({}),
};

EmailFooter.defaultProps = {
  style: null,
};
