import gql from 'graphql-tag';

export const SANDBOX_START_PLUMBID_MUTATION = gql`
  mutation SandboxStartPlumbid($input: SandboxStartPlumBidInput!) {
    sandboxStartPlumbid(input: $input) {
      success
      errors
      plumbid {
        id
      }
    }
  }
`;
