import React from 'react';
import { shape } from 'prop-types';

export const Copy = ({ style }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
    <path
      d="M13.6004 5.90002H7.30039C6.52719 5.90002 5.90039 6.52683 5.90039 7.30002V13.6C5.90039 14.3732 6.52719 15 7.30039 15H13.6004C14.3736 15 15.0004 14.3732 15.0004 13.6V7.30002C15.0004 6.52683 14.3736 5.90002 13.6004 5.90002Z"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.1 10.1H2.4C2.0287 10.1 1.6726 9.9525 1.41005 9.68995C1.1475 9.4274 1 9.0713 1 8.7V2.4C1 2.0287 1.1475 1.6726 1.41005 1.41005C1.6726 1.1475 2.0287 1 2.4 1H8.7C9.0713 1 9.4274 1.1475 9.68995 1.41005C9.9525 1.6726 10.1 2.0287 10.1 2.4V3.1"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

Copy.propTypes = {
  style: shape({}),
};

Copy.defaultProps = {
  style: {},
};
