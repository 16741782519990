import { Grid, Typography } from '@material-ui/core';

import { PBox } from 'legos';
import { theme } from 'utils';
import { ParticipantActions } from './ParticipantActions';

export const ParticipantItem = ({
  getLink,
  handleEdit,
  handleClose,
  participant,
  inputWarning,
  disableEditingField,
  disableDeleteAndCopy,
  participantStatusProps,
  actionsButtonsPosition,
}) => (
  <>
    <PBox width="75%">
      <Grid container>
        <Grid item xs={4} style={{ paddingRight: 8 }}>
          <PBox py={0.55}>
            <Typography variant="h4">Status</Typography>
          </PBox>
          <PBox height="24px" width="calc(100% - 8px)" pt={1.8}>
            <Typography
              variant="h4"
              style={{
                fontSize: 12,
                lineHeight: 1.2,
                fontWeight: 400,
                color: participantStatusProps.color,
              }}
            >
              {participant.id ? participantStatusProps.text : ''}
            </Typography>
          </PBox>
        </Grid>
        <Grid item xs={8} style={{ height: '90px' }}>
          <PBox py={0.55}>
            <Typography variant="h4">Full Name/Entity Name</Typography>
          </PBox>
          <PBox
            height="24px"
            width="calc(100% - 8px)"
            pt={1.8}
            bgcolor={inputWarning[`${participant.id}fullName`] ? theme.palette.background.whiteSmoke : 'unset'}
            border={inputWarning[`${participant.id}fullName`] ? `2px solid ${theme.palette.warning.main}` : 'unset'}
            style={{
              cursor: participant.email === '' ? 'text' : 'unset',
            }}
            onClick={() => {
              if (inputWarning[`${participant.id}fullName`]) {
                handleEdit();
              }
            }}
          >
            <Typography variant="body1">{participant.fullName}</Typography>
          </PBox>
          {inputWarning[`${participant.id}fullName`] && (
            <PBox width="calc(100% - 8px)">
              <Typography style={{ lineHeight: 1.2, fontSize: 12, color: theme.palette.warning.main }}>
                {inputWarning[`${participant.id}fullName`]}
              </Typography>
            </PBox>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <PBox pt={0.25} pb={0.75}>
            <Typography variant="h4">Role</Typography>
          </PBox>
          <PBox height="24px" width="calc(100% - 8px)" pt={1.8}>
            <Typography variant="body1">{participant.role}</Typography>
          </PBox>
        </Grid>
        <Grid item xs={8} style={{ height: '90px' }}>
          <PBox py={0.55}>
            <Typography variant="h4">Email</Typography>
          </PBox>
          <PBox
            height="24px"
            width="calc(100% - 8px)"
            pt={1.8}
            bgcolor={participant.email === '' ? theme.palette.background.whiteSmoke : 'unset'}
            style={{
              borderBottom: participant.email === '' ? `1px solid ${theme.palette.text.suvaGrey}` : 'unset',
              cursor: participant.email === '' ? 'text' : 'unset',
            }}
            onClick={() => {
              if (participant.email === '') {
                handleEdit();
              }
            }}
          >
            <Typography variant="body1">{participant.email}</Typography>
          </PBox>
        </Grid>
      </Grid>
    </PBox>
    <PBox
      display="flex"
      alignItems={actionsButtonsPosition ? 'flex-end' : 'flex-start'}
      minWidth="142px"
      pt={3.5}
      pb={2.5}
      pl={1}
    >
      <ParticipantActions
        participant={participant}
        getLink={() => getLink(participant)}
        handleEdit={handleEdit}
        disableEditingField={disableEditingField}
        handleClose={handleClose}
        disableDelete={disableDeleteAndCopy}
      />
    </PBox>
  </>
);
