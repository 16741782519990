import React from 'react';
import { shape } from 'prop-types';

export const Add = ({ style }) => (
  <svg
    style={style}
    width={style?.width || 10}
    height={style?.height || 10}
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 4.5V0.5H4.5V4.5H0.5V5.5H4.5V9.5H5.5V5.5H9.5V4.5H5.5Z" />
  </svg>
);

Add.propTypes = {
  style: shape({}),
};

Add.defaultProps = {
  style: null,
};
