import moment from 'moment';
import { ADMIN_PLUMBID } from 'routes';
import { Box, Collapse, TableCell, TableRow, Typography } from '@material-ui/core';

import { PropertyThumbnailNameAddress } from 'components/PropertyThumbnailNameAddress';

import { formatNumberToUSD, formatUserFullName } from 'utils';
import { Icons } from 'legos';
import { PlumBidDetails } from './PlumBidDetails';
import { PlumBidStatusCard } from 'components/Dashboard/ControlSection/PlumBidStatusCard';
import { theme } from 'utils/theme';

export const PlumBidRowItem = ({ plumBid, plumBidId, pathname, role, showDetailsById, setShowDetailsById }) => (
  <>
    <TableRow
      hover
      style={{
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
      }}
      onClick={() => setShowDetailsById(plumBid.id === showDetailsById ? null : plumBid.id)}
    >
      <TableCell width="2%">
        <Box
          mr={1}
          style={{
            cursor: 'pointer',
            transition: 'all 0.2s ',
            transformOrigin: 'center',
            transform: showDetailsById === plumBid.id ? 'rotate(180deg)' : 'rotate(90deg)',
          }}
        >
          <Icons.ArrowRight />
        </Box>
      </TableCell>
      <TableCell width="30%" component="th" scope="row" style={{ paddingTop: 8, paddingBottom: 8 }}>
        <PropertyThumbnailNameAddress property={plumBid.mls} />
      </TableCell>

      <TableCell width="20%">
        <Box>
          <Typography variant="h5" component="span" style={{ color: theme.palette.text.rain }}>
            start:{' '}
          </Typography>
          <Typography variant="h5" component="span" style={{ color: 'black' }}>{`${
            plumBid.startDateTime ? moment(plumBid.startDateTime).format('MMM DD [at] h:mma') : '-'
          }`}</Typography>
        </Box>
        <Box>
          <Typography variant="h5" component="span" style={{ color: theme.palette.text.rain }}>
            finish:{' '}
          </Typography>
          <Typography variant="h5" component="span" style={{ color: 'black' }}>
            {`${plumBid.finishDateTime ? moment(plumBid.finishDateTime).format('MMM DD [at] h:mma') : '-'}`}
          </Typography>
        </Box>
      </TableCell>

      <TableCell width="10">
        <PlumBidStatusCard plumBid={plumBid} timeLeft invert />
      </TableCell>
      <TableCell width="14">
        <Typography variant="h5">{formatUserFullName(plumBid.owner)}</Typography>
        <Typography variant="h5" style={{ color: theme.palette.text.stone }}>
          email: {plumBid.owner.email}
        </Typography>
        <Typography variant="h5" style={{ color: theme.palette.text.stone }}>
          phone: {plumBid.owner.phone}
        </Typography>
      </TableCell>
      <TableCell width="14">
        <Typography variant="h5">
          {formatNumberToUSD(plumBid.listPrice, {
            hidePlusSign: true,
          })}
        </Typography>
      </TableCell>
      {pathname !== ADMIN_PLUMBID && (
        <TableCell width="14">
          <Typography variant="h5">{role}</Typography>
        </TableCell>
      )}
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={pathname === ADMIN_PLUMBID ? 6 : 7}>
        <Collapse in={+showDetailsById === +plumBid.id} timeout="auto" unmountOnExit>
          <PlumBidDetails plumBidId={plumBidId} />
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);
