import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import { Routes } from 'routes';
import { AuthProvider, SettingsProvider } from 'service/store';
import { MediaProvider } from 'utils/hooks';

import { SnackBar, DialogContainer } from 'components';
import './app.scss';

function App() {
  return (
    <SnackBar>
      <MediaProvider>
        <AuthProvider>
          <SettingsProvider>
            <BrowserRouter>
              <Helmet encodeSpecialCharacters titleTemplate="%s | plumBid" defaultTitle="plumBid">
                <html lang="en" />
                <meta charSet="utf-8" />
              </Helmet>
              <Routes />
              <DialogContainer />
            </BrowserRouter>
          </SettingsProvider>
        </AuthProvider>
      </MediaProvider>
    </SnackBar>
  );
}

export default App;
