import React from 'react';

import { useAuthState } from 'service/store';
import { ChatWithAdmin } from 'components';

export const UserChatScreen = () => {
  const [{ me }] = useAuthState();

  return <ChatWithAdmin chatName={`admin_chat_${me.userId}_${me.email}`} />;
};
