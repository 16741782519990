import gql from 'graphql-tag';

import { ExtendedUserType } from '../types';

export const ME_QUERY = gql`
  {
    me {
      ${ExtendedUserType}
    }
  }
`;
