import React from 'react';

import { PageContainer, TermsOfUse } from 'components';

export const TermsOfUsePage = () => {
  return (
    <PageContainer disableLoading>
      <TermsOfUse />
    </PageContainer>
  );
};
