import React from 'react';
import { shape } from 'prop-types';

export const Seller = ({ style }) => (
  <svg
    style={style}
    width="14"
    height="15"
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.90299 3.81225C9.90299 1.84425 8.30848 0.249746 6.34049 0.249746C4.37249 0.249746 2.77799 1.84425 2.77799 3.81225C2.77799 5.00166 3.3612 6.05605 4.25757 6.70247C4.25183 6.70534 4.24752 6.70678 4.24465 6.70821C3.60253 6.97971 3.0265 7.369 2.53091 7.86603C2.03532 8.36162 1.64747 8.93909 1.37597 9.58263C1.11022 10.2118 0.968007 10.8798 0.953642 11.5664C0.952206 11.6311 1.00392 11.6842 1.06856 11.6842H1.92902C1.99079 11.6842 2.0425 11.6339 2.04394 11.5722C2.07267 10.4632 2.51655 9.42462 3.30231 8.63742C4.11392 7.82293 5.19129 7.37475 6.34049 7.37475C8.30848 7.37475 9.90299 5.78024 9.90299 3.81225ZM6.34049 6.28301C4.97582 6.28301 3.86972 5.17691 3.86972 3.81225C3.86972 2.44758 4.97582 1.34148 6.34049 1.34148C7.70515 1.34148 8.81125 2.44758 8.81125 3.81225C8.81125 5.17691 7.70515 6.28301 6.34049 6.28301ZM7.8488 9.45766H11.6411C11.7043 9.45766 11.7561 9.40595 11.7561 9.34274V8.5383C11.7561 8.4751 11.7043 8.42338 11.6411 8.42338H9.10573L9.78376 7.56005C9.79956 7.53994 9.80818 7.51409 9.80818 7.48967C9.80818 7.42646 9.75646 7.37475 9.69326 7.37475H8.65036C8.57998 7.37475 8.5139 7.40778 8.46937 7.46237L7.48537 8.71356C7.42217 8.794 7.38769 8.89455 7.38769 8.99798C7.38913 9.25224 7.59454 9.45766 7.8488 9.45766ZM11.2964 10.377H7.50405C7.44084 10.377 7.38913 10.4287 7.38913 10.4919V11.2964C7.38913 11.3596 7.44084 11.4113 7.50405 11.4113H10.0395L9.36143 12.2746C9.34563 12.2947 9.33701 12.3206 9.33701 12.345C9.33701 12.4082 9.38872 12.4599 9.45193 12.4599H10.4948C10.5652 12.4599 10.6313 12.4269 10.6758 12.3723L11.6598 11.1211C11.723 11.0407 11.7575 10.9401 11.7575 10.8367C11.7561 10.5824 11.5506 10.377 11.2964 10.377Z" />
  </svg>
);

Seller.propTypes = {
  style: shape({}),
};

Seller.defaultProps = {
  style: {
    fill: '#2CA084',
  },
};
