import { Box, Typography } from '@material-ui/core';
import { theme } from 'utils/theme';

export const ContingencyTag = ({ contingencyRemoved, statusBlock }) => (
  <Box
    py={statusBlock ? 2 : 1.2}
    mx={statusBlock ? 0.5 : 1}
    my={statusBlock ? 0.5 : 'unset'}
    px={2}
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor={contingencyRemoved ? theme.palette.background.highBid : theme.palette.background.whiteSmoke1}
    borderRadius="2px"
    width={statusBlock && 138}
    minWidth={138}
  >
    <Typography variant={statusBlock ? 'body1' : 'h6'} style={{ color: contingencyRemoved ? 'white' : 'black' }}>
      {contingencyRemoved ? 'Contingency removed' : 'Contingent offer'}
    </Typography>
  </Box>
);
