import { useEffect } from 'react';

import { apolloClient, ON_NEW_SANDBOX_PLUMBID_EVENT_SUBSCRIPTION } from 'service/graphql';

export const useSBPlumBidSubscription = () => {
  useEffect(() => {
    const observer = apolloClient.subscribe({
      query: ON_NEW_SANDBOX_PLUMBID_EVENT_SUBSCRIPTION,
      variables: { room: 'sandbox_plumbid_events' },
    });

    const subscription = observer.subscribe();

    return () => subscription.unsubscribe();
  }, []);
};
