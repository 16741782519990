import { createMuiTheme } from '@material-ui/core';
import { typography } from './typography';
import { palette } from './palette';

export const FONT_FAMILY_HN = '"Helvetica Neue", "Roboto", "Aria", sans-serif';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 960,
      xl: 1440,
      mobile: 500,
    },
  },
  spacing: 8,
  palette,
  typography,
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#F4F4F4',
        borderTopLeftRadius: 'unset',
        borderTopRightRadius: 'unset',
        ' &:hover': {
          backgroundColor: '#F4F4F4',
        },
      },
      input: {
        padding: '12.5px 14px',
      },
      adornedStart: {
        paddingLeft: 'unset',
      },
    },
    MuiInputAdornment: {
      filled: {
        marginTop: 'unset !important',
      },
    },
  },
});
