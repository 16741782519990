import gql from 'graphql-tag';

export const REVIEW_SELLER_ANSWER = gql`
  mutation ReviewSellerAnswers($input: ReviewSellerAnswersInput!) {
    reviewSellerAnswers(input: $input) {
      success
      errors
    }
  }
`;
