import { theme } from 'utils/theme';

export const DotPuls = () => {
  return (
    <>
      <style>
        {`
          @keyframes dot-puls-animation {
            from { transform: scale(1)}
            50% { transform: scale(0.5)}
              to { transform: scale(1)}
          }
        `}
      </style>
      <div
        style={{
          position: 'absolute',
          top: 30,
          left: 44,
          backgroundColor: theme.palette.text.warning,
          height: 10,
          width: 10,
          borderRadius: '50%',
          animation: 'dot-puls-animation 1300ms cubic-bezier(0.1, -0.2, 0.2, 0) infinite',
        }}
      />
    </>
  );
};
