import gql from 'graphql-tag';

export const REMOVE_PARTICIPANT_INVITE_MUTATION = gql`
  mutation RemoveParticipantInvite($input: RemoveParticipantInviteInput!) {
    removeParticipantInvite(input: $input) {
      success
      errors
    }
  }
`;
