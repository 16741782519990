import gql from 'graphql-tag';

export const REMOVE_OFFER_MUTATION = gql`
  mutation RemoveOffer($input: RemoveOfferInput!) {
    removeOffer(input: $input) {
      success
      errors
    }
  }
`;
