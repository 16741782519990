import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { formatNumberToUSD, formatNumberToUSNumeric } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';

const attributes = [
  {
    title: 'Price',
    field: 'listingPrice',
    format: value => formatNumberToUSD(value, { hidePlusSign: true }),
  },
  {
    title: 'Bedrooms',
    field: 'numberOfBedrooms',
  },
  {
    title: 'Baths',
    field: 'numberOfBathrooms',
  },
  {
    title: 'YEAR BUILT',
    field: 'yearBuilt',
  },
  {
    title: 'SQFT',
    field: 'propertySize',
    format: value => `${formatNumberToUSNumeric(value)}`,
  },
  {
    title: 'Exterior',
    field: 'exterior',
    format: value => `${formatNumberToUSNumeric(value)} Acre(s)`,
  },
];

export const PropertyAttributes = ({ property, displayFields, align, ...props }) => {
  const responsive = useResponsive();

  return (
    <Grid
      container
      direction={responsive.mobileScreen ? 'column' : 'row'}
      alignItems="center"
      justify="space-between"
      {...props}
    >
      {attributes
        .filter(attr => displayFields.includes(attr.field))
        .map(attr => {
          let value = property && property[attr.field];
          if (!value) {
            value = '-';
          } else if (attr.format) {
            value = attr.format(value);
          }
          return (
            value !== '-' && (
              <Grid container={responsive.mobileScreen} item key={attr.field} style={{ padding: '0 16px' }}>
                <>
                  <Grid item xs={6} lg="auto">
                    <Typography
                      variant="h4"
                      align={align}
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 4,
                      }}
                    >
                      {attr.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg="auto">
                    <Typography variant={responsive.mobileScreen ? 'h5' : 'subtitle2'} align={align}>
                      {value}
                      {attr.suffix || ''}
                    </Typography>
                  </Grid>
                </>
              </Grid>
            )
          );
        })}
    </Grid>
  );
};

PropertyAttributes.propTypes = {
  displayFields: arrayOf(string),
  align: string,
};

PropertyAttributes.defaultProps = {
  align: 'left',
  displayFields: [
    'numberOfBedrooms',
    'numberOfBathrooms',
    'yearBuilt',
    'interior',
    'exterior',
    'listingPrice',
    'propertySize',
  ],
};
