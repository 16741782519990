import { useEffect } from 'react';

import {
  Footer,
  PageContainer,
  AgentBenefitsLA,
  DescriptionOptimizerLA,
  AboutPlumBidLA,
  LearnMoreLA,
} from 'components';

export const AgentsLAPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer disableLoading>
      <AgentBenefitsLA />
      <DescriptionOptimizerLA />
      <AboutPlumBidLA />
      <LearnMoreLA />
      <Footer />
    </PageContainer>
  );
};
