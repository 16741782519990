import React from 'react';
import { shape } from 'prop-types';

export const RightArrow = ({ style }) => (
  <svg
    style={style}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="12"
      y="4"
      width="12"
      height="0.999999"
      transform="rotate(-180 12 4)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8531 3.14657C12.8997 3.19302 12.9366 3.24819 12.9618 3.30894C12.987 3.36968 13 3.4348 13 3.50057C13 3.56634 12.987 3.63146 12.9618 3.6922C12.9366 3.75295 12.8997 3.80813 12.8531 3.85457L9.85311 6.85457C9.75922 6.94846 9.63188 7.0012 9.49911 7.0012C9.36633 7.0012 9.23899 6.94846 9.14511 6.85457C9.05122 6.76068 8.99847 6.63335 8.99847 6.50057C8.99847 6.36779 9.05122 6.24046 9.14511 6.14657L11.7921 3.50057L9.14511 0.854571C9.05122 0.760684 8.99847 0.633346 8.99847 0.500571C8.99847 0.367795 9.05122 0.240457 9.14511 0.146571C9.23899 0.0526839 9.36633 -6.1194e-05 9.49911 -6.11882e-05C9.63188 -6.11824e-05 9.75922 0.0526839 9.85311 0.146571L12.8531 3.14657Z"
    />
  </svg>
);

RightArrow.propTypes = {
  style: shape({}),
};

RightArrow.defaultProps = {
  style: null,
};
