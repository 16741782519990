import gql from 'graphql-tag';

export const MARK_ADMIN_CHAT_READ_MUTATION = gql`
  mutation MarkAdminChatRead($input: MarkAdminChatReadInput!) {
    markAdminChatRead(input: $input) {
      errors
      success
    }
  }
`;
