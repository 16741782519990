import React from 'react';
import { shape } from 'prop-types';

export const UserAvatarBlack = ({ style }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M15.8333 17.4167H3.16663V15.8333C3.16663 14.7835 3.58366 13.7767 4.32599 13.0344C5.06833 12.292 6.07514 11.875 7.12496 11.875H11.875C12.9248 11.875 13.9316 12.292 14.6739 13.0344C15.4163 13.7767 15.8333 14.7835 15.8333 15.8333V17.4167ZM9.49996 10.2917C8.87618 10.2917 8.25851 10.1688 7.68221 9.93008C7.10592 9.69137 6.58228 9.34149 6.1412 8.90041C5.70012 8.45934 5.35024 7.9357 5.11153 7.3594C4.87282 6.78311 4.74996 6.16544 4.74996 5.54166C4.74996 4.91788 4.87282 4.30021 5.11153 3.72391C5.35024 3.14761 5.70012 2.62398 6.1412 2.1829C6.58228 1.74182 7.10592 1.39194 7.68221 1.15323C8.25851 0.914519 8.87618 0.791656 9.49996 0.791656C10.7597 0.791657 11.9679 1.2921 12.8587 2.1829C13.7495 3.0737 14.25 4.28188 14.25 5.54166C14.25 6.80143 13.7495 8.00962 12.8587 8.90041C11.9679 9.79121 10.7597 10.2917 9.49996 10.2917Z"
      fill={style?.fill || 'black'}
    />
  </svg>
);

UserAvatarBlack.propTypes = {
  style: shape({}),
};

UserAvatarBlack.defaultProps = {
  style: null,
};
