import { theme } from 'utils/theme';

export const Divider = ({ style, children, align = 'horizontal' }) => {
  return (
    <div
      style={{
        ...(align === 'horizontal'
          ? { borderBottom: '1px solid', borderBottomColor: theme.palette.divider }
          : { borderLeft: '1px solid', borderLeftColor: theme.palette.divider }),
        ...style,
      }}
    >
      {children}
    </div>
  );
};
