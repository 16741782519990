import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { CHATS_LIST_QUERY, ON_ADMIN_CHAT_SUBSCRIPTION } from 'service/graphql';

import { useNotification } from './useNotification';

export const useChats = (variables, options) => {
  options = options || {};
  const showNotification = useNotification();
  const { data, loading, refetch, subscribeToMore } = useQuery(CHATS_LIST_QUERY, {
    variables,
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (subscribeToMore && options.withSubscription) {
      return subscribeToMore({
        document: ON_ADMIN_CHAT_SUBSCRIPTION,
        variables: { room: 'admin_chats' },
        updateQuery: (prev, { subscriptionData }) => {
          const newOrUpdatedChat = subscriptionData?.data?.onAdminChat?.chat;

          if (!newOrUpdatedChat) {
            return prev;
          }

          const index = prev.adminChats?.findIndex(chat => chat.id === newOrUpdatedChat.id);
          if (index > -1) {
            const copyChats = [...prev.adminChats];
            copyChats[index] = newOrUpdatedChat;
            return { ...prev, adminChats: copyChats };
          } else {
            return {
              ...prev,
              adminChats: [newOrUpdatedChat, ...prev.adminChats],
            };
          }
        },
      });
    }
  }, [subscribeToMore, options.withSubscription]);

  const chats = data?.adminChats
    ? data?.adminChats
        .map(chat => ({
          ...chat,
          lastMessage: {
            ...chat.lastMessage,
            createdAt: moment(chat.lastMessage.createdAt),
          },
        }))
        .sort((a, b) => {
          if (a.owner.fullName === b.owner.fullName) {
            return b.lastMessage.createdAt.diff(a.lastMessage.createdAt);
          }

          return a.owner.fullName < b.owner.fullName ? -1 : 1;
        })
    : [];
  return {
    chats,
    chatsLoading: loading,
    refetchChats: refetch,
  };
};
