import React from 'react';
import GoogleMapReact from 'google-map-react';
import Box from '@material-ui/core/Box';

import { getEnv } from 'utils/getEnv';

const googleMapsApiKey = getEnv('GOOGLE_MAP_API_KEY');

export const GoogleMap = ({ property }) => {
  const point =
    property && property.latitude && property.longitude ? { lat: +property.latitude, lng: +property.longitude } : {};
  return (
    <Box height="30vw" minHeight="300px" width="100%">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleMapsApiKey,
          libraries: ['places', 'visualization'],
        }}
        defaultCenter={point}
        defaultZoom={11}
        center={point}
        onGoogleApiLoaded={({ map, maps }) =>
          new maps.Marker({
            position: point,
            map,
            title: property.title,
          })
        }
        options={{
          gestureHandling: 'greedy',
          panControl: true,
          mapTypeControl: false,
          scrollwheel: true,
          styles: [
            {
              stylers: [{ visibility: 'on' }],
            },
          ],
        }}
      />
    </Box>
  );
};
