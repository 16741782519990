import React from 'react';
import { Box, DialogContent, Dialog, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { omit, isNil } from 'lodash';
import { object, string, number, ref } from 'yup';
import { useMutation } from '@apollo/client';

import { DialogHeader } from '../Header/DialogHeader';
import { Input, CurrencyInput, NavigationButton, Button } from 'legos';
import { PropertyThumbnail } from 'components';
import { useNotification } from 'utils/hooks';
import { SANDBOX_EDIT_PROPERTY_MUTATION } from 'service/graphql';
import { theme } from 'utils/theme';
import { ESCROW_MAX, ESCROW_MIN } from 'constant';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

const ValidationSchema = object().shape({
  previewImage: string().test('Is image upload?', 'Image is required', function (value) {
    return !(!value && !this.resolve(ref('mlsPreviewImage')));
  }),
  title: string().required('Title is required'),
  listingPrice: number()
    .min(1, 'Listing Price must be greater than or equal to 1')
    .required('Listing Price is required'),
  amountDown: number()
    .min(0, 'Down Payment Importance level must be greater than or equal to 0')
    .max(5.5, 'Down Payment Importance level must be less than or equal to 5.5')
    .required('Down Payment Importance level is required'),
  idealEscrowLength: number()
    .min(ESCROW_MIN, `Ideal Escrow length must be greater than or equal to ${ESCROW_MIN}`)
    .max(ESCROW_MAX, `Ideal Escrow length must be less than or equal to ${ESCROW_MAX}`)
    .required('Ideal Escrow length is required'),
  escrowPriority: number()
    .min(1, 'Escrow Priority must be greater than or equal to 1')
    .max(5, 'Escrow Priority must be less than or equal to 5')
    .required('Escrow Priority is required'),
  inspectionPriority: number()
    .min(0, 'Inspection Priority must be greater than or equal to 0')
    .max(6.5, 'Inspection Priority must be less than or equal to 6.5')
    .required('Inspection Priority is required'),
  loanContingencyPriority: number()
    .min(0, 'Loan Contingency Priority must be greater than or equal to 1')
    .max(5, 'Loan Contingency Priority must be less than or equal to 5')
    .required('Loan Contingency Priority is required'),
  appraisalContingencyPriority: number()
    .min(0, 'Appraisal Contingency Priority must be greater than or equal to 1')
    .max(5, 'Appraisal Contingency Priority must be less than or equal to 5')
    .required('Appraisal Contingency Priority is required'),
  leaseBackLength: number()
    .min(0, 'Lease Back Length must be greater than or equal to 0')
    .max(29, 'Lease Back Length must be less than or equal to 29')
    .required('Lease Back Length is required'),
  leaseBackImportance: number()
    .min(1.5, 'Lease Back Importance must be greater than or equal to 1.5')
    .max(2.5, 'Lease Back Importance must be less than or equal to 2.5')
    .required('Lease Back Importance is required'),
  sellerContingencyPriority: number()
    .min(0.5, 'Seller Contingency Priority must be greater than or equal to 0.5')
    .max(3.5, 'Seller Contingency Priority must be less than or equal to 3.5')
    .required('Seller Contingency Priority is required'),
  buyerWarrantyPriority: number()
    .min(0, 'Buyer Warranty Priority must be greater than or equal to 0')
    .max(5, 'Buyer Warranty Priority must be less than or equal to 5')
    .required('Buyer Warranty Priority is required'),
  buyerContingencyPriority: number()
    .min(0, 'Buyer Contingency Priority must be greater than or equal to 0')
    .max(5, 'Buyer Contingency Priority must be less than or equal to 5')
    .required('Buyer Contingency Priority is required'),
});

const optimizerParameters = [
  {
    name: 'amountDown',
    title: 'Down Payment Importance level',
  },
  {
    name: 'idealEscrowLength',
    title: 'Ideal Escrow Length',
  },
  {
    name: 'escrowPriority',
    title: 'Escrow Priority',
  },
  {
    name: 'inspectionPriority',
    title: 'Inspection Priority',
  },
  {
    name: 'appraisalContingencyPriority',
    title: 'Appraisal Contingency Priority',
  },
  {
    name: 'buyerWarrantyPriority',
    title: 'Buyer Warranty Priority',
  },
  {
    name: 'leaseBackLength',
    title: 'Lease Back length',
  },
  {
    name: 'loanContingencyPriority',
    title: 'Loan Contingency Priority',
  },
  {
    name: 'sellerContingencyPriority',
    title: 'Seller Contingency Priority',
  },
  {
    name: 'buyerContingencyPriority',
    title: 'Buyer Contingency Priority',
  },
  {
    name: 'leaseBackImportance',
    title: 'Lease Back Importance',
  },
];

export const AddPhantomPlumBidDialog = ({ isOpen, onClose, onOk, title, okTitle, property }) => {
  const classes = useStyles();
  const showNotification = useNotification();

  const [addOrEditSandboxProperty, { loading }] = useMutation(SANDBOX_EDIT_PROPERTY_MUTATION);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: property?.title || '',
      listingPrice: property?.listingPrice || 0,
      previewImage: '',
      mlsPreviewImage: property?.previewImage,
      amountDown: property?.amountDown || 0,
      escrowPriority: property?.escrowPriority || 0,
      leaseBackLength: property?.leaseBackLength || 0,
      idealEscrowLength: property?.idealEscrowLength || 0,
      inspectionPriority: property?.inspectionPriority || 0,
      leaseBackImportance: isNil(property?.leaseBackImportance) ? 0.5 : property?.leaseBackImportance,
      buyerWarrantyPriority: property?.buyerWarrantyPriority || 0,
      loanContingencyPriority: property?.loanContingencyPriority || 0,
      buyerContingencyPriority: property?.buyerContingencyPriority || 0,
      sellerContingencyPriority: property?.sellerContingencyPriority || 0,
      appraisalContingencyPriority: property?.appraisalContingencyPriority || 0,
    },
    validationSchema: ValidationSchema,
    onSubmit: values => {
      const input = omit(values, ['previewImage', 'mlsPreviewImage']);

      if (values.previewImage) {
        input.previewImage = values.previewImage;
      }
      if (property) {
        input.propertyId = property.id;
      }

      addOrEditSandboxProperty({
        variables: { input },
      })
        .then(res => {
          if (res?.data?.sandboxEditProperty?.errors) {
            showNotification({ error: res.data.sandboxEditProperty.errors });
          } else if (res?.data?.sandboxEditProperty?.success) {
            onOk();
          }
        })
        .catch(error => showNotification({ error }));
    },
  });

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="lg" scroll="body">
      <DialogHeader title={title} onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4}>
          <Grid container>
            <Grid container item spacing={4}>
              <Grid item xs={8}>
                <Typography variant="h4" align="left">
                  Enter property name
                </Typography>
                <Box pt={1}>
                  <Input
                    value={formik.values.title}
                    style={{ width: '100%', marginRight: 18 }}
                    onChange={e => formik.setFieldValue('title', e.target.value)}
                    error={formik.touched.title && formik.errors.title ? formik.errors.title : null}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" align="left">
                  Enter listing price
                </Typography>
                <Box pt={1}>
                  <CurrencyInput
                    value={formik.values.listingPrice}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('listingPrice', value)}
                    allowNegative={false}
                    error={
                      formik.touched.listingPrice && formik.errors.listingPrice ? formik.errors.listingPrice : null
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Box py={4}>
                  <Typography variant="h4">Upload property photos</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box width="90px" height="90px" style={{ position: 'relative' }}>
                  <PropertyThumbnail
                    isLocalPath={formik.values.previewImage}
                    aspectRatio={1 / 1}
                    src={formik.values.previewImage ? formik.values.previewImage : property?.previewImage}
                  />
                  {formik.values.previewImage && (
                    <NavigationButton
                      icon="Close"
                      color={theme.palette.common.white}
                      style={{
                        background: theme.palette.primary.main,
                        borderRadius: '50%',
                        position: 'absolute',
                        top: 4,
                        right: 4,
                      }}
                      onClick={() => formik.setFieldValue('previewImage', '')}
                    />
                  )}
                </Box>
              </Grid>
              {!formik.values.previewImage && (
                <Grid item>
                  <NavigationButton
                    icon="Add"
                    size={90}
                    iconSize={25}
                    color={theme.palette.text.nobel}
                    fileType=".png, .jpg, .jpeg"
                    style={{ background: theme.palette.background.whiteSmoke, width: 90, height: 90, color: 'red' }}
                    uploadFile
                    onUploadFile={event => formik.setFieldValue('previewImage', event.currentTarget.files[0])}
                  />
                </Grid>
              )}
            </Grid>
            {formik.touched.previewImage && formik.errors.previewImage && (
              <Typography variant="h6" style={{ color: theme.palette.text.error, marginTop: 8 }}>
                {formik.errors.previewImage}
              </Typography>
            )}
            <Grid container item spacing={4}>
              <Grid item xs={12}>
                <Box py={4}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                    Edit optimizer parameters
                  </Typography>
                </Box>
              </Grid>
              {optimizerParameters.map(({ name, title }) => (
                <Grid item xs={12} sm={6} md={4} key={name}>
                  <Typography variant="h4" align="left">
                    {title}
                  </Typography>
                  <Box pt={1}>
                    <CurrencyInput
                      value={formik.values[name]}
                      style={{ width: '100%', marginRight: 18 }}
                      onChange={value => formik.setFieldValue(name, value)}
                      allowNegative={false}
                      error={formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                      prefix=""
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
          <Button title="Cancel" variant="secondary" onClick={onClose} style={{ width: 120 }} />
          <Box width={8} />
          <Button title={okTitle} loading={loading} icon="Add" onClick={formik.handleSubmit} style={{ width: 153 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
