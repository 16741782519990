import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Typography, Grid } from '@material-ui/core';

import { NEW_PARTICIPANT_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import { REVIEW_BUYERS_ONBOARDING } from 'routes';
import { LinkButton, PBox } from 'legos';
import { getParticipantField, getBuyerWarningMessage } from 'utils';
import { useNotification, useResponsive } from 'utils/hooks';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { useMutation } from '@apollo/client';
import { CREATE_INVITE_MUTATION } from 'service/graphql';
import { theme } from 'utils/theme';

export const BuyerParticipantItem = ({
  plumBid,
  participant,
  party,
  showInBuyerOnboarding,
  setSelectParticipant,
  selectedParticipant,
  viewForListingAgent,
}) => {
  const history = useHistory();
  const showNotification = useNotification();
  const [createInvitedMutation, { loading: createInvitedLoading }] = useMutation(CREATE_INVITE_MUTATION);
  const responsive = useResponsive();

  const handleViewOnboarding = useCallback(() => {
    history.push({
      pathname: REVIEW_BUYERS_ONBOARDING,
      search: `plumbid=${plumBid.id}&b=${participant.id}&p=${party.id}`,
    });
  }, [history, participant?.id, party?.id, plumBid?.id]);

  const handleClick = useCallback(() => {
    if (showInBuyerOnboarding) {
      setSelectParticipant(participant);
    }
  }, [participant, setSelectParticipant, showInBuyerOnboarding]);

  const getLink = async () => {
    if (participant.invite?.link) {
      return participant.invite.link;
    } else {
      try {
        const { data } = await createInvitedMutation({
          variables: {
            input: {
              participantId: participant.id,
              email: getParticipantField(participant, 'email'),
              role: participant.role,
              fullName: getParticipantField(participant, 'fullName'),
              plumbidId: plumBid.id,
              plumbidPartyId: party.id,
              copied: true,
            },
          },
        });
        if (data?.createInvite?.errors) {
          showNotification({ error: 'Something went wrong! Please try again.' });
          return '';
        }
        return data.createInvite.invite.link;
      } catch (err) {
        showNotification({ error: 'Something went wrong! Please try again.' });
      }
    }
  };

  const participantStatusProps = useMemo(() => getBuyerWarningMessage(participant), [participant]);
  const isDisabled = plumBid.status === PLUMBID_STATUS.CANCELED || plumBid.status === PLUMBID_STATUS.FINISHED;
  const isFullService = plumBid.service.name === 'fullservice';

  return (
    <PBox
      p={0.75}
      mt={0.5}
      height={40}
      borderRadius="4px"
      bgcolor={
        showInBuyerOnboarding && selectedParticipant?.participantId === participant.id
          ? theme.palette.background.whiteSmoke1
          : theme.palette.common.white
      }
      onClick={handleClick}
      style={{
        cursor: showInBuyerOnboarding ? 'pointer' : 'default',
      }}
    >
      <Grid container justify="space-between">
        <Grid item xs zeroMinWidth>
          <Typography variant="h5" noWrap style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200 }}>
            {getParticipantField(participant, 'fullName') || getParticipantField(participant, 'email') || 'N/A'}
          </Typography>
          <Typography style={{ fontSize: 11, color: theme.palette.text.stone }}>
            {participant.role === ROLES.BUYER ? 'Buyer' : participant.role === ROLES.BUYER_AGENT ? 'Buyers agent' : ''}
          </Typography>
          <Typography
            style={{
              fontSize: 12,
              lineHeight: 1.2,
              fontWeight: 400,
              color: viewForListingAgent
                ? participantStatusProps.color ?? theme.palette.text.warning
                : theme.palette.text.grey,
            }}
          >
            {viewForListingAgent ? participantStatusProps.text : getParticipantField(participant, 'email')}
          </Typography>
        </Grid>
        {!showInBuyerOnboarding && (
          <Grid item xs="auto">
            {viewForListingAgent ? (
              participant.status === NEW_PARTICIPANT_STATUS.INVITED ||
              participant.status === NEW_PARTICIPANT_STATUS.PENDING ? (
                <CopyToClipboard
                  label="Copy link"
                  getText={getLink}
                  disabled={!participant.id || isDisabled}
                  loading={createInvitedLoading}
                />
              ) : participant.role === ROLES.BUYER && !isFullService ? (
                <LinkButton
                  icon="ViewEye"
                  color={theme.palette.primary.main}
                  title={responsive.smallScreen ? '' : 'View Onboarding'}
                  onClick={handleViewOnboarding}
                  style={{ fontWeight: 500, whiteSpace: 'noWrap' }}
                />
              ) : null
            ) : (
              <Typography
                style={{
                  fontSize: 12,
                  lineHeight: 1.2,
                  fontWeight: 400,
                  color: participantStatusProps.color ?? theme.palette.text.warning,
                }}
              >
                {participantStatusProps.text}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </PBox>
  );
};
