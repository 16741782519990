import { Box, Tooltip, Zoom } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Slider from 'vendor/Slider';
import { theme } from 'utils/theme';
import { useState } from 'react';

const thumbMarginLeft = 12;

export const CustomCardSlider = withStyles(theme => ({
  root: {
    color: theme.palette.purple.light,
    '& .Mui-disabled': {
      height: 26,
      width: 26,
      borderColor: theme.palette.text.stone,
      backgroundColor: theme.palette.text.stone,
      marginTop: -9,
      marginLeft: -12,
    },
    '& .MuiSlider-track': {
      marginTop: props => (props.track === 'inverted' ? 0 : 2),
      height: props => (props.track === 'inverted' ? 6 : 2),
      borderRadius: 0,
      backgroundColor: props =>
        props.disabled || props.inactive
          ? theme.palette.background.paper
          : props.track === 'inverted'
          ? theme.palette.background.paper
          : theme.palette.cardSliderTrack,
    },
    '& .MuiSlider-rail': {
      marginTop: props => (props.track === 'inverted' ? 2 : 0),
      height: props => (props.track === 'inverted' ? 2 : 6),
      borderRadius: 0,
      opacity: 1,
      backgroundColor: props =>
        props.disabled || props.inactive
          ? theme.palette.background.paper
          : props.track === 'inverted'
          ? theme.palette.cardSliderTrack
          : theme.palette.background.paper,

      outline: props => props.track === 'inverted' && '2px solid white',
    },
    zIndex: 12,
  },
  thumb: {
    height: 26,
    width: 26,
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.purple.light,
    marginTop: -9,
    marginLeft: -thumbMarginLeft,
    zIndex: 10,
    '&:focus, &:hover, &:active': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
      height: 26,
    },
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25) !important',
  },

  active: {
    height: 3,
    borderRadius: 0,
  },

  mark: {
    backgroundColor: theme.palette.text.grey,
    height: 5,
    width: 1.63,
    marginTop: 28,
  },
  markLabel: {
    marginTop: 10,
    opacity: 1,
    zIndex: 10,
    backgroundColor: theme.palette.background.card,
  },
  markActive: {
    backgroundColor: theme.palette.background.tabIndicator,
  },
}))(Slider);

const CustomToolTip = withStyles(theme => ({
  tooltip: {
    padding: 6,
    fontSize: 12,
  },
}))(Tooltip);

export const CardSlider = ({ innerId, value, onChange, onChangeCommitted, track, isPercent, ...props }) => {
  const [oldValue, setOldValue] = useState(null);

  const onChangeHandle = (_, newValue) => {
    if (props?.maxValue !== undefined && props?.max + props?.min - newValue > props.maxValue && track === 'inverted') {
      newValue = props?.max + props?.min - props.maxValue;
    }
    if (oldValue === null) {
      setOldValue(value);
    }
    onChange(_, newValue);
  };

  const onChangeCommittedHandle = (_, newValue) => {
    if (props?.maxValue !== undefined && props?.max + props?.min - newValue > props.maxValue && track === 'inverted') {
      newValue = props?.max + props?.min - props.maxValue;
    }

    onChangeCommitted(_, newValue, newValue !== oldValue);
    setOldValue(null);
  };

  return (
    <CustomCardSlider
      value={value}
      component="div"
      valueLabelDisplay="off"
      onChange={onChangeHandle}
      onChangeCommitted={onChangeCommittedHandle}
      track={track}
      {...props}
    >
      {props.maxValue >= 0 && props.maxValue < props.max ? (
        <>
          <Box
            style={{
              left: track === 'inverted' ? 0 : 'unset',
              right: track !== 'inverted' ? 0 : 'unset',
              height: 3,
              zIndex: 1,
              marginTop: 2,
              position: 'absolute',
              boxSizing: 'content-box',
              backgroundColor: isPercent ? theme.palette.cardSliderTrack : theme.palette.text.warning,
              width: `${((props.max - props.maxValue) * 100) / (props.max - props.min)}%`,
            }}
          />
          <CustomToolTip
            arrow
            open
            placement="top"
            title={`${isPercent ? 'Recommended:' : 'Max:'} ${props.maxValue}${isPercent ? '%' : ''}`}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}
            PopperProps={{
              disablePortal: true,
            }}
          >
            <Box
              style={{
                width: 12,
                zIndex: 22,
                height: 12,
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                top: 'calc(50% - 3px)',
                boxSizing: 'content-box',
                justifyContent: 'center',
                backgroundColor: isPercent ? theme.palette.cardSliderTrack : theme.palette.text.warning,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                left:
                  track === 'inverted'
                    ? `calc(${((props.max - props.maxValue) * 100) / (props.max - props.min)}% - ${
                        thumbMarginLeft - 7
                      }px)`
                    : 'unset',

                right:
                  track !== 'inverted'
                    ? `calc(${((props.max - props.maxValue) * 100) / (props.max - props.min)}% - ${
                        thumbMarginLeft - 7
                      }px)`
                    : 'unset',
              }}
            />
          </CustomToolTip>
        </>
      ) : null}
    </CustomCardSlider>
  );
};
