import { Grid } from '@material-ui/core';

import { formatNumberToUSD } from 'utils/formatters';
import { Spinner, Text } from 'legos';
import { theme } from 'utils/theme';
import { useResponsive } from 'utils/hooks';

export const CurrentMinimumBidCard = ({
  bidsHistory,
  plumbidData,
  currentBid,
  minimalBid,
  minimalBidLoading,
  disabled,
}) => {
  const { tabletScreen } = useResponsive();
  const isFullService = plumbidData?.service?.name === 'fullservice';
  return (
    <>
      {(!isFullService ||
        (isFullService && bidsHistory?.length > 0)) && (
          <Grid container>
            <Grid item xs="auto">
              <Text
                disabled={disabled}
                variant="h2"
                style={{ fontWeight: 400, lineHeight: 1.7, minWidth: tabletScreen ? 230 : 140, marginRight: 4 }}
              >
                Current plumBid:
              </Text>
            </Grid>
            <Grid item xs="auto">
              <Text
                variant="h3"
                disabled={disabled}
                style={{
                  fontSize: 24,
                  lineHeight: 1.7,
                  color: theme.palette.purple.light,
                }}
              >
                {formatNumberToUSD(currentBid, {
                  hidePlusSign: true,
                })}
              </Text>
            </Grid>
          </Grid>
        )}
      <Grid container>
        <Grid item xs="auto">
          <Text disabled={disabled} variant="h2" style={{ fontWeight: 400, lineHeight: 1.7, minWidth: 230 }}>
            Minimum plumBid:
          </Text>
        </Grid>
        <Grid item xs="auto" style={{ alignSelf: 'flex-end' }}>
          <Text
            variant="h3"
            disabled={disabled}
            style={{
              fontSize: 24,
              lineHeight: 1.7,
              color: theme.palette.purple.light,
            }}
          >
            {minimalBidLoading ? (
              <Spinner />
            ) : (
              formatNumberToUSD(Math.round(+minimalBid), {
                hidePlusSign: true,
              })
            )}
          </Text>
        </Grid>
      </Grid>
    </>
  );
};
