import React, { useState } from 'react';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Select } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';
import { formatUserFullName } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const AutoFillQuestionnaireDialog = ({ isOpen, onClose, onOk, users, isSellerParty, loading }) => {
  const classes = useStyles();
  const responsive = useResponsive();
  const [selectedUser, setSelectedUser] = useState(0);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="md" scroll="body">
      <DialogHeader title="AUTOMATIC QUESTIONNAIRE PAIRING" onClose={onClose} />
      <DialogContent
        style={{ paddingLeft: responsive.mobileScreen ? 8 : 24, paddingRight: responsive.mobileScreen ? 8 : 24 }}
      >
        <Box mx={1} mt={1} mb={2} pb={4}>
          <Typography variant="h5" style={{ lineHeight: 1.9, verticalAlign: 'center', fontSize: 16 }}>
            This questionnaire was already completed by
            <span style={{ marginLeft: 4, marginRight: 4 }}>
              <Select
                value={selectedUser}
                small
                items={users.map((user, index) => ({ text: formatUserFullName(user), value: index }))}
                onChange={e => setSelectedUser(e.target.value)}
              />
            </span>
            - {isSellerParty ? 'seller' : 'a co-buyer'} in your party.
          </Typography>

          <Typography variant="h5" style={{ lineHeight: 1.9, fontSize: 16 }}>
            If you feel comfortable with their answers, you can simply confirm. If not, please complete.
          </Typography>
        </Box>
        <Box display="flex" justifyContent={responsive.mobileScreen ? 'center' : 'flex-end'} mt={1} mb={2}>
          <Button
            title="I want to complete it myself"
            variant="secondary"
            marginLeft={responsive.mobileScreen ? 1 : 2}
            marginRight={responsive.mobileScreen ? 1 : 2}
            style={{ width: '30%', minWidth: 105 }}
            onClick={onClose}
          />
          <Box width={8} />
          <Button
            title="Confirm auto-pairing"
            style={{ width: '35%', minWidth: 105 }}
            marginLeft={responsive.mobileScreen ? 1 : 2}
            marginRight={responsive.mobileScreen ? 1 : 2}
            onClick={() => onOk(selectedUser)}
            loading={loading}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
