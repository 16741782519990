import { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import { PageContainer, Footer } from 'components';
import { Button } from 'legos';
import { ACCEPT_INVITE_MUTATION } from 'service/graphql';
import { formatGraphqlError } from 'utils/formatters';
import { removeSession } from 'service/auth';
import { useAuthState, useSettingsState } from 'service/store';
import { usePageLoading, useDialog, useGetMe } from 'utils/hooks';
import { HomePage } from 'pages';
import { Icons } from 'legos';
import { DIALOG } from 'constant';
import { theme } from 'utils/theme';
import { DASHBOARD } from 'routes';
import state from 'service/graphql/state';

export const InvitePage = () => {
  const { loading: loadingGetMe } = useGetMe();
  const [{ me, loggedIn }, dispatchAuth] = useAuthState();
  const [, dispatchSettings] = useSettingsState();

  const history = useHistory();
  const location = useLocation();
  const { token, email } = queryString.parse(location.search);

  const { openDialog: openOkCancelDialog, closeDialog: closeOkCancelDialog } = useDialog(DIALOG.OK_CANCEL);
  const [acceptInvite, { loading, error, data, called }] = useMutation(ACCEPT_INVITE_MUTATION);

  usePageLoading(loading, 'InvitePage');

  const isShowErrorInvite = !loading && (data?.acceptInvite?.errors?.length || error || !token);
  const isShowHomePage = !loading && data?.acceptInvite?.success;

  const dispatchInvite = useCallback(
    data => {
      dispatchSettings({
        type: 'invite',
        payload: {
          invite: {
            ...data?.acceptInvite.invite,
            token,
            plumbid: data?.acceptInvite.plumbid,
            userExists: data?.acceptInvite.userExists,
          },
        },
      });
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: {
          isOpenDrawer: true,
          drawerScreen: data?.acceptInvite.userExists ? 'SignInFromInvitationScreen' : 'SignUpFromInvitationScreen',
          drawerScreenGoBack: data?.acceptInvite.userExists
            ? 'SignUpFromInvitationScreen'
            : 'SignInFromInvitationScreen',
        },
      });
    },
    [dispatchSettings, token]
  );
  useEffect(() => {
    if (
      (!loggedIn && !loadingGetMe && token && !called) ||
      (loggedIn && me?.userId && !loadingGetMe && token && !called)
    ) {
      acceptInvite({
        variables: {
          input: {
            token,
          },
        },
      }).then(({ data }) => {
        if (!loggedIn && data?.acceptInvite?.success) {
          dispatchInvite(data);
        } else if (
          loggedIn &&
          data?.acceptInvite?.success &&
          data?.acceptInvite?.invite?.participant?.user?.userId !== me?.userId
        ) {
          dispatchAuth({
            type: 'logout',
          });
          removeSession();
          dispatchInvite(data);
        } else if (
          loggedIn &&
          data?.acceptInvite?.success &&
          data?.acceptInvite?.invite?.participant?.user?.userId === me?.userId
        ) {
          history.push({
            pathname: DASHBOARD,
            search: `plumbid=${data.acceptInvite?.plumbid.id}`,
          });
        }
      });
    }
  }, [
    token,
    acceptInvite,
    loggedIn,
    me?.userId,
    me?.fullName,
    dispatchInvite,
    dispatchAuth,
    openOkCancelDialog,
    closeOkCancelDialog,
    history,
    called,
    loadingGetMe,
  ]);

  useEffect(() => {
    if (email) {
      state.signUpFormVar({ email: email.replace(' ', '+') });
    }
  }, [email]);

  return (
    <PageContainer>
      {isShowErrorInvite ? (
        <>
          <Box height="60vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column" pt={5}>
            <Grid container item xs={12} md={8} direction="row" spacing={4}>
              <Grid item container justify="flex-end" xs={2} sm={4}>
                <Icons.TimeInvite />
              </Grid>
              <Grid item container direction="column" spacing={1} xs={8}>
                <Grid item>
                  {data?.acceptInvite?.errors?.length &&
                    data.acceptInvite.errors.map(err => (
                      <Typography
                        variant="h1"
                        paragraph
                        style={{ textTransform: 'uppercase', color: theme.palette.text.primary }}
                      >
                        {err}
                      </Typography>
                    ))}
                  {error && (
                    <Typography variant="h3" paragraph color="textSecondary">
                      {formatGraphqlError(error)}
                    </Typography>
                  )}
                  {!token && (
                    <Typography variant="h3" paragraph color="textSecondary">
                      Invalid link
                    </Typography>
                  )}
                  <Typography
                    variant="h4"
                    paragraph
                    style={{ fontSize: 14, textTransform: 'uppercase', color: theme.palette.text.primary }}
                  >
                    Please contact the Listing Agent who issued the link
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography
                    variant="h6"
                    paragraph
                    align="center"
                    style={{
                      fontWeight: 700,
                      backgroundColor: theme.palette.divider,
                      borderRadius: '50%',
                      width: 'auto',
                      padding: '10px 8px',
                      verticalAlign: 'middle',
                    }}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" paragraph style={{ fontSize: 14 }}>
                    I already have an account
                  </Typography>
                  <Button
                    title="Go to login"
                    icon="RightArrow"
                    style={{ minWidth: 240 }}
                    onClick={() => {
                      history.replace('/');
                      dispatchSettings({
                        type: 'toggleAuthDrawer',
                        payload: {
                          isOpenDrawer: true,
                          drawerScreen: 'SignInScreen',
                          drawerScreenGoBack: null,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      ) : null}
      {isShowHomePage ? <HomePage /> : null}
    </PageContainer>
  );
};
