import gql from 'graphql-tag';

import { SBBidType } from '../types';

export const CREATE_SANDBOX_BID_MUTATION = gql`
  mutation SandboxCreateBid($input:  SandboxCreateBidInput!) {
    sandboxCreateBid(input: $input) {
      success
      errors
      bid {
        id
        ${SBBidType}
      }
    }
  }
`;
