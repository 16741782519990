import React from 'react';

import { Box, LinearProgress, Typography } from '@material-ui/core';

export const UploadingBar = ({ progress }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5">
        {progress < 100
          ? `Uploading offers... ${progress}%`
          : 'Uploading is complete. Please continue to step 5 to review your offers.'}
      </Typography>
      <Box pt={1.25}>
        <LinearProgress variant="determinate" value={progress} style={{ height: 6 }} />
      </Box>
    </Box>
  );
};
