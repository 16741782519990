import { Fragment } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';

import { CopyToClipboard } from 'components';
import { Icons, PBox } from 'legos';
import { theme } from 'utils';

export const ParticipantActions = ({
  participant,
  loading,
  handleAccept,
  handleClose,
  handleEdit,
  getLink,
  isButtonDisabled,
  isAcceptDisabled,
  isCloseDisabled,
  disableDelete,
  disableEditingField,
}) => (
  <>
    {participant.edited ? (
      <IconButton disabled={isAcceptDisabled} style={{ height: 36, width: 36 }} onClick={handleAccept}>
        {loading ? (
          <CircularProgress
            style={{
              backgroundColor: 'transparent',
              color: 'inherit',
              opacity: 0.6,
              position: 'absolute',
              top: 'calc(50% - 10px)',
              left: 'calc(50% - 10px)',
            }}
            size={20}
          />
        ) : (
          <Icons.Accept
            style={{
              fill: isAcceptDisabled || isButtonDisabled ? theme.palette.disabled : theme.palette.purple.dark,
              height: 36,
              width: 36,
            }}
          />
        )}
      </IconButton>
    ) : (
      <>
        <PBox p={0.5} display="flex">
          <CopyToClipboard label="Copy link" getText={getLink} disabled={!participant.id || isButtonDisabled} />
        </PBox>
        <IconButton
          style={{
            padding: 0,
          }}
          onClick={handleEdit}
          disabled={disableEditingField}
        >
          <Icons.EditPencilNoBG
            style={{
              fill: disableEditingField ? theme.palette.text.rain : theme.palette.purple.dark,
            }}
          />
        </IconButton>
      </>
    )}

    <PBox>
      <IconButton
        style={{ height: 38, width: 38 }}
        onClick={handleClose}
        disabled={disableDelete || isCloseDisabled || isButtonDisabled}
      >
        {participant.edited ? (
          <Icons.Close
            style={{ fill: isCloseDisabled || isButtonDisabled ? theme.palette.disabled : theme.palette.purple.dark }}
          />
        ) : (
          <Icons.Delete
            style={{
              fill:
                disableDelete || isCloseDisabled || isButtonDisabled
                  ? theme.palette.text.rain
                  : theme.palette.purple.dark,
            }}
          />
        )}
      </IconButton>
    </PBox>
  </>
);
