import React, { createContext, useContext, useReducer } from 'react';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const initialState = {
    isOpenDrawer: false,
    drawerScreen: 'SignInScreen',
    drawerScreenGoBack: 'SignInScreen',
    refreshScreen: null,
    invite: null,
    plumBidPartyId: null,
    createAdminPassword: null,
    hasNewChatMessages: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'hasNewChatMessages': {
        return {
          ...state,
          hasNewChatMessages: action.payload,
        };
      }
      case 'toggleAuthDrawer':
        return {
          ...state,
          hasNewChatMessages: action.payload.drawerScreen === 'UserChatScreen' ? false : state.hasNewChatMessages,
          isOpenDrawer: action.payload.isOpenDrawer,
          drawerScreen: action.payload.drawerScreen,
          drawerScreenGoBack: action.payload.drawerScreenGoBack,
          resetPasswordUidb64: action.payload.resetPasswordUidb64,
          resetPasswordToken: action.payload.resetPasswordToken,
          createAdminPassword: action.payload.createAdminPassword,
        };
      case 'refreshScreen':
        return {
          ...state,
          refreshScreen: action.payload,
        };
      case 'invite':
        return {
          ...state,
          invite: action.payload.invite,
        };
      case 'plumBidPartyId':
        return {
          ...state,
          plumBidPartyId: action.payload.plumBidPartyId,
        };
      case 'sendInvite':
        return {
          ...state,
          sendInvite: {
            ...action.payload,
          },
        };
      default:
        return state;
    }
  };

  return <SettingsContext.Provider value={useReducer(reducer, initialState)}>{children}</SettingsContext.Provider>;
};

export const useSettingsState = () => useContext(SettingsContext);
