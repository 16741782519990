import React from 'react';
import { SnackbarProvider } from 'notistack';
import { withStyles } from '@material-ui/styles';

const snackBarStyle = theme => ({
  error: { backgroundColor: `${theme.palette.error.main} !important` },
  success: { backgroundColor: `${theme.palette.purple.light} !important` },
});

export const SnackBar = withStyles(snackBarStyle)(({ children, classes }) => (
  <SnackbarProvider
    classes={{ variantError: classes.error, variantSuccess: classes.success }}
    maxSnack={3}
    autoHideDuration={2500}
  >
    {children}
  </SnackbarProvider>
));
