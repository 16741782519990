import gql from 'graphql-tag';

export const CREATE_INVITE_MUTATION = gql`
  mutation CreateInviteMutation($input: CreateInviteInput!) {
    createInvite(input: $input) {
      success
      errors
      invite {
        fullName
        link
        token
      }
    }
  }
`;
