import gql from 'graphql-tag';

import { ExtendedUserType } from '../types';

export const ON_NEW_LISTING_AGENT_STATUS_SUBSCRIPTION = gql`
  subscription OnNewListingAgentStatus($room: String) {
    onNewListingAgentStatus(room: $room) {
      listingAgent {
        ${ExtendedUserType}
      }
    }
  }
`;
