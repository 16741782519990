import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { formatGraphqlError } from 'utils/formatters';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useCallback(
    ({ message, error, variant = 'error' }) => {
      if (error) {
        enqueueSnackbar(formatGraphqlError(error), {
          variant: 'error',
          autoHideDuration: 6500,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          preventDuplicate: true,
          onClick: () => {
            closeSnackbar();
          },
        });
      }
      if (message) {
        enqueueSnackbar(message, {
          variant,
          autoHideDuration: 6500,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          preventDuplicate: true,

          onClick: () => {
            closeSnackbar();
          },
        });
      }
    },
    [enqueueSnackbar, closeSnackbar]
  );
};
