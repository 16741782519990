import { useState, useEffect } from 'react';
import { useDebounce } from 'utils/hooks';

import { Input } from './Input';

export const SearchInput = ({ value, delay = 500, onChange, placeholder = 'Search' }) => {
  const [searchValue, setSearchValue] = useState(value);
  const debouncedSearchText = useDebounce(searchValue, delay);
  useEffect(() => {
    onChange(debouncedSearchText);
  }, [debouncedSearchText, onChange]);

  return (
    <Input
      variant="rounded"
      startAdornment="Search"
      type="search"
      placeholder={placeholder}
      style={{ height: 48 }}
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      clear={searchValue ? true : false}
      onClear={() => setSearchValue('')}
    />
  );
};
