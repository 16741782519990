import React from 'react';
import { bool, shape } from 'prop-types';

import { Grid, ListItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { formatNumberToUSD } from 'utils/formatters';
import { Icons } from 'legos';
import { PropertyThumbnailNameAddress } from 'components/PropertyThumbnailNameAddress';
import { theme } from 'utils/theme';

export const PlumBidItem = ({ property }) => {
  return (
    <ListItem
      style={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 8,
      }}
    >
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item xs={8}>
          <PropertyThumbnailNameAddress property={property.mls} />
        </Grid>
        <Grid item container xs={4}>
          <Grid item container xs={12} justify="flex-end">
            <Typography variant="caption" style={{ fontWeight: 300 }}>
              {formatNumberToUSD(property.listPrice, { hidePlusSign: true })}
            </Typography>
          </Grid>
          <Grid item container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <Icons.UserInverse style={{ borderRadius: '50%' }} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                {property.listAgent}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PlumBidItem.propTypes = {
  property: shape({}),
  withPrice: bool,
};

PlumBidItem.defaultProps = {
  withPrice: false,
};
