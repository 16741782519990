import React, { memo } from 'react';
import { Box, Grid } from '@material-ui/core';

import { BaseCard } from './BaseCard';
import { Checkbox, Text } from 'legos';
import { formatNumberToUSD } from 'utils/formatters';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AdditionalTermsCard = memo(
  ({ disabled, loading, valueOfferLeaseBack, sellerPrioritiesValue, changeIncentive, valueOfferLeaseBackImprove }) => {
    const { mobileScreen } = useResponsive();

    const priorityNotApplied = valueOfferLeaseBackImprove < 1;

    return (
      <BaseCard
        disabled={disabled}
        improve={+(valueOfferLeaseBack ? valueOfferLeaseBackImprove : 0)}
        sellerPrioritiesTitle="Seller Contingency Priority"
        sellerPrioritiesValue={sellerPrioritiesValue}
        loading={loading}
        priorityNotApplied={priorityNotApplied}
      >
        <Box mb={2} mt={5.2}>
          <Text
            variant="h2"
            disabled={disabled}
            style={{
              fontSize: mobileScreen ? 20 : 24,
            }}
          >
            Additional Terms
          </Text>
        </Box>
        {!priorityNotApplied && (
          <Grid container direction="row" alignItems="center" justify="space-between" mb={2}>
            <Grid item xs={8}>
              <Text disabled={disabled} variant="caption" style={{ flex: 6, margin: 0, padding: 0 }}>
                Offer Lease Back
              </Text>
            </Grid>

            <Grid item xs={1}>
              <Checkbox
                component="span"
                disabled={disabled}
                checked={valueOfferLeaseBack}
                style={{ flex: 2, margin: 0, padding: 0 }}
                onChange={(_, newValue) => changeIncentive(newValue, 'offerLeaseBack')}
              />
            </Grid>

            <Grid item xs={3}>
              <Text
                disabled={disabled}
                variant="h6"
                style={{
                  ...(valueOfferLeaseBack
                    ? { fontWeight: 700, color: theme.palette.purple.light }
                    : { fontWeight: 400, color: 'rgba(0, 0, 0, 0.69)' }),
                  margin: 0,
                  padding: 0,
                  flex: 4,
                }}
              >
                {formatNumberToUSD(valueOfferLeaseBackImprove)}
              </Text>
            </Grid>
          </Grid>
        )}
      </BaseCard>
    );
  }
);
