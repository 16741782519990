import gql from 'graphql-tag';

export const CHANGE_BUYER_ANSWERS_MUTATION = gql`
  mutation ChangeBuyerAnswers($input: ChangeBuyerAnswersInput!) {
    changeBuyerAnswers(input: $input) {
      success
      errors
    }
  }
`;
