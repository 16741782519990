import React from 'react';

export const UserInverse = ({ style }) => (
  <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4595 18.0163C20.5887 16.6588 21.3741 15.0495 21.7492 13.3246C22.1243 11.5997 22.0781 9.80995 21.6145 8.10667C21.1509 6.40339 20.2835 4.83672 19.0858 3.53921C17.8881 2.2417 16.3952 1.25152 14.7334 0.65242C13.0717 0.0533218 11.29 -0.137064 9.53907 0.097368C7.78813 0.3318 6.11944 0.984154 4.67419 1.99924C3.22893 3.01433 2.04962 4.36229 1.23602 5.9291C0.422417 7.4959 -0.00154314 9.23546 4.22045e-06 11.0006C0.000597995 13.5666 0.905593 16.0505 2.55619 18.0163L2.54046 18.0296C2.5955 18.0956 2.6584 18.1522 2.71501 18.2174C2.78578 18.2983 2.86205 18.3745 2.93517 18.4531C3.15533 18.6919 3.38178 18.9213 3.61923 19.1366C3.69157 19.2026 3.76627 19.2639 3.83939 19.3267C4.091 19.5436 4.34968 19.7494 4.6178 19.9411C4.6524 19.9647 4.68385 19.9953 4.71845 20.0196V20.0102C6.55999 21.3051 8.75684 22 11.0087 22C13.2605 22 15.4573 21.3051 17.2989 20.0102V20.0196C17.3335 19.9953 17.3641 19.9647 17.3995 19.9411C17.6668 19.7486 17.9263 19.5436 18.1779 19.3267C18.251 19.2639 18.3257 19.2018 18.3981 19.1366C18.6355 18.9205 18.862 18.6919 19.0821 18.4531C19.1553 18.3745 19.2307 18.2983 19.3023 18.2174C19.3581 18.1522 19.4218 18.0956 19.4768 18.0289L19.4595 18.0163ZM11.0079 4.71556C11.7077 4.71556 12.3917 4.9229 12.9736 5.31137C13.5555 5.69984 14.009 6.25198 14.2768 6.89798C14.5446 7.54398 14.6146 8.25482 14.4781 8.94061C14.3416 9.6264 14.0046 10.2563 13.5098 10.7508C13.0149 11.2452 12.3845 11.5819 11.6981 11.7183C11.0118 11.8547 10.3004 11.7847 9.65384 11.5171C9.00731 11.2495 8.45471 10.7964 8.06593 10.215C7.67714 9.63364 7.46962 8.95012 7.46962 8.2509C7.46962 7.31327 7.8424 6.41404 8.50595 5.75103C9.1695 5.08803 10.0695 4.71556 11.0079 4.71556ZM4.72316 18.0163C4.7368 16.9847 5.15636 16 5.89106 15.2752C6.62577 14.5503 7.61654 14.1437 8.64904 14.1431H13.3667C14.3992 14.1437 15.39 14.5503 16.1247 15.2752C16.8594 16 17.2789 16.9847 17.2926 18.0163C15.5681 19.569 13.3292 20.4282 11.0079 20.4282C8.68658 20.4282 6.44759 19.569 4.72316 18.0163Z"
      fill={style.fill ? style.fill : '#302727'}
    />
  </svg>
);
