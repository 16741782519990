import { Box } from '@material-ui/core';

import { PropertyThumbnailNameAddress } from 'components/PropertyThumbnailNameAddress';

export const WizardPropertyThumbnail = ({ plumBid, currentStep }) => {
  return (
    <Box p={2} border={1}>
      <PropertyThumbnailNameAddress property={plumBid?.mls} />
    </Box>
  );
};
