import gql from 'graphql-tag';

export const APPROVE_LISTING_AGENT_MUTATION = gql`
  mutation ApproveListingAgentMutation($input: ApproveListingAgentInput!) {
    approveListingAgent(input: $input) {
      success
      errors
    }
  }
`;
