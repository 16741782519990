import gql from 'graphql-tag';

import { BidType } from '../types';

export const GET_BIDS_QUERY = gql`
  query Bids($id: Int) {
    bids(plumbidId: $id) {
      id
      ${BidType}
    }
  }
`;
