import { Box, Typography } from '@material-ui/core';

import { TERM_OF_USE } from 'routes';
import { Button, RouterLink } from 'legos';

import { ReviewOfferContent } from 'components/OfferModal/ReviewOfferContent';
import { theme } from 'utils';

export const OfferCard = ({ index, offer, plumBid, miniature, style, onClose }) => {
  const renderContent = () => (
    <Box style={style}>
      {miniature && (
        <Typography variant="h1" align="left" style={{ paddingBottom: 16 }}>
          OFFER REVIEW
        </Typography>
      )}

      <ReviewOfferContent plumBid={plumBid} offerDetails={offer} asOfferCard />
    </Box>
  );

  if (!offer) {
    return null;
  }

  return miniature ? (
    renderContent()
  ) : (
    <Box>
      <Box m={3}>{renderContent()}</Box>
      <Box m={3}>
        <Typography variant="h5">
          IMPORTANT: Once accepted your bid considered final and cannot be cancelled.{' '}
          <RouterLink to={TERM_OF_USE} onClick={onClose} style={{ color: theme.palette.text.primary }}>
            {'Terms & Conditions'}
          </RouterLink>
        </Typography>
      </Box>
      {!miniature && (
        <Box mx={3.75} mb={5.5} mt={{ sm: 10, xs: 10 }} textAlign="right">
          <Button title="Close" variant="secondary" onClick={onClose} style={{ width: 115 }} />
        </Box>
      )}
    </Box>
  );
};
