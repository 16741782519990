import gql from 'graphql-tag';

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangeUserPasswordMutation($input: ChangeUserPasswordInput!) {
    changeUserPassword(input: $input) {
      success
      errors
    }
  }
`;
