import React from 'react';
import { shape } from 'prop-types';

export const Optimizer = ({ style }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M16 2.28571H13.6571C13.3714 0.971429 12.2286 0 10.8571 0C9.48571 0 8.34286 0.971429 8.05714 2.28571H0V3.42857H8.05714C8.34286 4.74286 9.48571 5.71429 10.8571 5.71429C12.2286 5.71429 13.3714 4.74286 13.6571 3.42857H16V2.28571ZM10.8571 4.57143C9.88571 4.57143 9.14286 3.82857 9.14286 2.85714C9.14286 1.88571 9.88571 1.14286 10.8571 1.14286C11.8286 1.14286 12.5714 1.88571 12.5714 2.85714C12.5714 3.82857 11.8286 4.57143 10.8571 4.57143Z"
      fill="white"
    />
    <path
      d="M0 11.4286H2.34286C2.62857 12.7429 3.77143 13.7143 5.14286 13.7143C6.51429 13.7143 7.65714 12.7429 7.94286 11.4286H16V10.2857H7.94286C7.65714 8.97143 6.51429 8 5.14286 8C3.77143 8 2.62857 8.97143 2.34286 10.2857H0V11.4286ZM5.14286 9.14286C6.11429 9.14286 6.85714 9.88571 6.85714 10.8571C6.85714 11.8286 6.11429 12.5714 5.14286 12.5714C4.17143 12.5714 3.42857 11.8286 3.42857 10.8571C3.42857 9.88571 4.17143 9.14286 5.14286 9.14286Z"
      fill="white"
    />
  </svg>
);

Optimizer.propTypes = {
  style: shape({}),
};

Optimizer.defaultProps = {
  style: null,
};
