import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USERS_QUERY } from 'service/graphql';

import { useNotification } from './useNotification';
import { normalizeUser } from 'utils';

export const useUsers = variables => {
  const showNotification = useNotification();
  const { data, loading, fetchMore, refetch } = useQuery(GET_USERS_QUERY, {
    variables,
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (data?.users?.errors) {
      showNotification({ error: data?.users?.errors });
    }
  }, [data?.users?.errors, showNotification]);

  const users =
    data?.users?.edges?.map(({ node }) => normalizeUser(node)) || [];

  return {
    users,
    hasNextPage: data?.users?.pageInfo?.hasNextPage,
    endCursor: data?.users?.pageInfo?.endCursor,
    usersLoading: loading,
    fetchMoreUsers: fetchMore,
    refetchUsers: refetch,
  };
};
