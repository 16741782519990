import gql from 'graphql-tag';

export const RECALCULATE_PMBID_OFFERS_BIDS_MUTATION = gql`
  mutation RecalculatePmbidOffersBids($input: RecalculatePlumBidOffersBidsInput!) {
    recalculatePmbidOffersBids(input: $input) {
      success
      errors
    }
  }
`;
