import { withStyles } from '@material-ui/styles';
import { Box, IconButton } from '@material-ui/core';

import { Icons } from 'legos';
import { DIALOG } from 'constant';
import { useDialog, useResponsive } from 'utils/hooks';
import smartMockupsHomeFullMac from 'assets/images/smartMockupsHomeFullMac.png';
import landingVideoThumbnail from 'assets/images/LandingVideoFrame.png';


const CustomIconButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#731E6BF0',
    transition: '1s',
    '&:hover': {
      backgroundColor: '#731E6B',
      boxShadow: '0 0 10px #731E6BF0',
      transition: '0.5s',
    },
  },
}))(IconButton);


export const LandingVideo = () => {
  const { openDialog: openVideoDialog } = useDialog(DIALOG.VIDEO);

  const { padScreen, smallScreen, tabletScreen, mdScreen } = useResponsive();

  const playVideo = () => {
    openVideoDialog({
      video:
        'https://dev.yourmultipleoffer.com/backend/static/plumbid.mp4',
      title: 'The Future of Real Estate',
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ position: 'relative' }}
    >
      <img
        style={{
          width: padScreen ? '100%' : tabletScreen ? '90%' : '75%',
        }}
        src={landingVideoThumbnail}
        srcSet={`${landingVideoThumbnail} 2x`}
        alt=""
      />
      <CustomIconButton
        className="play-button"
        style={{
          position: 'absolute',
          height: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
          width: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
          left: padScreen ? '45%' : tabletScreen ? '42%' : '45%',
          top: mdScreen ? '42%' : padScreen ? '35%' : '38%',
        }}
        onClick={playVideo}
      >
        <Icons.Play
          style={{
            marginLeft: 6,
            height: 36,
            width: 36,
          }}
        />
      </CustomIconButton>
    </Box>
  );
};


export const HowPlumBidWorksVideo = () => {
  const { openDialog: openVideoDialog } = useDialog(DIALOG.VIDEO);

  const { padScreen, smallScreen, tabletScreen, mdScreen } = useResponsive();

  const playVideo = () => {
    openVideoDialog({
      video:
        'https://www.youtube.com/watch?v=UgPGybD12-0&ab_channel=InfoPlumbid',
      title: 'How plumBid Works',
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ position: 'relative' }}
    >
      <img
        style={{
          width: padScreen ? '100%' : tabletScreen ? '90%' : '75%',
        }}
        src={smartMockupsHomeFullMac}
        srcSet={`${smartMockupsHomeFullMac} 2x`}
        alt=""
      />
      <CustomIconButton
        className="play-button"
        style={{
          position: 'absolute',
          height: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
          width: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
          left: padScreen ? '45%' : tabletScreen ? '42%' : '43.5%',
          top: mdScreen ? '42%' : padScreen ? '35%' : '34.5%',
        }}
        onClick={playVideo}
      >
        <Icons.Play
          style={{
            marginLeft: 6,
            height: 36,
            width: 36,
          }}
        />
      </CustomIconButton>
    </Box>
  );
};
