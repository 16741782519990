import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { removeSession } from 'service/auth';
import { ME_QUERY } from 'service/graphql';
import { useAuthState } from 'service/store';
import { usePageLoading } from './usePageLoading';
import { normalizeUser } from 'utils';

export const useGetMe = fetchPolicy => {
  const [{ me, loggedIn }, dispatchAuth] = useAuthState();

  const { error, data, loading, refetch } = useQuery(ME_QUERY, {
    fetchPolicy: fetchPolicy ?? 'cache-first',
  });
  usePageLoading(loading, 'useGetMe');

  useEffect(() => {
    if (loggedIn) {
      refetch();
    }
  }, [me?.userId, loggedIn, refetch]);

  useEffect(() => {
    if (error) {
      dispatchAuth({
        type: 'logout',
      });
      removeSession();
    } else if (data) {
      dispatchAuth({
        type: 'init',
        payload: normalizeUser(data.me),
      });
    }
  }, [error, data, dispatchAuth]);

  useEffect(() => {
    dispatchAuth({
      type: 'meLoading',
      payload: loading,
    });
  }, [dispatchAuth, loading]);

  return { error, data, loading, fetchMe: refetch };
};
