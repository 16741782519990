const defaults = {
  devGraphqlPath: 'https://dev.yourmultipleoffer.com',
  devWsPath: 'wss://dev.yourmultipleoffer.com',
};

const projectEnv = {
  // Get env params on build
  SASS_PATH: process.env.SASS_PATH,
  GOOGLE_MAP_API_KEY: 'AIzaSyDEn1PLc3xsp4sgjDIDKXWExyumJHqnWig',
  GOOGLE_RECAPTCHA_API_KEY: '6LcUhyIeAAAAAG7dLLaq_hRK9P6675UrOHq5Yein',
  PLUMBID_API_ENDPOINT:
    window.REACT_APP_PLUMBID_API_ENDPOINT === '__REACT_APP_API_ROOT__'
      ? defaults.devGraphqlPath
      : window.REACT_APP_PLUMBID_API_ENDPOINT,
  PLUMBID_API_SUBSCRIPTION_ENDPOINT:
    window.REACT_APP_PLUMBID_API_SUBSCRIPTION_ENDPOINT === '__REACT_APP_API_SUBSCRIPTION_ROOT__'
      ? defaults.devWsPath
      : window.REACT_APP_PLUMBID_API_SUBSCRIPTION_ENDPOINT,
};

export const getEnv = key => {
  if (typeof projectEnv[key] !== 'undefined') {
    return projectEnv[key];
  }

  const location = window.location;

  if (key === 'PLUMBID_API_ENDPOINT') {
    if (location.port === '3000' || location.port === '5000') {
      return defaults.devGraphqlPath;
    }

    return `${location.protocol}//${location.host}`;
  }

  if (key === 'PLUMBID_API_SUBSCRIPTION_ENDPOINT') {
    if (location.port === '3000' || location.port === '5000') {
      return defaults.devWsPath;
    }

    const wsProtocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
    return `${wsProtocol}//${location.host}`;
  }
};
