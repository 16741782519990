import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { Icons, PBox } from 'legos';
import { PropertyThumbnail } from 'components';
import { PlumBidStatus } from './PlumBidStatus';
import { LastBidInfo } from './LastBidInfo';
import { ContingentNotQualified } from './ContingentNotQualified';
import { formatAddressTwoLines } from 'utils/formatters';
import { ControlButtons } from './ControlButtons';
import { theme } from 'utils/theme';
import { PLUMBID_STATUS } from 'constant';

export const ControlSection = ({ plumBid, refetch, disabled }) => {
  const myInfo = plumBid?.myInfoInPlumBid;
  const isFinished = plumBid.status === PLUMBID_STATUS.FINISHED;
  const reservedPriceMet = useMemo(() => plumBid?.reservePriceMet && plumBid?.service?.name === 'fullservice', [
    plumBid?.reservePriceMet,
    plumBid?.service?.name,
  ]);

  const renderPropertyNameAddress = () => {
    const address = formatAddressTwoLines(plumBid.mls || {});

    return plumBid.mls ? (
      <PBox display="flex" alignItems="center" my={1}>
        <Icons.MapMarker />
        <PBox display="flex" flexDirection="column">
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              color: theme.palette.text.primary,
              fontWeight: 800,
              lineHeight: '20px',
            }}
          >
            {address.line1}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginLeft: 8,
              color: theme.palette.text.primary,
              fontWeight: 800,
              lineHeight: '20px',
            }}
          >
            {address.line2}
          </Typography>
        </PBox>
      </PBox>
    ) : null;
  };

  return (
    <PBox p={3.75} width="100%" bgcolor={theme.palette.background.solitude}>
      <PropertyThumbnail aspectRatio={1 / 1} src={plumBid.mls?.previewImage} />
      <PBox pt={2}>
        <PlumBidStatus plumBid={plumBid} refetch={refetch} />
      </PBox>
      <ContingentNotQualified plumBid={plumBid} />
      {renderPropertyNameAddress()}
      {(plumBid?.service?.name === 'multipleoffers' || (reservedPriceMet || !isFinished)) && <LastBidInfo plumBid={plumBid} myInfo={myInfo} />}
      {plumBid?.service?.name === 'fullservice' && isFinished && !reservedPriceMet && (
        <PBox
          py={1}
          my={1.75}
          width="100%"
          display="flex"
          borderRadius="3.25px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.background.charcoal}
        >
          <Typography
            variant="h6"
            style={{
              color: theme.palette.common.white,
              padding: '0 8px',
            }}
          >
            Reserve price not met
          </Typography>
        </PBox>
      )}
      <PBox mt={2}>
        <ControlButtons plumBid={plumBid} disabledButtons={disabled} />
      </PBox>
      {/* TODO Romove this section before release */}
      <PBox pt={2}>
        plumBid Info
        <Typography>Id: {plumBid.id}</Typography>
        <Typography>My Role: {myInfo.role}</Typography>
      </PBox>
    </PBox>
  );
};
