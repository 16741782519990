import { useState } from 'react';

import { Box, Grid, Typography, Collapse } from '@material-ui/core';
import { LinkButton } from 'legos';
import { theme } from 'utils/theme';

export const AboutUsTeam = ({ contactList }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  return (
    <Grid container item spacing={10} xs={11} style={{ padding: '90px 0' }}>
      <Grid item xs={12} md={4}>
        <img style={{ width: '100%', borderRadius: '8px' }} src={contactList.picture} alt="" />
      </Grid>
      <Grid item md={8}>
        <Box pb={2}>
          <Typography variant="h2" style={{ fontSize: 34, fontWeight: 500 }}>
            {contactList.name}
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: 24 }}>
            {contactList.position}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            style={{ fontSize: 19, color: theme.palette.text.transparent, lineHeight: '27px' }}
          >
            {contactList.bio}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: 19,
              color: theme.palette.text.transparent,
              lineHeight: '27px',
              paddingTop: contactList.bioField2 ? 24 : 0,
            }}
          >
            {contactList.bioField2}
          </Typography>
          <Collapse in={checked} disableStrictModeCompat>
            <Typography
              variant="body1"
              style={{ fontSize: 19, color: theme.palette.text.transparent, lineHeight: '27px', paddingTop: 24 }}
            >
              {contactList.moreBioField1}
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: 19, color: theme.palette.text.transparent, lineHeight: '27px', paddingTop: 24 }}
            >
              {contactList.moreBioField2}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 19,
                color: theme.palette.text.transparent,
                lineHeight: '27px',
                paddingTop: contactList.moreBioField3 ? 24 : 0,
              }}
            >
              {contactList.moreBioField3}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 19,
                color: theme.palette.text.transparent,
                lineHeight: '27px',
                paddingTop: contactList.moreBioField4 ? 24 : 0,
              }}
            >
              {contactList.moreBioField4}
            </Typography>
          </Collapse>
          {contactList.moreBioField1 && (
            <LinkButton
              onClick={handleChange}
              style={{
                fontSize: 19,
                fontWeight: 700,
                color: theme.palette.text.transparent,
                marginTop: 24,
              }}
            >
              {checked ? 'Show less' : 'Show more'}
            </LinkButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
