const SANDBOX_KEY = 'sk';
const FRONT_TOKEN = 'front_token';
const FRONT_REFRESH_TOKEN = 'front_refresh_token';
const FRONT_EXPIRE = 'front_expires';
const TUTORIAL_TOGGLE = 'tutorial_toggle';
const OPTIMIZER_STEP = 'tutorial_optimizer';
export class LocalStorage {
  static getSandboxKey = () => localStorage.getItem(SANDBOX_KEY);
  static setSandboxKey = key => localStorage.setItem(SANDBOX_KEY, key);

  static getFrontToken = () => localStorage.getItem(FRONT_TOKEN);
  static setFrontToken = token => localStorage.setItem(FRONT_TOKEN, token);
  static removeFrontToken = token => localStorage.removeItem(FRONT_TOKEN, token);

  static getRefreshToken = () => localStorage.getItem(FRONT_REFRESH_TOKEN);
  static setRefreshToken = token => localStorage.setItem(FRONT_REFRESH_TOKEN, token);
  static removeRefreshToken = () => localStorage.removeItem(FRONT_REFRESH_TOKEN);

  static getExpireToken = () => localStorage.getItem(FRONT_EXPIRE);
  static setExpireToken = token => localStorage.setItem(FRONT_EXPIRE, token);
  static removeExpireToken = () => localStorage.removeItem(FRONT_EXPIRE);

  static getTutorialOptimizerStep = () => {
    const localStorageTutorial = +localStorage.getItem(OPTIMIZER_STEP);
    return Number.isNaN(localStorageTutorial) ? 0 : localStorageTutorial;
  };
  static setTutorialOptimizerStep = value => localStorage.setItem(OPTIMIZER_STEP, value);
  static removeTutorialOptimizerStep = () => localStorage.removeItem(OPTIMIZER_STEP);

  static getTutorialToggle = () => {
    const tutorialToggle = localStorage.getItem(TUTORIAL_TOGGLE);
    return tutorialToggle === 'false' ? false : true;
  };
  static setTutorialToggle = value => localStorage.setItem(TUTORIAL_TOGGLE, value);
  static removeTutorialToggle = () => localStorage.removeItem(TUTORIAL_TOGGLE);
}
