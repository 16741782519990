import { Box, Grid, Typography } from '@material-ui/core';

import { formatAddressTwoLines, formatNumberToUSD } from 'utils/formatters';
import { TextWithLabel } from 'legos';
import { OFFER_PRECISE_FIELDS } from 'constant';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const ReviewOfferContent = ({ plumBid, offerDetails, asOfferCard }) => {
  const responsive = useResponsive();
  const address = formatAddressTwoLines(plumBid.mls || {});

  if (!plumBid || !offerDetails) {
    return null;
  }
  return (
    <Box mt={1.5}>
      <Grid container alignItems="stretch" style={{ border: 'solid 1px #EAE8E4' }}>
        {!asOfferCard && OFFER_PRECISE_FIELDS.some(fieldName => !offerDetails.payload[fieldName]) && (
          <Grid item sm={12}>
            <Box px={4} pt={2}>
              <Typography variant="h5" style={{ color: theme.palette.text.warning }}>
                We didn’t manage to read some of the offer details, please review
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box p={responsive.mobileScreen ? 1 : 4}>
            <Box pb={2}>
              <TextWithLabel label="Property Address" text={address.line1} text2={address.line2} />
            </Box>
            <Box pb={2}>
              <TextWithLabel
                label="Down Payment Amount"
                text={formatNumberToUSD(offerDetails?.payload.cashdownAmount ?? offerDetails?.payload.amountDown, {
                  hidePlusSign: true,
                })}
              />
            </Box>
            <Box pb={2}>
              <Typography variant="h4" align="left" style={{ textTransform: 'uppercase' }}>
                Inspection Contingency
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography variant="subtitle2" align="left">
                  {offerDetails?.payload.inspectionContingency ? 'Yes' : 'No'}
                </Typography>
                {offerDetails?.payload.inspectionContingency && (
                  <Typography variant="subtitle2" align="left" style={{ marginLeft: 8 }}>
                    {offerDetails?.payload.inspectionContingencyLength < 2
                      ? (offerDetails?.payload.inspectionContingencyLength || '') +
                        (offerDetails?.payload.inspectionContingencyLength ? ' day' : '')
                      : (offerDetails?.payload.inspectionContingencyLength || '') +
                        (offerDetails?.payload.inspectionContingencyLength ? ' days' : '')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="h4" align="left" style={{ textTransform: 'uppercase' }}>
                Appraisal Contingency
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography variant="subtitle2" align="left">
                  {offerDetails?.payload.appraisalContingency ? 'Yes' : 'No'}
                </Typography>
                {offerDetails?.payload.appraisalContingency && (
                  <Typography variant="subtitle2" align="left" style={{ marginLeft: 8 }}>
                    {offerDetails?.payload.appraisalContingencyLength < 2
                      ? (offerDetails?.payload.appraisalContingencyLength || '') +
                        (offerDetails?.payload.appraisalContingencyLength ? ' day' : '')
                      : (offerDetails?.payload.appraisalContingencyLength || '') +
                        (offerDetails?.payload.appraisalContingencyLength ? ' days' : '')}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box p={responsive.mobileScreen ? 1 : 4}>
            <Box pb={2}>
              <TextWithLabel
                label="Offer Price"
                text={formatNumberToUSD(offerDetails?.payload.offerPrice || offerDetails?.payload.price, {
                  hidePlusSign: true,
                })}
              />
            </Box>
            <Box pb={2}>
              <TextWithLabel
                label="Escrow Period"
                text={
                  offerDetails?.payload.escrowLength < 2
                    ? (offerDetails?.payload.escrowLength || '0') + ' day'
                    : (offerDetails?.payload.escrowLength || '0') + ' days'
                }
              />
            </Box>
            <Box pb={2}>
              <Typography variant="h4" align="left" style={{ textTransform: 'uppercase' }}>
                Loan Contingency
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography variant="subtitle2" align="left">
                  {offerDetails?.payload.loanContingency ? 'Yes' : 'No'}
                </Typography>
                {offerDetails?.payload.loanContingency && (
                  <Typography variant="subtitle2" align="left" style={{ marginLeft: 8 }}>
                    {offerDetails?.payload.loanContingencyLength < 2
                      ? (offerDetails?.payload.loanContingencyLength || '') +
                        (offerDetails?.payload.loanContingencyLength ? ' day' : '')
                      : (offerDetails?.payload.loanContingencyLength || '') +
                        (offerDetails?.payload.loanContingencyLength ? ' days' : '')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="h4" align="left" style={{ textTransform: 'uppercase' }}>
                Offer Lease Back
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography variant="subtitle2" align="left">
                  {offerDetails?.payload.offerLeaseBack ? 'Yes' : 'No'}
                </Typography>
                {offerDetails?.payload.offerLeaseBack && (
                  <>
                    <Typography variant="subtitle2" align="left" style={{ marginLeft: 8 }}>
                      {offerDetails?.payload.offerLeaseBackLength < 2
                        ? (offerDetails?.payload.offerLeaseBackLength || '') +
                          (offerDetails?.payload.offerLeaseBackLength ? ' day' : '')
                        : (offerDetails?.payload.offerLeaseBackLength || '') +
                          (offerDetails?.payload.offerLeaseBackLength ? ' days' : '')}
                    </Typography>
                    <Typography variant="subtitle2" align="left" style={{ marginLeft: 8 }}>
                      {offerDetails?.payload.offerLeaseBackPricePerDay > 0
                        ? formatNumberToUSD(offerDetails?.payload.offerLeaseBackPricePerDay, {
                            hidePlusSign: true,
                          }) + ' /Per Day'
                        : ''}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
