import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { DIALOG, ROLES } from 'constant';
import { useDialog } from './useDialog';
import state from 'service/graphql/state';
import { useAuthState } from 'service/store';

export const useGlobalPopup = () => {
  const [{ me }] = useAuthState();
  const isAdmin = me?.roles?.includes(ROLES.ADMIN);
  const globalPlumBidPopup = useReactiveVar(state.globalPlumBidPopupVar);
  const isRunningAtomicOperation = useReactiveVar(state.isRunningAtomicOperationVar);
  const { closeDialog: closeReviewAndBidDialog } = useDialog(DIALOG.REVIEW_AND_BID);

  const { openDialog: openNewBidReceivedDialog, isOpenDialog: isOpenNewBidReceivedDialog } = useDialog(
    DIALOG.GP_NEW_BID_RECEIVED_STARTED
  );
  const { openDialog: openPlumBidJustStartedDialog, isOpenDialog: isOpenPlumBidJustStartedDialog } = useDialog(
    DIALOG.GP_PLUMBID_JUST_STARTED
  );
  const { openDialog: openBackUpPositionDialog, isOpenDialog: isOpenBackUpPositionDialog } = useDialog(
    DIALOG.GP_BACKUP_POSITION
  );
  const { openDialog: openFinishDataHasChangedDialog, isOpenDialog: isOpenFinishDataHasChangedDialog } = useDialog(
    DIALOG.GP_FINISH_DATA_HAS_CHANGED
  );

  const { openDialog: openReservePriceMetChangedDialog, isOpenDialog: isOpenReservePriceMetChangedDialog } = useDialog(
    DIALOG.GP_RESERVE_PRICE_MET_CHANGED
  );

  useEffect(() => {
    if (
      (!isRunningAtomicOperation &&
        !isOpenBackUpPositionDialog &&
        !isOpenNewBidReceivedDialog &&
        !isOpenPlumBidJustStartedDialog &&
        !isOpenFinishDataHasChangedDialog &&
        !isAdmin) ||
      (!isRunningAtomicOperation && !isOpenReservePriceMetChangedDialog)
    ) {
      let justOneDialogToShow = false;
      Object.keys(globalPlumBidPopup).forEach(plumBidId => {
        const plumBidPopup = globalPlumBidPopup[plumBidId];
        // Dialog plumBid just started
        if (
          !justOneDialogToShow &&
          plumBidPopup.popupPlumBidJustStartedNeedToShow &&
          !plumBidPopup.popupPlumBidJustStartedShowed
        ) {
          justOneDialogToShow = true;
          const globalPlumBidPopupLocal = state.globalPlumBidPopupVar();
          state.globalPlumBidPopupVar({
            ...globalPlumBidPopupLocal,
            [plumBidId]: {
              ...globalPlumBidPopupLocal[plumBidId],
              popupPlumBidJustStartedNeedToShow: false,
              popupPlumBidJustStartedShowed: true,
            },
          });

          openPlumBidJustStartedDialog({ plumBidId, participantId: plumBidPopup.participantId });
        }

        // Dialog new bid received
        if (
          !justOneDialogToShow &&
          plumBidPopup.popupNewBidReceivedNeedToShow &&
          !plumBidPopup.popupNewBidReceivedShowed
        ) {
          justOneDialogToShow = true;
          const globalPlumBidPopupLocal = state.globalPlumBidPopupVar();
          state.globalPlumBidPopupVar({
            ...globalPlumBidPopupLocal,
            [plumBidId]: {
              ...globalPlumBidPopupLocal[plumBidId],
              popupNewBidReceivedNeedToShow: false,
              popupNewBidReceivedShowed: true,
            },
          });
          closeReviewAndBidDialog();
          openNewBidReceivedDialog({
            plumBidId,
            isMyPartyBidWasHighest: plumBidPopup.myPartyBidWasHighest,
          });
        }

        // Dialog choose backup position
        if (
          !justOneDialogToShow &&
          plumBidPopup.popupBackupPositionNeedToShow &&
          !plumBidPopup.popupBackupPositionShowed
        ) {
          justOneDialogToShow = true;
          const globalPlumBidPopupLocal = state.globalPlumBidPopupVar();
          state.globalPlumBidPopupVar({
            ...globalPlumBidPopupLocal,
            [plumBidId]: {
              ...globalPlumBidPopupLocal[plumBidId],
              popupBackupPositionNeedToShow: false,
              popupBackupPositionShowed: true,
            },
          });
          openBackUpPositionDialog({
            plumBidId,
          });
        }

        // Dialog finish plumBid data has changed
        if (
          !justOneDialogToShow &&
          plumBidPopup.popupFinishDateHasChangedNeedToShow &&
          !plumBidPopup.popupFinishDateHasChangedShowed
        ) {
          justOneDialogToShow = true;
          const globalPlumBidPopupLocal = state.globalPlumBidPopupVar();
          state.globalPlumBidPopupVar({
            ...globalPlumBidPopupLocal,
            [plumBidId]: {
              ...globalPlumBidPopupLocal[plumBidId],
              popupFinishDateHasChangedNeedToShow: false,
              popupFinishDateHasChangedShowed: true,
            },
          });
          openFinishDataHasChangedDialog({
            plumBidId,
          });
        }

        // Dialog reserve price met changed
        if (
          !justOneDialogToShow &&
          plumBidPopup.popupReservePriceMetChangedNeedToShow &&
          !plumBidPopup.popupReservePriceMetChangedShowed
        ) {
          justOneDialogToShow = true;
          const globalPlumBidPopupLocal = state.globalPlumBidPopupVar();
          state.globalPlumBidPopupVar({
            ...globalPlumBidPopupLocal,
            [plumBidId]: {
              ...globalPlumBidPopupLocal[plumBidId],
              popupReservePriceMetChangedNeedToShow: false,
              popupReservePriceMetChangedShowed: true,
            },
          });
          openReservePriceMetChangedDialog({
            plumBidId,
          });
        }
      });
    }
  }, [
    globalPlumBidPopup,
    isRunningAtomicOperation,
    openNewBidReceivedDialog,
    openBackUpPositionDialog,
    isOpenNewBidReceivedDialog,
    isOpenBackUpPositionDialog,
    openPlumBidJustStartedDialog,
    isOpenPlumBidJustStartedDialog,
    closeReviewAndBidDialog,
    isOpenFinishDataHasChangedDialog,
    openFinishDataHasChangedDialog,
    isOpenReservePriceMetChangedDialog,
    openReservePriceMetChangedDialog,
    isAdmin,
  ]);
};
