import gql from 'graphql-tag';

export const CREATE_BID_REST = gql`
  mutation CreateBid_REST($input: CreateBidInput!) {
    createBid(input: $input) @rest(path: "plum-bid/{args.input.plumbidId}/bid/", method: "POST") {
      success
      errors
    }
  }
`;
