import React from 'react';

import { LinearProgress, Typography } from '@material-ui/core';
import { PBox } from 'legos/PBox';

export const ProcessingBar = ({ processingInfo, countUploadOffer }) => {
  return (
    <PBox py={1} display="flex" flexDirection="column">
      <Typography variant="h5">
        {processingInfo.isRunProcessing || countUploadOffer
          ? `Currently processing ${countUploadOffer} offer${processingInfo.totalFiles > 1 ? 's' : ''}...
        `
          : processingInfo.totalFiles < 1
          ? 'Upload Offer'
          : 'Processing is complete, please review your offers'}
      </Typography>
      <PBox pt={1.25}>
        <LinearProgress
          variant={processingInfo.isRunProcessing ? 'indeterminate' : 'determinate'}
          value={100}
          style={{ height: 3 }}
        />
      </PBox>
    </PBox>
  );
};
