import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, LinearProgress, Box } from '@material-ui/core';

import { NavigationButton, Divider } from 'legos';

import { PropertyThumbnailNameAddress } from 'components';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const QuestionSubHeader = ({ plumbid, mismatch, progress }) => {
  const history = useHistory();
  const responsive = useResponsive();

  return (
    <Box
      style={{
        margin: '0 auto',
        width: '100%',
        top: 60,
        right: 0,
        left: 'auto',
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 100,
      }}
    >
      <Box
        pt={1}
        pb={1}
        px={responsive.mobileScreen ? 2 : 6}
        style={{
          boxSizing: 'border-box',
          maxWidth: 1440,
          margin: '0 auto',
        }}
      >
        <Grid container alignItems="center" justify="flex-start">
          <Grid item container alignItems="center" xs={12} lg={5}>
            <NavigationButton
              fullWidth={false}
              onClick={() => history.goBack()}
              icon="LeftArrow"
              color={theme.palette.common.white}
              style={{
                backgroundColor: theme.palette.purple.light,
                marginRight: 38,
              }}
            />
            <Box pr={1}>
              <PropertyThumbnailNameAddress property={plumbid?.mls} />
            </Box>
          </Grid>

          <Grid container alignItems="center" justify="flex-start" item xs={responsive.mobileScreen ? 12 : true}>
            <Grid item xs={responsive.mobileScreen ? 12 : 9}>
              <Box mt={responsive.mobileScreen ? 2 : 0}>
                <Typography
                  variant={responsive.mobileScreen ? 'h4' : 'h3'}
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 700,
                  }}
                >
                  Purchase Offer Review
                </Typography>
                {mismatch ? (
                  <Typography
                    variant="overline"
                    component="div"
                    style={{ color: theme.palette.text.warning, textTransform: 'none', height: 16 }}
                  >
                    There is a mismatch in answers of Buyers, please agree on the same options
                  </Typography>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={responsive.mobileScreen ? 12 : 3} id="tutorial__ProgressOnboarding">
              <Typography variant="subtitle1">{`Progress: ${Math.ceil(progress)}%`}</Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{
                  height: 8,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </Box>
  );
};
