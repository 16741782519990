import gql from 'graphql-tag';

export const CHANGE_SELLERS_ANSWERS_MUTATION = gql`
  mutation ChangeSellerAnswers($input: ChangeSellerAnswersInput!) {
    changeSellerAnswers(input: $input) {
      success
      errors
    }
  }
`;
