import { useLocation } from 'react-router-dom';

import { AdminContent } from './AdminContent';
import { ADMIN_PLUMBID, ADMIN_USERS, ADMIN_DASHBOARD, ADMIN_CHATS, ADMIN_SANDBOX, SETTINGS } from 'routes';

export const HeaderContent = handleClose => {
  const { pathname } = useLocation();

  switch (pathname) {
    case ADMIN_DASHBOARD:
    case ADMIN_PLUMBID:
    case ADMIN_USERS:
    case ADMIN_CHATS:
    case ADMIN_SANDBOX:
      return <AdminContent />;

    case SETTINGS:
      return null;
    default:
      return null;
  }
};
