import React from 'react';
import { shape } from 'prop-types';

export const Star = ({ style }) => (
  <svg
    width="44"
    height="43"
    viewBox="0 0 44 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5196 18.0984H8L21.7705 22.6886L18.5196 18.0984Z"
        fill="#FEF200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2295 22.6885L30.9508 34.623L27.6196 24.4292L22.2295 22.6885Z"
        fill="#F1C409"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5078 34.623L22.2291 28.3227V22.6885L13.5078 34.623Z"
        fill="#F1C409"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 18.0984H25.4802L22.2295 22.6886L36 18.0984Z"
        fill="#F1C409"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2295 28.3227L30.9508 34.623L22.2295 22.6885V28.3227Z"
        fill="#FEF200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2291 22.6885L16.8393 24.4292L13.5078 34.623L22.2291 22.6885Z"
        fill="#FEF200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5576 18.1492L21.7707 22.6885V8L18.5576 18.1492Z"
        fill="#F1C409"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2295 8V22.6885L25.4426 18.1492L22.2295 8Z"
        fill="#FEF200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7705 22.7489L8 18.0984L16.5106 24.5246L21.7705 22.7489Z"
        fill="#F1C409"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2295 22.7489L27.4891 24.5246L36 18.0984L22.2295 22.7489Z"
        fill="#FEF200"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="44"
        height="42.623"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.086283 0 0 0 0 0.086283 0 0 0 0 0.086283 0 0 0 0.175894 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

Star.propTypes = {
  style: shape({}),
};

Star.defaultProps = {
  style: null,
};
