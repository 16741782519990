import { TableRow } from '@material-ui/core';

import { Icons, PBox } from 'legos';
import { ROLES } from 'constant';
import { useAuthState } from 'service/store';
import { formatNumberToUSD, formatUserFullName } from 'utils';
import { getInitials } from 'utils/formatters';
import { theme } from 'utils/theme';
import { StyledTypography } from './styled';
import { TableCell } from '../HistoryTableCell';

const getDayOrDays = value => (value > 1 ? 'days' : 'day');

const columns = [
  {
    width: '12%',
    formatter: (bid, hideBidInfo) => (hideBidInfo ? '' : formatNumberToUSD(bid.price, { hidePlusSign: true })),
  },
  {
    width: '10%',
    formatter: (bid, hideBidInfo) => (hideBidInfo ? '' : bid.partyName),
  },
  {
    width: '8%',
    formatter: (bid, hideBidInfo) => (hideBidInfo ? '' : formatNumberToUSD(bid.amountDown, { hidePlusSign: true })),
  },
  {
    width: '7%',
    formatter: (bid, hideBidInfo) => (hideBidInfo ? '' : `${bid.escrowLength} ${getDayOrDays(bid.escrowLength)}`),
  },
  {
    width: '7%',
    formatter: (bid, hideBidInfo) =>
      hideBidInfo
        ? ''
        : bid.inspectionContingency
        ? `${bid.inspectionContingencyLength} ${getDayOrDays(bid.inspectionContingencyLength)}`
        : 'No',
  },
  {
    width: '7%',
    formatter: (bid, hideBidInfo) =>
      hideBidInfo
        ? ''
        : bid.loanContingency
        ? `${bid.loanContingencyLength} ${getDayOrDays(bid.loanContingencyLength)}`
        : 'No',
  },
  {
    width: '9%',
    formatter: (bid, hideBidInfo) =>
      hideBidInfo
        ? ''
        : bid.appraisalContingency
        ? `${bid.appraisalContingencyLength} ${getDayOrDays(bid.appraisalContingencyLength)}`
        : 'No',
  },
  {
    width: '8%',
    formatter: (bid, hideBidInfo) => (hideBidInfo ? '' : bid.offerLeaseBack ? 'Yes' : 'No'),
  },
];

export const HistoryTableBody = ({ bid, myRole, isInitBid, myPartyId, sandboxKey, isSandbox }) => {
  const [{ me }] = useAuthState();
  const isMyBid = isSandbox
    ? sandboxKey === bid.owner.user.key
    : myRole === ROLES.BUYER && bid.owner?.user?.id === me?.userId;
  const hideBidInfo = (myRole === ROLES.BUYER || myRole === ROLES.BUYER_AGENT) && bid.partyId !== +myPartyId;

  return (
    <TableRow key={bid.id}>
      <TableCell width="12%">
        <StyledTypography variant="h6">
          {hideBidInfo ? getInitials(formatUserFullName(bid.owner.user)) : formatUserFullName(bid.owner.user)}
        </StyledTypography>
      </TableCell>
      <TableCell width="20%" component="th" scope="row">
        <PBox display="flex" alignItems="center" justifyContent="flex-start">
          <PBox width={35}>
            <Icons.SquareBid
              style={{
                fill:
                  isMyBid || (myRole === ROLES.BUYER_AGENT && !hideBidInfo)
                    ? theme.palette.primary.main
                    : theme.palette.text.gainsboro,
              }}
            />
          </PBox>
          <PBox width="100%" ml={0.5}>
            <PBox display="flex" alignItems="center">
              <StyledTypography variant="h6">
                {formatNumberToUSD(bid.plumBidAmount, { hidePlusSign: true })}
              </StyledTypography>
              {isInitBid && (
                <PBox
                  bgcolor={theme.palette.text.nobel}
                  borderRadius="2px"
                  px={0.5}
                  display="flex"
                  alignItems="center"
                  ml={1}
                >
                  <StyledTypography variant="subtitle2" type="initial">
                    initial bid
                  </StyledTypography>
                </PBox>
              )}
            </PBox>
            <StyledTypography $color={theme.palette.text.nobel}>{bid.formatDate}</StyledTypography>
          </PBox>
        </PBox>
      </TableCell>
      {columns.map((col, index) => (
        <TableCell key={`${index}${col.width}`} width={col.width}>
          <StyledTypography variant="h6">{col.formatter(bid, hideBidInfo)}</StyledTypography>
        </TableCell>
      ))}
    </TableRow>
  );
};
