import { Box, ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

import { Icons } from 'legos';
import { theme } from 'utils';
import { DIALOG } from 'constant';
import { useDialog } from 'utils/hooks';

const Button = styled(ButtonBase)`
  width: 100%;
  height: 70px;
  border: 1px solid white;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  ${({ imageurl }) => `background-image: url("${imageurl}");`}
  outline: 1px solid ${({ disabled }) => (disabled ? theme.palette.disabled : 'black')};
`;

export const PreviewImageButton = ({ imageurl }) => {
  const { openDialog } = useDialog(DIALOG.PREVIEW_IMAGE);

  const disabled = !imageurl;

  return (
    <Button
      disabled={disabled}
      imageurl={imageurl}
      onClick={() =>
        openDialog({
          url: imageurl,
        })
      }
    >
      <Box
        right={0}
        bottom={0}
        width="34px"
        height="34px"
        display="flex"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        bgcolor={theme.palette.buttonBackground}
      >
        <Icons.Magnifier style={{ fill: 'white' }} />
      </Box>
    </Button>
  );
};
