import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PBox } from 'legos/PBox';
import { theme } from 'utils';
import { useResponsive } from 'utils/hooks';

export const PlumBidSkeleton = () => {
  const { tabletScreen } = useResponsive();
  return (
    <PBox mb={3} position="relative">
      <Grid container spacing={tabletScreen ? 1 : 3}>
        <Grid item container xs={12} lg={3}>
          <PBox px={3.75} width="100%" bgcolor={theme.palette.background.whiteSmoke2}>
            <Skeleton
              variant="rect"
              animation="wave"
              height="240px"
              width="100%"
              style={{ background: theme.palette.text.gainsboro, margin: '19px 0' }}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height="25px"
              width="76px"
              style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height="25px"
              width="100%"
              style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height="25px"
              width="100%"
              style={{ background: theme.palette.text.gainsboro, marginBottom: 70 }}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height="50px"
              width="100%"
              style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height="50px"
              width="100%"
              style={{ background: theme.palette.text.gainsboro, marginBottom: 19 }}
            />
          </PBox>
        </Grid>

        <Grid item container xs={12} lg>
          <PBox bgcolor={theme.palette.background.whiteSmoke2} width="100%" px={3.75}>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="57%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="17%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="23%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 8 }}
              />
            </PBox>
            <PBox display="flex" my={4.7}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="55.5%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="24px"
                width="40%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox display="flex" mt={2.35} mb={7}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="15px"
                width="30%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
            <PBox my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="15px"
                width="30%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
            <PBox my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="15px"
                width="30%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
            <PBox my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="15px"
                width="30%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
            <PBox my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro, marginBottom: 8 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="15px"
                width="30%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
          </PBox>
        </Grid>
        <Grid item container xs={12} lg>
          <PBox bgcolor={theme.palette.background.whiteSmoke2} width="100%" px={3.75}>
            <PBox display="flex" mt={2.35} mb={4.7}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="57%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="17%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="23%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 8 }}
              />
            </PBox>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
            <PBox display="flex" my={2.35}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="60%"
                style={{ background: theme.palette.text.gainsboro }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height="25px"
                width="20%"
                style={{ background: theme.palette.text.gainsboro, marginLeft: 16 }}
              />
            </PBox>
          </PBox>
        </Grid>
      </Grid>
    </PBox>
  );
};
