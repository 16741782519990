import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { UserTable, PageContainer } from 'components';

export const AdminUsersPage = () => {
  return (
    <PageContainer>
      <Box px={6}>
        <Box py={5}>
          <Typography
            align="left"
            variant="h1"
            style={{ textTransform: 'uppercase' }}
          >
            Users
          </Typography>
        </Box>
        <UserTable />
      </Box>
    </PageContainer>
  );
};
