import React from 'react';

import { PageContainer, PrivacyPolicy } from 'components';

export const PrivacyPolicyPage = () => {
  return (
    <PageContainer disableLoading>
      <PrivacyPolicy />
    </PageContainer>
  );
};
