import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip as MaterialTooltip, ClickAwayListener } from '@material-ui/core/';

import { PBox } from './PBox';

const LightTooltip = withStyles(theme => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: 'black',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '21px',
  },
}))(MaterialTooltip);

export const Tooltip = ({ placement, text, style, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleTooltipToggle = useCallback(e => {
    setIsOpen(true);
    e.preventDefault();
  }, []);

  const handleMouseMoveTooltip = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <PBox
        style={style}
        zIndex={50}
        onClick={handleTooltipToggle}
        onMouseMove={handleMouseMoveTooltip}
        onMouseLeave={handleTooltipClose}
      >
        <LightTooltip
          placement={placement}
          title={text}
          arrow
          open={isOpen}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
        >
          <PBox p={1} borderRadius="50%" display="flex">
            {children}
          </PBox>
        </LightTooltip>
      </PBox>
    </ClickAwayListener>
  );
};
