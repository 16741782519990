import { Box } from '@material-ui/core';
import { ReactComponent as PlaceholderHouse } from 'assets/images/PlaceholderHouse.svg';
import { ReactComponent as PlaceholderMapPin } from 'assets/images/PlaceholderMapPin.svg';
import { ReactComponent as PlaceholderBigHouse } from 'assets/images/PlaceholderBigHouse.svg';
import { theme } from 'utils/theme';

export const WizardPropertyPlaceholder = () => {
  return (
    <>
      <Box
        width="100%"
        m={0.5}
        height={85}
        bgcolor={theme.palette.background.whiteSmoke2}
        display="flex"
        alignItems="center"
      >
        <Box
          width={77}
          height={55}
          ml={4}
          bgcolor={theme.palette.text.gainsboro}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PlaceholderHouse />
        </Box>
        <Box width="60%" ml={3}>
          <Box height={18} width="100%" display="flex" alignItems="center">
            <PlaceholderMapPin />
            <Box width="70%" height={6} ml={1} bgcolor={theme.palette.text.gainsboro} />
            <Box width="15%" height={6} ml={1} bgcolor={theme.palette.background.paper} />
            <Box width="15%" height={6} ml={1} bgcolor={theme.palette.background.paper} />
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex">
        <Box width="20%" ml={0.5} height={340} bgcolor={theme.palette.background.whiteSmoke2} />
        <Box
          width="60%"
          ml={0.5}
          height={340}
          bgcolor={theme.palette.background.whiteSmoke2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PlaceholderBigHouse />
        </Box>
        <Box width="20%" marginX={0.5} height={340} bgcolor={theme.palette.background.whiteSmoke2} />
      </Box>
      <Box width="100%" m={0.5} pt={5} pl={4} height={220} bgcolor={theme.palette.background.whiteSmoke2}>
        <Box width="50%" height={6} mt={3} bgcolor={theme.palette.text.gainsboro} borderRadius={2} />
        <Box width="35%" height={6} mt={3} bgcolor={theme.palette.text.gainsboro} borderRadius={2} />
        <Box width="35%" height={6} mt={3} bgcolor={theme.palette.text.gainsboro} borderRadius={2} />
        <Box width="35%" height={6} mt={3} bgcolor={theme.palette.text.gainsboro} borderRadius={2} />
      </Box>
    </>
  );
};
