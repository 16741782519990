import React from 'react';
import Image from 'material-ui-image';
import { theme } from 'utils/theme';

export const PropertyThumbnail = ({ src, aspectRatio = 9 / 6, isLocalPath, style }) => {
  const path = isLocalPath ? (src ? URL.createObjectURL(src) : '/no_photo.png') : src ? src : '/no_photo.png';

  return (
    <Image
      cover
      aspectRatio={aspectRatio}
      src={path}
      alt="Object preview"
      style={{
        ...(!src ? { border: `1px solid  ${theme.palette.divider}` } : null),
        ...style,
      }}
    />
  );
};
