import { useMutation } from '@apollo/client';

import { MARK_ADMIN_CHAT_READ_MUTATION } from 'service/graphql';

export const useMarkChatAsRead = () => {
  const [markChatAsRead] = useMutation(MARK_ADMIN_CHAT_READ_MUTATION);

  return {
    markChatAsRead,
  };
};
