import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  GET_DASHBOARD_ADMIN_QUERY,
  ON_NEW_LISTING_AGENT_STATUS_SUBSCRIPTION,
  ON_NEW_PLUMBID_FULL_EVENT_SUBSCRIPTION,
} from 'service/graphql';

import { useNotification } from './useNotification';
import { LISTING_AGENT_VERIFY_STATUS, PLUMBID_STATUS } from 'constant';

export const useDashboardAdmin = variables => {
  const { data, loading, refetch, subscribeToMore } = useQuery(GET_DASHBOARD_ADMIN_QUERY, {
    variables,
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribeOnNewListingAgent = subscribeToMore({
        document: ON_NEW_LISTING_AGENT_STATUS_SUBSCRIPTION,
        variables: { room: 'listing_agent' },
        updateQuery: (prev, { subscriptionData }) => {
          const newOrUpdateListingAgent = subscriptionData?.data?.onNewListingAgentStatus?.listingAgent;
          if (!newOrUpdateListingAgent) {
            return prev;
          }

          const index = prev.adminDashboard.joinedListingAgents.findIndex(
            ({ userId }) => userId === newOrUpdateListingAgent.userId
          );

          if (index > -1) {
            const tempJoinedListingAgents = [...prev.adminDashboard.joinedListingAgents];

            if (
              newOrUpdateListingAgent.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.VERIFIED ||
              newOrUpdateListingAgent.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.REJECTED
            ) {
              tempJoinedListingAgents.splice(index, 1);
            } else {
              tempJoinedListingAgents[index] = newOrUpdateListingAgent;
            }
            return {
              ...prev,
              adminDashboard: {
                ...prev.adminDashboard,
                joinedListingAgents: tempJoinedListingAgents,
              },
            };
          } else if (
            newOrUpdateListingAgent.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.NEW ||
            newOrUpdateListingAgent.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.UNVERIFIED
          ) {
            return {
              ...prev,
              adminDashboard: {
                ...prev.adminDashboard,
                joinedListingAgents: [...prev.adminDashboard.joinedListingAgents, newOrUpdateListingAgent],
              },
            };
          } else {
            return prev;
          }
        },
      });
      const unsubscribeOnNewPlumBid = subscribeToMore({
        document: ON_NEW_PLUMBID_FULL_EVENT_SUBSCRIPTION,
        variables: { room: 'plumbid_events' },
        updateQuery: (prev, { subscriptionData }) => {
          const newPlumBid = subscriptionData?.data?.onNewPlumBidEvent?.plumbid;
          if (!newPlumBid || !prev?.adminDashboard) {
            return prev;
          }

          const index = prev.adminDashboard.livePlumbids.findIndex(({ id }) => newPlumBid.id === id);

          if (newPlumBid.status === PLUMBID_STATUS.LIVE && index === -1) {
            let copyLivePlumBids = [...prev.adminDashboard.livePlumbids];
            copyLivePlumBids.push(newPlumBid);

            return {
              ...prev,
              adminDashboard: {
                ...prev.adminDashboard,
                livePlumbids: copyLivePlumBids,
              },
            };
          }

          if (newPlumBid.status !== PLUMBID_STATUS.LIVE && index > -1) {
            let copyLivePlumBids = [...prev.adminDashboard.livePlumbids];
            copyLivePlumBids.splice(index, 1);

            return {
              ...prev,
              adminDashboard: {
                ...prev.adminDashboard,
                livePlumbids: copyLivePlumBids,
              },
            };
          }

          return prev;
        },
      });

      return () => {
        unsubscribeOnNewListingAgent();
        unsubscribeOnNewPlumBid();
      };
    }
  }, [subscribeToMore]);

  const showNotification = useNotification();

  const livePlumBids = useMemo(() => data?.adminDashboard?.livePlumbids || [], [data?.adminDashboard?.livePlumbids]);
  const newPlumbids = useMemo(() => data?.adminDashboard?.newPlumbids || [], [data?.adminDashboard?.newPlumbids]);
  const joinedListingAgents = useMemo(
    () => data?.adminDashboard?.joinedListingAgents.filter(agent => !agent.isVerifiedAgent) || [],
    [data?.adminDashboard?.joinedListingAgents]
  );

  const statisticCount = useMemo(
    () => ({
      completePlumbidsCount: data?.adminDashboard?.completePlumbidsCount || '0',
      usersJoined: data?.adminDashboard?.usersJoined || '0',
      newPlumbidsCount: data?.adminDashboard?.newPlumbidsCount || '0',
    }),
    [
      data?.adminDashboard?.completePlumbidsCount,
      data?.adminDashboard?.usersJoined,
      data?.adminDashboard?.newPlumbidsCount,
    ]
  );
  return {
    newPlumbids,
    livePlumBids,
    joinedListingAgents,
    statisticCount,
    dashboardAdminLoading: loading,
    refetchDashboardAdminData: refetch,
  };
};
