import { useEffect } from 'react';

import { useAuthState } from 'service/store/authStore';
import { apolloClient, ON_NEW_USER_ME_EVENT_SUBSCRIPTION } from 'service/graphql';

export const useGetMeSubscription = () => {
  const [{ me }] = useAuthState();

  useEffect(() => {
    if (me?.userId) {
      const observer = apolloClient.subscribe({
        query: ON_NEW_USER_ME_EVENT_SUBSCRIPTION,
        variables: { room: 'user_me_events' },
      });

      const subscription = observer.subscribe();
      return () => subscription.unsubscribe();
    }
  }, [me?.userId]);
};
