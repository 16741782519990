import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { PlumBidTableContainer, PageContainer } from 'components';

export const AdminPlumBidPage = () => {
  return (
    <PageContainer>
      <Box px={6}>
        <Box py={5}>
          <Typography align="left" variant="h1" style={{ textTransform: 'uppercase' }}>
            PLUMBIDS
          </Typography>
        </Box>
        <PlumBidTableContainer />
      </Box>
    </PageContainer>
  );
};
