import { useEffect } from 'react';
import { PBox } from 'legos';
import { Footer } from 'components';
import { PRIVACY_POLICY } from 'routes';
import { useSettingsState } from 'service/store';
import { useLocation } from 'react-router-dom';

export const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  const [, dispatchSettings] = useSettingsState();

  useEffect(() => {
    if (pathname === PRIVACY_POLICY) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
    }
  }, [dispatchSettings, pathname]);

  return (
    <>
      <PBox display="flex" justifyContent="center">
        <PBox maxWidth="800px" m={2}>
          <h2 align="center">plumBid Privacy Policy</h2>
          <p>
            This Privacy Policy describes how plumBid Inc. (“plumBid”, “we”, or “us”) collect, use, store, transfer, and
            otherwise process personal information in the course of operating our business, including online through{' '}
            <a target="_blank" href="https://www.plumbid.com/" rel="noreferrer">
              https://www.plumbid.com/
            </a>{' '}
            and all other websites and applications that link to this Privacy Policy (the “Service”).
          </p>
          <div>
            <p>
              1. <b>PERSONAL INFORMATION WE COLLECT</b>
            </p>
            <p>
              When you visit our website, contact us, submit, input, and/or bid on any property or otherwise use the
              Service we may collect your personal information. We may collect the following information from and about
              you:
            </p>

            <p>
              Personal identifiers and contact details (e.g., name, email address, phone number, password, Social
              Security number, or other similar identifiers);
            </p>

            <p>Demographic information (e.g., your age and gender);</p>

            <p>
              Financial information (e.g., credit history, a range of your credit scores, income levels, sources of
              income, credit or debit card information, and banking and other financial information);
            </p>

            <p>Information about your home (e.g., address, property disclosures, etc.);</p>

            <p>
              Internet or other electronic network activity information, such as the data we receive when you interact
              with the Service (e.g., referring website from which you accessed the Service, information about your use
              of and interactions with the Service, including your searches on our platform, and information about bids
              you place, including information such as bid amount, timing of bid, and changes to bids);
            </p>

            <p>
              Information about the device you use to access the Service (e.g., operating system type, browser
              information, IP address);
            </p>

            <p>
              Professional or employment information (e.g., your employer, job title, and professional licenses or
              certifications);
            </p>

            <p>
              Additional information (e.g., information you provide regarding your home rental or ownership history,
              information you provide regarding your criminal history or information provided via a background or credit
              history check); and
            </p>

            <p>Inferences we may draw from the information we collect.</p>
          </div>
          <p>
            We may combine the information we collect from you with information that we receive about you from other
            sources.
          </p>
          <div>
            <p>
              2. <b>HOW WE USE PERSONAL INFORMATION</b>
            </p>
            <p>We may use your personal information for the following purposes:</p>
            <p>
              <b>Service improvements</b>: We analyze usage information, including website analytics, to continually
              improve the user experience and to improve our Service, including the algorithms we use to provide the
              Service.
            </p>
            <p>
              <b>Communications</b>: We may use your personal information to communicate with you. Communications may
              include providing information about changes to the terms and conditions, updates regarding our services,
              or responses to questions you pose.
            </p>
            <p>
              <b>Marketing</b>: We may use your personal information to market to you (e.g., via email, phone, and
              social media), build a profile about you, to understand your preferences, and to help determine which
              marketing materials (for example, region- or sector- focused) would be of interest to you. For example, we
              may use your personal information to recommend other properties that may be of interest to you. Where
              required by law, we will obtain your consent before sending such marketing materials.
            </p>
            <p>
              <b>Exercising our rights</b>: We may use your personal information to exercise our legal rights where it
              is necessary to do so, for example to detect, prevent, and respond to intellectual property infringement
              claims or violations of law.
            </p>
            <p>
              <b>Complying with our obligations</b>: We may process your personal information to carry out fraud
              prevention checks, implement measures designed to secure our Service, or comply with other legal or
              regulatory requirements, when required by law.
            </p>
            <p>
              <b>Customizing your experience</b>: We may use your personal information to improve your experience of the
              Service, such as by providing interactive or personalized elements on the Service and providing you with
              content based on your interests.
            </p>
            <p>
              <b>Identification and authentication</b>: We use your identification information to verify your identity
              when you access and use our Service, or otherwise engage with us, and to implement measures designed to
              safeguard your personal information. This may include conducting credit or background checks, checking
              references you provide, and validating information such as income and employment history.
            </p>
            <p>
              We process your personal information to provide the Service. For example, we may process your personal
              information to maintain your account with us or if you use our Service to submit or bid on a property
              facilitate the purchase or sale of a home. We may also use personal information to make decisions
              regarding bids and provide a platform for our users to interact with us. We may also use your personal
              information to provide you with additional information about the homebuying process, such as
              recommendations for escrow companies.
            </p>
            <p>
              We may also anonymize your personal information in such a way that you may not reasonably be re-identified
              by us or any other company, and may use this anonymized information for any other purpose.
            </p>
          </div>
          <div>
            <p>
              3. <b>HOW AND WHEN WE DISCLOSE PERSONAL INFORMATION</b>
            </p>
            <p>We may disclose the information we collect from you through the Service as follows:</p>

            <p>
              to third parties that provide services to us in connection with our business operations and that have
              agreed to keep the information confidential. For example, we may partner with companies to optimize the
              Service, support email and messaging services, and analyze information obtained through the Service;
            </p>

            <p>
              to our business partners, such as providers of escrow or mortgage services, who may use the information we
              provide subject to their own privacy policies;
            </p>

            <p>
              as required by law, such as to comply with a subpoena or other legal process, or to comply with government
              reporting obligations;
            </p>

            <p>
              when we believe in good faith that disclosure is necessary (a) to protect our rights, the integrity of the
              Service, or your safety or the safety of others, or (b) to detect, prevent, or respond to fraud,
              intellectual property infringement, violations of our policies, violations of law, or other misuse of the
              Service; and
            </p>

            <p>
              to potential transactional partners, service providers, advisors, or other third parties in connection
              with the consideration, negotiation, or completion of a corporate transaction, including in the context of
              bankruptcy, in which we are acquired by or merged with another company or we sell, liquidate, or transfer
              all or a portion of our assets.
            </p>

            <p>
              In addition, we may share or disclose information that has been aggregated, de-identified, or otherwise
              does not identify you.
            </p>
          </div>
          <div>
            <p>
              4. <b>COOKIES</b>
            </p>
            <p>
              The Service may use cookies. Cookies are small files that a website or its service provider transfers to
              your device’s hard drive through your browser (if you allow) that enables the website’s or service
              provider’s systems to recognize your browser and capture and remember certain information.
            </p>
            <p>We may use cookies to:</p>
            <p>
              Understand and save users’ preferences for future visits; and compile aggregate data about website traffic
              and website interactions in order to offer better user experiences and tools in the future.
            </p>
            <p>We may also use trusted third-party services that track this information on our behalf.</p>
            <p>
              You can choose to have your computer notify you each time a cookie is being sent, or you can choose to
              turn off all cookies. You do this through your browser settings. Because each browser is a little
              different, look at your browser’s “Help Menu” to learn the correct way to modify your cookies.
            </p>
            <p>
              If you turn cookies off, some of the features of the Service may not function properly or otherwise be
              available to you.
            </p>
            <p>
              We do not currently respond to web browser “do not track” signals or other mechanisms that provide a
              method to opt out of the collection of information across the networks of websites and online services in
              which we participate. For more information, visit www.allaboutdnt.org.
            </p>
          </div>
          <div>
            <p>
              5. <b>THIRD-PARTY DATA COLLECTION</b>
            </p>
            <p>
              Some content or applications of the Service may be provided by third parties, including advertisers, ad
              networks and servers, analytics companies, and application providers. These third parties may use cookies
              or other tracking technologies to collect information about you when you use our Service. The information
              they collect may be associated with your personal information or they may collect information, including
              personal information, about your online activities over time and across different sites and other online
              services. We do not control these third parties’ tracking technologies or how they may be used. If you
              have any questions, you should contact the responsible provider directly.{' '}
            </p>
            <p>
              One of the third-party services the Service uses is Google Analytics, a web analytics service provided by
              Google, Inc. (“Google”). Google Analytics uses cookies to analyze how individuals use the Service. The
              information generated by the cookie about your use of the Service (including your IP address) will be
              transmitted to and stored by Google on servers in the United States. Google will use this information for
              the purpose of evaluating your use of the Service, compiling reports on use of the Service, and providing
              other services relating to the Service. For more information about how Google collects and uses
              information, visit{' '}
              <a target="_blank" href="http://www.google.com/policies/privacy/partners/" rel="noreferrer">
                www.google.com/policies/privacy/partners/
              </a>
              , or any other URL Google may provide from time to time. To opt out of being tracked by Google Analytics
              across all websites visit:{' '}
              <a target="_blank" href="http://tools.google.com/dlpage/gaoptout" rel="noreferrer">
                http://tools.google.com/dlpage/gaoptout
              </a>
              .
            </p>
            <p>
              Our Service may offer links to other websites operated by third parties. Such third-party websites may
              have information, policies, and practices different from our Service. We are not responsible for the
              collection and use of your information by such third parties that are not under our control. We encourage
              you to review the privacy policies of each website you visit.
            </p>
          </div>
          <div>
            <p>
              6. <b>HOW TO CONTACT US</b>
            </p>
            <p>
              If you have any questions about this Privacy Policy or our use of your information collected through the
              Service, please contact us at <a href="mailto:privacy@plumbid.com">privacy@plumbid.com</a>
            </p>
          </div>
          <div>
            <p>
              7. <b>CHANGES TO THE PRIVACY POLICY</b>
            </p>
            <p>
              If we update this Privacy Policy, we will notify you by posting a new Privacy Policy on this page. If we
              make any changes that materially change the ways we process or protect your information, we will provide
              additional notification of changes.
            </p>
          </div>
          <p>
            <b>Effective</b>: January 1, 2022
          </p>
        </PBox>
      </PBox>
      <Footer />
    </>
  );
};
