import gql from 'graphql-tag';

export const GET_PLUMBID_ADMIN_QUERY = gql`
  query AdminPlumBid(
    $after: String
    $first: Int
    $dateFrom: Date
    $dateTo: Date
    $listingAgentId: Int
    $status: String
    $needHelp: Boolean
    $orderBy: [String]
    $id: Int
    $address: String
  ) {
    plumbids(
      after: $after
      first: $first
      dateFrom: $dateFrom
      dateTo: $dateTo
      listingAgentId: $listingAgentId
      status: $status
      needHelp: $needHelp
      orderBy: $orderBy
      id: $id
      address: $address
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id: plumbidId
          plumbidId
          timeLeft {
            timeLeft
          }
          notifyOnParsed
          owner {
            userId
            fullName
            phone
            email
          }
          active
          createdAt
          startDateTime
          finishDateTime
          listPrice
          status

          plumbidpartySet {
            id
            isDuplicate
            party
            isWinner
            status
            onboardingStatus
          }
          mls {
            id
            mlsId
            title
            fullDescription
            previewImage
            listingPrice
            dateTaken
            country
            zipCode
            state
            city
            address
            latitude
            longitude
            propertySize
            lotSize
            numberOfBedrooms
            numberOfBathrooms
            yearBuilt
            mlsinfophotoSet {
              picture
            }
          }
        }
        cursor
      }
    }
  }
`;
