import { Box, Typography } from '@material-ui/core';
import { theme } from 'utils/theme';

export const PlumbidInfo = () => {
  return (
    <Box p={4} bgcolor={theme.palette.background.whiteSmoke}>
      <Box pb={3}>
        <Typography variant={'h2'} style={{ textTransform: 'uppercase' }}>
          Please
        </Typography>
      </Box>
      <Typography variant={'body1'} style={{ lineHeight: '18px' }}>
        As the Listing Agent, you determine how long the bidding lasts for the plumBid. You have received multiple
        offers on your listing. Please confirm that all received offers have been successfully uploaded.
        <br />
        <br />
        <strong>Do you expect any additional offers that have not yet been submitted?</strong>
        <br />
        <br />
        <strong>If Yes:</strong> We strongly suggest that you set the plumBid end-date far enough in the future to allow
        all buyers at least 24 hours to complete the buyer onboarding process and participate in bidding.
        <br />
        <br />
        <strong>If No:</strong> Once you have received and uploaded all the offers, you can set the end date for any
        time. We have found most Listing Agents prefer to set the plumBid end-date for 1-2 days in the future.
        <br />
        <br />
        As the Listing Agent, you can modify the end-date until the second bidder has completed their onboarding, at
        which point the plumBid begins. Make sure that you give all expected buyers adequate time to register. To
        maintain excitement and buyer interest, do not set the plumBid end-date too far in the future.
        <br />
        <br />
      </Typography>
      <Typography
        variant={'body1'}
        style={{
          lineHeight: '18px',
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        Remember, the plumBid will begin immediately once the seller and two bidders have finished their registration
        and onboarding processes.
      </Typography>
    </Box>
  );
};
