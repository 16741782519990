import { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Grid,
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { useAuthState, useSettingsState } from 'service/store';
import state from 'service/graphql/state';
import { CallMeBack, Drawer } from 'components';
import { RouterLink, Button, LogoHome, NavigationButton } from 'legos';
import { useInitials, useResponsive } from 'utils/hooks';
import { AuthNav } from './AuthNav';
import {
  BUYERS,
  AGENTS,
  SELLERS,
  CONTACTS,
  ABOUT_US,
  WIZARD,
  PRIVACY_POLICY,
  TERM_OF_USE,
  USER_SANDBOX,
  PLUMBID_LUXURY_AUCTIONS,
  PLUMBID_MULTIPLE,
  AGENTS_LA,
  SELLER_LA,
  FAQ,
  SANDBOX_PLUMBID,
  BUYERS_LA,
} from 'routes';

import { LISTING_AGENT_VERIFY_STATUS, ROLES } from 'constant';
import { HeaderMobile } from './HeaderMobile';
import { DashboardButton } from './DashboardButton';
import { theme } from 'utils/theme';
import { AccordionList } from 'legos/Accordion';
import { HeaderLink } from './HeaderLink';

const StyledMenu = withStyles({
  paper: {
    textAlign: 'center',
    marginTop: '7px',
    backgroundColor: 'transparent',
    padding: ' 0px !important',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    textAlign: 'center',
    borderRadius: '0',
    padding: '16px 46px',
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.background.footer,
    backgroundColor: theme.palette.common.white,
    transition: 'background-color 0.3s', // Optional: add a transition for a smoother effect
    '&:hover': {
      backgroundColor: theme.palette.primary.main + ' !important',
      color: theme.palette.common.white + ' !important',
    },
  },
  roundedBottom: {
    borderRadius: '0 ',
  },
}))(MenuItem);

const LinksLA = [
  { title: 'Overview', link: () => PLUMBID_LUXURY_AUCTIONS },
  { title: 'Buyers', link: () => BUYERS_LA },
  { title: 'Sellers', link: () => SELLER_LA },
  { title: 'Agents', link: () => AGENTS_LA },
];

const LinksMO = [
  { title: 'Overview', link: () => PLUMBID_MULTIPLE },
  { title: 'Buyers', link: () => BUYERS },
  { title: 'Sellers', link: () => SELLERS },
  { title: 'Agents', link: () => AGENTS },
];

export const HeaderHome = () => {
  useInitials();
  const history = useHistory();
  const { pathname, state: pathState } = useLocation();
  const { padScreen, mobileScreen } = useResponsive();
  const [{ me }] = useAuthState();

  const toTabletScreen = useMediaQuery('(max-width: 1110px) ', {
    noSsr: true,
  });

  const isSwitchScreen = toTabletScreen;

  const isSaveSignUpForm = useReactiveVar(state.signUpFormVar);
  const [, dispatchSettings] = useSettingsState();

  const isShowButtonAddPlumBid =
    me?.roles?.includes(ROLES.LISTING_AGENT) &&
    (me?.isVerifiedAgent ||
      me.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.VERIFIED);

  const [isOpenLAMenu, setIsOpenLAMenu] = useState(null);
  const [isOpenMOMenu, setIsOpenMOMenu] = useState(null);

  const openLaMenu = Boolean(isOpenLAMenu);
  const openMOMenu = Boolean(isOpenMOMenu);

  const isShowGoBackButton =
    pathname === PRIVACY_POLICY || pathname === TERM_OF_USE;

  const handleClick = (event) => {
    setIsOpenLAMenu(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpenLAMenu(null);
  };

  const handleClickMOMenu = (event) => {
    setIsOpenMOMenu(event.currentTarget);
  };

  const handleCloseMOMenu = () => {
    setIsOpenMOMenu(null);
  };

  useEffect(() => {
    if (!isSwitchScreen) {
      setIsOpenLAMenu(null);
      setIsOpenMOMenu(null);
    }
  }, [isSwitchScreen]);

  const goBack = useCallback(() => {
    if (isSaveSignUpForm?.isSave && !me && pathState === 'invite') {
      setTimeout(
        () =>
          dispatchSettings({
            type: 'toggleAuthDrawer',
            payload: { isOpenDrawer: true, drawerScreen: 'SignUpScreen' },
          }),
        300
      );
    }
    history.goBack();
  }, [dispatchSettings, history, isSaveSignUpForm?.isSave, me, pathState]);

  const renderHeaderContent = () => (
    <>
      {pathname === USER_SANDBOX || pathname === SANDBOX_PLUMBID ? (
        <Box pr={2}>
          {' '}
          <CallMeBack />{' '}
        </Box>
      ) : null}
      <DashboardButton
        isSwitchScreen={isSwitchScreen}
        me={me}
        isShowGoBackButton={isShowGoBackButton}
      />
      {isShowButtonAddPlumBid ? (
        <Grid
          item
          style={{
            padding: isSwitchScreen ? '16px 8px' : null,
            marginLeft: isSwitchScreen ? null : 8,
          }}
        >
          <Button
            title={
              me?.wizardStepInfo?.length
                ? 'Continue adding plumBid'
                : 'Add a new plumBid'
            }
            fullWidth={isSwitchScreen}
            align="center"
            style={{
              borderRadius: '4px',
              fontWeight: 500,
              height: 39,
              minWidth: isShowGoBackButton ? 200 : 230,
            }}
            onClick={() =>
              history.push({
                pathname: WIZARD,
              })
            }
          />
        </Grid>
      ) : null}
    </>
  );

  const renderHeaderMobile = () => (
    <HeaderMobile
      handleClick={handleClick}
      handleClose={handleClose}
      open={openLaMenu}
    >
      <Box pl={2}>
        <AccordionList
          title="Luxury Auctions"
          content={LinksLA.map((link, linkIndex) => (
            <Box py={1} display="flex" alignItems="center">
              <div
                style={{
                  width: '4px',
                  height: '4px',
                  marginRight: '8px',
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                }}
              />
              <RouterLink
                key={linkIndex}
                to={link.link()}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                <div>{link.title}</div>
              </RouterLink>
            </Box>
          ))}
        />
        <AccordionList
          title="Multiple Offers"
          content={LinksMO.map((link, linkIndex) => (
            <Box py={1} display="flex" alignItems="center">
              <div
                style={{
                  width: '4px',
                  height: '4px',
                  marginRight: '8px',
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                }}
              />
              <RouterLink
                key={linkIndex}
                to={link.link()}
                style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                <div>{link.title}</div>
              </RouterLink>
            </Box>
          ))}
        />
      </Box>

      <Box py={2} pl={4}>
        <RouterLink
          to={USER_SANDBOX}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontSize: '16px', fontWeight: 400 }}
          >
            Experience it
          </Typography>
        </RouterLink>
      </Box>

      <Box py={2} pl={4}>
        <RouterLink
          to={ABOUT_US}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontSize: '16px', fontWeight: 400 }}
          >
            About Us
          </Typography>
        </RouterLink>
      </Box>

      <Box py={2} pl={4}>
        <RouterLink
          to={CONTACTS}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontSize: '16px', fontWeight: 400 }}
          >
            Contact Us
          </Typography>
        </RouterLink>
      </Box>
      <Box py={2} pl={4}>
        <RouterLink to={FAQ} style={{ textDecoration: 'none', color: 'white' }}>
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontSize: '16px', fontWeight: 400 }}
          >
            FAQ
          </Typography>
        </RouterLink>
      </Box>
      {renderHeaderContent()}
    </HeaderMobile>
  );

  return (
    <AppBar
      id="header"
      position="fixed"
      elevation={0}
      style={{
        justifyContent: 'center',
        height: me && !isSwitchScreen ? 120 : 70,
        paddingTop: me && !isSwitchScreen ? 10 : 0,
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{
          height: 70,
          maxWidth: 1440,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: mobileScreen ? 0 : 24,
          paddingLeft: mobileScreen ? 24 : 24,
        }}
      >
        <Grid
          container
          item
          xs={isShowGoBackButton ? 9 : 3}
          md={padScreen ? 4 : 2}
          alignItems="center"
        >
          {isShowGoBackButton && (
            <NavigationButton
              icon="LeftArrow"
              fullWidth={false}
              onClick={goBack}
              color={theme.palette.common.white}
              style={{
                backgroundColor: theme.palette.purple.light,
                marginRight: 38,
              }}
            />
          )}
          <RouterLink to="/">
            <Box
              id="logoBox"
              style={{
                transition: '0.3s',
                maxHeight: isShowGoBackButton ? 130 : 70,
                maxWidth: isShowGoBackButton ? 130 : 160,
              }}
            >
              <LogoHome
                size={
                  isShowGoBackButton && mobileScreen
                    ? '100%'
                    : mobileScreen
                    ? '150%'
                    : '100%'
                }
                style={{ maxHeight: 60 }}
              />
            </Box>
          </RouterLink>
        </Grid>
        <Grid
          item
          container
          display="flex"
          flexWrap="nowrap"
          justify="flex-end"
          alignItems="center"
          xs={isShowGoBackButton ? 3 : 8}
          md={isShowGoBackButton || padScreen ? 8 : 10}
          spacing={2}
        >
          {isSwitchScreen ? (
            <>
              <Grid
                container
                item
                xs
                alignItems="center"
                justify="flex-end"
                style={{ paddingRight: mobileScreen ? 60 : 30 }}
              >
                <AuthNav isHome />
              </Grid>
              {renderHeaderMobile()}
            </>
          ) : (
            <>
              <Grid item>
                <HeaderLink
                  variant="h5"
                  onClick={handleClick}
                  color="primary"
                  style={{ fontWeight: 500, cursor: 'pointer' }}
                >
                  Luxury Auctions
                </HeaderLink>
                <StyledMenu
                  anchorEl={isOpenLAMenu}
                  open={openLaMenu}
                  onClose={handleClose}
                  style={{ padding: ' 0px !important' }}
                >
                  <Box py={1}>
                    {LinksLA.map((item, index) => (
                      <RouterLink
                        key={item.title}
                        to={item.link()}
                        style={{ textDecoration: 'none', color: 'white' }}
                      >
                        <StyledMenuItem
                          onClick={handleClose}
                          style={{
                            borderRadius:
                              index === 0
                                ? '0'
                                : LinksLA.length - 1 === index
                                ? '0 0 6px 6px'
                                : 0,
                            padding: '16px 46px',
                            fontSize: '16px',
                            fontWeight: 500,
                            color: theme.palette.background.footer,
                            backgroundColor: theme.palette.common.white,
                          }}
                        >
                          {item.title}
                        </StyledMenuItem>
                      </RouterLink>
                    ))}
                  </Box>
                </StyledMenu>
              </Grid>
              <Grid item justify="center">
                <HeaderLink
                  variant="h5"
                  onClick={handleClickMOMenu}
                  color="primary"
                  align="center"
                  style={{ fontWeight: 500, position: 'relative' }}
                >
                  Multiple Offers
                </HeaderLink>
                <StyledMenu
                  anchorEl={isOpenMOMenu}
                  open={openMOMenu}
                  onClose={handleCloseMOMenu}
                  style={{ padding: ' 0px !important' }}
                >
                  <Box py={1}>
                    {LinksMO.map((item, index) => (
                      <RouterLink
                        key={item.title}
                        to={item.link()}
                        style={{ textDecoration: 'none', color: 'white' }}
                      >
                        <StyledMenuItem
                          onClick={handleCloseMOMenu}
                          style={{
                            borderRadius:
                              index === 0
                                ? '0'
                                : LinksLA.length - 1 === index
                                ? '0 0 6px 6px'
                                : 0,
                            padding: '16px 46px',
                            fontSize: '16px',
                            fontWeight: 500,
                            color: theme.palette.background.footer,
                            backgroundColor: theme.palette.common.white,
                            transition: 'background-color 0.3s',
                          }}
                        >
                          {item.title}
                        </StyledMenuItem>
                      </RouterLink>
                    ))}
                  </Box>
                </StyledMenu>
              </Grid>

              <Grid item>
                <RouterLink
                  to={USER_SANDBOX}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <HeaderLink variant="h5" color="textPrimary">
                    Experience it
                  </HeaderLink>
                </RouterLink>
              </Grid>

              <Grid item>
                <RouterLink
                  to={ABOUT_US}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <HeaderLink variant="h5" color="textPrimary">
                    About Us
                  </HeaderLink>
                </RouterLink>
              </Grid>

              <Grid item>
                <RouterLink
                  to={CONTACTS}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <HeaderLink variant="h5" color="textPrimary">
                    Contact Us
                  </HeaderLink>
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink
                  to={FAQ}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <HeaderLink variant="h5" color="textPrimary">
                    FAQ
                  </HeaderLink>
                </RouterLink>
              </Grid>
              <AuthNav isHome />
            </>
          )}
        </Grid>
        {me && !isSwitchScreen ? (
          <Grid item container justify="flex-end">
            {renderHeaderContent()}
          </Grid>
        ) : null}
      </Grid>
      <Drawer />
    </AppBar>
  );
};
