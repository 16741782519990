import { Box, Typography } from '@material-ui/core';
import { theme } from 'utils';
import { AccordionList } from 'legos/Accordion';

export const CollapseBox = ({ title, text }) => {
  return (
    <Box
      className="collapse-box"
      bgcolor={theme.palette.background.benefits}
      borderRadius="8px"
      px={2}
      py={3}
    >
      <AccordionList
        titleStyle={{ color: theme.palette.background.tabIndicator }}
        title={
          <Typography
            className="collapse-title"
            variant="h2"
            style={{ paddingLeft: 16 }}
          >
            {title}
          </Typography>
        }
        icon="RoundedArrow"
        content={
          <Typography
            variant="h3"
            style={{
              fontSize: '18px',
              lineHeight: '27px',
              paddingTop: '26px',
              paddingBottom: '16px',
            }}
          >
            {text}
          </Typography>
        }
      />
    </Box>
  );
};
