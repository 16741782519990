import gql from 'graphql-tag';
import { AdminPlumbidType } from '../types';

export const GET_USER_DETAILS_QUERY = gql`
  query GetUsersDetail($userId: Int) {
    userDetails(userId: $userId) {
      id
      email
      fullName
      phone
      dreId
      isListingAgent
      isVerifiedAgent
      isActive
      isSuperuser
      isStaff
      role
      created
      resetExpired
      participantSet {
        edges {
          node {
            id
            role
            plumbid {
              ${AdminPlumbidType}
            }
          }
        }
      }
      roles
      listingAgentStatus
      userId
      participants {
        id
      }
    }
  }
`;
