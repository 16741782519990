import React from 'react';

import { Box, LinearProgress, Typography, CircularProgress } from '@material-ui/core';

export const UploadingBarFooter = ({ processingInfo }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ borderRight: '2px solid #D8D8D8', borderLeft: '2px solid #D8D8D8' }}
    >
      <Box width="100%">
        <LinearProgress variant="determinate" value={processingInfo.percentProcessingFiles} style={{ height: 2 }} />
      </Box>
      <Box display="flex" justifyContent="center" pt={5}>
        {processingInfo.isRunProcessing ? (
          <CircularProgress value={processingInfo.percentProcessingFiles} size={17} style={{ marginRight: 4 }} />
        ) : null}
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="h4">
            {processingInfo.countProcessingFiles > 0
              ? `Processing ${processingInfo.countProcessingFiles} of ${processingInfo.totalFiles} offer${
                  processingInfo.totalFiles > 1 ? 's' : ''
                }`
              : 'Uploading is complete'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
