import { useEffect, forwardRef, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FieldArray, Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { Input } from 'legos';
import { getParticipantField } from 'utils';
import { useNotification, useDialog } from 'utils/hooks';
import { DIALOG, ROLES } from 'constant';
import { Step3Scheme } from '../index.scheme';
import { WizardPropertyThumbnail } from '../WizardPropertyThumbnail';
import { WizardPropertyThumbnailPlaceholder } from '../WizardPropertyThumbnailPlaceholder';
import { REMOVE_PARTICIPANT_INVITE_MUTATION, UPDATE_PARTICIPANT_INVITE_MUTATION } from 'service/graphql';
import { Divider } from 'legos/Divider';
import { SellerItem } from './SellerItem';

const newSeller = {
  id: null,
  edited: false,
  email: '',
  emailOld: '',
  fullName: '',
  fullNameOld: '',
  new: true,
};

export const Step3 = forwardRef(
  (
    { plumBid, plumbidId, createInvitedMutation, createInvitedLoading, responsive, inviteEmailText, setIsNextDisabled },
    ref
  ) => {
    const showNotification = useNotification();
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [updateParticipantInviteMutation, { loading: updateParticipantLoading }] = useMutation(
      UPDATE_PARTICIPANT_INVITE_MUTATION
    );
    const [removeParticipant] = useMutation(REMOVE_PARTICIPANT_INVITE_MUTATION);
    const { openDialog: openOkCancelDialog, closeDialog: closeOkCancelDialog } = useDialog(DIALOG.OK_CANCEL);
    const loading = updateParticipantLoading || createInvitedLoading;
    const isDisabled = !plumBid?.mls || loading;

    const handleRemoveSeller = (index, participantId, remove) => {
      openOkCancelDialog({
        title: 'Attention',
        text: 'Are you sure you want to remove this seller?',
        okTitle: 'OK',
        onOk: async () => {
          try {
            await removeParticipant({
              variables: {
                input: {
                  participantId,
                },
              },
            });
          } finally {
            remove(index);
            closeOkCancelDialog();
          }
        },
      });
    };

    const sellerCreateInvite = async value => {
      const { data } = value.id
        ? await updateParticipantInviteMutation({
            variables: {
              input: {
                participantId: value.id,
                fullName: value.fullName?.trim() || '',
                role: value.role,
                email: value.email?.trim(),
              },
            },
          })
        : await createInvitedMutation({
            variables: {
              input: {
                email: value.email,
                role: ROLES.SELLER,
                fullName: value.fullName || '',
                plumbidId: plumbidId,
                plumbidPartyId: plumBid.sellerParty.id,
              },
            },
          });

      const error = value.id ? data?.updateParticipantInvite?.errors : data?.createInvite?.errors;

      if (error) {
        if (
          error.includes(
            'Participant with such email has been invited for specified plumBid already. Please check if email is correct.'
          ) ||
          error.includes('User has been already invited.')
        ) {
          return 'Participant already take a part in this plumBid.';
        } else {
          showNotification({ error });
        }
      }

      if (data?.updateParticipantInvite?.fullName) {
        showNotification({
          message: 'User name has been changed because the user is already registered',
          variant: 'warning',
        });
      }
    };

    useEffect(() => {
      if (loading) {
        setIsNextDisabled(loading);
      }
      return () => setIsNextDisabled(false);
    }, [setIsNextDisabled, loading]);

    return (
      <Formik
        innerRef={ref}
        enableReinitialize
        initialValues={{
          sellers: plumBid?.sellerParty?.participantSet
            ? [
                ...plumBid?.sellerParty?.participantSet?.map(seller => ({
                  id: seller.id,
                  email: getParticipantField(seller, 'email'),
                  emailOld: getParticipantField(seller, 'email'),
                  fullName: getParticipantField(seller, 'fullName'),
                  fullNameOld: getParticipantField(seller, 'fullName'),
                  edited: false,
                  exist: !!seller.user,
                })),
                newSeller,
              ]
            : [newSeller],
          message: inviteEmailText?.[1] || '',
        }}
        validationSchema={Step3Scheme}
      >
        {({ values, handleChange }) => (
          <Form>
            <Grid container direction={responsive.tabletScreen ? 'column' : 'row'} spacing={2} justify="space-between">
              <Grid item xs={12} lg={7}>
                <Typography variant="h1" style={{ textTransform: 'uppercase', marginBottom: 50 }}>
                  Add Seller(s) information
                </Typography>
                {responsive.mobileScreen ? (
                  <Box mb={2}>
                    <Typography variant="caption">Info</Typography>
                  </Box>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="caption">Full name</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">Email</Typography>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                )}
                <FieldArray name="sellers">
                  {arrayHelpers => (
                    <>
                      {values.sellers.map((seller, index) => (
                        <Box key={`sellers.${index}`} mt={2}>
                          <SellerItem
                            responsive={responsive}
                            index={index}
                            seller={seller}
                            loading={loading}
                            isDisabled={isDisabled}
                            selectedSeller={selectedSeller}
                            setSelectedSeller={setSelectedSeller}
                            arrayHelpers={arrayHelpers}
                            handleRemoveSeller={handleRemoveSeller}
                            sellerCreateInvite={sellerCreateInvite}
                            addNewSellerRow={() => arrayHelpers.push(newSeller)}
                          />

                          {responsive.mobileScreen &&
                            values.sellers.length > 1 &&
                            index !== values.sellers.length - 1 && <Divider />}
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
                <Box mt={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="caption">Message</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box p={1} pl={3} bgcolor="rgb(244, 244, 244)" height={16}>
                        <Typography variant="h5">
                          {plumBid?.mls &&
                            `Hello ${
                              selectedSeller !== null
                                ? ref.current.values.sellers[selectedSeller]?.fullName || ''
                                : values.sellers.length
                                ? values.sellers[0]?.fullName || ''
                                : ''
                            }`}
                        </Typography>
                      </Box>
                      <Input
                        name={`message`}
                        value={values.message || ' '}
                        textArea
                        rows={9}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={4}>
                {plumBid?.mls ? <WizardPropertyThumbnail plumBid={plumBid} /> : <WizardPropertyThumbnailPlaceholder />}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
);
