import React, { useState, useRef, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Formik } from 'formik';
import { pick } from 'lodash';
import Box from '@material-ui/core/Box';
import { object, string, bool, ref } from 'yup';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { ACCEPT_INVITE_MUTATION, CREATE_USER_MUTATION } from 'service/graphql';
import { Input, PhoneInput, Button, Checkbox, Divider, RouterLink } from 'legos';
import { setSession } from 'service/auth';
import { LOGIN_MUTATION } from 'service/graphql';
import { useSettingsState, useAuthState } from 'service/store';
import { PropertyThumbnailNameAddress } from 'components';
import { ROLES } from 'constant';
import { useNotification } from 'utils/hooks';
import { formatToTitleCase, getEnv, normalizeUser } from 'utils';
import { DASHBOARD, ADMIN_DASHBOARD, TERM_OF_USE, PRIVACY_POLICY } from 'routes';
import { theme } from 'utils/theme';
import state from 'service/graphql/state';

const SignUpValidationSchema = isInvitedBuyerOrSeller =>
  object().shape({
    fullName: string().trim().required('Full name is required').nullable(),
    ...(!isInvitedBuyerOrSeller
      ? {
          dreId: string()
            .trim()
            .max(12, 'The number of characters must not exceed 12')
            .matches(/^\d+$/, 'DRE ID can only contain numbers')
            .required('DRE ID is required')
            .nullable(),
        }
      : null),
    phone: string()
      .required('Phone is required')
      .min(10, 'Please enter number in the XXX XXX XXXX format')
      .max(10)
      .nullable(),
    password: string()
      .trim()
      .required('Password is required')
      .min(6, 'Password is too short - should be 6 chars minimum')
      .nullable(),
    passwordRepeat: string()
      .trim()
      .required('Should not be empty')
      .oneOf([ref('password'), null], "Password confirmation doesn't match password"),
    ...(!isInvitedBuyerOrSeller
      ? { agreeWithTermsAndPrivatePolicy: bool().oneOf([true], 'Please indicate that you have read and agree') }
      : null),
    email: string().trim().required('Email is required').email('Invalid email'),
  });

const getFields = withoutTermsAndCond => [
  {
    label: 'Full name *',
    fieldName: 'fullName',
    init: '',
    autoFocus: true,
  },
  {
    label: 'DRE License Number *',
    fieldName: 'dreId',
    init: '',
  },
  {
    label: 'Phone *',
    fieldName: 'phone',
    component: 'PhoneInput',
    type: 'tel',
    init: '',
  },
  {
    text: <Typography>Send SMS Notifications</Typography>,
    fieldName: 'acceptSms',
    component: 'Checkbox',
    init: true,
  },
  {
    label: 'Email *',
    fieldName: 'email',
    type: 'email',
    init: '',
  },
  {
    label: 'Create Password *',
    fieldName: 'password',
    type: 'password',
    init: '',
  },
  {
    label: 'Confirm password *',
    fieldName: 'passwordRepeat',
    type: 'password',
    init: '',
  },
  ...(withoutTermsAndCond
    ? []
    : [
        {
          text: (
            <Typography>
              I have read and agreed to the{' '}
              <RouterLink to={TERM_OF_USE} state="invite" style={{ color: theme.palette.text.primary }}>
                terms conditions,
              </RouterLink>{' '}
              and{' '}
              <RouterLink to={PRIVACY_POLICY} style={{ color: theme.palette.text.primary }}>
                privacy policy
              </RouterLink>
            </Typography>
          ),
          fieldName: 'agreeWithTermsAndPrivatePolicy',
          component: 'Checkbox',
          init: false,
        },
      ]),
];

const initFields = withoutTermsAndCond =>
  getFields(withoutTermsAndCond).reduce((prev, cur) => ({ ...prev, [cur.fieldName]: cur.init }), {});

export const SignUpScreen = ({ withInvite }) => {
  const history = useHistory();
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const signUpRef = useRef();
  const googleReCaptchaApiKey = getEnv('GOOGLE_RECAPTCHA_API_KEY');
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);
  const [signInMutation] = useMutation(LOGIN_MUTATION);
  const [acceptInvite] = useMutation(ACCEPT_INVITE_MUTATION);

  const [, dispatchAuth] = useAuthState();
  const [{ invite }, dispatchSettings] = useSettingsState();

  const isInvitedBuyerOrSeller =
    withInvite && (invite.participant.role === ROLES.BUYER || invite.participant.role === ROLES.SELLER);

  sessionStorage.setItem('inv', history.location.pathname + history.location.search);

  const openSignInScreen = () =>
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: withInvite ? 'SignInFromInvitationScreen' : 'SignInScreen',
        drawerScreenGoBack: withInvite ? 'SignUpScreenFromInvitation' : 'SignUpScreen',
      },
    });

  const redirect = roles => {
    if (roles.includes(ROLES.ADMIN)) {
      setLoading(false);
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
      history.push(ADMIN_DASHBOARD);
    } else {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
      history.push(DASHBOARD);
    }
  };

  const handleRecaptchaChange = value => {
    setIsVerified(value);
  };

  const onSubmit = async values => {
    try {
      setLoading(true);

      const fieldsList = ['fullName', 'email', 'password', 'passwordRepeat', 'dreId', 'phone', 'acceptSms'];
      if (withInvite && invite) {
        fieldsList.push('token');
      }

      const {
        data: { createUser },
      } = await createUserMutation({
        variables: {
          input: {
            ...pick(values, fieldsList),
            phone: `+1${values.phone}`,
          },
        },
      });

      withInvite &&
        invite &&
        (await acceptInvite({
          variables: {
            input: {
              token: invite.token,
              accept: true,
            },
          },
        }));

      if (!createUser.success) {
        showNotification({ error: createUser.errors });
        setLoading(false);
        return;
      }

      const {
        data: { tokenAuth },
      } = await signInMutation({
        variables: {
          email: values.email,
          password: values.password,
        },
      }).finally(() => state.signUpFormVar({}));
      const user = normalizeUser(tokenAuth.user);

      dispatchAuth({
        type: 'init',
        payload: user,
      });
      setSession(tokenAuth);

      setTimeout(() => {
        sessionStorage.removeItem('inv');
        redirect(user.roles);
      }, 1000);
    } catch (error) {
      setLoading(false);
      showNotification({ error: 'Something went wrong. Please try again' });
    }
  };

  const saveSignUpFields = useCallback(() => {
    const saveValue = {
      ...initFields(isInvitedBuyerOrSeller),
      ...signUpRef.current?.values,
      password: '',
      passwordRepeat: '',
      isSave: true,
    };

    state.signUpFormVar(saveValue);
  }, [isInvitedBuyerOrSeller]);

  const getSaveSignUpFields = useReactiveVar(state.signUpFormVar);

  const initialValues = {
    ...(getSaveSignUpFields?.isSave ? getSaveSignUpFields : initFields(isInvitedBuyerOrSeller)),
    ...(withInvite && invite
      ? {
          email: invite.email,
          fullName: invite.fullName,
          token: invite.token,
        }
      : null),
  };
  return (
    <Formik
      innerRef={signUpRef}
      initialValues={initialValues}
      validationSchema={SignUpValidationSchema(isInvitedBuyerOrSeller)}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Box display="flex" flexDirection="column" p={3.75}>
            {withInvite && invite ? (
              <Box border={`1px solid ${theme.palette.divider}`} p={1}>
                <PropertyThumbnailNameAddress property={invite.plumbid.mls} withPrice />
              </Box>
            ) : (
              <Typography variant="h3" style={{ fontWeight: 800, textTransform: 'uppercase', cursor: 'alias' }}>
                {withInvite ? 'CREATE NEW ACCOUNT' : 'Create your Listing Agent account'}
              </Typography>
            )}

            {getFields(isInvitedBuyerOrSeller).map(
              ({ fieldName, label, type, component, LabelComp, autoFocus, text }) => {
                switch (component) {
                  case 'Checkbox':
                    return (
                      <>
                        <Box key={fieldName} mt={1.5} display="flex" alignItems="center">
                          <Checkbox
                            checked={values[fieldName]}
                            onChange={event => setFieldValue(fieldName, event.target.checked)}
                          />
                          <Typography>{text}</Typography>
                        </Box>
                        {touched[fieldName] && errors[fieldName] ? (
                          <Typography variant="h6" style={{ color: theme.palette.text.error }}>
                            {errors[fieldName]}
                          </Typography>
                        ) : null}
                      </>
                    );
                  case 'PhoneInput':
                    return (
                      <Box key={fieldName} mt={1.5}>
                        <PhoneInput
                          label={label}
                          value={values[fieldName]}
                          type={type || 'text'}
                          onChange={value => {
                            setFieldValue(fieldName, value);
                            saveSignUpFields();
                          }}
                          error={touched[fieldName] && errors[fieldName]}
                        />
                      </Box>
                    );
                  default:
                    return (
                      <Box
                        key={fieldName}
                        mt={1.5}
                        hidden={
                          fieldName === 'dreId' &&
                          withInvite &&
                          (invite.participant.role === ROLES.BUYER || invite.participant.role === ROLES.SELLER)
                        }
                      >
                        <Input
                          autoFocus={autoFocus}
                          label={label}
                          value={values[fieldName]}
                          type={type || 'text'}
                          onChange={event =>
                            setFieldValue(
                              fieldName,
                              fieldName === 'fullName' ? formatToTitleCase(event.target.value) : event.target.value
                            )
                          }
                          onBlur={saveSignUpFields}
                          error={touched[fieldName] && errors[fieldName]}
                        />
                      </Box>
                    );
                }
              }
            )}
            <Box mt={2} display="flex" justifyContent="center">
              <ReCAPTCHA sitekey={googleReCaptchaApiKey} onChange={handleRecaptchaChange} />
            </Box>
            <Box mt={1} width="100%">
              <Button
                title="Create new account"
                type="submit"
                fullWidth
                icon="RightArrow"
                loading={loading}
                disabled={!isVerified}
              />
            </Box>
            {withInvite && invite && (
              <>
                <Divider
                  style={{
                    marginBottom: 48,
                    marginTop: 48,
                    marginLeft: -48,
                    marginRight: -48,
                  }}
                />
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 800,
                    marginBottom: 20,
                    textTransform: 'uppercase',
                  }}
                >
                  I have an account
                </Typography>
                <Button title="Login" icon="RightArrow" onClick={openSignInScreen} />
              </>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
