import React from 'react';
import Joyride from 'react-joyride';
import { TooltipTutorial } from 'components';
import { theme } from 'utils/theme';

export const Tutorial = ({ run, style, steps, stepIndex, ref, callback, disableScrolling }) => {
  return (
    <Joyride
      run={run}
      steps={steps}
      continuous
      spotlightClicks
      spotlightPadding={3}
      disableScrolling={disableScrolling === false ? false : true}
      disableBeacon
      showSkipButton
      showProgress
      stepIndex={stepIndex}
      ref={ref}
      styles={{
        options: {
          arrowColor: theme.palette.background.vulcan,
          backgroundColor: theme.palette.background.vulcan,
          beaconSize: 36,
          overlayColor: theme.palette.transparentBlack.black8,
          primaryColor: theme.palette.background.vulcan,
          spotlightShadow: `0 0 40px ${theme.palette.transparentBlack.black8} `,
          textColor: theme.palette.common.white,
          zIndex: 10000,
          ...style,
        },
      }}
      tooltipComponent={TooltipTutorial}
      callback={callback}
    />
  );
};
