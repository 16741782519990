import React from 'react';
import { shape } from 'prop-types';

export const UserAvatar = ({ style }) => (
  <svg
    style={style}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <g>
      <path d="M594.7,990H405.3c-179.8,0-321.7,0-321.7-96.1v-19.2c0-180.1,144.4-326.6,321.7-326.6h189.5c177.4,0,321.7,146.5,321.7,326.6v19.2C916.4,990,767.3,990,594.7,990L594.7,990z M405.2,605.7c-146.6,0-265.8,120.6-265.8,269v19.2c0,38.4,153.6,38.4,265.8,38.4h189.5c92.9,0,265.8,0,265.8-38.4v-19.2c0-148.3-119.2-269-265.8-269H405.2L405.2,605.7z M490.5,528.8c-140.9,0-255.4-116.4-255.4-259.4c0-143,114.6-259.4,255.4-259.4c140.8,0,255.4,116.3,255.4,259.4C745.9,412.4,631.3,528.8,490.5,528.8L490.5,528.8z M291,269.4c0,111.2,89.5,201.8,199.5,201.8c110,0,199.4-90.5,199.4-201.8c0-111.2-89.5-201.7-199.4-201.7C380.5,67.7,291,158.1,291,269.4L291,269.4z" />
    </g>
  </svg>
);

UserAvatar.propTypes = {
  style: shape({}),
};

UserAvatar.defaultProps = {
  style: null,
};
