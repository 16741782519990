import { useMutation } from '@apollo/client';
import { omit, get, isString, isNil, isEmpty } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { CALCULATE_BID_REST } from 'service/graphql/mutation/calculateBid_REST';
import { SANDBOX_CALCULATE_BID_MUTATION } from 'service/graphql';
import { useDebounce } from '../../utils/hooks/useDebounce';
import {
  LOAN_MIN,
  APPRAISAL_MIN,
  INSPECTION_MIN,
  MAX_PAY_AMOUNT,
  CASH_DOWN_IMPROVE_MODE,
  MINIMUM_PERCENT_NO_VA_LOAN,
} from 'constant';
import { getNewIncentive, parsingErrors, refreshIncentiveInStorage } from './helper';

export const useAllIncentive = (isSandbox, initIncentive, minimalBid, plumBid, sandboxKey, me) => {
  const [calculateBidFetch, { loading, data: calculateBid }] = useMutation(
    isSandbox ? SANDBOX_CALCULATE_BID_MUTATION : CALCULATE_BID_REST,
    {
      ignoreResults: false,
    }
  );

  const [incentive, changeIncentive] = useState(initIncentive);
  const [improve, changeImprove] = useState({});
  const [errors, setErrors] = useState();
  const [cashDownErrors, setCashDownErrors] = useState();

  const [showInnerCashDown, setShowInnerCashDown] = useState(false);
  const [loadingByFields, setLoadingByFields] = useState({});

  const debouncedIncentive = useDebounce(incentive, 500);
  // set initial incentive
  useEffect(() => {
    if (plumBid?.id) {
      changeIncentive(oldIncentive => getNewIncentive(plumBid, isSandbox, sandboxKey, me, oldIncentive));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandbox, me, plumBid?.id, sandboxKey]);

  const isCashDownEmpty = useMemo(() => {
    if (
      incentive.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN &&
      (isNil(incentive.amountDown) || (isString(incentive.amountDown) && isEmpty(incentive.amountDown)))
    ) {
      return true;
    }
    if (
      incentive.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN &&
      (isNil(incentive.percentageDown) || (isString(incentive.percentageDown) && isEmpty(incentive.percentageDown)))
    ) {
      return true;
    }

    return false;
  }, [incentive.amountDown, incentive.cashDownImproveMode, incentive.percentageDown]);

  const hasCacheDownError = useMemo(() => {
    if (incentive.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN) {
      return (
        +incentive.amountDown > (incentive.isAutoCalculated ? +improve.price : +incentive.price) ||
        +incentive.amountDown <
          (incentive.vaLoan
            ? 0
            : ((incentive.isAutoCalculated ? +improve.price : +incentive.price) * MINIMUM_PERCENT_NO_VA_LOAN) / 100) ||
        +improve.percentageDown < (incentive.vaLoan ? 0 : MINIMUM_PERCENT_NO_VA_LOAN) ||
        +improve.percentageDown > 100
      );
    } else {
      if (
        isNil(incentive.percentageDown) ||
        (isString(incentive.percentageDown) && isEmpty(incentive.percentageDown))
      ) {
        return true;
      }
      return (
        +improve.amountDown > (incentive.isAutoCalculated ? +improve.price : +incentive.price) ||
        +improve.amountDown <
          (incentive.vaLoan
            ? 0
            : ((incentive.isAutoCalculated ? +improve.price : +incentive.price) * MINIMUM_PERCENT_NO_VA_LOAN) / 100) ||
        +incentive.percentageDown < (incentive.vaLoan ? 0 : MINIMUM_PERCENT_NO_VA_LOAN) ||
        +incentive.percentageDown > 100
      );
    }
  }, [
    improve.price,
    incentive.price,
    incentive.vaLoan,
    improve.amountDown,
    incentive.amountDown,
    improve.percentageDown,
    incentive.percentageDown,
    incentive.isAutoCalculated,
    incentive.cashDownImproveMode,
  ]);

  useEffect(() => {
    if (
      plumBid?.id &&
      (debouncedIncentive.price || debouncedIncentive.isAutoCalculated) &&
      !debouncedIncentive.lazyChange
    ) {
      let input = omit(debouncedIncentive, [
        'cashDownImproveMode',
        CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
        CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN,
        'isAutoCalculated',
        'lazyChange',
        'vaLoan',
      ]);

      input[debouncedIncentive.cashDownImproveMode] = debouncedIncentive[debouncedIncentive.cashDownImproveMode];
      if (debouncedIncentive.isAutoCalculated) {
        input = omit(input, ['price']);
      } else {
        if (!input.price) {
          input.price = 0;
        }
        input.price = `${input.price}`;
      }

      if (
        debouncedIncentive.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN &&
        !debouncedIncentive.percentageDown
      ) {
        input.percentageDown = 0;
      }

      if (
        debouncedIncentive.cashDownImproveMode === CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN &&
        !debouncedIncentive.amountDown
      ) {
        input.amountDown = 0;
      }

      refreshIncentiveInStorage(debouncedIncentive, isSandbox);
      input.plumbidId = plumBid.id;
      calculateBidFetch({
        variables: { input },
      })
        .then(response => {
          const err = get(response, `data.${isSandbox ? 'sandboxCalculateBid' : 'calculateBid'}.errors`);
          if (err) {
            const parsedErrors = parsingErrors(err);
            if (parsedErrors.globalErrors) {
              setErrors(parsedErrors.globalErrors);
            } else {
              setErrors(errors ? `${errors}` : '');
            }
            if (parsedErrors.cashDownErrors) {
              setCashDownErrors(parsedErrors.cashDownErrors);
            } else {
              setCashDownErrors();
            }
          } else {
            setErrors(errors ? `${errors}` : '');
            setCashDownErrors();
          }

          const newImprove = get(response, `data.${isSandbox ? 'sandboxCalculateBid' : 'calculateBid'}.bid`) || {};
          newImprove.percentageDown = newImprove.percentageDown
            ? Math.floor(+newImprove.percentageDown)
            : newImprove.percentageDown;
          newImprove.amountDown = newImprove.amountDown < 0 ? -1 * newImprove.amountDown : newImprove.amountDown;
          changeImprove(newImprove);
        })
        .catch(err => setErrors(`${errors ? errors : ''} ${Array.isArray(err) ? err.join(', ') : err} `))
        .finally(() => {
          setShowInnerCashDown(false);
          setLoadingByFields({});
        });
    }
    // Do not include debounceIncentive in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSandbox,
    minimalBid,
    plumBid?.id,
    calculateBidFetch,
    debouncedIncentive.price,
    debouncedIncentive.plumbidId,
    debouncedIncentive.lazyChange,
    debouncedIncentive.amountDown,
    debouncedIncentive.escrowLength,
    debouncedIncentive.percentageDown,
    debouncedIncentive.offerLeaseBack,
    debouncedIncentive.loanContingency,
    debouncedIncentive.isAutoCalculated,
    debouncedIncentive.cashDownImproveMode,
    debouncedIncentive.appraisalContingency,
    debouncedIncentive.inspectionContingency,
    debouncedIncentive.loanContingencyLength,
    debouncedIncentive.appraisalContingencyLength,
    debouncedIncentive.inspectionContingencyLength,
  ]);

  const resetErrors = useCallback(() => setErrors(), []);

  const changeIncentiveHandle = useCallback((newValue, field, maxValue, lazyChange, isValueChange = true) => {
    const additionalField = { lazyChange: !!lazyChange };
    changeIncentive(oldValue => {
      if (!lazyChange && isValueChange) {
        const fields = { [field]: true };
        if (field === 'price') {
          fields.all = true;
        }

        if (field === 'appraisalContingency' && !oldValue.loanContingency) {
          fields.loanContingency = true;
        }
        setLoadingByFields(oldValueLoading => ({ ...oldValueLoading, ...fields }));
      }

      if (
        field === 'inspectionContingencyLength' ||
        field === 'appraisalContingencyLength' ||
        field === 'loanContingencyLength'
      ) {
        if (+newValue === 0) {
          additionalField[field] = false;
        }

        if (+maxValue > 0) {
          newValue = +newValue > +maxValue ? (+newValue > +oldValue[field] ? +oldValue[field] : +newValue) : +newValue;
        }
      }
      if (field === 'inspectionContingency' && newValue === true && oldValue.inspectionContingencyLength === 0) {
        additionalField.inspectionContingencyLength = INSPECTION_MIN;
      }

      if (field === 'appraisalContingency' && newValue === true && oldValue.appraisalContingencyLength === 0) {
        additionalField.appraisalContingencyLength = APPRAISAL_MIN;
      }

      if (field === 'loanContingency' && newValue === true && oldValue.loanContingencyLength === 0) {
        additionalField.loanContingencyLength = LOAN_MIN;
      }

      if (field === 'price' && +newValue > MAX_PAY_AMOUNT) {
        newValue = oldValue.price;
      }
      return {
        ...oldValue,
        [field]: newValue,
        ...additionalField,
        ...(field === 'price' ? { isAutoCalculated: false } : null),
      };
    });
  }, []);

  const changeCashIncentive = useCallback(
    ({ mode, newValue, lazyChange, isValueChange = true }) => {
      if (!lazyChange && isValueChange) {
        setLoadingByFields(oldValue => ({
          ...oldValue,
          cashDown: true,
          loanContingency: true,
          appraisalContingency: true,
        }));
      }

      if (mode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN && lazyChange) {
        setShowInnerCashDown(true);
      }
      changeIncentive(oldValue => ({
        ...oldValue,
        lazyChange: !!lazyChange,
        cashDownImproveMode: mode,
        [mode]: newValue,
        ...(mode === CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN && lazyChange
          ? {
              [CASH_DOWN_IMPROVE_MODE.AMOUNT_DOWN]:
                ((incentive.isAutoCalculated ? improve.price : incentive.price) * newValue) / 100,
            }
          : null),
      }));
    },
    [incentive, improve.price]
  );

  const toggleCashDownMode = useCallback(
    mode =>
      changeIncentive(oldValue => ({
        ...oldValue,
        cashDownImproveMode: mode,
        amountDown: improve.amountDown,
        percentageDown: improve.percentageDown,
      })),
    [changeIncentive, improve.amountDown, improve.percentageDown]
  );

  const toggleIsAutoCalculated = (checked, lazy) => {
    resetErrors();
    setLoadingByFields({ all: true });
    if (lazy) {
      changeIncentive({
        ...incentive,
        isAutoCalculated: false,
      });
    } else {
      changeIncentive({
        ...incentive,
        isAutoCalculated: checked,
        ...(checked
          ? { cashDownImproveMode: CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN, percentageDown: improve.percentageDown }
          : null),
      });
    }
  };

  return {
    errors,
    improve,
    incentive,
    setErrors,
    resetErrors,
    calculateBid,
    cashDownErrors,
    loadingByFields,
    showInnerCashDown,
    setLoadingByFields,
    toggleCashDownMode,
    changeCashIncentive,
    setShowInnerCashDown,
    changeIncentiveHandle,
    toggleIsAutoCalculated,
    improveLoading: loading,
    hasCacheDownError: hasCacheDownError || isCashDownEmpty,
  };
};
