import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RadioMaterial from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Radio = withStyles(theme => ({
  root: {
    color: theme.palette.purple.light,
    '&$checked': {
      color: theme.palette.purple.light,
    },
  },
  checked: {},
}))(({ title, value, ...props }) => (
  <FormControlLabel value="best" label={title} control={<RadioMaterial {...props} />} />
));
