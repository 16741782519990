import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxMD from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { theme } from 'utils/theme';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '9px 9px 9px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 0,
    width: 20,
    height: 20,
    boxShadow: props =>
      `inset 0 0 0 2px ${
        props.error ? theme.palette.error.main : props.warning ? theme.palette.warning.main : 'black'
      }`,
    backgroundColor: 'inherit',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.background.aliceBlue,
    },
    'input:disabled ~ &': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.disabled}`,
    },
  },
  checkedIcon: {
    backgroundColor: 'inherit',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%2300'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '',
    },
    'input:disabled ~ &': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.disabled}`,
    },
  },
}));

export const Checkbox = ({ label, error, warning, labelPlacement, checked, ...props }) => {
  const classes = useStyles({ error, warning });

  return (
    <>
      <FormControlLabel
        style={{ margin: 0 }}
        labelPlacement={labelPlacement || 'end'}
        control={
          <CheckboxMD
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            checked={!!checked}
            {...props}
          />
        }
        label={label}
      />

      <Typography variant="h6" style={{ color: theme.palette.text.error }}>
        {error}
      </Typography>
    </>
  );
};
