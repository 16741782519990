import gql from 'graphql-tag';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      errors
      user {
        id
        email
        fullName
        phone
        dreId
        isVerifiedAgent
        participants {
          edges {
            node {
              role
            }
          }
        }
      }
    }
  }
`;
