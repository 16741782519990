import React, { useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';

import { PlumBidTable } from './PlumBidTable';
import { UserInformation } from './UserInformation';
import { useAuthState } from 'service/store/authStore';
import { UserPlumBidsHeader } from './UserPlumBidsHeader';
import { DIALOG, LISTING_AGENT_STATUS_ACTION } from 'constant';
import { useUserDetails, useDialog, usePageLoading, useListingAgentStatusAction } from 'utils/hooks';

export const UserPlumBidsContainer = () => {
  const [{ me }] = useAuthState();
  const { userDetails, userPlumBids, refetchUser, usersDetailsLoading } = useUserDetails(me);
  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: setLoadingInOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const { approveListingAgent, approveListingAgentLoading, rejectListingAgent, rejectListingAgentLoading } =
    useListingAgentStatusAction();

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: approveListingAgentLoading,
      }),
    [approveListingAgentLoading, setLoadingInOkCancelDialog]
  );

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: rejectListingAgentLoading,
      }),
    [rejectListingAgentLoading, setLoadingInOkCancelDialog]
  );

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const openDialogHandle = useCallback(
    (email, approveOrReject) => {
      openOkCancelDialog({
        onOk: () => {
          const refetchTableAndCloseDialog = () => {
            refetchUser();
            closeOkCancelDialog();
          };

          if (approveOrReject === LISTING_AGENT_STATUS_ACTION.APPROVE) {
            approveListingAgent(email, refetchTableAndCloseDialog);
          } else {
            rejectListingAgent(email, refetchTableAndCloseDialog);
          }
        },
        okTitle: approveOrReject,
        title: `${approveOrReject} Listing Agent`,
        text: `${approveOrReject} listing agent?`,
      });
    },
    [refetchUser, rejectListingAgent, approveListingAgent, closeOkCancelDialog, openOkCancelDialog]
  );
  usePageLoading(usersDetailsLoading, 'UserPlumBidsContainer');

  return (
    <Box style={{ margin: '0 48px' }}>
      <UserPlumBidsHeader
        userDetails={userDetails}
        onClickApprove={() => openDialogHandle(userDetails.email, LISTING_AGENT_STATUS_ACTION.APPROVE)}
        onClickReject={() => openDialogHandle(userDetails.email, LISTING_AGENT_STATUS_ACTION.REJECT)}
      />
      <UserInformation userDetails={userDetails} />
      <PlumBidTable plumBids={userPlumBids} userDetails={userDetails} loading={usersDetailsLoading} />
    </Box>
  );
};
