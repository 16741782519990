import gql from 'graphql-tag';

export const UPDATE_PARTICIPANT_MUTATION = gql`
  mutation UpdateParticipant($input: UpdateParticipantInput!) {
    updateParticipant(input: $input) {
      success
      errors
      fullName
    }
  }
`;
