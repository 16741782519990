import { Box, Typography } from '@material-ui/core';

import { Icons } from 'legos';
import { theme } from 'utils/theme';

export const PlumBidWarningMessage = ({ defaultText }) => {
  return (
    <Box
      borderRadius={2.2}
      px={1}
      py={0.5}
      display="flex"
      style={{ backgroundColor: theme.palette.background.cardUserPartiallyReady }}
      justifyContent="center"
      alignItems="center"
    >
      <Icons.Warning />
      <Typography
        variant="caption"
        align="center"
        style={{
          color: theme.palette.text.warning,
          marginLeft: 8,
        }}
      >
        {defaultText}
      </Typography>
    </Box>
  );
};
