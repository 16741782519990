import React from 'react';
import { shape } from 'prop-types';

export const Info = ({ style }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M9.601 0C4.298 0 5.20936e-08 4.298 5.20936e-08 9.6C-0.000131294 10.8609 0.248116 12.1094 0.730565 13.2743C1.21301 14.4392 1.92021 15.4977 2.81177 16.3892C3.70334 17.2808 4.76179 17.988 5.9267 18.4704C7.09161 18.9529 8.34014 19.2011 9.601 19.201C14.902 19.201 19.201 14.903 19.201 9.6C19.201 4.298 14.902 0 9.601 0ZM10.497 3.466C11.433 3.466 11.708 4.009 11.708 4.63C11.708 5.405 11.088 6.122 10.029 6.122C9.143 6.122 8.721 5.677 8.747 4.94C8.747 4.319 9.266 3.466 10.497 3.466ZM8.099 15.35C7.459 15.35 6.992 14.961 7.439 13.256L8.172 10.231C8.299 9.747 8.32 9.553 8.172 9.553C7.981 9.553 7.15 9.887 6.66 10.217L6.341 9.694C7.896 8.395 9.684 7.633 10.449 7.633C11.089 7.633 11.195 8.389 10.876 9.553L10.036 12.733C9.887 13.295 9.951 13.489 10.1 13.489C10.292 13.489 10.92 13.257 11.538 12.77L11.9 13.256C10.387 14.768 8.738 15.35 8.099 15.35Z"
      fill="black"
    />
  </svg>
);

Info.propTypes = {
  style: shape({}),
};

Info.defaultProps = {
  style: null,
};
