import React from 'react';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import { object, string, ref } from 'yup';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { RESET_PASSWORD_CONFIRM_MUTATION } from 'service/graphql';
import { Input, Button } from 'legos';
import { useSettingsState } from 'service/store';
import { useNotification } from 'utils/hooks';
import { HOME } from 'routes';
import state from 'service/graphql/state';

const ValidationSchema = object().shape({
  newPassword1: string()
    .trim()
    .required('Password is required')
    .min(6, 'Password is too short - should be 6 chars minimum')
    .nullable(),
  newPassword2: string()
    .trim()
    .required('Should not be empty')
    .oneOf([ref('newPassword1'), null], "Password confirmation doesn't match password"),
});

const fields = [
  {
    label: 'New password',
    fieldName: 'newPassword1',
    type: 'password',
    init: '',
  },
  {
    label: 'Confirm new password',
    fieldName: 'newPassword2',
    type: 'password',
    init: '',
  },
];

const initFields = fields.reduce((prev, cur) => ({ ...prev, [cur.fieldName]: cur.init }), {});

export const ResetPasswordScreen = () => {
  const history = useHistory();
  const showNotification = useNotification();
  const getSaveSignUpFields = useReactiveVar(state.signUpFormVar);

  const isInvited = sessionStorage.getItem('inv');

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_CONFIRM_MUTATION);
  const [{ resetPasswordUidb64, resetPasswordToken, createAdminPassword }, dispatchSettings] = useSettingsState();

  const formik = useFormik({
    initialValues: initFields,
    validationSchema: ValidationSchema,

    onSubmit: values => {
      resetPassword({
        variables: {
          input: {
            uid: resetPasswordUidb64,
            token: resetPasswordToken,
            ...values,
          },
        },
      })
        .then(res => {
          if (res.data?.resetPasswordConfirm?.success) {
            if (isInvited || getSaveSignUpFields?.inviteToken) {
              dispatchSettings({
                type: 'toggleAuthDrawer',
                payload: {
                  isOpenDrawer: false,
                  drawerScreen: 'ResetPasswordScreen',
                  drawerScreenGoBack: 'SignUpScreen',
                },
              });
              showNotification({ message: 'New password saved', variant: 'success' });
              history.replace(
                getSaveSignUpFields?.inviteToken ? `/invite?token=${getSaveSignUpFields?.inviteToken}` : isInvited
              );
              return;
            }
            showNotification({ message: 'New password saved', variant: 'success' });
            history.replace(HOME);
            dispatchSettings({
              type: 'toggleAuthDrawer',
              payload: {
                isOpenDrawer: true,
                drawerScreen: 'SignInScreen',
                drawerScreenGoBack: 'SignUpScreen',
              },
            });
          }
          if (res.data?.resetPasswordConfirm?.errors) {
            showNotification({ error: res.data?.resetPasswordConfirm?.errors });
          }
        })
        .catch(error => showNotification({ error }));
    },
  });

  return (
    <Box display="flex" flexDirection="column" p={3.75}>
      <Typography variant="h3" style={{ fontWeight: 800, textTransform: 'uppercase' }}>
        {createAdminPassword ? 'create password' : 'RESET PASSWORD'}
      </Typography>

      {fields.map(({ fieldName, label, type }, index) => (
        <Box key={fieldName} mt={2}>
          <Input
            label={index === 0 && createAdminPassword ? 'Create password' : label}
            value={formik.values[fieldName]}
            type={type || 'text'}
            onChange={event => formik.setFieldValue(fieldName, event.target.value)}
            error={formik.touched[fieldName] && formik.errors[fieldName]}
          />
        </Box>
      ))}

      <Button
        title={createAdminPassword ? 'Save password' : 'Save new password'}
        onClick={formik.submitForm}
        icon="RightArrow"
        loading={loading}
        style={{ marginTop: 48 }}
      />
    </Box>
  );
};
