import React from 'react';

import { PageContainer, ReviewBuyerOnboarding } from 'components';

export const ReviewBuyersOnboardingPage = () => {
  return (
    <PageContainer>
      <ReviewBuyerOnboarding />
    </PageContainer>
  );
};
