import { Add } from './AddIcon';
import { BidAvatar } from './BidAvatarIcon';
import { Bid } from './BidIcon';
import { Close } from './CloseIcon';
import { Edit } from './EditIcon';
import { Error } from './ErrorIcon';
import { WarningCircle } from './WarningCircle';
import { RightArrow } from './RightArrowIcon';
import { LeftArrow } from './LeftArrowIcon';
import { TimerLeft } from './TimerLeftIcon';
import { ViewProperty } from './ViewPropertyIcon';
import { UserAvatar } from './UserAvatarIcon';
import { UserLogged } from './UserLoggedIcon';
import { Users } from './UsersIcon';
import { Seller } from './SellerIcon';
import { CubeView } from './CubeView';
import { Star } from './StarIcon';
import { Calendar } from './Calendar';
import { Send } from './Send';
import { Copy } from './Copy';
import { Delete } from './Delete';
import { Sellers } from './Sellers';
import { Upload } from './Upload';
import { Search } from './Search';
import { EditPen } from './EditPen';
import { UserInverse } from './UserInverse';
import { Accept } from './Accept';
import { HouseArrow } from './HouseArrow';
import { SquerePlus } from './SquerePlus';
import { Dashboard } from './Dashboard';
import { House } from './House';
import { Email } from './Email';
import { Wave } from './Wave';
import { JoinedAgent } from './JoinedAgent';
import { UsersAdmin } from './UsersAdmin';
import { Facebook } from './Facebook';
import { Instagram } from './Instagram';
import { Telephone } from './Telephone';
import { Address } from './Address';
import { EmailFooter } from './EmailFooter';
import { Hamburger } from './Hamburger';
import { More } from './More';
import { DocumentIcon } from './DocumentIcon';
import { DocumentIconFilled } from './DocumentIconFilled';
import { Moon } from './Moon';
import { GoBack } from './GoBack';
import { Magnifier } from './Magnifier';
import { Marker } from './Marker';
import { Warning } from './Warning';
import { TimeInvite } from './TimeInvite';
import { Info } from './Info';
import { Sandbox } from './Sandbox';
import { Optimizer } from './Optimizer';
import { CircleCheck } from './CircleCheckIcon';
import { NewOffer } from './NewOfferIcon';
import { QuestionMark } from './QuestionMark';
import { Call } from './Call';
import { Demigos } from './Demigos';
import { UploadDocument } from './UploadDocument';
import { CalendarNew } from './CalendarNew';
import { Clock } from './Clock';
import { WizardWait } from './WizardWait';
import { Link } from './Link';
import { Phone } from './Phone';
import { UserAvatarBlack } from './UserAvatarBlack';
import { EditPencil } from './EditPencil';
import { EditPencilNoBG } from './EditPencilNoBG';
import { ArrowGo } from './ArrowGo';
import { Completed } from './Completed';
import { Download } from './Download';
import { MyBid } from './MyBidIcon';
import { MyBidInversion } from './MyBidInversion';
import { ArrowAccept } from './ArrowAccept';
import { AttentionContingent } from './AttentionContingent';
import { CirclePlus } from './CirclePlus';
import { ArrowRight } from './ArrowRight';
import { ViewEye } from './ViewEye';
import { MapMarker } from './MapMarker';
import { SandClock } from './SandClock';
import { SquareBid } from './SquareBid';
import { Zero } from './Zero';
import { LinkedIn } from './LinkedIn';
import { MaxValueContingencyWarning } from './MaxValueContingencyWarning';
import { ArrowDown } from './ArrowDown';
import { Login } from './Login';
import { InfoUnfilled } from './InfoUnfilled';
import { EditDocument } from './EditDocument';
import { Play } from './Play';
import { ShieldCheck } from './ShieldCheck';
import { UserLogin } from './UserLogin';
import { HamburgerRounded } from './HamburgerRounded';
import { RoundedMail } from './RoundedMail';
import { RoundedPhone } from './RoundedPhone';
import { RoundedArrow } from './RoundedArrow';

export const Icons = {
  Accept,
  AttentionContingent,
  GoBack,
  ArrowGo,
  Add,
  WizardWait,
  BidAvatar,
  UserAvatarBlack,
  Bid,
  Close,
  DocumentIcon,
  Dashboard,
  Demigos,
  Email,
  Edit,
  EditPen,
  EditPencil,
  EditPencilNoBG,
  EditDocument,
  Error,
  RightArrow,
  LeftArrow,
  House,
  TimerLeft,
  ViewProperty,
  UserAvatar,
  UserLogged,
  UserInverse,
  Users,
  Login,
  Seller,
  CubeView,
  Star,
  Calendar,
  CalendarNew,
  Clock,
  Send,
  Copy,
  Delete,
  Sellers,
  Upload,
  UploadDocument,
  Search,
  HouseArrow,
  SquerePlus,
  Wave,
  JoinedAgent,
  UsersAdmin,
  Facebook,
  Instagram,
  Telephone,
  Address,
  EmailFooter,
  Hamburger,
  More,
  Moon,
  Magnifier,
  Marker,
  Warning,
  TimeInvite,
  Info,
  InfoUnfilled,
  Sandbox,
  Optimizer,
  CircleCheck,
  NewOffer,
  QuestionMark,
  Call,
  DocumentIconFilled,
  WarningCircle,
  Link,
  Phone,
  Completed,
  Download,
  MyBid,
  MyBidInversion,
  ArrowAccept,
  CirclePlus,
  ArrowRight,
  ViewEye,
  MapMarker,
  SandClock,
  SquareBid,
  Zero,
  LinkedIn,
  MaxValueContingencyWarning,
  ArrowDown,
  Play,
  ShieldCheck,
  UserLogin,
  HamburgerRounded,
  RoundedMail,
  RoundedPhone,
  RoundedArrow,
};
