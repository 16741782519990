import React, { useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { PageContainer, DashboardSandbox, HeaderHome } from 'components';
import { NavigationButton } from 'legos';
import { useSBPlumBids, usePageLoading, useResponsive } from 'utils/hooks';
import { useAuthState, useSettingsState } from 'service/store';
import { LocalStorage } from 'utils/localStorage';
import { theme } from 'utils/theme';

import bgSandbox from 'assets/images/bg_sandbox.jpg';

export const UserSandboxPage = () => {
  const [{ me }] = useAuthState();
  const [{ refreshScreen }, dispatchSettings] = useSettingsState();
  const { smallScreen } = useResponsive();
  const { sbPlumBids, sbProperties, refetch, loading } = useSBPlumBids(
    {
      sandboxKey: LocalStorage.getSandboxKey(),
    },
    true
  );

  const toTabletScreen = useMediaQuery('(max-width: 1110px) ', {
    noSsr: true,
  });

  useEffect(() => {
    if (refreshScreen === 'UserSandboxPage') {
      refetch();
      dispatchSettings({
        type: 'refreshScreen',
        payload: null,
      });
    }
  }, [refreshScreen, refetch, dispatchSettings]);
  const history = useHistory();

  usePageLoading(loading, 'UserSandboxPage');

  return (
    <>
      <HeaderHome />
      <PageContainer>
        <Box px={6} pb={6} pt={me && !toTabletScreen ? 19 : 10}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <NavigationButton
              fullWidth={false}
              onClick={() => history.goBack()}
              icon="LeftArrow"
              size={34}
              iconSize={14}
              style={{
                background: theme.palette.primary.main,
                marginRight: 32,
              }}
              color={theme.palette.common.white}
            />

            <Typography variant="h1">Try the plumBid optimizer</Typography>
          </Box>
        </Box>

        <Box
          height="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundImage: `url(${bgSandbox})`,
            backgroundSize: 'cover',
            backgroundPosition: smallScreen ? 'center' : 'left',
            backgroundPositionY: '85%',
          }}
        />
        <Box padding={6}>
          <Typography variant="h2">
            Choose a home you’d like to simulate a plumBid
          </Typography>
        </Box>

        <DashboardSandbox
          plumBids={sbPlumBids}
          properties={sbProperties}
          refetch={refetch}
        />
      </PageContainer>
    </>
  );
};
