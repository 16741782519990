import styled from 'styled-components';

export const PBox = styled.div`
  ${({ bgcolor }) => (bgcolor ? `background-color: ${bgcolor};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  
  ${({ top }) => (top ? `top: ${top};` : '')}
  ${({ bottom }) => (bottom ? `bottom: ${bottom};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}
  ${({ right }) => (right ? `right: ${right};` : '')}
  
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : '')}
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}

  ${({ m }) => (m ? `margin: ${m * 8}px;` : '')}
  ${({ mt }) => (mt ? `margin-top: ${mt * 8}px;` : '')}
  ${({ mb }) => (mb ? `margin-bottom: ${mb * 8}px;` : '')}
  ${({ ml }) => (ml ? `margin-left: ${ml * 8}px;` : '')}
  ${({ mr }) => (mr ? `margin-right: ${mr * 8}px;` : '')}
  ${({ my }) => (my ? `margin-top: ${my * 8}px; margin-bottom: ${my * 8}px;` : '')}

  ${({ p }) => (p ? `padding: ${p * 8}px;` : '')}
  ${({ pt }) => (pt ? `padding-top: ${pt * 8}px;` : '')}
  ${({ pb }) => (pb ? `padding-bottom: ${pb * 8}px;` : '')}
  ${({ pr }) => (pr ? `padding-right: ${pr * 8}px;` : '')}
  ${({ pl }) => (pl ? `padding-left: ${pl * 8}px;` : '')}
  ${({ px }) => (px ? `padding-left: ${px * 8}px; padding-right: ${px * 8}px;` : '')}
  ${({ py }) => (py ? `padding-top: ${py * 8}px; padding-bottom: ${py * 8}px;` : '')}

  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
  ${({ borderRight }) => (borderRight ? `border-right: ${borderRight};` : '')}

  ${({ border }) => (border ? `border: ${border};` : '')}

  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}

  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf};` : '')}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : '')}

  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : '')}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : '')}
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
  ${({ resize }) => (resize ? `resize: ${resize};` : '')}


  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${({ boxShadow }) => (boxShadow ? `box-shadow: ${boxShadow};` : '')}
`;
