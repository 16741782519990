import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';

import { Icons, LinkButton, Button, Autocomplete, SearchInput } from 'legos';
import {
  useUsers,
  useDialog,
  useDebounce,
  usePageLoading,
  useNotification,
  useListingAgentStatusAction,
} from 'utils/hooks';
import { GLOBAL, ROLES, DIALOG, LISTING_AGENT_VERIFY_STATUS, LISTING_AGENT_STATUS_ACTION } from 'constant';
import { useSettingsState } from 'service/store';
import { formatUserFullName } from 'utils/formatters';
import { ADMIN_USER_PLUMBIDS } from 'routes';
import { RouterLink } from 'legos/RouterLink';
import { DEACTIVATE_USER_MUTATION } from 'service/graphql';
import { useAuthState } from 'service/store';
import { theme } from 'utils/theme';

const CustomTableCell = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))(TableCell);

const columnWidth = ['35%', '12%', '25%', '11%', '27%'];

export const UserTable = () => {
  const [, dispatchSettings] = useSettingsState();
  const [searchRole, setSearchRole] = useState('');
  const [searchName, setSearchName] = useState('');
  const [{ me }] = useAuthState();
  const debouncedSearchName = useDebounce(searchName, 500);
  const showNotification = useNotification();
  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: setLoadingInOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const { users, usersLoading, fetchMoreUsers, refetchUsers, hasNextPage, endCursor } = useUsers({
    role: searchRole,
    searchName: debouncedSearchName,
    first: GLOBAL.ROWS_PER_PAGE,
  });

  const [runDeactivateUser] = useMutation(DEACTIVATE_USER_MUTATION);

  const { approveListingAgent, approveListingAgentLoading, rejectListingAgent, rejectListingAgentLoading } =
    useListingAgentStatusAction();

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: approveListingAgentLoading,
      }),
    [approveListingAgentLoading, setLoadingInOkCancelDialog]
  );

  useEffect(
    () =>
      setLoadingInOkCancelDialog({
        loading: rejectListingAgentLoading,
      }),
    [rejectListingAgentLoading, setLoadingInOkCancelDialog]
  );

  useEffect(() => {
    refetchUsers();
  }, [debouncedSearchName, refetchUsers, searchRole]);

  const openDialogHandle = useCallback(
    (email, approveOrReject) => {
      openOkCancelDialog({
        onOk: () => {
          const refetchTableAndCloseDialog = () => {
            refetchUsers();
            closeOkCancelDialog();
          };

          if (approveOrReject === LISTING_AGENT_STATUS_ACTION.APPROVE) {
            approveListingAgent(email, refetchTableAndCloseDialog);
          } else {
            rejectListingAgent(email, refetchTableAndCloseDialog);
          }
        },
        okTitle: approveOrReject,
        title: `${approveOrReject} Listing Agent`,
        text: `${approveOrReject} listing agent?`,
      });
    },
    [refetchUsers, rejectListingAgent, approveListingAgent, closeOkCancelDialog, openOkCancelDialog]
  );

  const handleDeactivateUser = (userId, isActive) =>
    openOkCancelDialog({
      title: `${isActive ? 'Deactivate user' : 'Activate user'}`,
      text: `${isActive ? 'Do you want to deactivate the user?' : 'Do you want to activate the user?'}`,
      onOk: () => {
        setLoadingInOkCancelDialog({ loading: true });
        runDeactivateUser({
          variables: {
            input: {
              userId,
              activate: !isActive,
            },
          },
        })
          .then(res => {
            if (res.data?.adminDeactivateUser?.errors) {
              showNotification({ error: res.data.adminDeactivateUser.errors });
            } else {
              showNotification({ message: isActive ? 'User deactivated' : 'User activated', variant: 'success' });
              refetchUsers();
            }
          })
          .catch(error => showNotification({ error }))
          .finally(() => {
            setLoadingInOkCancelDialog({ loading: false });
            closeOkCancelDialog();
          });
      },
    });

  usePageLoading(usersLoading, 'UserTable');

  const selectRole = (_, value) => {
    setSearchRole(value);
  };

  const openAddAdminUserDialog = () =>
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: 'AddAdminUserScreen',
      },
    });
  const renderRoles = user => (
    <Grid container spacing={1} style={{ overflow: 'hidden' }}>
      {user.roles.map(role => (
        <Grid item>
          <Typography
            key={role}
            variant="h5"
            noWrap
            style={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              background: theme.palette.background.table,
              color:
                (role === ROLES.LISTING_AGENT && user.listingAgentStatus !== LISTING_AGENT_VERIFY_STATUS.VERIFIED) ||
                !user.isActive
                  ? theme.palette.disabled
                  : theme.palette.text.primary,
              width: 'auto',
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            {role}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  const renderTableBody = () =>
    users.map(user => (
      <TableRow
        key={user.id}
        hover
        style={{
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <CustomTableCell width={columnWidth[0]} component="th" scope="row">
          <Box display="flex" alignItems="center">
            <Box>
              <Icons.UserInverse
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 8,
                  fill: user.isActive ? theme.palette.text.primary : theme.palette.text.disabled,
                }}
              />
            </Box>
            <Box>
              <RouterLink
                className="userTable--router"
                to={ADMIN_USER_PLUMBIDS + '/' + user.userId}
                style={{
                  color: user.isActive ? theme.palette.primary.main : theme.palette.text.disabled,
                }}
              >
                <Typography variant="h5">{formatUserFullName(user)}</Typography>
              </RouterLink>

              {user.dreId && (
                <Typography variant="h5" style={{ color: theme.palette.text.rain }}>
                  id: {user.dreId}
                </Typography>
              )}
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell width={columnWidth[1]}>
          <Typography
            variant="h5"
            style={{
              color: user.isActive ? theme.palette.text.primary : theme.palette.text.disabled,
            }}
          >
            {user.phone}
          </Typography>
        </CustomTableCell>
        <CustomTableCell width={columnWidth[2]}>
          <Typography
            variant="h5"
            style={{
              color: user.isActive ? theme.palette.text.primary : theme.palette.text.disabled,
            }}
          >
            {user.email}
          </Typography>
        </CustomTableCell>
        <CustomTableCell width={columnWidth[3]} style={{ height: 'auto' }}>
          {renderRoles(user)}
        </CustomTableCell>
        <CustomTableCell
          width={columnWidth[4]}
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {user.roles.includes(ROLES.LISTING_AGENT) &&
            (user.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.NEW ||
              user.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.UNVERIFIED) && (
              <>
                <LinkButton
                  icon="Accept"
                  title="Approve"
                  iconSize={8}
                  color="link"
                  onClick={() => openDialogHandle(user.email, LISTING_AGENT_STATUS_ACTION.APPROVE)}
                  style={{
                    marginRight: 8,
                  }}
                />
                <LinkButton
                  icon="Close"
                  title="Reject"
                  iconSize={8}
                  color="link"
                  onClick={() => openDialogHandle(user.email, LISTING_AGENT_STATUS_ACTION.REJECT)}
                  style={{
                    marginRight: 8,
                  }}
                />
              </>
            )}
          {user.userId !== me.userId && (
            <LinkButton
              icon={user.isActive ? 'Moon' : 'Add'}
              iconSize={16}
              title={user.isActive ? 'Deactivate' : 'Active'}
              color="link"
              onClick={() => handleDeactivateUser(user.userId, user.isActive)}
              style={{
                marginRight: 24,
                fill: 'red',
              }}
            />
          )}
        </CustomTableCell>
      </TableRow>
    ));

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid container item xs={9} alignItems="center">
          <Grid item>
            <SearchInput value={searchName} onChange={value => setSearchName(value)} />
          </Grid>
          <Grid item>
            <Box minWidth={200}>
              <Autocomplete
                noOptionsText="No role"
                options={Object.values(ROLES)}
                onChange={selectRole}
                placeholder="Role"
                clearOnEscape
                clearOnBlur
                autoComplete
                style={{ marginLeft: 8 }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Button title="Add admin user" icon="Add" onClick={openAddAdminUserDialog} />
        </Grid>
      </Grid>

      <Box pt={2}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            fetchMoreUsers({
              variables: {
                after: endCursor,
                first: GLOBAL.ROWS_PER_PAGE,
              },
            });
          }}
          hasMore={hasNextPage}
          loader={
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          }
        >
          <TableContainer>
            <Table
              stickyHeader
              aria-label="users table"
              style={{
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <TableHead>
                <TableRow>
                  <CustomTableCell width={columnWidth[0]} style={{ backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4">Full name</Typography>
                    <Typography variant="h4">ID</Typography>
                  </CustomTableCell>
                  <CustomTableCell width={columnWidth[1]} style={{ backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4">Phone</Typography>
                  </CustomTableCell>
                  <CustomTableCell width={columnWidth[2]} style={{ backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4">Email</Typography>
                  </CustomTableCell>
                  <CustomTableCell width={columnWidth[3]} style={{ backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4">Role</Typography>
                  </CustomTableCell>
                  <CustomTableCell width={columnWidth[4]} style={{ backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4">Actions</Typography>
                  </CustomTableCell>
                </TableRow>
              </TableHead>

              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </>
  );
};
