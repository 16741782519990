import gql from 'graphql-tag';

export const CONFIRM_BUYER_ANSWERS_MUTATION = gql`
  mutation ConfirmBuyerAnswersMutation($input: ConfirmBuyerAnswersInput!) {
    confirmBuyerAnswers(input: $input) {
      success
      errors
    }
  }
`;
