import React from 'react';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import { object, string } from 'yup';

import { Input, PhoneInput, Button } from 'legos';
import { useNotification, useCreateAdminUser } from 'utils/hooks';
import { useSettingsState } from 'service/store';
import { GET_USERS_QUERY } from 'service/graphql';

const validationSchema = object().shape({
  fullName: string().trim().required('Full name is required'),
  email: string().trim().required('Email is required').email('Invalid email'),
});

const fields = [
  {
    label: 'Full name',
    fieldName: 'fullName',
    init: '',
  },
  {
    label: 'Email',
    fieldName: 'email',
    type: 'email',
    init: '',
  },
  {
    label: 'Phone',
    fieldName: 'phone',
    component: 'PhoneInput',
    type: 'tel',
    init: '',
  },
];

const initialValues = fields.reduce((prev, cur) => ({ ...prev, [cur.fieldName]: cur.init }), {});

export const AddAdminUserScreen = () => {
  const [, dispatchSettings] = useSettingsState();
  const { createAdminUser, createAdminUserLoading } = useCreateAdminUser();
  const showNotification = useNotification();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      createAdminUser({
        variables: {
          input: values,
        },
        refetchQueries: [{ query: GET_USERS_QUERY }],
      }).then(response => {
        if (response?.data?.createAdminUser?.success) {
          dispatchSettings({
            type: 'toggleAuthDrawer',
            payload: { isOpenDrawer: false },
          });
          showNotification({
            message: 'Admin user created',
            variant: 'success',
          });
        }
      });
    },
  });

  return (
    <Box height="calc(100% - 60px)" display="flex" flexDirection="column" p={3.75} justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        {fields.map(({ fieldName, label, type, component }) => (
          <Box key={fieldName} mt={1.5}>
            {component === 'PhoneInput' ? (
              <PhoneInput
                label={label}
                value={formik.values[fieldName]}
                type="tel"
                onChange={value => formik.setFieldValue(fieldName, value)}
                error={formik.touched[fieldName] && formik.errors[fieldName]}
              />
            ) : (
              <Input
                label={label}
                value={formik.values[fieldName]}
                type={type || 'text'}
                onChange={event => formik.setFieldValue(fieldName, event.target.value)}
                error={formik.touched[fieldName] && formik.errors[fieldName]}
              />
            )}
          </Box>
        ))}
      </Box>

      <Box>
        <Button
          title="Create new user"
          fullWidth
          onClick={formik.submitForm}
          icon="Add"
          loading={createAdminUserLoading}
          style={{ marginTop: 16, marginBottom: 16 }}
        />
      </Box>
    </Box>
  );
};
