import gql from 'graphql-tag';

import { BidType } from '../types';

export const SAVE_OFFER_DATA_MUTATION = gql`
  mutation SaveOfferDataMutation($input: SaveOfferDataInput!) {
    saveOfferData(input: $input) {
      success
      errors
      bid {
        id
        ${BidType}
      }
    }
  }
`;
