import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { APPROVE_LISTING_AGENT_MUTATION, REJECT_LISTING_AGENT_MUTATION } from 'service/graphql';
import { useNotification } from './useNotification';

export const useListingAgentStatusAction = () => {
  const showNotification = useNotification();

  const [approve, { loading: approveListingAgentLoading }] = useMutation(APPROVE_LISTING_AGENT_MUTATION);
  const [reject, { loading: rejectListingAgentLoading }] = useMutation(REJECT_LISTING_AGENT_MUTATION);

  const approveListingAgent = useCallback(
    (email, cb) => {
      approve({
        variables: {
          input: {
            email,
          },
        },
      })
        .then(response => {
          if (response?.data?.approveListingAgent?.success) {
            showNotification({
              message: 'Listing Agent approved',
              variant: 'success',
            });
          } else if (response?.data?.approveListingAgent?.errors) {
            showNotification({
              message: response.data.approveListingAgent.errors,
              variant: 'error',
            });
          }
        })
        .catch(error =>
          showNotification({
            error,
          })
        )
        .finally(cb);
    },
    [approve, showNotification]
  );

  const rejectListingAgent = useCallback(
    (email, cb) => {
      reject({
        variables: {
          input: {
            email,
          },
        },
      })
        .then(response => {
          if (response?.data?.rejectListingAgent?.success) {
            showNotification({
              message: 'Listing Agent rejected',
              variant: 'success',
            });
            cb();
          } else if (response?.data?.rejectListingAgent?.errors) {
            showNotification({
              message: response.data.rejectListingAgent.errors,
              variant: 'error',
            });
          }
        })
        .catch(error =>
          showNotification({
            error,
          })
        );
    },
    [reject, showNotification]
  );

  return {
    approveListingAgent,
    approveListingAgentLoading,
    rejectListingAgent,
    rejectListingAgentLoading,
  };
};
