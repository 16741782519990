import React from 'react';

import { Text, PBox } from 'legos';
import { theme } from 'utils/theme';

const colorsStep = [
  '#27B05E23',
  '#27B05E33',
  '#27B05E45',
  '#27B05E63',
  '#27B05E75',
  '#27B05E90',
  '#27B05E99',
  '#27B05Eaa',
  '#27B05Ecc',
  '#27B05E',
];

export const SellerPriorities = ({ title, process, disabled }) => {
  const step = Math.ceil(process / (100 / colorsStep.length));

  return (
    <PBox width="100%">
      <PBox
        mt={1}
        mb={1}
        width="100%"
        height="16px"
        display="flex"
        borderRadius="50px"
        border={`${theme.palette.background.paper} solid 2px`}
        bgcolor={disabled ? theme.palette.text.grey : theme.palette.background.paper}
      >
        {!disabled &&
          colorsStep.map((color, index) => {
            return (
              <PBox
                key={index}
                width="20%"
                height="16px"
                stepIndex={index}
                bgcolor={step > index ? color : '#0000000D'}
                borderRight={`${theme.palette.background.paper} solid 2px`}
                borderRadius={index === 0 ? '50px 0 0 50px' : index === colorsStep.length - 1 ? '0 50px 50px 0' : ''}
              />
            );
          })}
      </PBox>
      <Text disabled={disabled} variant="h5">
        {title}
      </Text>
    </PBox>
  );
};
