import React, { useState, useCallback } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';

import { FileItem } from './FileItem';
import { UploadingBar } from './UploadingBar';
import { UploadZipFormsOffer } from './UploadZipFormsOffer';
import { WizardPropertyThumbnail } from '../WizardPropertyThumbnail';
import { WizardPropertyThumbnailPlaceholder } from '../WizardPropertyThumbnailPlaceholder';

export const Step2 = ({ plumBid, plumBidLoading, onUploadFiles, files, uploadingProgress, responsive }) => {
  const onSelect = useCallback(id => {
    setSelectedId(id);
  }, []);

  const [selectedId, setSelectedId] = useState();

  if (plumBidLoading && !plumBid) {
    return (
      <Box width="100%" p={2} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={4} justify="space-between">
      <Grid container item xs={12} md={responsive.tabletScreen ? 12 : 7} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
            Upload your offers
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box pt={7}>
            <Grid item xs={12} md={10} lg={responsive.tabletScreen ? 8 : 10}>
              {(!!plumBid?.buyerParty?.length || !!files?.length) && (
                <Box pb={2}>
                  <UploadingBar progress={files?.length ? uploadingProgress : 100} />
                </Box>
              )}
              <UploadZipFormsOffer onUploadFile={onUploadFiles} plumBid={plumBid} loading={uploadingProgress !== 100}>
                {!!plumBid?.buyerParty?.length || !!files?.length ? (
                  <Box mt={3.75} style={{ overflowY: 'scroll' }}>
                    {plumBid.buyerParty.map(party => (
                      <FileItem key={party.id} party={party} selectedId={selectedId} onSelect={onSelect} />
                    ))}
                  </Box>
                ) : null}
              </UploadZipFormsOffer>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} lg={responsive.tabletScreen ? 6 : 4}>
        {plumBid?.mls ? <WizardPropertyThumbnail plumBid={plumBid} /> : <WizardPropertyThumbnailPlaceholder />}
      </Grid>
    </Grid>
  );
};
