import { Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PBox } from 'legos/PBox';
import { theme } from 'utils';
import { useResponsive } from 'utils/hooks';

export const OfferSkeleton = () => {
  const { tabletScreen } = useResponsive();
  return (
    <PBox mb={3} position="relative">
      <Grid container spacing={tabletScreen ? 1 : 3}>
        <Grid item container xs>
          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="100%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="100%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="100%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="30%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="35%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Box width="100%" px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" pt={10} px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
            <PBox width="100%" px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Box width="100%" pt={8} px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </Box>
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <PBox width="100%" pt={3} px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
            <PBox width="100%" px={3.75}>
              <PBox display="flex" mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 65 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="12px"
                  width="40%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
              <PBox display="flex" mb={4.7}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro, marginRight: 8 }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height="35px"
                  width="50%"
                  style={{ background: theme.palette.text.gainsboro }}
                />
              </PBox>
            </PBox>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <PBox width="100%" pt={3} px={3.75} pb={{ xs: 4 }}>
              <Skeleton
                variant="rect"
                animation="wave"
                height="75px"
                width="100%"
                style={{ background: theme.palette.text.gainsboro }}
              />
            </PBox>
          </Grid>
        </Grid>
      </Grid>
    </PBox>
  );
};
