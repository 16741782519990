import { useHistory } from 'react-router';

import { Button, PBox } from 'legos';
import { BUYER_ONBOARDING, SELLER_ONBOARDING, WIZARD } from 'routes';
import { DIALOG, NEW_PARTICIPANT_STATUS, NEW_PARTY_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import { useAuthState } from 'service/store';
import { GoToOptimizer } from './GoToOptimizer';
import { useDialog } from 'utils/hooks';
import { ForceBid } from './ForceBid';

export const ControlButtons = ({ plumBid, disabledButtons }) => {
  const history = useHistory();
  const [{ me }] = useAuthState();

  const myInfo = plumBid?.myInfoInPlumBid;
  const { openDialog: openReviewOfferDialog } = useDialog(DIALOG.REVIEW_OFFER);
  const disabled = plumBid.status === PLUMBID_STATUS.INCOMPLETE || disabledButtons;
  const reviewOffers = () => {
    if (plumBid.myInfoInPlumBid.isBuyerParty) {
      openReviewOfferDialog({
        plumBid: plumBid,
        party: plumBid.myInfoInPlumBid.myParty,
        offerSettings: { values: plumBid.myInfoInPlumBid.myParty.offersettings, errors: {} },
      });
    } else {
      history.push({
        pathname: WIZARD,
        state: { displayOnlyStep5: true, plumBidId: plumBid.id },
      });
    }
  };

  const openReviewPriorities = participant => {
    if (myInfo.role === ROLES.LISTING_AGENT) {
      history.push({
        pathname: SELLER_ONBOARDING,
        search: `plumbid=${plumBid.id}`,
        state: {
          seller: participant,
        },
      });
    } else {
      history.push({
        pathname: SELLER_ONBOARDING,
        search: `plumbid=${plumBid.id}`,
      });
    }
  };

  const showOnboardingComplete = plumBid.status !== PLUMBID_STATUS.FINISHED;
  const isFullService = plumBid.service.name === 'fullservice';

  const isShowButtonForceBid =
    ((plumBid.sellerParty.status === NEW_PARTY_STATUS.COMPLETED && plumBid.buyerPartiesReady > 1) ||
      plumBid.status === PLUMBID_STATUS.LIVE) &&
    me.roles.includes(ROLES.ADMIN) &&
    !plumBid.lastBid;


  if (plumBid.status === PLUMBID_STATUS.CANCELED) return null;

  if (myInfo.role === ROLES.BUYER_AGENT) {
    return (
      <>
        <GoToOptimizer plumBid={plumBid} />
        {!isFullService && (
          <PBox pt={1}>
            <Button fullWidth variant="secondary" title="Review offer" onClick={reviewOffers} disabled={disabled} />
          </PBox>
        )}
      </>
    );
  }

  if (myInfo.role === ROLES.BUYER) {
    return (
      <>
        {myInfo.myStatus === NEW_PARTICIPANT_STATUS.COMPLETED && (
          <PBox pb={1}>
            <GoToOptimizer plumBid={plumBid} />
          </PBox>
        )}
        {showOnboardingComplete && !isFullService && (
          <Button
            fullWidth
            variant={myInfo.myStatus === NEW_PARTICIPANT_STATUS.COMPLETED ? 'secondary' : 'primary'}
            title={
              myInfo.myStatus === NEW_PARTICIPANT_STATUS.COMPLETED ? 'Onboarding Completed ' : 'Complete Onboarding'
            }
            onClick={() =>
              history.push({
                pathname: BUYER_ONBOARDING,
                search: `plumbid=${plumBid.id}`,
                state: { myStatusInPlumBid: myInfo },
              })
            }
            disabled={disabled}
          />
        )}
        {!isFullService && (
          <PBox pt={1}>
            <Button fullWidth variant="secondary" title="Review offer" onClick={reviewOffers} disabled={disabled} />
          </PBox>
        )}
      </>
    );
  }

  if (myInfo.role === ROLES.SELLER) {
    return (
      <>
        {myInfo.myStatus === NEW_PARTICIPANT_STATUS.COMPLETED && (
          <PBox pb={1}>
            <GoToOptimizer plumBid={plumBid} />
          </PBox>
        )}
        {(plumBid.status !== PLUMBID_STATUS.FINISHED || myInfo.myStatus !== NEW_PARTICIPANT_STATUS.COMPLETED) && (
          <Button
            fullWidth
            variant={myInfo.myStatus === NEW_PARTICIPANT_STATUS.COMPLETED ? 'secondary' : 'primary'}
            title="Set Priorities"
            onClick={() => openReviewPriorities(plumBid?.sellerParty?.participantSet[0])}
            disabled={disabled}
          />
        )}
      </>
    );
  }
  if (myInfo.role === ROLES.LISTING_AGENT) {
    return (
      <PBox pb={1}>
        <GoToOptimizer plumBid={plumBid} />
        {isShowButtonForceBid && !isFullService ? (
          <PBox mt={1}>
            <ForceBid plumbidId={plumBid?.id} />
          </PBox>
        ) : null}
      </PBox>
    );
  }

  return null;
};
