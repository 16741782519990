import { ApolloClient, InMemoryCache, split, from, fromPromise } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { RestLink } from 'apollo-link-rest';
import DebounceLink from 'apollo-link-debounce';

import { renewToken } from 'service/auth';
import state from './state';
import { LocalStorage } from 'utils/localStorage';
import { getEnv } from 'utils/getEnv';

const renewTokenInterval = 29.5 * 60 * 1000;

// query, mutation
const httpLink = createUploadLink({
  uri: `${getEnv('PLUMBID_API_ENDPOINT')}/graphql/`,
  credentials: 'include',
});

const authLink = setContext(({ operationName }, { headers }) => {
  const token = LocalStorage.getFrontToken();
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `${operationName?.includes('REST') ? 'Bearer' : 'JWT'} ${token}` } : null),
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, forward, networkError }) => {
  if (
    (graphQLErrors && graphQLErrors?.[0]?.message === 'Signature has expired') ||
    networkError?.message?.includes?.('Received status code 401') ||
    networkError?.message?.includes?.('Authentication credentials were not provided.')
  ) {
    return fromPromise(renewToken())
      .filter(value => Boolean(value))
      .flatMap(newToken => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            ...(newToken
              ? { authorization: `${operation?.operationName?.includes('REST') ? 'Bearer' : 'JWT'} ${newToken}` }
              : null),
          },
        });
        return forward(operation);
      });
  }
});

// subscription
export const subscriptionClient = new SubscriptionClient(
  async () =>
    `${getEnv('PLUMBID_API_SUBSCRIPTION_ENDPOINT')}/graphql/${
      LocalStorage.getFrontToken() ? `?token=${LocalStorage.getFrontToken()}` : ''
    }`,
  {
    lazy: true,
    reconnect: true,
    connectionParams: {
      token: LocalStorage.getFrontToken(),
    },
  },
  null,
  []
);

subscriptionClient.onReconnected(() => {
  state.isWSConnectedVar(true);
});

subscriptionClient.onDisconnected(() => {
  state.isWSConnectedVar(false);
});

const wsLink = new WebSocketLink(subscriptionClient);

//Add polling for renew token and reset webSocket connections
setInterval(() => renewToken(), renewTokenInterval);

const restLink = new RestLink({
  uri: `${getEnv('PLUMBID_API_ENDPOINT')}/api/v1/`,
  credentials: 'include',
  headersToOverride: ['authorization'],
  headers: {
    'Content-Type': 'application/json',
  },
});
const DEFAULT_DEBOUNCE_TIMEOUT = 500;
const debouncedLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectedChat: {
          read() {
            return state.selectedChatVar();
          },
        },
        plumbids: relayStylePagination(),
        users: relayStylePagination(),
        plumbidByUser: {
          keyArgs: ['plumbidStatus'],
        },
        globalPlumBidPopup: {
          read() {
            return state.globalPlumBidPopupVar();
          },
        },
        pageLoading: {
          read() {
            return state.pageLoadingVar();
          },
        },
        plumBidsTimer: {
          read() {
            return state.plumBidsTimerVar();
          },
        },
        plumBidsTimerLeft: {
          read() {
            return state.plumBidsTimerLeftVar();
          },
        },
        showDialog: {
          read() {
            return state.showDialogVar();
          },
        },
        tutorialOptimizerStep: {
          read() {
            return state.tutorialOptimizerStepVar();
          },
        },
        tutorialToggleActive: {
          read() {
            return state.tutorialToggleActiveVar();
          },
        },
        tutorialSkipRun: {
          read() {
            return state.tutorialSkipRunVar();
          },
        },
        tutorialPause: {
          read() {
            return state.tutorialPauseVar();
          },
        },
        isRunningAtomicOperation: {
          read() {
            return state.runAtomicOperationVar();
          },
        },
        isWSConnected: {
          read() {
            return state.isWSConnectedVar();
          },
        },
        signUpForm: {
          read() {
            return state.signUpFormVar();
          },
        },
        bidProcessing: {
          read() {
            return state.bidProcessingVar();
          },
        },
      },
    },
  },
});

export const apolloClient = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    from([authLink, errorLink, restLink, debouncedLink, httpLink])
  ),
  cache,
});
