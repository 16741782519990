import React from 'react';
import { shape } from 'prop-types';

export const MyBidInversion = ({ style }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <circle cx="13.6665" cy="14" r="13.5" fill="white" />
    <path
      d="M20.5989 21.1694C20.8108 20.9551 20.9297 20.6658 20.9297 20.3644C20.9297 20.063 20.8108 19.7738 20.5989 19.5595L12.8233 11.7891L11.2393 13.3938L19.02 21.1719C19.1235 21.2761 19.2466 21.3587 19.3822 21.415C19.5178 21.4712 19.6632 21.5001 19.81 21.4999C19.9568 21.4996 20.1021 21.4703 20.2375 21.4136C20.3729 21.3568 20.4957 21.2738 20.5989 21.1694Z"
      fill="#731E6B"
    />
    <path d="M10.9818 7.0166L6.51904 11.531L9.52694 14.5751L13.9897 10.0581L10.9818 7.0166Z" fill="#731E6B" />
    <path
      d="M6.27056 11.8304C6.21679 11.7752 6.15247 11.7315 6.08144 11.7017C6.0104 11.672 5.93411 11.6569 5.8571 11.6572C5.78018 11.6574 5.70405 11.6728 5.6331 11.7026C5.56215 11.7323 5.49776 11.7757 5.44364 11.8304L5.18523 12.0888C5.07551 12.2021 5.01416 12.3536 5.01416 12.5113C5.01416 12.669 5.07551 12.8205 5.18523 12.9338L8.1492 15.9184C8.26037 16.0252 8.40853 16.0848 8.56266 16.0848C8.71679 16.0848 8.86494 16.0252 8.97611 15.9184L9.23452 15.66C9.34531 15.5478 9.40742 15.3965 9.40742 15.2388C9.40742 15.0811 9.34531 14.9298 9.23452 14.8176L6.27056 11.8304Z"
      fill="#731E6B"
    />
    <path
      d="M14.237 9.75811C14.3475 9.86607 14.4959 9.92651 14.6504 9.92651C14.8049 9.92651 14.9533 9.86607 15.0639 9.75811L15.3223 9.4997C15.4331 9.38749 15.4952 9.23616 15.4952 9.07849C15.4952 8.92081 15.4331 8.76948 15.3223 8.65728L12.3583 5.67264C12.3042 5.61795 12.2399 5.57453 12.1689 5.54489C12.0979 5.51526 12.0218 5.5 11.9449 5.5C11.868 5.5 11.7918 5.51526 11.7208 5.54489C11.6499 5.57453 11.5855 5.61795 11.5314 5.67264L11.273 5.93105C11.1622 6.04325 11.1001 6.19458 11.1001 6.35226C11.1001 6.50993 11.1622 6.66126 11.273 6.77347L14.237 9.75811Z"
      fill="#731E6B"
    />
  </svg>
);

MyBidInversion.propTypes = {
  style: shape({}),
};

MyBidInversion.defaultProps = {
  style: {
    fill: 'black',
  },
};
