import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { NavigationButton } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const DialogHeader = ({ title, onClose, height, logo }) => {
  const responsive = useResponsive();
  return (
    <Box style={{ minHeight: height || 72 }}>
      <Box
        display="flex"
        pl={3.75}
        alignItems="stretch"
        justifyContent="space-between"
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          style={{
            borderRight: `1px solid ${theme.palette.divider}`,
            width: '100%',
          }}
        >
          <Typography variant={responsive.mobileScreen ? 'h4' : 'h2'} style={{ textTransform: 'uppercase' }}>
            {logo}
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          style={{
            width: height || 72,
          }}
        >
          <NavigationButton icon="Close" size={height || 72} onClick={onClose} />
        </Box>
      </Box>
    </Box>
  );
};
