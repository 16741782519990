import React from 'react';
import { shape } from 'prop-types';

export const Send = ({ style }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" style={style}>
    <path
      d="M16.9992 1L8.19922 9.8"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17 1L11.4 17L8.2 9.8L1 6.6L17 1Z"
      stroke={style.stroke || style.fill || style.color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

Send.propTypes = {
  style: shape({}),
};

Send.defaultProps = {
  style: {},
};
