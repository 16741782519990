import { useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_PLUMBID_ADMIN_QUERY, ON_NEW_PLUMBID_ADMIN_EVENT_SUBSCRIPTION } from 'service/graphql';
import { useNotification } from './useNotification';
import { useAuthState } from 'service/store/authStore';
import { normalizePlumBid } from 'utils/normalizePlumBid';

export const usePlumBidAdmin = variables => {
  const [{ me }] = useAuthState();
  const [fetch, { data, loading, fetchMore, refetch, subscribeToMore }] = useLazyQuery(GET_PLUMBID_ADMIN_QUERY, {
    errorPolicy: 'all',
    variables,
    onError: error => showNotification({ error }),
  });

  const showNotification = useNotification();

  useEffect(() => {
    if (subscribeToMore) {
      return subscribeToMore({
        document: ON_NEW_PLUMBID_ADMIN_EVENT_SUBSCRIPTION,
        variables: { room: 'admin_plumbid_events' },
      });
    }
  }, [subscribeToMore]);

  const plumBidsAdmin = useMemo(
    () =>
      data?.plumbids?.edges?.map(edge => {
        return { plumBid: normalizePlumBid(edge.node, false, me) };
      }) || [],
    [data?.plumbids?.edges, me]
  );

  const pageInfo = data?.plumbids?.pageInfo || {};

  return {
    plumBidsAdmin,
    pageInfo,
    refetchPlumBids: refetch || fetch,
    fetchMore: fetchMore,
    plumBidsAdminLoading: loading,
  };
};
