import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import { string, number, shape, func, oneOfType } from 'prop-types';

import { Icons } from 'legos/Icons';
import { theme } from 'utils/theme';

export const NavigationButton = ({
  icon,
  onClick,
  size,
  style,
  color,
  iconSize,
  uploadFile,
  onUploadFile,
  fileType,
  ...props
}) => {
  const Icon = Icons[icon] || null;
  const _color = theme.palette.text[color] || color;
  const uploadInputRef = useRef();
  const handleClick = uploadFile ? () => uploadInputRef.current?.click() : onClick;

  return (
    <Button
      style={{
        minWidth: size || 0,
        height: size,
        width: size,
        borderRadius: 0,
        margin: 0,
        color: _color,
        ...style,
      }}
      onClick={handleClick}
      {...props}
    >
      {uploadFile ? (
        <input
          id="upload-file"
          type="file"
          accept={fileType}
          ref={uploadInputRef}
          onChange={onUploadFile}
          style={{ display: 'none' }}
        />
      ) : null}
      {Icon ? <Icon style={{ fill: _color, width: iconSize, height: iconSize }} /> : null}
    </Button>
  );
};

NavigationButton.propTypes = {
  icon: string.isRequired,
  onClick: func.isRequired,
  size: oneOfType([string, number]),
  style: shape({}),
  color: string,
};

NavigationButton.defaultProps = {
  size: 32,
  style: null,
  color: theme.palette.text.primary,
};
