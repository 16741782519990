import React from 'react';
import { Box } from '@material-ui/core';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './styles.css';
import { Button } from 'legos';
import Image from 'material-ui-image';

class CarouselContainer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      value: 0,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    if (value > this.props.images.length - 1) {
      value = 0;
    } else if (value < 0) {
      value = this.props.images.length - 1;
    }
    this.setState({ value });
    this.myRef.current.scrollIntoView({ inline: 'center', alignToTop: false, block: 'nearest', behavior: 'smooth' });
  }

  handleArrow = param => {
    this.setState(
      () => {
        if (param > this.props.images.length - 1) {
          return { value: 0 };
        } else if (param < 0) {
          return { value: this.props.images.length - 1 };
        }
        return { value: param };
      },
      () =>
        this.myRef.current.scrollIntoView({
          inline: 'center',
          alignToTop: false,
          block: 'nearest',
          behavior: 'smooth',
        })
    );
  };

  clickThumbnail = index => {
    this.setState({ value: index }, () =>
      this.myRef.current.scrollIntoView({
        inline: 'center',
        alignToTop: false,
        block: 'nearest',
        behavior: 'smooth',
      })
    );
  };

  render() {
    return (
      <div className="container-carousel">
        <Carousel
          centered
          fastSwipe
          infinite
          arrows
          value={this.state.value}
          slides={this.props.images.map((image, index) => {
            return (
              <Box className="container-img" display="flex" justifyContent="center" alignItems="center" width="100%">
                <Image
                  style={{ width: '100%', height: '100%', borderLeft: ' 2px solid #EAE8E4', objectFit: 'cover' }}
                  src={image.picture}
                  key={`${image.picture}_${index}`}
                  alt=""
                />
              </Box>
            );
          })}
          onChange={this.onChange}
        />
        <Box
          className="container-dots"
          style={{ marginTop: '-70px', height: 65, overflow: 'hidden' }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flex={1}
        >
          <Button className="custom-arrow-left custom-arrows" onClick={() => this.handleArrow(this.state.value - 1)} />
          <Box display="flex" justifyContent="space-between" width="100%" style={{ overflow: 'hidden' }}>
            <Box display="flex" width={this.props.images.length * 100} height="90px" style={{ overflowX: 'scroll' }}>
              {this.props.images.map((image, index) => (
                <img
                  {...(index === this.state.value ? { ref: this.myRef } : null)}
                  style={{
                    cursor: 'pointer',
                    width: '100px',
                    height: 'auto',
                    border: ' 2px solid #eae8e4',
                    borderRight: 'none',
                    opacity: index === this.state.value ? 1 : 0.5,
                    objectFit: 'cover',
                  }}
                  src={image.picture}
                  key={`${index}-${image.picture}`}
                  alt=""
                  onClick={() => this.clickThumbnail(index)}
                />
              ))}
            </Box>
          </Box>
          <Button className="custom-arrow-right custom-arrows" onClick={() => this.handleArrow(this.state.value + 1)} />
        </Box>
      </div>
    );
  }
}

export { CarouselContainer };
