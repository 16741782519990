import gql from 'graphql-tag';

export const GET_MINIMAL_BID_REST = gql`
  query MinimalBid_REST($input: ID) {
    minimalBid(input: $input) @rest(path: "plum-bid/{args.input.id}/minimal-bid/", method: "GET") {
      minimalBid
      success
      errors
    }
  }
`;
