import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { number, arrayOf, shape, element } from 'prop-types';
import { DASHBOARD } from 'routes';
import { Tabs as MaterialTabs, Tab, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { theme } from 'utils/theme';

const customStyle = withStyles(() => ({
  tabStyle: {
    '& .MuiTab-root': {
      minWidth: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginRight: 48,
      paddingTop: 24,
      paddingBottom: 24,
      fontWeight: 300,
    },
  },

  dashboardTab: {
    '& .MuiTab-root': {
      fontWeight: 300,
      color: 'black',
      opacity: 1,
      paddingLeft: 48,
      paddingRight: 48,
      marginRight: 0,
    },
    '& .Mui-selected': {
      color: theme.palette.purple.light,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.purple.light,
    },
  },

  indicator: {
    '&:hover': {
      color: theme.palette.purple.light,
      opacity: 1,
    },

    '&:focus': {
      color: theme.palette.purple.light,
    },
  },
}));

const TabsComp = forwardRef(({ classes, tabs, startIndex, divider, tabStyle, onTabChange, id }, ref) => {
  const location = useLocation();
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(Math.abs(startIndex) < tabs.length ? Math.abs(startIndex) : 0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    const search = queryString.parse(location.search);
    search.tab = newValue;
    history.replace({
      path: location.pathname,
      search: queryString.stringify(search),
    });
    if (onTabChange) {
      onTabChange(newValue);
    }
  };
  useImperativeHandle(ref, () => ({
    nextTab: () => handleChange(null, tabIndex < tabs.length - 1 ? tabIndex + 1 : tabIndex),
    prevTab: () => handleChange(null, tabIndex > 0 ? tabIndex - 1 : tabIndex),
  }));

  return (
    <>
      <MaterialTabs
        className={`${classes.tabStyle} ${location.pathname === DASHBOARD && classes.dashboardTab}`}
        value={tabIndex}
        onChange={handleChange}
        style={tabStyle}
      >
        {tabs.map((item, index) => (
          <Tab
            className={location.pathname === DASHBOARD ? classes.indicator : ''}
            id={index === 1 ? id : null}
            key={item.tabTitle}
            label={<Typography variant="caption">{item.tabTitle}</Typography>}
            disableRipple
          />
        ))}
      </MaterialTabs>
      {divider}
      {tabs[tabIndex].tabComponent}
    </>
  );
});

TabsComp.propsType = {
  tabs: arrayOf(shape({})).isRequired,
  startIndex: number,
  divider: element,
  tabStyle: shape({}),
};

TabsComp.defaultProps = {
  startIndex: 0,
  divider: null,
  tabStyle: null,
};

export const Tabs = customStyle(TabsComp);
