import React, { useState } from 'react';
import { Box, DialogContent, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Button, PhoneInput } from 'legos';
import { useAuthState } from 'service/store';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const CallMeBackDialog = ({ isOpen, onOk, onClose }) => {
  const classes = useStyles();
  const [{ me }] = useAuthState();
  const [phone, setPhone] = useState(me.phone);

  const changePhone = e => {
    setPhone(e.target.value.replace(/[,\s$]+/g, ''));
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="Call me back" onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4}>
          <PhoneInput label="Is this your number?" value={phone} onClick={changePhone} format="(###) ###-####" />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
          <Button
            title="Cancel"
            variant="secondary"
            style={{ width: '30%', minWidth: 'fit-content' }}
            onClick={onClose}
          />
          <Box width={8} />
          <Button
            title="Confirm"
            style={{ width: '35%', minWidth: 'fit-content' }}
            onClick={() => onOk(phone)}
            disabled={!phone}
            marginRight={3}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
