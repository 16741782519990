import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { ACTIONS, EVENTS } from 'react-joyride';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { SubHeader } from './SubHeader';
import { BidButtonContainer } from './BidButtonContainer';
import { BidInputCard } from './BidInputCard';
import { useSettingsState } from 'service/store';
import { LocalStorage } from 'utils/localStorage';
import { useAuthState } from 'service/store/authStore';
import { SANDBOX_PLUMBID, BID_HISTORY, ADMIN_BID_HISTORY, DASHBOARD, ADMIN_DASHBOARD } from 'routes';
import {
  ROLES,
  DIALOG,
  LOAN_MAX,
  LOAN_MIN,
  APPRAISAL_MAX,
  APPRAISAL_MIN,
  LOAN_MAX_DIFF,
  PLUMBID_STATUS,
  INSPECTION_MAX,
  INSPECTION_MIN,
  NEW_PARTY_STATUS,
  APPRAISAL_MAX_DIFF,
  INSPECTION_MAX_DIFF,
  PROCESSING_STEP_COUNT,
  CASH_DOWN_IMPROVE_MODE,
  MINIMUM_PERCENT_NO_VA_LOAN,
} from 'constant';
import { theme } from 'utils/theme';
import { Tutorial } from 'legos';
import { useAllIncentive } from './useAllImproves';
import { AutoCalculating } from './AutoCalculating';
import { CurrentMinimumBidCard } from './CurrentMinimumBidCard';
import { PropertyDescriptionCard } from './PropertyDescriptionCard';
import { HistoryBids, PageContainer, PlumBidWarningMessage, Footer, OptimizerStep } from 'components';
import { CashDownCard, EscrowCard, ContingencyCard, AdditionalTermsCard } from './IncentiveCard';
import { CREATE_BID_REST, CREATE_SANDBOX_BID_MUTATION, CREATE_SANDBOX_KEY_MUTATION } from 'service/graphql';
import { useNotification, usePlumbid, useDialog, usePageLoading, useTimeLeft, useResponsive } from 'utils/hooks';
import state, { setBidProcessing } from 'service/graphql/state';
import { RedirectPage } from 'components/RedirectPage';

const GET_INITIAL_INCENTIVE = (plumbid, plumbidData) => ({
  plumbidId: plumbid,
  isAutoCalculated: plumbidData?.service?.name === 'fullservice' ?? false,
  cashDownImproveMode: CASH_DOWN_IMPROVE_MODE.PERCENTAGE_DOWN,
  sellerContingency: false,
  buyerWarranty: false,
  buyerContingency: false,
});

export const PlumbidPageContent = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const history = useHistory();
  const [, dispatchSettings] = useSettingsState();
  const [{ me }] = useAuthState();
  const location = useLocation();
  const showNotification = useNotification();


  const [sandboxKey, setSandboxKey] = useState(LocalStorage.getSandboxKey());

  const { plumbid: plumBidId } = queryString.parse(location.search);
  const isSandbox = location.pathname === SANDBOX_PLUMBID;

  const {
    openDialog: openGetSandboxKeyDialog,
    closeDialog: closeGetSandboxKeyDialog,
    changeProps: changePropsGetSandboxKeyDialog,
  } = useDialog(DIALOG.GET_SANDBOX_KEY);

  const {
    openDialog: openReviewAndBidDialog,
    closeDialog: closeReviewAndBidDialog,
    changeProps: changeReviewAndBidDialog,
  } = useDialog(DIALOG.REVIEW_AND_BID);

  const { openDialog: openGetSandboxFinishDialog, closeDialog: closeGetSandboxFinishDialog } = useDialog(
    DIALOG.OK_CANCEL
  );

  const { openDialog: openShowErrorDialog, closeDialog: closeShowErrorDialog } = useDialog(DIALOG.OK_CANCEL);

  const [createSandboxKey, { loading: createSandboxKeyLoading }] = useMutation(CREATE_SANDBOX_KEY_MUTATION);
  const [createBid, { loading: createBidLoading }] = useMutation(
    isSandbox ? CREATE_SANDBOX_BID_MUTATION : CREATE_BID_REST
  );

  const {
    plumbidData,
    bidsHistory,
    minimalBid,
    plumbidLoading,
    minimalBidLoading,
    refetchPlumbidData,
    plumBidError,
  } = usePlumbid(isSandbox, {
    variables: { id: plumBidId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: isSandbox ? 'cache-first' : 'cache-only',
  });

  const {
    errors,
    improve,
    setErrors,
    incentive,
    calculateBid,
    improveLoading,
    cashDownErrors,
    loadingByFields,
    hasCacheDownError,
    showInnerCashDown,
    toggleCashDownMode,
    changeCashIncentive,
    changeIncentiveHandle,
    toggleIsAutoCalculated,
  } = useAllIncentive(
    isSandbox,
    GET_INITIAL_INCENTIVE(plumBidId, plumbidData),
    minimalBid,
    plumbidData,
    sandboxKey,
    me
  );

  usePageLoading(plumbidLoading, 'PlumbidPageContent');
  useTimeLeft(plumbidData);

  const sellerPriorities = plumbidData?.sellerParty?.sellerPriorities;

  const isWinnerSandbox = useMemo(() => {
    if (plumbidData?.status === PLUMBID_STATUS.FINISHED) {
      if (plumbidData?.sbbidSet?.length > 0) {
        if (
          plumbidData?.sbbidSet?.reduce((prev, cur) => {
            if (cur.plumBidAmount > prev.plumBidAmount) {
              return cur;
            }
            return prev;
          })?.owner.user.key === LocalStorage.getSandboxKey()
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, [plumbidData]);

  const PropertyDescriptionMemo = useMemo(
    () => (plumbidData ? <PropertyDescriptionCard property={plumbidData.mls} /> : null),
    [plumbidData]
  );
  const HistoryBidsMemo = useMemo(
    () =>
      plumbidData ? (
        <HistoryBids
          bids={bidsHistory}
          isMeBuyer={plumbidData?.myInfoInPlumBid.role === ROLES.BUYER}
          isSandbox={isSandbox}
          sandboxKey={sandboxKey}
          isWinner={isSandbox ? isWinnerSandbox : plumbidData?.myInfoInPlumBid.isWinner}
          myRole={plumbidData?.myInfoInPlumBid.role}
          myPartyId={plumbidData?.myInfoInPlumBid.myParty?.id}
          isFullService={plumbidData?.service?.name === 'fullservice'}
        />
      ) : null,
    [bidsHistory, plumbidData, isSandbox, sandboxKey, isWinnerSandbox]
  );

  const maxContingency = useCallback(
    (maxDays, minDays, diff) => {
      if (incentive.escrowLength - diff <= maxDays) {
        const max = incentive.escrowLength - diff;
        return minDays > max ? minDays : incentive.escrowLength - diff;
      }
      return maxDays;
    },
    [incentive.escrowLength]
  );

  const isMyPartyBidHighest = useMemo(() => {
    if (isSandbox) {
      return bidsHistory[0]?.owner?.user?.key === sandboxKey;
    } else if (plumbidData?.myInfoInPlumBid?.myParty?.id) {
      return +plumbidData?.lastBid?.partyId === +plumbidData.myInfoInPlumBid.myParty.id;
    }
    return false;
  }, [bidsHistory, isSandbox, plumbidData?.lastBid?.partyId, plumbidData?.myInfoInPlumBid?.myParty?.id, sandboxKey]);

  const isDontAllowBid = plumbidData?.myInfoInPlumBid?.myParty?.onboardingStatus?.pending;

  const nextTo = isSandbox || me.roles.some(role => role === ROLES.ADMIN) ? 'goBack' : 'dashboard';

  const recommendedPercentageDown =
    calculateBid?.calculateBid?.bid?.cashDownPercentage >= 0 ? calculateBid?.calculateBid?.bid.cashDownPercentage : 100;

  const hasContingencyMaxValueError = useMemo(
    () =>
      (incentive.loanContingency &&
        incentive.loanContingencyLength > maxContingency(LOAN_MAX, LOAN_MIN, LOAN_MAX_DIFF)) ||
      (incentive.inspectionContingency &&
        incentive.inspectionContingencyLength > maxContingency(INSPECTION_MAX, INSPECTION_MIN, INSPECTION_MAX_DIFF)) ||
      (incentive.appraisalContingency &&
        incentive.appraisalContingencyLength > maxContingency(APPRAISAL_MAX, APPRAISAL_MIN, APPRAISAL_MAX_DIFF)),
    [
      maxContingency,
      incentive.loanContingency,
      incentive.appraisalContingency,
      incentive.inspectionContingency,
      incentive.loanContingencyLength,
      incentive.appraisalContingencyLength,
      incentive.inspectionContingencyLength,
    ]
  );

  // const currentBidAmount = plumbidData?.lastBid?.plumBidAmount || bidsHistory[0]?.plumBidAmount;

  useEffect(() => {
    if (isSandbox && plumbidData?.status === PLUMBID_STATUS.FINISHED) {
      openGetSandboxFinishDialog({
        onOk: () => {
          dispatchSettings({
            type: 'refreshScreen',
            payload: 'UserSandboxPage',
          });
          closeGetSandboxFinishDialog();

          history.goBack();
        },
        onClose: () => {
          dispatchSettings({
            type: 'refreshScreen',
            payload: 'UserSandboxPage',
          });
          closeGetSandboxFinishDialog();
        },
        okTitle: 'OK',
        hideCancel: true,
        title: 'plumBid finished',
        text: 'This plumBid has expired, please choose another one!',
      });
    }
  }, [
    history,
    isSandbox,
    dispatchSettings,
    plumbidData?.status,
    openGetSandboxFinishDialog,
    closeGetSandboxFinishDialog,
    sandboxKey,
  ]);

  useEffect(() => changePropsGetSandboxKeyDialog({ loading: createSandboxKeyLoading }), [
    changePropsGetSandboxKeyDialog,
    createSandboxKeyLoading,
  ]);

  useEffect(() => changeReviewAndBidDialog({ loading: createBidLoading }), [
    changeReviewAndBidDialog,
    createBidLoading,
  ]);

  useEffect(() => {
    if (!isSandbox) {
      if (plumbidData?.status === PLUMBID_STATUS.FINISHED) {
        history.push({
          pathname: me?.roles.includes(ROLES.ADMIN) ? ADMIN_BID_HISTORY : BID_HISTORY,
          search: `?plumbid=${plumbidData?.id}&t=3`,
        });
      } else if (
        plumbidData?.myInfoInPlumBid.role === ROLES.BUYER &&
        plumbidData?.myInfoInPlumBid.myPartyStatus !== NEW_PARTY_STATUS.COMPLETED
      ) {
        history.push({
          pathname: me?.roles.includes(ROLES.ADMIN) ? ADMIN_DASHBOARD : DASHBOARD,
        });
      }
    }
  }, [
    history,
    me?.roles,
    plumbidData?.id,
    plumbidData?.status,
    isSandbox,
    plumbidData?.myInfoInPlumBid.role,
    plumbidData?.myInfoInPlumBid.myPartyStatus,
  ]);

  const createBidAction = useCallback(
    async sandboxKey => {
      if (isMyPartyBidHighest) {
        return showNotification({
          message: 'You are the highest bidder',
          variant: 'success',
        });
      }

      try {
        setBidProcessing({
          error: false,
          loading: true,
          plumBidId: plumbidData.id,
          bidId: null,
          currentStep: 0,
        });
        const { data } = await createBid({
          variables: {
            input: {
              ...(isSandbox ? { sandboxKey } : null),
              plumbidId: +plumBidId,
              price: incentive.isAutoCalculated ? improve.price : incentive.price,
              amountDown: improve.amountDown,
              percentageDown: improve.percentageDown,
              escrowLength: incentive.escrowLength,
              vaLoan: incentive.vaLoan,
              inspectionContingency: incentive.inspectionContingency,
              inspectionContingencyLength: incentive.inspectionContingencyLength,
              loanContingency: incentive.loanContingency,
              loanContingencyLength: incentive.loanContingencyLength,
              appraisalContingency: incentive.appraisalContingency,
              appraisalContingencyLength: incentive.appraisalContingencyLength,
              offerLeaseBack: incentive.offerLeaseBack,
              buyerWarranty: incentive.buyerWarranty,
              buyerContingency: incentive.buyerContingency,
              sellerContingency: incentive.sellerContingency,
            },
          },
        });
        if (data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.success) {
          setBidProcessing({
            bidId: data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.id,
          });
          await refetchPlumbidData();
        }
        if (data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.errors) {
          state.bidProcessingVar({
            error: true,
            loading: false,
            plumBidId: plumbidData.id,
            currentStep: PROCESSING_STEP_COUNT,
          });
          if (
            data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.errors.some(
              err => err === 'Sandbox key has expired.' || err === 'Wrong sandbox key'
            )
          ) {
            openGetSandboxKeyDialog({
              onOk: values => {
                createSandboxKey({
                  variables: {
                    input: values,
                  },
                })
                  .then(res => {
                    if (res?.data?.sandboxGetKey.success) {
                      setSandboxKey(res?.data?.sandboxGetKey.key);
                      LocalStorage.setSandboxKey(res?.data?.sandboxGetKey.key);
                      createBidAction(res?.data?.sandboxGetKey.key);
                    } else if (res?.data?.sandboxGetKey.errors) {
                      showNotification({ error: res.data.sandboxGetKey.errors });
                    }
                  })
                  .catch(error => showNotification({ error }))
                  .finally(closeGetSandboxKeyDialog);
              },
            });
          } else if (
            data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.errors.some(
              err =>
                err === 'Bid was unsuccessful. Someone already made another one.' ||
                err === 'Bid was unsuccessful. Another bidder recently made the same or bigger one.' ||
                err === 'Someone is bidding right now.'
            )
          ) {
            openShowErrorDialog({
              title: 'Attention',
              text: 'You have been outbid by another bidder.',
              onOk: () => closeShowErrorDialog(),
              hideCancel: true,
              okTitle: 'OK',
            });
          } else {
            showNotification({ error: data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.errors });
          }
        } else {
          showNotification({ error: data[isSandbox ? 'sandboxCreateBid' : 'createBid']?.errors });
        }
      } catch (error) {
        if (error?.message?.includes?.('Received status code 429')) {
          openShowErrorDialog({
            title: 'Attention',
            text: 'You have been outbid by another bidder.',
            onOk: () => closeShowErrorDialog(),
            hideCancel: true,
            okTitle: 'OK',
          });
        } else {
          showNotification({ error });
        }
        setBidProcessing({
          error: true,
          bidId: null,
          loading: false,
          plumBidId: plumbidData.id,
          currentStep: PROCESSING_STEP_COUNT,
        });
      } finally {
        setBidProcessing({
          error: false,
          bidId: null,
          loading: false,
          plumBidId: plumbidData.id,
        });
      }
    },
    [
      createBid,
      isSandbox,
      plumBidId,
      improve.price,
      plumbidData?.id,
      incentive.price,
      showNotification,
      incentive.vaLoan,
      createSandboxKey,
      improve.amountDown,
      refetchPlumbidData,
      isMyPartyBidHighest,
      openShowErrorDialog,
      closeShowErrorDialog,
      incentive.escrowLength,
      improve.percentageDown,
      incentive.buyerWarranty,
      openGetSandboxKeyDialog,
      incentive.offerLeaseBack,
      closeGetSandboxKeyDialog,
      incentive.loanContingency,
      incentive.isAutoCalculated,
      incentive.buyerContingency,
      incentive.sellerContingency,
      incentive.appraisalContingency,
      incentive.inspectionContingency,
      incentive.loanContingencyLength,
      incentive.appraisalContingencyLength,
      incentive.inspectionContingencyLength,
    ]
  );

  const handleCloseReviewAndBidDialog = useCallback(() => {
    closeReviewAndBidDialog();
  }, [closeReviewAndBidDialog]);

  const handleCreateBid = useCallback(() => {
    if (hasContingencyMaxValueError) {
      return;
    }

    if (isSandbox) {
      if (sandboxKey) {
        createBidAction(sandboxKey);
      } else {
        openGetSandboxKeyDialog({
          onOk: values => {
            createSandboxKey({
              variables: {
                input: values,
              },
            })
              .then(res => {
                if (res?.data?.sandboxGetKey.success) {
                  setSandboxKey(res?.data?.sandboxGetKey.key);
                  LocalStorage.setSandboxKey(res?.data?.sandboxGetKey.key);
                  createBidAction(res?.data?.sandboxGetKey.key);
                } else if (res?.data?.sandboxGetKey.errors) {
                  showNotification({ error: res.data.sandboxGetKey.errors });
                }
              })
              .catch(error => showNotification({ error }))
              .finally(closeGetSandboxKeyDialog);
          },
        });
      }
    } else {
      openReviewAndBidDialog({
        title: 'Review and place Bid',
        okTitle: 'OK',
        isDontAllowBid: isDontAllowBid,
        data: {
          price: incentive.isAutoCalculated ? improve.price : incentive.price,
          amountDown: improve.amountDown,
          escrowLength: incentive.escrowLength,
          inspectionContingency: incentive.inspectionContingency,
          inspectionContingencyLength: incentive.inspectionContingencyLength,
          loanContingency: incentive.loanContingency,
          loanContingencyLength: incentive.loanContingencyLength,
          appraisalContingency: incentive.appraisalContingency,
          appraisalContingencyLength: incentive.appraisalContingencyLength,
          offerLeaseBack: incentive.offerLeaseBack,
          buyerContingency: incentive.buyerContingency,
          percentageDown: incentive.percentageDown,
        },
        onClose: handleCloseReviewAndBidDialog,
        onOk: isDontAllowBid
          ? handleCloseReviewAndBidDialog
          : () => {
            handleCloseReviewAndBidDialog();
            createBidAction();
          },
      });
    }
  }, [
    improve,
    incentive,
    isSandbox,
    sandboxKey,
    isDontAllowBid,
    createBidAction,
    createSandboxKey,
    showNotification,
    openReviewAndBidDialog,
    openGetSandboxKeyDialog,
    closeGetSandboxKeyDialog,
    hasContingencyMaxValueError,
    handleCloseReviewAndBidDialog,
  ]);

  const showInsufficientBid =
    !isMyPartyBidHighest && !plumbidLoading && !createBidLoading && +improve.plumBidAmount < Math.round(+minimalBid);

  const percentagePriority = useMemo(
    () =>
      isSandbox
        ? plumbidData?.sbsellerprioritiesSet[0]?.percentage
        : plumbidData?.sellerParty?.sellerPriorities?.percentage,
    [isSandbox, plumbidData?.sbsellerprioritiesSet, plumbidData?.sellerParty?.sellerPriorities?.percentage]
  );

  const showBidButton =
    isSandbox || (plumbidData?.myInfoInPlumBid.role === ROLES.BUYER && plumbidData.status === PLUMBID_STATUS.LIVE);

  const disableBidButton =
    errors ||
    hasCacheDownError ||
    isMyPartyBidHighest ||
    (!isMyPartyBidHighest && +improve.plumBidAmount < Math.round(+minimalBid)) ||
    !isEmpty(loadingByFields) ||
    improveLoading ||
    minimalBidLoading;

  const loadingBidButton = plumbidLoading || createBidLoading;

  // Tutorial
  const optimizerStep = useReactiveVar(state.tutorialOptimizerStepVar);
  // const isActiveTutorial = useReactiveVar(state.tutorialToggleActiveVar);
  const handleJoyrideCallback = useCallback(
    data => {
      const { action, index, type } = data;
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        state.tutorialOptimizerStepVar(index + (action === ACTIONS.PREV ? -1 : 1));
        if (optimizerStep < 3) {
          state.tutorialOptimizerStepVar(optimizerStep + 1);
          LocalStorage.setTutorialOptimizerStep(optimizerStep + 1);
        }
      }
    },
    [optimizerStep]
  );
  const reservedPriceMet = useMemo(
    () => plumbidData?.lastBid && !plumbidData?.reservePriceMet && plumbidData?.service && plumbidData?.service.name === 'fullservice',
    [plumbidData?.lastBid, plumbidData?.reservePriceMet, plumbidData?.service]
  );

  return plumbidData ? (
    <PageContainer>
      <Tutorial
        callback={handleJoyrideCallback}
        // run={isActiveTutorial ? optimizerStep < 3 : false}
        run={false}
        steps={OptimizerStep()}
        stepIndex={optimizerStep}
        disableScrolling
      />
      <SubHeader
        plumbidData={plumbidData}
        nextTo={nextTo}
        plumbidLoading={plumbidLoading}
        isSandbox={isSandbox}
        isWinner={isWinnerSandbox}
        me={me}
      />

      <Box pt={2} mb={2} px={{ xs: 1, md: 3, lg: 4, xl: 6 }}>
        {errors && (
          <Box
            bgcolor={theme.palette.background.tutu}
            py={1}
            mx={mdScreen ? 1 : 0}
            px={2}
            mb={1}
            borderRadius="5px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography style={{ color: theme.palette.text.scarlet }}>{errors}</Typography>
          </Box>
        )}

        <Grid container spacing={mdScreen ? 0 : 2} justify="flex-start">
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              backgroundColor: theme.palette.background.card,
              margin: 8,
              borderRadius: 15,
            }}
            id="tutorial__YouPay"
          >
            <Grid item xs={12} md={6} lg={tabletScreen ? 6 : 3}>
              <Box py={{ xs: 1, md: 1 }} pl={{ xs: 1, lg: 2 }}>

                <CurrentMinimumBidCard
                  plumbidData={plumbidData}
                  bidsHistory={bidsHistory}
                  minimalBid={minimalBid}
                  minimalBidLoading={minimalBidLoading}
                  currentBid={
                    plumbidData?.lastBid?.plumBidAmount || bidsHistory[0]?.plumBidAmount || plumbidData.listPrice
                  }
                />
                {reservedPriceMet && <Box height="40px" mt={4}>
                  <PlumBidWarningMessage defaultText="Reserve price not met" />
                </Box>}
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={tabletScreen ? 6 : 6} container id="tutorial__PayAmount">
              <BidInputCard
                loading={
                  improveLoading || loadingByFields.calculateBidLoading ? improveLoading : !isEmpty(loadingByFields)
                }
                errors={errors}
                limit={improve.limit}
                setErrors={setErrors}
                plumbidData={plumbidData}
                bidsHistory={bidsHistory}
                improvePrice={improve.price}
                incentivePrice={incentive.price}
                effectiveBid={improve.plumBidAmount}
                totalIncentives={improve.totalIncentives}
                changeIncentiveHandle={changeIncentiveHandle}
                isAutoCalculated={incentive.isAutoCalculated}
                toggleIsAutoCalculated={toggleIsAutoCalculated}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={tabletScreen ? 12 : 3}
              container
              direction="column"
              alignItems="center"
              justify="center"
              spacing={3}
              style={{ padding: mdScreen ? '24px 0' : '' }}
            >
              <Box height="30px">
                {showInsufficientBid && !(improveLoading || !isEmpty(loadingByFields)) && (
                  <PlumBidWarningMessage defaultText="Minimum bid not met" />
                )}
              </Box>
              <AutoCalculating
                toggleIsAutoCalculated={toggleIsAutoCalculated}
                isAutoCalculated={incentive.isAutoCalculated}
              />

              {showBidButton && (
                <BidButtonContainer
                  title={isMyPartyBidHighest ? 'You are the highest bidder' : 'Review and Bid'}
                  disabled={disableBidButton}
                  variant={isMyPartyBidHighest ? 'bid' : 'primary'}
                  icon={hasContingencyMaxValueError ? 'MaxValueContingencyWarning' : ''}
                  style={{ width: '70%' }}
                  loading={loadingBidButton}
                  onClick={handleCreateBid}
                  plumbidData={plumbidData}
                  textIcon={
                    incentive.percentageDown > recommendedPercentageDown
                      ? 'Please review your cash down values according to the maximum amounts marked on the boxes'
                      : null
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid container item id="tutorial__OptimizerParameters" spacing={2}>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4}>
              <CashDownCard
                disabled={errors}
                vaLoan={incentive.vaLoan}
                improvePrice={improve.price}
                maxValue={recommendedPercentageDown}
                cashDownErrors={cashDownErrors}
                incentivePrice={incentive.price}
                showInnerCashDown={showInnerCashDown}
                hasCacheDownError={hasCacheDownError}
                improveAmountDown={improve.amountDown}
                toggleCashDownMode={toggleCashDownMode}
                changeCashIncentive={changeCashIncentive}
                incentiveAmountDown={incentive.amountDown}
                loadingCashDown={loadingByFields.cashDown}
                isAutoCalculated={incentive.isAutoCalculated}
                improvePercentageDown={improve.percentageDown}
                sellerPrioritiesValue={percentagePriority?.cash}
                downPaymentImprove={improve.downPaymentIncentive}
                incentivePercentageDown={incentive.percentageDown}
                minimumPercentNoVALoan={MINIMUM_PERCENT_NO_VA_LOAN}
                loading={loadingByFields.cashDown || loadingByFields.all}
                incentiveCashDownImproveMode={incentive.cashDownImproveMode}
              />
            </Grid>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4} style={{ paddingTop: mdScreen && 8 }}>
              <EscrowCard
                disabled={errors}
                escrowLength={incentive.escrowLength}
                improveEscrow={improve.escrowIncentive}
                changeIncentive={changeIncentiveHandle}
                disableSlider={loadingByFields.escrowLength}
                sellerPrioritiesValue={percentagePriority?.escrow}
                idealEscrowLength={sellerPriorities?.idealEscrowLength}
                loading={loadingByFields.escrowLength || loadingByFields.all}
              />
            </Grid>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4} style={{ paddingTop: mdScreen && 8 }}>
              <ContingencyCard
                disabled={errors}
                minDays={INSPECTION_MIN}
                maxDays={INSPECTION_MAX}
                key="Inspection Contingency"
                name="Inspection Contingency"
                field="inspectionContingency"
                value={incentive.inspectionContingency}
                changeIncentive={changeIncentiveHandle}
                improve={improve.inspectionContingencyIncentive}
                valueDays={incentive.inspectionContingencyLength}
                sellerPrioritiesValue={percentagePriority?.inspection}
                sellerPrioritiesTitle="Inspection Contingency Priority"
                disableSlider={loadingByFields.inspectionContingencyLength}
                improveByLength={improve.inspectionContingencyLengthIncentive}
                maxValue={maxContingency(INSPECTION_MAX, INSPECTION_MIN, INSPECTION_MAX_DIFF)}
                loading={
                  loadingByFields.inspectionContingency ||
                  loadingByFields.inspectionContingencyLength ||
                  loadingByFields.all
                }
              />
            </Grid>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4} style={{ paddingTop: mdScreen && 8 }}>
              <ContingencyCard
                disabled={errors}
                minDays={LOAN_MIN}
                maxDays={LOAN_MAX}
                key="Loan Contingency"
                name="Loan Contingency"
                field="loanContingency"
                value={incentive.loanContingency}
                changeIncentive={changeIncentiveHandle}
                improve={improve.loanContingencyIncentive}
                valueDays={incentive.loanContingencyLength}
                sellerPrioritiesValue={percentagePriority?.loan}
                sellerPrioritiesTitle="Loan Contingency Priority"
                disableSlider={loadingByFields.loanContingencyLength}
                improveByLength={improve.loanContingencyLengthIncentive}
                maxValue={maxContingency(LOAN_MAX, LOAN_MIN, LOAN_MAX_DIFF)}
                loading={
                  loadingByFields.loanContingency || loadingByFields.loanContingencyLength || loadingByFields.all
                }
              />
            </Grid>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4} style={{ paddingTop: mdScreen && 8 }}>
              <ContingencyCard
                disabled={errors}
                minDays={APPRAISAL_MIN}
                maxDays={APPRAISAL_MAX}
                key="Appraisal Contingency"
                name="Appraisal Contingency"
                field="appraisalContingency"
                value={incentive.appraisalContingency}
                changeIncentive={changeIncentiveHandle}
                improve={improve.appraisalContingencyIncentive}
                valueDays={incentive.appraisalContingencyLength}
                sellerPrioritiesValue={percentagePriority?.appraisal}
                sellerPrioritiesTitle="Appraisal Contingency Priority"
                disableSlider={loadingByFields.appraisalContingencyLength}
                improveByLength={improve.appraisalContingencyLengthIncentive}
                maxValue={maxContingency(APPRAISAL_MAX, APPRAISAL_MIN, APPRAISAL_MAX_DIFF)}
                loading={
                  loadingByFields.appraisalContingency ||
                  loadingByFields.appraisalContingencyLength ||
                  loadingByFields.all
                }
              />
            </Grid>
            <Grid item xs={12} lg={tabletScreen ? 6 : 4} style={{ paddingTop: mdScreen && 8 }}>
              <AdditionalTermsCard
                disabled={errors}
                valueOfferLeaseBack={incentive.offerLeaseBack}
                valueOfferLeaseBackImprove={improve.leaseBackIncentiveDisplay}
                changeIncentive={changeIncentiveHandle}
                sellerPrioritiesValue={percentagePriority?.cop}
                loading={loadingByFields.offerLeaseBack || loadingByFields.all}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} style={{ paddingTop: mdScreen && 8 }} id="tutorial__BidHistory">
            {HistoryBidsMemo}
          </Grid>
          <Grid item container xs={12} style={{ paddingTop: mdScreen && 8 }}>
            {PropertyDescriptionMemo}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </PageContainer>
  ) : plumBidError && !plumbidData ? (
    <RedirectPage />
  ) : null;
};
