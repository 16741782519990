import { Typography, Grid } from '@material-ui/core';
import moment from 'moment';

import { formatUserFullName } from 'utils/formatters';
import { useSelectChat, useMarkChatAsRead } from 'utils/hooks';
import { CHAT_STATUS } from 'constant';
import { AvatarUserInitials, PBox } from 'legos';
import { useAuthState } from 'service/store/authStore';
import { theme } from 'utils/theme';

export const ChatItem = ({ chat, selectLight }) => {
  const [{ me }] = useAuthState();
  const { markChatAsRead } = useMarkChatAsRead();
  const { selectedChat, setSelectedChat } = useSelectChat();

  const setSelectedChatHandle = () => {
    if (chat.status === CHAT_STATUS.UNREAD) {
      markChatAsRead({ variables: { input: { chatName: chat.name } } }).catch(() => {});
    }
    setSelectedChat(chat.id);
  };

  const isSelected = selectedChat === chat.id;

  return (
    <PBox
      pl={0}
      py={2}
      pr={2}
      onClick={setSelectedChatHandle}
      bgcolor={
        isSelected
          ? theme.palette.primary.main
          : selectLight
          ? theme.palette.background.card
          : theme.palette.background.paper
      }
      display="relative"
      style={{ cursor: 'pointer' }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <PBox position="relative">
            <AvatarUserInitials fullName={chat.owner.fullName} />
            {chat.status === CHAT_STATUS.UNREAD && chat.lastMessage.owner.id !== me.id && (
              <div
                style={{
                  position: 'absolute',
                  top: 3,
                  left: 20,
                  backgroundColor: theme.palette.background.sundown,
                  height: 15,
                  width: 15,
                  borderRadius: '50%',
                }}
              />
            )}
          </PBox>
        </Grid>
        <Grid item xs={7} style={{ marginBottom: 8 }} zeroMinWith>
          <PBox pb={1}>
            <Typography
              variant="h4"
              style={{ color: isSelected ? theme.palette.common.white : theme.palette.text.primary }}
            >
              {chat.owner.fullName}
            </Typography>
          </PBox>
          <Typography
            variant="h4"
            style={{
              color: isSelected ? theme.palette.transparentWhite.white87 : theme.palette.transparentBlack.black47,
            }}
          >
            {chat.lastMessage.owner.id === me.id ? 'You:' : formatUserFullName(chat.lastMessage.owner)}
          </Typography>

          <Typography
            variant="body2"
            noWrap
            style={{
              fontWeight: 400,
              maxHeight: '59px',
              color: isSelected ? theme.palette.transparentWhite.white67 : theme.palette.transparentBlack.black67,
            }}
          >
            {chat.lastMessage.message}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="h6"
            align="right"
            style={{ color: isSelected ? theme.palette.common.white : theme.palette.text.primary }}
          >
            {chat.lastMessage.createdAt.isSame(moment(), 'day')
              ? chat.lastMessage.createdAt.format('hh:mm')
              : chat.lastMessage.createdAt.format('DD MMM')}
          </Typography>
        </Grid>
      </Grid>
    </PBox>
  );
};
