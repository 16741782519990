import { NEW_PARTICIPANT_STATUS, NEW_PARTY_STATUS, ONBOARDING_STATUS, PLUMBID_STATUS } from 'constant';
import { theme } from 'utils/theme';

export const getBuyerWarningMessage = participant => {
  if (participant.status === NEW_PARTICIPANT_STATUS.PENDING) {
    return {
      text: 'Not invited',
      variant: 'warning',
      color: theme.palette.text.warning,
    };
  } else if (participant.status === NEW_PARTICIPANT_STATUS.INVITED) {
    return {
      text: 'Invited',
      variant: 'info',
      color: theme.palette.text.summerSky,
    };
  } else if (participant.onboardingStatus?.[ONBOARDING_STATUS.INCORRECT]) {
    return {
      text: 'Incorrect Offer',
      variant: 'error',
      color: theme.palette.text.scarlet,
    };
  } else if (participant.status === NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING) {
    if (
      !participant.onboardingStatus?.[ONBOARDING_STATUS.INCORRECT] &&
      !participant.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH] &&
      !participant.onboardingStatus?.[ONBOARDING_STATUS.PENDING]
    ) {
      return {
        text: 'Onboarding in Progress',
        variant: 'warning',
        color: theme.palette.text.warning,
      };
    } else {
      if (participant.onboardingStatus?.[ONBOARDING_STATUS.PENDING]) {
        return {
          text: 'Pending',
          variant: 'error',
          color: theme.palette.text.scarlet,
        };
      } else if (participant.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH]) {
        return {
          text: 'Mismatch',
          variant: 'warning',
          color: theme.palette.text.warning,
        };
      }
      return {
        text: '',
        variant: '',
        color: '',
      };
    }
  }
  if (participant.backUpPosition) {
    return {
      text: 'Backup Position',
      variant: 'warning',
      color: theme.palette.text.warning,
    };
  }
  return {
    text: 'Completed',
    variant: 'success',
    color: theme.palette.background.highBid,
  };
};

export const getBuyerPartyWarningMessage = (party, plumBidStatus) => {
  if (plumBidStatus === PLUMBID_STATUS.FINISHED) {
    if (party.isWinner) {
      return {
        text: 'Winner',
        variant: 'success',
        color: theme.palette.background.highBid,
      };
    }
    if (party.backUpPosition) {
      return {
        text: 'Backup Position Requested',
        variant: 'warning',
        color: theme.palette.text.warning,
      };
    }
    if (party.status === NEW_PARTY_STATUS.COMPLETED) {
      return {
        text: 'Did not win',
        variant: 'warning',
        color: theme.palette.text.warning,
      };
    }
    if (party.status !== NEW_PARTY_STATUS.COMPLETED) {
      return {
        text: 'Did not participate',
        variant: 'error',
        color: theme.palette.text.scarlet,
      };
    }
  }

  if (party.status === NEW_PARTY_STATUS.WAITING_FOR_ONBOARDING) {
    if (party.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH]) {
      return {
        text: 'Mismatch',
        variant: 'warning',
        color: theme.palette.text.warning,
      };
    } else if (party.onboardingStatus?.[ONBOARDING_STATUS.INCORRECT]) {
      return {
        text: 'Incorrect offer',
        variant: 'error',
        color: theme.palette.text.scarlet,
      };
    } else if (party.onboardingStatus?.[ONBOARDING_STATUS.PENDING]) {
      return {
        text: 'Pending',
        variant: 'error',
        color: theme.palette.text.scarlet,
      };
    }
    return {
      text: 'Onboarding in Progress',
      variant: 'warning',
      color: theme.palette.text.warning,
    };
  } else if (party.status === NEW_PARTY_STATUS.WAITING_FOR_PARTICIPANTS) {
    return {
      text: 'Waiting for Participants',
      variant: 'warning',
      color: theme.palette.text.warning,
    };
  }
  return {
    text: 'Completed',
    color: theme.palette.background.highBid,
    variant: 'success',
  };
};

export const getSellerPartyWarningMessage = party => {
  const messages = [];
  if (party.status === NEW_PARTY_STATUS.COMPLETED) {
    messages.push({
      text: 'Onboarding Completed',
      variant: 'success',
      color: theme.palette.background.highBid,
    });
  } else if (party.status === NEW_PARTY_STATUS.WAITING_FOR_PARTICIPANTS) {
    messages.push({
      text: 'Waiting for Sellers',
      variant: 'warning',
      color: theme.palette.text.warning,
    });
  } else if (party.status === NEW_PARTY_STATUS.WAITING_FOR_ONBOARDING) {
    if (!party.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH]) {
      messages.push({
        text: 'Waiting for Seller Preferences',
        variant: 'warning',
        color: theme.palette.text.warning,
      });
    } else {
      if (party.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH]) {
        messages.push({
          text: 'Mismatch of Answers',
          variant: 'warning',
          color: theme.palette.text.warning,
        });
      }
    }
  }
  return messages;
};

export const getSellerWarningMessage = participant => {
  if (participant.status === NEW_PARTICIPANT_STATUS.PENDING) {
    return {
      text: 'Not Invited',
      variant: 'warning',
      color: theme.palette.text.warning,
    };
  } else if (participant.status === NEW_PARTICIPANT_STATUS.INVITED) {
    return {
      text: 'Invited',
      variant: 'Info',
      color: theme.palette.text.summerSky,
    };
  }
  if (participant.status === NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING) {
    if (participant.onboardingStatus?.[ONBOARDING_STATUS.REVIEW]) {
      return {
        text: 'Review Seller Answers',
        variant: 'error',
        color: theme.palette.text.scarlet,
      };
    } else if (
      !participant.onboardingStatus?.[ONBOARDING_STATUS.REVIEW] &&
      !participant.onboardingStatus?.[ONBOARDING_STATUS.MISMATCH]
    ) {
      return {
        text: 'Onboarding in Progress',
        variant: 'warning',
        color: theme.palette.text.warning,
      };
    } else {
      if (participant.onboardingStatus[ONBOARDING_STATUS.MISMATCH]) {
        return {
          text: 'Mismatch',
          variant: 'warning',
          color: theme.palette.text.warning,
        };
      }
    }
  }
  if (participant.status === NEW_PARTICIPANT_STATUS.COMPLETED) {
    return {
      text: 'Onboarding Completed',
      variant: 'success',
      color: theme.palette.background.highBid,
    };
  }
};
