import React from 'react';
import { shape } from 'prop-types';

export const Calendar = ({ style }) => (
  <svg width="12" height="13" viewBox="0 0 12 13" style={style}>
    <path d="M11 1H9V0H8V1H4V0H3V1H1C0.734885 1.00033 0.480723 1.10579 0.293259 1.29326C0.105794 1.48072 0.000330774 1.73488 0 2V12C0.000330774 12.2651 0.105794 12.5193 0.293259 12.7067C0.480723 12.8942 0.734885 12.9997 1 13H11C11.2651 12.9997 11.5193 12.8942 11.7067 12.7067C11.8942 12.5193 11.9997 12.2651 12 12V2C11.9997 1.73488 11.8942 1.48072 11.7067 1.29326C11.5193 1.10579 11.2651 1.00033 11 1ZM1 2H3V3H4V2H8V3H9V2H11V4H1V2ZM1 5H3.5V8H1V5ZM7.5 12H4.5V9H7.5V12ZM7.5 8H4.5V5H7.5V8ZM8.5 12V9H11L11.0006 12H8.5Z" />
  </svg>
);

Calendar.propTypes = {
  style: shape({}),
};

Calendar.defaultProps = {
  style: null,
};
