import React from 'react';
import { useSubscription, useMutation } from '@apollo/client';

import { CallMeBackButton } from 'legos';
import { useDialog, useGetMe, useNotification } from 'utils/hooks';
import { useSettingsState, useAuthState } from 'service/store';
import { ON_NEW_ADMIN_CHAT_MESSAGE_SUBSCRIPTION, CALL_ME_BACK_MUTATION } from 'service/graphql';
import { DIALOG, ROLES } from 'constant';

export const CallMeBack = ({ alignByParent }) => {
  const [{ hasNewChatMessages, drawerScreen }, dispatchSettings] = useSettingsState();
  const [callMeBackMutation] = useMutation(CALL_ME_BACK_MUTATION);
  const { openDialog: openCallMeBackDialog, closeDialog: closeCallMeBackDialog } = useDialog(DIALOG.CALL_ME_BACK);
  const [{ me }] = useAuthState();
  const { fetchMe } = useGetMe();
  const isAdmin = me?.roles?.includes(ROLES.ADMIN);

  const showNotification = useNotification();
  useSubscription(ON_NEW_ADMIN_CHAT_MESSAGE_SUBSCRIPTION, {
    shouldResubscribe: true,
    variables: {
      chatName: `admin_chat_${me?.userId}_${me?.email}`,
    },
    skip: !me?.userId,
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        subscriptionData?.data?.onNewAdminChatMessage?.owner?.userId !== +me.id &&
        drawerScreen !== 'UserChatScreen'
      ) {
        showNotification({
          message: 'New chat message',
          variant: 'success',
        });
        dispatchSettings({ type: 'hasNewChatMessages', payload: true });
      }
    },
  });

  const openChat = () => {
    const openUserChatScreen = () => {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: {
          isOpenDrawer: true,
          drawerScreen: 'UserChatScreen',
        },
      });
    };

    if (!me.callMeBackPhone) {
      openCallMeBackDialog({
        onOk: phone => {
          callMeBackMutation({ variables: { input: { phone } } })
            .then(() => {
              fetchMe();
              openUserChatScreen();
            })
            .catch(() => {})
            .finally(() => closeCallMeBackDialog());
        },
        onClose: () => {
          closeCallMeBackDialog();
          openUserChatScreen();
        },
      });
    } else {
      openUserChatScreen();
    }
  };

  return me?.userId && !isAdmin ? (
    <CallMeBackButton plumbidId={null} onClick={openChat} hasNewMessage={hasNewChatMessages} />
  ) : null;
};
