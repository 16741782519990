import React from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import { Typography } from '@material-ui/core';
import '@brainhubeu/react-carousel/lib/style.css';
import './styles.css';
import { theme } from 'utils/theme';

export class CarouselText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      slides: this.props.content.map(item => (
        <Typography variant="body1" style={{ color: theme.palette.common.white, fontSize: 13, lineHeight: '18px' }}>
          {item.text}
        </Typography>
      )),
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  render() {
    return (
      <div>
        <Carousel value={this.state.value} slides={this.state.slides} onChange={this.onchange} />
        <Dots value={this.state.value} onChange={this.onchange} number={this.state.slides.length} />
      </div>
    );
  }
}
