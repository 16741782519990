import React from 'react';
import { shape } from 'prop-types';

export const QuestionMark = ({ style }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke={style?.stroke ?? 'black'}
      strokeWidth="1.8"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      d="M7.38098 7.3C7.59258 6.6985 8.01022 6.1913 8.55994 5.86822C9.10966 5.54514 9.75599 5.42704 10.3844 5.53484C11.0129 5.64264 11.5829 5.96937 11.9936 6.45718C12.4042 6.94498 12.6289 7.56237 12.628 8.2C12.628 10 9.92798 10.9 9.92798 10.9"
      stroke={style?.stroke ?? 'black'}
      strokeWidth="1.8"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      d="M10 14.5H10.01"
      stroke={style?.stroke ?? 'black'}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

QuestionMark.propTypes = {
  style: shape({}),
};

QuestionMark.defaultProps = {
  style: null,
};
