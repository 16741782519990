import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import Box from '@material-ui/core/Box';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { Divider, Input, Button, LinkButton } from 'legos';
import { setSession } from 'service/auth';
import { ACCEPT_INVITE_MUTATION, LOGIN_MUTATION } from 'service/graphql';
import { useAuthState, useSettingsState } from 'service/store';
import state from 'service/graphql/state';
import { useNotification } from 'utils/hooks';
import { normalizeUser } from 'utils';
import { PropertyThumbnailNameAddress } from 'components';
import { DASHBOARD, ADMIN_DASHBOARD, HOME } from 'routes';
import { LISTING_AGENT_VERIFY_STATUS, ROLES } from 'constant';
import { theme } from 'utils/theme';

const SignInValidationSchema = object().shape({
  email: string().trim().required('Email is required').email('Invalid email').nullable(),
  password: string().trim().required('Password is required').nullable(),
});

export const SignInScreen = ({ withInvite }) => {
  const [{ redirectPath }, dispatchAuth] = useAuthState();
  const [{ invite }, dispatchSettings] = useSettingsState();
  const [loading, setLoading] = useState(false);
  const [acceptInvite] = useMutation(ACCEPT_INVITE_MUTATION);
  const history = useHistory();
  const showNotification = useNotification();

  const getSaveUserFields = useReactiveVar(state.signUpFormVar);

  sessionStorage.setItem('inv', history.location.pathname + history.location.search);

  const redirect = user => {
    if (redirectPath !== null) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
      dispatchAuth({
        type: 'redirectPath',
        payload: null,
      });
      history.push(redirectPath.path);
    } else if (user.roles.includes(ROLES.ADMIN)) {
      setLoading(false);
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
      history.push(ADMIN_DASHBOARD);
    } else {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
      if (
        user.roles.includes(ROLES.LISTING_AGENT) &&
        (!user.isVerifiedAgent || user?.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.REJECTED)
      ) {
        history.push(HOME);
      } else {
        history.push(DASHBOARD);
      }
    }
  };

  const openForgotPasswordScreen = () =>
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: 'ForgotPasswordScreen',
        drawerScreenGoBack: 'SignInScreen',
      },
    });

  const openSignUpScreen = () =>
    dispatchSettings({
      type: 'toggleAuthDrawer',
      payload: {
        isOpenDrawer: true,
        drawerScreen: withInvite && invite ? 'SignUpFromInvitationScreen' : 'SignUpScreen',
        drawerScreenGoBack: withInvite && invite ? 'SignInFromInvitationScreen' : 'SignInScreen',
      },
    });

  const [signInMutation] = useMutation(LOGIN_MUTATION);

  const onSubmit = async values => {
    try {
      setLoading(true);
      const {
        data: { tokenAuth },
      } = await signInMutation({
        variables: {
          email: values.email,
          password: values.password,
          ...(withInvite && invite ? { inviteToken: invite.token } : null),
        },
      });
      withInvite &&
        invite &&
        (await acceptInvite({
          variables: {
            input: {
              token: invite.token,
              accept: true,
            },
          },
        }));
      setSession(tokenAuth);
      const user = normalizeUser(tokenAuth.user);

      dispatchAuth({
        type: 'init',
        payload: user,
      });

      setTimeout(() => {
        sessionStorage.removeItem('inv');

        redirect(user);
      }, 1000);
    } catch (error) {
      setLoading(false);
      showNotification({ error });
    }
  };

  return (
    <Formik
      initialValues={{
        email: getSaveUserFields?.email
          ? getSaveUserFields.email
          : withInvite
          ? invite.email
          : redirectPath?.email ?? '',
        password: '',
      }}
      validationSchema={SignInValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Box display="flex" flexDirection="column" p={3.75}>
            {withInvite && invite ? (
              <Box border={`1px solid ${theme.palette.divider}`} p={1}>
                <PropertyThumbnailNameAddress property={invite.plumbid.mls} withPrice />
              </Box>
            ) : (
              <Typography variant="h3" style={{ fontWeight: 800 }}>
                I already have an account
              </Typography>
            )}
            <Box mt={4} mb={2}>
              <Input
                autoFocus
                label="Email"
                type="email"
                value={values.email}
                onChange={event => setFieldValue('email', event.target.value)}
                error={touched.email && errors.email}
              />
            </Box>
            <Input
              label="Password"
              type="password"
              value={values.password}
              onChange={event => setFieldValue('password', event.target.value)}
              error={touched.password && errors.password}
            />
            <LinkButton
              title="Forgot password?"
              variant="h6"
              color="primary"
              onClick={openForgotPasswordScreen}
              style={{
                marginTop: 6,
                marginBottom: 13,
                textDecoration: 'underline',
              }}
            />
            <Button title="Sign in" type="submit" icon="RightArrow" loading={loading} />
            {!invite?.userExists ? (
              <>
                <Divider
                  style={{
                    marginBottom: 48,
                    marginTop: 48,
                    marginLeft: -48,
                    marginRight: -48,
                  }}
                />

                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 800,
                    marginBottom: 20,
                  }}
                >
                  I don’t have an account
                </Typography>
                <Typography variant="subtitle1" noWrap={false} style={{ marginBottom: 24, maxWidth: '80%' }}>
                  If you are a listing agent, buyer, or seller please create a secure account to utilize the innovative
                  plumBid platform.
                </Typography>
                <Button title="Create an account" icon="Add" onClick={openSignUpScreen} />
              </>
            ) : null}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
