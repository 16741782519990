import { useState } from 'react';

import { AppBar, Grid, Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Logo, RouterLink } from 'legos';
import { Drawer } from 'components';
import { AuthNav } from './AuthNav';
import { HeaderContent } from './HeaderContent';
import { HeaderMobile } from './HeaderMobile';
import { useReactiveVar } from '@apollo/client';
import state from 'service/graphql/state';
import { CallMeBack } from 'components/CallMeBack';
import { useInitials, useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';
import { useLocation } from 'react-router-dom';
import { ADMIN } from 'routes';

const useAppBarStyle = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid',
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
  },
}));

export const Header = ({ withoutAuth }) => {
  useInitials();
  const pageLoading = Object.values(useReactiveVar(state.pageLoadingVar) ?? {}).some(val => val);
  const { pathname } = useLocation();
  const isAdmin = pathname.includes(ADMIN);

  const { padScreen } = useResponsive();

  const classesAppBar = useAppBarStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderHeaderMobile = () => (
    <>
      <Grid item container xs justify="flex-end">
        <CallMeBack />
      </Grid>
      <Grid item container xs={2} justify="flex-end">
        <HeaderMobile
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
          style={{
            borderLeft: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box onClick={handleClose}>
            <HeaderContent />
          </Box>
          <Box pt={2}>
            <AuthNav />
          </Box>
        </HeaderMobile>
      </Grid>
    </>
  );

  return (
    <AppBar className={classesAppBar.root} position="sticky" elevation={0} id="general-header">
      <Grid
        container
        direction="row"
        style={{
          maxWidth: 1440,
          margin: '0 auto',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: padScreen ? 15 : 48,
          paddingRight: padScreen ? 0 : 48,
        }}
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={1}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Grid>

        {!withoutAuth ? (
          padScreen ? (
            <Grid item container xs={11} justify="flex-end">
              {renderHeaderMobile()}
            </Grid>
          ) : (
            <Grid item container xs={11} direction="row" spacing={3} justify="flex-end" alignItems="center">
              <Grid
                container
                item
                xs={7}
                md={isAdmin ? 10 : 'auto'}
                lg={isAdmin ? 8 : 6}
                spacing={1}
                justify="flex-end"
                alignItems="center"
              >
                <HeaderContent />
              </Grid>
              {!isAdmin && (
                <Grid item xs="auto">
                  <CallMeBack />
                </Grid>
              )}
              <AuthNav />
            </Grid>
          )
        ) : null}
      </Grid>

      <Box style={{ height: 4, width: '100%' }}>{pageLoading ? <LinearProgress /> : null}</Box>
      <Drawer />
    </AppBar>
  );
};
