import React from 'react';
import { shape } from 'prop-types';

export const House = ({ style }) => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M1 6.95L8.65 1L16.3 6.95V16.3C16.3 16.7509 16.1209 17.1833 15.8021 17.5021C15.4833 17.8209 15.0509 18 14.6 18H2.7C2.24913 18 1.81673 17.8209 1.49792 17.5021C1.17911 17.1833 1 16.7509 1 16.3V6.95Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.09991 18V9.5H11.1999V18"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

House.propTypes = {
  style: shape({}),
};

House.defaultProps = {
  style: null,
};
