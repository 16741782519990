import gql from 'graphql-tag';

export const CONFIRM_SELLER_ANSWERS_MUTATION = gql`
  mutation ConfirmSellerAnswersMutation($input: ConfirmSellerAnswersInput!) {
    confirmSellerAnswers(input: $input) {
      success
      errors
    }
  }
`;
