import gql from 'graphql-tag';

export const SEND_AGENT_EMAIL_MUTATION = gql`
  mutation SendAgentEmailMutation($input: SendAgentEmailInput!) {
    sendAgentEmail(input: $input) {
      success
      errors
    }
  }
`;
