import { useState } from 'react';
import { NEW_PARTICIPANT_STATUS } from 'constant';

import { PBox } from 'legos';
import { ParticipantItem } from './ParticipantItem';
import { ParticipantForm } from './ParticipantForm';

export const ParticipantDetailsItem = ({
  index,
  loading,
  getLink,
  inputError,
  participant,
  inputWarning,
  setInputError,
  plumBidLoading,
  newParticipant,
  setInputWarning,
  isButtonDisabled,
  setIsOfferChanged,
  deleteParticipant,
  idEditingParticipant,
  saveParticipantChange,
  participantStatusProps,
  setIdEditingParticipant,
}) => {
  const [actionsButtonsPosition, setActionsButtonsPosition] = useState(false);

  const handleClose = () => {
    deleteParticipant(participant.id);
  };

  const handleEdit = () => {
    setIsOfferChanged(true);
    setIdEditingParticipant(participant.id);
  };

  const isParticipantEdited = idEditingParticipant !== null && idEditingParticipant !== participant.id;

  const disableEditing = isParticipantEdited || isButtonDisabled || loading || participant.exist;

  const disableDeleteAndCopy = isParticipantEdited || isButtonDisabled || loading;

  const disableEditingField =
    participant.status === NEW_PARTICIPANT_STATUS.COMPLETED ||
    participant.status === NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING ||
    participant.status === NEW_PARTICIPANT_STATUS.INVITED ||
    disableDeleteAndCopy;

  return (
    <PBox py={2} pl={2} width="100%" display="flex">
      {participant.id === idEditingParticipant ? (
        <ParticipantForm
          index={index}
          loading={loading}
          inputError={inputError}
          participant={participant}
          inputWarning={inputWarning}
          setInputError={setInputError}
          plumBidLoading={plumBidLoading}
          disableEditing={disableEditing}
          setInputWarning={setInputWarning}
          draftParticipant={newParticipant}
          isButtonDisabled={isButtonDisabled}
          disableDelete={disableDeleteAndCopy}
          setIsOfferChanged={setIsOfferChanged}
          disableEditingField={disableEditingField}
          saveParticipantChange={saveParticipantChange}
          participantStatusProps={participantStatusProps}
          actionsButtonsPosition={actionsButtonsPosition}
          setIdEditingParticipant={setIdEditingParticipant}
          setActionsButtonsPosition={setActionsButtonsPosition}
        />
      ) : (
        <ParticipantItem
          getLink={getLink}
          handleEdit={handleEdit}
          participant={participant}
          handleClose={handleClose}
          inputWarning={inputWarning}
          isButtonDisabled={isButtonDisabled}
          disableDelete={disableDeleteAndCopy}
          actionsButtonsPosition={actionsButtonsPosition}
          participantStatusProps={participantStatusProps}
          disableEditingField={disableEditingField || newParticipant?.length > 0}
        />
      )}
    </PBox>
  );
};
