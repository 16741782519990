import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { PageContainer, HomePageContainer } from 'components';
import { HOME, CREATE_PASSWORD } from 'routes';
import { CHECK_RESET_PASSWORD_LINK_MUTATION } from 'service/graphql';
import { formatGraphqlError } from 'utils/formatters';
import { removeSession } from 'service/auth';
import state from 'service/graphql/state';
import { useAuthState, useSettingsState } from 'service/store';
import { usePageLoading, useURLQuery } from 'utils/hooks';
import { Icons } from 'legos';
import { theme } from 'utils/theme';

export const ResetPasswordPage = () => {
  const [, dispatchAuth] = useAuthState();
  const [, dispatchSettings] = useSettingsState();
  const history = useHistory();
  const { token, uidb64, email, invite } = useURLQuery();

  const [checkResetToken, { loading, error, data, called }] = useMutation(CHECK_RESET_PASSWORD_LINK_MUTATION);

  usePageLoading(loading, 'ResetPasswordPage');

  const location = useLocation();

  const isCreatePassword = location.pathname.search(CREATE_PASSWORD) > -1;

  useEffect(() => {
    if (token && uidb64) {
      dispatchAuth({
        type: 'logout',
      });
      removeSession();
      checkResetToken({
        variables: {
          input: {
            token,
            uid: uidb64,
          },
        },
      })
        .then(res => {
          if (res?.data?.checkResetPasswordLink?.success) {
            history.replace(HOME);
            dispatchSettings({
              type: 'toggleAuthDrawer',
              payload: {
                resetPasswordToken: token,
                resetPasswordUidb64: uidb64,
                isOpenDrawer: true,
                drawerScreen: 'ResetPasswordScreen',
                drawerScreenGoBack: 'SignInScreen',
                createAdminPassword: isCreatePassword,
              },
            });
          }
        })
        .catch(() => {
          dispatchSettings({
            type: 'toggleAuthDrawer',
            payload: {
              resetPasswordToken: null,
              resetPasswordUidb64: null,
              isOpenDrawer: false,
            },
          });
        });
    }
  }, [token, uidb64, checkResetToken, dispatchAuth, dispatchSettings, history, isCreatePassword, email]);

  useEffect(() => {
    if (email) {
      state.signUpFormVar({ email: email.replace(' ', '+'), inviteToken: invite });
    }
  }, [email, invite]);

  const isError = data?.checkResetPasswordLink?.errors?.length || error || !token || !uidb64;

  return (
    <PageContainer>
      {called && !loading && isError ? (
        <Grid container justify="center" alignItems="center" spacing={4} style={{ margin: 'auto 0' }}>
          <Grid item>
            <Icons.TimeInvite />
          </Grid>
          <Grid item>
            {data?.checkResetPasswordLink?.errors?.length &&
              data.checkResetPasswordLink.errors.map(err => (
                <Typography
                  variant="h1"
                  paragraph
                  style={{ textTransform: 'uppercase', color: theme.palette.text.primary }}
                >
                  {err}
                </Typography>
              ))}
            {error && (
              <Typography variant="h3" paragraph color="textSecondary">
                {formatGraphqlError(error)}
              </Typography>
            )}
            {(!token || !uidb64) && (
              <Typography variant="h3" paragraph color="textSecondary">
                Invalid link
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <HomePageContainer />
      )}
    </PageContainer>
  );
};
