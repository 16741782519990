import React from 'react';
import { Box, DialogContent, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { Button, Input } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';
import { useAgentListingRole } from 'utils/hooks/useAgentListingRole';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
});

const ValidationSchema = object({
  dreId: string()
    .min(1)
    .max(12, 'The number of characters must not exceed 12')
    .matches(/^\d+$/, 'DRE ID can only contain numbers'),
});

export const AddDreIdDialog = ({ isOpen, onClose, dreId }) => {
  const classes = useStyles();

  const { addAgentListingRole, addAgentListingRoleLoading } = useAgentListingRole();

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      dreId,
    },

    validationSchema: ValidationSchema,

    onSubmit: values => {
      addAgentListingRole({
        variables: {
          input: {
            dreId: values.dreId,
          },
        },
      }).then(() => onClose());
    },
  });

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="Apply as Listing Agent" onClose={onClose} />
      <DialogContent>
        <Box my={3}>
          <Input
            autoFocus
            name="dreId"
            label="DRE ID"
            value={values.dreId}
            onChange={handleChange}
            error={touched.dreId && errors.dreId}
          />
        </Box>

        <Box py={5} display="flex">
          <Button style={{ width: '40%' }} variant="secondary" title="Cancel" align="center" onClick={onClose} />
          <Box component="span" pr={3} />
          <Button
            title="Submit"
            style={{ width: '60%' }}
            onClick={handleSubmit}
            align="center"
            loading={addAgentListingRoleLoading}
            disabled={!values.dreId || (touched.dreId && errors.dreId)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
