import React from 'react';
import { List, Box, Typography, Grid } from '@material-ui/core';

import { PlumBidItem } from './PlumBidItem';
import { Icons } from 'legos';
import { theme } from 'utils/theme';

export const NewPlumBidsList = ({ newPlumbids }) => {
  return (
    <Box p={3.5} style={{ backgroundColor: theme.palette.background.card }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Icons.SquerePlus />
            <Typography
              variant="h5"
              style={{
                paddingLeft: '12px',
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              New Plumbids
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            px={1}
            py={0.5}
            style={{
              backgroundColor: theme.palette.background.card,
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.text.primary}`,
            }}
          >
            <Typography
              variant="h4"
              style={{
                maxHeight: '28px',
              }}
            >
              Last Week
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <List>
        {!newPlumbids.length && (
          <Box pt={4}>
            <Typography>There are no new plumbids</Typography>
          </Box>
        )}
        {newPlumbids.map(item => (
          <PlumBidItem property={item} key={item.id} />
        ))}
      </List>
    </Box>
  );
};
