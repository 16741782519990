import gql from 'graphql-tag';

import { ExtendedUserType } from '../types';

export const GET_DASHBOARD_ADMIN_QUERY = gql`
  query AdminDashboard {
    adminDashboard {
      livePlumbids  {
        id
        totalBidders
        readyBidders
        timeLeft {
          timeLeft
        }
        notifyOnParsed
        owner {
          userId
          fullName
          phone
          email
        }
        active
        createdAt
        startDateTime
        finishDateTime
        listPrice
        status

        plumbidpartySet {
          id
          isDuplicate
          party
          isWinner
          status
          onboardingStatus
        }
        mls {
          id
          mlsId
          title
          fullDescription
          previewImage
          listingPrice
          dateTaken
          country
          zipCode
          state
          city
          address
          latitude
          longitude
          propertySize
          lotSize
          numberOfBedrooms
          numberOfBathrooms
          yearBuilt
          mlsinfophotoSet {
            picture
          }
        }
    }
    newPlumbids {
      id
      totalBidders
      readyBidders
      timeLeft {
        timeLeft
      }
      notifyOnParsed
      owner {
        userId
        fullName
        phone
        email
      }
      active
      createdAt
      startDateTime
      finishDateTime
      listPrice
      status

      plumbidpartySet {
        id
        isDuplicate
        party
        isWinner
        status
        onboardingStatus
      }
      mls {
        id
        mlsId
        title
        fullDescription
        previewImage
        listingPrice
        dateTaken
        country
        zipCode
        state
        city
        address
        latitude
        longitude
        propertySize
        lotSize
        numberOfBedrooms
        numberOfBathrooms
        yearBuilt
        mlsinfophotoSet {
          picture
        }
      }
      }
       joinedListingAgents {
        ${ExtendedUserType}
      }
      newPlumbidsCount
      completePlumbidsCount
      usersJoined
    }
  }
`;
