import React from 'react';
import { shape } from 'prop-types';

export const Login = ({ style }) => (
  <svg
    version="1.0"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    style={style}
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2380 5114 c-19 -2 -78 -9 -130 -14 -330 -36 -695 -160 -990 -336
  -375 -224 -680 -529 -904 -904 -175 -292 -291 -632 -338 -990 -16 -123 -16
  -497 0 -620 82 -623 356 -1150 820 -1581 256 -239 575 -425 922 -539 274 -91
  491 -124 800 -124 228 0 329 9 530 50 689 141 1304 583 1674 1204 175 292 291
  632 338 990 16 123 16 497 0 620 -47 358 -163 698 -338 990 -224 375 -529 680
  -904 904 -289 173 -634 291 -980 336 -88 12 -438 21 -500 14z m385 -304 c583
  -54 1146 -347 1517 -790 487 -581 652 -1337 452 -2067 -77 -281 -213 -550
  -398 -785 -34 -43 -63 -78 -66 -78 -3 0 -19 43 -35 96 -85 284 -283 589 -512
  790 -144 126 -341 247 -518 319 l-40 16 35 26 c63 47 216 208 253 266 142 221
  202 460 177 704 -37 366 -251 681 -575 850 -674 350 -1488 -91 -1565 -850 -20
  -197 18 -404 106 -579 71 -141 189 -287 305 -375 27 -20 49 -40 49 -43 0 -3
  -33 -18 -73 -34 -270 -109 -540 -321 -729 -571 -109 -145 -213 -349 -264 -520
  -15 -52 -31 -95 -34 -95 -8 0 -122 148 -179 233 -63 94 -174 310 -219 425 -78
  198 -127 427 -144 675 -52 717 271 1445 839 1898 459 366 1041 542 1618 489z
  m5 -860 c257 -73 458 -274 536 -535 35 -119 37 -289 6 -406 -93 -347 -395
  -579 -752 -579 -357 0 -659 232 -752 579 -31 117 -29 287 6 406 88 296 316
  497 636 559 58 11 247 -3 320 -24z m-5 -1851 c310 -40 584 -178 821 -414 178
  -178 290 -358 362 -585 26 -81 67 -271 59 -275 -1 -1 -31 -24 -67 -52 -308
  -240 -679 -394 -1095 -454 -116 -17 -454 -17 -570 0 -416 60 -787 214 -1095
  454 -36 28 -66 51 -67 52 -2 1 4 39 12 84 91 517 461 950 961 1124 221 77 431
  98 679 66z"
      />
    </g>
  </svg>
);

Login.propTypes = {
  style: shape({}),
};

Login.defaultProps = {
  style: null,
};
