import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { formatAddress } from 'utils';

export const PropertyDescription = ({ property }) => {
  return (
    <Grid container direction="column">
      <Typography variant="h1" style={{ textTransform: 'uppercase', marginTop: 50 }} paragraph>
        {formatAddress(property || {})}
      </Typography>

      <Typography variant="subtitle1" paragraph align="justify" style={{ marginTop: 20, marginBottom: 40 }}>
        {property.fullDescription}
      </Typography>
      <Typography variant="caption" style={{ textTransform: 'uppercase', marginBottom: 30 }} paragraph>
        On Map
      </Typography>
    </Grid>
  );
};
