import React from 'react';
import { shape } from 'prop-types';

export const ArrowAccept = ({ style }) => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M17 0.5L6 11.5L1 6.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

ArrowAccept.propTypes = {
  style: shape({}),
};

ArrowAccept.defaultProps = {
  style: null,
};
