import { isNil } from 'lodash';
import { useMutation } from '@apollo/client';
import React, { useState, useCallback, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { DIALOG, PLUMBID_STATUS } from 'constant';
import { PartyItem } from './PartyItem';
import { PartyDetails } from './PartyDetails';
import { REMOVE_OFFER_MUTATION } from 'service/graphql';
import { useNotification, useDialog, useResponsive } from 'utils/hooks';
import { useAuthState } from 'service/store';
import { MoveableFrame, PBox } from 'legos';
import { UploadZipFormsOffer } from '../Step2/UploadZipFormsOffer';
import { getProcessingText } from '../helpers';
import { ProcessingBar } from './ProcessingBar';
import { OfferSkeleton } from './OfferSkeleton';

export const Step5 = ({
  plumBid,
  readOnly,
  fileUploading,
  selectedParty,
  plumBidLoading,
  plumBidRefetch,
  processingInfo,
  handleUploadFile,
  setSelectedParty,
  countUploadOffer,
  uploadingProgress,
  setIsOfferChanged,
  displayOnlyOneStep,
  closeOkCancelDialog,
  setCountUploadOffer,
  defaultSelectedParty,
  createInvitedMutation,
  isOpenSendEmailFinishDialog,
  setIsOpenSendEmailFinishDialog,
}) => {
  const [{ me }] = useAuthState();
  const showNotification = useNotification();
  const { bigTabletScreen } = useResponsive();
  const [editMode, setEditMode] = useState(false);
  const [idEditingParticipant, setIdEditingParticipant] = useState(null);

  const isDuplicateOfferPlumBid = plumBid.buyerParty?.find(item => item.isDuplicate);

  const [removeOffer] = useMutation(REMOVE_OFFER_MUTATION);

  const {
    openDialog: openDeleteOfferDialog,
    closeDialog: closeDeleteOfferDialog,
    changeProps: changePropsDeleteOfferDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const { openDialog: openOfferDuplicateDialog, closeDialog: closeOfferDuplicateDialog } = useDialog(
    DIALOG.OFFER_DUPLICATE
  );

  const removeSelectOffer = useCallback(
    async id => {
      setSelectedParty(
        () => defaultSelectedParty?.id || plumBid?.buyerParty?.find(party => isNil(party?.offerdoc?.progress))?.id
      );
      return removeOffer({
        variables: {
          input: { offerDocId: id },
        },
      })
        .then(({ data }) => {
          if (data?.removeOffer?.errors) {
            showNotification({ error: data?.removeOffer.errors });
          }
        })
        .catch(error => showNotification({ error }))
        .finally(() => {
          plumBidRefetch().finally(() => {
            setCountUploadOffer(prev => (prev > 0 ? prev - 1 : 0));
            setSelectedParty(null);
          });
        });
    },
    [
      removeOffer,
      plumBidRefetch,
      setSelectedParty,
      showNotification,
      plumBid?.buyerParty,
      setCountUploadOffer,
      defaultSelectedParty?.id,
    ]
  );

  useEffect(() => {
    if (
      !displayOnlyOneStep &&
      processingInfo.countProcessingFiles > 0 &&
      !isOpenSendEmailFinishDialog &&
      !plumBid.notifyOnParsed
    ) {
      const timer = setTimeout(() => {
        closeOkCancelDialog();
        setIsOpenSendEmailFinishDialog(true);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [
    me,
    processingInfo,
    displayOnlyOneStep,
    closeOkCancelDialog,
    plumBid.notifyOnParsed,
    isOpenSendEmailFinishDialog,
    setIsOpenSendEmailFinishDialog,
  ]);

  useEffect(() => {
    if (isDuplicateOfferPlumBid) {
      openOfferDuplicateDialog({
        offer: isDuplicateOfferPlumBid,
        onOk: () =>
          removeSelectOffer(isDuplicateOfferPlumBid.offerdoc.id).finally(() => {
            setTimeout(() => closeOfferDuplicateDialog(), 0);
          }),
      });
    }
  }, [isDuplicateOfferPlumBid, closeOfferDuplicateDialog, openOfferDuplicateDialog, removeSelectOffer]);

  useEffect(() => {
    if (!selectedParty && plumBid?.buyerParty.length > 0 && !processingInfo.isRunProcessing && !fileUploading) {
      setSelectedParty(plumBid?.buyerParty[0]?.id);
    }
  }, [fileUploading, plumBid?.buyerParty, processingInfo.isRunProcessing, selectedParty, setSelectedParty]);

  const onSelect = useCallback(
    (party, fakeProcessing) => {
      if ((fakeProcessing === 0 || fakeProcessing >= 99) && !isNil(party?.offerdoc?.parseEndedAt) && !editMode) {
        setSelectedParty(party.id);
        setIdEditingParticipant(null);
      }
    },
    [editMode, setSelectedParty]
  );

  const handleDeleteParty = useCallback(
    party =>
      openDeleteOfferDialog({
        title: 'Attention',
        text: 'Are you sure you want to remove this offer?',
        okTitle: 'OK',
        onOk: () => {
          changePropsDeleteOfferDialog({ loading: true });
          removeSelectOffer(party.offerdoc.id).finally(() => {
            changePropsDeleteOfferDialog({ loading: false });
            closeDeleteOfferDialog();
          });
        },
      }),
    [closeDeleteOfferDialog, openDeleteOfferDialog, removeSelectOffer, changePropsDeleteOfferDialog]
  );

  const isDisabled =
    editMode || plumBid.status === PLUMBID_STATUS.CANCELED || plumBid.status === PLUMBID_STATUS.FINISHED;

  if (plumBidLoading && !plumBid) {
    return (
      <Box width="100%" p={2} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const renderPartyList = () => (
    <>
      {isDisabled ? (
        plumBid?.buyerParty.map(party => {
          return (
            <PartyItem
              party={party}
              key={party.id}
              editMode={editMode}
              onSelect={onSelect}
              isSelectedParty={selectedParty === party.id}
              deleteParty={handleDeleteParty}
              displayOnlyOneStep={displayOnlyOneStep}
              isButtonDisabled={isDisabled}
              plumBidStatus={plumBid.status}
              processingInfo={processingInfo}
              selectedParty={selectedParty}
              setCountUploadOffer={setCountUploadOffer}
            />
          );
        })
      ) : (
        <UploadZipFormsOffer
          plumBidRefetch={plumBidRefetch}
          onUploadFile={handleUploadFile}
          plumBid={plumBid}
          loading={uploadingProgress !== 100}
          onSelect={onSelect}
        >
          {plumBid?.buyerParty.map(party => (
            <PartyItem
              party={party}
              key={party.id}
              editMode={editMode}
              onSelect={onSelect}
              isSelectedParty={selectedParty === party.id}
              deleteParty={handleDeleteParty}
              displayOnlyOneStep={displayOnlyOneStep}
              isButtonDisabled={isDisabled}
              plumBidStatus={plumBid.status}
              processingInfo={processingInfo}
              selectedParty={selectedParty}
              setCountUploadOffer={setCountUploadOffer}
            />
          ))}
        </UploadZipFormsOffer>
      )}
    </>
  );

  return (
    <>
      {!bigTabletScreen && (
        <MoveableFrame parentId="wizard-footer" title={getProcessingText(processingInfo, countUploadOffer)}>
          {renderPartyList()}
        </MoveableFrame>
      )}
      <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
        Review Offers
      </Typography>
      {bigTabletScreen && (
        <PBox py={1}>
          <ProcessingBar processingInfo={processingInfo} countUploadOffer={countUploadOffer} />
          {renderPartyList()}
        </PBox>
      )}
      {selectedParty ? (
        <PartyDetails
          plumBid={plumBid}
          selectedParty={selectedParty}
          plumBidRefetch={plumBidRefetch}
          plumBidLoading={plumBidLoading}
          setIsOfferChanged={setIsOfferChanged}
          createInvitedMutation={createInvitedMutation}
          uploadFiles={handleUploadFile}
          fileUploading={fileUploading}
          displayOnlyOneStep={displayOnlyOneStep}
          isButtonDisabled={isDisabled}
          setEditMode={setEditMode}
          readOnly={readOnly}
          idEditingParticipant={idEditingParticipant}
          setIdEditingParticipant={setIdEditingParticipant}
        />
      ) : (
        <Box mt={4}>
          <OfferSkeleton />
        </Box>
      )}
    </>
  );
};
