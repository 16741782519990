import React from 'react';
import { List, Box, Typography } from '@material-ui/core';

import { PlumBidItem } from './PlumBidItem';
import { Icons } from 'legos';
import { theme } from 'utils/theme';

export const LivePlumBidsList = ({ livePlumBids }) => {
  return (
    <Box p={3.5} style={{ backgroundColor: theme.palette.background.card }}>
      <Box mb={3.5} display="flex" alignItems="center">
        <Icons.HouseArrow />
        <Typography
          variant="h5"
          style={{
            paddingLeft: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          Live Plumbids
        </Typography>
      </Box>
      <List>
        {!livePlumBids.length && (
          <Box pt={4}>
            <Typography>There are no live plumbids</Typography>
          </Box>
        )}
        {livePlumBids.map(item => (
          <PlumBidItem property={item} key={item.id} />
        ))}
      </List>
    </Box>
  );
};
