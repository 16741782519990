import { forwardRef, useEffect } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { IconButton, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import { Formik, Form } from 'formik';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { useDialog, useNotification } from 'utils/hooks';
import { Icons } from 'legos';
import { PlumbidInfo } from './PlumbidInfo';
import { WizardPropertyThumbnail } from '../WizardPropertyThumbnail';
import { WizardPropertyThumbnailPlaceholder } from '../WizardPropertyThumbnailPlaceholder';
import { DIALOG } from 'constant';
import { theme } from 'utils/theme';

const validateDateRange = finishDate =>
  finishDate?.isBetween(
    moment().set('minute', Math.floor(moment().get('minute') / 15) * 15 + 14),
    moment().add(7, 'd').set('hour', 23).set('minute', 59).set('second', 59)
  );

export const Step4 = forwardRef(({ plumBid, currentStep, setPlumbidDates, setIsNextDisabled, responsive }, ref) => {
  const showNotification = useNotification();
  const { closeDialog, openDialog } = useDialog(DIALOG.OK_CANCEL);
  useEffect(() => {
    if (ref?.current?.values?.finishDate) {
      setIsNextDisabled(!validateDateRange(ref?.current?.values?.finishDate));
    }
    return () => setIsNextDisabled(false);
  }, [ref, ref?.current?.values?.finishDate, setIsNextDisabled, currentStep]);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        finishDate: plumBid?.finishDateTime
          ? moment(plumBid?.finishDateTime)
          : moment()
              .add(1, 'day')
              .set('minute', Math.floor(moment().add(1, 'day').get('minute') / 15) * 15 + 15),
      }}
      onSubmit={values => {
        return new Promise(resolve => {
          if (values.finishDate.isBefore(moment().add(1, 'day'))) {
            openDialog({
              onOk: () => {
                setPlumbidDates({
                  variables: { input: { plumbidId: plumBid.id, finishDateTime: values.finishDate } },
                })
                  .then(({ data }) => resolve(data.setPlumbidDates.success))
                  .catch(() => {
                    showNotification({
                      message: 'Something went wrong',
                      variant: 'error',
                    });
                    resolve(false);
                  })
                  .finally(closeDialog);
              },
              onCancel: () => {
                closeDialog();
                resolve(false);
              },
              okTitle: 'Yes',
              title: 'plumBid finish date ',
              text: 'plumBid will end in less then 24 hours. Do you want to continue?',
            });
          } else {
            setPlumbidDates({
              variables: { input: { plumbidId: plumBid.id, finishDateTime: values.finishDate } },
            })
              .then(({ data }) => resolve(data.setPlumbidDates.success))
              .catch(() => {
                showNotification({
                  message: 'Something went wrong',
                  variant: 'error',
                });
                resolve(false);
              });
          }
        });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Typography variant="h1" style={{ textTransform: 'uppercase', marginBottom: 20 }}>
            Pick your plumBid date
          </Typography>
          <Grid container direction="row" spacing={4} justify="space-between">
            {responsive.padScreen && (
              <Grid item xs={12} md={8} lg={7}>
                {plumBid?.mls ? (
                  <WizardPropertyThumbnail plumBid={plumBid} currentStep={currentStep} />
                ) : (
                  <WizardPropertyThumbnailPlaceholder />
                )}
              </Grid>
            )}
            <Grid item xs={12} lg={8}>
              <Grid container item xs={12}>
                <PlumbidInfo />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={responsive.mobileScreen ? 12 : 4}>
                  <Box mt={2}>
                    <Typography variant="caption">plumBid ends at</Typography>
                  </Box>
                </Grid>
              </Grid>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={responsive.mobileScreen ? 12 : true} md={4}>
                    <DatePicker
                      fullWidth
                      variant="dialog"
                      format="MM/DD/yyyy"
                      id="date"
                      orientation="portrait"
                      minDate={moment().add(1, 'minute')}
                      maxDate={moment().add(7, 'day')}
                      value={values.finishDate}
                      onChange={date => {
                        setIsNextDisabled(!validateDateRange(date));
                        setFieldValue('finishDate', date);
                      }}
                      inputVariant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Icons.CalendarNew />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!validateDateRange(values.finishDate) && (
                      <Box pt={0.5}>
                        <Typography style={{ color: theme.palette.error.main }}>
                          Not earlier than now and later then 7 days from now
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={responsive.mobileScreen ? 12 : true} md={4}>
                    <TimePicker
                      orientation="portrait"
                      fullWidth
                      id="time"
                      value={values.finishDate}
                      onChange={date => {
                        setIsNextDisabled(!validateDateRange(date));
                        setFieldValue('finishDate', date);
                      }}
                      inputVariant="filled"
                      minutesStep={15}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Icons.Clock />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            {!responsive.padScreen && (
              <Grid item lg={4}>
                {plumBid?.mls ? (
                  <WizardPropertyThumbnail plumBid={plumBid} currentStep={currentStep} />
                ) : (
                  <WizardPropertyThumbnailPlaceholder />
                )}
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
});
