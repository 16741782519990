import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_USER_DETAILS_QUERY } from 'service/graphql';

import { useNotification } from './useNotification';
import { normalizePlumBid } from 'utils/normalizePlumBid';

export const useUserDetails = me => {
  const { id } = useParams();
  const showNotification = useNotification();
  const { data, refetch, loading } = useQuery(GET_USER_DETAILS_QUERY, {
    variables: {
      userId: id,
    },
    onError: error => showNotification({ error }),
  });

  useEffect(() => {
    if (data?.userDetails?.errors) {
      showNotification({ error: data?.userDetails?.errors });
    }
  }, [data?.userDetails?.errors, showNotification]);

  const userDetails = data?.userDetails || [];
  const userPlumBids =
    data?.userDetails?.participantSet?.edges.map(edge => {
      return { plumBid: normalizePlumBid(edge.node.plumbid, false, me), role: edge.node.role };
    }) || [];

  return {
    userDetails,
    usersDetailsLoading: loading,
    userPlumBids,
    refetchUser: refetch,
  };
};
