import gql from 'graphql-tag';

export const GET_MY_PLUMBIDS = gql`
  query PlumbidByUser($plumbidStatus: [Int]) {
    plumbidByUser(plumbidStatus: $plumbidStatus) {
      id
      isSellerPartyReady
      buyerPartiesCount
      buyerPartiesReady
      totalBidders
      readyBidders
      timeLeft {
        timeLeft
      }
      active
      service {
        id
        name
      }
      createdAt
      finishDateTime
      startDateTime
      openingPrice
      reservedPrice
      reservePriceMet
      listPrice
      status
      isAdministrating
      plumbidpartySet {
        id
        isDuplicate
        party
        isWinner
        status
        onboardingStatus
        buyerPriorities {
          offerContingent
          offerContingentSecondary
        }
        offerdoc {
          offerFile
          filename
          offerFileUrl
          id
          progress
          error
        }
        offersettings {
          id
          agreementContingent
          offerPrice
          offerPriceIsPrecise
          offerPriceImage
          propertyAddress
          propertyAddressIsPrecise
          cashdownAmount
          cashdownAmountIsPrecise
          escrowLength
          escrowLengthIsPrecise
          isVaLoan
          isFhaLoan
          inspectionContingency
          inspectionContingencyIsPrecise
          inspectionContingencyLength
          inspectionContingencyLengthIsPrecise
          loanContingency
          loanContingencyIsPrecise
          loanContingencyLength
          loanContingencyLengthIsPrecise
          appraisalContingency
          appraisalContingencyIsPrecise
          appraisalContingencyLength
          appraisalContingencyLengthIsPrecise
          offerLeaseBack
          offerLeaseBackIsPrecise
          offerLeaseBackLength
          offerLeaseBackLengthIsPrecise
          offerLeaseBackPricePerDay
          offerLeaseBackPricePerDayIsPrecise
        }
        contingencyRemoved
        secondaryContingencyRemoved
        sellerPriorities {
          id
          offerContingent
          offerContingentSecondary
          amountDown
          escrowPriority
          inspectionPriority
          loanContingencyPriority
          appraisalContingencyPriority
          leaseBackLength
          sellerContingencyPriority
          buyerWarrantyPriority
          idealEscrowLength
          percentage {
            cash
            escrow
            inspection
            loan
            appraisal
            leaseback
            cop
          }
        }
        participants {
          edges {
            node {
              id
              role
              active
              backUpPosition
              popupStatus {
                id
                backUpPosition
                pending
                plumBidStarted
                timeLeftIncrease
                newBidReceived
                finishDateHasChanged
                reservePriceMetChanged
              }
              onboardingStatus {
                mismatch
                review
                incorrectOffer
                pending
              }
              preferences {
                id
                amountDown
                escrowPriority
                inspectionPriority
                loanContingencyPriority
                appraisalContingencyPriority
                leaseBackLength
                sellerContingencyPriority
                buyerWarrantyPriority
                percentage {
                  cash
                  escrow
                  inspection
                  loan
                  appraisal
                  leaseback
                  cop
                }
              }
              user {
                userId
                email
                fullName
              }
              offerParticipant {
                id
                fullName
                email
              }
              status
              invite {
                id
                fullName
                createdAt
                acceptedAt
                token
                email
                active
                link
                status
              }
            }
          }
        }
      }
      mls {
        id
        mlsId
        title
        fullDescription
        previewImage
        listingPrice
        dateTaken
        country
        zipCode
        state
        city
        address
        latitude
        longitude
        propertySize
        lotSize
        numberOfBedrooms
        numberOfBathrooms
        yearBuilt
      }
      lastBid {
        id
        partyId
        owner {
          user {
            userId
            fullName
          }
        }
      }
    }
  }
`;
