import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';

import { Icons, WarningBlock } from 'legos';
import { getParticipantField, getSellerWarningMessage } from 'utils';
import { theme } from 'utils/theme';

export const SellerItem = ({ isSelectedSeller, onSelectSeller, seller }) => {
  const color = isSelectedSeller ? theme.palette.primary.main : theme.palette.text.primary;
  const bgcolor = isSelectedSeller ? theme.palette.background.whiteSmoke : theme.palette.background.paper;
  const warningMessage = useMemo(() => getSellerWarningMessage(seller), [seller]);

  return (
    <Box
      px={2}
      height={44}
      display="flex"
      borderRadius={4}
      bgcolor={bgcolor}
      alignItems="center"
      justifyContent="space-between"
      style={{ cursor: isSelectedSeller ? 'default' : 'pointer' }}
      onClick={() => onSelectSeller(seller)}
    >
      <Box display="flex" alignItems="center" pl={1.25} flex={1}>
        <Icons.UserAvatarBlack style={{ fill: color, width: 19, height: 24 }} />
        <Box ml={1}>
          <Typography variant="h5" style={{ color: color, textOverflow: 'clip' }}>
            {getParticipantField(seller, 'fullName')}
          </Typography>
        </Box>
      </Box>
      {warningMessage && <WarningBlock variant={warningMessage.variant} text={warningMessage.text} />}
    </Box>
  );
};
