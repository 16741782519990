import React from 'react';
import { shape } from 'prop-types';

export const GoBack = ({ style }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, fill: 'none' }}
  >
    <path d="M26 14H2" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 26L2 14L14 2" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

GoBack.propTypes = {
  style: shape({}),
};

GoBack.defaultProps = {
  style: null,
};
