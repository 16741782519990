import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { theme } from 'utils';
import { PBox, Icons, RouterLink } from 'legos';
import { SETTINGS, AGENTS } from 'routes';
import { useAuthState } from 'service/store/authStore';
import { LISTING_AGENT_VERIFY_STATUS, ROLES } from 'constant';
import { USER_HAS_SEEN_VERIFICATION_MUTATION } from 'service/graphql';
import { Footer } from 'components';

export const ListingAgentVerification = () => {
  const history = useHistory();
  const [{ me }] = useAuthState();
  const isAgent = me?.roles?.includes(ROLES.LISTING_AGENT);

  const [isVerifiedAgent, setIsVerifiedAgent] = useState(false);

  const [userHasSeenVerification] = useMutation(USER_HAS_SEEN_VERIFICATION_MUTATION);

  const changeUserVerification = useCallback(
    async values => {
      return userHasSeenVerification({
        variables: {
          input: { isSeenVerification: values },
        },
      })
        .then(({ data }) => {
          if (data?.removeOffer.success) {
          }
        })
        .catch(error => console.log({ error }));
    },
    [userHasSeenVerification]
  );

  useEffect(() => {
    if (
      me?.roles.length === 1 &&
      isAgent &&
      !me?.isSeenVerification &&
      (me?.isVerifiedAgent || me.listingAgentStatus !== LISTING_AGENT_VERIFY_STATUS.REJECTED)
    ) {
      changeUserVerification(false);

      if (me?.isVerifiedAgent) {
        setIsVerifiedAgent(true);
      }
      if (me.isSeenVerification) {
        history.push(AGENTS);
      } else {
        setTimeout(() => {
          history.push(AGENTS);
          changeUserVerification(true);
        }, 10000);
      }
    }
  }, [
    history,
    me?.roles,
    me?.isVerifiedAgent,
    me.listingAgentStatus,
    me.isSeenVerification,
    changeUserVerification,
    isAgent,
  ]);

  if (me?.isSeenVerification) {
    return null;
  }

  return (
    <>
      {isAgent && me?.roles.length === 1 ? (
        <PBox
          p={2}
          mb={1}
          height="48px"
          display="flex"
          borderRadius="15px"
          alignItems="center"
          justifyContent="center"
          bgcolor={theme.palette.background.wrapper}
          border={`1px solid ${theme.palette.border.wrapper}`}
        >
          {isVerifiedAgent ? (
            <Typography variant="h5" style={{ lineHeight: '24px' }}>
              Hold on a few seconds while we approve your account. Please check your email to receive notice that your
              account is ready.
            </Typography>
          ) : LISTING_AGENT_VERIFY_STATUS.REJECTED === me.listingAgentStatus ? (
            <Typography variant="h5" style={{ verticalAlign: 'top', lineHeight: '24px' }}>
              Your Listing Agent application was rejected. You can reapply from{' '}
              <RouterLink style={{ color: theme.palette.text.primary }} to={SETTINGS} Your profile page>
                Your profile page.
              </RouterLink>
            </Typography>
          ) : (
            <Typography variant="h5" style={{ lineHeight: '24px' }}>
              Admin will have to check your DRE License number, please expect an email.
            </Typography>
          )}
        </PBox>
      ) : null}
      {isVerifiedAgent ? (
        <PBox display="flex" justifyContent="center" pt={10}>
          <Icons.Completed style={{ width: 100, height: 100 }} />
        </PBox>
      ) : null}
      {isAgent && me?.roles.length === 1 ? (
        <PBox style={{ position: 'fixed', bottom: 0 }}>
          <Footer />
        </PBox>
      ) : null}
    </>
  );
};
