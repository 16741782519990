import React, { createContext, useContext, useReducer } from 'react';
import { LocalStorage } from 'utils/localStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const initialState = {
    loggedIn: !!LocalStorage.getFrontToken(),
    me: null,
    meLoading: false,
    redirectPath: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'init':
        return {
          ...state,
          me: action.payload,
          loggedIn: true,
        };
      case 'login':
        return {
          ...state,
          me: null,
          loggedIn: true,
        };
      case 'logout':
        return {
          ...state,
          me: null,
          loggedIn: false,
        };
      case 'meLoading':
        return {
          ...state,
          meLoading: action.payload,
        };
      case 'redirectPath':
        return {
          ...state,
          redirectPath: action.payload,
        };
      default:
        return state;
    }
  };

  return <AuthContext.Provider value={useReducer(reducer, initialState)}>{children}</AuthContext.Provider>;
};

export const useAuthState = () => useContext(AuthContext);
