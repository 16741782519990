import gql from 'graphql-tag';

import { ChatMessageType } from '../types';

export const ON_ADMIN_CHAT_SUBSCRIPTION = gql`
  subscription OnAdminChat($room: String) {
    onAdminChat(room: $room) {
      room
      chat {
        id
        name
        status
        owner {
          id
          fullName
          callMeBackPhone
        }
        lastMessage {
          ${ChatMessageType}
        }
      }
    }
  }
`;
