import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, DialogContent, Dialog, Typography } from '@material-ui/core';

import { Button } from 'legos';
import { DialogHeader } from '../Header/DialogHeader';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}));

export const MakeRevisionOfferDialog = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogHeader title="OFFER CORRECTION" onClose={onClose} />
      <DialogContent>
        <Box mx={1} mt={1} mb={2} pb={4}>
          <Typography variant="h5" style={{ lineHeight: 1.5 }}>
            We have received the correction on your current offer. Please notify your agent of this revision. Thank you.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={1} mb={2}>
          <Button align="center" title="OK" style={{ width: 160 }} onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
