import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import { OfferCard } from '../SellerOnboardingForm/OfferCard';
import { DialogHeader } from '../Header/DialogHeader';

const CustomDialog = withStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 0,
    },
  },
}))(Dialog);

export const TopOfferDialog = ({ offer, onClose, isOpen, plumBid }) => {
  if (!offer) {
    return null;
  }

  return (
    <CustomDialog open={isOpen} onClose={onClose} fullWidth maxWidth="md" scroll="body">
      <DialogHeader title="Offer Summary" onClose={onClose} />
      <OfferCard offer={offer} onClose={onClose} plumBid={plumBid} />
    </CustomDialog>
  );
};
