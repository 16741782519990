import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { get } from 'lodash';
import { Box, Grid, Radio, Typography, RadioGroup, FormControlLabel, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { DIALOG, NEW_PARTY_STATUS, ONBOARDING_STATUS, PARTY_KIND, PLUMBID_STATUS, ROLES } from 'constant';
import { Footer } from './Footer';
import { OfferCard } from './OfferCard';
import state from 'service/graphql/state';
import { formatUserFullName, formatUserNameChain } from 'utils/formatters';
import { Input, Button, TopOfferButton, Icons, PBox, Checkbox } from 'legos';
import { useDialog, useURLQuery, usePageLoading, useNotification, useResponsive } from 'utils/hooks';
import { getParticipantField } from 'utils';
import { theme } from 'utils/theme';
import { AnswerActions } from './AnswerActions';
import { QuestionTitleItem } from './QuestionTitleItem';

const exceptionRuleOnSaleContingency = 'Q14A1';
const exceptionRuleTermsAndCondition = 'Q17A2';

export const SellerOnboardingForm = ({
  plumBid,
  refetch,
  mismatch,
  topOffers,
  setAnswer,
  canSubmit,
  processing,
  reviewMode,
  plumBidLoading,
  sellersAnswers,
  priorityParams,
  selectedSeller,
  plumBidRefetch,
  sellerQuestions,
  answeredSellers,
  myRoleInPlumBid,
  topOffersLoading,
  isSellerAnswered,
  saveSellerAnswers,
  confirmSellerAnswers,
  anotherSellersAnswer,
  isMeReachedToToFinish,
  confirmSellerAnswersLoading,
}) => {
  const showNotification = useNotification();

  const inputQ4A6Ref = useRef();
  const inputQ6A1Ref = useRef();

  const { plumbid } = useURLQuery();
  const history = useHistory();
  const { mobileScreen, mdScreen } = useResponsive();

  const [isShowedAutoFillDialog, setIsShowedAutoFillDialog] = useState(false);
  const [confirmAnswersLoading, setConfirmAnswersLoading] = useState(false);
  const [answerFieldValue, setAnswerFieldValue] = useState(null);
  const [answerFieldChange, setAnswerFieldChange] = useState(false);
  const [isFieldError, setIsFieldError] = useState(false);
  const [currentScrollQuestionIndex, setCurrentScrollQuestionIndex] = useState(0);
  const [currentScrollQuestionId, setCurrentScrollQuestionId] = useState(null);
  const [isDisableConfirm, setIsDisableConfirm] = useState(true);

  const { openDialog: openSurveyDialog, closeDialog: closeSurveyDialog } = useDialog(DIALOG.SURVEY);
  const { openDialog: openTopOfferDialog } = useDialog(DIALOG.TOP_OFFER);
  const {
    openDialog: openAutoFillQuestionnaireDialog,
    closeDialog: closeAutoFillQuestionnaireDialog,
    changeProps: changePropsAutoFillQuestionnaireDialog,
  } = useDialog(DIALOG.AUTO_FILL_QUESTIONNAIRE);
  const { openDialog: openOkCancelDialog, closeDialog: closeOkCancelDialog } = useDialog(DIALOG.OK_CANCEL);

  const isMyLAInPlumBid = plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT;
  const isPlumBidFinished = plumBid?.status === PLUMBID_STATUS.FINISHED;
  const isPlumBidActive = plumBid?.status === PLUMBID_STATUS.LIVE;
  const isShowOnboarding = (isMyLAInPlumBid && selectedSeller && isSellerAnswered) || myRoleInPlumBid === ROLES.SELLER;
  const isExceptionOnSaleContingency = sellersAnswers.some(
    answer => answer.answerId === exceptionRuleOnSaleContingency
  );
  const isExceptionRuleTermsAndCondition = sellersAnswers.some(
    answer => answer.answerId === exceptionRuleTermsAndCondition
  );
  const illegalAnswerCombination = useMemo(() => {
    const cashDownValues = Object.values(priorityParams.illegalAnswerCombination.cashDown);
    const cashDown = cashDownValues.length > 1 && cashDownValues.every(value => value);
    const inspectionContingencyValues = Object.values(priorityParams.illegalAnswerCombination.inspectionContingency);
    const inspectionContingency =
      inspectionContingencyValues.length > 1 && inspectionContingencyValues.every(value => value);

    return {
      ...(cashDown ? { Q1: true, Q2: true, 'Q3.2': true } : null),
      ...(inspectionContingency ? { Q8: true, Q9: true, 'Q10.2': true } : null),
      hasIllegalAnswerCombination: cashDown || inspectionContingency || isExceptionOnSaleContingency,
    };
  }, [
    priorityParams.illegalAnswerCombination.cashDown,
    priorityParams.illegalAnswerCombination.inspectionContingency,
    isExceptionOnSaleContingency,
  ]);
  const questionsId =
    sellersAnswers.filter(answer => !answer.answerId || illegalAnswerCombination[answer.questionId]) || [];

  if (isExceptionOnSaleContingency) {
    questionsId.push({ questionId: 'Q14' });
  }
  if (isExceptionRuleTermsAndCondition) {
    questionsId.push({ questionId: 'Q17' });
  }

  usePageLoading(plumBidLoading, 'SellerOnboardingForm');

  const submitForm = () => {
    setConfirmAnswersLoading(true);
    setIsShowedAutoFillDialog(true);
    const confirmAnswers = () => {
      state.isRunningAtomicOperationVar(true);
      confirmSellerAnswers()
        .then(res => {
          if (res?.data?.confirmSellerAnswers?.success) {
            plumBidRefetch()
              .then(res => {
                const sellerParty = res?.data?.plumbidById?.plumbidpartySet?.find(
                  party => party.party === PARTY_KIND.SELLER
                );
                if (sellerParty?.onboardingStatus?.[ONBOARDING_STATUS.REVIEW]) {
                  closeSurveyDialog();
                  openOkCancelDialog({
                    okTitle: 'OK',
                    hideCancel: true,
                    title: 'Attention',
                    text: 'Your agent will contact you regarding your responses',
                    onOk: () => {
                      closeOkCancelDialog();
                      history.goBack();
                      state.isRunningAtomicOperationVar(false);
                    },
                    onClose: () => {
                      closeOkCancelDialog();
                      state.isRunningAtomicOperationVar(false);
                      setConfirmAnswersLoading(false);
                    },
                  });
                } else {
                  closeSurveyDialog();
                  history.goBack();
                  state.isRunningAtomicOperationVar(false);
                }
              })
              .catch(() => {
                state.isRunningAtomicOperationVar(false);
                setConfirmAnswersLoading(false);
              });
          }
          state.isRunningAtomicOperationVar(false);
          if (res?.data?.confirmSellerAnswers?.errors) {
            showNotification({ error: res?.data?.confirmSellerAnswers?.errors });
            setConfirmAnswersLoading(false);
          }
        })
        .catch(() => {
          state.isRunningAtomicOperationVar(false);
          setConfirmAnswersLoading(false);
        })
        .finally(closeSurveyDialog);
    };

    if (mismatch) {
      openSurveyDialog({
        onOk: () => {
          confirmAnswers();
        },
        onClose: () => {
          closeSurveyDialog();
          setConfirmAnswersLoading(false);
          closeOkCancelDialog();
        },
      });
    } else {
      confirmAnswers();
    }
  };

  const getText = (question, text, field = 'text1', curAnswer) => {
    if (curAnswer?.questionId === 'Q17') {
      return text.replace('Terms & Conditions?', '');
    }
    return get(question, ['format', field]) ? question.format[field](text, priorityParams) : text;
  };

  const setRadioAnswer = (answerId, number, question, answerValue) => {
    setIsShowedAutoFillDialog(true);
    if (answerId === 'Q16A1') {
      answerValue = topOffers[0]?.offerId;
    }
    if (answerId === 'Q16A2') {
      answerValue = topOffers[1]?.offerId;
    }

    setAnswer(number, answerId, question, answerValue);
    if (sellersAnswers[number].questionId === 'Q4') {
      if (answerId !== 'Q4A6') {
        inputQ4A6Ref.current.value = null;
      } else {
        inputQ4A6Ref.current?.focus();
      }
    }

    if (answerId === 'Q5A1') {
      setTimeout(() => {
        inputQ6A1Ref.current?.focus();
      }, 1000);
    }
  };

  const handleAutoFillQuestionnaireDialog = useCallback(() => {
    setIsShowedAutoFillDialog(true);
    openAutoFillQuestionnaireDialog({
      users: answeredSellers,
      isSellerParty: true,
      onOk: async index => {
        try {
          changePropsAutoFillQuestionnaireDialog({ loading: true });
          const userId = answeredSellers[index].userId;
          const { data } = await saveSellerAnswers({
            variables: {
              input: {
                plumbidId: plumbid,
                answers: anotherSellersAnswer[userId]
                  .filter(answer => answer.answerId)
                  .map(({ questionId, answerId, answerValue }) => ({
                    answerId,
                    answerValue: answerValue ? +answerValue : null,
                    questionId,
                  })),
              },
            },
          });
          if (data?.changeSellerAnswers?.errors) {
            showNotification({ error: data.changeSellerAnswers.errors });
          }

          await refetch();
        } catch (error) {
          showNotification({ error });
        } finally {
          closeAutoFillQuestionnaireDialog();
          changePropsAutoFillQuestionnaireDialog({ loading: false });
          plumBidRefetch();
        }
      },
    });
  }, [
    plumbid,
    refetch,
    plumBidRefetch,
    answeredSellers,
    showNotification,
    saveSellerAnswers,
    anotherSellersAnswer,
    openAutoFillQuestionnaireDialog,
    closeAutoFillQuestionnaireDialog,
    changePropsAutoFillQuestionnaireDialog,
  ]);

  useEffect(() => {
    if (
      !isShowedAutoFillDialog &&
      plumBid &&
      plumBid?.myInfoInPlumBid.role === ROLES.SELLER &&
      (!isMeReachedToToFinish || plumBid?.myInfoInPlumBid.myPartyOnboardingStatus?.[ONBOARDING_STATUS.MISMATCH]) &&
      plumBid?.myInfoInPlumBid.myParty.status !== NEW_PARTY_STATUS.COMPLETED &&
      !processing &&
      answeredSellers?.length &&
      sellerQuestions
    ) {
      handleAutoFillQuestionnaireDialog();
    }
  }, [
    plumBid,
    processing,
    sellersAnswers,
    sellerQuestions,
    answeredSellers,
    isShowedAutoFillDialog,
    handleAutoFillQuestionnaireDialog,
    isMeReachedToToFinish,
  ]);

  const answerFieldError = (answerValue, answer, question, curAnswer) => {
    if (curAnswer?.answerId === 'Q4A6') {
      if (
        answerValue > question?.restrict[curAnswer?.answerId].maxValue ||
        answerValue < question?.restrict[curAnswer?.answerId].minValue ||
        !answerValue
      ) {
        setIsFieldError(
          `Min: ${question?.restrict[answer.answerId].minValue}, max: ${question?.restrict[answer.answerId].maxValue}`
        );
      } else {
        setIsFieldError('');
      }
    } else {
      if (
        (answerValue > question?.restrict[answer.answerId].maxValue ||
          answerValue < question?.restrict[answer.answerId].minValue ||
          !answerValue) &&
        curAnswer?.answerId === answer.answerId
      ) {
        setIsFieldError(
          `Min: ${question?.restrict[answer.answerId].minValue}, max: ${question?.restrict[answer.answerId].maxValue}`
        );
      } else {
        setIsFieldError('');
      }
    }
  };

  const handleAccept = (answerValue, number, answer, question) => {
    const restrict = question?.restrict[answer?.answerId];
    if (!answerValue || (answerValue >= restrict.minValue && answerValue <= restrict.maxValue)) {
      setAnswer(number, answer.answerId, question, answerValue, false, true);
      setAnswerFieldValue(null);
      setAnswerFieldChange(false);
    }
  };

  const handleClose = () => {
    setAnswerFieldChange(false);
    setAnswerFieldValue(null);
  };

  const handleChange = (answerValue, answer, question, curAnswer) => {
    setAnswerFieldChange({ edit: true, answerId: curAnswer?.answerId });
    setAnswerFieldValue(answerValue);
    answerFieldError(answerValue, answer, question, curAnswer);
  };

  const handleScroll = () => {
    const index =
      (questionsId.length &&
        questionsId.findIndex(val => val.questionId === questionsId[currentScrollQuestionIndex]?.questionId)) ||
      0;

    if (index === questionsId.length - 1) {
      setCurrentScrollQuestionId(questionsId[index]?.questionId);
      setCurrentScrollQuestionIndex(0);
    } else if (index !== -1) {
      if (questionsId.length > 1) {
        setCurrentScrollQuestionIndex(index + 1);
      } else {
        setCurrentScrollQuestionIndex(index);
      }
      setCurrentScrollQuestionId(questionsId[index]?.questionId);
    } else {
      setCurrentScrollQuestionIndex(0);
      setCurrentScrollQuestionId(questionsId[0]?.questionId);
    }
  };

  const renderInput = (curAnswer, number, question) => {
    return question.answerList.map(answer => (
      <Grid key={answer.answerId} container justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <Typography>{answer.text}</Typography>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Input
            style={{ flexBasis: '200px' }}
            inputRef={inputQ6A1Ref}
            type="number"
            error={answerFieldChange?.answerId === curAnswer?.answerId && isFieldError}
            disabled={isMyLAInPlumBid || isPlumBidFinished || isPlumBidActive}
            value={
              (answerFieldChange?.edit && answerFieldChange?.answerId === curAnswer?.answerId) || processing
                ? answerFieldValue
                : curAnswer.answerValue
            }
            inputProps={{ min: 1 }}
            onChange={e => handleChange(e.target.value, answer, question, curAnswer)}
          />
          {answerFieldChange?.answerId === curAnswer?.answerId && (
            <AnswerActions
              number={number}
              answer={answer}
              question={question}
              isDisabledAccept={isFieldError}
              handleAccept={handleAccept}
              answerFieldValue={answerFieldValue}
              handleClose={handleClose}
            />
          )}
        </Grid>
        <Grid item xs>
          {renderAnswersAnotherSeller(question.partyAnswers, answer.answerId)}
        </Grid>
      </Grid>
    ));
  };

  const renderAnswersAnotherSeller = (partyAnswers, answerId) => {
    const anotherAnswer = partyAnswers.filter(item => item.answerId === answerId);
    return anotherAnswer.length ? (
      <PBox pb={mobileScreen ? 2 : 0}>
        <Typography component="span" style={{ fontStyle: 'italic' }}>
          (This answer was selected by:{' '}
          {formatUserNameChain(
            anotherAnswer.map(
              item =>
                `${formatUserFullName(item.owner.user)}${item.answerValue && !['Q16A1', 'Q16A2'].some(answerId => item.answerId === answerId)
                  ? ` [${item.answerValue} day${item.answerValue > 1 ? 's' : ''}]`
                  : ''
                }`
            )
          )}
          )
        </Typography>
      </PBox>
    ) : null;
  };

  const renderCheckbox = (curAnswer, number, question) => {
    const answerId = curAnswer.answerId === 'Q18A1' ? 'Q18A2' : 'Q18A1';
    return (
      <Box mb={2.5} display="flex" alignItems="center">
        <Checkbox
          disabled={isMyLAInPlumBid || isPlumBidFinished || isPlumBidActive}
          checked={curAnswer.answerValue}
          onChange={e => {
            setAnswer(number, answerId, question, e.target.checked);
          }}
        />
        <Typography>Please acknowledge and accept</Typography>
      </Box>
    );
  };

  const renderRadioGroup = (curAnswer, number, question) => {
    return (
      <RadioGroup
        id={curAnswer.questionId}
        value={curAnswer.answerId}
        onChange={e => setRadioAnswer(e.target.value, number, question, curAnswer.answerValue)}
      >
        {question.answerList.map(answer => {
          return (
            <>
              <Grid key={answer.answerId} container alignItems="center" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    key={answer.answerId}
                    value={answer.answerId}
                    control={
                      <Radio
                        disabled={
                          processing ||
                          (answer.answerId === 'Q16A1' && !topOffers[0]) ||
                          (answer.answerId === 'Q16A2' && !topOffers[1]) ||
                          isMyLAInPlumBid ||
                          isPlumBidFinished ||
                          isPlumBidActive ||
                          answerFieldChange?.edit
                        }
                        style={{
                          color: theme.palette.primary.main,
                        }}
                        size="small"
                      />
                    }
                    label={getText(question, answer.text, answer.answerId)}
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '17px',
                      height: 30,
                    }}
                  />
                </Grid>
                {answer.answerId === 'Q4A6' && (
                  <Grid item container alignItems="center" xs={6}>
                    <Input
                      inputRef={inputQ4A6Ref}
                      style={{ flexBasis: '100px' }}
                      type="number"
                      disabled={isMyLAInPlumBid || isPlumBidFinished || isPlumBidActive}
                      error={answerFieldChange?.answerId === curAnswer?.answerId && isFieldError}
                      onFocus={() => setRadioAnswer('Q4A6', number, question, curAnswer.answerValue)}
                      value={
                        (answerFieldChange?.edit && answerFieldChange?.answerId === curAnswer?.answerId) || processing
                          ? answerFieldValue
                          : curAnswer.answerValue
                      }
                      inputProps={{ min: 1 }}
                      onChange={e => handleChange(e.target.value, answer, question, curAnswer)}
                    />
                    {answerFieldChange?.answerId === curAnswer?.answerId && (
                      <AnswerActions
                        number={number}
                        answer={answer}
                        question={question}
                        isDisabledAccept={isFieldError}
                        handleAccept={handleAccept}
                        answerFieldValue={answerFieldValue}
                        handleClose={handleClose}
                      />
                    )}
                  </Grid>
                )}

                <Grid item xs={mobileScreen ? 12 : true}>
                  {renderAnswersAnotherSeller(question.partyAnswers, answer.answerId)}
                </Grid>
              </Grid>
              {(curAnswer.answerId === exceptionRuleOnSaleContingency &&
                answer.answerId === exceptionRuleOnSaleContingency) ||
                (curAnswer.answerId === exceptionRuleTermsAndCondition &&
                  answer.answerId === exceptionRuleTermsAndCondition) ? (
                <Box pl={3} pt={mdScreen ? 3 : 1}>
                  <Typography
                    variant="body2"
                    component="pre"
                    style={{ color: theme.palette.text.error, fontWeight: 400 }}
                  >
                    {curAnswer.answerId === exceptionRuleOnSaleContingency &&
                      answer.answerId === exceptionRuleOnSaleContingency
                      ? `We don’t recommend to have a plumBid auction with he contingency on sale.
To complete the onboarding remove the contingency.`
                      : 'To complete the onboarding you have to agree to the plumBid Terms of Conditions'}
                  </Typography>
                </Box>
              ) : null}
            </>
          )
        })}
      </RadioGroup>
    );
  };

  const renderAnswers = (curAnswer, number, question) => {
    if (question.showOffersCard && !(topOffers?.length > 1)) {
      return null;
    }

    return (
      <Box>
        {question.type === 'input'
          ? renderInput(curAnswer, number, question)
          : question.type === 'checkbox'
            ? renderCheckbox(curAnswer, number, question)
            : renderRadioGroup(curAnswer, number, question)}
      </Box>
    );
  };

  const renderOfferButton = index => (
    <Box textAlign="center">
      <TopOfferButton
        offerIndex={plumBid.myInfoInPlumBid.role === ROLES.LISTING_AGENT ? index : null}
        onClick={() =>
          openTopOfferDialog({
            offer: { payload: topOffers[index] },
            plumBid: plumBid,
          })
        }
        disabled={topOffersLoading || !topOffers[index] || processing}
      >
        <OfferCard
          offer={{ payload: topOffers[index] }}
          plumBid={plumBid}
          style={{
            transform: 'scale(0.25) translate(10%, 0%)',
            minHeight: 200,
            minWidth: 550,
          }}
          miniature
        />
        <Icons.Magnifier style={{ position: 'absolute', top: 5, right: 8 }} />
        {topOffersLoading && <CircularProgress style={{ position: 'absolute' }} />}
      </TopOfferButton>
      <Typography style={{ color: theme.palette.text.disabled, fontStyle: 'italic', paddingTop: 5 }}>
        Offer {index + 1}
      </Typography>
    </Box>
  );

  const renderQuestionTitle = (curAnswer, number, question) => {
    if (question.showOffersCard && !(topOffers?.length > 1)) {
      return null;
    }
    if (curAnswer.questionId === 'Q18' && isDisableConfirm) {
      setIsDisableConfirm(false)
    }
    return (
      <QuestionTitleItem
        curAnswer={curAnswer}
        number={number}
        question={question}
        illegalAnswerCombination={illegalAnswerCombination}
        getText={getText}
        priorityParams={priorityParams}
        cardDisabled={processing || isMyLAInPlumBid || isPlumBidFinished || isPlumBidActive}
        setRadioAnswer={setRadioAnswer}
        renderOfferButton={renderOfferButton}
        currentScrollQuestionId={currentScrollQuestionId}
        setCurrentScrollQuestionId={setCurrentScrollQuestionId}
      />
    );
  };
  return plumBid ? (
    <>
      <Box
        display="flex"
        style={{
          boxSizing: 'border-box',
        }}
      >
        {isShowOnboarding ? (
          <>
            <Box pr={mdScreen ? 0 : 5} pt={mdScreen ? 2 : 4} pb={4} width="100%">
              {!plumBidLoading &&
                sellerQuestions &&
                sellersAnswers.map((curAnswer, number) => {
                  const question = sellerQuestions[curAnswer.questionId];

                  const isQuestionWithoutAnswer =
                    !curAnswer.answerId ||
                    ((curAnswer.answerId === 'Q4A6' || curAnswer.answerId === 'Q6A1') && !curAnswer.answerValue);

                  return (
                    <Box
                      key={curAnswer.questionId}
                      pl={1}
                      bgcolor={
                        !processing && isQuestionWithoutAnswer
                          ? theme.palette.background.roseWhite
                          : theme.palette.background.paper
                      }
                      border={
                        illegalAnswerCombination[curAnswer.questionId] ? `solid 1px ${theme.palette.error.main}` : ''
                      }
                    >
                      {renderQuestionTitle(curAnswer, number, question)}
                      {renderAnswers(curAnswer, number, question)}
                    </Box>
                  );
                })}

              {processing && (
                <Box mt={2} ml={{ xs: 1, md: 5 }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>

            {!reviewMode && (
              <Footer>
                <Button
                  title={questionsId.length > 0 ? 'Complete unanswered questions' : 'Confirm'}
                  icon="RightArrow"
                  disabled={
                    questionsId.length > 0
                      ? isDisableConfirm || false
                      : !canSubmit ||
                      isMyLAInPlumBid ||
                      isPlumBidFinished ||
                      isPlumBidActive ||
                      illegalAnswerCombination.hasIllegalAnswerCombination
                  }
                  loading={confirmSellerAnswersLoading || topOffersLoading || processing || confirmAnswersLoading}
                  onClick={questionsId.length > 0 ? handleScroll : submitForm}
                  style={{ width: questionsId.length > 0 ? 285 : 150 }}
                  id="tutorial__ConfirmOnboarding"
                />
              </Footer>
            )}
            <Box my={3.75} pb={2}></Box>
          </>
        ) : (
          <Box p={2} pt={2} pl={6}>
            <Typography variant="h2">{`Seller ${selectedSeller ? getParticipantField(selectedSeller, 'fullName') || '' : ''
              } Hasn't Begun Questionnaire.`}</Typography>
          </Box>
        )}
      </Box>
    </>
  ) : null;
};
