import gql from 'graphql-tag';

import { ChatMessageType } from '../types';

export const SEND_ADMIN_CHAT_MESSAGE_MUTATION = gql`
  mutation SendAdminChatMessage($input: SendAdminChatMessageInput!) {
    sendAdminChatMessage(input: $input) {
      errors
      success
      adminChatMessage {
        ${ChatMessageType}
      }
    }
  }  
`;
