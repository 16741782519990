import { memo } from 'react';
import { Typography } from '@material-ui/core';

import { ROLES } from 'constant';
import { useResponsive } from 'utils/hooks';
import { SellerStatus } from './SellerStatus';
import { getParticipantField, theme } from 'utils';
import { ActionButtonsBlock } from './ActionButtonsBlock';
import { PBox } from 'legos';

export const SellerItem = memo(({ plumBid, seller, disabledActionButton, showActionButtons, removeSeller }) => {
  const responsive = useResponsive();
  const canShowEmailAndStatus =
    plumBid.myInfoInPlumBid.role !== ROLES.BUYER_AGENT && plumBid.myInfoInPlumBid.role !== ROLES.BUYER;

  return (
    <>
      <PBox
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexDirection={responsive.smallScreen ? 'column' : 'row'}
        alignItems={responsive.smallScreen ? 'flex-start' : 'center'}
      >
        <PBox mr={1}>
          <Typography variant="h4" style={{ fontWeight: 500 }}>
            {getParticipantField(seller, 'fullName')}
          </Typography>
          {canShowEmailAndStatus && (
            <Typography variant="h6" style={{ fontWeight: 500, color: theme.palette.text.grey }}>
              {getParticipantField(seller, 'email')}
            </Typography>
          )}
        </PBox>

        {canShowEmailAndStatus && (
          <PBox mr={1}>
            <SellerStatus participant={seller} />
          </PBox>
        )}
      </PBox>
      {showActionButtons && (
        <ActionButtonsBlock
          seller={seller}
          removeSeller={() => removeSeller(seller.id)}
          disabledActionButton={disabledActionButton}
        />
      )}
    </>
  );
});
