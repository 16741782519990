import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { Icons } from 'legos';
import { formatPDFFileName } from 'utils/formatters';
import { theme } from 'utils/theme';

export const FileItem = ({ selectedId, onSelect, party }) => {
  const color = party.uploading
    ? theme.palette.text.rain
    : selectedId === party?.id
    ? theme.palette.primary.main
    : theme.palette.text.primary;
  const bgcolor = selectedId === party?.id ? theme.palette.background.whiteSmoke : theme.palette.background.paper;

  return (
    <Box
      px={2}
      mb={0.5}
      height={44}
      display="flex"
      borderRadius={4}
      bgcolor={bgcolor}
      alignItems="center"
      onClick={e => {
        e.stopPropagation();
        onSelect(party.id);
      }}
    >
      {party.uploading ? (
        <CircularProgress style={{ color: theme.palette.primary.main }} size={20} />
      ) : (
        <Icons.DocumentIconFilled style={{ color }} />
      )}
      <Box pl={1.25} flex={1} style={{ overflow: 'hidden', minWidth: 0 }}>
        <Typography noWrap variant="h5" style={{ color, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {formatPDFFileName(party?.offerdoc?.filename) ?? `File_${party?.id ?? ''}`}
        </Typography>
      </Box>
    </Box>
  );
};
