import React from 'react';
import { shape } from 'prop-types';

export const UploadDocument = ({ style }) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M42.9999 30.5C43.7999 30.6 44.2999 31.2 44.2999 32V60C44.2999 60.8 43.6999 61.4 42.9999 61.5C42.0999 61.6 41.3999 60.9 41.3999 60.1V32C41.3999 31.1 42.0999 30.5 42.9999 30.5Z"
      fill="#DADADA"
    />
    <path
      d="M42.7999 62.8C41.2999 62.8 40.2 61.6 40.2 60.2V32C40.2 30.5 41.4999 29.2 43.0999 29.4C44.3999 29.5 45.5 30.7 45.5 32.1V60.1C45.5 61.5 44.3999 62.7 43.0999 62.8C42.9999 62.8 42.8999 62.8 42.7999 62.8ZM42.9 31.7C42.7 31.7 42.5999 31.8 42.5999 31.9V60.1C42.5999 60.2 42.6999 60.3 42.7999 60.3C42.8999 60.3 43 60.1 43 60V32C43.1 31.9 43 31.7 42.9 31.7Z"
      fill="#DADADA"
    />
    <path
      d="M42.2 27.7C42.5 27.4 43.1 27.4 43.4 27.7L54.7 38.4C55.3 38.9 55.2999 39.9 54.7999 40.4C54.2999 41 53.2999 41 52.7999 40.5L42.9 31.1L32.9999 40.5C32.3999 41 31.4999 41 30.9999 40.4C30.4999 39.8 30.5 38.9 31.1 38.4L42.2 27.7Z"
      fill="#DADADA"
    />
    <path
      d="M53.7999 42.1C53.0999 42.1 52.4999 41.8 51.9999 41.4L42.8999 32.8L33.7999 41.4C32.7999 42.4 30.9999 42.3 30.0999 41.3C29.5999 40.8 29.3999 40.1 29.3999 39.4C29.3999 38.7 29.6999 38 30.1999 37.6L41.4999 26.9C42.2999 26.2 43.5999 26.2 44.3999 26.9L55.6999 37.6C56.1999 38.1 56.4999 38.7 56.4999 39.4C56.4999 40.1 56.2999 40.8 55.7999 41.3C55.1999 41.8 54.4999 42.1 53.7999 42.1ZM42.7999 29.9H43.2999L43.6999 30.3L53.5999 39.7C53.6999 39.8 53.7999 39.8 53.8999 39.7C53.9999 39.6 53.9999 39.6 53.9999 39.5C53.9999 39.5 53.9999 39.4 53.8999 39.3L42.7999 28.8L31.6999 39.3C31.5999 39.4 31.5999 39.4 31.5999 39.5C31.5999 39.5 31.5999 39.6 31.6999 39.7C31.7999 39.8 31.8999 39.8 31.9999 39.7L42.2999 30L42.7999 29.9Z"
      fill="#DADADA"
    />
    <path
      d="M68 84.5H17.7C13.5 84.5 10.2 81.1 10.2 77V9.5C10.2 5.3 13.6 2 17.7 2H68C72.2 2 75.5 5.4 75.5 9.5V76.9C75.5 81.1 72.0999 84.5 68 84.5ZM17.7 7.6C16.6 7.6 15.7 8.5 15.7 9.6V77C15.7 78.1 16.6 79 17.7 79H68C69.0999 79 70 78.1 70 77V9.5C70 8.4 69.0999 7.5 68 7.5H17.7V7.6Z"
      fill="#DADADA"
    />
    <path
      d="M82.3 98H32C27.8 98 24.5 94.6 24.5 90.5C24.5 89 25.7 87.7 27.3 87.7C28.8 87.7 30.1 88.9 30.1 90.5C30.1 91.6 31 92.5 32.1 92.5H82.4C83.5 92.5 84.4 91.6 84.4 90.5V23.1C84.4 22 83.5 21.1 82.4 21.1C80.9 21.1 79.6 19.9 79.6 18.3C79.6 16.7 80.8 15.5 82.4 15.5C86.6 15.5 89.9 18.9 89.9 23V90.4C89.8 94.6 86.5 98 82.3 98Z"
      fill="#DADADA"
    />
  </svg>
);

UploadDocument.propTypes = {
  style: shape({}),
};

UploadDocument.defaultProps = {
  style: null,
};
