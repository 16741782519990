import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { WIZARD } from 'routes';
import { Button, PBox } from 'legos';
import { usePlumBids } from './usePlumBids';
import { useAuthState } from 'service/store';
import { DashboardTabs } from './DashboardTabs';
import { useResponsive, useUpdateQueryString } from 'utils/hooks';
import { ListingAgentVerification } from './ListingAgentVerification';
import { LISTING_AGENT_VERIFY_STATUS, PLUMBID_STATUS_ID, ROLES } from 'constant';
import { useSettingsState } from 'service/store/settingsStore';
import { Footer } from 'components';

export const ACTIVE_PLUMBIDS = [PLUMBID_STATUS_ID.LIVE, PLUMBID_STATUS_ID.INCOMPLETE, PLUMBID_STATUS_ID.UPCOMING];
export const INACTIVE_PLUMBIDS = [PLUMBID_STATUS_ID.FINISHED, PLUMBID_STATUS_ID.CANCELED, PLUMBID_STATUS_ID.ESCROW];

const getFilter = parsedSearch => {
  if (parsedSearch.plumbid) {
    return undefined;
  }

  if (parsedSearch.t) {
    if (Number(parsedSearch.t) === 0) {
      return undefined;
    }
    if (Number(parsedSearch.t) === 2) {
      return INACTIVE_PLUMBIDS;
    }
  }
  return ACTIVE_PLUMBIDS;
};

export const DashboardScreen = () => {
  const [parsedSearch] = useUpdateQueryString();
  const { mobileScreen, tabletScreen } = useResponsive();

  const [{ refreshScreen }, dispatchSettings] = useSettingsState();

  const history = useHistory();
  const [{ me }] = useAuthState();

  const { plumBids, plumBidsLoading, plumBidsRefetch } = usePlumBids(getFilter(parsedSearch));

  const isUserListingAgent = useMemo(() => me?.roles?.includes(ROLES.LISTING_AGENT), [me]);

  const showAddPlumBidButton =
    isUserListingAgent && (me?.isVerifiedAgent || me?.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.VERIFIED);

  useEffect(() => {
    if (refreshScreen === 'DashboardPage') {
      plumBidsRefetch({ variables: { plumbidStatus: ACTIVE_PLUMBIDS } });
      dispatchSettings({
        type: 'refreshScreen',
        payload: null,
      });
    }
  }, [refreshScreen, plumBidsRefetch, dispatchSettings]);

  return (
    <Grid container direction="column">
      <Grid item>
        <PBox mt={mobileScreen ? 2.5 : tabletScreen ? 1 : 7.75} mb={tabletScreen ? 1 : 6.875} px={mobileScreen ? 1 : 6}>
          {!isUserListingAgent || (isUserListingAgent && me?.roles.length > 1) || me?.isVerifiedAgent ? (
            <Typography variant="h1" className="plumB1id" style={{ textTransform: 'uppercase' }}>
              {isUserListingAgent && me.roles.length === 1 ? 'Listing agent DASHBOARD' : 'DASHBOARD'}
            </Typography>
          ) : null}
        </PBox>
      </Grid>
      <Grid item container>
        <Grid item xs>
          <PBox px={mobileScreen ? 1 : 6}>
            {!plumBidsLoading ? <ListingAgentVerification /> : null}
            {showAddPlumBidButton ? (
              <PBox textAlign="right" pt={1}>
                <Button
                  title={me?.wizardStepInfo?.length ? 'Continue adding plumBid' : 'Add a new plumBid'}
                  icon="Add"
                  style={{ width: 220, marginBottom: 16 }}
                  onClick={() => history.push(WIZARD)}
                />
              </PBox>
            ) : null}
          </PBox>
          {!isUserListingAgent ||
            (isUserListingAgent && showAddPlumBidButton) ||
            (isUserListingAgent && me?.roles?.length > 1) ? (
            <DashboardTabs plumBids={plumBids} plumBidsRefetch={plumBidsRefetch} plumBidsLoading={plumBidsLoading} />
          ) : null}
          <PBox
            style={
              plumBids?.length < 1
                ? {
                  position: plumBidsLoading ? 'static' : 'fixed',
                  maxWidth: 1344,
                  margin: '0 auto',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '',
                }
                : { maxWidth: 1344, margin: '0 auto' }
            }
          >
            <Footer />
          </PBox>
        </Grid>
      </Grid>
    </Grid>
  );
};
